import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isMd } from "../../utils";
import { ArrowExitIcon, PlusIcon2, SearchIcon } from "../../img";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import TextField from "../../components/ui/form/Fields/TextField";
import { CheckboxIcon } from "../../img/icons/CheckboxIcon";
import Button from "../../components/ui/form/Buttons/Button";
import CompleteModal, { BtnDataItem } from "../../components/ui/dialog/CompleteModal";
import AddGroupModal from "./AddGroupModal";
import { useDispatch, useSelector } from "react-redux";
import { getGroupRemoveId, setGroupRemoveId } from "../../redux/reducers/workplace/workplaceSlice";
import { getPersonData, useAppDispatch } from "../../redux";
import { $getGroupList, $leaveGroup, $showGroupById } from "../../api/requests/groups";
import { GetGroupData } from "../../api/requests/groups/interface";
import noImageBanner from "../../img/noimage.png";
import { withDebounce } from "../../functions";

interface DropdownItem {
    label: string;
    value: number | string;
}

const GroupsPage = () => {
    const isMobile = isMd();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const searchParams = new URLSearchParams(location.search).get("search");

    const groupRemoveId = useSelector(getGroupRemoveId);
    const { group_id } = useSelector(getPersonData);

    const [searchValue, setSearchValue] = useState<string>("");

    const [groupList, setGroupList] = useState<GetGroupData[]>([]);
    const [filterGroupList, setFilterGroupList] = useState<GetGroupData[]>([]);

    const [isOpenRemove, setIsOpenRemove] = useState<GetGroupData | null>(null);
    const [isOpenAdd, setIsOpenAdd] = useState(false);

    const btnListRemove = [
        {
            text: "Отписаться",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const statusList = [
        {
            text: <CheckboxIcon />,
            key: 0,
        },
        {
            text: "Обработка",
            key: 4,
        },
        {
            text: "Приглашение",
            key: 5,
        }
    ];

    const handleNavigation = () => {
        navigate(-1);
    };

    const selectIcon = (role: string) => {
        const findKey = statusList.find(i => i.key === +role)

        if (findKey) return findKey.text

        return statusList[0].text
    }

    useEffect(() => {
        withDebounce(() => init());
    }, [searchValue]);

    useEffect(() => {
        if (groupRemoveId) {
            const findGroup = filterGroupList.find(item => item.id === groupRemoveId);

            findGroup && setIsOpenRemove(findGroup);
        }
    }, [groupRemoveId]);

    useEffect(() => {
        searchParams && setSearchValue(searchParams);
    }, [searchParams]);

    function init() {
        $getGroupList().then(res => {
            if (!res?.length) return;
            setGroupList(res);

            const updateList = [...res]
            .filter(({ role }: any) => {
                return role !== '0';
            })
            .filter(({ title }: any) => {
                return title.toLowerCase().includes(searchValue.toLowerCase());
            })
            .sort((prev: any, next: any) => {
                if (next.id === group_id) {
                    return 1;
                }

                if (prev.id === group_id) {
                    return -1;
                }

                return 0;
            })

            setFilterGroupList(updateList);
        });
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        if (!value.length) return navigate(`/groups`);

        navigate(`/groups?search=${value}`);
    }

    function handleOpenEdit(select: any) {
        if (+select?.role === 4) return;

        $showGroupById({ id: select.id }, { formData: true }).then(res => {
            if (res.error || res.response) return;

            navigate(`/?group_id=${select.id}`);
        })
    }

    function handleRemoveGroup(select: any) {
        setIsOpenRemove(select);
    }

    function handleChangeFilterRole(select: DropdownItem) {
        const updateList = [...groupList].filter(item => item.id === select.value);

        setFilterGroupList(updateList);
    }

    function handleClickBtn(select: BtnDataItem) {
        if (select.key === 0 && isOpenRemove) {
            $leaveGroup(
                { id: isOpenRemove.id },
                { formData: true }
            ).then(res => {
                setIsOpenRemove(null);
                init();
            });

            return;
        }

        setIsOpenRemove(null);
    }

    return (
        <div className="container employees">
            <div className="layout-inner__header birthdays-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Группы</h1>
                    </div>
                )}

                <div className="employees-header__right">
                    {/*<Dropdown*/}
                    {/*    placeholder={"Категория"}*/}
                    {/*    options={[]}*/}
                    {/*    onChange={handleChangeFilterRole}*/}
                    {/*/>*/}

                    <div className={"form-group contests__application__search"}>
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"#00000080"} />}
                        />
                    </div>
                </div>
            </div>

            <div className="groups__list">
                {filterGroupList.map((
                    item,
                    idx
                ) => (
                    <div
                        className={`groups__list__item ${item.id === group_id ? "active" : ""}`}
                        key={`groups-list-item-${idx}`}
                        onClick={() => handleOpenEdit(item)}
                    >
                        <div className="groups__list__item__top">
                            <div className={"groups__list__item__top__info"}>
                                <img
                                    className="groups__list__item__image"
                                    src={(item?.image as string) || noImageBanner}
                                    alt=""
                                />

                                <h3>{item.title}</h3>
                            </div>

                            <div className={"groups__list__item__likeIcon"}>
                                {typeof selectIcon(item.role) === "string"
                                    ? <p>{selectIcon(item.role)}</p>
                                    : selectIcon(item.role)}
                            </div>
                        </div>

                        {/*<div className="groups__list__item__content">*/}
                        {/*    <p className="groups__list__item__small">{item?.text}</p>*/}
                        {/*</div>*/}

                        <div className={"groups__list__item__bottom"}>
                            <div className="layout-thanks__list">
                                {item?.members?.slice(0, 3)?.map((
                                    {
                                        name,
                                        photo
                                    }, index) => (
                                    <div key={`members-item-${index}`} className="layout-thanks__item">
                                        {photo && <img src={photo} />}

                                        {!photo && name && <div
                                          className="layout-thanks__name">{name?.slice(0, 1)}</div>}
                                    </div>
                                ))}

                                {item?.members_count > 3 && (
                                    <div className="layout-thanks__count">
                                        {item.members_count}
                                    </div>
                                )}
                            </div>

                            <Button
                                className={"btn btn-text"}
                                onClick={(event) => {
                                    event.stopPropagation();

                                    handleRemoveGroup(item);
                                }}
                                text={"Отписаться"}
                            />
                        </div>
                    </div>
                ))}

                <div className={"groups__list__add"} onClick={() => setIsOpenAdd(true)}>
                    <div className={"groups__list__add-block"}>
                        <div className={"groups__list__add-icon"}><PlusIcon2 /></div>

                        <p>Добавить группу</p>
                    </div>
                </div>
            </div>

            <CompleteModal
                isOpen={!!isOpenRemove}
                setIsOpen={() => {
                    setIsOpenRemove(null);
                    dispatch(setGroupRemoveId(""));
                }}
                icon={"🥲"}
                title={"Вы уверены, что хотите отписаться от группы?"}
                btnList={btnListRemove}
                onClick={handleClickBtn}
            />

            <AddGroupModal
                isOpen={isOpenAdd}
                setIsOpen={() => setIsOpenAdd(false)}
                onClose={() => {
                    init();
                    setIsOpenAdd(false);
                }}
                list={groupList}
            />
        </div>
    );
};

export default GroupsPage;