import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getPersonData } from "../../../redux";
import {
    getPopupData, getSelected, setShapeData
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { Controller, useForm } from "react-hook-form";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import HashtagsField from "../../ui/form/Hashtags/HashtagsField";
import Button from "../../ui/form/Buttons/Button";

interface FormData {
    room: any;
    peripheries: string[];
}

interface WorkplaceCreateMeetingDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProps: any;
}

const WorkplaceCreateMeetingDialog = (
    {
        isOpen,
        onClose,
        selectedProps
    }: WorkplaceCreateMeetingDialogProps
) => {
    const { id: user_id } = useSelector(getPersonData);

    const { request } = selectedProps;

    const dispatch = useDispatch();

    const { city, building, floor, roomsInMap } = selectedProps || useSelector(getSelected);

    const previousData = useSelector(getPopupData);

    const isEdit = !!previousData?.isEdit;

    const initialBooking = {
        isEdit,
        room: previousData?.request?.room ? {
            ...previousData?.request?.room,
            value: previousData?.request?.room.id,
            label: previousData?.request?.room.name,
        } : null,
        peripheries: request?.request?.peripheries || []
    };

    const [numberList, setNumberList] = useState<any[]>([]);

    const [booking, setBooking] = useState<any>(null);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid }
    } = useForm<FormData>(
        {
            values: {
                ...initialBooking
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        if (floor && floor?.rooms) {
            const listDropdown = [...floor.rooms]
            .map(item => {
                return {
                    ...item,
                    label: item.name,
                    value: item.id
                };
            })
            .filter((room) => {
                return !roomsInMap.find((roomInMap: any) => {
                    if (roomInMap.request.room.id === previousData?.request?.room?.id) return false;

                    return roomInMap.request.room.id === room.id;
                });
            });

            setNumberList(listDropdown);

            setBooking({
                ...previousData
            });
        }
    }, [floor]);

    function onSubmit(data: FormData) {
        const requestData = {
            ...data
        };

        if (isEdit) return handleEdit(requestData);

        handleCreate(
            {
                ...requestData

            }
        );
    }

    function handleCreate(requestData: any) {
        dispatch(
            setShapeData({
                ...booking,
                request: {
                    ...booking?.request,
                    room: requestData.room,
                    peripheries: requestData.peripheries
                },
                type: "meeting-room",
                isCreate: true
            })
        );

        handleClosePopup();
    }

    function handleEdit(requestData: any) {
        dispatch(
            setShapeData({
                ...booking,
                request: {
                    ...booking?.request,
                    room: requestData.room,
                    peripheries: requestData.peripheries
                },
                type: "meeting-room",
                isEdit: true,
                isCreate: false
            })
        );

        handleClosePopup();
    }

    function handleDelete() {
        dispatch(
            setShapeData({
                ...booking,
                type: "meeting-room",
                isRemove: true,
                isEdit: false,
                isCreate: false
            })
        );

        handleClosePopup();
    }

    function handleClosePopup() {
        onClose();
        reset();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={handleClosePopup}
            widthProps={427}
            label={`${isEdit ? "Редактировать" : "Добавить"} переговорную`}
            classNameHeader={"pb-10"}
        >
            <div className={"workplace__booking"}>
                <div className={"workplace__booking__head"}>
                    <div className={"workplace__booking__address"}>
                        {`${city?.name || ""}, ${building?.name || ""}, ${floor?.number}-й этаж`}
                    </div>
                </div>

                <form
                    className={"workplace__booking__form d-flex flex-column gap-5"}
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <div className={"d-flex flex-column gap-5"}>
                        <Controller
                            name={"room"}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Выберете переговорку"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    label="Выбрать переговорку"
                                    placeholder={"Выберете переговорку"}
                                    options={numberList}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="peripheries"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <HashtagsField
                                    value={value}
                                    title="Периферия рядом"
                                    name="peripheries"
                                    onChange={onChange}
                                    isHash={false}
                                />
                            )}
                        />
                    </div>

                    <div className={"workplace__booking__btns"}>
                        <Button
                            type={"submit"}
                            text={isEdit ? "Сохранить" : "Добавить"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                        />

                        {isEdit ? (
                            <Button
                                text={"Удалить"}
                                className={"btn btn-red ml-0 w-100"}
                                onClick={handleDelete}
                            />
                        ) : (
                            <Button
                                text={"Отменить"}
                                className={"btn btn-light ml-0 w-100"}
                                onClick={handleClosePopup}
                            />
                        )}
                    </div>
                </form>
            </div>
        </DialogContainer>
    );
};

export default WorkplaceCreateMeetingDialog;