import { IBlogsRes } from "../../api/requests/blogs/interface";
import { FC, useState } from "react";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { BlankImage } from "../ui/blank-image/BlankImage";

interface IBlogsDraftCard {
    data: IBlogsRes
}

interface DropdownItem {
    label: string;
    value: number | string;
}

export const BlogsDraftCard: FC<IBlogsDraftCard> = ({
    data
}) => {
    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "",
            value: 0
        },
        {
            label: "На модерации",
            value: 1
        },
        {
            label: "",
            value: 2
        },
        {
            label: "Отклонено",
            value: 3
        }
    ]);

    const statusFind = statusList.find(item => item.value == data.status);

    return (
        <div className='blogs-drafts-card'>
            <div className='feed-news-card__img'>
                <BlankImage image={data?.banner || ''} classname='blogs-drafts-card__image'/>

                {data.status && (
                    <div className={`feed-news-card__active ${statusFind?.label}`} style={{background: data.status == 1 ? '' : '#FF0000'}}>
                        <div className={`${statusFind?.label}`}></div>

                        <p>{statusFind?.label}</p>
                    </div>
                )}
            </div>

            <div className='blogs-drafts-card__content'>
                <h1 className='blogs-drafts-card__title'>{ data?.title }</h1>

                { data.category?.title ? (
                    <div className='blogs-drafts-card__category' >
                        { data.category?.title }
                    </div>
                ) : null }

                <p className='blogs-drafts-card__text'>
                    {data.cleanedText}
                </p>

            </div>
        </div>
    )
}