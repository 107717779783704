import dayjs from "dayjs";
import 'dayjs/locale/ru'

const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

const currentMonth = months[new Date().getMonth()].toLowerCase()

export const statisticsDates = {
    months: months,

    hours: ['00:00', '01:00', '02:00', '03:00', '04:00',
        '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00',
        '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00',
    ],

    weekDays: generateRangeArray().map((item) => `${item.day} ${item.month}`),

    desktopDays: function () {
        let result = [];

        for (let i = 0; i <= getCurrentMonthDaysArray().length; i += 4) {
            result.push(getCurrentMonthDaysArray()[i])
        }

        return result
    },

    desktopHours: ['00:00', '05:00', '09:00', '14:00', '19:00', '23:00'],

    tabletDays: function () {
        let result = [];

        for (let i = 0; i <= getCurrentMonthDaysArray().length; i += 7) {
            result.push([getCurrentMonthDaysArray()[i]])
        }

        return result
    },

    tabletMonths: ['Янв', 'Мар', 'Май', 'Июл',  'Сен', 'Дек'],

    tabletWeek: function () {
        let result = [];
        for (let i = 0; i <= generateRangeArray().length; i += 2) {
            result.push(`${generateRangeArray()[i].day} ${generateRangeArray()[i].month} `);
        }

        return result
    }
}

export function generateRangeArray() {
    const startOfWeek = dayjs().locale('ru').startOf('week').toDate(); // Start of the week
    const endOfWeek = dayjs().locale('ru').endOf('week').toDate();

    let result = [];
    let currentDate = new Date(startOfWeek);

    // Loop until the current date exceeds the end of the week
    while (currentDate <= endOfWeek) {
        result.push({
            day: currentDate.getDate(),
            month: currentDate.toLocaleString('ru', { month: 'short' }), // Get short month name in Russian
        });
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
    }

    return result;
}

export function generateRangeArrayWeek () {
    const startOfWeek = dayjs().locale('ru').startOf('week');
    const weekArray = [];


    for (let i = 0; i < 7; i++) {
        weekArray.push({date: startOfWeek.add(i, 'day').format('DD.MM.YYYY'), day: startOfWeek.add(i, 'day').format('DD')});
    }

    return weekArray;
}

export function getMonthArray() {
    // Start with the first day of the current month
    const startOfMonth = dayjs().locale('ru').startOf('month');
    // Get the total number of days in the current month
    const daysInMonth = dayjs().locale('ru').daysInMonth();
    const monthArray = [];

    // Generate the dates for each day of the current month
    for (let i = 0; i < daysInMonth; i++) {
        monthArray.push(startOfMonth.add(i, 'day').format('DD.MM.YYYY'));
    }

    return monthArray;
}

function getCurrentMonthDaysArray() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
    const monthAbbreviation = new Intl.DateTimeFormat('ru', { month: 'short' }).format(currentDate);

    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
        daysArray.push(`${i}${monthAbbreviation.toLowerCase()}`);
    }
    return daysArray;
}
