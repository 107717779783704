import {FC} from "react";
import {IBaseIconProps} from "../types";

export const PaperClipIcon: FC<IBaseIconProps> = ({
                                                      color = '#000',
                                                      size = 24,
                                                  }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.4383 11.662L12.2483 20.852C11.1225 21.9779 9.59552 22.6103 8.00334 22.6103C6.41115 22.6103 4.88418 21.9779 3.75834 20.852C2.63249 19.7262 2 18.1992 2 16.607C2 15.0148 2.63249 13.4879 3.75834 12.362L12.9483 3.17201C13.6989 2.42145 14.7169 1.99979 15.7783 1.99979C16.8398 1.99979 17.8578 2.42145 18.6083 3.17201C19.3589 3.92257 19.7806 4.94055 19.7806 6.00201C19.7806 7.06347 19.3589 8.08145 18.6083 8.83201L9.40834 18.022C9.22252 18.2078 9.00192 18.3552 8.75913 18.4558C8.51634 18.5564 8.25613 18.6081 7.99334 18.6081C7.73055 18.6081 7.47033 18.5564 7.22754 18.4558C6.98476 18.3552 6.76416 18.2078 6.57834 18.022C6.39252 17.8362 6.24512 17.6156 6.14455 17.3728C6.04398 17.13 5.99222 16.8698 5.99222 16.607C5.99222 16.3442 6.04398 16.084 6.14455 15.8412C6.24512 15.5984 6.39252 15.3778 6.57834 15.192L15.0683 6.71201"
                stroke={color} strokeWidth="1.99" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}