import React, { useState } from "react";
import {
    ServicesEmbulanceIcon,
    ServicesTripIcon,
    ServicesVacationIcon
} from "../../img";
import { useNavigate } from "react-router-dom";
import HorizonNavigate from "../../components/navigation/HorizonNavigate";
import { UsersMultiIcon } from "../../img/icons/workspace/UsersMultiIcon";
import { FeedEventsContent } from "../../components/main";
import { isTillMd } from "../../utils";

export const CalendarPage = () => {
    const isMobile = isTillMd();
    const navigate = useNavigate();

    const [servicesStaticData, setServicesStaticData] = useState([
        {
            image: <ServicesVacationIcon />,
            title: "Отпуск",
            category: "Календарь",
            url: "vacation"
        },
        {
            image: <ServicesTripIcon />,
            title: "Командировка",
            category: "Календарь",
            url: "business"
        },
        {
            image: <ServicesEmbulanceIcon />,
            title: "Больничный",
            category: "Календарь",
            url: "medical"
        },
        {
            image: <UsersMultiIcon />,
            title: "Мероприятия",
            category: "Календарь",
            url: "events"
        }
    ]);

    return (
        <div className={"calendar-page"}>
            {!isMobile && <HorizonNavigate isScroll={false} />}

            <div className="container">
                <div className={"calendar-page__wrapper"}>
                    <div className="calendar-page__wrapper__header">
                        <h3 className={""}>Календарь</h3>
                    </div>

                    <div className="vacation-content">
                        {servicesStaticData?.map((item, idx) => (
                            <div
                                className="services-block"
                                key={idx}
                                onClick={() => {
                                    navigate(item.url);
                                }}
                            >
                                <div className="services-block__image">
                                    {item.image}
                                </div>

                                <h1 className="services-block__title">{item.title}</h1>

                                <div className="services-block__category">
                                    {item.category}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="container">
                <FeedEventsContent/>
            </div>
        </div>
    );
};