import { FC } from "react";
import { IPolls, IPolls2 } from "../../../api/requests/polls/interface";
import noImageBanner from "../../../img/noimage.png";
import Button from "../../ui/form/Buttons/Button";
import { ShareIcon } from "../../../img/icons/ShareIcon";
import { CoinCompetitionIcon } from "../../../img";
import { Trackbar } from "../../ui/trackbar";

interface CreatePollFormSubmit {
    image: File | string | null;
    title: string;
    description: string,
    text?: string;
    date_start: string;
    date_end: string;
    status: boolean,
    pinned: boolean,
    complicated: boolean,
    answers: any,
}

interface IPollsPreviewPage {
    data: any
    handleSubmit?: () => void;
    setValue: any,
    answersData: any,
}

export const PollsPageView: FC<IPollsPreviewPage> = (
    {
        data,
        handleSubmit,
        answersData,
        setValue
    }) => {
    function moveFirstToEnd(array: any) {
        if (array) {
            if (array?.length === 0) return array;
            const [firstItem, ...rest] = array;
            return [...rest, firstItem];
        }
    }

    return (
        <div className="polls-create-preview__content">
            <div className="polls-create-preview__img">
                <img src={data?.image || noImageBanner} />
            </div>

            <div className="polls-create-preview__desc">
                <div className={"d-flex align-items-center gap-3"}>
                    <h3 style={{ fontSize: data.title ? "" : "2rem" }}
                        className="polls-create-preview__title">{data.title || "Название"}</h3>
                </div>

                <div className="polls-create-preview__answers">
                    {/*{data.description}*/}
                    {answersData && (
                        <>
                            {answersData.map((item: any, idx: number) => (
                                <div>
                                    <h2 className="polls-create-preview__answers__title">{item.text}</h2>

                                    <div className="polls-create-preview__answers__list">
                                        {item.type !== "trackbar" && (
                                            <>
                                                {moveFirstToEnd(item?.answers)?.map((answer: any) => (
                                                    <>
                                                        <div
                                                            className="polls-modal-question__block"
                                                            key={idx}>
                                                            <input
                                                                type="checkbox"
                                                                checked={false}
                                                                disabled={true}
                                                                onChange={() => ""}
                                                            />

                                                            <p className="polls-create-preview__answers__text">{answer.id == 0 ? 'Поле Другое' : answer.text}</p>
                                                        </div>
                                                    </>
                                                ))}
                                            </>
                                        )}
                                    </div>

                                    {item.type === "trackbar" && (
                                        <div>
                                            <Trackbar
                                                disabled={true}
                                                min={item.min || 0}
                                                max={item.max || 10}
                                                step={1}
                                                value={item.min}
                                                onChange={(e: any) => ""}
                                            />
                                        </div>
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>

                {/*<div className='polls-create-preview__point'>*/}
                {/*    <h1 className='polls-create-preview__point__title'>Награда:</h1>*/}

                {/*    <div className='polls-create-preview__point__stats'>*/}
                {/*        <CoinCompetitionIcon/>*/}

                {/*        <p>{data.reward_point}</p>*/}
                {/*    </div>*/}
                {/*</div>*/}

                <div className="polls-create-preview__button">
                    Ответить
                </div>
            </div>
        </div>
    );
};