export const SportIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g opacity={opacity}>
                <path d="M1 10C1 8.89543 1.89543 8 3 8V16C1.89543 16 1 15.1046 1 14V10Z"
                      fill={color}
                />
                <path d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16V8Z"
                      fill={color}
                />
                <rect x="4" y="5" width="4" height="14" rx="2"
                      stroke={color}
                      strokeWidth="2"
                />
                <rect x="16" y="5" width="4" height="14" rx="2"
                      stroke={color}
                      strokeWidth="2"
                />
                <rect x="16.5" y="10.5" width="3" height="9"
                      transform="rotate(90 16.5 10.5)"
                      stroke={color}
                />
            </g>
        </svg>
    );
};
