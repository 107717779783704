export const ExcelDownloadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.8884 20.631C18.3312 20.631 17.7869 20.5581 17.2554 20.4124C16.7239 20.2666 16.2996 20.0823 15.9824 19.8595L16.5996 18.6379C16.9082 18.8436 17.2768 19.0108 17.7054 19.1394C18.1426 19.2594 18.5712 19.3194 18.9913 19.3194C19.9514 19.3194 20.4314 19.0665 20.4314 18.5608C20.4314 18.3207 20.3071 18.1536 20.0585 18.0593C19.8185 17.965 19.4285 17.875 18.8884 17.7893C18.3227 17.7035 17.8597 17.6049 17.4997 17.4935C17.1483 17.3821 16.8396 17.1892 16.5739 16.9149C16.3167 16.632 16.1882 16.242 16.1882 15.7448C16.1882 15.0933 16.4582 14.5747 16.9982 14.1889C17.5469 13.7946 18.2841 13.5974 19.2099 13.5974C19.6814 13.5974 20.1528 13.6531 20.6243 13.7646C21.0958 13.8674 21.4815 14.0089 21.7816 14.1889L21.1644 15.4104C20.5814 15.0676 19.9257 14.8961 19.197 14.8961C18.7255 14.8961 18.3655 14.969 18.1169 15.1147C17.8769 15.2519 17.7569 15.4362 17.7569 15.6676C17.7569 15.9248 17.8855 16.1091 18.1426 16.2205C18.4084 16.3234 18.8156 16.422 19.3642 16.5163C19.9128 16.602 20.3629 16.7006 20.7143 16.812C21.0658 16.9235 21.3658 17.112 21.6144 17.3778C21.8716 17.6435 22.0002 18.0207 22.0002 18.5093C22.0002 19.1522 21.7216 19.6666 21.1644 20.0523C20.6072 20.4381 19.8485 20.631 18.8884 20.631Z"
                fill="black" />
            <path d="M13.0498 11H14.6571V20.5409H13.0498V11Z" fill="black" />
            <path
                d="M10.0508 20.541L8.28916 18.1621L6.51469 20.541H4.74023L7.41478 17.0563L4.85596 13.6746H6.64328L8.32773 15.9248L10.0122 13.6746H11.7481L9.17638 17.0306L11.8638 20.541H10.0508Z"
                fill="black" />
            <path
                d="M3.05439 20.631C2.76293 20.631 2.51434 20.5324 2.3086 20.3352C2.10287 20.1295 2 19.8723 2 19.5637C2 19.2465 2.09858 18.9894 2.29574 18.7922C2.50148 18.595 2.75436 18.4965 3.05439 18.4965C3.35442 18.4965 3.60301 18.595 3.80018 18.7922C4.00591 18.9894 4.10878 19.2465 4.10878 19.5637C4.10878 19.8723 4.00591 20.1295 3.80018 20.3352C3.59444 20.5324 3.34585 20.631 3.05439 20.631Z"
                fill="black" />
            <path
                d="M12.5715 2V7.94246M12.5715 7.94246L15.6118 4.90768M12.5715 7.94246L9.53125 4.90768"
                stroke="black" strokeWidth="1.33012" stroke-linecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};