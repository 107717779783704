import {
    ArrowExitIcon,
    CloseIcon,
    CoinCompetitionIcon,
    HamburgerIcon,
    PenIcon,
    PlusIcon, SettingIcon
} from "../../../img";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import TextField from "../../../components/ui/form/Fields/TextField";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import Button from "../../../components/ui/form/Buttons/Button";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import { PollsCreateQuestionModal, PollsPageView } from "../../../components/polls";
import { IPolls2, IPollsAnswers } from "../../../api/requests/polls/interface";
import {
    $createPoll,
    $deletePoll,
    $editPoll,
    $getPollsList,
    $viewPollsItem
} from "../../../api/requests/polls";
import { useDispatch, useSelector } from "react-redux";
import { getPersonData, updateRouteTitle } from "../../../redux";
import { getYearMonthDay, isMd } from "../../../utils";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import FileField from "../../../components/ui/form/FileField/FileField";
import moment from "moment";
import CompleteModal from "../../../components/ui/dialog/CompleteModal";
import { $deleteBlogs, $deleteDashboardBlogs } from "../../../api/requests/blogs";
import { ei } from "@fullcalendar/core/internal-common";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface CreatePollFormSubmit {
    image: File | string | null;
    title: string;
    text?: string;
    description: string,
    date: any;
    end_date: any;
    status: boolean,
    pinned: boolean,
    complicated: boolean,
    awarding_enabled: string,
    award_amount: string,
    is_adaptation: string,
    type: string,
}

export const PollsCreatePage = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const dispatch = useDispatch();

    const isMobile = isMd();
    const navigate = useNavigate();

    const { workspace_id: wsId } = useSelector(getPersonData);

    const purpose: string = pathname.includes("edit") ? "edit" : "create";
    const poll_id = searchParams.get("poll_id");

    const dragItem = useRef<any>(null);
    const dragOverTime = useRef<any>(null);

    const [isShow, setIsShow] = useState(true);
    const [pollData, setPollData] = useState<IPolls2>();

    const [answersData, setAnswersData] = useState<IPollsAnswers[]>([]);
    const [isModalOpen, setIsOpenModal] = useState(0);

    const [editData, setEditData] = useState<any>({})

    const [deleteModalOpen, setDeleteModalOpen] = useState(0);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<CreatePollFormSubmit>(
        (purpose === "edit" && editData) ? {
            values: {
                image: editData?.image,
                title: editData?.title,
                date: new Date(editData?.date * 1000),
                end_date: new Date(editData?.end_date * 1000),
                complicated: editData?.complicated,
                status: editData?.status,
                pinned: editData?.pinned,
                description: editData?.description,
                is_adaptation: editData?.is_adaptation,
                award_amount: editData?.award_amount,
                awarding_enabled: editData?.awarding_enabled,
                type: editData?.type,
            }
        } : {}
    );

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const handlePollFieldChange = (index: number, value: string) => {
        const updatedList = [...answersData].map((item, idx) =>
            idx === index ? { ...item, text: value } : item
        );

        setAnswersData(updatedList);
    };

    const handleAddPoll = () => {
        const lastId = answersData.length > 0 ? answersData[answersData.length - 1].question_id : 0;
        const newItem = {
            question_id: lastId + 1,
            text: "",
            type: "radio",
            answers: [
                { id: 0, text: "", next: "", personal_answer: 0 },
                { id: 1, text: "", next: "" },
            ]
        };

        setAnswersData([...answersData, newItem]);
    };

    const handleDeletePoll = (id: number) => {
        const updatedList = answersData.filter((item) => item.question_id !== id);

        setAnswersData(updatedList);
    };

    const handleSort = () => {
        let _answersData = [...answersData];

        const draggedItemContent = _answersData.splice(dragItem.current, 1)[0];

        _answersData.splice(dragOverTime.current, 0, draggedItemContent);

        dragItem.current = null;
        dragOverTime.current = null;

        setAnswersData(_answersData);
    };

    const handleNavigation = () => {
        const pathArray = location.pathname.split("/");
        if (pathArray.length > 1) {
            pathArray.pop();
            const newPath = pathArray.join("/") || "/";
            navigate(newPath);
        } else {
            navigate("/");
        }
    };

    const onDragEnd = (result: any) => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "group") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...answersData];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            const updatedList = workValue.map((item, idx) => {
                return {
                    ...item,
                    question_id: idx + 1
                }
            })

            setAnswersData(updatedList);

            return;
        }
    };

    useEffect(() => {
        if (poll_id) {
            $viewPollsItem(+poll_id, {formData: true}).then(res => {
                setEditData({
                    ...res,
                })

                setAnswersData(res?.text)
            })
        }
    }, []);

    useEffect(() => {
        if (editData) {

            // setAnswersData(pollData.answers);
            dispatch(updateRouteTitle({ key: "polls_edit", title: editData?.title }));
        }
    }, [editData]);

    function onSubmit(status: number) {
        const updatedList = {
            ...editData,
            cat_id: undefined,
            title: watch().title,
            description: watch().description,
            date: watch().date / 1000,
            end_date: watch().end_date / 1000,
            text: JSON.stringify(answersData),
            workspace_id: wsId,
            image: watch().image,
            status: status,
            pinned: watch().pinned ? 1 : 0,
            complicated: watch().complicated ? 1 : 0,
            hashtags: '',
            password: undefined,
            is_adaptation: watch()?.is_adaptation || '0',
            type: watch()?.type || '1',
            award_amount: watch()?.award_amount || '0',
            awarding_enabled: watch()?.awarding_enabled || '0'
        }

        if (purpose === 'edit') {
            $editPoll(updatedList, {formData: true}).then(res => {
                if (res.error) return;

                navigate('/polls')
            })
        }
        else {
            $createPoll(updatedList, {formData: true}).then((res) => {
                if (res.error) return;

                navigate('/polls')
            })
        }
    }

    function onDelete (select: any) {
        if (select.key == 1) {
            setDeleteModalOpen(0);
        } else {
            $deletePoll({id: poll_id}, {formData: true}).then(res => {
                if (res.error) return;

                navigate('/polls')
            })
        }
    }


    return (
        <div className="polls-create">
            <CompleteModal
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить публикацию?"}
                isOpen={deleteModalOpen !== 0}
                setIsOpen={() => setDeleteModalOpen(0)}
                btnList={btnListRemove}
                onClick={onDelete}
            />

            <PollsCreateQuestionModal
                isOpen={isModalOpen !== 0}
                onClose={() => setIsOpenModal(0)}
                data={answersData[isModalOpen - 1]}
                setAnswersData={setAnswersData}
                answersData={answersData}
            />

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1> {purpose === "edit" ? editData?.title : "Создать опрос"}</h1>
                    </div>
                )}
            </div>

            <div className="polls-create-content">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                action=""
                                onSubmit={handleSubmit(() => onSubmit(1))}
                                className="contests__create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileField
                                            className={"form-image"}
                                            upload={value}
                                            label="Изображение"
                                            accept=".png, .jpg, .svg"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Название опроса"
                                            name="title"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextArea
                                            value={value || ""}
                                            className=""
                                            title="Описание"
                                            placeholder={"Введите описание"}
                                            // name="desc"
                                            onChange={onChange}
                                            // errors={errors}
                                        />
                                    )}
                                />

                                {/*<Controller*/}
                                {/*    name="category"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <Dropdown*/}
                                {/*            className={"form-select"}*/}
                                {/*            placeholder={"Категория"}*/}
                                {/*            label={"Тип опроса"}*/}
                                {/*            options={[*/}
                                {/*                {*/}
                                {/*                    label: "Все",*/}
                                {/*                    value: "all"*/}
                                {/*                },*/}
                                {/*                ...categoryList*/}
                                {/*            ]}*/}
                                {/*            value={value}*/}
                                {/*            onChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <div className={"polls-create__form-date"}>
                                    <Controller
                                        name="date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={moment(value).format("DD.MM.YYYY")}
                                                className=""
                                                minDate={new Date()}
                                                maxDate={ watch()?.end_date}
                                                label="Дата начала"
                                                placeholder={"Выберите дату"}
                                                // name="name"
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name="end_date"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                value={moment(value).format("DD.MM.YYYY")}
                                                className=""
                                                label="Дата завершения"
                                                placeholder={"Выберите дату"}
                                                minDate={watch()?.date || new Date()}
                                                // name="name"
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                            />
                                        )}
                                    />
                                </div>

                                {/*<Controller*/}
                                {/*    name="type"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <Dropdown*/}
                                {/*            className={"form-select"}*/}
                                {/*            placeholder={"Категория"}*/}
                                {/*            label={"Тип опроса"}*/}
                                {/*            options={[*/}
                                {/*                {*/}
                                {/*                    label: "Все",*/}
                                {/*                    value: "all"*/}
                                {/*                },*/}
                                {/*                ...categoryList*/}
                                {/*            ]}*/}
                                {/*            value={value}*/}
                                {/*            onChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                {/*<Controller*/}
                                {/*    name="logic"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <Dropdown*/}
                                {/*            className={"form-select"}*/}
                                {/*            placeholder={"Категория"}*/}
                                {/*            label={"Логика опроса"}*/}
                                {/*            options={[*/}
                                {/*                {*/}
                                {/*                    label: "Все",*/}
                                {/*                    value: "all"*/}
                                {/*                },*/}
                                {/*                ...categoryList*/}
                                {/*            ]}*/}
                                {/*            value={value}*/}
                                {/*            onChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <span className="polls-create__form__line"></span>

                                <div className="polls-create-answers">
                                    <p className="polls-create-answers__label">Вопросы</p>

                                    <DragDropContext onDragEnd={onDragEnd}>
                                        <Droppable droppableId="ROOT" type="group">
                                            {(provided) => (
                                                <div
                                                    {...provided.droppableProps}
                                                    ref={provided.innerRef}
                                                >
                                                    {answersData.map((item: IPollsAnswers, idx: number) => (
                                                        <Draggable
                                                            draggableId={`items-${idx}-block-${item?.question_id}`}
                                                            key={`items-${idx}-block-${item?.question_id}`}
                                                            index={idx}
                                                        >
                                                            {(_provided) => (
                                                                <div
                                                                    {..._provided.draggableProps}
                                                                    {..._provided.dragHandleProps}
                                                                    ref={_provided.innerRef}
                                                                    className="polls-create-answers__block"
                                                                >
                                                                    <div
                                                                        className="polls-create-answers__block__left">
                                                                        <div

                                                                        >
                                                                            <HamburgerIcon />
                                                                        </div>


                                                                        <p>{idx + 1}</p>
                                                                    </div>

                                                                    <div
                                                                        className="polls-create-answers__block__input">
                                                                        <input
                                                                            type="text"
                                                                            onChange={(e) => handlePollFieldChange(idx, e.target.value)}
                                                                            value={item.text}
                                                                        />

                                                                        <div
                                                                            className="services-create-form-field__input__right">
                                                                            <div
                                                                                style={{ opacity: 0.5 }}
                                                                                className="services-create-form-field__input__button"
                                                                                onClick={() => handleDeletePoll(item.question_id)}>
                                                                                <TrashIcon
                                                                                    color="#000" />
                                                                            </div>

                                                                            <div
                                                                                style={{ opacity: 0.5 }}
                                                                                className="services-create-form-field__input__button"
                                                                                onClick={() => setIsOpenModal(idx + 1)}
                                                                            >
                                                                                <PenIcon
                                                                                    color="#000" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    ))}

                                                    {provided.placeholder}

                                                    <div className="polls-create-answers__button"
                                                         onClick={handleAddPoll}>
                                                        <PlusIcon2 color={"#007BFF"} size={32} />
                                                    </div>
                                                </div>
                                            )}
                                        </Droppable>
                                    </DragDropContext>
                                </div>

                                {/*<Controller*/}
                                {/*    name="type_answer"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <Dropdown*/}
                                {/*            className={"form-select"}*/}
                                {/*            placeholder={"Категория"}*/}
                                {/*            label={"Тип ответов"}*/}
                                {/*            options={[*/}
                                {/*                {*/}
                                {/*                    label: "Все",*/}
                                {/*                    value: "all"*/}
                                {/*                },*/}
                                {/*                ...categoryList*/}
                                {/*            ]}*/}
                                {/*            value={value}*/}
                                {/*            onChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                {/*<Controller*/}
                                {/*    name="extra"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <ToggleBlock*/}
                                {/*            isChecked={value}*/}
                                {/*            className="polls-create__form-toggle"*/}
                                {/*            desc="Поле “Другое”"*/}
                                {/*            handleChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <span className="polls-create__form__line"></span>

                                <div className='polls-create__form__textWithToggle'>
                                    <Controller
                                        name="awarding_enabled"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <ToggleBlock
                                                isChecked={value == '1'}
                                                className="polls-create__form__textWithToggle-toggle"
                                                desc="Награда за участие"
                                                handleChange={() => onChange(value == '0' ? '1' : '0')}
                                            />
                                        )}

                                    />

                                    {watch()?.awarding_enabled == '1' && (
                                        <Controller
                                            name="award_amount"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <TextField
                                                    value={value}
                                                    className=""
                                                    type='number'
                                                    name="name"
                                                    onChange={onChange}
                                                    prepend={<CoinCompetitionIcon />}
                                                />
                                            )}
                                        />
                                    )}
                                </div>

                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value == "0"}
                                            className="polls-create__form-toggle"
                                            desc="Анонимный опрос"
                                            handleChange={() => onChange(value == '0' ? '1' : '0')}
                                        />
                                    )}
                                />

                                {/*<Controller*/}
                                {/*    name="is_adaptation"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: true,*/}
                                {/*            message: ""*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <ToggleBlock*/}
                                {/*            isChecked={value == '1'}*/}
                                {/*            className="polls-create__form-toggle"*/}
                                {/*            desc="Отображать опрос"*/}
                                {/*            handleChange={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <Controller
                                    name="pinned"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value}
                                            className="polls-create__form-toggle"
                                            desc="Закрепить опрос"
                                            handleChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="is_adaptation"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleBlock
                                            isChecked={value == '1'}
                                            className="polls-create__form-toggle"
                                            desc="Адаптация"
                                            handleChange={() => onChange(value == '0' ? '1' : '0')}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    {purpose === "edit" && (
                                        <Button
                                            onClick={() => setDeleteModalOpen(1)}
                                            type={"reset"}
                                            text={"Удалить"}
                                            className={"btn btn-de w-100 polls-create__form__deleteButton"}
                                        />
                                    )}

                                    <Button
                                        text={"В черновик"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => onSubmit(0)}
                                    />

                                    <Button
                                        onClick={() => onSubmit(1)}
                                        type={"submit"}
                                        text={purpose === 'edit' ? "Сохранить" : 'Опубликовать'}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"polls-show-preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && watch() && (
                                    <PollsPageView data={watch()} setValue={setValue} answersData={answersData}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};