import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, StarIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { FC, useEffect, useState } from "react";
import { getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";
import noImageBanner from "../../../img/noimage.png";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";

export interface NewsCardProps {
    title: string;
    image: string;
    category_title: string;
    date: any;
    views: number;
    likes_count: number;
    isLiked: boolean;
    id?: number;
    like_type?: number;
    like_name?: string;
    like_image?: string;
    like_image_file?: string;
    status?: number;
    category_id?: number;
    comments?: string;
    comments_count?: number,
    notifications_comments?: number;
    group_id?: string;
    hashtags?: string;
    background_color?: string;
    is_new_type?: string;
    groups_ids?: string;
    custom_likes_count?: number;
    users_like?: number;
    users_custom_like?: number;
}

interface INewsCard {
    isLikeIcon?: boolean;
    data: NewsCardProps
}

export const NewsCard: FC<INewsCard> = ({
    isLikeIcon = false,
    data
}) => {
    const navigate = useNavigate()

    const isMobile = isTillMd();
    const [imgSrc, setImgSrc] = useState<any>(null)

    const {
        title,
        image,
        category_title,
        date,
        views,
        likes_count,
        comments_count,
        isLiked,
        id
    } = data

    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            setImgSrc(image);
        };

    }, [image]);

    function handleRedirectNews() {
        navigate(`/news/show/${id}`)
    }

    return (
        <div className='news-card' onClick={handleRedirectNews}>
            <BlankImage image={image}/>

            <div className='news-card__infoBox'>

                { isLikeIcon ? (
                        <div className='news-card__infoBox__top'>
                            <h5>{ TextOverflow({title, lines: 3}) }</h5>

                            <div
                                // onClick={ () => {
                                //     handleLike(item.id)
                                // } }
                                className={ `employees-block__likeIcon ${ isLiked ? 'active' : '' }` }
                            >
                                <StarIcon opacity={isLiked ? 1 : 0.2}/>
                            </div>
                        </div>
                    ) :
                    <h5>{ TextOverflow({title, lines: 3}) }</h5>
                }

                {!isMobile && (
                  <div className={ `news-card__catTitleStyle` }>{ category_title ? category_title : 'Без категории' }</div>
                )}

                <div className='news-card__content'>
                    <div className='news-card__dateBox'>
                        <NewsDateIcon
                            size={ '1.25rem' }
                            style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} }
                        />

                        <div>{ getYearMonthDay(date * 1000) }</div>
                    </div>

                    <div className='news-card__stats'>
                        <div className='news-card__stats__block'>
                            <EyeIcon />

                            <h1>{ views }</h1>
                        </div>

                        <div className='news-card__stats__block'>
                            <CommentIcon />

                            <h1>{ comments_count }</h1>
                        </div>

                        <div className='news-card__stats__block'>
                            <LikeIcon color='#00A7B5' />

                            <h1>{ likes_count }</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}