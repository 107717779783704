import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import {
    getProfileVarsData,
    getRedactorNewsListData,
    setRedactorNewsListData
} from "../../../../../redux";
import { CKEditorTool, EditorJsLocal, EditorJsToHtml } from "./constants";
import { useParams } from "react-router-dom";

import EditorJS from "@editorjs/editorjs";
import Header from "@editorjs/header";
import List from "@coolbytes/editorjs-list";
import ImageTool from "@editorjs/image";
import Embed from "@editorjs/embed";
import Quote from "@editorjs/quote";
import createGenericInlineTool from "editorjs-inline-tool";
import AttachesTool from "@editorjs/attaches";
import ImageGallery from "editorjs-gallery";
import Tooltip from "editorjs-tooltip";
import ColorPlugin from "@7polo/editorjs-text-color";
import Paragraph from "@editorjs/paragraph";
import { LayoutBlockTool } from "editorjs-layout";
import Collapse from "@groupher/editor-collapse";
import Strikethrough from "@sotaproject/strikethrough";
import { withDebounce } from "../../../../../functions";

interface EditorJsProps {
    jsonCode: any;
    setSaveJsonEditor: (value: any) => void;
    typePage?: string;
}

const EditorJs = (
    {
        jsonCode,
        setSaveJsonEditor,
        typePage = "news"
    }: EditorJsProps
) => {
    const dispatch = useDispatch();

    const { id } = useParams();

    const vars = useSelector(getProfileVarsData);

    const editorRef: any = useRef(null);

    const [editorData, setEditorData] = useState<any>("");

    const [jsonCodeData, setJsonCodeData] = useState(jsonCode);
    const isStringLocalJson = jsonCodeData && (typeof jsonCodeData === "string");

    const [isLocalSave, setIsLocalSave] = useState(false);

    const htmlData = useSelector(getRedactorNewsListData);

    const dialog = document.querySelector("modal");

    if (dialog) {
        const toolbar: any = dialog.querySelector(".ce-toolbar__plus");
        const popover: any = dialog.querySelectorAll(".ce-popover-item__icon");
        const setting: any = dialog.querySelector(".ce-toolbar__settings-btn");
        const search: any = dialog.querySelector(".cdx-search-field__icon");

        if (toolbar) toolbar.style.color = vars.secondaryColor;
        if (setting) setting.style.color = vars.secondaryColor;
        if (search) search.style.color = vars.secondaryColor;
        if (popover) popover.forEach((i: any) => {
            i.style.color = vars.secondaryColor;
        });
    }

    if (typeof window !== "undefined") {
        const column_tools = {
            header: {
                class: Header,
                inlineToolbar: true
                // tunes: ['anyTuneName'],
            },
            paragraph: {
                class: Paragraph,
                inlineToolbar: true,
                placeholder: "Текст",
                preserveBlank: true
                // tunes: ['anyTuneName'],
            },
            list: {
                class: List,
                inlineToolbar: true,
                toolbox: {
                    title: "Список"
                }
            },
            image: {
                class: ImageTool,
                config: {
                    endpoints: {
                        byFile: "https://api-itelma-dev.svoi.club/backend/v1/upload/file"
                    },
                    additionalRequestHeaders: {
                        "authorization": `Bearer ${localStorage.getItem("token")}`
                    },
                    field: "file_content",
                    types: "image/png, image/jpg",
                    additionalRequestData: {
                        target_type: typePage,
                        file_type: "image"
                    },
                    uploader: {
                        async uploadByFile(file: File) {
                            return await downloadFile(file, "image").then((response: any) => {
                                console.log(response, '1');
                                if (!response.file) return response.error;

                                return {
                                    success: 1,
                                    file: {
                                        url: response.file.url,
                                        name: file.name,
                                        title: file.name,
                                        size: file.size
                                    }
                                };
                            });
                        }
                    }
                }
            },
            embed: {
                class: Embed,
                config: {
                    services: {
                        youtube: true,
                        coub: true
                    }
                }
            },
            quote: {
                class: Quote,
                inlineToolbar: true,
                config: {
                    quotePlaceholder: "Введите цитату",
                    captionPlaceholder: "Введите заголовок"
                }
            }
        };

        // Обработчик вывода HTML-кода
        const handleShowHTML = () => {
            const htmlCode = EditorJsToHtml(editorData);

            // const htmlCode = '<h1>Header</h1><p style="text-align: left">text</p><ol><li>list</li><li>asdsadzxczx</li><li>ascdsascd</li><li>sczcasdcds</li></ol><blockquote>fjnvnsfj sdn jkncds&#39;vsdv njvdn jkndsjk n&#39;c d sd dvfd jkndsjk nc&#39;ds [sfd djk ndn kjs</blockquote><table><thead><tr><th>table</th><th>table2</th></tr></thead><tbody><tr><td>table3</td><td>table4</td></tr><tr><td>table</td><td>table</td></tr></tbody></table>\n' +
            // 	'\t\t\t\t<div class="toggle-block is-opened">\n' +
            // 	'\t\t\t\t\t<div class="toggle-block__title">title</div><div class="toggle-block__content"><p style="text-align: left">text</p></div></div>'

            setSaveJsonEditor({ ...editorData });
            dispatch(setRedactorNewsListData(htmlCode));
        };

        const handleChange = useCallback((api: string, event: any) => {
            setEditorData(api);
        }, []);

        useEffect(() => {
            if (!editorData) return;

            startOfWhile(() => handleShowHTML(), 500);
        }, [editorData]);

        // Инициализация редактора при монтировании компонента
        useEffect(() => {
            withDebounce(() => {
                if ((id === undefined) && !jsonCodeData) return initEditor();

                if (!jsonCode) return initEditor();

                if (!jsonCodeData || isStringLocalJson || isLocalSave) return;

                initEditor();
            })
        }, [jsonCodeData]);

        useEffect(() => {
            const isObject = jsonCodeData && Object.keys(jsonCodeData).length;
            const isString = jsonCode && (typeof jsonCode === "string");

            if ((!isObject || isStringLocalJson) && isString) return setJsonCodeData(JSON.parse(jsonCode));

            if (!jsonCode || isObject) return;

            setJsonCodeData(jsonCode);
        }, [jsonCode]);

        useEffect(() => {
            return () => {
                setJsonCodeData({});
                setSaveJsonEditor({});
                dispatch(setRedactorNewsListData(""));
                if (editorRef.current) editorRef.current?.destroy();
                editorRef.current = null;
            };
        }, []);

        const startOfWhile = (func: any, time: any) => {
            const delayDebounceFn = setTimeout(func, time);

            return () => clearTimeout(delayDebounceFn);
        };

        const handleEditorTextChange = async (eNode: any) => {
            const editorSavedData = await eNode.saver.save();

            handleChange(editorSavedData, eNode);

            const isObject = jsonCodeData && Object.keys(jsonCodeData).length;
            const isString = jsonCode && (typeof jsonCode === "string");

            setIsLocalSave(true);
            if ((!isObject || isStringLocalJson) && isString) return setJsonCodeData(JSON.parse(editorSavedData));

            if (!jsonCode || isObject) return;

            setJsonCodeData(editorSavedData);
        };

        // Функция инициализации редактора с необходимыми плагинами
        const initEditor = () => {
            if (editorRef.current) {
                editorRef.current.id = "editorjs";

                const editorJSConfig = {
                    tools: {
                        ...column_tools
                    },
                    placeholder: "Нажмите чтобы начать редактирование",
                    onChange: () => handleEditorTextChange(editorInstance),
                    onReady: () => handleEditorTextChange(editorInstance)
                };

                const editorConfig: any = {
                    holder: "editorjs",
                    placeholder: "Наведите чтобы начать редактирование",
                    language: "ru",
                    data: jsonCodeData,
                    tools: {
                        paragraph: {
                            class: Paragraph,
                            inlineToolbar: true,
                            placeholder: "Текст",
                            preserveBlank: true,
                            // tunes: ['anyTuneName'],
                        },
                        header: {
                            class: Header,
                            inlineToolbar: true
                            // tunes: ['anyTuneName'],
                        },
                        list: {
                            class: List,
                            inlineToolbar: true,
                            toolbox: {
                                title: "Список"
                            }
                        },
                        image: {
                            class: ImageTool,
                            config: {
                                endpoints: {
                                    byFile: "https://api-itelma-dev.svoi.club/backend/v1/upload/file"
                                },
                                additionalRequestHeaders: {
                                    "authorization": `Bearer ${localStorage.getItem("token")}`
                                },
                                field: "file_content",
                                types: "image/png, image/jpg",
                                additionalRequestData: {
                                    target_type: typePage,
                                    file_type: "image"
                                },
                                uploader: {
                                    async uploadByFile(file: File) {
                                        return await downloadFile(file, "image").then((response: any) => {
                                            console.log(response, '2');
                                            if (!response.file) return response.error;

                                            return {
                                                success: 1,
                                                file: {
                                                    url: response.file.url,
                                                    name: file.name,
                                                    title: file.name,
                                                    size: file.size
                                                }
                                            };
                                        });
                                    }
                                }
                            }
                        },
                        embed: {
                            class: Embed,
                            config: {
                                services: {
                                    youtube: true,
                                    coub: true
                                }
                            }
                        },
                        quote: {
                            class: Quote,
                            inlineToolbar: true,
                            config: {
                                quotePlaceholder: "Введите цитату",
                                captionPlaceholder: "Введите заголовок"
                            }
                        },
                        ckeditor: {
                            class: CKEditorTool,
                            // inlineToolbar: true,
                            config: {
                                toolbar: {
                                    items: [
                                        "undo", "redo",
                                        //'|', 'heading',
                                        "|", "bold", "italic", "underline", "link",
                                        "|", "fontColor", "fontBackgroundColor",
                                        "|", "uploadImage", "removeFormat"
                                    ]
                                },
                                table: {
                                    language: "ru",
                                    placeholder: "Начните писать текст здесь...",
                                    contentToolbar: [
                                        "tableProperties",
                                        "tableCellProperties",
                                        "|",
                                        "tableColumn",
                                        "tableRow",
                                        "|",
                                        "mergeTableCells",
                                        "|",
                                        "insertTable"
                                    ]
                                },
                                image: {
                                    toolbar: [
                                        "imageStyle:inline", "imageStyle:wrapText", "imageStyle:breakText", "|",
                                        "toggleImageCaption", "imageTextAlternative"
                                    ]
                                }
                            }
                        },
                        collapse: {
                            class: Collapse,
                            inlineToolbar: true
                        },
                        strikethrough: {
                            class: Strikethrough
                        },
                        // color: {
                        //     class: ColorPlugin,
                        //     config: {
                        //         colorCollections: ["#000000", "#9C27B0", "#673AB7", "#3F51B5", "#0070FF", "#03A9F4", "#00BCD4", "#4CAF50", "#8BC34A", "#CDDC39", "#FFF"],
                        //         defaultColor: "#FF1300",
                        //         type: "text",
                        //         customPicker: true // add a button to allow selecting any colour
                        //     }
                        // },
                        // marker: {
                        //     class: ColorPlugin,
                        //     config: {
                        //         colorCollections: ["#EC7878", "#9C27B0", "#673AB7", "#3F51B5", "#0070FF", "#03A9F4", "#00BCD4", "#4CAF50", "#8BC34A", "#CDDC39", "#FFF"],
                        //         defaultColor: "#FF1300",
                        //         default: "#FF1300",
                        //         type: "marker",
                        //         customPicker: true, // add a button to allow selecting any colour
                        //     }
                        // },
                        underline: createGenericInlineTool,
                        attaches: {
                            class: AttachesTool,
                            config: {
                                endpoint: "https://api-itelma-dev.svoi.club/backend/v1/upload/file",
                                field: "file_content",
                                buttonText: "Загрузить файл",
                                errorMessage: "Не удалось загрузить файл. Максимальный размер файл 15мб.",
                                additionalRequestData: {
                                    target_type: typePage,
                                    file_type: "file"
                                },
                                additionalRequestHeaders: {
                                    "authorization": `Bearer ${localStorage.getItem("token")}`
                                },
                                uploader: {
                                    async uploadByFile(file: File) {
                                        return await downloadFile(file, "file").then((response: any) => {
                                            if (!response.file) return response.error;

                                            return {
                                                success: 1,
                                                file: {
                                                    url: response.file.url,
                                                    name: file.name,
                                                    title: file.name,
                                                    size: file.size
                                                }
                                            };
                                        });
                                    }
                                }
                            }
                        },
                        gallery: {
                            class: ImageGallery,
                            config: {
                                endpoints: {
                                    byFile: "https://api-itelma-dev.svoi.club/backend/v1/upload/file",
                                    removeImage: null
                                },
                                additionalRequestHeaders: {
                                    "authorization": `Bearer ${localStorage.getItem("token")}`
                                },
                                field: "file_content",
                                additionalRequestData: {
                                    target_type: typePage,
                                    file_type: "image"
                                },
                                captionPlaceholder: "Название",
                                buttonContent: "Добавьте изображение",
                                uploader: {
                                    async uploadByFile(file: File) {
                                        return await downloadFile(file, "image").then((response: any) => {
                                            if (!response.file) return response.error;

                                            return {
                                                success: 1,
                                                file: {
                                                    url: response.file.url,
                                                    name: file.name,
                                                    title: file.name,
                                                    size: file.size
                                                }
                                            };
                                        });
                                    }
                                }
                            },
                            toolbox: {
                                icon: `
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M21.0007 7.60391V20.4039C21.0007 20.563 20.9375 20.7156 20.825 20.8282C20.7125 20.9407 20.5599 21.0039 20.4007 21.0039H7.60073C7.4416 21.0039 7.28899 20.9407 7.17647 20.8282C7.06395 20.7156 7.00073 20.563 7.00073 20.4039V7.60391C7.00073 7.44478 7.06395 7.29216 7.17647 7.17964C7.28899 7.06712 7.4416 7.00391 7.60073 7.00391H20.4007C20.5599 7.00391 20.7125 7.06712 20.825 7.17964C20.9375 7.29216 21.0007 7.44478 21.0007 7.60391Z" stroke="#007BFF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M18.0007 4.00391H4.60073C4.4416 4.00391 4.28899 4.06712 4.17647 4.17964C4.06395 4.29216 4.00073 4.44478 4.00073 4.60391V18.0039M7.00073 16.8039L12.4447 15.0039L21.0007 18.0039M16.5007 13.0039C16.1029 13.0039 15.7214 12.8459 15.4401 12.5646C15.1588 12.2833 15.0007 11.9017 15.0007 11.5039C15.0007 11.1061 15.1588 10.7246 15.4401 10.4432C15.7214 10.1619 16.1029 10.0039 16.5007 10.0039C16.8986 10.0039 17.2801 10.1619 17.5614 10.4432C17.8427 10.7246 18.0007 11.1061 18.0007 11.5039C18.0007 11.9017 17.8427 12.2833 17.5614 12.5646C17.2801 12.8459 16.8986 13.0039 16.5007 13.0039Z" stroke="#007BFF" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            `}
                        },
                        tooltip: {
                            class: Tooltip,
                            config: {}
                        },
                        layout: {
                            class: LayoutBlockTool,
                            config: {
                                EditorJS,
                                editorJSConfig,
                                enableLayoutEditing: true,
                                enableLayoutSaving: true,
                                initialData: {
                                    itemContent: {
                                        1: {
                                            blocks: []
                                        }
                                    },
                                    layout: {
                                        type: "container",
                                        id: "",
                                        className: "editor-columns-blocks",
                                        children: [
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block",
                                                itemContentId: "1"
                                            }
                                        ]
                                    }
                                }
                            }
                        },

                        twoColumns: {
                            class: LayoutBlockTool,
                            config: {
                                EditorJS,
                                editorJSConfig,
                                enableLayoutEditing: true,
                                enableLayoutSaving: true,
                                initialData: {
                                    itemContent: {
                                        1: {
                                            blocks: []
                                        },
                                        2: {
                                            blocks: []
                                        }
                                    },
                                    layout: {
                                        type: "container",
                                        id: "",
                                        className: "editor-columns-blocks",
                                        children: [
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-50",
                                                itemContentId: "1"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-50",
                                                itemContentId: "2"
                                            }
                                        ]
                                    }
                                }
                            },
                            shortcut: "CMD+2",
                            toolbox: {
                                icon: `
                                  <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                                    <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                                    <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                                  </svg>
                                `,
                                title: "2 столбца"
                            }
                        },

                        threeColumns: {
                            class: LayoutBlockTool,
                            config: {
                                EditorJS,
                                editorJSConfig,
                                enableLayoutEditing: false,
                                enableLayoutSaving: false,
                                initialData: {
                                    itemContent: {
                                        1: {
                                            blocks: []
                                        },
                                        2: {
                                            blocks: []
                                        },
                                        3: {
                                            blocks: []
                                        }
                                    },
                                    layout: {
                                        type: "container",
                                        id: "",
                                        className: "editor-columns-blocks",
                                        children: [
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-33",
                                                itemContentId: "1"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-33",
                                                itemContentId: "2"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-33",
                                                itemContentId: "3"
                                            }
                                        ]
                                    }
                                }
                            },
                            shortcut: "CMD+3",
                            toolbox: {
                                icon: `
                                  <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                                    <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                                    <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
                                  </svg>
                                `,
                                title: "3 столбца"
                            }
                        },

                        fourColumns: {
                            class: LayoutBlockTool,
                            config: {
                                EditorJS,
                                editorJSConfig,
                                enableLayoutEditing: false,
                                enableLayoutSaving: false,
                                initialData: {
                                    itemContent: {
                                        1: {
                                            blocks: []
                                        },
                                        2: {
                                            blocks: []
                                        },
                                        3: {
                                            blocks: []
                                        },
                                        4: {
                                            blocks: []
                                        }
                                    },
                                    layout: {
                                        type: "container",
                                        id: "",
                                        className: "editor-columns-blocks",
                                        children: [
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-25",
                                                itemContentId: "1"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-25",
                                                itemContentId: "2"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-25",
                                                itemContentId: "3"
                                            },
                                            {
                                                type: "item",
                                                id: "",
                                                className: "editor-columns-block w-25",
                                                itemContentId: "4"
                                            }
                                        ]
                                    }
                                }
                            },
                            shortcut: "CMD+4",
                            toolbox: {
                                icon: `
              <svg xmlns='http://www.w3.org/2000/svg' width="16" height="16" viewBox='0 0 512 512'>
                <rect x='128' y='128' width='336' height='336' rx='57' ry='57' fill='none' stroke='currentColor' stroke-linejoin='round' stroke-width='32'/>
                <path d='M383.5 128l.5-24a56.16 56.16 0 00-56-56H112a64.19 64.19 0 00-64 64v216a56.16 56.16 0 0056 56h24' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='32'/>
              </svg>
            `,
                                title: "4 столбца"
                            }
                        }
                    },
                    i18n: EditorJsLocal,
                    onChange: () => handleEditorTextChange(editorInstance),
                    logLevel: "WARN"
                };

                const editorInstance: any = new EditorJS(editorConfig);
            }
        };

        const downloadFile = async (event: any, type = "file") => {
            const data = new FormData();

            data.append("file_content", event);
            data.append("target_type", typePage);
            data.append("file_type", type);

            try {
                const response = await fetch(`${process.env.REACT_APP_NEW_API_URL}backend/v1/upload/file`, {
                    method: 'POST',
                    body: data,
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                return await response.json();
            } catch (err) {
                console.error(">>> API Error", err);
                return err;
            }
        };

        return (
            <div className={"editorjs__container"}>
                {/*{htmlData && <div dangerouslySetInnerHTML={{ __html: htmlData }}></div>}*/}

                <div id="editorjs" ref={editorRef} className="editor"></div>
            </div>
        );
    }

    return null;
};

export default EditorJs;
