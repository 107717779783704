import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $getBadges = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/get_badges", data,{
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            formData: true
        }
    });

export const $createBadge = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/create_ws_badge", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            formData: true
        }
    });

export const $updateBadge = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/update_ws_badge", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            formData: true
        }
    });

export const $deleteBadge = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/delete_ws_badge", data,{
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            formData: true
        }
    });
