import Button from "../../../components/ui/form/Buttons/Button";
import { ArrowExitIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import Dropdown, { DropdownItem } from "../../../components/ui/form/Dropdown/Dropdown";

interface VacationHeaderProps {
    setCalendarBtn: (value: "timelineDay" | "dayGridWeek" | "dayGridMonth") => void;
    categoryList: any[];
    categorySelect: any;
    setCategorySelect: (value: any) => void;
}

const EventsCalendarHeader = (
    {
        setCalendarBtn,
        categoryList,
        categorySelect,
        setCategorySelect
    }: VacationHeaderProps
) => {
    const navigate = useNavigate();

    const [calendarBtnList, setCalendarBtnList] = useState([
        {
            key: "timelineDay",
            text: "День",
            active: false
        },
        {
            key: "dayGridWeek",
            text: "Неделя",
            active: true
        },
        {
            key: "dayGridMonth",
            text: "Месяц",
            active: false
        }
    ]);

    const handleChangeActive = (item: any) => {
        const updateList = [...calendarBtnList].map(i => {
            i.active = item.key === i.key;

            return i;
        });

        setCalendarBtnList(updateList);
        setCalendarBtn(item.key);
    };

    const handleBack = () => {
        navigate(`/calendar`);
    };

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`);
    }

    return (
        <div className={"vacation__header"}>
            <div className={"vacation__header__title"}>
                <div className={"back cursor-pointer"} onClick={handleBack}>
                    <ArrowExitIcon color={"black"} />
                </div>

                <h2>Мероприятия</h2>
            </div>

            <div className="vacation__header__btns">
                <div className={"vacation__header__btns-calendar"}>
                    {calendarBtnList.map((item, idx) => (
                        <Button
                            key={`calendar-btn-item-${idx}`}
                            className={`btn btn-${item.active ? "blue" : "secondary"}`}
                            onClick={() => handleChangeActive(item)}
                            text={item.text}
                        />
                    ))}
                </div>
            </div>

            <div className="events-content__header">
                <Dropdown
                    value={categorySelect}
                    className="workspace-select"
                    placeholder={"Категория"}
                    options={categoryList}
                    onChange={handleCategory}
                />
            </div>
        </div>
    );
};

export default EventsCalendarHeader;