import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BlankImage } from "../ui/blank-image/BlankImage";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { FavoriteIcon } from "../../img/icons/others/FavoriteIcon";
import ToggleBlock from "../ui/form/Toggle/ToggleBlock";
import { $setDiscountById, $setDiscountFavoriteById } from "../../api/requests/discount";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";

export interface DiscountCardProps {
    id: number;
    title: string;
    description: string;
    discount_description: string;
    gallery: string;
    logo: string;
    favorite: number;
    category?: any;
    on: number;
}

interface IDiscountCard {
    data: DiscountCardProps;
    setUpdateData?: (select: any) => void;
}

const DiscountCard = (
    {
        data,
        setUpdateData,
    }: IDiscountCard
) => {
    const navigate = useNavigate();

    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);

    const isAdmin = currentRole === 1;

    const [isFavorite, setIsFavorite] = useState(false);

    const {
        title,
        discount_description,
        gallery,
        logo,
        favorite: isLiked,
        category,
        id,
        on
    } = data;

    useEffect(() => {
        setIsFavorite(!!isLiked);
    }, [isLiked]);

    function handleRedirectNews() {
        navigate(`/discount/show/${id}`);
    }

    function setAsFavorite(e: any) {
        e.stopPropagation();
        setIsFavorite(!isFavorite);

        // $toggleUserFavorites({
        //     type: "discount",
        //     type_id: id,
        //     add_to_favorites: !isFavorite
        // }, { newApi: true }).then();

        const requestData = {
            ws_id: personalData.workspace_id,
            partner_id: data.id,
            favorite: isFavorite ? 0 : 1
        }

        $setDiscountFavoriteById(requestData).then()
    }

    function handleChangeToggle(e: any) {
        e.stopPropagation();

        const requestData = {
            id: personalData.workspace_id,
            partner_id: data.id,
            status: data.on ? 0 : 1
        }

        $setDiscountById(requestData).then()

        setUpdateData && setUpdateData({ ...data, on: !data.on })
    }

    return (
        <div
            className="media-files-card"
            onClick={handleRedirectNews}
        >
            <div className={"discount__block__content__img"}>
                <BlankImage image={gallery[0]} />

                {isAdmin && (
                    <div className={"discount__block__content__toggle"}>
                        <ToggleBlock
                            isChecked={!!on}
                            desc=""
                            handleChange={(value, e) => handleChangeToggle(e)}
                        />
                    </div>
                )}

                <div className={"discount__block__content__company"}>
                    <BlankImage image={logo} />
                </div>
            </div>

            <div className="media-files-card__content discount__block__content">
                <div className="media-files-card__header">
                    <div className={"discount__block__content__header"}>
                        <h5>{TextOverflow({ title, lines: 3 })}</h5>

                        <p>{discount_description}</p>
                    </div>

                    <div>
                        <div
                            className="media-files-card__favorite"
                            onClick={(e) => setAsFavorite(e)}
                        >
                            <FavoriteIcon
                                opacity={isFavorite ? 1 : 0.3}
                                color={isFavorite ? "#00A7B5" : ""}
                                strokeColor={isFavorite ? "#00A7B5" : "black"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DiscountCard;