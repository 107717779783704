import TextOverflow from "../../../utils/text-overflow/TextOverflow";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { FC, useEffect, useState } from "react";
import { getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";
import { FavoriteIcon } from "../../../img/icons/others/FavoriteIcon";
import { $toggleMediaFileFavorite } from "../../../api/requests/media-files";
import {$toggleUserFavorites} from "../../../api/requests/favorites";

export interface MediaFilesCardProps {
    id: number;
    title: string;
    description: string;
    banner: string;
    is_favourite: boolean;
    category?: any;
    status: number;
    view_count: number;
}

interface IMediaFilesCard {
    data: MediaFilesCardProps;
}

export const MediaFilesCard: FC<IMediaFilesCard> = (
    {
        data
    }) => {
    const navigate = useNavigate();

    const isMobile = isTillMd();
    const [imgSrc, setImgSrc] = useState<any>(null);

    const [isFavorite, setIsFavorite] = useState(false);

    const {
        title,
        banner: image,
        is_favourite: isLiked,
        category,
        view_count: views,
        id
    } = data;

    useEffect(() => {
        setIsFavorite(isLiked);
    }, [isLiked]);

    useEffect(() => {
        const img = new Image();
        img.src = image;

        img.onload = () => {
            setImgSrc(image);
        };

    }, [image]);

    function handleRedirectNews() {
        navigate(`/media-files/show/${id}`);
    }

    function setAsFavorite(e: any) {
        e.stopPropagation()
        setIsFavorite(!isFavorite);
        $toggleUserFavorites({type: 'mediaGallery', type_id: id, add_to_favorites: !isFavorite}, { newApi: true }).then();
    }

    return (
        <div className="media-files-card" onClick={handleRedirectNews}>
            <BlankImage image={image} />

            <div className="media-files-card__content">

                <div className="media-files-card__header">
                    <h5>{TextOverflow({ title, lines: 3 })}</h5>

                    <div className="media-files-card__favorite" onClick={(e) => setAsFavorite(e)}>
                        <FavoriteIcon
                            opacity={isFavorite ? 1 : 0.3}
                            color={isFavorite ? "#00A7B5" : ""}
                            strokeColor={isFavorite ? "#00A7B5" : "black"}
                        />
                    </div>
                </div>


                {!isMobile && (
                    <div
                        className={`media-files-card__category`}>{category ? category?.title : "Без категории"}</div>
                )}

                <div className="media-files-card__bottombar">
                    <div className="media-files-card__stats">
                        <EyeIcon />

                        <p>{views}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};