import { FC } from "react";
import { IBaseIconProps } from "../types";

export const AnalyticsNewsIcon: FC<IBaseIconProps>= (
    {
        size = 28,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 29 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.666 16V8.00001M16.666 16L22.768 19.487C22.8592 19.5392 22.9625 19.5665 23.0676 19.5662C23.1726 19.566 23.2758 19.5381 23.3667 19.4855C23.4576 19.4328 23.5331 19.3572 23.5856 19.2662C23.6382 19.1753 23.6659 19.0721 23.666 18.967V5.03301C23.6661 4.92786 23.6385 4.82455 23.586 4.73342C23.5335 4.6423 23.4581 4.56656 23.3671 4.51379C23.2762 4.46103 23.173 4.43309 23.0678 4.43277C22.9627 4.43246 22.8593 4.45978 22.768 4.51201L16.666 8.00001M16.666 16H9.66602M16.666 8.00001H9.66602C8.60515 8.00001 7.58773 8.42143 6.83759 9.17158C6.08744 9.92172 5.66602 10.9391 5.66602 12C5.66602 13.0609 6.08744 14.0783 6.83759 14.8284C7.58773 15.5786 8.60515 16 9.66602 16M9.66602 16L10.423 21.3C10.502 21.8157 10.7813 22.2793 11.2002 22.5902C11.6191 22.9011 12.1438 23.0341 12.6602 22.9603C13.1766 22.8865 13.6431 22.6119 13.9582 22.1962C14.2733 21.7804 14.4116 21.2571 14.343 20.74L13.666 16H9.66602Z"
                stroke={color} strokeWidth="2" />
        </svg>
    );
};