import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import React, { useState } from "react";
import { BlankAva } from "../avatars/BlankAva";
import AddSelectUsersModal from "./AddSelectUsersModal";

interface AddSelectUsersProps {
    title?: string;
    usersList: any[];
    setUsersList: (value: any) => void;
    isSingle?: boolean;
    disabled?: boolean;
}

const AddSelectUsers = (
    {
        title,
        usersList = [],
        setUsersList,
        isSingle,
        disabled
    }: AddSelectUsersProps
) => {
    const [modalOpen, setModalOpen] = useState(false);

    return (
        <div className="services-create-form__employee">
            {title && <p className="title mb-2">{title}</p>}

            <div className="">
                {usersList.length > 0 && (
                    <div className={"services-create-form__employees__list"}>
                        {usersList?.map((item: any, idx: number) => (
                            <div key={idx} className="layout-vacation-modal__block"  onClick={() => setModalOpen(true)}>
                                <div className="layout-vacation-modal__block__right">
                                    {item?.photo ? (
                                        <img src={item?.photo} alt="" />
                                    ) : (
                                        <BlankAva
                                            colorBg={"#CCD9E0"}
                                            colorText={"black"}
                                            text={item?.name}
                                        />
                                    )}

                                    <div className="layout-vacation-modal__block__desc">
                                        <h1>{item.name}</h1>

                                        <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                        <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {((isSingle && usersList.length > 0) || disabled) ? null : (
                    <div
                        className="services-create-form__employees__addButton w-100"
                        onClick={() => setModalOpen(true)}
                    >
                        <PlusIcon2 />
                    </div>
                )}
            </div>

            <AddSelectUsersModal
                isOpen={modalOpen}
                onClose={() => setModalOpen(false)}
                data={usersList}
                setData={setUsersList}
                isSingleSelect={isSingle}
            />
        </div>
    )
}

export default AddSelectUsers