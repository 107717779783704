export const TrashIcon = ({
    color = '#FF0000',
    size = 24,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.5 6.17647H20.5M9.5 3H15.5M16 21H9C7.89543 21 7 20.0519 7 18.8824L6.5434 7.27937C6.51973 6.67783 6.97392 6.17647 7.54253 6.17647H17.4575C18.0261 6.17647 18.4803 6.67783 18.4566 7.27937L18 18.8824C18 20.0519 17.1046 21 16 21Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
        />
    </svg>
)
