import { CloseIcon, MessengerSendIcon, PaperClipIcon } from "../../img";
import { CommentMessengerChatBox } from "./CommentMessengerChatBox";
import React, { FC, useRef, useState } from "react";
import { ICommentsMessenger } from "../../api/requests/blogs/interface";
import { isTillMd } from "../../utils";
import { $postComment } from "../../api/requests/comment";
import ToggleBlock from "../ui/form/Toggle/ToggleBlock";
import { $editIdeasNotification } from "../../api/requests/ideas";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";
import { $getUsers } from "../../api/requests/user";
import { IResponseUsers, IUsers } from "../../api/requests/user/interface";
import { API_URL } from "../../api/config";

interface CommentMessengerPros {
    id?: number;
    comments_data: ICommentsMessenger[];
    setIsLoading?: any;
    url: string;
    accept?: string;
    isNotification?: number;
    isExpert?: boolean
}

export const CommentMessenger: FC<CommentMessengerPros> = (
    {
        comments_data,
        id,
        setIsLoading,
        url,
        accept = ".png, .jpg",
        isNotification,
        isExpert
    }
) => {
    const isMobile = isTillMd();
    const { workspace_id: wsId } = useSelector(getPersonData);

    const profileData = useSelector(getPersonData);
    const [text, setText] = useState("");
    const [attachments, setAttachments] = useState<any>([]);
    const [isNotificationValue, setIsNotificationValue] = useState<number>(isNotification || 0);//notifications_comments

    const [showDropdown, setShowDropdown] = useState(false);
    const [usersList, setUsersList] = useState<IUsers[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const taggedUsers = useRef<IUsers[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setText(value);

        const caret = e.target.selectionStart;

        const lastWord = value.slice(0, caret).split(" ").pop();
        if (lastWord?.startsWith("@")) {
            const query = lastWord.slice(1); // Remove "@"
            if (query) {
                fetchSuggestions(query);
            }
            setShowDropdown(true);
            setCursorPosition(caret);
        } else {
            setShowDropdown(false);
        }
    };

    const generateHTML = () => {
        let formattedText = text;

        taggedUsers.current.forEach((user) => {
            const tagPattern = new RegExp(`@${user.name}`, "g");
            const userLink = `<a href="https://itelma-dev.svoi.club/user?workspace_id=${wsId}&amp;user_id=${user.id}"><span class="hashtag">@${user.name}</span></a>`;
            formattedText = formattedText.replace(tagPattern, userLink);
        });

        return `<p>${formattedText}</p>`;
    };

    const fetchSuggestions = async (query: string) => {
        if (wsId) {
            $getUsers({ id: wsId, search: query }, { formData: true }).then(res => {
                if (!res.users) return;

                setUsersList(res.users)
            });
        }
    };

    const handleOptionSelect = (user: IUsers) => {
        if (textareaRef.current && cursorPosition !== null) {
            const value = text;
            const beforeCursor = value.slice(0, cursorPosition).split(" ").slice(0, -1).join(" ");
            const afterCursor = value.slice(cursorPosition);

            const newValue = `${beforeCursor} @${user.name} ${afterCursor}`.trim();
            setText(newValue);

            taggedUsers.current.push(user);
            setShowDropdown(false);
            textareaRef.current.focus();
        }
    };

    const handlePostComment = () => {
        const htmlContent = generateHTML();

        setIsLoading(true);
        if (id) {
            $postComment({
                id,
                text: htmlContent,
                attachments
            }, `${url}/createcomment`, { formData: true }).then(res => {
                setIsLoading(false);
                setText("");
                setAttachments([]);
            });
        }
    };

    const handleGetCommentImages = ({ target }: any) => {
        const file = target?.files[0];

        if (file) {
            // const imageURL = URL.createObjectURL(file);
            setAttachments([...attachments, file]);
        }
    };

    const handleDeleteCommentImages = (index: number) => {
        const updatedList = attachments.filter((
            item: any,
            idx: number
        ) => idx !== index);

        setAttachments(updatedList);
    };

    function handleNotification() {
        const updateNot = isNotificationValue === 1 ? 0 : 1

        const requestData = {
            id,
            on: updateNot
        }

        $editIdeasNotification(requestData, { formData: true }).then(res => {
            if (!res) return

            setIsNotificationValue(updateNot)
        })
    }

    return (
        <div className="news-messenger">
            <div className="news-messenger__top">
                {!isMobile && (
                    <>
                        {profileData?.photo ? (
                            <img className="news-messenger__top__avatar"
                                 src={profileData?.photo} alt="" />
                        ) : (
                            <div className="news-messenger__top__avatar">
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={profileData?.name}
                                />
                            </div>
                        )}
                    </>
                )}

                <div className="news-messenger__top__textarea__right">
                    <div className="news-messenger__top__textarea">
                        <textarea
                            // type="text"
                            // multiple
                            ref={textareaRef}
                            value={text}
                            onChange={handleInputChange}
                            placeholder="Оставить комментарий"
                            className="news-messenger__top__input"
                            // onChange={(e) => setText(e.target.value)}
                        />

                        <div className="news-messenger__top__textarea__buttons">
                            <label className="news-messenger__top__textarea__buttons__imageButton">
                                <PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />

                                <input
                                    type="file"
                                    accept={accept}
                                    onChange={handleGetCommentImages}
                                    hidden
                                />
                            </label>

                            {isMobile ? (
                                <MessengerSendIcon />
                            ) : (
                                <div className="news-messenger__top__textarea__buttons__enterButton"
                                     onClick={handlePostComment}>
                                    Опубликовать
                                </div>
                            )}
                        </div>

                        {showDropdown && usersList.length > 0 && (
                            <div className='news-messenger__top__textarea__users'>
                                {usersList.map((item, idx) => (
                                    <div  className="layout-vacation-modal__block__right"
                                          onClick={() => handleOptionSelect(item)}
                                          key={idx}
                                    >
                                        {item?.photo ? (
                                            <img src={item?.photo} alt="" />
                                        ) : (
                                            <BlankAva
                                                colorBg={"#CCD9E0"}
                                                colorText={"black"}
                                                text={item?.name}
                                            />
                                        )}

                                        <div className="layout-vacation-modal__block__desc">
                                            <h1>{item?.name}</h1>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>


                    <div className="news-messenger__top__textarea__attachments">
                        {attachments?.map((
                            item: any,
                            idx: number
                        ) => (
                            <div className="news-messenger__top__textarea__attachments__item"
                                 key={idx}>
                                <div className="closeIcon"
                                     onClick={() => handleDeleteCommentImages(idx)}>
                                    <CloseIcon color={"#FF0000"} />
                                </div>

                                <p>
                                    {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}
                                </p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {(isNotification || typeof isNotification === "number") && (
                <ToggleBlock
                    isChecked={ isNotificationValue === 1 }
                    className="ideas__create__form-toggle"
                    desc="Сообщать о новых коментариях"
                    handleChange={ handleNotification }
                />
            )}

            {id && (
                <CommentMessengerChatBox
                    comments_data={comments_data}
                    item_id={id}
                    url={url}
                    setIsLoading={setIsLoading}
                    isExpert={isExpert}
                />
            )}
        </div>
    );
};