import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { ReactNode, useEffect, useState } from "react";
import {
    BlogIcon,
    CloseIcon,
    ContestIcon,
    InfoIcon,
    InterviewsIcon,
    MenuIcon,
    NewsIcon, PenIcon
} from "../../../img";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import { $editGroup } from "../../../api/requests/groups";
import { useSelector } from "react-redux";
import { getCurrentGroupData } from "../../../redux/reducers/workplace/workplaceSlice";
import Button from "../../../components/ui/form/Buttons/Button";
import CheckIcon from "../../../img/icons/vacation/CheckIcon";

export interface StateData {
    key: string;
    name: string;
    url: string;
    value: number;
    icon: ReactNode;
}

const WorkspaceSettingGroupModules = () => {
    const currentGroup = useSelector(getCurrentGroupData);

    const [state, setState] = useState<StateData[]>([
        {
            name: "Новости",
            url: "/news",
            icon: <NewsIcon />,
            key: "news",
            value: 0,
        },
        {
            name: "Информация",
            url: "/pages",
            icon: <InfoIcon />,
            key: "pages",
            value: 0,
        },
        {
            name: "Конкурсы",
            url: "/contests",
            icon: <ContestIcon />,
            key: "contests",
            value: 0,
        },
        {
            name: "Опросы",
            url: "/interviews",
            icon: <InterviewsIcon />,
            key: "interviews",
            value: 0,
        },
        {
            name: "Блог",
            url: "/posts",
            icon: <BlogIcon />,
            key: "posts",
            value: 0,
        },
    ]);
    const [selectItem, setSelectItem] = useState<StateData | null>(null);

    const transformArrayToObject = (arr: StateData[]): Record<string, number> => {
        return arr.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {} as Record<string, number>);
    };

    useEffect(() => {
        if (currentGroup) {
            const updateList = [...state].map(item => {
                if (currentGroup[item.key]) return {
                    ...item,
                    value: +currentGroup[item.key]
                }

                return item
            });

            setState(updateList);
        }
    }, [currentGroup]);

    function changeCheckboxes(select: any) {
        const updateList = [...state].map(item => {
            if (item.key === select.key) return {
                ...item,
                value: select.value ? 0 : 1
            }

            return item
        });

        const requestData: any = {
            id: currentGroup.id,
            ...transformArrayToObject(updateList)
        };

        $editGroup(requestData, { formData: true }).then(res => {
            setState(updateList);
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'modules') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEdit(targetIndex, deletedItem, workValue);
        }
    }

    function handleEdit(targetIndex: number, deletedItem: any, workValue: any[]) {
        const requestData: any = {
            id: currentGroup.id,
            key: deletedItem.key,
            sort: targetIndex
        };

        console.log(requestData);

        setState(workValue);
        // $editGroup(requestData, { formData: true }).then(res => {
        //     setState(workValue);
        // });
    }

    function handleEditValue(select: StateData | null) {
        setSelectItem(select);
    }

    function handleSaveEditValue() {
        const findItem = state.find(item => item.key === selectItem?.key)

        if (findItem && findItem.name === selectItem?.name) {
            handleEditValue(null);

            return;
        }

        const updateList = [...state].map(item => {
            if (item.key === selectItem?.key) return {
                ...item,
                name: selectItem.name
            }

            return item
        });

        const requestData: any = {
            id: currentGroup.id,
            name: selectItem?.name
        };

        $editGroup(requestData, { formData: true }).then(res => {
            setState(updateList);
            handleEditValue(null);
        });
    }

    function handleChangeSelect(event: any) {
        if (!selectItem) return;

        const updateObj: StateData = {
            ...selectItem,
            name: event?.target.value,
        }

        console.log(updateObj, 'updateObj');

        setSelectItem(updateObj)
    }

    return (
        <>
            {/*<DragDropContext onDragEnd={onDragEnd}>*/}
            {/*    <Droppable droppableId='list' type="modules">*/}
            {/*        {(provided) => (*/}
            {/*            <div ref={provided.innerRef} {...provided.droppableProps} className={"workspace-settings-group__modules"}>*/}
            {/*                {state.map((item, index) => (*/}
            {/*                    <div key={`${item.key}-item-${index}`} className={"menuBlock"}>*/}
            {/*                        <Draggable draggableId={`${item.key}-item-${index}`} index={index}>*/}
            {/*                            {(provided) => (*/}
            {/*                                <div*/}
            {/*                                    ref={provided.innerRef}*/}
            {/*                                    {...provided.draggableProps}*/}
            {/*                                    {...provided.dragHandleProps}*/}
            {/*                                    className={"drag-and-drop__block workspace-settings-group__modules-block"}*/}
            {/*                                >*/}
            {/*                                    <div className={"w-100 justify-content-between"}>*/}
            {/*                                        <div>*/}
            {/*                                            <MenuIcon color={"#00000080"} />*/}

            {/*                                            {item.icon}*/}

            {/*                                            <p>{item.name}</p>*/}
            {/*                                        </div>*/}

            {/*                                        <ToggleBlock*/}
            {/*                                            desc={""}*/}
            {/*                                            isChecked={!!item.value}*/}
            {/*                                            handleChange={() => changeCheckboxes(item)}*/}
            {/*                                        />*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            )}*/}
            {/*                        </Draggable>*/}
            {/*                    </div>*/}
            {/*                ))}*/}
            {/*                {provided.placeholder}*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </Droppable>*/}
            {/*</DragDropContext>*/}

            <div className={"workspace-settings-group__modules"}>
                {state.map((item, index) => (
                    <div
                        key={`${item.key}-item-${index}`}
                        className={"drag-and-drop__block workspace-settings-group__modules-block"}
                    >
                        <div className={"w-100 justify-content-between"}>
                            <div>
                                {/*<MenuIcon color={"#00000080"} />*/}

                                {item.icon}

                                {(selectItem?.key === item.key) ? (
                                    <input
                                        value={selectItem.name}
                                        className={"workspace-settings-group__modules-field"}
                                        onChange={handleChangeSelect}
                                        autoFocus
                                    />
                                ) : (
                                    <p>{item.name}</p>
                                )}
                            </div>

                            {(selectItem?.key === item.key) ? (
                                <div className={""}>
                                    <Button
                                        text={<CheckIcon size={26} color={"white"} />}
                                        className={"btn-check"}
                                        onClick={handleSaveEditValue}
                                    />

                                    <Button
                                        text={<CloseIcon
                                            width={14}
                                            height={14}
                                            color={"#FF0000"}
                                        />}
                                        className={"btn-icon"}
                                        onClick={() => handleEditValue(null)}
                                    />
                                </div>
                            ) : (
                                <div className={""}>
                                    <Button
                                        text={<PenIcon />}
                                        className={"btn-icon"}
                                        onClick={() => handleEditValue(item)}
                                    />

                                    <ToggleBlock
                                        className={"gap-0"}
                                        desc={""}
                                        isChecked={!!item.value}
                                        handleChange={() => changeCheckboxes(item)}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default WorkspaceSettingGroupModules;