import { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getPersonData } from '../../redux'

export const PersonalFieldsBlock = () => {
    const personalData = useSelector(getPersonData)
    
    const [groups, setGroups] = useState<any>([])
    
    useEffect(() => {
        if (personalData?.field_groups) {
            setGroups(personalData?.field_groups)
        }
    }, [personalData])

    return (
        <div className="layout-right-block">
            {groups &&
                groups.map((item: any, idx: number) => (
                    item.names ?
                    <Fragment key={`data-profile-groups-${idx}`}>
                        <div
                            className="layout-right-block__header"
                        >
                            <h1 className="layout-right-block__header__title">
                                {item.title}
                            </h1>
                        </div>

                        <div className="profile-work">
                            <div className="profile-work__content">
                                {item.names &&
                                    item.names.map((item: any, idx: number) => (
                                        <div
                                            key={`data-profile-fields-${idx}`}
                                            className="profile-work__block"
                                        >
                                            <p className="profile-work__block__placeholder">
                                                {item.name}
                                            </p>

                                            <h1 className="profile-work__block__value">
                                                {item.value ? item.value : 'Не указано'}
                                            </h1>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Fragment>
                    : null
                ))}
        </div>
    )
}
