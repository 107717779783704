import Button from "../../components/ui/form/Buttons/Button";
import React, { useEffect, useState } from "react";
import { PencilIcon } from "../../img/icons/PencilIcon";
import { PlusIcon2, UserProfileIcon } from "../../img";
import { TrashIcon } from "../../img/icons/TrashIcon";
import { LayerIcon } from "../../img/icons/LayerIcon";
import WorkspaceAddOffice, {
    daysList
} from "../../components/workspace/booking/WorkspaceAddOffice";
import {
    getIsOpenCreateBanner,
    setIsOpenCreateBanner, setToAddFolder
} from "../../redux/reducers/workplace/workplaceSlice";
import { useDispatch, useSelector } from "react-redux";
import WorkspaceAddFloor from "../../components/workspace/booking/WorkspaceAddFloor";
import WorkspaceAddMeeting from "../../components/workspace/booking/WorkspaceAddMeeting";
import {
    $deleteRoomBooking,
    $getMeetBookingById
} from "../../api/requests/workspace-settings/booking";
import {
    getStructure
} from "../../redux/reducers/workplace-management/workplaceManagementSlice";
import { findTime } from "../../utils/date/hourdsTime";

const WorkspaceSettingOfficeManager = () => {
    const dispatch = useDispatch();

    const isNewBlock = useSelector(getIsOpenCreateBanner);
    const idMeet = useSelector(getStructure);

    const [buildingListData, setBuildingListData] = useState([
        {
            title: "Город",
            value: "",
            key: "city",
        },
        {
            title: "Адрес",
            value: "",
            key: "address"
        },
        {
            title: "Дни работы",
            value: "",
            key: "is_open_on"
        },
        {
            title: "Время работы",
            value: "",
            key: "time"
        }
    ]);
    const [buildingData, setBuildingData] = useState<any>(null);

    const [buildingSelectData, setBuildingSelectData] = useState<any>(null);
    const [meetSelectData, setMeetSelectData] = useState<any>(null);
    const [floorSelectData, setFloorSelectData] = useState<any>(null);

    const [floorListData, setFloorListData] = useState<any[]>([]);

    const [meetFloorList, setMeetFloorList] = useState<any[]>([]);

    const [isOpenFloorModal, setIsOpenFloorModal] = useState(false);
    const [isOpenMeetingModal, setIsOpenMeetingModal] = useState(false);

    useEffect(() => {
        idMeet && init();
    }, [idMeet]);

    function init() {
        if (!idMeet) return;

        $getMeetBookingById(idMeet).then(res => {
            if (!res?.data) return;

            const updateBuildingList = [...buildingListData].map(item => {
                if (item.key === "city") return {
                    ...item,
                    value: res?.data?.city?.name
                }

                if (item.key === "is_open_on") {
                    const filterList = daysList.filter(i => res.data[i.name]);
                    const updateList = [...filterList].map((i, idx) => (filterList.length - 1) === idx
                        ? i.label : (`${i.label}, `));

                    return {
                        ...item,
                        value: updateList
                    }
                }

                if (item.key === "time") return {
                    ...item,
                    value: `${findTime(res.data.start_time)?.time} - ${findTime(res.data.end_time)?.time}`
                }


                if (item.key && res.data[item.key]) return {
                    ...item,
                    value: res.data[item.key]
                }

                return item
            })

            setBuildingListData(updateBuildingList);

            setBuildingData(res.data);
            setFloorListData(res.data.floors);
            setMeetFloorList(res.data.floors);
        })
    }

    function handleEditBuilding() {
        setBuildingSelectData(buildingData);
        dispatch(setIsOpenCreateBanner(true));
    }

    function handleRemove(building: any, floor: any, meet: any) {
        if (!building.id || !floor.id || !meet.id) return;

        $deleteRoomBooking(building.id, floor.id, meet.id).then(res => {
            init();
        })
    }

    return (
        <div className={"h-100"}>
            {idMeet ? (
                <div className={"workspace-settings__meeting"}>
                    <div className={"workspace-settings__meeting__item"}>
                        {buildingListData.map((item, idx) => (
                            <div
                                key={`main-item-${idx}`}
                                className={"workspace-settings__meeting__item-block"}
                            >
                                <p className={"workspace-settings__meeting__item-block-title"}>
                                    {item.title}
                                </p>

                                <p>{item.value}</p>
                            </div>
                        ))}

                        <Button
                            className="btn-icon"
                            text={<PencilIcon />}
                            onClick={handleEditBuilding}
                        />
                    </div>

                    <div>
                        <h3>Этажи</h3>

                        <div className={"workspace-settings__meeting__items"}>
                            {!!floorListData?.length && floorListData.map((item, index) => (
                                <div
                                    key={`floor-item-data-${index}`}
                                    className={"workspace-settings__meeting__item"}
                                >
                                    <div className={"workspace-settings__meeting__item-block"}>
                                        <p className={"workspace-settings__meeting__item-block-title"}>
                                            Этаж
                                        </p>

                                        <p>{item.number}</p>
                                    </div>

                                    <div className={"workspace-settings__meeting__item-block"}>
                                        <p className={"workspace-settings__meeting__item-block-title"}>
                                            Кол-во рабочих мест
                                        </p>

                                        <p>{item.workplace_count}</p>
                                    </div>

                                    <div className={"workspace-settings__meeting__item-block"}>
                                        <p className={"workspace-settings__meeting__item-block-title"}>
                                            Кол-во переговорных
                                        </p>

                                        <p>{item.rooms_count}</p>
                                    </div>

                                    <Button
                                        className="btn-icon"
                                        text={<PencilIcon />}
                                        onClick={() => {
                                            setBuildingSelectData(buildingData);
                                            setFloorSelectData(item);
                                            setIsOpenFloorModal(true);
                                        }}
                                    />
                                </div>
                            ))}

                            <Button
                                text={<PlusIcon2 size={32} color={"#00A7B5"} />}
                                className={"btn btn-primary-revert w-100"}
                                onClick={() => {
                                    setIsOpenFloorModal(true);
                                    setBuildingSelectData(buildingData);
                                }}
                            />
                        </div>
                    </div>

                    <div className={"h-100"}>
                        <h3>Переговорные</h3>

                        {!!meetFloorList?.length ? meetFloorList.map((item, idx) => (
                            <div
                                className={"workspace-settings__meeting__items mb-3 pb-1"}
                                key={`meet-floor-item-${idx}`}
                            >
                                <h5>{item.number} Этаж</h5>

                                <div className={"workspace-settings__meeting__blocks"}>
                                    <div className={"row"}>
                                        <div className={"col-md-4 col-6 workspace-settings__meeting-meet-col"}>
                                            <Button
                                                text={<PlusIcon2 size={32} color={"#00A7B5"} />}
                                                className={"btn btn-primary-revert w-100 h-100"}
                                                onClick={() => {
                                                    setBuildingSelectData(buildingData);
                                                    setFloorSelectData(item);
                                                    setIsOpenMeetingModal(true);
                                                }}
                                            />
                                        </div>

                                        {item?.rooms && item.rooms.map((_item: any, _idx: number) => (
                                            <div
                                                key={`main-item-${idx}-${_idx}`}
                                                className={"col-md-4 col-6 workspace-settings__meeting-meet-col"}
                                            >
                                                <div
                                                    className={"workspace-settings__meeting-meet-block"}>
                                                    <div
                                                        className={"d-flex align-items-center justify-content-between gap-3"}>
                                                        <h5>{_item.name}</h5>

                                                        <div className={"d-flex gap-4"}>
                                                            <Button
                                                                className="btn-icon"
                                                                text={<PencilIcon />}
                                                                onClick={() => {
                                                                    setBuildingSelectData(buildingData);
                                                                    setFloorSelectData(item);
                                                                    setMeetSelectData(_item);
                                                                    setIsOpenMeetingModal(true);
                                                                }}
                                                            />
                                                            <Button
                                                                className="btn-icon"
                                                                text={<TrashIcon />}
                                                                onClick={() => handleRemove(buildingData, item, _item)}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className={"d-flex gap-3"}>
                                                        <div className={"d-flex gap-3 w-50"}>
                                                            <LayerIcon />

                                                            <p>Этаж: {item.number}</p>
                                                        </div>

                                                        <div className={"d-flex gap-3 w-50"}>
                                                            <UserProfileIcon
                                                                size={20}
                                                                color={"#00A7B5"}
                                                            />

                                                            <p>Мест: {_item.workplace_count}</p>
                                                        </div>
                                                    </div>

                                                    <div
                                                        className={"workspace-settings__meeting-meet-bottom"}
                                                    >
                                                        <p>{_item.periphery}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <div className={"workspace-settings__meeting-empty"}>
                                <p>
                                    Для начала добавьте этаж
                                </p>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div className={"workspace-settings__meeting-empty"}>
                    <p>
                        Выберите офис чтобы смотреть или <br/>
                        редактировать информацию об офисе
                    </p>
                </div>
            )}

            <WorkspaceAddOffice
                data={buildingSelectData}
                isOpen={isNewBlock}
                onClose={(notUpdate: boolean) => {
                    setBuildingSelectData(null);
                    dispatch(setToAddFolder(true));
                    dispatch(setIsOpenCreateBanner(false));
                    !notUpdate && init();
                }}
                setIsOpen={() => {
                    setBuildingSelectData(null);
                    dispatch(setIsOpenCreateBanner(false));
                }}
            />

            <WorkspaceAddFloor
                building={buildingSelectData}
                floor={floorSelectData}
                isOpen={isOpenFloorModal}
                onClose={() => {
                    setIsOpenFloorModal(false);
                    setBuildingSelectData(null);
                    setFloorSelectData(null);
                    init();
                }}
                setIsOpen={() => {
                    setIsOpenFloorModal(false);
                    setBuildingSelectData(null);
                    setFloorSelectData(null);
                }}
            />

            <WorkspaceAddMeeting
                building={buildingSelectData}
                floor={floorSelectData}
                meet={meetSelectData}
                isOpen={isOpenMeetingModal}
                onClose={() => {
                    setIsOpenMeetingModal(false);
                    setBuildingSelectData(null);
                    setFloorSelectData(null);
                    setMeetSelectData(null);
                    init();
                }}
                setIsOpen={() => {
                    setIsOpenMeetingModal(false);
                    setBuildingSelectData(null);
                    setFloorSelectData(null);
                    setMeetSelectData(null);
                }}
            />
        </div>
    )
}

export default WorkspaceSettingOfficeManager;