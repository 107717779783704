import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getPersonData,
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { EventsCalendarUser } from "./EventsCalendarUser";

export const EventsCalendarUsersCalendarList: FC<any> = (
    {
        expand,
        handleExpand,
        list,
    }
) => {
    const dispatch = useDispatch();

    const currentUser = useSelector(getPersonData);

    const [page, setPage] = useState(1);
    const [isLoadFirstPage, setIsLoadFirstPage] = useState(true);
    const [eventsList, setEventsList] = useState<any[]>([]);

    const handlePage = (value: number) => {
        setPage(value);

        setIsLoadFirstPage(true);
    };

    useEffect(() => {
        if ((page === 1) && !isLoadFirstPage) return;

        setIsLoadFirstPage(false);

        getRequestBooking();
    }, [page]);

    useEffect(() => {
        getRequestBooking();
    }, [list]);

    function getRequestBooking() {
        setEventsList(list);

        // setPage(res.current_page);
    }

    return (
        <div
            className={"vacation__content__left__calendar-block"}
        >
            <div className={"vacation__content__left__calendar-list"}>
                {eventsList && eventsList.map((item: any, index: number) => {
                    return (
                        <EventsCalendarUser
                            key={`event-calendar-item-${item?.id || index}`}
                            panel={item?.id}
                            expand={expand}
                            handleExpand={handleExpand}
                            data={item}
                        />
                    );
                })}
            </div>

            {/*{userData?.total > userData?.per_page && (*/}
            {/*    <div className={"vacation__content__left__calendar-pagination-wrap"}>*/}
            {/*        <Pagination*/}
            {/*            // className={"vacation__content__left__calendar-pagination"}*/}
            {/*            pages={userData?.last_page}*/}
            {/*            activePage={page}*/}
            {/*            setActivePage={handlePage}*/}
            {/*            // disabled={isBookingLoading}*/}
            {/*        />*/}
            {/*    </div>*/}
            {/*)}*/}
        </div>
    );
};
