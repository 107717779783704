export const vacationColors = [
    '#3AC4FF4D',
    '#00A7B54D',
    '#00B5534D',
];

export const vacationHoverColors = [
    '#3AC4FF4D',
    '#00A7B54D',
    '#00B5534D',
];

export const vacationStatus: any = {
    '-1': 'Не согласовано',
    0: 'Отпуск',
    1: 'Согласование',
    2: 'Согласовано',
};

export const vacationStatusColor: any = {
    '-1': '#FF6363',
    0: '#00C814',
    1: '#00A7B5',
    2: '#00A7B5',
};
