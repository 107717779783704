import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getDepartment,
    getMonth,
    setBookingLoading,
    getBookingList
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { VacationUser } from "./VacationUser";

export const VacationUsersCalendarList: FC<any> = (
    {
        isAdmin,
        expand,
        handleExpand,
        filter
    }
) => {
    const dispatch = useDispatch();
    const department = useSelector(getDepartment);
    const month = useSelector(getMonth);
    const userList = useSelector(getBookingList);

    const [page, setPage] = useState(1);
    const [isBookingLoading, setIsBookingLoading] = useState(false);
    const [isLoadFirstPage, setIsLoadFirstPage] = useState(false);
    const [bookingUsersList, setBookingUsersList] = useState<any[]>([]);
    const [userData, setUserData] = useState<any>({});

    const handlePage = (value: number) => {
        setPage(value);

        setIsLoadFirstPage(true);
    };

    useEffect(() => {
        if (isBookingLoading || ((page === 1) && !isLoadFirstPage)) return;

        setIsLoadFirstPage(false);
        setIsBookingLoading(true);

        userList && getRequestBooking();
    }, [page, userList]);

    useEffect(() => {
        if (userList) {
            const filterUserList = [...userList].filter(item => item.user.name.toLowerCase().includes(filter.toLowerCase()))

            setBookingUsersList(filterUserList)
        }
    }, [filter]);

    useEffect(() => {
        if (isBookingLoading) return;

        if (page === 1 && userList) return getRequestBooking();

        setPage(1);
    }, [department, month, userList]);

    useEffect(() => {
        dispatch(setBookingLoading(isBookingLoading));
    }, [isBookingLoading]);

    function getRequestBooking() {
        setBookingUsersList(userList);
        setIsBookingLoading(false);
    }

    return (
        <div
            className={"vacation__content__left__calendar-block"}
        >
            {isBookingLoading ? (
                <div className={"vacation__content__left__calendar-block"}>
                    {[1,2,3,4,5].map((_, index) => (
                        <Skeleton key={`vacation-skeleton-item-${index}`} />
                    ))}
                </div>
            ) : (
                <>
                    <div className={"vacation__content__left__calendar-head"}>
                        <div className={"vacation__content__left__calendar-label"}>В календаре</div>

                        {/*<div*/}
                        {/*    className={"vacation__content__left__calendar-count"}*/}
                        {/*    style={{*/}
                        {/*        color: vars.colors.fourthColor,*/}
                        {/*        backgroundColor: vars.colors.secondaryColor,*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {userData?.total || 0}*/}
                        {/*</div>*/}
                    </div>

                    <div className={"vacation__content__left__calendar-list"}>
                        {!!bookingUsersList.length ? bookingUsersList.map((item: any, index: number) => {
                            return (
                                <VacationUser
                                    key={`vacation-user-item-${index}`}
                                    panel={item?.id}
                                    expand={expand}
                                    handleExpand={handleExpand}
                                    indicator={index}
                                    data={item}
                                />
                            );
                        }) : <div className={"accordion__wrapper"}>Пустой список</div>}
                    </div>

                    {/*{userData?.total > userData?.per_page && (*/}
                    {/*    <div className={"vacation__content__left__calendar-pagination-wrap"}>*/}
                    {/*        <Pagination*/}
                    {/*            // className={"vacation__content__left__calendar-pagination"}*/}
                    {/*            pages={userData?.last_page}*/}
                    {/*            activePage={page}*/}
                    {/*            setActivePage={handlePage}*/}
                    {/*            // disabled={isBookingLoading}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*)}*/}
                </>
            )}
        </div>
    );
};
