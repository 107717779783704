import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

export interface policeInitialStateType {
    bookingList: any;
    bookingSelectData: any;
    floorsList: any[];
    floorsIsActiveList: any[];
    choosingNewMeetingRoom: any;
    isOpenBookingModal: boolean;
    isOpenBookingActionModal: boolean;
    modalData: any;
    modalActionData: any;
    modalSelectsOffice: {
        isOpen: boolean;
        city?: any;
        office?: any;
        officeList?: any[];
    };
    initialViewCalendar: string;
    getCurrentDayCalendar: string;
    isShowContentLeft: boolean;
    isSelectMeeting: boolean;
    isBooking: boolean;
}

const initialState: policeInitialStateType = {
    bookingList: {},
    floorsList: [],
    floorsIsActiveList: [],
    bookingSelectData: {},
    isOpenBookingModal: false,
    isOpenBookingActionModal: false,
    choosingNewMeetingRoom: {},
    modalData: {},
    modalActionData: {},
    modalSelectsOffice: {
        isOpen: false,
        city: {},
        office: {},
        officeList: []
    },
    initialViewCalendar: "timelineDay",
    getCurrentDayCalendar: "",
    isShowContentLeft: true,
    isSelectMeeting: false,
    isBooking: false
};

const bookingRoomsSlice = createSlice({
    name: "bookingRoomsData",
    initialState,
    reducers: {
        setBookingData: (state, action: PayloadAction<any>) => {
            state.bookingList = action.payload;
        },
        setBookingFloorsData: (state, action: PayloadAction<any[]>) => {
            state.floorsList = action.payload;
        },
        setBookingFloorsIsActiveData: (state, action: PayloadAction<any[]>) => {
            state.floorsIsActiveList = action.payload;
        },
        setBookingSelectData: (state, action: PayloadAction<any>) => {
            state.bookingSelectData = action.payload;
        },
        onClickOpenBookingModal: (state, action: PayloadAction<any>) => {
            state.isOpenBookingModal = !state.isOpenBookingModal;

            state.modalData = action.payload;
        },
        onClickOpenBookingActionModal: (state, action: PayloadAction<any>) => {
            state.isOpenBookingActionModal = !state.isOpenBookingActionModal;

            state.modalActionData = action.payload;
        },
        onClickChangeMeeting: (state, action: PayloadAction<any>) => {
            state.choosingNewMeetingRoom = action.payload;
        },
        onClickChangeDropdownMeeting: (state, action: PayloadAction<any>) => {
            state.isSelectMeeting = action.payload;
        },
        setUpdateModalSelectsOffice: (state, action: PayloadAction<any>) => {
            state.modalSelectsOffice = {
                isOpen: action.payload.isOpen || false,
                city: action.payload.city || {},
                office: action.payload.office || {},
                officeList: action.payload.officeList || []
            };
        },
        setInitialViewCalendar: (state, action: PayloadAction<any>) => {
            state.initialViewCalendar = action.payload;
        },
        setCurrentDayCalendar: (state, action: PayloadAction<any>) => {
            state.getCurrentDayCalendar = action.payload;
        },
        setShowContentLeft: (state, action: PayloadAction<any>) => {
            state.isShowContentLeft = action.payload;
        },
        setIsBooking: (state, action: PayloadAction<any>) => {
            state.isBooking = action.payload;
        }
    }
});

export const {
    setBookingData,
    setBookingFloorsData,
    setBookingFloorsIsActiveData,
    setBookingSelectData,
    onClickOpenBookingModal,
    onClickOpenBookingActionModal,
    onClickChangeMeeting,
    setUpdateModalSelectsOffice,
    setInitialViewCalendar,
    setCurrentDayCalendar,
    setShowContentLeft,
    onClickChangeDropdownMeeting,
    setIsBooking
} = bookingRoomsSlice.actions;

export const getBookingData = (state: RootState) => state.bookingRooms.bookingList;
export const getBookingFloorsData = (state: RootState) => state.bookingRooms.floorsList;
export const getBookingFloorsIsActiveData = (state: RootState) => state.bookingRooms.floorsIsActiveList;
export const getBookingSelectData = (state: RootState) => state.bookingRooms.bookingSelectData;
export const getBookingIsOpenModal = (state: RootState) => state.bookingRooms.isOpenBookingModal;
export const getBookingActionIsOpenModal = (state: RootState) =>
    state.bookingRooms.isOpenBookingActionModal;
export const getChoosingNewMeetingRoom = (state: RootState) => state.bookingRooms.choosingNewMeetingRoom;
export const getIsSelectMeeting = (state: RootState) => state.bookingRooms.isSelectMeeting;
export const getBookingModalData = (state: RootState) => state.bookingRooms.modalData;
export const getBookingActionModalData = (state: RootState) => state.bookingRooms.modalActionData;
export const getModalSelectsOffice = (state: RootState) => state.bookingRooms.modalSelectsOffice;
export const getInitialViewCalendar = (state: RootState) => state.bookingRooms.initialViewCalendar;
export const getCurrentDayCalendar = (state: RootState) => state.bookingRooms.getCurrentDayCalendar;
export const getShowContentLeft = (state: RootState) => state.bookingRooms.isShowContentLeft;
export const getIsBooking = (state: RootState) => state.bookingRooms.isBooking;

export default bookingRoomsSlice.reducer;
