import { isLg } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
    getPersonData,
    getStructureCompany,
    getStructureTopHeight,
    setStructureCompany,
    setStructureCurrentTopHeight
} from "../../../redux";
import React, { useEffect, useState } from "react";
import StructureContentGrid from "./StructureGrid";
import {
    $getStructure,
    $getStructureSearch,
    $getStructureUser
} from "../../../api/requests/structure";
import { useLocation } from "react-router-dom";
import { withDebounce } from "../../../functions";
import StructureNav from "./StructureNav";

interface StructureContentProps {
    departmentId: number | null;
    setDepartmentVacation: any;
    handleClosePopup: () => void;
}

const StructureContent = (
    {
        departmentId,
        setDepartmentVacation,
        handleClosePopup
    }: StructureContentProps
) => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const is_admin = !!searchParams.get("is_admin");
    const searchProps = searchParams.get("search");

    const isTablet = isLg();

    const dispatch = useDispatch();
    const currentUser = useSelector(getPersonData);
    const structureCompany: any = useSelector(getStructureCompany);
    const top = useSelector(getStructureTopHeight);

    const isAdmin = currentUser?.workspace_role?.role === 1;
    const requestAdmin = isAdmin || is_admin;

    const [isLoadingMain, setLoadingMain] = useState<any>(true);
    const [department, setDepartment] = useState<any>(departmentId);
    const [searchDepartment, setSearchDepartment] = useState<any>(null);
    const [user, setUser] = useState<any>("");
    const [search, setSearch] = useState<any>("");
    const [focus, setFocus] = useState<any>(false);

    const handleSort = (a: any, b: any, sort: any[]) => {
        return sort?.length > 0 &&
            sort?.indexOf(a?.inner_id || a?.department?.id || a?.id) -
            sort?.indexOf(b?.inner_id || b?.department?.id || b?.id);
    };

    useEffect(() => {
        setLoadingMain(true);

        if (departmentId) setDepartment(departmentId);
    }, [departmentId]);

    useEffect(() => {
        setLoadingMain(true);
        withDebounce(() => setSearch(searchProps || ""));
    }, [searchProps]);

    useEffect(() => {
        if (department) handleGetStructure();
    }, [department]);

    useEffect(() => {
        setFocus(false);

        if (search) {
            getStructureBySearch(search);
        }

        if (search === "" && department) {
            handleGetStructure();
        }
    }, [search]);

    useEffect(() => {
        if (currentUser) {
            // setLoadingMain(true);
            //
            // setSearch(null);
            // // setDepartment(null);
            //
            // setUser(currentUser?.inner_id);
        }
    }, [currentUser]);

    useEffect(() => {
        if (user) {
            getStructureUser();
        }
    }, [user]);

    function getStructureBySearch(value: string) {
        $getStructureSearch({
                is_admin: requestAdmin,
                department_id: department || searchDepartment,
                search: value
            }
        ).then(res => {
            let timeout: any;

            if (res) {
                const childArray = res?.data?.team?.staff?.map((staff: any) => {
                    return {
                        ...staff,
                        isSearch: true
                    };
                });

                const uniqueArray = childArray?.filter((item: any, index: number) => {
                    return (
                        index ===
                        childArray?.findIndex((arrEl: any) => {
                            return JSON.stringify(arrEl) === JSON.stringify(item);
                        })
                    );
                });

                const updateArray = uniqueArray ? [uniqueArray] : [];

                dispatch(
                    setStructureCompany({
                        structure: updateArray
                    })
                );

                timeout = setTimeout(() => {
                    // @ts-ignore
                    document.getElementById("grid").scroll({
                        left: isTablet ? 0 : document.getElementById("grid")?.scrollWidth,
                        behavior: "smooth"
                    });
                }, 100);

                setLoadingMain(false);
                setFocus(true);
            }

            return () => clearTimeout(timeout);
        });
    }

    function getStructureUser() {
        $getStructureUser({
            inner_id: user,
            is_admin: requestAdmin ? 1 : undefined
        }).then(res => {
            if (!res?.data) return;

            let timeout: any;

            if (res && isLoadingMain) {
                const structureArray: any[] = [];

                const modernData = (item: any) => {
                    const childArray = item?.team?.staff?.find((person: any) => person?.inner_id === user)
                        ? item?.team?.staff?.concat(
                            item?.children?.map((child: any) => {
                                return modernData(child);
                            })
                        )
                        : item?.children?.map((child: any) => {
                            return modernData(child);
                        });

                    return {
                        ...item?.team?.leader,
                        department: item,
                        children: childArray,
                        hasChild: item?.team?.staff?.length > 0 || item?.children?.length > 0
                    };
                };

                const modernActiveData = (item: any) => {
                    let isActive = false;
                    let isActiveStyle = false;

                    const childArray = item?.children?.map((child: any) => {
                        if (child?.inner_id === user) {
                            isActive = true;
                            isActiveStyle = true;
                        }

                        return modernActiveData(child);
                    });

                    return {
                        ...item,
                        children: childArray,
                        isActive:
                            (item?.children?.length > 0 && item?.inner_id !== user) ||
                            (item?.children?.length > 0 && isActive),
                        isActiveStyle: item?.children?.length > 0 && isActiveStyle,
                        isExpand: item?.children?.length > 0 || item?.inner_id === user
                    };
                };

                const updateData = modernActiveData(modernData(res.data));

                structureArray?.push([updateData]);

                const buildStructure = (item: any) => {
                    if (structureArray.length > 2) {
                        for (let i = 2; i < structureArray.length; i++) {
                            const isDuplicateArr = structureArray[i].find((i: any) => i?.inner_id === item?.inner_id);

                            if (isDuplicateArr) return;
                        }
                    }

                    if ((item?.inner_id !== user) && (item?.children?.length > 0)) {
                        structureArray?.push(item?.children);

                        item?.children?.map((child: any) => {
                            return buildStructure(child);
                        });
                    }
                };

                buildStructure(updateData);

                const updateArray = [...structureArray];

                const indexArray = updateArray?.map((col, index) => {
                    const centerArray = [...col];

                    centerArray?.splice(
                        col?.findIndex((child: any) => child?.isExpand),
                        1
                    );

                    centerArray?.splice(
                        Math.ceil(centerArray.length / 2),
                        0,
                        col?.find((child: any) => child?.isExpand)
                    );

                    return centerArray?.map((child: any) => {
                        return {
                            ...child,
                            index,
                            isHidden: !(isTablet && child?.isExpand),
                            isUser: true
                        };
                    });
                });

                dispatch(
                    setStructureCompany({
                        structure: indexArray
                    })
                );

                timeout = setTimeout(() => {
                    const activeElement = document.getElementById("active")?.getBoundingClientRect();

                    if (activeElement) {
                        // @ts-ignore
                        document.getElementById("grid").scroll({
                            left: isTablet ? 0 : document.getElementById("grid")?.scrollWidth,
                            behavior: "smooth"
                        });

                        if (isAdmin || currentUser) {
                            const wrapElement = document.getElementById("structure");

                            if (wrapElement) {
                                const wrapElementPosition = wrapElement.getBoundingClientRect();

                                wrapElement.scrollTo({
                                    top: isTablet
                                        ? activeElement?.top + activeElement?.height
                                        : activeElement?.top + wrapElementPosition.top - wrapElementPosition.height / 2,
                                    behavior: "smooth"
                                });
                            }

                            return;
                        }

                        window.scrollTo({
                            top: isTablet
                                ? activeElement?.top + activeElement?.height
                                : activeElement?.top + window.pageYOffset - window.innerHeight / 2,
                            behavior: "smooth"
                        });
                    }
                }, 100);

                setLoadingMain(false);
                setUser("");
            }

            return () => clearTimeout(timeout);
        });
        setFocus(false);
    }

    function handleGetStructure() {
        $getStructure(
            {
                // is_admin: requestAdmin ? 1 : undefined,
                department_id: department || searchDepartment
            }
        ).then(res => {
            let timeout: any;

            if (res && isLoadingMain) {
                const childArray = res?.data?.team?.staff
                    ? res?.data?.team?.staff?.concat(
                        res?.data?.children?.map((child: any) => {
                            return {
                                ...child?.team?.leader,
                                department: child,
                                hasChild: child?.team?.staff?.length > 0 || child?.children_count > 0
                                // top
                            };
                        })
                    )
                    : res?.data?.children?.map((child: any) => {
                        return {
                            ...child?.team?.leader,
                            department: child,
                            hasChild: child?.team?.staff?.length > 0 || child?.children_count > 0
                            // top
                        };
                    });

                const mainArray = [
                    [
                        {
                            ...res?.data?.team?.leader,
                            department: res?.data,
                            hasChild: res?.data?.children?.length > 0,
                            isActive: true,
                            isActiveStyle: true,
                            isExpand: true
                        }
                    ],
                    childArray
                ];

                const updateArray = mainArray?.map((col, index) => {
                    return col
                    ?.sort((a: any, b: any) => handleSort(a, b, res?.data?.sort))
                    ?.map((child: any) => {
                        return { ...child, index };
                    });
                });

                dispatch(
                    setStructureCompany({
                        structure: updateArray
                    })
                );

                setLoadingMain(false);

                if (search === "") {
                    setFocus(true);
                }
            }

            if (res && !isLoadingMain) {
                const childArray = res?.data?.team?.staff
                    ? res?.data?.team?.staff?.concat(
                        res?.data?.children?.map((child: any) => {
                            return {
                                ...child?.team?.leader,
                                department: child,
                                hasChild: child?.team?.staff?.length > 0 || child?.children_count > 0
                                // top
                            };
                        })
                    )
                    : res?.data?.children?.map((child: any) => {
                        return {
                            ...child?.team?.leader,
                            department: child,
                            hasChild: child?.team?.staff?.length > 0 || child?.children_count > 0
                            // top
                        };
                    });

                const updateArray = [
                    ...structureCompany?.structure?.slice(0, structureCompany?.structure?.length),
                    childArray?.sort((a: any, b: any) => handleSort(a, b, res?.data?.sort))
                ];

                const indexArray = updateArray?.map((col, index) => {
                    return col?.map((child: any) => {
                        return { ...child, index };
                    });
                });

                dispatch(
                    setStructureCompany({
                        structure: indexArray
                    })
                );

                dispatch(setStructureCurrentTopHeight(0));

                timeout = setTimeout(() => {
                    const activeElement = document.getElementById("active")?.getBoundingClientRect();

                    if (activeElement) {
                        // @ts-ignore
                        document.getElementById("grid").scroll({
                            left: isTablet ? 0 : document.getElementById("grid")?.scrollWidth,
                            behavior: "smooth"
                        });

                        if (isAdmin || currentUser) {
                            const wrapElement = document.getElementById("structure");

                            if (wrapElement) {
                                const wrapElementPosition = wrapElement.getBoundingClientRect();

                                wrapElement.scrollTo({
                                    top: isTablet
                                        ? activeElement?.top + activeElement?.height
                                        : activeElement?.top + wrapElementPosition.top - wrapElementPosition.height / 2,
                                    behavior: "smooth"
                                });
                            }

                            return;
                        }

                        window.scrollTo({
                            top: isTablet
                                ? activeElement?.top + activeElement?.height
                                : activeElement?.top + window.pageYOffset - window.innerHeight / 2,
                            behavior: "smooth"
                        });
                    }
                }, 100);
            }

            return () => clearTimeout(timeout);
        });
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <StructureNav
                        setDepartment={setDepartment}
                        setSearchDepartment={setSearchDepartment}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-12">
                    <div className={"structure__content"}>
                        <StructureContentGrid
                            isAdmin={requestAdmin}
                            isVacationAdmin={isAdmin}
                            departmentId={department}
                            search={search}
                            setDepartment={setDepartment}
                            setSearchDepartment={setSearchDepartment}
                            setUser={setUser}
                            setSearch={setSearch}
                            setLoadingMain={setLoadingMain}
                            setDepartmentVacation={setDepartmentVacation}
                            handleClosePopup={handleClosePopup}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default StructureContent;