import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";
import CountField from "../../ui/form/Fields/CountField";
import {
    $createFloorBooking,
    $deleteFloorBooking,
    $updateFloorBooking
} from "../../../api/requests/workspace-settings/booking";

interface WorkspaceAddFloorProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    building?: any;
    floor?: any;
}

interface FormData {
    number: number;
    workplace_count: number;
}

const WorkspaceAddFloor = (
    {
        building,
        floor,
        isOpen,
        onClose,
        setIsOpen
    }: WorkspaceAddFloorProps
) => {
    const isEdit = !!floor;

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid },
    } = useForm<FormData>({
        values: {
            number: floor?.number,
            workplace_count: floor?.workplace_count,
        }
    });

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        if (!building?.id) return;

        const requestData = {
            ...request,
            is_active: true
        };

        if (isEdit) return editData(requestData)

        $createFloorBooking(building.id, requestData).then(res => {
            if (res.error || res.response) return;

            setIsStatusComplete(true);
        })
    }

    function editData(requestData: any) {
        if (!building?.id || !floor?.id) return;

        $updateFloorBooking(building.id, floor.id,{
            ...requestData,
        }).then(res => {
            if (res.error || res.response) return;

            handleCloseModal();
        })
    }

    function handleRemove() {
        if (!building?.id || !floor?.id) return;

        $deleteFloorBooking(building.id, floor.id).then(res => {
            handleCloseModal();
        })
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                reset();
                setIsStatusComplete(false);
            }}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && (isEdit ? "Редактирование этажа" : "Новый этаж")}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно создали этаж.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form
                    className={"workspace-settings__meeting-meet__add"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="workspace-settings__meeting-meet__add-block">
                        <div className={"d-flex gap-3"}>
                            <Controller
                                name="number"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CountField
                                        value={value}
                                        label="Этаж"
                                        onChange={onChange}
                                        isInput
                                    />
                                )}
                            />

                            <Controller
                                name="workplace_count"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CountField
                                        value={value}
                                        label="Количество мест"
                                        onChange={onChange}
                                        isInput
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        {isEdit && (
                            <Button
                                type={"submit"}
                                text={"Удалить"}
                                className={"btn btn-danger w-100"}
                                onClick={handleRemove}
                            />
                        )}

                        <Button
                            type={"submit"}
                            text={isEdit ? "Сохранить" : "Добавить"}
                            className={"btn btn-primary ml-auto w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default WorkspaceAddFloor;