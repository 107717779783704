import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../store';

import moment from 'moment';

export interface vacationInitialStateType {
    month: string;
    cityList: any;
    city: string | number;
    departmentList: any;
    department: string | number;
    holidayList: any;
    bookingList: any;
    bookingData: any;
    userList: any;
    currentUser: any;
    conciliatorList: any;
    popupData: any;
    openPopup: boolean;
    openCalendarPopup: boolean;
    settings: any;
    isOwner: boolean;
    isVacation: boolean;
    isVacationIndividual: boolean;
    isBookingLoading: boolean;
}

const initialState: vacationInitialStateType = {
    month: moment()?.format('YYYY-MM-DD'),
    cityList: null,
    city: '',
    departmentList: null,
    department: '',
    holidayList: null,
    bookingList: null,
    bookingData: null,
    userList: null,
    currentUser: null,
    conciliatorList: null,
    popupData: null,
    openPopup: false,
    openCalendarPopup: false,
    settings: null,
    isOwner: false,
    isVacation: false,
    isVacationIndividual: false,
    isBookingLoading: false,
};

const vacationSlice = createSlice({
    name: 'vacation',
    initialState,
    reducers: {
        setMonth: (state, {payload}: PayloadAction<any>) => {
            state.month = payload;
        },
        setCityList: (state, {payload}: PayloadAction<any>) => {
            state.cityList = payload;
        },
        setCity: (state, {payload}: PayloadAction<any>) => {
            state.city = payload;
        },
        setDepartmentList: (state, {payload}: PayloadAction<any>) => {
            state.departmentList = payload;
        },
        setDepartment: (state, {payload}: PayloadAction<any>) => {
            state.department = payload;
        },
        setHolidayList: (state, {payload}: PayloadAction<any>) => {
            state.holidayList = payload;
        },
        setBookingList: (state, {payload}: PayloadAction<any>) => {
            state.bookingList = payload;
        },
        setBookingData: (state, {payload}: PayloadAction<any>) => {
            state.bookingData = payload;
        },
        setUserList: (state, {payload}: PayloadAction<any>) => {
            state.userList = payload;
        },
        setCurrentUser: (state, {payload}: PayloadAction<any>) => {
            state.currentUser = payload;
        },
        setConciliatorList: (state, {payload}: PayloadAction<any>) => {
            state.conciliatorList = payload;
        },
        setPopupData: (state, {payload}: PayloadAction<any>) => {
            state.popupData = payload;
        },
        setOpenPopup: (state) => {
            state.openPopup = !state.openPopup;
        },
        setOpenCalendarPopup: (state) => {
            state.openCalendarPopup = !state.openCalendarPopup;
        },
        setSettings: (state, {payload}: PayloadAction<any>) => {
            state.settings = payload;
        },
        setIsOwner: (state, {payload}: PayloadAction<any>) => {
            state.isOwner = payload;
        },
        setIsVacation: (state, {payload}: PayloadAction<any>) => {
            state.isVacation = payload;
        },
        setIsVacationIndividual: (state, {payload}: PayloadAction<any>) => {
            state.isVacationIndividual = payload;
        },
        setBookingLoading: (state, {payload}: PayloadAction<any>) => {
            state.isBookingLoading = payload;
        },
    },
});

export const {
    setMonth,
    setCityList,
    setCity,
    setDepartmentList,
    setDepartment,
    setHolidayList,
    setBookingList,
    setBookingData,
    setUserList,
    setCurrentUser,
    setConciliatorList,
    setPopupData,
    setOpenPopup,
    setOpenCalendarPopup,
    setSettings,
    setIsOwner,
    setIsVacation,
    setIsVacationIndividual,
    setBookingLoading,
} = vacationSlice.actions;

export const getMonth = (state: RootState) => state.vacation.month;
export const getCityList = (state: RootState) => state.vacation.cityList;
export const getCity = (state: RootState) => state.vacation.city;
export const getDepartmentList = (state: RootState) => state.vacation.departmentList;
export const getDepartment = (state: RootState) => state.vacation.department;
export const getHolidayList = (state: RootState) => state.vacation.holidayList;
export const getBookingList = (state: RootState) => state.vacation.bookingList;
export const getBookingData = (state: RootState) => state.vacation.bookingData;
export const getUserList = (state: RootState) => state.vacation.userList;
export const getCurrentUser = (state: RootState) => state.vacation.currentUser;
export const getConciliatorList = (state: RootState) => state.vacation.conciliatorList;
export const getPopupData = (state: RootState) => state.vacation.popupData;
export const getOpenPopup = (state: RootState) => state.vacation.openPopup;
export const getOpenCalendarPopup = (state: RootState) => state.vacation.openCalendarPopup;
export const getSettings = (state: RootState) => state.vacation.settings;
export const getIsOwner = (state: RootState) => state.vacation.isOwner;
export const getIsVacation = (state: RootState) => state.vacation.isVacation;
export const getIsVacationIndividual = (state: RootState) => state.vacation.isVacationIndividual;
export const getIsBookingLoading = (state: RootState) => state.vacation.isBookingLoading;

export const vacationSliceReducer = vacationSlice.reducer;

export default vacationSlice.reducer
