import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import { ResponseDefaultData } from "./interface";

export const $getMeetBooking = (
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.get("backend/v1/booking/building", {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $getMeetBookingById = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.get(`backend/v1/booking/building/${id}`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $createMeetBooking = (
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.post("backend/v1/booking/building", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $updateMeetBooking = (
    id: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.put(`backend/v1/booking/building/${id}`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $deleteMeetBooking = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.delete(`backend/v1/booking/building/${id}`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $createFloorBooking = (
    id: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.post(`backend/v1/booking/building/${id}/floor`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $updateFloorBooking = (
    buildingId: string | number,
    floorId: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.put(`backend/v1/booking/building/${buildingId}/floor/${floorId}`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $deleteFloorBooking = (
    buildingId: string | number,
    floorId: string | number,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.delete(`backend/v1/booking/building/${buildingId}/floor/${floorId}`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $createRoomBooking = (
    buildingId: string | number,
    floorId: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.post(`backend/v1/booking/building/${buildingId}/floor/${floorId}/room`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $updateRoomBooking = (
    buildingId: string | number,
    floorId: string | number,
    roomId: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.put(`backend/v1/booking/building/${buildingId}/floor/${floorId}/room/${roomId}`, data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });

export const $deleteRoomBooking = (
    buildingId: string | number,
    floorId: string | number,
    roomId: string | number,
    handler?: IApiHandlerParams
): Promise<ResponseDefaultData> =>
    api.delete(`backend/v1/booking/building/${buildingId}/floor/${floorId}/room/${roomId}`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler: {
            ...handler,
            newApi: true
        }
    });