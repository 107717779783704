import { FC } from "react";
import { ContestApplicationUsersItem } from "../../api/requests/contest/interface";
import { BlankImage } from "../ui/blank-image/BlankImage";

interface ICompetitionsApplicationCard {
    data: any;
    isAdmin?: any;
    handleOpenItem?: (data: any) => void;
}

export const CompetitionsApplicationCard: FC<ICompetitionsApplicationCard> = (
    {
        data,
        handleOpenItem,
        isAdmin,
    }
) => {
    
    const {
        id,
        name,
        photo,
        status,
        position,
        attachments,
        can_delete,
        text,
        uniq,
        user_id,
        date,
        user_voted,
        votes,
        winner,
    } = data;

    const statusList = [
        {
            key: "0",
            value: "Заявка"
        },
        {
            key: "1",
            value: "Победитель"
        },
        {
            key: "2",
            value: "Исключена"
        }
    ];

    const statusFind = statusList.find(item => item.key == (winner || 0));

    return (
        <div className={`contests__application__card ${handleOpenItem ? `status${statusFind?.key} cursor-pointer` : ``}`} onClick={() => handleOpenItem && handleOpenItem(data)}>
            <div className={"contests__application__card__header"}>
                <div className="contests__application__card__header-left">
                    <BlankImage image={photo}/>

                    <div className="contests__application__card__header-left-text">
                        <h4>{name}</h4>

                        <p>{position}</p>
                    </div>
                </div>

                {(handleOpenItem) && (
                    <div className={`contests__application__card__header-right status${statusFind?.key}`}>
                        <p>{statusFind?.value || "Неопределен"}</p>
                    </div>
                )}
            </div>

            <div className={`contests__application__card__content ${handleOpenItem ? "slice" : ""}`}>
                <p>{text}</p>
            </div>
        </div>
    );
};