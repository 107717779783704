import { FC, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { NotificationCard, NotificationCardProps } from "../../notification/notification-card";
import { $getNotification, $getPersonalPage } from "../../../api/requests/user";

interface IHeaderNotificationPopup {
    data: any;
    setPopup: any;
}

export const HeaderNotificationPopup: FC<IHeaderNotificationPopup> = (
    {
        data,
        setPopup
    }
) => {
    const navigate = useNavigate();

    function openNotifications() {
        setPopup(false);
        navigate("/notifications");
    }

    return (
        <div className="notifications-popup">
            <div className="notifications-popup-content">
                {data && data.slice(0, 5).map((notification: NotificationCardProps) => (
                    <NotificationCard data={notification} />
                ))}

                {(!data || data.length == 0) && (
                    <div className={"notifications-popup__empty"}>
                        Нет уведомления
                    </div>
                )}

                <div className="notifications-popup__button"
                     onClick={openNotifications}>
                    Открыть все
                </div>
            </div>
        </div>
    );
};