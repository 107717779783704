import {
    PlusIcon2,
} from "../../img";
import React, { useEffect, useState } from "react";
import Button from "../../components/ui/form/Buttons/Button";
import { useSelector } from "react-redux";
import {
    getMainPageData,
    getPersonData,
} from "../../redux";
import { successNotify } from "../../utils";
import { $deleteWelcome, $getWelcome } from "../../api/requests/workspace-settings/welcome";
import { WorkspaceSettingWelcomeForm } from "../../components/workspace/settings/WorkspaceSettingWelcomeForm";

const WorkspaceSettingWelcome = () => {
    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (personalData && mainData) init();
    }, [personalData, mainData]);

    function init() {
        getData();
    }

    function getData() {
        $getWelcome().then(res => {
            setData(res?.data || [])
            if (!res?.data?.length) {
                addEmptyData();
            }
        })
    }

    function addEmptyData() {
        if (data?.filter((item) => {
            if (item.isNew) {
                return true;
            }
            return false;
        })?.length) {
            return;
        }

        setData([...data, {
            days_after: "",
            points: "",
            comment: "",
            badge_id: "",
            isNew: true,
        }])
    }

    function handleDelete(idx: any, isNew: any) {
        if (isNew) {
            setData(data.filter((item, _idx) => {
                if (_idx == idx) return false;
                return true;
            }))
            successNotify("Успешно удалено").then();
        } else {
            $deleteWelcome({ id: data[idx]?.id }).then(res => {
                successNotify("Успешно удалено").then();
                setData(data.filter((item, _idx) => {
                    if (_idx == idx) return false;
                    return true;
                }))
            })
        }
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <div className={"workspace-settings__welcome"}>
                    <h3>Автоматизация велком баллов</h3>

                    <div className={"d-flex flex-column gap-4"}>
                        {data && data?.map((item: any, idx: number) => (
                            <WorkspaceSettingWelcomeForm
                                data={item}
                                onDelete={handleDelete}
                                onSuccess={getData}
                                index={idx}
                                key={idx}
                            />
                        ))}
                    </div>
                </div>

                <div className={"workspace-settings__welcome__btn"}>
                    <Button
                        text={<PlusIcon2
                            size={32}
                            color={"#00A7B5"} />}
                        className={"btn btn-blue-secondary w-100"}
                        onClick={addEmptyData}
                    />
                </div>
            </div>
        </div>
    );
};

export default WorkspaceSettingWelcome;