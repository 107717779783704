import {
    ArrowExitIcon,
    SearchIcon,
    SettingIcon
} from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";
import Button from "../../components/ui/form/Buttons/Button";
import TextField from "../../components/ui/form/Fields/TextField";
import {
    $getMediaFileCategories,
    $getMediaFiles
} from "../../api/requests/media-files";
import { MediaFilesCard } from "../../components/media-files/card";
import ModalCatBlogs from "../../components/categories/ModalCatBlogs";

export const MediaFilesPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [mediaFiles, setMediaFiles] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);

    const [searchValue, setSearchValue] = useState<string>("");
    const [categoryValue, setCategoryValue] = useState<number>(-1);

    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    useEffect(() => {
        init();
    }, []);

    function init() {
        getMediaFiles();
        getCategories();
    }

    function getMediaFiles() {
        $getMediaFiles({ newApi: true }).then(res => {
            if (!res) return;

            setMediaFiles(res.data.data);
            setFilterList(res.data.data);
        });
    }

    function getCategories() {
        $getMediaFileCategories({ formData: true, newApi: true }).then(res => {
            if (!res || !res.data.length) return;

            setCategoryList(
                [
                    ...res.data
                ]
                .map(i => ({
                    ...i,
                    label: i.title.length > 30 ? i.title.slice(0, 30) + "..." : i.title,
                    value: i.id
                }))
            );
        });
    }

    const getCategoryValue = (
        opts: { id?: number; title?: string; label: string; value: string | number }[],
        val: number | string | null
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    function onChangeCategory(value: any) {
        setCategoryValue(value.id);

        const filterList = [...mediaFiles].filter(item => {
            return (item.title.toLowerCase().includes(searchValue.toLowerCase())) && (value.id == -1 || item.category?.id == value.id);
        });

        setFilterList(filterList);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...mediaFiles].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase())) && (categoryValue == -1 || item.category?.id == categoryValue);
        });

        setFilterList(filterList);
    }

    return (
        <div className="media-files">
            <ModalCatBlogs
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={"mediaGalleries"}
            />

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Медиафайлы</h1>
                    </div>
                )}

                <div className="media-files-controllers">
                    <div className="media-files-controllers__input">

                        <Dropdown
                            // className="workspace-select"
                            options={[
                                {
                                    id: -1,
                                    title: "Все категории",
                                    value: "-1",
                                    label: "Все категории"
                                },
                                ...categoryList
                            ]}
                            value={
                                getCategoryValue(
                                    [
                                        {
                                            id: -1,
                                            title: "Все категории",
                                            value: "-1",
                                            label: "Все категории"
                                        },
                                        ...categoryList
                                    ]
                                    , categoryValue)
                            }
                            onChange={onChangeCategory}
                            labelBottom={(
                                <div className={"dropdown__list__setting"}>
                                    <Button
                                        leftIcon={<SettingIcon color={"currentColor"} />}
                                        text={"Настройки"}
                                        className={"btn btn-primary-revert w-100"}
                                        onClick={handleSetting}
                                    />
                                </div>
                            )}
                        />
                        {/*<Dropdown*/}
                        {/*    className={"form-select"}*/}
                        {/*    placeholder={"Категория не выбрана"}*/}
                        {/*    options={categoryList}*/}
                        {/*    value={getCategoryValue(categoryList, categoryValue)}*/}
                        {/*    onChange={onChangeCategory}*/}
                        {/*/>*/}
                    </div>

                    <div className="media-files-controllers__input media-files-search">
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"rgba(0,0,0,0.5)"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                </div>
            </div>

            <div className="container media-files-content">
                <div className="media-files-content__items">
                    {filterList.map((item, idx) => (
                        <MediaFilesCard data={item} key={idx} />
                    ))}
                </div>
            </div>

        </div>
    );
};