import { FC } from "react";
import { IBaseIconProps } from "../types";

export const AnalyticsPollIcon: FC<IBaseIconProps> = (
    {
        size = 28,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 28 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22 14V7.749C22.0001 7.67006 21.9845 7.59189 21.9543 7.51896C21.9241 7.44603 21.8798 7.37978 21.824 7.324L18.676 4.176C18.5636 4.06345 18.4111 4.00014 18.252 4H6.6C6.44087 4 6.28826 4.06321 6.17574 4.17574C6.06321 4.28826 6 4.44087 6 4.6V23.4C6 23.5591 6.06321 23.7117 6.17574 23.8243C6.28826 23.9368 6.44087 24 6.6 24H13M10 12H18M10 8H14M10 16H13M19.954 18.94L20.954 17.94C21.0581 17.8358 21.1817 17.7531 21.3178 17.6968C21.4539 17.6404 21.5997 17.6113 21.747 17.6113C21.8943 17.6113 22.0401 17.6404 22.1762 17.6968C22.3123 17.7531 22.4359 17.8358 22.54 17.94C22.7501 18.1502 22.8682 18.4353 22.8682 18.7325C22.8682 19.0297 22.7501 19.3148 22.54 19.525L21.54 20.525L19.954 18.939L16.963 21.93C16.8135 22.08 16.7154 22.2737 16.683 22.483L16.439 24.04L17.996 23.797C18.2053 23.7646 18.399 23.6665 18.549 23.517L21.539 20.525"
                stroke={color} strokeWidth="1.65" stroke-linecap="round"
                strokeLinejoin="round" />
            <path
                d="M18 4V7.4C18 7.55913 18.0632 7.71174 18.1757 7.82426C18.2883 7.93679 18.4409 8 18.6 8H22"
                stroke={color} strokeWidth="1.65" stroke-linecap="round"
                strokeLinejoin="round" />
        </svg>
    );
};