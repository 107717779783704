import { FC } from "react";
import { IBaseIconProps } from "../types";

export const AnalyticsPollIdeasIcon:FC<IBaseIconProps>= (
    {
        size = 28,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 29 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1115_35694)">
                <path
                    d="M13.334 2V5H15.334V2H13.334ZM6.55664 4.80859L5.14258 6.22266L7.26367 8.34375L8.67773 6.92969L6.55664 4.80859ZM22.1113 4.80859L19.9902 6.92969L21.4043 8.34375L23.5254 6.22266L22.1113 4.80859ZM14.334 7C10.4797 7 7.33398 10.1457 7.33398 14C7.33398 16.7678 8.9901 19.1022 11.334 20.2344V23C11.334 24.0931 12.2409 25 13.334 25H15.334C16.427 25 17.334 24.0931 17.334 23V20.2344C19.6779 19.1022 21.334 16.7678 21.334 14C21.334 10.1457 18.1883 7 14.334 7ZM14.334 9C17.1077 9 19.334 11.2263 19.334 14C19.334 16.1843 17.9393 18.0229 16 18.709L15.334 18.9434V23H13.334V18.9434L12.668 18.709C10.7287 18.0229 9.33398 16.1843 9.33398 14C9.33398 11.2263 11.5603 9 14.334 9ZM2.33398 13V15H5.33398V13H2.33398ZM23.334 13V15H26.334V13H23.334ZM7.26367 19.6562L5.14258 21.7773L6.55664 23.1914L8.67773 21.0703L7.26367 19.6562ZM21.4043 19.6562L19.9902 21.0703L22.1113 23.1914L23.5254 21.7773L21.4043 19.6562Z"
                    fill={color} />
            </g>
            <defs>
                <clipPath id="clip0_1115_35694">
                    <rect width="24" height="24" fill="white" transform="translate(2.33398 2)" />
                </clipPath>
            </defs>
        </svg>
    );
};