import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArrowExitIcon, EyeIcon, ShareIcon } from "../../../img";
import { $getSportById } from "../../../api/requests/sport";
import TrainingContentItem from "../TrainingContentItem";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData, SportViewItemData
} from "../../../api/requests/sport/interface";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import Button from "../../../components/ui/form/Buttons/Button";
import TrainingSessionViewModalSign from "./TrainingSessionViewModalSign";
import { daysOfWeek, isMd, successNotify } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRole, getPersonData, updateRouteTitle } from "../../../redux";
import TrainingSessionViewModalCancel from "./TrainingSessionViewModalCancel";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { EyeIcon2 } from "../../../img/icons/news/EyeIcon2";

const TrainingSessionViewPage = () => {
    const isMobile = isMd();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const { id } = useParams();
    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);

    const isAdmin = currentRole === 1;

    const [isDisabledSign, setIsDisabledSign] = useState(false);

    const [trainingSessionData, setTrainingSessionData] = useState<SportViewItemData | null>(null);
    const [isOpenModalSign, setIsOpenModalSign] = useState<boolean>(false);
    const [isOpenModalCancel, setIsOpenModalCancel] = useState<boolean>(false);
    const [isRecorded, setIsRecorded] = useState<boolean>(false);

    const [selectSchedule, setSelectSchedule] = useState<any>(trainingSessionData?.schedules[0]);

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data.map((item) => {
            const dateObj = new Date(item.date_from_at);
            const weekDay = daysOfWeek[dateObj.getDay()];
            const formattedDate = `${String(dateObj.getDate()).padStart(2, "0")}.${String(dateObj.getMonth() + 1).padStart(2, "0")}`; // Format date as dd.mm
            const timeStart = new Date(item.date_from_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const timeEnd = new Date(item.date_to_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const time = `${timeStart} - ${timeEnd}`;

            return {
                ...item,
                week: weekDay,
                date: formattedDate,
                time: time
            };
        });
    };

    const transformContentData = (item: SportItemData): SportViewItemData => {
        return {
            ...item,
            seats: item.seats - (item?.unique_users?.length || 0),
            schedules: transformDateData(item.schedules)
        };
    };

    useEffect(() => {
        personalData && init();
    }, [personalData]);

    useEffect(() => {
        dispatch(updateRouteTitle({key: "sportSetting", title: trainingSessionData?.title}));
        setSelectSchedule(trainingSessionData?.schedules[0])
    }, [ trainingSessionData ]);

    useEffect(() => {
        const findRecord = selectSchedule?.enrolled_users?.find((item: any) => item.id === personalData.id);

        setIsRecorded(!!findRecord);
    }, [ selectSchedule ]);

    function init() {
        if (!id) return;

        console.log(personalData, 'asd');

        if (typeof personalData?.opportunity_for_training === 'boolean') {
            setIsDisabledSign(!personalData?.opportunity_for_trainings);
        }

        getSelect();

        getIsBlockMe();
    }

    function getSelect() {
        if (!id) return;

        $getSportById(isAdmin, id, null, { newApi: true }).then(res => {
            if (!res.data) return;

            setTrainingSessionData(transformContentData(res.data));
        });
    }

    function getIsBlockMe() {
        // setIsDisabledSign(true);
    }

    function handleShare() {
        const url = window.location.href;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then()
    }

    function handleChangeDateWorkout(select: SportDateData) {
        setSelectSchedule(select);
    }

    return (
        <div className="news-show">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate("/sport")}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{trainingSessionData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="news-show-content">
                <div className="news-show-content__left">
                    <div className="sport__view">
                        <div className="sport__view__content">
                            {trainingSessionData &&
                              <TrainingContentItem
                                  {...trainingSessionData}
                                  handleChangeDateWorkout={handleChangeDateWorkout}
                                  selectSchedule={selectSchedule}
                              />}

                            {trainingSessionData?.venue && (
                                <div className="sport__view__content__location">
                                    <LocationIcon />

                                    <p>{trainingSessionData?.venue}</p>
                                </div>
                            )}

                            {(selectSchedule?.enrolled_users && selectSchedule?.enrolled_users.length) ? (
                                <div className="sport__view__content__users">
                                    <p>Участники</p>

                                    <div className="layout-congratulations__content">
                                        {selectSchedule?.enrolled_users.slice(0, 10).map((item: any, idx: number) => (
                                            <div
                                                className={`layout-congratulations__block ${idx === 0 ? "first" : ""}`}
                                                key={idx}
                                            >
                                                <BlankImage
                                                    image={item.photo}
                                                    classname={""}
                                                />
                                            </div>
                                        ))}

                                        {selectSchedule?.enrolled_users?.length > 10 && (
                                            <div className={"layout-congratulations__number"}>{selectSchedule?.enrolled_users?.length - 10}</div>
                                        )}
                                    </div>
                                </div>
                            ) : null}

                            <div className="sport__content-card-block__bottom">
                                <div className="news-show-info__buttons">
                                    <Button
                                        text={"Записаться на тренировку"}
                                        className="btn btn-primary news-show-info__buttons__likeButton"
                                        onClick={() => setIsOpenModalSign(true)}
                                        disabled={isDisabledSign}
                                    />

                                    {isRecorded && (
                                        <Button
                                            text={"Отменить запись на тренировку"}
                                            className="btn btn-light news-show-info__buttons__likeButton ml-0"
                                            onClick={() => setIsOpenModalCancel(true)}
                                            disabled={isDisabledSign}
                                        />
                                    )}

                                    <button
                                        className="news-show-info__buttons__shareButton"
                                        onClick={handleShare}
                                    >
                                        <ShareIcon size={24} color="#00A7B5" /> <p>Поделиться</p>
                                    </button>
                                </div>

                                <div className="news-show-info__stats">
                                    <div className="news-show-info__stats__block">
                                        <EyeIcon2 color="#000" size={20} />

                                        <p>{trainingSessionData?.views}</p>
                                    </div>
                                </div>
                            </div>

                            {isDisabledSign && (
                                <div className={"sport__content-card-block__bottom error"}>
                                    <p>К сожалению, вы были временно заблокированы для записи на
                                        тренировки из-за трех пропущенных занятий. Пожалуйста,
                                        свяжитесь с администратором для разблокировки.</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <TrainingSessionViewModalSign
                isOpen={isOpenModalSign}
                setIsOpen={(value) => {
                    setIsOpenModalSign(value);
                }}
                onClose={(value) => {
                    setIsOpenModalSign(value);
                    init();
                }}
                data={trainingSessionData}
            />

            <TrainingSessionViewModalCancel
                isOpen={isOpenModalCancel}
                onClose={(value) => {
                    setIsOpenModalCancel(value);
                    init();
                }}
                setIsOpen={setIsOpenModalCancel}
                data={trainingSessionData}
            />
        </div>
    );
};

export default TrainingSessionViewPage;