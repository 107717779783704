import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";
import { ArrowExitIcon } from "../../../img";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { isMd } from "../../../utils";
import { $getDiscount, $getDiscountById } from "../../../api/requests/discount";
import { YMaps, Map, Placemark, GeolocationControl } from "@pbe/react-yandex-maps";
import Button from "../../../components/ui/form/Buttons/Button";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";

export const DiscountShowPage = () => {
    const isMobile = isMd();

    const mapRef = useRef(null);

    const navigate = useNavigate();

    const { id: mediaFileId } = useParams();

    const currentRole = useSelector(getCurrentRole);
    const personalData = useSelector(getPersonData);

    const [partner, setPartner] = useState<any>({});
    const [center, setCenter] = useState<any>(null);
    const [userLocation, setUserLocation] = useState<any>(null);
    const [activeAddress, setActiveAddress] = useState('');

    const [discountData, setDiscountData] = useState<any>(null);

    const handleUserLocationInCenter = () => {
        if (!userLocation) return;

        setCenter({lat: userLocation?.latitude, lng: userLocation?.longitude})
    }

    useEffect(() => {
        if (currentRole) getMediaFile();
    }, [currentRole, mediaFileId]);

    useEffect(() => {
        if (!partner) return;

        getGEOLocation((data: any) => setUserLocation(data.coords));
    }, [partner]);

    function getMediaFile() {
        if (!mediaFileId) return;

        const requestData = {
            id: personalData.workspace_id,
            partner_id: mediaFileId,
        }

        $getDiscountById(requestData).then(res => {
            if (!res?.id) return;

            setPartner(res);
        });
    }

    function getGEOLocation(callback: any) {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(i => {
                callback(i);
            });
        }
    }

    function handleClickDiscount(select: any) {
        if (!partner?.id && !select?.id) return;

        const requestData = {
            id: personalData.workspace_id,
            partner_id: partner.id,
            discount_id: select.id,
        }

        $getDiscount(requestData).then(res => {
            if (res.error) return;

            setDiscountData(res)
        })
    }

    return (
        <div className="media-files-show">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{partner?.title}</h1>
                    </div>
                )}
            </div>

            <div className="container media-files-show__full">
                <div className="media-files-show__left discount__left">
                    <BlankImage
                        image={partner?.gallery?.length ? partner?.gallery[0] : ""}
                        classname="media-files-show__image"
                    />

                    <div className="media-files-show__content">
                        <div className="media-files-show__content__topbar">
                            {isMobile && (
                                <div className="media-files-show__name">
                                    {partner?.title}
                                </div>
                            )}
                        </div>

                        <div
                            className="media-files-show__description"
                            dangerouslySetInnerHTML={{ __html: partner?.description }}
                        ></div>

                        {(partner.places && partner.places.length) && (
                            <div className={"discount__block__content__places"}>
                                <h5>Адреса и телефоны</h5>

                                <div className={"discount__block__content__places__items"}>
                                    {partner.places.map((item: any, idx: number) => (
                                        <div
                                            key={`address-contacts-item-${idx}`}
                                            className="discount__block__content__places__item"
                                            onClick={() => {
                                                if (activeAddress === item.address) {
                                                    setActiveAddress('');
                                                } else {
                                                    setActiveAddress(item.address);
                                                }
                                                setCenter({
                                                    lat: +item.lat,
                                                    lng: +item.lon,
                                                });
                                            }}
                                        >
                                            <div>
                                                <p>{item.address}</p>

                                                {item.phone && <p>{item.phone}</p>}
                                            </div>

                                            {item.work_time && <p>{item.work_time}</p>}
                                        </div>
                                    ))}
                                </div>

                                <div className={"discount__block__content__places__map"}>
                                    <YMaps
                                        query={{
                                            apikey: '25315837-6bba-44a5-b51b-ca57545cb6be',
                                            lang: "ru_RU"
                                        }}
                                    >
                                        <div style={{height: '100%',}}>
                                            <Map
                                                instanceRef={mapRef as any}
                                                width={'100%'}
                                                height={'100%'}
                                                defaultState={{
                                                    center: center ? [center?.lat, center?.lng] : [partner.places[0].lat, partner.places[0].lon,],
                                                    zoom: 15,
                                                    controls: ["zoomControl", "fullscreenControl"],
                                                }}
                                                state={{
                                                    center: center ? [center?.lat, center?.lng] : [partner.places[0].lat, partner.places[0].lon,],
                                                    zoom: 15,
                                                    controls: ["zoomControl", "fullscreenControl"],
                                                }}
                                                modules={["control.ZoomControl", "control.FullscreenControl"]}
                                            >
                                                {partner?.places?.map((item: any, index: number) => (
                                                    <Placemark
                                                        key={item.id}
                                                        geometry={[ +item.lat, +item.lon,]}
                                                        properties={{
                                                            item: index
                                                        }}
                                                        options={{
                                                            iconLayout: 'default#image',
                                                            iconImageHref: '/images/location_marker.png',// URL to your custom icon
                                                            iconImageSize: [48, 48], // Size of the icon
                                                            iconImageOffset: [-24, -48],
                                                        }}
                                                    />
                                                ))}

                                                {userLocation && (
                                                    <Placemark
                                                        geometry={[
                                                            userLocation?.latitude,
                                                            userLocation?.longitude,
                                                        ]}
                                                        options={{
                                                            iconLayout: 'default#image',
                                                            iconImageHref: '/images/location.svg',// URL to your custom icon
                                                            iconImageSize: [48, 48], // Size of the icon
                                                            iconImageOffset: [-24, -48],
                                                        }}
                                                    />
                                                )}

                                                {userLocation &&
                                                    <GeolocationControl
                                                        options={{
                                                            noPlacemark: true,
                                                        } as any}
                                                        onClick={handleUserLocationInCenter}
                                                    />
                                                }
                                            </Map>
                                        </div>
                                    </YMaps>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="media-files-show__right discount__right">
                    <div className={"discount__block__content__places"}>
                        <h4>Льготы ({partner?.discounts?.length})</h4>

                        <div className={"discount__right__content"}>
                            {partner?.discounts?.map((item: any, idx: number) => (
                                <div
                                    key={`address-contacts-item-${idx}`}
                                    className="discount__block__content__places__item"
                                    onClick={() => {}}
                                >
                                    <p>{item.description}</p>

                                    <Button
                                        className="btn btn-primary"
                                        text={'Получить'}
                                        onClick={() => handleClickDiscount(item)}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <DialogContainer
                isOpen={!!discountData}
                setIsOpen={() => setDiscountData(null)}
                isShowCloseBtn={false}
                classNameModal={"p-0"}
                widthProps={427}
            >
                <div className={"sport__view__modal contests__winner discount__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <h3>{discountData?.description}</h3>

                        <p>{discountData?.value}</p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={() => setDiscountData(null)}
                        />
                    </div>
                </div>
            </DialogContainer>
        </div>
    );
};