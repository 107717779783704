import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import { IServiceTripReq } from "./interface";

export const $createServiceTrip = (
    data: IServiceTripReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/services/business-trips/store", data, {
    handler
});