import {useNavigate} from "react-router-dom";
import {getDateByFormat, getYearMonthDay, isTillMd} from "../../utils";
import {FC, useEffect, useState} from "react";
import {BlankImage} from "../ui/blank-image/BlankImage";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import {CommentIcon, EyeIcon, LikeIcon, StarIcon} from "../../img";
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import {$toggleUserFavorites} from "../../api/requests/favorites";

interface IData {
    id: number,
    title: string,
    cover: string,
    date?: string,
    comments_count: number | string,
    views_count: number | string,
    likes_count?: number | string
}

interface IFavoritesNewsCard {
    isLoading: boolean,
    setIsLoading: any,
    type: string,
    data: IData,
}

export const FavoritesNewsCard: FC<IFavoritesNewsCard> = (
    {
        data,
        type,
        isLoading,
        setIsLoading
    }) => {
    const isLiked = true;
    const navigate = useNavigate()

    const isMobile = isTillMd();
    const [imgSrc, setImgSrc] = useState<any>(null)

    const {
        id,
        title,
        cover,
        date,
        comments_count,
        views_count,
        likes_count,
    } = data

    useEffect(() => {
        const img = new Image();
        img.src = cover;

        img.onload = () => {
            setImgSrc(cover);
        };

    }, [cover]);

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()
        setIsLoading(true)

        $toggleUserFavorites({type: type, type_id: id, add_to_favorites: false}, {newApi: true}).then(res => {
            setIsLoading(false)
        })
    };

    return (
        <div className='news-card' >
            <BlankImage image={cover}/>

            <div className='news-card__infoBox'>
                <div className='news-card__infoBox__top'>
                    <h5>{TextOverflow({title, lines: 3})}</h5>

                    <div
                        onClick={ (e) => {
                            handleLike(id, e)
                        } }
                        className={`employees-block__likeIcon ${isLiked ? 'active' : ''}`}
                    >
                        <StarIcon opacity={isLiked ? 1 : 0.2}/>
                    </div>
                </div>


                {/*{!isMobile && (*/}
                {/*    <div className={ `news-card__catTitleStyle` }>{ category_title ? category_title : 'Без категории' }</div>*/}
                {/*)}*/}

                <div className='news-card__content'>
                    {date && (
                        <div className='news-card__dateBox'>
                            <NewsDateIcon
                                size={ '1.25rem' }
                                style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} }
                            />

                            <div>{getYearMonthDay(date) }</div>
                        </div>
                    )}

                    <div className='news-card__stats'>
                        {views_count ? (
                            <div className='news-card__stats__block'>
                                <EyeIcon />

                                <h1>{ views_count }</h1>
                            </div>
                        ) : ''}

                        {comments_count ?  (
                            <div className='news-card__stats__block'>
                                <CommentIcon />

                                <h1>{ comments_count }</h1>
                            </div>
                        ) : ''}

                        {likes_count ? (
                            <div className='news-card__stats__block'>
                                <LikeIcon color='#00A7B5' />

                                <h1>{ likes_count }</h1>
                            </div>
                        ) : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}