import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { setRedactorNewsListData, updateRouteTitle } from "../../../redux";
import {
    $editDashboardBlogs, $getBlogsItem,
    $getDashboardBlogsItem,
    $toggleFavouriteBlog,
    $toggleLikeBlog
} from "../../../api/requests/blogs";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
    ArrowExitIcon,
    CommentIcon,
    EyeIcon,
    HeartIcon,
    LikeIcon,
    ShareIcon,
    StarIcon
} from "../../../img";
import { ClockIcon } from "@mui/x-date-pickers";
import {
    BlogsCommentsMessenger,
    BlogsPointsModal, BlogsRejectModal,
    BlogsShowPageCard
} from "../../../components/blogs";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { getYearMonthDay, getYearMonthDayAndTime, isMd, successNotify } from "../../../utils";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import Button from "../../../components/ui/form/Buttons/Button";
import { ProfileBalanceIcon } from "../../../img/icons/layout-components";
import {$toggleUserFavorites} from "../../../api/requests/favorites";

export const BlogsShowPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const isMobile = isMd();
    const isModeration = location.pathname.includes("moderation");

    const dispatch = useDispatch();

    const { id: blogs_id } = useParams();

    const [blogsList, setBlogsList] = useState<IBlogsRes[]>([]);
    const [blogData, setBlogData] = useState<IBlogsRes>();

    const [isCommentLoading, setIsCommentLoading] = useState(false);

    const rightData = blogsList.filter((item: any) => item.id != blogs_id);

    const [isLoading, setIsLoading] = useState(false);

    const [modalOpen, setModalOpen] = useState("");

    const [points, setPoints] = useState(0);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleCopy = () => {
        const url = window.location.href; // Get the current page URL
        navigator.clipboard.writeText(url)
        .then(() => {
            successNotify('Ссылка скопирована')
        })
        .catch((err) => {
        });
    };

    useEffect(() => {
        if (blogs_id) {
            $getBlogsItem(+blogs_id, { newApi: true }, true).then(res => {
                if (!res.data) return;

                setBlogData(res?.data);
            });
        }
    }, [])

    useEffect(() => {
        if (blogs_id) {
            $getBlogsItem(+blogs_id, { newApi: true }).then(res => {
                if (!res.data) return;

                setBlogData(res?.data);
            });
        }
    }, [blogs_id, isLoading]);

    useEffect(() => {
        dispatch(updateRouteTitle({ key: "blogs_show", title: blogData?.title }));
    }, [blogData]);

    function handleToggleFavourite() {
        setIsLoading(true);

        if (blogs_id) {
            $toggleUserFavorites({type: 'blog', type_id: +blogs_id, add_to_favorites: !blogData?.is_favourite}, {
                newApi: true,
            }).then(res => {
                setIsLoading(false);
            });
        }
    }

    function handleToggleLike() {
        setIsLoading(true);

        $toggleLikeBlog(blogs_id ? +blogs_id : 0, { newApi: true, formData: true }).then(res => {
            setIsLoading(false);
        });

    }

    function handleAcceptOrRejectBlog(type: string) {
        const updatedList = {
            title: blogData?.title,
            text: blogData?.text,
            json_text: JSON.stringify(JSON.parse(blogData?.json_text ? blogData?.json_text : "")),
            banner: blogData?.banner,
            is_favourite: blogData?.is_favourite,
            category_id: blogData?.category?.id,
            status: type === "accept" ? 2 : 3,
            points
        };

        if (blogs_id) {
            $editDashboardBlogs(+blogs_id, updatedList, { newApi: true }).then(res => {
                if (!res) return;

                navigate("/blogs");
            });
        }
    }

    return (
        <div className="blogs-show">
            {blogs_id && blogData && (
                <BlogsPointsModal
                    id={+blogs_id}
                    isOpen={modalOpen === "points"}
                    onClose={() => setModalOpen("")}
                    setLoading={setIsLoading}
                    blogData={blogData}
                    setPoints={setPoints}
                    points={points}
                />
            )}

            {blogs_id && blogData && (
                <BlogsRejectModal
                    id={+blogs_id}
                    isOpen={modalOpen === "reject"}
                    onClose={() => setModalOpen("")}
                    setLoading={setIsLoading}
                    blogData={blogData}
                />
            )}

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{blogData?.title}</h1>
                    </div>
                )}
            </div>

            {blogData ? (
                <div className="blogs-show-content">
                    <div className="news-show-content__left">
                        <div className="news-show-info">
                            <BlankImage image={blogData.banner || ""}
                                        classname={"news-show-info__image"} />

                            <div className="blogs-show-info__content">
                                <div className="blogs-show-info__header">
                                    <div
                                        className="news-show-info__header__practice">{blogData?.category?.title}</div>

                                    <div
                                        onClick={handleToggleFavourite}
                                        className={`blogs-card-block-content__likeIcon ${blogData.is_favourite ? "active" : ""}`}
                                    >
                                       <StarIcon opacity={!blogData.is_favourite ? 0.3 : 1}/>
                                    </div>
                                </div>

                                <div className="blogs-show-info__editor">
                                    <div dangerouslySetInnerHTML={{ __html: blogData?.text }} />
                                </div>

                                <div className="blogs-show-info__date">
                                    <ClockIcon /> <p>{getYearMonthDay(blogData.created_at)}</p>
                                </div>

                                {isModeration ? (
                                    <div className="blogs-show-info__bottom">
                                        <div
                                            className={"d-flex w-100 gap-3 services-show-content__buttons"}>
                                            <Button
                                                text={
                                                    <>
                                                        {points ? (
                                                            <div
                                                                className="ideas-show-info__profile__button"
                                                                style={{ background: "none" }}>
                                                                <div
                                                                    className="ideas-show-info__profile__button__left">
                                                                    <ProfileBalanceIcon />

                                                                    {points}
                                                                </div>

                                                                <h5>Изменить</h5>
                                                            </div>
                                                        ) : (
                                                            "Баллы за пост"
                                                        )}
                                                    </>
                                                }
                                                className={"btn btn-light w-100"}
                                                onClick={() => setModalOpen("points")}
                                            />

                                            <Button
                                                text={"Отклонить"}
                                                className={"btn btn-red w-100"}
                                                onClick={() => setModalOpen("reject")}
                                            />

                                            <Button
                                                type={"submit"}
                                                text={"Подтвердить"}
                                                className={"btn btn-primary w-100"}
                                                onClick={() => handleAcceptOrRejectBlog("accept")}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="blogs-show-info__bottom">
                                        {isMobile && (
                                            <div className="blogs-show-info__stats">
                                                <div className="blogs-show-info__stats__block">
                                                    <LikeIcon color="#000" size={20} />

                                                    <p>{blogData.likes_count}</p>
                                                </div>

                                                <div className="blogs-show-info__stats__block">
                                                    <CommentIcon color="#000" size={20} />

                                                    <p>{blogData.comments_count}</p>
                                                </div>

                                                <div className="blogs-show-info__stats__block">
                                                    <EyeIcon color="#000" size={20} />

                                                    <p>{blogData.views_count}</p>
                                                </div>
                                            </div>
                                        )}

                                        <div className="blogs-show-info__buttons">
                                            <button
                                                className={`news-show-info__buttons__likeButton`}
                                                onClick={handleToggleLike}
                                            >
                                                <LikeIcon
                                                    size={24}
                                                    strokeColor={blogData.is_liked ? "white" : ""}
                                                /> <p>Мне нравится</p>
                                            </button>
                                            {/*<button className="blogs-show-info__buttons__likeButton"*/}
                                            {/*        onClick={handleToggleLike}>*/}
                                            {/*    <LikeIcon size={24} color="#00A7B5" /> <p>Мне*/}
                                            {/*    нравится</p>*/}
                                            {/*</button>*/}

                                            <button
                                                onClick={handleCopy}
                                                className="blogs-show-info__buttons__shareButton">
                                                <ShareIcon size={24} color="#00A7B5" />
                                                <p>Поделиться</p>
                                            </button>
                                        </div>

                                        {!isMobile && (
                                            <div className="blogs-show-info__stats">
                                                <div className="blogs-show-info__stats__block">
                                                    <LikeIcon color="#000" size={20} />

                                                    <p>{blogData.likes_count}</p>
                                                </div>

                                                <div className="blogs-show-info__stats__block">
                                                    <CommentIcon color="#000" size={20} />

                                                    <p>{blogData.comments_count}</p>
                                                </div>

                                                <div className="blogs-show-info__stats__block">
                                                    <EyeIcon color="#000" size={20} />

                                                    <p>{blogData.views_count}</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>


                        {blogs_id && !isModeration && blogData.status !== 1 && (
                            <BlogsCommentsMessenger
                                blog_id={+blogs_id}
                                is_notify={blogData.is_notify == 1 || false}
                                isCommentLoading={isLoading}
                                setIsCommentLoading={setIsLoading}
                            />
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    );
};