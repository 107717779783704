import React, { FC } from "react";
import downloadFile from "../../../utils/file/download-file";
import { SaveIcon } from "../../../img/icons/others/SaveIcon";
import { BlankImage } from "../../ui/blank-image/BlankImage";
import { CloseIcon } from "../../../img";


interface IMediaFileItem {
    data: any;
    type: string;
    hasDownload?: boolean;
    hasDelete?: boolean;
    onDelete?: () => void;
}

export const MediaFileItem: FC<IMediaFileItem> = (
    {
        data,
        type,
        onDelete
    }) => {

    const filePath = data.file_path;
    const fileName = data.file_path.split("/").pop();

    const isImageFile = (): boolean => {
        // List of common image file extensions
        const imageExtensions = [".jpg", ".jpeg", ".png", ".gif", ".bmp", ".tiff", ".webp"];

        // Extract the file extension from the path
        const extension = filePath.slice(filePath.lastIndexOf(".")).toLowerCase();

        // Check if the extension matches any in the list
        return imageExtensions.includes(extension);
    };

    return (
        <div className={`media-files-${type}__file`} key={data.id}
             onClick={() => downloadFile(filePath, fileName)}>
            <div className={`media-files-${type}__file__info`}>
                {
                    isImageFile() ? (
                        <div className="media-files-show__file__image">
                            <BlankImage image={filePath} />
                        </div>
                    ) : (
                        <div className="media-files-show__file__ext">
                            {data.extension}
                        </div>
                    )
                }

                {
                    type == "show" && (
                        <div className={`media-files-${type}__file__download`}>
                            <SaveIcon />
                        </div>
                    )
                }

                {
                    type == "create" && (
                        <div className={`media-files-${type}__file__delete`}
                             onClick={onDelete}>
                            <CloseIcon width={14} height={14} />
                        </div>
                    )
                }
            </div>

            <div className="media-files-show__file__name">
                {data.name}
            </div>
        </div>
    );
};