import { useEffect, useState } from "react";
import { $getStatuses, $setStatus } from "../../api/requests/statuses";
import { IStatusItem } from "../../api/requests/statuses/interface";
import {
    changeProfile,
    getPersonData,
    getProfileVarsData,
    useAppDispatch
} from "../../redux";
import { useDispatch, useSelector } from "react-redux";

interface StatusCardProps {
    isOtherUser?: boolean;
}

export const StatusCard = (
    {
        isOtherUser
    }: StatusCardProps
) => {
    const dispatch = useDispatch();

    const profileData = useSelector(getPersonData);
    const vars = useSelector(getProfileVarsData);

    const [activeId, setIsActiveId] = useState(profileData?.status_id?.toString())
    const [data, setData] = useState<IStatusItem[]>([]);

    useEffect(() => {
        init();
    }, []);

    function init() {
        $getStatuses().then(res => {
            if (!res?.statuses) return;

            setData(res.statuses);
        });
    }

    function changeStatus(item: IStatusItem) {
        if (isOtherUser) return;

        const requestData = {
            status_id: item.id
        }

        setIsActiveId(item.id)

        $setStatus(requestData, {formData: true}).then(res => {
            if (!res) return;

            dispatch(changeProfile(res));
        })
    }

    return (
        <div className="layout-badges">
            <div className='layout-congratulations__header'>
                <h1>Статусы</h1>

                <p>{data.length}</p>
            </div>

            <div className="layout-badges__list">
                {data.map((item, idx) => {
                    return (
                        <div
                            key={`badge-status-item-${idx}`}
                            className={`layout-badges__item`}
                            style={{
                                borderColor: activeId === item.id ? vars.colors.primaryColor : ""
                            }}
                            onClick={() => changeStatus(item)}
                        >
                            <img src={item.icon} />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};