import {
    ArrowExitIcon,
    SearchIcon,
} from "../../img";
import { useNavigate } from "react-router-dom";
import { NewsCard } from "../../components/news";
import React, { useEffect, useState } from "react";
import { $getNewsList } from "../../api/requests/news";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import TextField from "../../components/ui/form/Fields/TextField";

export const NewsDraftPage = () => {
    const profileData = useSelector(getPersonData);
    const navigate = useNavigate();
    const isMobile = isMd();

    const [newsData, setNewsData] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [searchValue, setSearchValue] = useState<string>('');

    useEffect(() => {
        init();
    }, [])

    function init() {
        getNewsList();
    }

    function getNewsList() {
        $getNewsList(
            {
                workspace_id: profileData?.workspace_id || undefined,
                offset: 0,
                limit: 30,
                cat_id: -1,
            }, { formData: true }).then(res => {
            if (res.code) return;

            setNewsData(res);
            setFilterList(res);
        })
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...newsData].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase()));
        });

        setFilterList(filterList);
    }

    return (
        <div className='news'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                ) }

                <div className='news-search'>
                    <div className="search__block-field">
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"black"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                </div>
            </div>

            <div className="container news-content">
                {filterList.length ? (
                    <div className="news-content__items">
                        {filterList.map((item, idx) => (
                            <div key={idx} onClick={() => navigate(`/news/show/${item?.id}`)}>
                                <NewsCard data={item} />
                            </div>
                        ))}
                    </div>
                    ) : (
                    <div
                        className={"d-flex align-items-center justify-content-center p-5"}
                    >
                        Новостей нет
                    </div>
                )}
            </div>
        </div>
    )
}