import api from '../../../api'
import { IApiHandlerParams } from '../../interface'
import { BirthdayResponseData, IdData, WorkspaceEditData, WorkspaceLinkData } from "./interface";

let LocalMainPageNewData:Promise<any>;

let LocalsTimeout = 5 * 1000;
let LocalsTs:number;

export const $mainPageNewData = (
    handler?: IApiHandlerParams
): Promise<any> => {
    let now = new Date().getTime();
    let check = LocalsTs ? LocalsTs + LocalsTimeout : now;
    if(LocalMainPageNewData && now < check){
        return LocalMainPageNewData;
    }
    LocalsTs = now;
    LocalMainPageNewData = api.post('api/workspaces/mainpageNew', null, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })
    return LocalMainPageNewData;
}
    
export const $workSpaceEdit = (
    data: WorkspaceEditData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/workspaces/edit', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $getLinkList = (handler?: IApiHandlerParams): Promise<any> =>
    api.post(
        'api/workspaces/linkList',
        {},
        {
            // mock: require('./mocks/complete.form.calculate.json'),
            handler,
        }
    )

export const $linkCreate = (
    data: WorkspaceLinkData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/workspaces/linkCreate', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $linkEdit = (
    data: WorkspaceLinkData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/workspaces/linkEdit', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })
export const $linkDelete = (
    data: IdData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/workspaces/linkDelete', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $getWelcomeLinkSettings = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`backend/v1/workspaces/${id}/settings/welcome-link`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $getBirthdays = (
    period: number,
    handler?: IApiHandlerParams
): Promise<BirthdayResponseData> =>
    api.get(`api/v1/users/birthdays`, {
        // mock: require('./mocks/complete.form.calculate.json'),
        request: {
            params: { period }
        },
        handler,
    })
