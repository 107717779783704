import { FC } from "react";
import { IBaseIconProps } from "../types";

export const ModerationIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "black"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 22" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.40703 20.5998H3.60702C2.28154 20.5998 1.20702 19.5253 1.20703 18.1998L1.20712 3.79989C1.20713 2.47441 2.28165 1.3999 3.60712 1.3999H14.4074C15.7329 1.3999 16.8074 2.47442 16.8074 3.7999V10.3999M15.4012 20.1881V16.794M15.4012 16.794V13.3999M15.4012 16.794L12.007 16.794M15.4012 16.794L18.7953 16.794M5.40741 6.1999H12.6074M5.40741 9.7999H12.6074M5.40741 13.3999H9.00741"
                stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};