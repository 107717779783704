import { IBlogsRes } from "../../api/requests/blogs/interface";
import React, { FC } from "react";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, StarIcon } from "../../img";
import { ClockIcon } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { formatDate, getYearMonthDay } from "../../utils";
import { $toggleFavouriteBlog } from "../../api/requests/blogs";
import {$toggleUserFavorites} from "../../api/requests/favorites";

interface IBlogsCard {
    data: IBlogsRes,
    setIsLoading: any,
    isLoading: boolean,
}

export const BlogsCard: FC<IBlogsCard> = ({
    data,
    setIsLoading,
    isLoading
}) => {
    const navigate = useNavigate();

    function handleToggleFavourite(e: any) {
        e.stopPropagation()
        setIsLoading(true);

        $toggleUserFavorites({type: 'blog', type_id: data.id, add_to_favorites: !data.is_favourite}, {
            newApi: true,
        }).then(res => {
            setIsLoading(false);
        });
    }

    return (
        <div className='blogs-card'>
            <div className='blogs-card-user'>
                <BlankImage image={data?.author?.photo || ''} classname='blogs-card-user__image'/>

                <div className='blogs-card-user__content'>
                    <h1 className='blogs-card-user__content__name'>{ data?.author?.name }</h1>
                    <p className='blogs-card-user__content__position'>{data?.author?.position}</p>
                </div>
            </div>

            <div className='blogs-card-block'>
                <BlankImage image={data?.banner || ''} classname='blogs-card-block__image'/>

                <div className='blogs-card-block-content'>
                    <div className='blogs-card-block-content__top'>
                        <h1 className='blogs-card-block-content__title'>
                            { data?.title }
                        </h1>

                        <div
                            onClick={handleToggleFavourite}
                            className={`blogs-card-block-content__likeIcon ${data.is_favourite ? "active" : ""}`}
                        >
                            <StarIcon opacity={!data.is_favourite ? 0.3 : 1}/>
                        </div>
                    </div>

                    <div className='blogs-card-block-content__category'>
                        { data?.category?.title }
                    </div>

                    <div className='blogs-card-block-content__text'>
                        <div>{data?.cleanedText}</div>
                    </div>

                    <div style={{marginTop: 'auto', height: 'auto'}}>

                    </div>

                    <div className='blogs-card-block-content__bottom'>
                        <div className='blogs-card-block-content__bottom__block '>
                            <ClockIcon />

                            <p>{data?.created_at && getYearMonthDay(data?.created_at)} </p>
                        </div>

                        <div className='blogs-card-block-content__bottom__right'>
                            <div
                                className='blogs-card-block-content__bottom__block blogs-card-block-content__bottom__likeContent'>
                                <LikeIcon color='#000' />

                                <p>{ data?.likes_count || 0}</p>

                            </div>

                            <div className='blogs-card-block-content__bottom__block '>
                                <CommentIcon color='#000' />

                                <p>{ data?.comments_count || 0}</p>
                            </div>

                            <div className='blogs-card-block-content__bottom__block '>
                                <EyeIcon color='#000' />

                                <p>{ data?.views_count || 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}