import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import { FullCalendarComponentEventItem } from "./FullCalendarComponentEventItem";

import { getShowContentLeft } from "../../../../redux/reducers/booking/bookingRoomsSlice";

interface Room {
    id: number;
    initiator_id?: number;
    title: string;
    is_expired: boolean;
    members?: any[]; // Adjust the type according to the structure of members
    backgroundColor?: string;
    backgroundActionColor?: string;
    event_date: string;
    start_time: number;
    end_time: number;
    description: string;
    initiator?: {
        id?: number;
        phone?: number;
        photo?: string;
        name?: string;
    };
}

interface FullCalendarComponentDayProps {
    initialView?: string;
    slotMinTime?: string;
    slotMaxTime?: string;
    selectedDate?: string;
    meetRooms: Room[];
    onClickCalendar?: (item: Room | undefined) => void;
    onClickCalendarNew?: (info: any) => void;
}

export const FullCalendarComponentDay = (
    {
        initialView = "timelineDay",
        slotMinTime,
        slotMaxTime,
        selectedDate,
        meetRooms = [],
        onClickCalendar,
        onClickCalendarNew
    }: FullCalendarComponentDayProps
) => {
    const isShowLeftContent = useSelector(getShowContentLeft);
    const calendarRef = useRef<FullCalendar>(null);

    const searchData: any = (id: number) => {
        return meetRooms.find((room) => room.id === id);
    };

    useEffect(() => {
        if (calendarRef.current && selectedDate) {
            setTimeout(() => {
                calendarRef.current && calendarRef.current.getApi().gotoDate(selectedDate)
            })
        }
    }, [selectedDate]);

    return (
        <>
            <FullCalendar
                ref={calendarRef}
                plugins={[timeGridPlugin, resourceTimelinePlugin, interactionPlugin]}
                initialView={initialView}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                locale="ru"
                headerToolbar={false}
                displayEventTime
                selectable
                nowIndicator
                slotLabelFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    omitZeroMinute: false,
                    meridiem: "short"
                }}
                height={isShowLeftContent ? "700px" : "500px"}
                slotLabelInterval="01:00"
                slotDuration="00:30:00"
                slotMinTime={slotMinTime || "08:00:00"}
                slotMaxTime={slotMaxTime || "20:00:00"}
                slotLabelContent={({ text, date, view }) => {
                    const hours = date.getHours();

                    const modifiedText = hours % 2 === 0 ? text : '•';

                    return (
                        <span className="customTimeLine">
                            {modifiedText}
                            <span>{slotMaxTime?.split(":")?.slice(0, 2)?.join(":") || "20:00"}</span>
                        </span>
                    )
                }}
                firstDay={1}
                eventContent={(info) => (
                    <FullCalendarComponentEventItem
                        info={info}
                        item={searchData(+info.event.id)}
                    />
                )}
                eventTextColor="#000"
                eventBorderColor="transparent"
                //@ts-ignore
                resourceRender={(renderInfo: any) => {
                    renderInfo.el.style.backgroundColor = "blue";
                }}
                dateClick={(info) => {
                    const currentDate = new Date();
                    const selectedDate = info.date;

                    if (currentDate > selectedDate) {
                        return;
                    }

                    if (onClickCalendarNew) onClickCalendarNew(info);
                }}
                eventClick={(info) => {
                    const item = meetRooms.find((room) => room.id === +info.event.id);
                    if (onClickCalendar) onClickCalendar(item);
                }}
                events={meetRooms as any}
            />
        </>
    );
};
