import {ArrowExitIcon} from "../../../../img";
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {getYearMonthDayAndTime, isMd} from "../../../../utils";
import {Controller, useForm} from "react-hook-form";
import DatePickerForm from "../../../../components/ui/form/DatePickerForm";
import Button from "../../../../components/ui/form/Buttons/Button";
import FileClipField from "../../../../components/ui/form/FileField/FileClipField";
import {UploadIcon} from "../../../../img/icons/files/UploadIcon";
import {$uploadServiceApplicationFile} from "../../../../api/requests/services";
import {useSelector} from "react-redux";
import {getPersonData} from "../../../../redux";
import {BlankAva} from "../../../../components/ui/avatars/BlankAva";
import {
    $createServiceVacation,
    $getServiceVacation,
    $getServiceVacationItem
} from "../../../../api/requests/services/vacation";
import TextField from "../../../../components/ui/form/Fields/TextField";
import moment from "moment/moment";

export const ServicesVacationCreate = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const location = useLocation();
    const pathname = location.pathname;

    const { id: vacationId } = useParams();
    const isView: boolean = pathname.includes("view");

    const personalData = useSelector(getPersonData);
    const leader = personalData?.structure?.team?.leader

    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const [isAlreadySent, setIsAlreadySend] = useState(false)
    const [data, setData] = useState<any>()

    const [uploadFileList, setUploadFileList] = useState<any>(undefined);

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Отклонен',
            value: -1
        },
        {
            label: 'Создан',
            value: 0
        },
        {
            label: 'Успешно',
            value: 1
        },
    ])

    const findStatus = statusesData.find(item => item.value == data?.status)

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: {errors, isValid},
        trigger
    } = useForm<any>();

    const btnListRemove = [
        {
            text: "Отлично",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    const getFileName = (url: string) => {
        const parts: any = url?.split("/");
        return parts[parts?.length - 1];
    };

    useEffect(() => {
        $getServiceVacation(0, {newApi: true}).then(res => {
        })
    }, [])

    useEffect(() => {
        if (vacationId) {
            $getServiceVacationItem(+vacationId, {newApi: true}).then(res => {
                if (res.data) {
                    setIsAlreadySend(true)
                    setData(res?.data)
                }
                else {
                    setIsAlreadySend(false)
                }
            })
        }
    }, [isView])

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        $uploadServiceApplicationFile({file: item.file}, {
                            newApi: true,
                            formData: true
                        }).then(res => {
                            setUploadFileList({file: reader.result});
                            setValue("file", res.data.url);
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }

    function onSubmit(obj: any) {
        const updatedList = {
            ...obj,
            date_from:  moment(obj.start_date || new Date()).format('YYYY-MM-DD'),
            date_to:  moment(obj.end_date || new Date()).format('YYYY-MM-DD')
        }
        $createServiceVacation(updatedList, {newApi: true}).then(res => {

        })
    }


    // console.log("AAA", statusesData.find(item => item.value == data?.status)?.label)
    return (
        <>
            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services/vacation")}>
                            <ArrowExitIcon/>
                        </div>

                        <h1>Отпуск</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <div className='services-applications-create__block__left'>
                        <h5 className="services-applications-create__title">Бронирование отпуска</h5>

                        <div
                            style={{background: (findStatus?.label != 'Рассмотрение' && findStatus?.value == 1) ? '#00A7B5' : '' }}
                            className={`services-applications-create__status ${findStatus?.value == 0 ? 'waiting' : ''}`}>

                            {findStatus?.label || 'Рассмотрение'}
                        </div>
                    </div>

                    <p className="services-applications-create__text">Москва, Мясницкая 35, 3-й этаж</p>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__dates">
                                <Controller
                                    name={"date_from"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            disabled={isAlreadySent}
                                            startDate={value || data?.date_from}
                                            minDate={new Date(minDate)}
                                            maxDate={watch().end_date}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={"date_to"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            startDate={value || data?.date_to}
                                            disabled={isAlreadySent}
                                            className=""
                                            minDate={watch().start_date || new Date(minDate)}
                                            maxDate={new Date(maxDate)}
                                            label="Дата завершения"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <Controller
                                name={'reason'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Напишите название"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        disabled={isAlreadySent}
                                        value={value || data?.reason}
                                        className=""
                                        title={'Основание'}
                                        placeholder={"Введите причину "}
                                        name="name"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <div className='services-applications-create__file'>
                                <div className='services-applications-create__file__header'>
                                    <p className='services-applications-create__file__header__label'>Согласующий</p>
                                </div>

                                <div className="layout-vacation-modal__block"
                                     style={{background: '#00000008', padding: '10px', borderRadius: 16}}
                                >
                                    <div className="layout-vacation-modal__block__right">
                                        {leader?.user?.photo ? (
                                            <img src={leader?.user?.photo} alt=""/>
                                        ) : (
                                            <BlankAva
                                                colorBg={"#CCD9E0"}
                                                colorText={"black"}
                                                text={leader?.user?.name}
                                            />
                                        )}

                                        <div className="layout-vacation-modal__block__desc">
                                            <h1>{leader?.user?.name}</h1>

                                            <p className="layout-vacation-modal__block__desc__position">{leader?.title}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`services-applications-create__file ${isAlreadySent ? 'isLocked' : ''}`}>
                                <div className='services-applications-create__file__header'>
                                    <p className='services-applications-create__file__header__label'>Прикрепить файл</p>

                                </div>

                                <Controller
                                    name="file"
                                    disabled={isAlreadySent}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={".docx"}
                                            label={""}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList || isAlreadySent}
                                            uploadName={getFileName(uploadFileList?.file || data?.file || '')}
                                            text={'Ограничения на загрузку файла не более 100 мб.'}
                                            icon={<UploadIcon size={44}/>}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-view-status__list">
                                {data?.logs?.map((item: any, idx: number) => (
                                    <div className="services-view-status" key={idx}>
                                        <div className="services-view-status__dots">
                                            <div
                                                className="services-view-status__dots__circle"></div>

                                            {idx !== data.logs.length - 1 && (
                                                <div
                                                    className="services-view-status__dots__line"></div>
                                            )}
                                        </div>

                                        <div className="services-view-status__info">
                                            <h5 className="services-view-status__info__title">{item.status}</h5>

                                            <div className="services-view-status__info__sender">
                                                <p>Отправитель:</p>

                                                <h5>{item?.user_name}</h5>
                                            </div>

                                            {item.comment && (
                                                <div
                                                    className="services-view-status__info__sender">
                                                    <p>Комментарий:</p>

                                                    <h5>{item?.comment}</h5>
                                                </div>
                                            )}

                                            <p className="services-view-status__info__date">
                                                {getYearMonthDayAndTime(item?.updated_at)}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {!isView && (
                                <Button
                                    type={"submit"}
                                    text={"Отправить"}
                                    className={"btn btn-primary w-100"}
                                />
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}