export const IconThirdPlace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"
             fill="none">
            <path
                d="M6.17212 8.14368L8.29405 8.04444C8.82807 8.04916 9.11163 8.04444 9.19197 8.48867L9.65038 11.811C9.66456 11.948 9.8725 12.1276 9.91976 12.208C10.0521 12.104 10.1324 11.9386 10.1088 11.759L9.72599 8.67771C9.63148 7.94047 9.00293 7.38281 8.25624 7.38281H6.18157V8.13896L6.17212 8.14368Z"
                fill="#F7B57E" />
            <path
                d="M6.17669 7.41797V8.16939L6.37045 8.17884L7.38179 8.13631L8.27026 8.0985C8.31279 8.0985 8.36005 8.0985 8.39786 8.0985C8.70977 8.0985 8.92716 8.13158 9.04058 8.30171C9.08311 8.35842 9.12092 8.46239 9.13983 8.56636L9.30996 9.814L9.5179 11.3405C9.5179 11.3405 9.59824 11.8603 9.61242 11.8698C9.62659 11.9312 9.67385 11.9974 9.73056 12.0588C9.79673 12.1297 9.86761 12.1911 9.89597 12.2242C10.0283 12.1202 10.1086 11.9548 10.085 11.78L9.89124 10.2441L9.70221 8.70814C9.60769 7.9709 8.97915 7.41797 8.23718 7.41797H6.16724H6.17669Z"
                fill="#F4B077" />
            <path
                d="M6.17676 7.44727V8.19396L6.35162 8.22704L7.36296 8.18923L8.25143 8.15615C8.28923 8.15615 8.34594 8.15615 8.37903 8.15615C8.68621 8.15615 8.89887 8.20341 9.00757 8.37827C9.04538 8.43025 9.08319 8.5484 9.09736 8.64292L9.26749 9.88583L9.47543 11.4076C9.47543 11.4406 9.56523 11.9085 9.59358 11.9227C9.61249 11.9794 9.66447 12.0408 9.72118 12.0928C9.78734 12.1543 9.86296 12.2062 9.89604 12.2346C10.0284 12.1306 10.104 11.9652 10.0803 11.7904L9.89131 10.2639L9.70228 8.73743C9.60776 8.00492 8.98394 7.45199 8.2467 7.45199H6.18621L6.17676 7.44727Z"
                fill="#F2AB71" />
            <path
                d="M6.17205 7.48047V8.22716L6.32328 8.27915L7.33462 8.24606L8.21836 8.21771C8.25145 8.21771 8.31761 8.21771 8.34596 8.21771C8.6437 8.21771 8.85636 8.28387 8.9556 8.46346C8.99341 8.51072 9.02649 8.64304 9.04067 8.72811L9.20608 9.96629L9.40929 11.4833C9.41402 11.5353 9.50381 11.9653 9.54634 11.9842C9.56997 12.0362 9.62196 12.0929 9.68339 12.1402C9.75428 12.1969 9.8299 12.2394 9.86298 12.2536C9.9953 12.1497 10.0709 11.9842 10.0473 11.8094L9.85825 10.2877L9.66921 8.76591C9.5747 8.0334 8.9556 7.4852 8.21836 7.4852H6.1626L6.17205 7.48047Z"
                fill="#EFA66A" />
            <path
                d="M6.17212 7.50977V8.25173L6.30445 8.32735L7.31579 8.29899L8.19953 8.27064C8.22789 8.27064 8.3035 8.27064 8.32713 8.27064C8.62013 8.27064 8.82807 8.35097 8.91787 8.53528C8.95095 8.57309 8.98403 8.72905 8.99821 8.79994L9.16361 10.0334L9.36683 11.5457C9.37628 11.6118 9.46607 12.0088 9.52751 12.0324C9.55586 12.0797 9.61257 12.1317 9.67401 12.1695C9.7449 12.2168 9.82051 12.2498 9.86305 12.2593C9.99537 12.1553 10.071 11.9899 10.0474 11.8198L9.85832 10.3028L9.66928 8.78576C9.57949 8.05797 8.95567 7.50977 8.22316 7.50977H6.17685H6.17212Z"
                fill="#ECA164" />
            <path
                d="M6.17212 7.53906V8.28103L6.28554 8.37555L7.29216 8.35192L8.1759 8.32829C8.19953 8.32829 8.27987 8.32829 8.3035 8.32829C8.58705 8.32829 8.79499 8.42281 8.88006 8.61657C8.90841 8.64965 8.9415 8.81978 8.95095 8.88122L9.11635 10.1147L9.31484 11.6175C9.32429 11.7026 9.42354 12.0617 9.49915 12.0901C9.53223 12.1374 9.59367 12.1799 9.65511 12.213C9.73072 12.2508 9.80634 12.2744 9.84887 12.2744C9.98119 12.1752 10.0568 12.0098 10.0332 11.8349L9.84414 10.3226L9.65511 8.81033C9.56532 8.08254 8.94622 7.53906 8.21371 7.53906H6.17212Z"
                fill="#E99C5D" />
            <path
                d="M6.17212 7.56723V8.30447L6.26664 8.41789L7.27325 8.39899L8.157 8.38008C8.157 8.38008 8.26569 8.38008 8.2846 8.38008C8.56342 8.38008 8.77136 8.48877 8.84225 8.68726C8.86588 8.71562 8.89896 8.89993 8.90841 8.95192L9.0691 10.1806L9.26758 11.6788C9.28176 11.778 9.381 12.1041 9.4708 12.1372C9.5086 12.1797 9.57004 12.2175 9.6362 12.2411C9.71182 12.2695 9.79216 12.2884 9.83469 12.2789C9.96229 12.1797 10.0426 12.0143 10.019 11.8394L9.82996 10.3319L9.64093 8.82904C9.55114 8.10598 8.93204 7.5625 8.20425 7.5625H6.17212V7.56723Z"
                fill="#E79657" />
            <path
                d="M6.17212 7.59375V8.33099L6.24773 8.46804L7.25435 8.44914L8.13337 8.43496C8.13337 8.43496 8.24679 8.43496 8.26097 8.43496C8.53034 8.43496 8.73828 8.55783 8.80444 8.76577C8.82335 8.7894 8.85643 8.98789 8.86116 9.03042L9.02184 10.2544L9.2156 11.7478C9.22978 11.866 9.33847 12.1542 9.44244 12.192C9.48497 12.2299 9.55114 12.2629 9.6173 12.2818C9.69764 12.3055 9.77325 12.3102 9.82524 12.2913C9.95284 12.192 10.0332 12.0266 10.0095 11.8565L9.82051 10.3584L9.63148 8.86029C9.54168 8.14195 8.92732 7.59848 8.19953 7.59848H6.17212V7.59375Z"
                fill="#E49150" />
            <path
                d="M6.17196 7.62777V8.36029L6.22867 8.51624L7.23056 8.50206L8.10958 8.49261C8.10958 8.49261 8.223 8.49261 8.23245 8.49261C8.4971 8.49261 8.70032 8.62966 8.75703 8.84233C8.77121 8.86123 8.80429 9.07863 8.80901 9.10698L8.96497 10.3263L9.15873 11.8149C9.17763 11.9472 9.28633 12.2024 9.40448 12.245C9.45646 12.2781 9.52262 12.3064 9.58879 12.3159C9.66913 12.33 9.74947 12.3253 9.80145 12.3017C9.92905 12.2024 10.0047 12.037 9.98104 11.8669L9.79673 10.3735L9.60769 8.88014C9.5179 8.1618 8.90353 7.62305 8.18519 7.62305H6.16724L6.17196 7.62777Z"
                fill="#E18C4A" />
            <path
                d="M6.17212 7.65707V8.38958L6.20993 8.56917L7.21182 8.55972L8.09083 8.55027H8.21371C8.46891 8.55027 8.67212 8.70149 8.71938 8.92361C8.72883 8.93306 8.76191 9.16936 8.76664 9.18826L8.92259 10.4028L9.11163 11.8867C9.13053 12.038 9.24395 12.2554 9.381 12.3026C9.43772 12.3357 9.50388 12.3546 9.57477 12.3593C9.65983 12.3641 9.74017 12.3499 9.79216 12.3168C9.91976 12.2176 9.99537 12.0569 9.97174 11.882L9.78743 10.3934L9.60312 8.90471C9.51333 8.1911 8.90369 7.65234 8.18535 7.65234H6.17212V7.65707Z"
                fill="#DE8743" />
            <path
                d="M6.17212 7.68441V8.4122L6.19102 8.61069H7.19291L8.07193 8.60124H8.1948C8.44055 8.60124 8.64376 8.76664 8.6863 8.99349C8.6863 8.99821 8.7241 9.24868 8.7241 9.26286L8.87533 10.4727L9.06437 11.9519C9.088 12.1173 9.20615 12.3016 9.35265 12.3536C9.41408 12.382 9.48497 12.3961 9.55586 12.3914C9.64565 12.3914 9.72127 12.3678 9.7827 12.3252C9.9103 12.226 9.98592 12.0653 9.96229 11.8952L9.77798 10.4113L9.59367 8.92732C9.50388 8.21371 8.89424 7.67969 8.18063 7.67969H6.17685L6.17212 7.68441Z"
                fill="#DC823D" />
            <path
                d="M6.17212 8.6592H8.17117C8.41219 8.6592 8.61068 8.83879 8.64376 9.07508L8.82808 10.5496L9.01238 12.024C9.04547 12.284 9.27704 12.4683 9.53696 12.4352C9.62675 12.4257 9.70709 12.3927 9.76853 12.3407C9.89613 12.2414 9.97174 12.0808 9.94811 11.9106L9.7638 10.4361L9.57949 8.96166C9.4897 8.25278 8.88478 7.71875 8.17117 7.71875H6.17212V8.66393V8.6592Z"
                fill="#D97D36" />
            <path
                d="M9.51797 8.96046C9.42818 8.2563 8.82326 7.76953 8.1191 7.76953H6.44141L6.17676 7.78844V7.86877L6.23819 8.65327H7.84027C7.84027 8.65327 8.19472 8.65327 8.19944 8.66273C8.36012 8.66745 8.5019 8.75724 8.57751 8.88957C8.61059 8.94155 8.63895 9.01717 8.6484 9.08806L8.82799 10.5389L8.84216 10.6476L9.00757 11.985C9.02647 12.141 9.12572 12.2969 9.25804 12.3584C9.32893 12.4056 9.40927 12.4198 9.49434 12.4104C9.5794 12.4009 9.65974 12.3678 9.72591 12.3206C9.84405 12.2308 9.91022 12.0701 9.88659 11.8952C9.88659 11.8669 9.71173 10.4822 9.70228 10.4444C9.69283 10.3735 9.51324 8.96046 9.51324 8.96046H9.51797Z"
                fill="#D27732" />
            <path
                d="M9.45662 8.96629C9.36683 8.26685 8.76664 7.82734 8.06248 7.83207H6.41314L6.17212 7.8746V7.95494L6.29499 8.6591H7.86872C7.86872 8.6591 8.20898 8.66383 8.22316 8.67801C8.37911 8.69218 8.51144 8.78197 8.58233 8.9143C8.61541 8.96156 8.63904 9.04663 8.64849 9.10806L8.82808 10.5353L8.84225 10.644L9.00766 11.9578C9.02656 12.0948 9.12108 12.2791 9.2345 12.3311C9.30066 12.3878 9.37155 12.402 9.45662 12.3926C9.53696 12.3831 9.61257 12.35 9.68346 12.3028C9.79216 12.2224 9.85359 12.0665 9.82997 11.8869C9.82524 11.8302 9.66456 10.54 9.65038 10.4597C9.63148 10.3179 9.45662 8.96629 9.45662 8.96629Z"
                fill="#CC712E" />
            <path
                d="M9.39046 8.967C9.30066 8.27229 8.7052 7.88477 8.00577 7.88477H6.38478L6.17212 7.95093V8.03127L6.35643 8.65981H7.9018C7.93015 8.65981 8.22788 8.66927 8.24679 8.68344C8.39802 8.70235 8.52089 8.79686 8.58705 8.92919C8.61541 8.97172 8.63904 9.06624 8.64849 9.11823L8.82335 10.5218L8.83753 10.6258L8.99821 11.916C9.01238 12.0388 9.10218 12.2468 9.20615 12.294C9.26758 12.3602 9.33375 12.3791 9.40936 12.3649C9.48497 12.3555 9.56059 12.3224 9.6362 12.2799C9.73545 12.209 9.78743 12.053 9.7638 11.8687C9.75435 11.7884 9.60312 10.588 9.58422 10.4651C9.55114 10.2524 9.38573 8.967 9.38573 8.967H9.39046Z"
                fill="#C56B2A" />
            <path
                d="M9.32886 8.9697C9.23907 8.27972 8.64361 7.93945 7.9489 7.93945H6.35627L6.16724 8.02452V8.10959L6.41771 8.65779H7.93472C7.97253 8.65779 8.24191 8.67197 8.27026 8.69087C8.41204 8.71922 8.53019 8.81374 8.59162 8.94607C8.61525 8.98388 8.63888 9.08785 8.64833 9.1351L8.81847 10.5151L8.83264 10.619L8.9886 11.8809C9.00278 11.9895 9.08784 12.2164 9.17763 12.2589C9.23434 12.3345 9.29105 12.3534 9.36667 12.3393C9.43756 12.3298 9.51317 12.2967 9.59351 12.2589C9.6833 12.1975 9.72584 12.0415 9.70221 11.8525C9.69276 11.7438 9.55098 10.6379 9.52735 10.4725C9.48482 10.189 9.32414 8.96497 9.32414 8.96497L9.32886 8.9697Z"
                fill="#BF6526" />
            <path
                d="M9.26758 8.97631C9.18252 8.28633 8.58233 7.99805 7.89707 7.99805H6.3328L6.17212 8.10674V8.19181L6.48403 8.6644H7.97269C8.01995 8.6644 8.26569 8.67858 8.3035 8.70693C8.44055 8.74001 8.55397 8.83926 8.60596 8.97158C8.62486 9.00467 8.64849 9.11809 8.65794 9.15589L8.82807 10.5075L8.84225 10.6115L8.99821 11.8497C9.01238 11.9394 9.088 12.1946 9.16834 12.2325C9.22032 12.3175 9.26758 12.3364 9.3432 12.327C9.40936 12.3175 9.48025 12.2844 9.56531 12.2514C9.64565 12.1946 9.68346 12.0434 9.65983 11.8544C9.64565 11.7173 9.51805 10.706 9.4897 10.498C9.43771 10.1436 9.28176 8.98103 9.28176 8.98103L9.26758 8.97631Z"
                fill="#B85F22" />
            <path
                d="M9.20615 8.97826C9.12108 8.293 8.52562 8.05198 7.84036 8.05671C7.81673 8.05671 7.71749 8.05671 7.68913 8.05671H6.30445L6.17212 8.18431V8.26937L6.54547 8.66162H8.00577C8.06248 8.66162 8.2846 8.68053 8.32713 8.71361C8.45945 8.75614 8.56342 8.85539 8.61068 8.98771C8.62959 9.01607 8.65322 9.13421 8.65794 9.16729L8.82335 10.4953L8.83753 10.5992L8.98876 11.8091C8.99821 11.8847 9.07382 12.1635 9.13998 12.1919C9.18724 12.2911 9.22978 12.31 9.29594 12.2958C9.35737 12.2864 9.42826 12.2533 9.51806 12.2202C9.58894 12.173 9.6173 12.0265 9.59367 11.828C9.57477 11.6626 9.45662 10.7457 9.42354 10.5C9.3621 10.0747 9.21087 8.97826 9.21087 8.97826H9.20615Z"
                fill="#B2591D" />
            <path
                d="M9.13998 8.9848C9.05492 8.30427 8.46418 8.11523 7.78365 8.11523C7.75529 8.11523 7.6655 8.11523 7.63715 8.11523H6.28081L6.17212 8.26646V8.35153L6.6069 8.66344H8.03885C8.10501 8.66344 8.3035 8.68707 8.35076 8.72015C8.47363 8.76741 8.57288 8.87138 8.61541 9.00371C8.62959 9.02733 8.65322 9.15493 8.65794 9.18329L8.81862 10.4876L8.8328 10.5916L8.9793 11.7778C8.98876 11.8392 9.05492 12.137 9.1069 12.1606C9.14471 12.2693 9.18252 12.2882 9.24868 12.274C9.30539 12.2646 9.37155 12.2315 9.46607 12.2031C9.52278 12.1653 9.54641 12.0188 9.52278 11.8156C9.50388 11.6219 9.39518 10.7995 9.35265 10.5113C9.28176 10.015 9.13526 8.98007 9.13526 8.98007L9.13998 8.9848Z"
                fill="#AB5319" />
            <path
                d="M9.07839 8.9875C8.99332 8.3117 8.40259 8.16992 7.72678 8.16992C7.6937 8.16992 7.61336 8.16992 7.58028 8.16992H6.24758L6.16724 8.34005V8.42512L6.66346 8.66142H8.06705C8.14266 8.66142 8.31279 8.68504 8.37423 8.72758C8.49238 8.78429 8.5869 8.88826 8.61998 9.02058C8.62943 9.03476 8.65306 9.17654 8.65306 9.19544L8.81374 10.4762L8.82792 10.5754L8.97442 11.7332C8.97915 11.7805 9.04531 12.1019 9.08311 12.1208C9.1162 12.2389 9.14455 12.2578 9.20599 12.2436C9.25797 12.2342 9.32414 12.2011 9.42338 12.1775C9.47064 12.1491 9.48954 12.0026 9.46591 11.7994C9.44228 11.582 9.34777 10.8495 9.30051 10.5187C9.21544 9.95159 9.07839 8.98278 9.07839 8.98278V8.9875Z"
                fill="#A54D15" />
            <path
                d="M9.01702 8.98955C8.93196 8.31847 8.34122 8.22395 7.67487 8.22868C7.63706 8.22868 7.5709 8.22868 7.53309 8.22868H6.22874L6.17676 8.42244V8.5075L6.73441 8.66346H8.10965C8.19472 8.66346 8.34122 8.69181 8.40738 8.73907C8.5208 8.80051 8.60587 8.9092 8.63423 9.04153C8.63895 9.05098 8.66258 9.20221 8.66258 9.21639L8.81854 10.4735L8.83271 10.5727L8.97449 11.7069C8.97449 11.74 9.03593 12.085 9.06428 12.0945C9.09264 12.2221 9.11154 12.2457 9.16825 12.2315C9.21551 12.2221 9.28167 12.189 9.38564 12.1701C9.42345 12.1512 9.4329 12.0047 9.40927 11.7967C9.38092 11.551 9.30058 10.9083 9.24859 10.5396C9.15407 9.90165 9.02175 8.99427 9.02175 8.99427L9.01702 8.98955Z"
                fill="#9E4711" />
            <path
                d="M8.95567 8.99432C8.87061 8.32797 8.2846 8.28071 7.62297 8.28544C7.58044 8.28544 7.52373 8.28544 7.48119 8.28544H6.20048L6.17212 8.49811V8.58317L6.79121 8.66351H8.13809C8.23261 8.66351 8.35548 8.69659 8.42637 8.74858C8.53507 8.81947 8.61068 8.92816 8.63431 9.06049C8.63431 9.06521 8.65794 9.22589 8.66267 9.23062L8.81862 10.4641L8.8328 10.5633L8.96985 11.6739C8.96985 11.6739 9.02656 12.0567 9.04074 12.0614C9.06437 12.1985 9.07855 12.2221 9.13053 12.2079C9.17307 12.1985 9.2345 12.1654 9.3432 12.1465C9.37155 12.137 9.37155 11.9953 9.34792 11.7779C9.31957 11.5038 9.24868 10.9556 9.18724 10.5444C9.08327 9.83553 8.95567 8.9896 8.95567 8.9896V8.99432Z"
                fill="#98410D" />
            <path
                d="M6.17212 8.6562H8.17117C8.41219 8.6562 8.61068 8.83578 8.64376 9.07207L8.82808 10.5466L9.01238 12.021C9.04547 12.281 9.03601 12.1534 9.29594 12.1203C9.3432 12.1156 9.23923 11.3311 9.12581 10.5513C9.01238 9.77151 8.88951 8.99173 8.88951 8.99173C8.79972 8.28285 8.13809 8.33483 7.42448 8.33483H6.17212V8.6562Z"
                fill="#913B09" />
            <path
                d="M7.09825 8.14368L4.97632 8.04444C4.4423 8.04916 4.15874 8.04444 4.0784 8.48867L3.61999 11.811C3.60581 11.948 3.39787 12.1276 3.35061 12.208C3.21829 12.104 3.13795 11.9386 3.16158 11.759L3.54438 8.67771C3.63889 7.94047 4.26744 7.38281 5.01413 7.38281H7.0888V8.13896L7.09825 8.14368Z"
                fill="#F7B57E" />
            <path
                d="M7.09353 7.41797V8.16939L6.89977 8.17884L5.88842 8.13631L4.99996 8.0985C4.95742 8.0985 4.91016 8.0985 4.86763 8.0985C4.55572 8.0985 4.33833 8.13158 4.22491 8.30171C4.17765 8.35842 4.14457 8.46239 4.12566 8.56636L3.95553 9.814L3.74759 11.3405C3.74759 11.3405 3.66725 11.8603 3.65308 11.8698C3.6389 11.9312 3.59164 11.9974 3.53493 12.0588C3.46877 12.1297 3.39788 12.1911 3.36479 12.2242C3.23247 12.1202 3.15213 11.9548 3.18049 11.78L3.36952 10.2441L3.56328 8.70814C3.6578 7.9709 4.28634 7.41797 5.02358 7.41797H7.09353Z"
                fill="#F4B077" />
            <path
                d="M7.09368 7.44727V8.19396L6.91882 8.22704L5.90748 8.18923L5.01901 8.15615C4.9812 8.15615 4.92449 8.15615 4.89141 8.15615C4.58422 8.15615 4.37156 8.20341 4.26286 8.37827C4.22506 8.43025 4.18725 8.5484 4.17307 8.64292L4.00294 9.88583L3.795 11.4076C3.795 11.4406 3.70521 11.9085 3.67685 11.9227C3.65795 11.9794 3.60596 12.0408 3.54925 12.0928C3.48309 12.1543 3.40748 12.2062 3.37439 12.2346C3.24207 12.1306 3.16646 11.9652 3.19009 11.7904L3.37912 10.2639L3.56816 8.73743C3.66267 8.00492 4.28649 7.45199 5.02373 7.45199H7.08422L7.09368 7.44727Z"
                fill="#F2AB71" />
            <path
                d="M7.09837 7.48047V8.22716L6.94241 8.27915L5.93107 8.24606L5.04733 8.21771C5.01425 8.21771 4.94809 8.21771 4.91973 8.21771C4.622 8.21771 4.40933 8.28387 4.31009 8.46346C4.27228 8.51072 4.2392 8.64304 4.22502 8.72811L4.05962 9.96629L3.8564 11.4833C3.85168 11.5353 3.76188 11.9653 3.71935 11.9842C3.69572 12.0362 3.64374 12.0929 3.5823 12.1402C3.51141 12.1969 3.4358 12.2394 3.40272 12.2536C3.27039 12.1497 3.19478 11.9842 3.21841 11.8094L3.40744 10.2877L3.59648 8.76591C3.69099 8.0334 4.31009 7.4852 5.04733 7.4852H7.10309L7.09837 7.48047Z"
                fill="#EFA66A" />
            <path
                d="M7.09837 7.50977V8.25173L6.96604 8.32735L5.9547 8.29899L5.07096 8.27064C5.0426 8.27064 4.96699 8.27064 4.94336 8.27064C4.65035 8.27064 4.44241 8.35097 4.34789 8.53528C4.31481 8.57309 4.28173 8.72905 4.26756 8.79994L4.10215 10.0334L3.89894 11.5457C3.88948 11.6118 3.79969 12.0088 3.73826 12.0324C3.7099 12.0797 3.65319 12.1317 3.59175 12.1695C3.52086 12.2168 3.44525 12.2498 3.40272 12.2593C3.27039 12.1553 3.19478 11.9899 3.21841 11.8198L3.40744 10.3028L3.59648 8.78576C3.68627 8.05797 4.31009 7.50977 5.0426 7.50977H7.08892H7.09837Z"
                fill="#ECA164" />
            <path
                d="M7.09842 7.53906V8.28103L6.985 8.37555L5.97838 8.35192L5.09464 8.32829C5.07101 8.32829 4.99067 8.32829 4.96704 8.32829C4.68349 8.32829 4.47555 8.42281 4.39048 8.61657C4.36212 8.64965 4.32904 8.81978 4.31959 8.88122L4.15891 10.1147L3.96042 11.6175C3.95097 11.7026 3.85173 12.0617 3.77611 12.0901C3.74303 12.1374 3.6816 12.1799 3.62016 12.213C3.54455 12.2508 3.46893 12.2744 3.4264 12.2744C3.29407 12.1752 3.21846 12.0098 3.24209 11.8349L3.43112 10.3226L3.62016 8.81033C3.70995 8.08254 4.32904 7.53906 5.06156 7.53906H7.10315H7.09842Z"
                fill="#E99C5D" />
            <path
                d="M7.09831 7.56723V8.30447L7.00379 8.41789L5.99718 8.39899L5.11343 8.38008C5.11343 8.38008 5.00474 8.38008 4.98583 8.38008C4.70701 8.38008 4.50379 8.48877 4.42818 8.68726C4.40455 8.71562 4.37147 8.89993 4.36202 8.95192L4.20134 10.1806L4.00285 11.6788C3.98867 11.778 3.88943 12.1041 3.79963 12.1372C3.76183 12.1797 3.70039 12.2175 3.63423 12.2411C3.55861 12.2695 3.47827 12.2884 3.43574 12.2789C3.30814 12.1797 3.2278 12.0143 3.25143 11.8394L3.44047 10.3319L3.6295 8.82904C3.71929 8.10598 4.33839 7.5625 5.06617 7.5625H7.09831V7.56723Z"
                fill="#E79657" />
            <path
                d="M7.09838 7.59375V8.33099L7.02277 8.46804L6.01615 8.44914L5.13713 8.43496C5.13713 8.43496 5.02371 8.43496 5.00953 8.43496C4.74016 8.43496 4.53222 8.55783 4.46605 8.76577C4.44715 8.7894 4.41407 8.98789 4.40934 9.03042L4.24866 10.2544L4.0549 11.7478C4.04072 11.866 3.93203 12.1542 3.82806 12.192C3.78553 12.2299 3.71936 12.2629 3.6532 12.2818C3.57286 12.3055 3.49725 12.3102 3.44526 12.2913C3.31766 12.192 3.23732 12.0266 3.26095 11.8565L3.44999 10.3584L3.63902 8.86029C3.72881 8.14195 4.34318 7.59848 5.07097 7.59848H7.09838V7.59375Z"
                fill="#E49150" />
            <path
                d="M7.0983 7.62777V8.36029L7.04159 8.51624L6.0397 8.50206L5.16068 8.49261C5.16068 8.49261 5.04726 8.49261 5.03308 8.49261C4.76843 8.49261 4.56522 8.62966 4.50851 8.84233C4.49433 8.86123 4.46125 9.07863 4.45653 9.10698L4.30057 10.3263L4.10681 11.8149C4.0879 11.9472 3.97921 12.2024 3.86106 12.245C3.80908 12.2781 3.74291 12.3064 3.67675 12.3159C3.59641 12.33 3.51607 12.3253 3.46409 12.3017C3.33649 12.2024 3.25615 12.037 3.2845 11.8669L3.47354 10.3735L3.66257 8.88014C3.75237 8.1618 4.36673 7.62305 5.08507 7.62305H7.10303L7.0983 7.62777Z"
                fill="#E18C4A" />
            <path
                d="M7.09836 7.65707V8.38958L7.06055 8.56917L6.05866 8.55972L5.17964 8.55027H5.05677C4.80157 8.55027 4.59836 8.70149 4.5511 8.92361C4.54165 8.93306 4.50857 9.16936 4.50384 9.18826L4.34788 10.4028L4.15885 11.8867C4.13995 12.038 4.02652 12.2554 3.88947 12.3026C3.83276 12.3357 3.7666 12.3546 3.69571 12.3593C3.61065 12.3641 3.5303 12.3499 3.47832 12.3168C3.35072 12.2176 3.27511 12.0569 3.29874 11.882L3.48305 10.3934L3.66736 8.90471C3.75715 8.1911 4.36679 7.65234 5.08513 7.65234H7.09836V7.65707Z"
                fill="#DE8743" />
            <path
                d="M7.09818 7.68441V8.4122L7.07928 8.61069H6.07739L5.19837 8.60124H5.0755C4.82503 8.60124 4.62654 8.76664 4.584 8.99349C4.584 8.99821 4.5462 9.24868 4.5462 9.26286L4.39497 10.4727L4.20593 11.9519C4.1823 12.1173 4.06416 12.3016 3.91765 12.3536C3.85622 12.382 3.78533 12.3961 3.71444 12.3914C3.62937 12.3914 3.54903 12.3678 3.4876 12.3252C3.36 12.226 3.28438 12.0653 3.30801 11.8952L3.49232 10.4113L3.67663 8.92732C3.76643 8.21371 4.37607 7.67969 5.08968 7.67969H7.09346L7.09818 7.68441Z"
                fill="#DC823D" />
            <path
                d="M7.09817 8.6592H5.09911C4.85809 8.6592 4.6596 8.83879 4.62652 9.07508L4.44221 10.5496L4.2579 12.024C4.22482 12.284 3.99325 12.4683 3.73333 12.4352C3.64353 12.4257 3.5632 12.3927 3.50176 12.3407C3.37416 12.2414 3.29854 12.0808 3.32217 11.9106L3.50648 10.4361L3.69079 8.96166C3.78059 8.25278 4.3855 7.71875 5.09911 7.71875H7.09817V8.66393V8.6592Z"
                fill="#D97D36" />
            <path
                d="M3.75237 8.96046C3.84216 8.2563 4.44707 7.76953 5.15123 7.76953H6.82893L7.09358 7.78844V7.86877L7.03214 8.65327H5.43006C5.43006 8.65327 5.07562 8.65327 5.06617 8.66273C4.90549 8.66745 4.76844 8.75724 4.6881 8.88957C4.65501 8.94155 4.62666 9.01717 4.61721 9.08806L4.43762 10.5389L4.42344 10.6476L4.25804 11.985C4.23913 12.141 4.13989 12.2969 4.00757 12.3584C3.93668 12.4056 3.85634 12.4198 3.77127 12.4104C3.68621 12.4009 3.60586 12.3678 3.5397 12.3206C3.42155 12.2308 3.35539 12.0701 3.37902 11.8952C3.37902 11.8669 3.55388 10.4822 3.56333 10.4444C3.57278 10.3735 3.75237 8.96046 3.75237 8.96046Z"
                fill="#D27732" />
            <path
                d="M3.81376 8.96629C3.90356 8.26685 4.50375 7.82734 5.20791 7.83207H6.85724L7.09826 7.8746V7.95494L6.97539 8.6591H5.40167C5.40167 8.6591 5.0614 8.66383 5.04722 8.67801C4.89127 8.69218 4.75894 8.78197 4.68806 8.9143C4.65497 8.96156 4.63134 9.04663 4.62189 9.10806L4.44231 10.5353L4.42813 10.644L4.26273 11.9578C4.24382 12.0948 4.1493 12.2791 4.03116 12.3311C3.96499 12.3878 3.8941 12.402 3.80904 12.3926C3.7287 12.3831 3.65308 12.35 3.58219 12.3028C3.4735 12.2224 3.41206 12.0665 3.43569 11.8869C3.44042 11.8302 3.6011 10.54 3.61528 10.4597C3.63418 10.3179 3.80904 8.96629 3.80904 8.96629H3.81376Z"
                fill="#CC712E" />
            <path
                d="M3.88006 8.967C3.96985 8.27229 4.56532 7.88477 5.26475 7.88477H6.88573L7.0984 7.95093V8.03127L6.91409 8.65981H5.36872C5.34036 8.65981 5.04263 8.66927 5.02373 8.68344C4.8725 8.70235 4.74963 8.79686 4.68346 8.92919C4.65511 8.97172 4.63148 9.06624 4.62203 9.11823L4.44717 10.5218L4.43299 10.6258L4.27231 11.916C4.25813 12.0388 4.16834 12.2468 4.06437 12.294C4.00293 12.3602 3.93677 12.3791 3.85643 12.3649C3.78082 12.3555 3.7052 12.3224 3.62959 12.2799C3.53034 12.209 3.47836 12.053 3.50199 11.8687C3.51144 11.7884 3.66267 10.588 3.68157 10.4651C3.71465 10.2524 3.88006 8.967 3.88006 8.967Z"
                fill="#C56B2A" />
            <path
                d="M3.94131 8.9697C4.02638 8.27972 4.62657 7.93945 5.32127 7.93945H6.9139L7.10294 8.02452V8.10959L6.85246 8.65779H5.33545C5.29764 8.65779 5.02827 8.67197 4.99991 8.69087C4.85813 8.71922 4.73999 8.81374 4.67855 8.94607C4.65492 8.98388 4.63129 9.08785 4.62184 9.1351L4.45171 10.5151L4.43753 10.619L4.28157 11.8809C4.2674 11.9895 4.18233 12.2164 4.09254 12.2589C4.03583 12.3345 3.97912 12.3534 3.9035 12.3393C3.83261 12.3298 3.757 12.2967 3.67666 12.2589C3.58687 12.1975 3.54434 12.0415 3.56796 11.8525C3.57742 11.7438 3.71919 10.6379 3.74282 10.4725C3.78536 10.189 3.94604 8.96497 3.94604 8.96497L3.94131 8.9697Z"
                fill="#BF6526" />
            <path
                d="M4.00294 8.97631C4.08801 8.28633 4.68347 7.99805 5.37345 7.99805H6.93773L7.09841 8.10674V8.19181L6.7865 8.6644H5.29784C5.25058 8.6644 5.00483 8.67858 4.96703 8.70693C4.82997 8.74001 4.71655 8.83926 4.66457 8.97158C4.64566 9.00467 4.62203 9.11809 4.61258 9.15589L4.44245 10.5075L4.42827 10.6115L4.27232 11.8497C4.26287 11.9394 4.18253 12.1946 4.10219 12.2325C4.0502 12.3175 4.00294 12.3364 3.93205 12.327C3.86589 12.3175 3.795 12.2844 3.70994 12.2514C3.63432 12.1946 3.59179 12.0434 3.61542 11.8544C3.6296 11.7173 3.7572 10.706 3.78555 10.498C3.83754 10.1436 3.99349 8.98103 3.99349 8.98103L4.00294 8.97631Z"
                fill="#B85F22" />
            <path
                d="M4.06431 8.97826C4.14937 8.293 4.74484 8.05198 5.43009 8.05671C5.45372 8.05671 5.55297 8.05671 5.58132 8.05671H6.96601L7.09834 8.18431V8.26937L6.72499 8.66162H5.26469C5.20798 8.66162 4.98586 8.68053 4.94333 8.71361C4.811 8.75614 4.70703 8.85539 4.65977 8.98771C4.64087 9.01607 4.61724 9.13421 4.61251 9.16729L4.44711 10.4953L4.43293 10.5992L4.2817 11.8091C4.27225 11.8847 4.19663 12.1635 4.1352 12.1919C4.08794 12.2911 4.0454 12.31 3.97924 12.2958C3.91781 12.2864 3.84692 12.2533 3.75712 12.2202C3.68624 12.173 3.65788 12.0265 3.68151 11.828C3.70041 11.6626 3.81384 10.7457 3.85164 10.5C3.91308 10.0747 4.06431 8.97826 4.06431 8.97826Z"
                fill="#B2591D" />
            <path
                d="M4.13033 8.9848C4.2154 8.30427 4.81086 8.11523 5.48666 8.11523C5.51502 8.11523 5.60481 8.11523 5.63317 8.11523H6.9895L7.0982 8.26646V8.35153L6.66341 8.66344H5.23147C5.1653 8.66344 4.96682 8.68707 4.91956 8.72015C4.79196 8.76741 4.69744 8.87138 4.65491 9.00371C4.64073 9.02733 4.6171 9.15493 4.6171 9.18329L4.45642 10.4876L4.44224 10.5916L4.29574 11.7778C4.28629 11.8392 4.22012 12.137 4.16814 12.1606C4.13033 12.2693 4.09253 12.2882 4.02636 12.274C3.96965 12.2646 3.89876 12.2315 3.80897 12.2031C3.75226 12.1653 3.72863 12.0188 3.75226 11.8156C3.77116 11.6219 3.87986 10.7995 3.91767 10.5113C3.98855 10.015 4.13506 8.98007 4.13506 8.98007L4.13033 8.9848Z"
                fill="#AB5319" />
            <path
                d="M4.19197 8.9875C4.27704 8.3117 4.86778 8.16992 5.54358 8.16992C5.57666 8.16992 5.657 8.16992 5.69008 8.16992H7.02279L7.10313 8.34005V8.42512L6.60691 8.66142H5.20332C5.1277 8.66142 4.95757 8.68504 4.89613 8.72758C4.77798 8.78429 4.68347 8.88826 4.65039 9.02058C4.64093 9.03476 4.6173 9.17654 4.61258 9.19544L4.4519 10.4762L4.43772 10.5754L4.29122 11.7332C4.28649 11.7805 4.22033 12.1019 4.18252 12.1208C4.14944 12.2389 4.12109 12.2578 4.05965 12.2436C4.00766 12.2342 3.9415 12.2011 3.84226 12.1775C3.795 12.1491 3.77609 12.0026 3.79972 11.7994C3.82335 11.582 3.91787 10.8495 3.96513 10.5187C4.0502 9.95159 4.18725 8.98278 4.18725 8.98278L4.19197 8.9875Z"
                fill="#A54D15" />
            <path
                d="M4.25321 8.98955C4.33827 8.31847 4.92429 8.22395 5.59536 8.22868C5.63317 8.22868 5.69933 8.22868 5.73714 8.22868H7.04149L7.09347 8.42244V8.5075L6.53582 8.66346H5.16058C5.07551 8.66346 4.92901 8.69181 4.86285 8.73907C4.74943 8.80051 4.66436 8.9092 4.636 9.04153C4.63128 9.05098 4.60765 9.20221 4.60292 9.21639L4.44697 10.4735L4.43279 10.5727L4.29101 11.7069C4.29101 11.74 4.22958 12.085 4.20122 12.0945C4.17287 12.2221 4.15396 12.2457 4.09253 12.2315C4.04527 12.2221 3.9791 12.189 3.87513 12.1701C3.83733 12.1512 3.82788 12.0047 3.85151 11.7967C3.87514 11.551 3.9602 10.9083 4.01219 10.5396C4.1067 9.90165 4.23903 8.99427 4.23903 8.99427L4.25321 8.98955Z"
                fill="#9E4711" />
            <path
                d="M4.31469 8.99432C4.39976 8.32797 4.98577 8.28071 5.6474 8.28544C5.68993 8.28544 5.74664 8.28544 5.78918 8.28544H7.06989L7.09825 8.49811V8.58317L6.47916 8.66351H5.13227C5.03776 8.66351 4.91488 8.69659 4.844 8.74858C4.7353 8.81947 4.65969 8.92816 4.63133 9.06049C4.63133 9.06521 4.6077 9.22589 4.6077 9.23062L4.45175 10.4641L4.43757 10.5633L4.30052 11.6739C4.30052 11.6739 4.24381 12.0567 4.22963 12.0614C4.206 12.1985 4.19182 12.2221 4.13984 12.2079C4.0973 12.1985 4.03587 12.1654 3.92717 12.1465C3.89882 12.137 3.89882 11.9953 3.92245 11.7779C3.9508 11.5038 4.02169 10.9556 4.08313 10.5444C4.1871 9.83553 4.31469 8.9896 4.31469 8.9896V8.99432Z"
                fill="#98410D" />
            <path
                d="M7.09823 8.6562H5.09917C4.85815 8.6562 4.65966 8.83578 4.62658 9.07207L4.44227 10.5466L4.25796 12.021C4.22488 12.281 4.23433 12.1534 3.97441 12.1203C3.93187 12.1156 4.03112 11.3311 4.14454 10.5513C4.25796 9.77151 4.38083 8.99173 4.38083 8.99173C4.47063 8.28285 5.13225 8.33483 5.84586 8.33483H7.09823V8.6562Z"
                fill="#913B09" />
            <path
                d="M6.64453 20.0005C9.51788 20.0005 11.843 17.6706 11.843 14.802C11.843 11.9334 9.51788 9.60352 6.64453 9.60352C3.77119 9.60352 1.44604 11.9287 1.44604 14.802C1.44604 17.6753 3.77119 20.0005 6.64453 20.0005Z"
                fill="url(#paint0_linear_14442_31354)" />
            <path
                d="M6.64447 19.0956C9.01687 19.0956 10.9403 17.1721 10.9403 14.7997C10.9403 12.4273 9.01687 10.5039 6.64447 10.5039C4.27207 10.5039 2.34863 12.4273 2.34863 14.7997C2.34863 17.1721 4.27207 19.0956 6.64447 19.0956Z"
                fill="url(#paint1_linear_14442_31354)" />
            <path
                d="M6.64464 18.6472C8.76657 18.6472 10.4868 16.927 10.4868 14.805C10.4868 12.6831 8.76657 10.9629 6.64464 10.9629C4.52272 10.9629 2.80249 12.6831 2.80249 14.805C2.80249 16.927 4.52272 18.6472 6.64464 18.6472Z"
                fill="url(#paint2_linear_14442_31354)" />
            <path
                d="M6.64453 20.0005C9.51788 20.0005 11.843 17.6706 11.843 14.802C11.843 11.9334 9.51788 9.60352 6.64453 9.60352C3.77119 9.60352 1.44604 11.9287 1.44604 14.802C1.44604 17.6753 3.77119 20.0005 6.64453 20.0005Z"
                fill="url(#paint3_radial_14442_31354)" />
            <path
                d="M6.64447 19.0956C9.01687 19.0956 10.9403 17.1721 10.9403 14.7997C10.9403 12.4273 9.01687 10.5039 6.64447 10.5039C4.27207 10.5039 2.34863 12.4273 2.34863 14.7997C2.34863 17.1721 4.27207 19.0956 6.64447 19.0956Z"
                fill="url(#paint4_radial_14442_31354)" />
            <path
                d="M6.64447 19.0956C9.01687 19.0956 10.9403 17.1721 10.9403 14.7997C10.9403 12.4273 9.01687 10.5039 6.64447 10.5039C4.27207 10.5039 2.34863 12.4273 2.34863 14.7997C2.34863 17.1721 4.27207 19.0956 6.64447 19.0956Z"
                fill="url(#paint5_radial_14442_31354)" />
            <path
                d="M8.51129 14.9338C8.36952 14.7495 8.18048 14.5983 7.95364 14.4896C8.1663 14.362 8.33643 14.2108 8.45931 14.0312C8.61999 13.7996 8.70033 13.5539 8.70033 13.2892C8.70033 12.7599 8.50657 12.3393 8.12849 12.0368C7.75515 11.7391 7.2353 11.5879 6.59258 11.5879C6.22868 11.5879 5.89315 11.654 5.59069 11.7911C5.2835 11.9282 5.03776 12.1219 4.86763 12.3677C4.69277 12.6134 4.60298 12.9017 4.60298 13.2183V13.3128H6.09636V13.2183C6.09636 13.1002 6.14834 13.0009 6.25231 12.9159C6.36574 12.8261 6.49333 12.7835 6.64456 12.7835C6.82887 12.7835 6.97065 12.8308 7.06989 12.93C7.16914 13.0293 7.2164 13.1569 7.2164 13.3176C7.2164 13.7429 7.01318 13.9367 6.5784 13.9367H5.86952V15.0992H6.5973C6.82415 15.0992 6.99901 15.1512 7.1266 15.2552C7.24475 15.3544 7.30619 15.534 7.30619 15.7845C7.30619 15.9593 7.24948 16.0964 7.13133 16.2051C7.01318 16.3185 6.8525 16.3705 6.64456 16.3705C6.46498 16.3705 6.31848 16.3185 6.1956 16.2051C6.07745 16.0964 6.01602 15.9735 6.01602 15.827V15.7325H4.51318V15.827C4.51318 16.1626 4.59825 16.465 4.77311 16.7297C4.94797 16.9943 5.19844 17.2023 5.52925 17.3488C5.85534 17.4953 6.21451 17.5661 6.5973 17.5661C7.24475 17.5661 7.77878 17.4055 8.18048 17.0936C8.58691 16.7722 8.79485 16.3421 8.79485 15.8128C8.79485 15.4679 8.70505 15.1748 8.52074 14.9386L8.51129 14.9338Z"
                fill="url(#paint6_linear_14442_31354)" />
            <path
                d="M8.24196 6.22401L3.78072 0H0L4.46125 6.24291C4.63138 6.48393 4.7259 6.77221 4.7259 7.06522V8.03403H8.50662V7.05104C8.50662 6.75331 8.4121 6.46503 8.24196 6.22401Z"
                fill="url(#paint7_linear_14442_31354)" />
            <path
                d="M11.999 0H9.45173L8.77592 0.94518L8.09539 1.89036L6.40352 4.25331L5.72772 5.19849L4.99048 6.22401C4.82035 6.46503 4.72583 6.75331 4.72583 7.05104V8.03403H8.50655V7.06522C8.50655 6.77221 8.60107 6.48393 8.7712 6.24291L9.51789 5.19849L10.1937 4.25331L11.8808 1.89036L13.2324 0H11.999Z"
                fill="url(#paint8_linear_14442_31354)" />
            <path
                d="M8.03396 7.08984H5.19842C4.9385 7.08984 4.72583 7.30251 4.72583 7.56243V8.50761C4.72583 8.76754 4.9385 8.9802 5.19842 8.9802H8.03396C8.29388 8.9802 8.50655 8.76754 8.50655 8.50761V7.56243C8.50655 7.30251 8.29388 7.08984 8.03396 7.08984Z"
                fill="url(#paint9_linear_14442_31354)" />
            <defs>
                <linearGradient id="paint0_linear_14442_31354" x1="1.01126" y1="7.33981" x2="10.515"
                                y2="19.9391" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDDCCB" />
                    <stop offset="0.3" stop-color="#F1994E" />
                    <stop offset="0.37" stop-color="#EC9449" />
                    <stop offset="0.45" stop-color="#DF843D" />
                    <stop offset="0.55" stop-color="#CA6B27" />
                    <stop offset="0.6" stop-color="#BB5A19" />
                    <stop offset="0.73" stop-color="#AA4D13" />
                    <stop offset="0.98" stop-color="#7E2C02" />
                    <stop offset="1" stop-color="#792900" />
                </linearGradient>
                <linearGradient id="paint1_linear_14442_31354" x1="9.85336" y1="19.8139"
                                x2="3.17567" y2="9.37914" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDDCCB" />
                    <stop offset="0.3" stop-color="#F1994E" />
                    <stop offset="0.37" stop-color="#EC9449" />
                    <stop offset="0.45" stop-color="#DF843D" />
                    <stop offset="0.55" stop-color="#CA6B27" />
                    <stop offset="0.6" stop-color="#BB5A19" />
                    <stop offset="0.73" stop-color="#AA4D13" />
                    <stop offset="0.98" stop-color="#7E2C02" />
                    <stop offset="1" stop-color="#792900" />
                </linearGradient>
                <linearGradient id="paint2_linear_14442_31354" x1="4.00287" y1="12.1822"
                                x2="11.5454" y2="19.6633" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDDCCB" />
                    <stop offset="0.3" stop-color="#F1994E" />
                    <stop offset="0.39" stop-color="#EC9449" />
                    <stop offset="0.51" stop-color="#DF843D" />
                    <stop offset="0.64" stop-color="#CA6B27" />
                    <stop offset="0.71" stop-color="#BB5A19" />
                    <stop offset="0.8" stop-color="#AA4D13" />
                    <stop offset="0.98" stop-color="#7E2C02" />
                    <stop offset="1" stop-color="#792900" />
                </linearGradient>
                <radialGradient id="paint3_radial_14442_31354" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.19775 15.4592) scale(7.21172 7.21172)">
                    <stop stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.73" stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.84" stop-color="#FFEEE0" />
                    <stop offset="0.93" stop-color="#FFD1AD" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint4_radial_14442_31354" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.86035 14.6098) scale(6.46503)">
                    <stop stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.73" stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.84" stop-color="#FFEEE0" />
                    <stop offset="0.93" stop-color="#FFD1AD" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint5_radial_14442_31354" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.86035 14.6098) scale(6.46503)">
                    <stop stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.73" stop-color="#FFD1AD" stop-opacity="0" />
                    <stop offset="0.84" stop-color="#FFEEE0" />
                    <stop offset="0.93" stop-color="#FFD1AD" stop-opacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_14442_31354" x1="-0.359216" y1="6.81474"
                                x2="14.0784" y2="22.8639" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FDDCCB" />
                    <stop offset="0.25" stop-color="#F1994E" />
                    <stop offset="0.3" stop-color="#EC9449" />
                    <stop offset="0.37" stop-color="#DF843D" />
                    <stop offset="0.44" stop-color="#CA6B27" />
                    <stop offset="0.48" stop-color="#BB5A19" />
                    <stop offset="0.51" stop-color="#B45516" />
                    <stop offset="0.68" stop-color="#943D0A" />
                    <stop offset="0.82" stop-color="#802E03" />
                    <stop offset="0.92" stop-color="#792900" />
                </linearGradient>
                <linearGradient id="paint7_linear_14442_31354" x1="1.32325" y1="0.259925"
                                x2="5.87429" y2="6.08696" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint8_linear_14442_31354" x1="7.16439" y1="6.50284"
                                x2="11.3657" y2="0.189037" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint9_linear_14442_31354" x1="6.33264" y1="11.3668"
                                x2="6.72961" y2="6.76376" gradientUnits="userSpaceOnUse">
                    <stop offset="0.49" stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
            </defs>
        </svg>
    );
};
