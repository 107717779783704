import React, { useState } from "react";
import { ArrowExitIcon, CoinCompetitionIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import { $excludeApplication, $pickWinner } from "../../../api/requests/contest";
import { failureNotify, successNotify } from "../../../utils";
import $ from "jquery";

interface ContestsViewWinnerExcludeProps {
    data: any;
    contestData?: any;
    isAdmin?: any;
    onBack: () => void;
    // onExclude: () => void;
    onSuccess: () => void;
    onClose: () => void;
}

let busy = false;
let tmpHtml = '';

const ContestsViewWinnerExclude = (
    {
        data,
        contestData,
        isAdmin,
        onBack,
        onSuccess,
        onClose,
    }: ContestsViewWinnerExcludeProps
) => {
    const [commentValue, setCommentValue] = useState<string>("");

    function handleExclude() {
        const requestData = {
            id: data.id,
            reason: commentValue
        }
        busy = true;

        let btn = $('.excludeSubmit');
        tmpHtml = btn.html();
        btn.html('<div class="dot-floating"></div>');

        $excludeApplication(requestData).then(res => {
            busy = false;
            btn.html(tmpHtml);
            if (!res || res.error) {
                return;
            }
            successNotify("Успешно");
            setCommentValue("")
            onSuccess && onSuccess();
        }).catch(() => {
            console.log('apiError');
            failureNotify('Ошибка');
            btn.html(tmpHtml);
            busy = false;
        })
    }
    return (
        <div className={"contests__winner contests__win"}>
            <div className={"contests__winner__header contests__win__header"}>
                <div className={"back"} onClick={() => onBack()}>
                    <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                </div>

                <h3 className={"m-auto"}>{data?.winner == 2 ? `Удалить` : `Исключить`}?</h3>
            </div>

            <div className={"contests__exclude__message"}>
                <p>Вы действительно хотите {data?.winner == 2 ? `удалить` : `исключить`} заявку из конкурса?</p>
            </div>
            {isAdmin && (
                <div className={"contests__win__list"}>
                    {/* {data?.selectUsersList?.map((item: any, idx: number) => ( */}
                    <div className="layout-vacation-modal__block">
                        <div className="layout-vacation-modal__block__right">
                            <img src={data.photo} alt="" />

                            <div className="layout-vacation-modal__block__desc">
                                <h1>{data.name}</h1>

                                <p className="layout-vacation-modal__block__desc__position">{data?.position}</p>

                                <p className="layout-vacation-modal__block__desc__department">{data?.department}</p>
                            </div>
                        </div>
                    </div>
                    {/* ))} */}
                </div>
            )}
            {data?.winner != 2 && isAdmin && (
                <div className={"contests__exclude__comment"}>
                    <TextField
                        value={commentValue}
                        onChange={setCommentValue}
                        title={"Причина исключения"}
                        placeholder={"Причина"}
                        className={``}
                    />
                </div>)}

            <div className={"contests__win__btns contests__winner__btns"}>
                <Button
                    text={data?.winner == 2 ? `Удалить` : `Исключить`}
                    className={"btn btn-red w-100 excludeSubmit"}
                    onClick={handleExclude}
                />

                <Button
                    text={"Отмена"}
                    className={"btn btn-light w-100"}
                    onClick={() => onClose()}
                />
            </div>
        </div>
    );
};

export default ContestsViewWinnerExclude;