import { Link, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
    ArrowExitIcon,
    CalendarIcon, ProfileGradeTrainingsIcon, SliderActivityIcon, SliderBadgesIcon,
    SliderBlogIcon,
    SliderFeedIcon,
    SliderServicesIcon
} from "../../../img";
import { SliderFlameIcon } from "../../../img/icons/slider/SliderFlameIcon";
import { ShopIcon } from "../../../img/icons/ShopIcon";
import { $storeToken } from "../../../api/requests/auth";
import redirectUrl from "../../../utils/redirect/redirectUrl";
import { PercentageIcon } from "../../../img/icons/PercentageIcon";

interface HorizonNavigateProps {
    isScroll?: boolean;
}

export const AnalyticsHorizonNavigation = (
    {
        isScroll = false
    }: HorizonNavigateProps) => {
    const navigate = useNavigate();

    const [sliderRouters, setSliderRouters] = useState([
        {
            id: 0,
            title: "Активность",
            route: "/analytics/activity",
            icon: <SliderActivityIcon />
        },
        {
            id: 3,
            title: "Бейджи и баллы",
            route: "/analytics/badges",
            icon: <SliderBadgesIcon/>
        },
        {
            id: 1,
            title: "Витрина Скидок",
            route: "/analytics/discounts",
            icon: <PercentageIcon color={"currentColor"} opacity={1}/>
        },
        {
            id: 10,
            title: "Спорт",
            route: "/analytics/sport",
            icon: <SliderFlameIcon />
        },
    ]);

    function fetchTokenForStore() {
        $storeToken({ newApi: true }).then(res => {
            if (!res) return;

            let token = res?.data?.token;

            redirectUrl(`${process.env.REACT_APP_CSTORE_URL}/auth/${token}`);
        });
    }

    return (
        <div className='analytics-navigation'>
            <div className='container analytics-navigation__header'>
                <div className='analytics-navigation__header__breadcrumbs'>
                    <p onClick={() => navigate('/')}
                        className='analytics-navigation__header__breadcrumbs__notActive'>Главная</p>

                    <span className='analytics-navigation__header__breadcrumbs__active'> {'>'} </span>

                    <p className='analytics-navigation__header__breadcrumbs__active'>Аналитика</p>
                </div>

                <div className="layout-inner__header__title">
                    <div className="header__exitIcon" onClick={() => navigate('/')}>
                        <ArrowExitIcon />
                    </div>

                    <h5 className='analytics-navigation__title'>Аналитика</h5>
                </div>
            </div>

            <div className={`navigation ${isScroll ? "scrolled" : ""}`}>
                <div className={"container d-flex"}>
                    {sliderRouters.map((
                        router,
                        idx
                    ) => (
                        <div
                            key={idx}
                            className={`navigation__router ${location.pathname === router.route ? "active" : ""}`}
                            onClick={() => {
                                if (router.id == 5) {
                                    fetchTokenForStore();
                                } else {
                                    navigate(router.route);
                                }
                            }}
                        >
                            {router?.icon}

                            <p>{router.title}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};