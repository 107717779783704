import { AcceptedIcon, ArrowExitIcon, RejectedIcon, ReturnIcon } from "../../../img";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import { ServicesAcceptanceList, ServicesApplicationsList } from "../../../components/services";
import { $getServiceApplications } from "../../../api/requests/services";
import { IServicesApplications } from "../../../api/requests/services/interface";
import { isMd } from "../../../utils";
import {
    ServicesApplicationsHistory
} from "../../../components/services/services-education/ServicesApplicationsHistory";
import {
    ServicesFormingApplicationModal, ServicesImprovementModal
} from "../../../components/services/services-education/modals";
import { PlusOneIcon } from "../../../img/icons/PlusOneIcon";
import {
    ServicesAddingAgreerModal
} from "../../../components/services/services-education/modals/ServicesAddingAgreerModal";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesEducationPage = () => {
    const isAdmin = true;
    const isHr = true;

    const navigate = useNavigate();
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [isOpenDialog, setIsOpenDialog] = useState(0);

    const [servicesApplicationsData, setServicesApplicationsData] = useState<IServicesApplications[]>([]);

    const [activeType, setActiveType] = useState("applications");
    const [types, setTypes] = useState([
            {
                id: 1,
                key: "applications",
                title: "Мои заявки"
            },
            {
                id: 2,
                key: "acceptance",
                title: "Согласование заявок"
            },
            {
                id: 3,
                key: "history",
                title: "История заявок"
            }
        ]
    );

    const columns = [
        { key: "id", title: "ID заявки", width: 10, class: "id" },
        { key: "education_date", title: "Дата обучение", width: 10 },
        { key: "application_date", title: "Дата заявки", width: 10 },
        {
            key: "sender", title: "Отправитель", width: 22.5, render: (value: any) => (
                <div className="services-acceptance__block__status">
                    <img className="services-acceptance__block__admin__image"
                         src={value?.image} alt="" />

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>
            )
        },
        {
            key: "users", title: "Согласующий", width: 22.5, render: (value: any) => (
                <div className="services-acceptance__block__users">
                    {value.map((user: any, idx: number) => (
                        <div className="services-acceptance__block__user" key={idx}>
                            <div className="services-acceptance__block__user__left">
                                <img src={user.image} alt="" />

                                <h5>{user.name}</h5>
                            </div>

                            {user.isAccepted === 1 && (
                                <AcceptedIcon />
                            )}

                            {user.isAccepted === 0 && (
                                <RejectedIcon />
                            )}
                        </div>
                    ))}
                </div>
            )
        },
        {
            key: "file", title: "Файл", width: 10, render: () => (
                <div className="services-acceptance__block__file">
                    Скачать
                </div>
            )
        },
        {
            key: "actions", title: "", width: 17, render: (_: any, row: any) => (
                <div className="services-acceptance__block__status">
                    {isAdmin || isHr ? (
                        <div className="services-acceptance__block__status">
                            <div className="services-acceptance__block__status__item">
                                <AcceptedIcon /></div>
                            <div className="services-acceptance__block__status__item">
                                <RejectedIcon /></div>

                            {isHr && (
                                <div className="services-acceptance__block__status__item"
                                     onClick={() => setIsOpenDialog(2)}><PlusOneIcon />
                                </div>
                            )}
                            <div className="services-acceptance__block__status__item"
                                 onClick={() => setIsOpenDialog(1)}><ReturnIcon /></div>
                        </div>
                    ) : (
                        <div className="services-acceptance__block__status">

                        </div>
                    )}
                </div>
            )
        }
    ];

    const data = [
        {
            id: "2314",
            education_date: "21.10.2024",
            application_date: "21.10.2024",
            sender: {
                image: "/testImages/people/Image-2.png",
                name: "Воробьёв Илья Романович"
            },
            users: [
                {
                    image: "/testImages/people/Image-3.png",
                    name: "Воробьёв Илья Романович",
                },
                {
                    image: "/testImages/people/Image-4.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 1
                },
                {
                    image: "/testImages/people/Image-5.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                }
            ]
        },
    ];

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const [buttonsList, setButtonsList] = useState([]);

    const [applicationsTableHeader, setApplicationsTableHeader] = useState([
        {
            key: "id",
            title: "ID заявки",
            width: "25%"
        },
        {
            key: "medical_date",
            title: "Дата больничного",
            width: "25%"
        },
        {
            key: "application_date",
            title: "Дата заявки",
            width: "25%"
        },
        {
            key: "file",
            title: "Файл",
            width: "25%"
        },
        {
            key: "status",
            title: "Статус",
            width: "25%"
        }
    ]);

    const {
        control,
        formState: { isValid },
        setValue
    } = useForm<any>();


    useEffect(() => {
        $getServiceApplications(30, true, { newApi: true }).then(res => {

        });
    }, []);

    return (
        <div className="services-education">
            <div className="container services-education-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon" onClick={() => navigate("/services?page=special-applications")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявка на обучение</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem onChange={() => ""} />
                </div>
            </div>

            <div className="services-education-content">
                <div className="container services-education-content__top">
                    {types.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={idx}
                            className={`services-education-content__top__block ${activeType === item.key ? "active" : ""}`}
                            onClick={() => setActiveType(item.key)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>

                <div className="services-education-wrapper">
                    <div className="container services-education-wrapper__header">
                        <div className="services-education-wrapper__buttons">
                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Все"}
                                            label={"Статус"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата обучение"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {activeType === 'applications' && (
                            <div className='services-education-wrapper__header__button'>
                                Подать заявку
                            </div>
                        )}
                    </div>

                    {activeType === "applications" && (
                        <ServicesApplicationsList data={servicesApplicationsData}
                                                  headerTable={applicationsTableHeader} />
                    )}

                    {activeType === "acceptance" && (
                        <ServicesAcceptanceList isAdmin={isAdmin} isHr={isHr} columns={columns}
                                                list={data} />
                    )}

                    {activeType === "history" && (
                        <ServicesApplicationsHistory isAdmin={isAdmin} isHr={isHr} />
                    )}
                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal}
                                                 onClose={() => setIsOpenModal(false)} />
            )}

            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          onClose={() => setIsOpenDialog(0)} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)} />
            )}
        </div>
    );
};