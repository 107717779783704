import {
    ArrowExitIcon,
    ArrowRightIcon, PlusIcon2, SettingIcon,
    WorkspaceAccrualIcon, WorkspaceBannerIcon, WorkspaceNavigationIcon,
    WorkspaceStoreIcon,
    WorkspaceUserFieldsIcon, WorkspaceUsersIcon
} from "../../img";
import { isMd } from "../../utils";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { SearchItem } from "../../components/ui/form/SearchItem";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import React, { ReactNode, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { store, useAppDispatch } from "../../redux";
import {
    getCurrentGroupData, getToAddFolder,
    setIsOpenCreateBanner, setOpenDetailsPopup, setToAddFolder
} from "../../redux/reducers/workplace/workplaceSlice";
import Button from "../../components/ui/form/Buttons/Button";
import {
    WorkspaceOfficeManagerIcon
} from "../../img/icons/workspace-settings/WorkspaceOfficeManagerIcon";
import { $getMeetBooking } from "../../api/requests/workspace-settings/booking";
import {
    setStructure
} from "../../redux/reducers/workplace-management/workplaceManagementSlice";
import { $storeToken } from "../../api/requests/auth";
import redirectUrl from "../../utils/redirect/redirectUrl";
import process from "process";

interface ISettingsPageChild {
    key: string,
    title: string,
    parent: string,
}

interface ISettingsPage {
    key: string;
    title: string;
    isOpen?: boolean;
    icon?: ReactNode;
    child?: ISettingsPageChild[];
    fill?: boolean;
}

export const WorkspaceSettingsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();
    const dispatch = useDispatch();

    const currentGroup = useSelector(getCurrentGroupData);
    const isUpdateLoadMeet = useSelector(getToAddFolder);

    const [activePage, setActivePage] = useState<any>({ key: "navigation" });
    const [activeChildPage, setActiveChildPage] = useState<any>(null);

    const [settingsPage, setSettingsPage] = useState<ISettingsPage[]>([
        {
            key: "navigation",
            title: "Навигация",
            icon: <WorkspaceNavigationIcon />
        },
        {
            key: "banners",
            title: "Банеры",
            icon: <WorkspaceBannerIcon />,
            fill: true
        },
        {
            key: "users",
            title: "Пользователи",
            icon: <WorkspaceUsersIcon />
        },
        {
            key: "user_fields",
            title: "Поля пользователя",
            icon: <WorkspaceUserFieldsIcon />
        },
        {
            key: "office-manager",
            title: "Управление офисом",
            icon: <WorkspaceOfficeManagerIcon />,
            fill: true,
            child: []
        },
        {
            key: "market",
            title: "Копр. Магазин",
            icon: <WorkspaceStoreIcon />
        },
        // {
        //     key: "vacation",
        //     title: "Отпуска",
        //     icon: <WorkspaceVacationIcon />
        // },
        {
            key: "accrual",
            title: "Начисления руководителям",
            icon: <WorkspaceAccrualIcon />,
            fill: true
        },
        {
            key: "all-settings",
            title: "Общие настройки",
            isOpen: false,
            icon: <WorkspaceUsersIcon />,
            child: [
                {
                    key: "setting",
                    title: "Общие",
                    parent: "all-settings"
                },
                {
                    key: "badges",
                    title: "Бейджи",
                    parent: "all-settings"
                },
                {
                    key: "booking",
                    title: "Бронирование",
                    parent: "all-settings"
                },
                {
                    key: "workplace",
                    title: "Рабочие места",
                    parent: "all-settings"
                },
                {
                    key: "welcome",
                    title: "Велком баллы",
                    parent: "all-settings"
                },
                {
                    key: "sport",
                    title: "Спорт",
                    parent: "all-settings"
                }
            ]
        }
    ]);

    const pages = location.pathname.split("/");

    const currentPage = pages[pages.length - 1];
    const parentPage = pages.length == 4 ? pages[pages.length - 2] : null;

    const handleOpenChildPage = (select: any) => {
        if (select.parent === "office-manager") dispatch(setStructure(select.key));

        setActiveChildPage(select);
        navigate(`${select.parent}/${select.key}`);
    };

    useEffect(() => {
        if (currentPage !== activePage.key) {
            setActivePage((prevState: any) => ({
                ...prevState,
                key: currentPage
            }));
        }
    }, [currentPage]);

    useEffect(() => {
        if (parentPage) {
            if (parentPage == "office-manager") {
                /* TODO: load and select childs */
            } else {
                if (activeChildPage?.key != currentPage) {
                    setActiveChildPage({
                        key: currentPage,
                        parent: parentPage
                    })

                    setSettingsPage([...settingsPage].map(item => {
                        if (item.key == parentPage) return {
                            ...item,
                            isOpen: true
                        };

                        return item;
                    }));
                }
            }

        }
    }, [currentPage, parentPage]);

    useEffect(() => {
        isUpdateLoadMeet && handleLoadMeet({
            key: "office-manager",
            title: "Управление офисом",
            icon: <WorkspaceOfficeManagerIcon />,
            fill: true,
            child: []
        }, true);
    }, [isUpdateLoadMeet]);

    function handleOpenPage(select: any) {
        if (select.key === "market") return fetchTokenForStore();

        setActivePage(select);
        setActiveChildPage(null);

        if (select.child) {
            if (select.key === "office-manager") return handleLoadMeet(select);

            const updateList = [...settingsPage].map(item => {
                if (item.key === select.key) return {
                    ...item,
                    isOpen: !select.isOpen
                };

                return item;
            });

            setSettingsPage(updateList);
        } else {
            const updateList = [...settingsPage].map(item => ({
                ...item,
                isOpen: false
            }));

            setSettingsPage(updateList);
        }

        navigate(select.key);
    }

    function fetchTokenForStore() {
        $storeToken({ newApi: true }).then(res => {
            if (!res) return;

            let token = res?.data?.token;

            redirectUrl(`${process.env.REACT_APP_CSTORE_URL}/auth/${token}?admin=1`);
        });
    }

    function handleDeleteGroup() {
        if (!currentGroup) return;

        // $deleteGroupById({ id: currentGroup.id }, { formData: true }).then(res => {
        //     navigate("/?group_id=0");
        // });
    }

    function handleLoadMeet(select: any, isUpdateLoadMeet?: boolean) {
        dispatch(setToAddFolder(false));

        $getMeetBooking().then(res => {
            if (!res?.data?.data) return;

            const updateList = [...settingsPage].map(item => {
                if (item.key === select.key) return {
                    ...item,
                    isOpen: isUpdateLoadMeet ? true : !select.isOpen,
                    child: [...res.data.data].map(i => ({
                        ...i,
                        key: i.id.toString(),
                        title: i.name,
                        parent: "office-manager"
                    }))
                };

                return item;
            });

            setSettingsPage(updateList);
        });

        navigate(select.key);
    }

    return (
        <div className="container workspace-settings">
            <div className="layout-inner__header user-header" style={{ height: 50 }}>
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Настройки организации</h1>
                    </div>
                )}

                {activePage.key === "users" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__dropdown">
                            <Dropdown
                                options={[{ value: 1, label: "Роль" }]}
                                placeholder={"Роль"}
                                onChange={() => ""}
                            />
                        </div>

                        <div className="workspace-settings-users__header__search">
                            <SearchItem
                                onChange={() => ""}
                            />
                        </div>
                    </div>
                )}

                {activePage.key === "banners" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__btn">
                            <Button
                                leftIcon={<PlusIcon2 color={"black"} />}
                                className="btn btn-secondary"
                                text={"Добавить банер"}
                                onClick={() => store.dispatch(setIsOpenCreateBanner(true))}
                            />
                        </div>
                    </div>
                )}

                {activePage.key === "user_fields" && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__btn">
                            <Button
                                leftIcon={<PlusIcon2 color={"black"} />}
                                className="btn btn-secondary"
                                text={"Добавить блок"}
                                onClick={() => store.dispatch(setIsOpenCreateBanner(true))}
                            />

                            <Button
                                leftIcon={<SettingIcon color={"black"} />}
                                className="btn btn-secondary"
                                text={"Настройка полей"}
                                onClick={() => store.dispatch(setOpenDetailsPopup())}
                            />
                        </div>
                    </div>
                )}

                {((activePage.key === "office-manager") || (activeChildPage?.parent === "office-manager")) && (
                    <div className="workspace-settings-users__header">
                        <div className="workspace-settings-users__header__btn">
                            <Button
                                leftIcon={<PlusIcon2 color={"black"} />}
                                className="btn btn-secondary"
                                text={"Добавить офис"}
                                onClick={() => store.dispatch(setIsOpenCreateBanner(true))}
                            />
                        </div>
                    </div>
                )}

                {/*<div className="workspace-settings-users__header">*/}
                {/*    <div className="workspace-settings-users__header__dropdown">*/}
                {/*        <Dropdown options={[{ value: 1, label: "Роль" }]} onChange={() => ""} />*/}
                {/*    </div>*/}

                {/*    <div className="workspace-settings-users__header__search">*/}
                {/*        <SearchItem onChange={() => ""} />*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className="workspace-settings-content">
                <div className="workspace-settings-left">
                    {settingsPage.map((item, idx) => (
                        <div className={`workspace-settings-page`}
                            key={`workspace-settings-left-${idx}`}
                        >
                            <div
                                className={`workspace-settings-page__main ${activePage.key === item.key ? "active" : ""}`}
                                onClick={() => handleOpenPage(item)}
                            >
                                <div className="workspace-settings-page__left">
                                    <div
                                        className={`workspace-settings-page__main__icon ${item.fill ? "fill" : "stroke"}`}>
                                        {item?.icon}
                                    </div>

                                    <p className="workspace-settings-page__title">{item.title}</p>
                                </div>

                                {item.child && (
                                    <div
                                        className={`profile-services-content-mobile__header__icon ${item.isOpen ? 'active' : ''}`}>
                                        <ArrowRightIcon />
                                    </div>
                                )}
                            </div>

                            {item.isOpen && (
                                <div className="workspace-settings-page__content">
                                    {item.child && item?.child.map((childItem, i) => (
                                        <div
                                            className={`workspace-settings-page__childPage ${childItem.key === activeChildPage?.key ? "active" : ""}`}
                                            key={i}
                                            onClick={() => handleOpenChildPage(childItem)}>
                                            {childItem.title}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}

                    {/*<Button*/}
                    {/*    className="btn btn-light mt-auto"*/}
                    {/*    text={"Начислить баллы"}*/}
                    {/*    onClick={handleDeleteGroup}*/}
                    {/*/>*/}
                </div>

                <div className="workspace-settings-right workspace-settings-group__right">
                    {settingsPage.map((item, idx) => {
                        if (item.key === activePage.key) {
                            return (
                                <React.Fragment key={`workspace-settings-right-${idx}`}>
                                    <Outlet />
                                </React.Fragment>
                            );
                        }

                        if ((activePage.key === activeChildPage?.key) && item.child) {
                            const activeItem = item.child.find(i => i.key === activeChildPage?.key);

                            if (activeItem) return (
                                <React.Fragment key={`workspace-settings-right-${idx}`}>
                                    <Outlet />
                                </React.Fragment>
                            );
                        }

                        return null;
                    })}
                </div>
            </div>
        </div>
    );
};