export const PencilIcon = ({
    color = '#00A7B5',
    size = 20,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4 13L7.5 16M3.5 13L13.3595 2.79619C14.4211 1.73461 16.1422 1.7346 17.2038 2.79619C18.2654 3.85777 18.2654 5.57894 17.2038 6.64052L7 16.5L2 18L3.5 13Z"
            stroke={color}
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)
