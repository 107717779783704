export const GroupsMenuIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M8 5C6.343 5 5 6.343 5 8C5 9.657 6.343 11 8 11C9.657 11 11 9.657 11 8C11 6.343 9.657 5 8 5ZM16 5C14.343 5 13 6.343 13 8C13 9.657 14.343 11 16 11C17.657 11 19 9.657 19 8C19 6.343 17.657 5 16 5ZM8 7C8.551 7 9 7.449 9 8C9 8.551 8.551 9 8 9C7.449 9 7 8.551 7 8C7 7.449 7.449 7 8 7ZM16 7C16.551 7 17 7.449 17 8C17 8.551 16.551 9 16 9C15.449 9 15 8.551 15 8C15 7.449 15.449 7 16 7ZM3.76367 9.00195C3.32553 9.01878 2.3722 9.1317 1.75195 9.75195C0.924953 10.579 1.00195 11.998 1.00195 11.998C1.00195 11.998 2.42105 12.075 3.24805 11.248C4.07505 10.421 3.99805 9.00195 3.99805 9.00195C3.99805 9.00195 3.90972 8.99634 3.76367 9.00195ZM8 13C5 13 1 14.464 1 16.5V19H14.0781H15H23V16.5C23 14.464 19 13 16 13C14.7159 13 13.2617 13.2829 12.0039 13.75C10.7438 13.2817 9.28671 13 8 13ZM8 15C10.36 15 12.62 16.0256 13 16.5996V17H3V16.5996C3.38 16.0256 5.64 15 8 15ZM16 15C18.36 15 20.62 16.0256 21 16.5996V17H15V16.5C15 16.0182 14.7703 15.57 14.3887 15.1641C14.9083 15.0693 15.4448 15 16 15Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
