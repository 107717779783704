import { ArrowExitIcon } from "../../img";
import { useNavigate, useParams } from "react-router-dom";
import { isMd } from "../../utils";
import { UserProfileCard, UserVacationCard } from "../../components/user-profile";
import { PersonalWorkBlock } from "../../components/profile";
import {
    BadgesCard,
    CongratulatedCard,
    StatusCard
} from "../../components/layout-components";
import { useEffect, useState } from "react";
import { $getUser } from "../../api/requests/user";
import UserProfileGratitudeModal from "./modal/UserProfileGratitudeModal";

export const UserProfilePage = () => {
    const isMobile = isMd();
    const navigate = useNavigate();
    const { id: user_id }: any = useParams();

    const [userData, setUserData] = useState<any>(null);
    const [badgeData, setBadgeData] = useState<any[]>([]);

    const [isOpenGratitude, setIsOpenGratitude] = useState(false);

    useEffect(() => {
        getUser();
    }, [user_id]);

    function getUser() {
        $getUser(user_id, { newApi: true }).then(res => {
            if (!res.data) return;

            setUserData(res.data);
            setBadgeData([...res.data?.badges?.personal, ...res.data?.badges?.ws])
        });
    }

    return (
        <div className="user">
            <div className="layout-inner__header user-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Профиль</h1>
                    </div>
                )}
            </div>

            <div className="user-content">
                <div className="user-left">
                    {userData && (
                        <UserProfileCard
                            data={userData}
                            openGratitude={() => setIsOpenGratitude(true)}
                        />
                    )}

                    <StatusCard isOtherUser/>
                    <BadgesCard data={badgeData} />

                    <CongratulatedCard data={userData?.badges?.personal} />

                    <UserVacationCard />
                </div>

                <div className="user-right">
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title">Работа</h1>
                        </div>

                        <PersonalWorkBlock />
                    </div>
                </div>
            </div>

            <UserProfileGratitudeModal
                userId={user_id}
                isOpen={isOpenGratitude}
                onClose={() => {
                    setIsOpenGratitude(false);
                    getUser();
                }}
                setIsOpen={() => setIsOpenGratitude(false)}
            />
        </div>
    );
};