import { Link, useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";
import { ArrowExitIcon, ProfileChatIcon } from "../../../img";
import React, { useState } from "react";
import { ProfilePersonalEditBlock } from "../../../components/profile/edit/personal";
import { ProfilePersonalIcon } from "../../../img/icons/profile/ProfilePersonalIcon";
import { ProfileConfidentialIcon } from "../../../img/icons/profile/ProfileConfidentialIcon";
import { ProfileConfidentialEditBlock } from "../../../components/profile/edit/confidential";
import Cookies from "js-cookie";
import { changeToken, resetProfile, store } from "../../../redux";
import { useDispatch } from "react-redux";

enum ProfileEditType {
    personal = 1,
    confidential
}

export const ProfileEditPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    return (
        <div className="profile-edit">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Настройки профиля</h1>
                    </div>
                )}
            </div>

            <div className="container ">
                <ProfilePersonalEditBlock />
            </div>
        </div>
    );
};