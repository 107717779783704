import DialogContainer from "../ui/dialog/DialogContainer";
import { FC, useEffect, useState } from "react";
import { CloseIcon } from "../../img";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import { $editDashboardBlogs } from "../../api/requests/blogs";
import { IBlogsRes } from "../../api/requests/blogs/interface";

interface IIdeaPointModal {
    id: number,
    isOpen: boolean
    onClose: () => void
    setLoading: any,
    blogData: IBlogsRes,
    setPoints: any,
    points: number | string,
}

export const BlogsPointsModal: FC<IIdeaPointModal> = (
    {
        isOpen,
        onClose,
        id,
        setLoading,
        blogData,
        setPoints,
        points
    }
) => {
    const [value, setValue] = useState(points)
    const handleChangeValue = (e: any) => {
        let value = e.target.value;

        if (/^\d*\.?\d*$/.test(value)) {
            value = value.replace(/^0+(?!$)/, "");

            if (value.startsWith(".")) {
                value = "0" + value;
            }

            setValue(value);
        }
    };

    useEffect(() => {
        setValue(points)
    }, [points])

    const sendPoint = () => {
        setPoints(value)
        onClose()
        // $editIdea({ id: id || 0, points }, { formData: true }).then(res => {
        //     onClose();
        //     setLoading(false);
        // });
    };

    return (
        <DialogContainer isOpen={isOpen} closeModal={onClose} isShowCloseBtn={false}>
            <div className="ideas-modal-points__header">
                <h5 className="ideas-modal-points__title">Баллы за пост</h5>

                <div className="ideas-modal-points__closeIcon" onClick={onClose}>
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="ideas-modal-points__content">
                <p className="ideas-modal-points__label">Кол-во баллов</p>

                <div className="ideas-modal-points__input">
                    <ProfileBalanceIcon />

                    <input
                        value={value || 0}
                        type="number"
                        onChange={handleChangeValue}
                    />
                </div>
            </div>

            <div className="ideas-modal-points__button" onClick={sendPoint}>
                Сохранить
            </div>
        </DialogContainer>
    );
};