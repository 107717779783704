import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { routeLinks } from "../../../routes/routeLinks";

export const initialStateReducer: any = {
    ...routeLinks,
    listLength: 0,
}

const routeTitleReducer = createSlice({
    name: 'route_title',
    initialState: initialStateReducer,
    reducers: {
        updateRouteTitle: (state, action) => {
            const { key, title } = action.payload;
            if (state[key]) {
                state[key].title = title;
            }
        },
        changeListLength: (state, action) => {
            state.listLength = action.payload
        },
    },
})

export const { updateRouteTitle, changeListLength } = routeTitleReducer.actions

export default routeTitleReducer.reducer