import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPersonData, useAppDispatch } from "../../redux";
import {
    getIsOpenCreateBanner, getOpenDetailsPopup,
    setIsOpenCreateBanner, setOpenDetailsPopup
} from "../../redux/reducers/workplace/workplaceSlice";
import {
    $getWorkspaceGroup, $getWorkspaceGroupFields
} from "../../api/requests/workspace-settings/field-users";
import {
    FieldsData,
    StateData,
} from "../../api/requests/workspace-settings/field-users/interface";
import WorkspaceSettingFieldChild from "./WorkspaceSettingFieldChild";
import WorkspaceSettingFieldsModalModules
    from "../../components/workspace/settings/WorkspaceSettingFieldsModalModules";

const WorkspaceSettingFieldUser = () => {
    const dispatch = useDispatch();

    const personalData = useSelector(getPersonData);
    const isNewBlock = useSelector(getIsOpenCreateBanner);
    const isOpenPopup = useSelector(getOpenDetailsPopup);

    const [fieldList, setFieldList] = useState<FieldsData[]>([]);

    const [state, setState] = useState<StateData[]>([]);
    const [groups, setGroups] = useState<any>([]);

    useEffect(() => {
        getGroupData();
    }, []);

    useEffect(() => {
        if (isNewBlock) {
            setState((list) => {
                return [
                    ...list,
                    {
                        title: "",
                        position: (list[list.length - 1]?.position || 0) + 1,
                        names: [],
                        status: 1
                    }
                ];
            });
            dispatch(setIsOpenCreateBanner(false));
            setTimeout(() => window.scrollTo({ top: (document.body.scrollHeight + 600), behavior: 'smooth' }))
        }
    }, [isNewBlock]);

    function getGroupData() {
        $getWorkspaceGroup({ newApi: true }).then(res => {
            if (res.error || res.response) return;

            setState(res.data);
            buildAndSave(res.data);
            getGroupFieldsData();
        });
    }

    function getGroupFieldsData() {
        $getWorkspaceGroupFields({ newApi: true }).then(res => {
            if (res.error || res.response) return;

            const namesList = state.flatMap(i => i.names);

            const uniqueNamesList = namesList.filter(
                (item, index, self) => index === self.findIndex(obj => obj.id === item.id)
            );

            const fieldsFilterSelectList = res.data?.filter(
                item => !uniqueNamesList.some(filterItem => filterItem.id === item.id)
            );

            setFieldList(fieldsFilterSelectList);
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "fields") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            setState(workValue);

            return;
        }

        const sourceDroppableIndex = groups[source?.droppableId];
        const targetDroppableIndex = groups[destination?.droppableId];
        const sourceItems = state[sourceDroppableIndex]?.names?.slice();
        const targetItems = source.droppableId !== destination.droppableId ? state[targetDroppableIndex]?.names?.slice() : sourceItems;

        if (sourceItems) {
            const [deletedItem] = sourceItems?.splice(source.index, 1);

            targetItems.splice(destination.index, 0, deletedItem);
        }

        const workValue = [...state];
        workValue[sourceDroppableIndex] = {
            ...state[sourceDroppableIndex],
            names: sourceItems,
        };
        workValue[targetDroppableIndex] = {
            ...state[targetDroppableIndex],
            names: targetItems,
        };

        setState(workValue);
    }

    function buildAndSave(items: any) {
        const groups = [];
        for (let i = 0; i < Object.keys(items).length; ++i) {
            const currentGroup = items[i];
            groups[currentGroup.id] = i;
        }

        setState(items);

        setGroups(groups);
    }

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="ROOT" type="fields">
                    {(provided) => (
                        <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={"workspace-settings-group__modules workspace-settings-users__field"}
                        >
                            {state.map((item, index) => (
                                <div key={`fields-${item.title}-item-${index}`} className={"menuBlock"}>
                                    <Draggable
                                        draggableId={`fields-${item.title}-item-${index}`}
                                        index={index}
                                    >
                                        {(_provided) => (
                                            <div
                                                ref={_provided.innerRef}
                                                {..._provided.draggableProps}
                                                {..._provided.dragHandleProps}
                                                className={"workspace-settings-users__field-block"}
                                            >
                                                <WorkspaceSettingFieldChild
                                                    item={item}
                                                    index={index}
                                                    state={state}
                                                    setState={setState}
                                                    fieldList={fieldList}
                                                    setFieldList={setFieldList}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                </div>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <WorkspaceSettingFieldsModalModules
                isOpen={isOpenPopup}
                setIsOpen={() => {
                    getGroupFieldsData();
                    dispatch(setOpenDetailsPopup());
                }}
            />
        </>
    );
};

export default WorkspaceSettingFieldUser;