import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { EventRequestCalendarData, EventResponseCalendarData } from "./interface";

export const $getEventCategories = (
    isAdmin: boolean,
    handler?: IApiHandlerParams
): Promise<any> => api.get(isAdmin ? "backend/v1/event/categories" : "api/v1/event/categories", {
    handler
});

export const $getEventList = (
    categoryId: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api.get(
    categoryId
        ? `backend/v1/event/content?category_id=${categoryId}`
        : "backend/v1/event/content",
    {
        handler
    });

export const $getEventPaginateList = (
    handler?: IApiHandlerParams
): Promise<any> => api.get(
    "api/v1/event/paginate",
    {
        // mock: require("./mocks/event.calendar.list.json"),
        handler: {
            newApi: true,
            ...handler,
        },
    });

export const $getEventLastList = (
    params?: { count: number },
    handler?: IApiHandlerParams
): Promise<any> => api.get(
    "api/v1/event/latest",
    {
        // mock: require("./mocks/event.calendar.list.json"),
        request: { params },
        handler: {
            newApi: true,
            ...handler,
        },
    });

export const $getCalendarEventList = (
    params: EventRequestCalendarData,
    handler?: IApiHandlerParams
): Promise<EventResponseCalendarData> => api.get(
    "api/v1/event/list",
    {
        // mock: require("./mocks/event.calendar.list.json"),
        request: { params },
        handler: {
            newApi: true, delay: 200,
            ...handler,
        },
    });

export const $getEventById = (
    id: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/event/by_id/${id}`, {
    handler
});

export const $createEvent = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("backend/v1/event/content", data, {
    handler
});

export const $editEvent = (
    id: string | undefined,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`backend/v1/event/content/${id}`, data, {
    handler
});

export const $deleteEvent = (
    id: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api
.delete(`backend/v1/event/content/${id}`, {
    handler
});

export const $subscribeEvent = (
    id: number | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/event/action`, {
    event_id: id,
    action: true
}, {
    handler
});

export const $unsubscribeEvent = (
    id: number | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/event/action`, {
    event_id: id,
    action: false
}, {
    handler
});