import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRole, getPersonData, updateRouteTitle } from "../../../redux";
import { ArrowExitIcon, CommentIcon, LikeIcon, ShareIcon } from "../../../img";
import {
    $getAdminMediaFileItem,
    $getMediaFileItem,
    $getMediaFiles
} from "../../../api/requests/media-files";
import { MediaFilesCard } from "../../../components/media-files/card";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { MediaFileItem } from "../../../components/media-files/file/MediaFileItem";
import { isMd, successNotify } from "../../../utils";
import { EyeIcon2 } from "../../../img/icons/news/EyeIcon2";
import { FavoriteIcon } from "../../../img/icons/others/FavoriteIcon";
import { $toggleUserFavorites } from "../../../api/requests/favorites";

export const MediaFilesShowPage = () => {
    const isMobile = isMd();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const { id: mediaFileId } = useParams();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [mediaFiles, setMediaFiles] = useState<any[]>([]);
    const [mediaFileData, setMediaFileData] = useState<any>({});

    const [isFavorite, setIsFavorite] = useState(false);

    useEffect(() => {
        $getMediaFiles({ newApi: true }).then(res => {
            if (!res) return;

            setMediaFiles(res.data.data);
        });
    }, []);

    useEffect(() => {
        if (currentRole)
            getMediaFile();
    }, [currentRole, mediaFileId]);

    useEffect(() => {
        dispatch(updateRouteTitle({ key: "mediaFiles_show", title: mediaFileData?.title }));
    }, [mediaFileData]);

    useEffect(() => {
        setIsFavorite(mediaFileData.is_favourite ?? false);
    }, [mediaFileId]);

    function getMediaFile() {
        if (!mediaFileId) return;

        if (isAdmin)
            $getAdminMediaFileItem(mediaFileId, { newApi: true }).then(res => {
                if (!res) return;

                setMediaFileData(res.data);
            });
        else
            $getMediaFileItem(mediaFileId, true, { newApi: true }).then(res => {
                if (!res) return;

                setMediaFileData(res.data);
            });
    }

    function handleShare() {
        const url = window.location.href;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then();
    }

    function setAsFavorite(e: any) {
        e.stopPropagation()
        setIsFavorite(!isFavorite);
        $toggleUserFavorites({type: 'mediaGallery', type_id: mediaFileId, add_to_favorites: !isFavorite}, { newApi: true }).then();
    }

    return (
        <div className="media-files-show">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>{mediaFileData?.title}</h1>
                    </div>
                )}
            </div>

            <div className="container media-files-show__full">
                <div className="media-files-show__left">
                    <BlankImage image={mediaFileData?.banner}
                                classname="media-files-show__image" />

                    <div className="media-files-show__content">

                        <div className="media-files-show__content__topbar">
                            {isMobile && (
                                <div className="media-files-show__name">
                                    {mediaFileData?.title}
                                </div>
                            )}

                            <div className="media-files-show__category">
                                {mediaFileData?.category ? mediaFileData.category.title : "Без категории"}
                            </div>

                            <div className="media-files-show__favorite"
                                 onClick={(e) => setAsFavorite(e)}>
                                <FavoriteIcon
                                    opacity={isFavorite ? 1 : 0.3}
                                    color={isFavorite ? "#00A7B5" : ""}
                                    strokeColor={isFavorite ? "#00A7B5" : "black"}
                                />
                            </div>
                        </div>

                        <div className="media-files-show__description">
                            {mediaFileData?.description}
                        </div>

                        <div className="media-files-show__title">Файлы</div>

                        <div className="media-files-show__files">
                            {mediaFileData?.files && mediaFileData?.files.map((item: any) => (
                                <MediaFileItem type="show" data={item} key={item.id} />
                            ))}
                        </div>

                        <div className="media-files-show__bottom">
                            <button
                                className="media-files-show__share"
                                onClick={handleShare}
                            >
                                <ShareIcon size={24} color="#00A7B5" /> <p>Поделиться</p>
                            </button>

                            <div className="media-files-show__stats">
                                <div className="media-files-show__stats__block">
                                    <EyeIcon2 color="#000" size={20} />

                                    <p>{mediaFileData?.view_count}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="media-files-show__right">
                    {mediaFiles.slice(0, 4).map((
                        item,
                        idx
                    ) => (
                        <MediaFilesCard key={`media-files-show-right-item-${idx}`} data={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};