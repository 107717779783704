import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    WorkspaceWelcomeCreateData,
} from "./interface";

export const $getWelcome = (
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get("backend/v1/workspaces/auto-points-balances", {
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $saveWelcome = (
    data: WorkspaceWelcomeCreateData,
    handler?: IApiHandlerParams
): Promise<any> => {
    if (data?.id) {
        return api.put("backend/v1/workspaces/auto-points-balances/" + data.id, data, {
            handler: {
                ...handler,
                newApi: true,
            }
        });
    } else {
        return api.post("backend/v1/workspaces/auto-points-balances", data, {
            handler: {
                ...handler,
                newApi: true,
            }
        });
    }
}

export const $deleteWelcome = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete("backend/v1/workspaces/auto-points-balances/" + data.id, {
        handler: {
            ...handler,
            newApi: true,
            formData: true,
        }
    });
