export const CompetitionIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M20 3L19 4.28516L6 9V15L8.45117 15.8887L8.16797 16.6895C7.95497 17.2905 8.26909 17.9491 8.87109 18.1621L11.0488 18.9336C11.6498 19.1466 12.3104 18.8305 12.5234 18.2285L12.793 17.4648L19 19.7148L20 21H22V3H20ZM20 6.05078V17.9492L19.6816 17.834L8 13.5977V10.4023L19.6816 6.16602L20 6.05078ZM2 9V15H4V9H2Z"
                    fill={color}
                />
            </g>
        </svg>
    );
};
