import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getPersonData } from "../../../../redux";
import {
    getPopupData,
    getSelected,
    setSelected
} from "../../../../redux/reducers/workplace/workplaceSlice";
import { failureNotify, successNotify } from "../../../../utils";
import {
    $getMapBookingBuildingData,
    $mapBookingCreate, $mapBookingDelete, $mapBookingEdit
} from "../../../../api/requests/workplace/booking-map";
import DialogContainer from "../../../ui/dialog/DialogContainer";
import DatePickerTimeField from "../../../ui/form/DatePickerForm/DatePickerTimeField";
import Button from "../../../ui/form/Buttons/Button";
import DatePickerForm from "../../../ui/form/DatePickerForm";
import { Controller, useForm } from "react-hook-form";
import { findTime } from "../../../../utils/date/hourdsTime";
import { ArrowDropdownIcon } from "../../../../img/icons/arrow/ArrowDropdownIcon";
import TextField from "../../../ui/form/Fields/TextField";

interface FormData {
    isEdit: boolean;
    place: string;
    name: string;
    booking_date?: Date;
    start_time: Date | null;
    end_time: Date | null;
}

interface BookingWorkplaceDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProps: any;
}

const BookingWorkplaceDialog = (
    {
        isOpen,
        onClose,
        selectedProps
    }: BookingWorkplaceDialogProps
) => {
    const { id: user_id } = useSelector(getPersonData);

    const { request } = selectedProps;
    const isMine = !request?.member || (!request.is_pinned && request?.member?.id === user_id);

    const dispatch = useDispatch();

    const { city, building, floor } = useSelector(getSelected);

    const previousData = useSelector(getPopupData);

    const isEdit = !!previousData?.booking;

    const bookingDate = moment(request?.booking?.booking_date).format('YYYY-MM-DD');

    const startTime = request?.booking ? moment(`${bookingDate}T${findTime(request?.booking?.start_time)?.time}`).toDate() : null;

    const endTime = request?.booking ? moment(`${bookingDate}T${findTime(request?.booking?.end_time || 0)?.time}`).toDate() : null;

    const timeMain = `${request?.booking ? findTime(request?.booking?.start_time)?.time : ""} - ${request?.booking ? findTime(request?.booking?.end_time)?.time : ""}`

    const initialBooking = {
        isEdit,
        place: previousData?.request?.place,
        name: previousData?.request?.number_name,
        booking_date: request?.booking?.booking_date,
        start_time: startTime,
        end_time: endTime,
    };

    const [isEditFields, setIsEditFields] = useState(false);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<FormData>(
        {
            values: {
                ...initialBooking
            }
        }
    );

    const handleCreate = (requestData: any) => {
        $mapBookingCreate(requestData).then(res => {
            handleClosePopup();

            if (res) {
                successNotify(res.message);

                updateCity();
            }
        });
    };

    function updateCity() {
        dispatch(setSelected({ city, building, floor, isLoading: true }));

        $getMapBookingBuildingData(city?.id).then(res => {
            const buildingData = res.data.find((item: any) => item.id === building.id);
            const floorData = buildingData?.floors?.find((item: any) => item.id === floor.id);
            dispatch(setSelected({
                city,
                building: buildingData,
                floor: floorData,
                isLoading: false
            }));
        });
    }

    const handleEdit = (requestData: any) => {
        $mapBookingEdit(previousData?.booking?.id, requestData).then(res => {
            handleClosePopup();
            updateCity();
        });
    };

    const handleDelete = () => {
        $mapBookingDelete(previousData?.booking?.id).then(res => {
            handleClosePopup();
            updateCity();
        });
    };

    function onSubmit(data: FormData) {
        const requestData = {
            booking_date: moment(data?.booking_date).format("YYYY-MM-DD"),
            // booking_end_date: period ? range?.booking_end_date : null,
            start_time: moment(data.start_time).diff(moment(data.start_time).startOf('day'), 'seconds'),
            end_time: moment(data.end_time).diff(moment(data.end_time).startOf('day'), 'seconds')
        }

        if (isEdit) return handleEdit(requestData)

        const selectDate = new Date(data?.booking_date as Date).getTime();
        const todayDate = new Date(moment().format("yyyy-MM-DD")).getTime();

        if (selectDate < todayDate) return failureNotify("Нельзя забронировать за прошлую дату");

        handleCreate(
            {
                ...requestData,
                city_id: city?.id,
                building_id: building?.id,
                floor_id: floor?.id,
                workplace_id: request?.id
            }
        )
    }

    function handleEditFields() {
        setIsEditFields(true)
    }

    function handleClosePopup() {
        setIsEditFields(false);
        onClose();
    }

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsEditFields(false);
                    onClose()
                }}
                widthProps={427}
                label={!isEdit
                    ? <>Бронирование</>
                    : <>Место {initialBooking?.place}</>}
                classNameHeader={"pb-10"}
            >
                <div className={"workplace__booking"}>
                    <div className={"workplace__booking__head"}>
                        {/*<div className={"title"}>*/}
                        {/*    {isMine ? <>Бронирование рабочего места</> : <>Рабочее*/}
                        {/*        место {booking?.name}</>}*/}
                        {/*</div>*/}

                        <div className={"workplace__booking__address"}>
                            {isMine ? (
                                <>{`${city?.name || ""}, ${building?.name || ""}, ${floor?.number}-й этаж, ${initialBooking?.place || ""} место`}</>
                            ) : (
                                <>{`${city?.name || ""}, ${building?.name || ""}, ${floor?.number}-й этаж`}</>
                            )}
                        </div>

                        {/*{!isMine && (*/}
                        {/*    <div className={"close"} onClick={onClose}>*/}
                        {/*        <CloseIcon />*/}
                        {/*    </div>*/}
                        {/*)}*/}
                    </div>

                    {isEditFields ? (
                        <form
                            className={"workplace__booking__form d-flex flex-column gap-5"}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {request?.is_department && (
                                <div className={"workplace__booking__department"}>
                                    {`Место закреплено за департаментом: `}

                                    <span>{request?.department_title}</span>
                                </div>
                            )}

                            {request?.is_pinned && `Постоянное рабочее место`}

                            {request?.request?.peripheries && (
                                <div className={"d-flex flex-column gap-5"}>
                                    {/*<PeripheryBlock*/}
                                    {/*    defaultValue={request?.request?.peripheries || []}*/}
                                    {/*    label="Периферия рядом"*/}
                                    {/*    isEditable={false}*/}
                                    {/*/>*/}
                                </div>
                            )}

                            {!request?.is_pinned && (
                                <div className={"d-flex flex-column gap-5"}>
                                    <Controller
                                        name={"booking_date"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                startDate={value}
                                                className=""
                                                label="Дата"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                                minDate={new Date()}
                                            />
                                        )}
                                    />

                                    <div className={"d-flex gap-3"}>
                                        <Controller
                                            name={"start_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время начала"
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />

                                        <Controller
                                            name={"end_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время завершения"
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            {isMine && (
                                <div className={"workplace__booking__btns"}>
                                    <Button
                                        type={"submit"}
                                        text={isEdit ? "Сохранить" : "Забронировать"}
                                        className={"btn btn-primary w-100"}
                                        disabled={!isValid}
                                    />

                                    <Button
                                        text={"Отмена"}
                                        className={"btn btn-light ml-0 w-100"}
                                        onClick={() => setIsEditFields(false)}
                                    />
                                </div>
                            )}
                        </form>
                        ) : (
                        <form
                            className={"workplace__booking__form d-flex flex-column gap-5"}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {request?.is_department && (
                                <div className={"workplace__booking__department"}>
                                    {`Место закреплено за департаментом: `}

                                    <span>{request?.department_title}</span>
                                </div>
                            )}

                            {isEdit && (
                                <div className="sport__content-card-user w-100">
                                    <img src={request?.member?.photo} alt=""
                                         className="sport__content-card-user__image" />

                                    <div className="sport__content-card-user__content">
                                        <h1 className="sport__content-card-user__content__name">{request?.member?.name}</h1>
                                        <p className="sport__content-card-user__content__position">{request?.member?.position}</p>

                                        <p className="layout-vacation-modal__block__desc__department">{request?.member?.company}</p>
                                    </div>
                                </div>
                            )}

                            {request?.is_pinned && `Постоянное рабочее место`}

                            {request?.request?.peripheries && (
                                <div className={"d-flex flex-column gap-5"}>
                                    {/*<PeripheryBlock*/}
                                    {/*    defaultValue={request?.request?.peripheries || []}*/}
                                    {/*    label="Периферия рядом"*/}
                                    {/*    isEditable={false}*/}
                                    {/*/>*/}
                                </div>
                            )}

                            {(!request?.is_pinned && !isEdit) && (
                                <div className={"d-flex flex-column gap-5"}>
                                    <Controller
                                        name={"booking_date"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                startDate={value}
                                                className=""
                                                label="Дата"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                                minDate={new Date()}
                                                disabled={isEdit}
                                            />
                                        )}
                                    />

                                    <div className={"d-flex gap-3"}>
                                        <Controller
                                            name={"start_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время начала"
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    disabled={isEdit}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />

                                        <Controller
                                            name={"end_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время завершения"
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    disabled={isEdit}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                            )}

                            {(!request?.is_pinned && isEdit) && (
                                <div className={"d-flex gap-5"}>
                                    <DatePickerForm
                                        startDate={watch("booking_date")}
                                        className=""
                                        label="Дата"
                                        placeholder={"Выберите дату"}
                                        onChange={() => {}}
                                        isIcon
                                        isRightIcon
                                        disabled
                                    />

                                    <TextField
                                        value={timeMain}
                                        className=""
                                        title="Время"
                                        placeholder={"Время"}
                                        onChange={() => {}}
                                        disabled
                                    />
                                </div>
                            )}

                            {isMine && (
                                <div className={"workplace__booking__btns"}>
                                    {isEdit ? (
                                        <>
                                            <Button
                                                text={"Отменить бронь"}
                                                className={"btn btn-red w-100"}
                                                onClick={handleDelete}
                                            />

                                            <Button
                                                text={"Редактировать"}
                                                className={"btn btn-light ml-0 w-100"}
                                                onClick={handleEditFields}
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            type={"submit"}
                                            text={isEdit ? "Сохранить" : "Забронировать"}
                                            className={"btn btn-primary w-100"}
                                            disabled={!isValid}
                                        />
                                    )}
                                </div>
                            )}
                        </form>
                    )}
                </div>
            </DialogContainer>
        </>
    );
};

export default BookingWorkplaceDialog;