import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import TextField from "../../ui/form/Fields/TextField";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";
import { $getSearchCity, } from "../../../api/requests/workspace-settings";
import DatePickerTimeField from "../../ui/form/DatePickerForm/DatePickerTimeField";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import moment from "moment";
import {
    $createMeetBooking, $deleteMeetBooking,
    $updateMeetBooking
} from "../../../api/requests/workspace-settings/booking";
import { findTime } from "../../../utils/date/hourdsTime";
import {
    setStructure
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { useAppDispatch } from "../../../redux";
import { useDispatch } from "react-redux";
import { withDebounce } from "../../../functions";

interface WorkspaceAddOfficeProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    data?: any;
}

interface FormData {
    name: string;
    city_id: number;
    address: string;
    days: any[];
    start_time?: Date | null;
    end_time?: Date | null;
}

interface DaysList {
    name: string;
    label: string;
    isRed?: boolean;
}

export const daysList: DaysList[] = [
    {
        name: 'is_open_on_monday',
        label: 'Пн',
    },
    {
        name: 'is_open_on_tuesday',
        label: 'Вт',
    },
    {
        name: 'is_open_on_wednesday',
        label: 'Ср',
    },
    {
        name: 'is_open_on_thursday',
        label: 'Чт',
    },
    {
        name: 'is_open_on_friday',
        label: 'Пт',
    },
    {
        name: 'is_open_on_saturday',
        label: 'Сб',
        isRed: true,
    },
    {
        name: 'is_open_on_sunday',
        label: 'Вс',
        isRed: true,
    },
];

const WorkspaceAddOffice = (
    {
        data,
        isOpen,
        onClose,
        setIsOpen
    }: WorkspaceAddOfficeProps
) => {
    const dispatch = useDispatch();

    const isEdit = !!data;

    const [isStatusComplete, setIsStatusComplete] = useState(false);
    const [cityList, setCityList] = useState<any[]>([]);
    const [search, setSearch] = useState("");

    const getDateByTime = (time: number) => {
        const timeString = findTime(time)?.time;
        const date = moment(new Date()).format("YYYY-MM-DD")

        return moment(`${date}T${timeString}`).toDate();
    }

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid, errors },
        watch,
        setValue
    } = useForm<FormData>({
        values: {
            name: data?.name,
            city_id: data?.city_id,
            address: data?.address,
            start_time: data?.start_time ? getDateByTime(data.start_time) : null,
            end_time: data?.end_time ? getDateByTime(data.end_time) : null,
            days: []
        }
    });

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value === val);
        }
        return null;
    };

    useEffect(() => {
        getCityList();
    }, []);

    useEffect(() => {
        search && getCityList();
    }, [search]);

    useEffect(() => {
        if (data) {
            const filterList = daysList.filter(i => data[i.name]);

            setValue("days", filterList)

            const cityListUpdate = data?.city ? [{
                ...data.city,
                label: data?.city?.name,
                value: data?.city?.id
            }, ...cityList, ] : cityList

            setCityList(cityListUpdate)
        }
    }, [data]);

    useEffect(() => {
        withDebounce(() => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        })

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    function getCityList() {
        $getSearchCity( search && { name: search } ).then(res => {
            if (!res.data) return;

            const cityListUpdate = res.data.map((i: any) => ({
                ...i,
                label: i.name,
                value: i.id
            }))

            setCityList(cityListUpdate)
        })
    }

    function handleCloseModal(notUpdate?: boolean) {
        onClose(notUpdate || false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        const selectedDaysSet = new Set(request.days.map((day: DaysList) => day.name));

        const daysItem = daysList.reduce((acc, day) => {
            acc[day.name] = selectedDaysSet.has(day.name);
            return acc;
        }, {} as Record<string, boolean>);

        const requestData = {
            ...request,
            ...daysItem,
            days: undefined,
            start_time: moment.duration(moment(request.start_time).format("HH:mm")).asSeconds(),
            end_time: moment.duration(moment(request.end_time).format("HH:mm")).asSeconds(),
            is_active: true
        };

        if (isEdit) return editData(requestData)

        $createMeetBooking(requestData).then(res => {
            if (res.error || res.response) return;

            setIsStatusComplete(true);
        })
    }

    function editData(requestData: any) {
        $updateMeetBooking(data?.id, {
            ...requestData,
            id: data?.id,
        }).then(res => {
            if (res.error || res.response) return;

            handleCloseModal();
        })
    }

    function handleRemove() {
        $deleteMeetBooking(data.id).then(res => {
            handleCloseModal(true);
            dispatch(setStructure(""))
        })
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                reset();
                setIsStatusComplete(false);
            }}
            isShowCloseBtn={!isStatusComplete}
            widthProps={650}
            label={!isStatusComplete && (isEdit ? "Редактирование офиса" : "Новый офис")}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно создали офис.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form
                    className={"workspace-settings__meeting-meet__add"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="workspace-settings__meeting-meet__add-block">
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    title="Название"
                                    placeholder={"Введите название"}
                                    name="name"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Controller
                                name="city_id"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Введите город"}
                                        label={"Город"}
                                        options={cityList}
                                        value={getValue(cityList, value)}
                                        onChange={(select) => onChange(select.value)}
                                        onChangeInput={(val) => withDebounce(() => setSearch(val))}
                                    />
                                )}
                            />

                            <Controller
                                name="address"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        title="Адрес"
                                        placeholder={"Укажите адрес"}
                                        name="address"
                                        onChange={onChange}
                                        errors={errors}
                                    />
                                )}
                            />
                        </div>

                        <Controller
                            name="days"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <div className={"form-group"}>
                                    <label className={"form-label"}>
                                        Дни работы
                                    </label>

                                    <div className={"workspace-settings__meeting-meet__add-days"}>
                                        {daysList.map((item, idx) => (
                                            <div
                                                className={`btn ml-0 w-100 cursor-pointer 
                                                ${watch().days.find(i => i.name === item.name) ? "btn-primary" : "btn-light"}
                                                ${item.isRed ? "red" : ""}
                                                `}
                                                key={`day-item-${idx}`}
                                                onClick={() => {
                                                    const findKey = watch().days.find(i => i.name === item.name)

                                                    if (findKey) {
                                                        const updateList = [...watch().days].filter(i => i.name !== item.name)

                                                        onChange(updateList);

                                                        return;
                                                    }

                                                    onChange([...watch().days, item])
                                                }}
                                            >
                                                {item.label}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Controller
                                name={"start_time"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете время"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <DatePickerTimeField
                                        startDate={value}
                                        label="Начало"
                                        placeholder={"Выберите время"}
                                        onChange={onChange}
                                        isRightIcon
                                    />
                                )}
                            />

                            <div className={"workspace-settings__meeting-meet__add-time-empty"}>
                                <br/>

                                <p>-</p>
                            </div>

                            <Controller
                                name={"end_time"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете время"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <DatePickerTimeField
                                        startDate={value}
                                        label="Конец"
                                        placeholder={"Выберите время"}
                                        onChange={onChange}
                                        isRightIcon
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn flex-row"}>
                        {isEdit && (
                            <Button
                                type={"submit"}
                                text={"Удалить"}
                                className={"btn btn-danger w-100"}
                                onClick={handleRemove}
                            />
                        )}

                        <Button
                            type={"submit"}
                            text={isEdit ? "Сохранить" : "Добавить"}
                            className={"btn btn-primary ml-auto w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default WorkspaceAddOffice;