import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $createLinksField = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/linkCreate", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $editLinksField = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/linkEdit", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $deleteLinksField = (
    data: { id: any },
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/workspaces/linkDelete", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });