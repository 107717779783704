import React, { FC, useEffect, useState } from "react";

import {
    MenuIcon,
    SearchIcon,
    NotificationIcon, ArrowExitIcon, SettingIcon
} from "../../img";
import { isMd, isTillMd } from "../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { iconConfig } from "../../routes/routeLinks";
import Button from "../ui/form/Buttons/Button";
import { HeaderSearch } from "./header-search/HeaderSearch";
import { $showGroupById } from "../../api/requests/groups";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import { HeaderNotification } from "./header-notification/HeaderNotification";
import {profileData} from "../../utils/loadData/profileData";
import {BlankAva} from "../ui/avatars/BlankAva";
import {ModerationIcon} from "../../img/icons/header/ModerationIcon";

interface HeaderHomeProps {
    openMenu: () => void;
    setModal: any;
    modal: string;
    title?: string,
    is_drafted?: boolean,
}

const HeaderHome: FC<HeaderHomeProps> = ({ openMenu, title, is_drafted, modal, setModal }) => {
    const isMobile: boolean = isMd();
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { id } = useParams();

    const personalData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const listLength = useSelector((state: any) => state.routeTitle.listLength);

    const [notificationCount, setNotificationCount] = useState<number | null>(0);

    const removePatternWithSlash = (
        inputText: string,
        pattern: string
    ): string => {
        const regex = new RegExp(`/${pattern}$`);

        return inputText.replace(regex, "");
    };

    useEffect(() => {
        setNotificationCount(personalData.unwatched_notifications_count)
    }, [personalData]);

    const currentIcons: any = iconConfig(navigate, searchParams, id, listLength)[id ? removePatternWithSlash(location.pathname, id) : location.pathname] || {
        leftIcons: [
            {
                icon: <ArrowExitIcon />,
                onClick: () => navigate(-1)
            }
        ],
        rightIcons: [
            {
                type: "search",
                icon: <SearchIcon color="#000" />,
                onClick: () => {
                }
            },
            {
                type: "notification",
                icon:
                    <div className='header__listLength'>
                        <NotificationIcon color={"#000"} />

                        <div className='header__listLength__notification'>
                            1
                        </div>
                    </div>,
                onClick: () => {
                }
            }

        ]
    };

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleToMain = () => {
        if (personalData?.group_id && (+personalData.group_id !== 0))
            $showGroupById({ id: "0" }, { formData: true }).then(() => {
            });

        navigate("/?group_id=0");
    };

    const rightIcons = (iconObj: any, index: number) => {
        const isSearch = modal == "search";
        const setSearchPopup = (isPopup: boolean) => setModal(isPopup ? "search" : "");

        const isNotification = modal == "notification";
        const setNotificationPopup = (isPopup: boolean) => {
            setNotificationCount(null);
            setModal(isPopup ? "notification" : "")
        };

        return (
            <React.Fragment key={`current-right-icon-${index}`}>
                {
                    (iconObj.type === "search" && isAdmin) && (
                        <div
                            onClick={() => navigate("/workspace-settings")}
                            className={"header-search__closed"}
                        >
                            <SettingIcon color={"black"} />
                        </div>
                    )
                }
                {
                    iconObj.type == "search" && !isMobile &&
                    <HeaderSearch isPopup={isSearch} setPopup={setSearchPopup} />
                }
                {
                iconObj.type == "notification" &&
                    <div className='header__listLength'>
                        <HeaderNotification isPopup={isNotification} setPopup={setNotificationPopup} />

                        {(notificationCount && notificationCount > 0) ? (
                            <div className='header__listLength__notification' style={{color: '#fff'}}>
                                {notificationCount}
                            </div>
                        ) : (<></>)}
                    </div>
                }
                {
                    !(iconObj.type == "search" || iconObj.type == "notification") &&
                    <>
                        {iconObj.checkIsRole ? (
                            currentIcons.roles.includes(currentRole) ? (
                                <Button
                                    key={index}
                                    text={iconObj.icon}
                                    className="btn btn-icon p-0"
                                    onClick={iconObj.onClick}
                                />
                            ) : null
                        ) : (
                            <Button
                                key={index}
                                text={iconObj.icon}
                                className="btn btn-icon p-0"
                                onClick={iconObj.onClick}
                            />
                        )}
                    </>
                }
            </React.Fragment>
        );
    };

    return (
        <header className={"header"}>
            <div className="header__items">
                <div className={"container-fluid"}>
                    <div className={"header__item"}>
                        {isMobile && (
                            <>
                                {title ?
                                    (
                                        <div className="header__exitIcon"
                                             onClick={handleNavigation}>
                                            <ArrowExitIcon />
                                        </div>
                                    )
                                    :
                                    (
                                        <>
                                            {personalData.photo ? (
                                                <img src={personalData?.photo} alt="" className="header__profileIcon"/>
                                            ) : (
                                                <BlankAva
                                                    colorBg={"#00A7B5"}
                                                    colorText={"#00A7B5"}
                                                    text={personalData?.name}
                                                />
                                            )}
                                        </>
                                        // <img onClick={() => navigate("profile")}
                                        //      src={"/testImages/people/Image-1.png"}
                                        //      className="header__profileIcon" />
                                    )
                                }
                            </>
                        )}

                        <div className={"header__left"}>
                            {!isMobile && (
                                <button
                                    type={"button"}
                                    className={"header__menu"}
                                    onClick={openMenu}
                                >
                                    <MenuIcon color={"#000"} />
                                </button>
                            )}

                            {isMobile && title ? (
                                <div className="header-center__title">
                                    {title}
                                </div>
                            ) : (
                                <a className={"header__logo"} onClick={handleToMain}>
                                    <img
                                        src="/images/logo.png"
                                        alt="logo"
                                    />
                                </a>
                            )}

                            {/*{currentIcons.leftIcons.map((iconObj, index) => (*/}
                            {/*    <div*/}
                            {/*        key={index}*/}
                            {/*        className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer"*/}
                            {/*        onClick={iconObj.onClick}*/}
                            {/*    >*/}
                            {/*        {iconObj.icon}*/}
                            {/*    </div>*/}
                            {/*))}*/}
                        </div>

                        <div className={"header__right"}>
                            <div className={"header__other"}>
                                <>
                                    {
                                        currentIcons.rightIcons.map((iconObj: any, index: number) => rightIcons(iconObj, index))
                                    }
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default HeaderHome;
