import { FC } from "react";

export const SliderActivityIcon: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.2219 14.4889L9.51081 21.6L18.7553 11.6445L13.7775 8.80002L14.4886 2.40002L5.24414 12.3556L10.2219 14.4889Z"
                stroke="currentColor" strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};