import React, { useState } from "react";
import { ArrowExitIcon, CoinCompetitionIcon } from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import Button from "../../../components/ui/form/Buttons/Button";
import { $pickWinner } from "../../../api/requests/contest";

interface ContestsViewWinnerWinProps {
    data: any;
    contestData?: any;
    onSuccess?: any;
    onBack?: () => void;
}

const ContestsViewWinnerWin = (
    {
        data,
        contestData,
        onSuccess,
        onBack
    }: ContestsViewWinnerWinProps
) => {

    const [commentValue, setCommentValue] = useState<string>("");

    function handleBack() {
        onBack && onBack();
    }

    function handleWinner() {
        const requestData = {
            id: contestData.id,
            comment_id: data.id,
            reason: commentValue
        }

        $pickWinner(requestData).then(res => {
            if(res.error) return;
            onBack && onBack();
            onSuccess(res);
        })
    }
    
    return (
        <div className={"contests__winner contests__win"}>
            <div className={"contests__winner__header contests__win__header"}>
                <div className={"back"} onClick={handleBack}>
                    <ArrowExitIcon color={"#00A7B5"} size={14} strokeWidth={4} />
                </div>

                <h3>Победители</h3>
            </div>

            <div className={"contests__win__list"}>
                {/* {data?.selectUsersList?.map((item: any, idx: number) => ( */}
                <div className="layout-vacation-modal__block">
                    <div className="layout-vacation-modal__block__right">
                        <img src={data.photo} alt="" />

                        <div className="layout-vacation-modal__block__desc">
                            <h1>{data.name}</h1>
                            <p className="layout-vacation-modal__block__desc__position">{data?.position}</p>
                            <p className="layout-vacation-modal__block__desc__department">{data?.department}</p>
                        </div>
                    </div>
                </div>
                {/* ))} */}
            </div>

            <div className={"contests__win__price"}>
                {contestData?.points && contestData?.points != 0 && <>
                    <div className="w-50 block">
                        <div className={"d-flex align-items-center gap-3"}>
                            <span className={"small"}>За победу</span>
                            <CoinCompetitionIcon />
                            <p>{contestData?.points}</p>
                        </div>
                    </div>
                </>}
                {contestData?.awards && contestData?.awards != 0 && <>
                    <div className="w-50 block">
                        <div className={"d-flex align-items-center gap-3"}>
                            <span className={"small"}>За участие</span>
                            <CoinCompetitionIcon />
                            <p>{contestData?.awards}</p>
                        </div>
                    </div>
                </>}

            </div>

            <div className={"contests__win__comment"}>
                <TextField
                    value={commentValue}
                    onChange={setCommentValue}
                    title={"Причина победы"}
                    placeholder={"Причина"}
                    className={``}
                />
            </div>

            <div className={"contests__win__btns contests__winner__btns"}>
                <Button
                    text={"Начислить награду"}
                    className={"btn btn-primary w-100"}
                    onClick={handleWinner}
                />
            </div>
        </div>
    );
};

export default ContestsViewWinnerWin;