import { ArrowExitIcon, CloseIcon, HamburgerIcon, SettingIcon } from "../../../img";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useRef, useState } from "react";
import TextField from "../../../components/ui/form/Fields/TextField";
import {
    IServiceCategories, IServiceCities,
    IServicesField,
    IServicesStatuses,
    IServiceTypes
} from "../../../api/requests/services/interface";
import PlusIcon2 from "../../../img/icons/vacation/PlusIcon2";
import {
    ServicesCreateStatusesModal,
    ServicesCreateTextModal, ServicesEmployeeModal,
    ServicesEmployeesModal
} from "../../../components/services";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";
import { isMd } from "../../../utils";
import {
    $createService, $editServiceItem,
    $getCities,
    $getServiceCategories, $getServiceItem,
    $getServiceTypes, $uploadServiceImage
} from "../../../api/requests/services";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { IUsers } from "../../../api/requests/user/interface";
import FileField from "../../../components/ui/form/FileField/FileField";
import { BlankAva } from "../../../components/ui/avatars/BlankAva";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesCreatePage = () => {
    const location = useLocation();
    const pathname = location.pathname;
    const searchParams = new URLSearchParams(location.search);

    const navigate = useNavigate();
    const isMobile = isMd();

    const purpose: string = pathname.includes("edit") ? "edit" : "create";
    const testBtnCreate = purpose === "edit" ? "Сохранить" : "Опубликовать";
    const { id: serviceId } = useParams();

    const [hashtags, setHashtags] = useState<any>([]);
    const [currentHashtag, setCurrentHashtag] = useState("");

    const [isOpenModal, setIsOpenModal] = useState(0);

    const [fieldsData, setFieldsData] = useState<IServicesField[]>([]);
    const [editedData, setEditedData] = useState<any>();
    const [statusesEditedData, setStatusesEditedData] = useState<any>();

    const [typesData, setTypesData] = useState<IServiceTypes[]>([]);
    const [typesTemplateData, setTypesTemplateData] = useState([]);
    const [categoriesData, setCategoriesData] = useState<IServiceCategories[]>([]);
    const [citiesData, setCitiesData] = useState<IServiceCities[]>([]);

    const [editData, setEditData] = useState<any>(null);

    const defaultStatusesData = {
        push_text: "",
        mail_text: "",
        supervisor_notification_mail: 0,
        supervisor_notification_push: 0,
        executors_notification_mail: 0,
        executors_notification_push: 0,
        conciliator_notification_mail: 0,
        conciliator_notification_push: 0
    };

    const [systemStatusesData, setSystemStatusesData] = useState([
        {
            type: 1,
            name: "Создана",
            ...defaultStatusesData
        },
        {
            type: 2,
            name: "Ожидает проверки",
            ...defaultStatusesData
        },
        {
            type: 4,
            name: "В работе",
            ...defaultStatusesData
        },
        {
            type: 3,
            name: "Выполнена",
            ...defaultStatusesData
        },
        {
            type: 0,
            name: "Закрыта",
            ...defaultStatusesData
        },
        {
            type: -1,
            name: "Отменена",
            ...defaultStatusesData
        },
        {
            type: -2,
            name: "Отклонена",
            ...defaultStatusesData
        }
    ]);
    const [statusesData, setStatusesData] = useState<IServicesStatuses[]>([]);

    const [supervisorData, setSupervisorData] = useState<IUsers>();
    const [executorsData, setExecutorsData] = useState([]);
    const [conciliatorsData, setConciliatorData] = useState([]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<any>(
        (purpose === "edit" && editData) ? {
            values: {
                image: editData.image,
                title: editData?.title,
                description: editData?.description,
                footer: editData?.footer,
                category: editData?.category,
                cities: editData?.cities,
                is_private: editData?.is_private,
                is_hidden: editData?.is_hidden,
                is_pinned: editData?.is_pinned,
                is_active: editData?.is_active,
                type: editData?.type,
                template_type: editData?.template_type
            }
        } : {}
    );


    const pressChangeValue = (event: any) => {
        const value: string = event.target.value;

        if (event.key === "Enter" && value) {
            event.preventDefault();
            event.stopPropagation();

            setHashtags([...hashtags, value.startsWith("#") ? value : `#${value}`]);
            setCurrentHashtag("");
        }
    };

    const onDragEnd = (result: any) => {
        const { destination, draggableId, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === "group") {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...statusesData];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            setStatusesData(workValue);

            return;
        }
    };

    const handleDeleteInput = (
        idx: number,
        data: any,
        setData: any
    ) => {
        const updatedList = data && data?.filter((
            item: any,
            index: number
        ) => index !== idx);

        setData(updatedList);
    };

    const handleAddStatusBlock = () => {
        const lastId = statusesData.length > 0 ? statusesData[statusesData.length - 1].id : 0;
        const newItem = {
            // id: lastId + 1,
            name: "",
            title: "",
            ...defaultStatusesData
        };

        setStatusesData([...statusesData, newItem]);
    };

    const handleChangeStatusesValue = (id: number, value: string) => {
        const updatedList = statusesData.map((item, idx) =>
            id === idx ? { ...item, name: value } : item
        );

        setStatusesData(updatedList);
    };

    const getEmployeesModalData = (id: number) => {
        let obj: any;

        if (id === 6) {
            obj = {
                data: executorsData,
                dataToSet: setExecutorsData
            };
        } else if (id === 7) {
            obj = {
                data: conciliatorsData,
                dataToSet: setConciliatorData
            };
        }

        return obj;
    };

    const getDropdownItems = (data: any, title: any) => {
        return data?.map((item: any) => {
            return {
                value: item.id,
                label: item[title],
                ...item
            };
        });
    };

    const getTypesTemplateData = () => {
        const getList: any = typesData.find((item: any) => item.id == watch()?.type);

        setTypesTemplateData(getDropdownItems(getList?.templates, "title"));
    };


    useEffect(() => {
        getTypesTemplateData();
    }, [watch().type]);

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        $getServiceTypes({ newApi: true }).then(res => {
            if (!res.data) return;

            setTypesData(getDropdownItems(res?.data?.sample_types, "title"));
        });

        $getServiceCategories({ newApi: true }).then(res => {
            if (!res.data) return;

            setCategoriesData(getDropdownItems(res?.data?.categories, "title"));
        });

        $getCities({ newApi: true }).then(res => {
            if (!res) return;

            setCitiesData(getDropdownItems(res, "city"));
        });

        if (purpose === "edit" && serviceId) {
            $getServiceItem(serviceId, { formData: true, newApi: true }).then((res: any) => {
                if (!res) return;

                const data = res.data.sample;

                setEditData({
                    ...data,
                    image: data?.image_file,
                    category: {
                        ...data?.category,
                        value: data.category?.id,
                        label: data?.category?.title
                    },
                    cities: getDropdownItems(data.cities, "city"),
                    template_type: data?.type_template_id
                });

                setHashtags(data?.hashtags?.split(",")
                ?.map((item: string) => item?.trim()?.replace("#", ""))
                ?.filter((item: string) => item));

                setFieldsData(data?.fields);
                setSystemStatusesData(data?.system_statuses);
                setSupervisorData(data?.supervisor);
                setExecutorsData(data?.executors);
                setConciliatorData(data?.conciliators);
            });
        }
    }, []);

    function onSubmit(data: any) {
        const updatedList = {
            title: watch()?.title,
            type_id: watch()?.type,
            type_template_id: watch()?.template_type,
            cities: watch()?.cities?.map((item: any) => item.value),
            hashtags: hashtags?.join(", "),
            system_statuses: systemStatusesData,
            statuses: statusesData,
            executors: executorsData?.map((item: IUsers) => item.id),
            conciliators: conciliatorsData.map((item: IUsers) => item.id),
            supervisor_id: supervisorData?.id,
            is_active: watch().is_active ? 1 : 0,
            is_pinned: watch().is_pinned ? 1 : 0,
            is_private: watch().is_private ? 1 : 0,
            is_hidden: watch().is_hidden ? 1 : 0,
            fields: fieldsData,
            description: watch()?.description,
            footer: watch()?.footer,
            conciliator_group_id: 0,
            conciliator_type: 0,
            executor_group_id: 0,
            executor_type: 0,
            sample_category_id: watch()?.category?.value
        };

        if (purpose === "edit") return handleEditData(updatedList);

        $createService(updatedList, { newApi: true }).then(res => {
            if (!res.data) return;


            if (watch()?.image) {
                $uploadServiceImage(res?.data?.sample?.id || 0, { image: watch()?.image }, {
                    newApi: true,
                    formData: true
                });
            }

            navigate("/services");
            reset();
        });

    }

    function handleEditData(requestData: any) {
        if (typeof (watch()?.image) !== "string") {
            $uploadServiceImage(serviceId || 0, { image: watch()?.image }, {
                newApi: true,
                formData: true
            }).then(res => {
                if (res) return;

                reset();
                navigate(`/services`);
            });
        }

        $editServiceItem(serviceId || 0, requestData, { newApi: true }).then(res => {
            if (!res.data) return;

            reset();
            navigate(`/services`);
        });

    }

    return (
        <div className="services-create">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1> {purpose === "edit" ? "Редактирование" : "Создание сервиса"}</h1>
                    </div>
                )}
            </div>

            <div className="services-create-content">
                <div className="services-create-block">
                    <form
                        onSubmit={handleSubmit((obj) => onSubmit(obj))}
                        className="services-create-form"
                        onKeyDown={(e) => checkKeyDown(e)}
                    >
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"form-image"}
                                    upload={value}
                                    label="Изображение"
                                    accept=".png, .jpg, .svg"
                                    setUpload={onChange}
                                />
                            )}
                        />

                        <h1 className="services-create-form__title">Основные</h1>

                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Напишите название"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value || ""}
                                    className=""
                                    title="Заголовок заявки"
                                    placeholder={"Введите заголовок"}
                                    name="name"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Напишите описание"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value || ""}
                                    className=""
                                    title="Описание"
                                    placeholder={"Введите описание"}
                                    // name="desc"
                                    onChange={onChange}
                                    // errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="type"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    placeholder={"Тип сервиса"}
                                    label={"Тип сервиса"}
                                    options={typesData}
                                    value={typesData.find((item) => item.id == watch()?.type)}
                                    onChange={(e) => {
                                        setValue("type", e.value);
                                        setValue("template_type", 0);
                                    }}
                                />
                            )}
                        />

                        {typesTemplateData && (
                            <Controller
                                name="template_type"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Тип шаблона"}
                                        label={"Тип шаблона"}
                                        options={typesTemplateData}
                                        value={
                                            watch().template_type ?
                                                typesTemplateData?.find((item: any) => item.id == watch()?.template_type)
                                                :
                                                {}
                                        }
                                        onChange={(e) => setValue("template_type", e.value)}
                                    />
                                )}
                            />
                        )}

                        <Controller
                            name="category"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    placeholder={"Родительский сервис"}
                                    label={"Родительский сервис"}
                                    options={categoriesData}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="cities"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    isMulti
                                    isSearchable
                                    className={"form-select"}
                                    placeholder={"Город"}
                                    label={"Город"}
                                    options={[
                                        ...citiesData
                                    ]}
                                    value={value}
                                    onChange={onChange}
                                />
                            )}
                        />

                        <div className="services-create-form__hashtags">
                            <Controller
                                name="hashtags"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { value } }) => (
                                    <TextField
                                        value={currentHashtag}
                                        className=""
                                        title="Хэштеги"
                                        name="hashtags"
                                        onChange={() => {
                                            setCurrentHashtag(value);
                                        }
                                        }
                                        onKeyDown={(e: any) => pressChangeValue(e)}
                                    />
                                )}
                            />

                            <p className="services-create-form__hashtags__warning">
                                Введите ключевое слово и нажмите Enter
                            </p>

                            <div className="services-create-form__hashtags__values">
                                {hashtags?.map((
                                    value: any,
                                    idx: number
                                ) => (
                                    <div key={idx}>
                                        {value && (
                                            <div
                                                className="services-create-form__hashtags__values__item">
                                                <p>{value}</p>

                                                <div
                                                    onClick={() => handleDeleteInput(idx, hashtags, setHashtags)}
                                                    className="services-create-form__hashtags__values__delete">
                                                    <CloseIcon color="#00A7B5" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <span className="services-create-form__line"></span>

                        <h1 className="services-create-form__title">Форма</h1>

                        <div className="services-create-form-field">
                            <p className="services-create-form-field__label">Текстовое поле</p>

                            {fieldsData.map((
                                item,
                                idx
                            ) => (
                                <div className='services-create-form-field__multiple'>
                                    <div className="services-create-form-field__block" key={idx}>
                                        <input
                                            type="text"
                                            onChange={(e) => ""}
                                            disabled
                                            value={item.name}
                                            className="services-create-form-field__input"
                                        />

                                        <div className="services-create-form-field__input__right">
                                            <div className="services-create-form-field__input__button"
                                                 onClick={() => {
                                                     setEditedData(item);
                                                     setIsOpenModal(2);
                                                 }}
                                            >
                                                <SettingIcon color="#00A7B5" />
                                            </div>

                                            <div
                                                className="services-create-form-field__input__button"
                                                onClick={() => handleDeleteInput(idx, fieldsData, setFieldsData)}>
                                                <CloseIcon color="#FF0000" width={15} height={15} />
                                            </div>
                                        </div>
                                    </div>

                                    {(item.type === 'radio' || item.type === 'radiolist' || item.type === 'checkbox') && item.values  && Array.isArray(item.values) && (
                                        <div className="services-create-modal__list" style={{paddingLeft: 60}}>
                                            {item?.values?.map((item: any, idx: number) => (
                                                <div className="services-create-modal__list__item" key={idx}>
                                                    {item}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="services-create-form-field__button"
                                 onClick={() => setIsOpenModal(1)}>
                                <PlusIcon2 color="#007BFF" />
                            </div>
                        </div>

                        <span className="services-create-form__line"></span>

                        <Controller
                            name="footer"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Напишите описание"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value || ""}
                                    className=""
                                    title="Текст подписи"
                                    placeholder={"Оставить комментарий"}
                                    // name="desc"
                                    onChange={onChange}
                                    // errors={errors}
                                />
                            )}
                        />

                        <span className="services-create-form__line"></span>

                        <h1 className="services-create-form__title">Статусы заявки</h1>

                        <div className="services-create-form-field">
                            <p className="services-create-form-field__label">Системные статусы</p>

                            {systemStatusesData.map((
                                item,
                                idx
                            ) => (
                                <div className="services-create-form-field__block" key={idx}>
                                    <input
                                        type="text"
                                        disabled
                                        onChange={(e) => ""}
                                        value={item.name}
                                        className="services-create-form-field__input"
                                    />

                                    <div className="services-create-form-field__input__right">
                                        <div className="services-create-form-field__input__button"
                                             onClick={() => {
                                                 setStatusesEditedData(item);
                                                 setIsOpenModal(3);
                                             }}
                                        >
                                            <SettingIcon color="#00A7B5" />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="services-create-form-field">
                            <p className="services-create-form-field__label">Пользовательские
                                статусы</p>

                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="ROOT" type="group">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                            className="services-create-form-field"

                                        >
                                            {statusesData.map((
                                                item: IServicesStatuses,
                                                idx: number
                                            ) => (
                                                <Draggable
                                                    draggableId={`items-${idx}-block-${item?.id}`}
                                                    key={`items-${idx}-block-${item?.id}`}
                                                    index={idx}
                                                >
                                                    {(_provided) => (
                                                        <div
                                                            {..._provided.draggableProps}
                                                            {..._provided.dragHandleProps}
                                                            ref={_provided.innerRef}
                                                            className="services-create-form-field__block"
                                                        >
                                                            <div
                                                                className="services-create-form-field__left">
                                                                <HamburgerIcon />
                                                                <input
                                                                    type="text"
                                                                    onChange={(e) => handleChangeStatusesValue(idx, e.target.value)}
                                                                    value={item.name}
                                                                    className="services-create-form-field__input"
                                                                />
                                                            </div>


                                                            <div
                                                                className="services-create-form-field__input__right">
                                                                <div
                                                                    className="services-create-form-field__input__button"
                                                                    onClick={() => {
                                                                        setStatusesEditedData(item);
                                                                        setIsOpenModal(4);
                                                                    }}
                                                                >
                                                                    <SettingIcon color="#00A7B5" />
                                                                </div>

                                                                <div
                                                                    className="services-create-form-field__input__button"
                                                                    onClick={() => handleDeleteInput(idx, statusesData, setStatusesData)}>
                                                                    <CloseIcon color="#FF0000"
                                                                               width={15}
                                                                               height={15} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}

                                            {provided.placeholder}

                                            <div className="polls-create-answers__button"
                                                 onClick={() => handleAddStatusBlock()}>
                                                <PlusIcon2 color={"#007BFF"} size={32} />
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>

                        <span className="services-create-form__line"></span>

                        <h1 className="services-create-form__title">Руководитель заявки</h1>

                        <div className="services-create-form__employees">
                            {supervisorData && (
                                <div className={"services-create-form__employees__list"}
                                     onClick={() => setIsOpenModal(5)}>
                                    <div className="layout-vacation-modal__block">
                                        <div className="layout-vacation-modal__block__right">
                                            {supervisorData?.photo ? (
                                                <img src={supervisorData?.photo} alt="" />
                                            ) : (
                                                <BlankAva
                                                    colorBg={"#CCD9E0"}
                                                    colorText={"black"}
                                                    text={supervisorData?.name}
                                                />
                                            )}

                                            <div className="layout-vacation-modal__block__desc">
                                                <h1>{supervisorData?.name}</h1>

                                                <p className="layout-vacation-modal__block__desc__position">HR
                                                    менеджер</p>

                                                <p className="layout-vacation-modal__block__desc__department">Департамент
                                                    испытания и сертификации</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {!supervisorData?.id && (
                                <div className={"services-create-form__employees__bottom"}>
                                    <div className="services-create-form__employees__addButton"
                                         onClick={() => setIsOpenModal(5)}>
                                        <PlusIcon2 />
                                    </div>
                                </div>
                            )}
                        </div>

                        <span className="services-create-form__line"></span>

                        <h1 className="services-create-form__title">Исполнители заявки</h1>

                        <div className="services-create-form__employees">
                            {executorsData.length > 0 && (
                                <div className={"services-create-form__employees__list"}>
                                    {executorsData?.map((item: any, idx: number) => (
                                        <div key={idx} className="layout-vacation-modal__block"  onClick={() => setIsOpenModal(6)}>
                                            <div className="layout-vacation-modal__block__right">
                                                {item?.photo ? (
                                                    <img src={item?.photo} alt="" />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={"#CCD9E0"}
                                                        colorText={"black"}
                                                        text={item?.name}
                                                    />
                                                )}

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{item.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                                    <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className={"services-create-form__employees__bottom"}>
                                <div className="services-create-form__employees__addButton"
                                     onClick={() => setIsOpenModal(6)}>
                                    <PlusIcon2 />
                                </div>
                            </div>
                        </div>

                        <span className="services-create-form__line"></span>

                        <h1 className="services-create-form__title">Согласанты</h1>

                        <div className="services-create-form__employees">
                            {conciliatorsData.length > 0 && (
                                <div className={"services-create-form__employees__list"}  onClick={() => setIsOpenModal(7)}>
                                    {conciliatorsData?.map((item: any, idx: number) => (
                                        <div key={idx} className="layout-vacation-modal__block">
                                            <div className="layout-vacation-modal__block__right">
                                                {item?.photo ? (
                                                    <img src={item?.photo} alt="" />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={"#CCD9E0"}
                                                        colorText={"black"}
                                                        text={item?.name}
                                                    />
                                                )}

                                                <div className="layout-vacation-modal__block__desc">
                                                    <h1>{item.name}</h1>

                                                    <p className="layout-vacation-modal__block__desc__position">{item.position}</p>

                                                    <p className="layout-vacation-modal__block__desc__department">{item.department}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div className={"services-create-form__employees__bottom"}>
                                <div className="services-create-form__employees__addButton"
                                     onClick={() => setIsOpenModal(7)}>
                                    <PlusIcon2 />
                                </div>
                            </div>
                        </div>

                        <span className="services-create-form__line"></span>

                        <Controller
                            name="is_active"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={value}
                                    desc="Отображать заявку"
                                    handleChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="is_pinned"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={value}
                                    desc="Закрепить заявку"
                                    handleChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="is_private"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={value}
                                    desc="Данные доступны только отправителю и исполнителю"
                                    handleChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="is_hidden"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={value}
                                    desc="Работает, но скрыт от пользователей"
                                    handleChange={onChange}
                                />
                            )}
                        />


                        <div className="services-create-buttons">
                            <div className={"d-flex gap-3"}>
                                <Button
                                    text={"Отмена"}
                                    className={"btn btn-light w-100"}
                                    onClick={() => navigate(-1)}
                                />


                                <Button
                                    onClick={onSubmit}
                                    type={"submit"}
                                    text={testBtnCreate}
                                    className={"btn btn-primary w-100"}
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <ServicesCreateTextModal
                isOpen={isOpenModal === 1 || isOpenModal === 2}
                onClose={() => {
                    setIsOpenModal(0);
                    setEditedData({});
                }}
                purpose={isOpenModal === 1 ? "add" : "edit"}
                fieldsData={fieldsData}
                setFieldsData={setFieldsData}
                editedData={editedData}
            />

            <ServicesCreateStatusesModal
                isOpen={isOpenModal === 3 || isOpenModal === 4}
                onClose={() => setIsOpenModal(0)}
                purpose={"edit"}
                statusesData={isOpenModal === 3 ? systemStatusesData : statusesData}
                setStatusesData={isOpenModal === 3 ? setSystemStatusesData : setStatusesData}
                editedData={statusesEditedData}
                setEditedData={setStatusesEditedData}
            />

            <ServicesEmployeesModal
                isOpen={isOpenModal === 6 || isOpenModal === 7}
                onClose={() => setIsOpenModal(0)}
                data={getEmployeesModalData(isOpenModal)?.data}
                setData={getEmployeesModalData(isOpenModal)?.dataToSet}
            />

            <ServicesEmployeeModal
                isEdit={purpose === "edit"}
                isOpen={isOpenModal === 5}
                onClose={() => setIsOpenModal(0)}
                data={supervisorData}
                setData={setSupervisorData}
            />
        </div>
    );
};