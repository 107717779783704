import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import {
    AcceptedIcon,
    ArrowExitIcon,
    EyeIcon,
    RejectedIcon,
    ShareIcon, WorkspaceUsersIcon
} from "../../../img";
import { $changeStatusUserSport, $getSportById } from "../../../api/requests/sport";
import {
    SportDateData,
    SportItemData,
    SportResponseDateData, SportViewItemData
} from "../../../api/requests/sport/interface";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import Button from "../../../components/ui/form/Buttons/Button";
import { daysOfWeek, isMd, successNotify } from "../../../utils";
import { useSelector } from "react-redux";
import { getCurrentRole, getProfileVarsData } from "../../../redux";
import { UserData } from "../../../components/ui/form/SearchItem";
import TrainerModalSingUser from "./TrainerModalSingUser";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { UserProfileAdd } from "../../../img/icons/UserProfileAdd";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";
import { EyeIcon2 } from "../../../img/icons/news/EyeIcon2";

const TrainerViewPage = () => {
    const isMobile: boolean = isMd();

    const navigate = useNavigate();
    const { id } = useParams();

    const vars = useSelector(getProfileVarsData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [trainingSessionData, setTrainingSessionData] = useState<SportViewItemData | null>(null);
    const [selectRecord, setSelectRecord] = useState<UserData | null>(null);
    const [selectSchedule, setSelectSchedule] = useState<any>(null);
    const [filterAttendance, setFilterAttendance] = useState<any>(null);

    const [isOpenModalSign, setIsOpenModalSign] = useState<boolean>(false);
    const [isLoadingStatuses, setIsLoadingStatuses] = useState<boolean>(false);
    const [isOpenRecord, setIsOpenRecord] = useState(false);

    const transformDateData = (data: SportResponseDateData[]): SportDateData[] => {
        return data.map((item) => {
            const dateObj = new Date(item.date_from_at);
            const weekDay = daysOfWeek[dateObj.getDay()];
            const formattedDate = `${String(dateObj.getDate()).padStart(2, "0")}.${String(dateObj.getMonth() + 1).padStart(2, "0")}`; // Format date as dd.mm
            const timeStart = new Date(item.date_from_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const timeEnd = new Date(item.date_to_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const time = `${timeStart} - ${timeEnd}`;

            return {
                ...item,
                week: weekDay,
                date: formattedDate,
                time: time
            };
        });
    };

    const transformContentData = (item: SportItemData): SportViewItemData => {
        return {
            ...item,
            schedules: transformDateData(item.schedules),
        };
    };

    const handleNavigation = () => {
        navigate(-1);
    };

    const statusColor = (status: number): string => {
        if (status === 1) return `${vars.colors.primaryColor}26`
        if (status === -2) return "#FF00001A"

        return ""
    }

    useEffect(() => {
        init();
    }, []);

    function init (schedule?: any) {
        if (!id) return;

        $getSportById(true, id, { with_users: true }, { newApi: true }).then(res => {
            if (!res.data) return;

            const list = transformContentData(res.data)

            setTrainingSessionData(list);

            if (schedule) {
                const findSchedule = list?.schedules.find(item => item.id === schedule.id)
                findSchedule && handleChangeDateWorkout(findSchedule)
            }
        });
    }

    function handleOpenSign() {
        setIsOpenModalSign(true);
    }

    function handleChangeDateWorkout(select: SportDateData) {
        if (!trainingSessionData) return;

        setSelectSchedule(select);

        const usersFilter = select?.users ? select.users : []

        setFilterAttendance(usersFilter);
    }

    function handleChangeStatus(select: UserData, newStatus: number) {
        if (!trainingSessionData || !filterAttendance) return;

        setIsLoadingStatuses(true);

        const listUpdate = filterAttendance.map((item: any) => ({
            ...item,
            status: item.id === select.id ? newStatus : item.status
        }));

        setFilterAttendance(listUpdate);

        setIsLoadingStatuses(false);
        setSelectRecord(null);

        const requestData = {
            schedule_id: selectSchedule.id,
            user_id: select.user_id,
            status: newStatus
        }

        $changeStatusUserSport(requestData, { newApi: true }).then(res => {
            if (res.error || res.response) return;
        })
    }

    function handleShare() {
        const url = window.location.href;
        navigator.clipboard.writeText(url);

        successNotify("Ссылка успешно скопирована").then();
    }

    return (
        <div className="sport__trainer__view">
            <div className="container">
                <div className="news-show-content__left">
                    <div className="sport__content-card-block-content">
                        <div className="sport__trainer__view-content__top">
                            <div className="layout-inner__header__title">
                                <div className="header__exitIcon" onClick={handleNavigation}>
                                    <ArrowExitIcon />
                                </div>

                                <h2>{trainingSessionData?.title}</h2>
                            </div>

                            {(trainingSessionData?.venue && !isMobile) && (
                                <div className="sport__view__content__location">
                                    <LocationIcon color={vars.colors.primaryColor}/>

                                    <p>Стадион “Спартак”, г. Москва, Лененский
                                        проспект</p>
                                </div>
                            )}
                        </div>

                        <div className="sport__trainer__view-content__text">
                            {trainingSessionData?.description}
                        </div>

                        {(trainingSessionData?.venue && isMobile) && (
                            <div className="sport__view__content__location">
                                <LocationIcon color={vars.colors.primaryColor}/>

                                <p>Стадион “Спартак”, г. Москва, Лененский
                                    проспект</p>
                            </div>
                        )}

                        <div className={`sport__trainer__view-content__participants`}>
                            <b>Участники:</b> {selectSchedule?.users?.length || 0}/{trainingSessionData?.seats}
                        </div>

                        <div className={"sport__trainer__view-content__date"}>
                            <h5>Для начала выберите время тренировки</h5>

                            <div className="sport__content-card-block-content__bottom sport__trainer__view-content__bottom">
                                {trainingSessionData?.schedules && trainingSessionData.schedules.map((item, idx) => (
                                    <div
                                        key={`date-item-${idx}`}
                                        className="sport__content-card-block-content__bottom__block"
                                        onClick={() => handleChangeDateWorkout(item)}
                                        style={{
                                            backgroundColor: (selectSchedule?.id === item.id) ? vars.colors.primaryColor : `${vars.colors.primaryColor}0D`,
                                            color: (selectSchedule?.id === item.id) ? "white" : vars.colors.primaryColor
                                        }}
                                    >
                                        <p style={{ color: (selectSchedule?.id === item.id) ? "white" : vars.colors.primaryColor }}>{item.week}</p>
                                        {item.date && <hr />}
                                        {item.date && <p style={{ color: (selectSchedule?.id === item.id) ? "white" : vars.colors.primaryColor }}>{item.date}</p>}
                                        {item.date && <hr />}
                                        <p style={{ color: (selectSchedule?.id === item.id) ? "white" : vars.colors.primaryColor }}>{item.time}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    {filterAttendance && (
                        <div className="sport__trainer__view-content__users">
                            <h5>Явка на тренировку:</h5>

                            <div className="sport__trainer__view-content__users__items">
                                {filterAttendance.map((item: any, idx: number) => (
                                    <div
                                        key={`training-item-${idx}`}
                                        className="sport__content-card-user sport__trainer__view-content-card-user"
                                        style={{
                                            backgroundColor: statusColor(item.status || 0),
                                            opacity: item.status === -1 ? 0.5 : 1
                                        }}
                                    >
                                        <BlankImage
                                            image={item?.user?.photo}
                                            classname={"sport__content-card-user__image"}
                                        />

                                        <div className="sport__content-card-user__content">
                                            <h1 className="sport__content-card-user__content__name">{item?.user?.name}</h1>
                                        </div>

                                        <div className={"sport__content-card-user__btn"}>
                                            {[0, -2].includes(item.status || 0) && (
                                                <Button
                                                    text={<AcceptedIcon color={"white"}/>}
                                                    className="btn btn-primary"
                                                    style={{ backgroundColor: vars.colors.primaryColor }}
                                                    onClick={() => {
                                                        setIsOpenRecord(true);
                                                        setSelectRecord(item);
                                                    }}
                                                    disabled={isLoadingStatuses}
                                                />
                                            )}

                                            {[0, 1].includes(item.status || 0) && (
                                                <Button
                                                    text={<RejectedIcon color={"white"}/>}
                                                    className="btn btn-danger"
                                                    onClick={() => handleChangeStatus(item, -2)}
                                                    disabled={isLoadingStatuses}
                                                />
                                            )}

                                            {item.status === -1 && (
                                                <p>Отменено</p>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}

                    <div className="sport__content-card-block__bottom">
                        <div className="news-show-info__buttons">
                            <Button
                                leftIcon={<UserProfileAdd/>}
                                text={"Записать на тренировку"}
                                className="btn btn-primary news-show-info__buttons__likeButton"
                                style={{backgroundColor: vars.colors.primaryColor}}
                                onClick={handleOpenSign}
                                disabled={!selectSchedule}
                            />

                            {currentRole !== 3 && (
                                <button
                                    className="news-show-info__buttons__shareButton"
                                    style={{ color: vars.colors.primaryColor }}
                                    onClick={handleShare}
                                >
                                    <ShareIcon size={24} color={vars.colors.primaryColor} />
                                    <p>Поделиться</p>
                                </button>
                            )}
                        </div>

                        <div className="news-show-info__stats">
                            {trainingSessionData?.views && (
                                <div className="news-show-info__stats__block">
                                    <EyeIcon2 color="#000" size={20} />

                                    <p>{trainingSessionData.views}</p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <TrainerModalSingUser
                isOpen={isOpenModalSign}
                setIsOpen={() => {
                    setIsOpenModalSign(false);
                }}
                onClose={() => {
                    setIsOpenModalSign(false);
                    init(selectSchedule);
                }}
                scheduleId={selectSchedule?.id}
            />

            <DialogContainer
                isOpen={isOpenRecord}
                setIsOpen={setIsOpenRecord}
                isShowCloseBtn={false}
                classNameModal={"p-0"}
                widthProps={427}
            >
                <div className={"sport__view__modal contests__winner"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3 className={"text-center mb-2"}>Пользователь был на тренировке?</h3>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Записать"}
                            className={"btn btn-primary-revert w-100 mb-2"}
                            onClick={() => {
                                setIsOpenRecord(false);
                                selectRecord && handleChangeStatus(selectRecord, 1);
                            }}
                        />

                        <Button
                            text={"Отмена"}
                            className={"btn btn-light w-100 ml-0"}
                            onClick={() => setIsOpenRecord(false)}
                        />
                    </div>
                </div>
            </DialogContainer>
        </div>
    );
};

export default TrainerViewPage;