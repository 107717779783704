import { FolderIcon } from "../../../img/icons/files/FolderIcon";
import { Controller, useForm } from "react-hook-form";
import TextField from "../../ui/form/Fields/TextField";
import React, { FC, useEffect, useState } from "react";
import { FileCategoryData, ICreateRequestFolder } from "../../../api/requests/files/interface";
import { $addFolder, $updateFolder } from "../../../api/requests/files";
import { store } from "../../../redux";
import { setToAddFolder } from "../../../redux/reducers/workplace/workplaceSlice";


interface IFileRenameInputProps {
    categoryId: number | undefined;
    item?: FileCategoryData | null;
    refresh: () => void;
}

export const FileCategoryRenameInput: FC<IFileRenameInputProps> = (
    {
        item,
        categoryId,
        refresh
    }
) => {

    const isEdit = item != null;

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid },
        setValue
    } = useForm<ICreateRequestFolder>(
        (isEdit && item) ? {
            values: {
                name: item.name
            }
        } : {}
    );

    function addNewFile() {
        const requestData = {
            name: watch().name,
            parent_id: categoryId
        };

        if (isEdit)
            $updateFolder(item.id, requestData, { newApi: true }).then(() => {
                refresh();
            });
        else
            $addFolder(requestData, { formData: true, newApi: true }).then(() => {
                refresh();
            });

        store.dispatch(setToAddFolder(false));
        reset();
    }

    return (
        <div className="files__new-item">
            <FolderIcon />

            <form onSubmit={handleSubmit(addNewFile)} className="files__form">
                <Controller
                    name="name"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Напишите название"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            autoFocus={true}
                            value={value || ""}
                            className=""
                            placeholder={"Введите название"}
                            name="name"
                            onChange={onChange}
                        />
                    )}
                />
            </form>
        </div>
    );
};