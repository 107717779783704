import DialogContainer from "../../ui/dialog/DialogContainer";
import React, { FC, useEffect, useState } from "react";
import { CloseIcon, PlusIcon2 } from "../../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import TextField from "../../ui/form/Fields/TextField";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import { IServicesField } from "../../../api/requests/services/interface";
import { failureNotify } from "../../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface IServicesTextModal {
    isOpen: boolean;
    onClose: () => void;
    purpose: string,
    fieldsData: IServicesField[],
    editedData?: IServicesField,
    setFieldsData: any,
}

export const ServicesCreateTextModal: FC<IServicesTextModal> = (
    {
        isOpen,
        onClose,
        purpose,
        fieldsData,
        setFieldsData,
        editedData
    }) => {
    const [listData, setListData] = useState<any[]>([{ id: 1, title: "" }]);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Текстовая строка",
            value: "text"
        },
        {
            label: "Единственный вариант из списка",
            value: "radio"
        },
        {
            label: "Выпадающий список",
            value: "radiolist"
        },
        {
            label: "Множественный вариант из списка",
            value: "checkbox"
        },
        {
            label: "Файл",
            value: "file"
        },
        {
            label: "Дата",
            value: "date_from_to"
        }
    ]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<IServicesField>(
        (purpose === "edit" && editedData) ? {
            values: {
                name: editedData?.name,
                not_after_to: editedData?.not_after_to,
                not_after_from: editedData?.not_after_from,
                not_before_to: editedData?.not_before_to,
                not_before_from: editedData?.not_before_from,
                type: editedData?.type === "date_from" ? "date_from_to" : editedData?.type,
                required: editedData?.required,
                values: editedData?.values
            }
        } : {
            defaultValues: {
                not_limited_after: true,
                not_limited_before: true,
                toggle: true
            }
        }
    );

    function getTypeValues() {
        let values;

        if (watch().type === "text") {
            values = watch().values || {};
        } else if (watch().type === "file") {
            values = [];
        } else {
            values = listData?.map((item) => item.title);
        }

        return values;
    }

    const getType = () => {
        let value;
        if (watch().type === "date_from_to") {
            if (!watch().toggle) {
                value = "date_from";
            } else {
                value = "date_from_to";
            }
        } else {
            value = watch().type;
        }

        return value;
    };

    const addFieldData = (obj: any) => {
        const updatedList = {
            name: watch().name,
            type: getType() || "text",
            required: watch().required || false,
            values: getTypeValues(),
            autofill: {},
            not_before_to: watch().not_before_to || false,
            not_before_from: watch().not_before_from || false,
            not_after_to: watch().not_after_to || false,
            not_after_from: watch().not_after_from || false
        };

        if (!updatedList.name) {
            failureNotify("Введите название");
        } else if ((updatedList.type === "radio" || updatedList.type === "radiolist" || updatedList.type === "checkbox") && !updatedList.values) {
            failureNotify("Введите параметры поля");
        } else {

            if (purpose === "edit") {
                const list = fieldsData.map((item) =>
                    item.name === editedData?.name ? updatedList : item
                );

                setFieldsData(list);
            } else {
                setFieldsData([...fieldsData, updatedList]);
            }

            setValue("name", "");
            setValue("type", "");
            setValue("values", "");
            setValue("required", false);
            setValue("not_before_to", false);
            setValue("not_before_from", false);
            setValue("not_after_to", false);
            setValue("not_after_from", false);
            setListData([{ id: 1, title: "" }]);
            onClose();
        }
    };


    const handleChangeListItems = (item: any, e: any) => {
        const updatedList = listData.map((value) =>
            value.id == item.id ? { ...value, title: e } : value
        );

        setListData(updatedList);
    };

    const handleToggleChange = (name: keyof IServicesField) => {
        setValue("not_limited_before", name === "not_limited_before");
        setValue("not_before_from", name === "not_before_from");
        setValue("not_before_to", name === "not_before_to");
    };

    const handleToggleChangeSecond = (name: keyof IServicesField) => {

        setValue("not_limited_after", name === "not_limited_after");
        setValue("not_after_from", name === "not_after_from");
        setValue("not_after_to", name === "not_after_to");
    };

    const handleAddListItem = () => {
        const lastId = listData?.length > 0 ? listData[listData?.length - 1].id : 0;

        if (listData?.length > 0) {
            setListData([
                ...listData,
                { id: lastId + 1, title: "" }
            ]);
        } else {
            setListData([{ id: lastId + 1, title: "" }]);
        }
    };

    const handleDeleteListItem = (id: number) => {
        const updatedList = listData.filter((item) => item.id != id);

        setListData(updatedList);
    };

    const handleDeleteData = () => {
        const updatedList = fieldsData.filter((item) =>
            item.name !== editedData?.name
            &&
            item.type !== editedData?.type
        );

        setFieldsData(updatedList);
        onClose();
    };

    useEffect(() => {
        if (purpose === "edit" && editedData?.type !== "text" && editedData?.type !== "file") {
            const updatedList: any = editedData?.values?.map((item: string, idx: number) => {
                return {
                    id: idx,
                    title: item
                };
            });

            setListData(updatedList);
        }
    }, [editedData]);

    useEffect(() => {
        // setValue("type", "text");
        if (!editedData?.not_after_from && !editedData?.not_after_to) {
            setValue("not_limited_after", true);
        }

        if (!editedData?.not_before_from && !editedData?.not_before_to) {
            setValue("not_limited_before", true);
        }

        if (editedData?.type === "date_from") {
            setValue("toggle", false);
        } else {
            setValue("toggle", true);
        }

    }, [isOpen]);

    return (
        <DialogContainer
            isOpen={isOpen}
            closeModal={onClose}
            classNameModal="services-create-modal"
            isShowCloseBtn={false}
        >
            <div className="services-create-modal__header">
                <h1
                    className="services-create-modal__header__title">{purpose === "edit" ? "Редактор поля" : "Новое поле"}</h1>

                <div className="services-create-modal__header__close" onClick={onClose}>
                    <CloseIcon color="#000" />
                </div>
            </div>

            <div className="services-create-modal__content">
                <form className="services-create-form">
                    <Controller
                        name="type"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Текстовая строка"}
                                label={"Тип"}
                                options={categoryList}
                                value={categoryList.find((item) => item.value === watch()?.type)}
                                onChange={(e) => setValue("type", e.value)}
                                defaultValue={categoryList[0]}
                            />
                        )}
                    />

                    <Controller
                        name="name"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { value, onChange } }) => (
                            <TextField
                                value={value || ""}
                                className=""
                                title="Название поля"
                                placeholder="Название поля"
                                name="title"
                                onChange={onChange}
                            />
                        )}
                    />

                    {(watch().type === "text" || !watch().type) && (
                        <Controller
                            name="values"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    placeholder={"Текстовая строка"}
                                    label={""}
                                    options={[
                                        {
                                            name: "Не заполнять",
                                            type: "public",
                                            label: "Не заполнять",
                                            value: ""
                                        },
                                        {
                                            column: "registration_date",
                                            type: 0,
                                            label: "Дата регистрации",
                                            value: 0
                                        },
                                        {
                                            column: "id",
                                            type: 0,
                                            label: "ID",
                                            value: 2
                                        },

                                        {
                                            column: "firstname",
                                            type: 0,
                                            label: "Имя",
                                            value: 3
                                        },
                                        {
                                            column: "lastname",
                                            type: 0,
                                            label: "Фамилия",
                                            value: 4
                                        },
                                        {
                                            column: "sex",
                                            type: 0,
                                            label: "Пол",
                                            value: 5
                                        },
                                        {
                                            column: "phone",
                                            type: 0,
                                            label: "Телефон",
                                            value: 6
                                        },
                                        {
                                            column: "registration_date",
                                            type: 0,
                                            label: "Дата регистрации",
                                            value: 7
                                        },
                                        {
                                            column: "mail",
                                            type: 0,
                                            label: "Почта",
                                            value: 8
                                        },
                                        {
                                            column: "birth",
                                            type: 0,
                                            label: "Дата рождения",
                                            value: 9
                                        },
                                        {
                                            column: "inner_id",
                                            type: 0,
                                            label: "Табельный номер",
                                            value: 10
                                        },
                                        {
                                            column: "555",
                                            type: 0,
                                            label: "Новое поasd",
                                            value: 11
                                        },
                                        {
                                            column: "541",
                                            type: 0,
                                            label: "Новое поле",
                                            value: 12
                                        },
                                        {
                                            column: "383",
                                            type: 0,
                                            label: "Подразделение",
                                            value: 13
                                        },
                                        {
                                            column: "344",
                                            type: 0,
                                            label: "Офис",
                                            value: 14
                                        },
                                        {
                                            column: "342",
                                            type: 0,
                                            label: "Город",
                                            value: 15
                                        },
                                        {
                                            column: "341",
                                            type: 0,
                                            label: "Внутренний табельный номер",
                                            value: 16
                                        },
                                        {
                                            column: "334",
                                            type: 0,
                                            label: "Должность",
                                            value: 17
                                        }
                                    ]}
                                    value={value?.label ? value : {
                                        name: "Не заполнять",
                                        type: "public",
                                        label: "Не заполнять",
                                        value: ""
                                    }}
                                    onChange={onChange}
                                    defaultValue={
                                        {
                                            name: "Не заполнять",
                                            type: "public",
                                            label: "Не заполнять",
                                            value: ""
                                        }
                                    }
                                />
                            )}
                        />
                    )}

                    {(watch().type === "radio" || watch().type === "radiolist" || watch()?.type === "checkbox") && (
                        <div className="services-create-modal__list">
                            {listData?.map((item, idx) => (
                                <div className="services-create-modal__list__item" key={idx}>
                                    <input
                                        type="text"
                                        value={item.title}
                                        onChange={(e) => handleChangeListItems(item, e.target.value)}
                                    />

                                    {idx != 0 && (
                                        <div className="services-create-modal__list__item__close"
                                             onClick={() => handleDeleteListItem(item.id)}>
                                            <CloseIcon color={"#FF0000"} />
                                        </div>
                                    )}
                                </div>
                            ))}

                            <div className="services-create-modal__list__button"
                                 onClick={handleAddListItem}>
                                <PlusIcon2 />
                            </div>
                        </div>
                    )}

                    {watch().type === "date_from_to" && (
                        <div className="services-create-modal__dates">
                            <Controller
                                name="toggle"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <ToggleBlock
                                        isChecked={value}
                                        desc="Указывать интервал дат"
                                        handleChange={onChange}
                                    />
                                )}
                            />

                            <div className="services-create-modal__dates__content">
                                <div className="services-create-modal__dates__content__item">
                                    <p className="services-show__form__label">Дата:</p>

                                    <Controller
                                        name="not_limited_before"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="layout-contest__block">
                                                <h3>Не ограничивать</h3>

                                                <input
                                                    type="checkbox"
                                                    checked={value}
                                                    onChange={() => handleToggleChange("not_limited_before")}
                                                />
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="not_before_from"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="layout-contest__block">
                                                <h3>Не раньше текущей даты</h3>

                                                <input
                                                    type="checkbox"
                                                    checked={value}
                                                    onChange={() => handleToggleChange("not_before_from")}
                                                />
                                            </div>
                                        )}
                                    />

                                    <Controller
                                        name="not_before_to"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <div className="layout-contest__block">
                                                <h3>Не позже текущей даты</h3>

                                                <input
                                                    type="checkbox"
                                                    checked={value}
                                                    onChange={() => handleToggleChange("not_before_to")}
                                                />
                                            </div>
                                        )}
                                    />
                                </div>

                                {watch().toggle && (
                                    <div className="services-create-modal__dates__content__item">
                                        <p className="services-show__form__label">Дата:</p>

                                        <Controller
                                            name="not_limited_after"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <div className="layout-contest__block">
                                                    <h3>Не ограничивать</h3>

                                                    <input
                                                        type="checkbox"
                                                        checked={value}
                                                        onChange={() => handleToggleChangeSecond("not_limited_after")}
                                                    />
                                                </div>
                                            )}
                                        />

                                        <Controller
                                            name="not_after_from"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <div className="layout-contest__block">
                                                    <h3>Не раньше текущей даты</h3>

                                                    <input
                                                        type="checkbox"
                                                        checked={value}
                                                        onChange={() => handleToggleChangeSecond("not_after_from")}
                                                    />
                                                </div>
                                            )}
                                        />

                                        <Controller
                                            name="not_after_to"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <div className="layout-contest__block">
                                                    <h3>Не позже текущей даты</h3>

                                                    <input
                                                        type="checkbox"
                                                        checked={value}
                                                        onChange={() => handleToggleChangeSecond("not_after_to")}
                                                    />
                                                </div>
                                            )}
                                        />

                                        {/*{beforeDatesList.map((check: any, idx) => (*/}
                                        {/*    <div className="layout-contest__block"*/}
                                        {/*         key={idx}*/}
                                        {/*    >*/}
                                        {/*        <h3>{check.title}</h3>*/}

                                        {/*        <input*/}
                                        {/*            type="checkbox"*/}
                                        {/*            checked={check.value}*/}
                                        {/*            onChange={(e) =>*/}
                                        {/*                handleChangeToggleValues(check.id, check.value == 1 ? 0 : 1, beforeDatesList, setBeforeDatesList)*/}
                                        {/*            }*/}
                                        {/*        />*/}
                                        {/*    </div>*/}
                                        {/*))}*/}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}


                    <Controller
                        name="required"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <ToggleBlock
                                isChecked={value || false}
                                desc="Обязательно для заполнения"
                                handleChange={(e) => setValue("required", e)}
                            />
                        )}
                    />
                </form>
            </div>

            <div className="services-create-modal__buttons">
                <div onClick={addFieldData}
                     className="services-create-modal__buttons__item services-create-modal__buttons__add">
                    {purpose ? "Сохранить" : "Добавить"}
                </div>

                {purpose === "edit" && (
                    <div
                        onClick={() => handleDeleteData()}
                        className="services-create-modal__buttons__item services-create-modal__buttons__delete">
                        Удалить поле
                    </div>
                )}
            </div>
        </DialogContainer>
    );
};