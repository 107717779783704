import {
    ArrowExitIcon,
    CalendarIcon,
    ReplacingIcon,
} from "../../../img";
import { useNavigate } from "react-router-dom";
import MainLayoutContent from "../../../layouts/MainLayoutContent";
import { VacationLeftContent } from "../../calendar/vacation/VacationLeftContent";
import { useEffect, useState } from "react";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { isMd } from "../../../utils";
import { $getSubordinates } from "../../../api/requests/user";
import moment from "moment";
import { MedicalLeaveRecord, User, VacationRecord } from "../../../api/requests/user/interface";
import { useDispatch } from "react-redux";
import { setBookingList } from "../../../redux";

export const ProfileColleagues = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();

    const [ dialog, setDialog ] = useState('closed')
    const [ activePerson, setActivePerson ] = useState<MedicalLeaveRecord>();

    const [ vacationList, setVacationList ] = useState<VacationRecord[]>([]);

    const [ ambulanceData, setAmbulanceData ] = useState<MedicalLeaveRecord[]>([]);

    const [ replacingContent, setReplacingContent ] = useState<MedicalLeaveRecord[]>([]);

    const [userAllList, setUserAllList] = useState<any[]>([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleOpenDialog = (
        item: any,
        type: string
    ) => {
        setActivePerson(item)
        setDialog(type)
    }

    const getChangeCalendarData = (item: VacationRecord | MedicalLeaveRecord, idx: number) => {
        return {
            id: idx,
            date_from: item?.start_date,
            date_to: item?.end_date,
            // vacation_info: {
            //     unused_vacation: item?.unused_vacation,
            // },
            isProgress: item.status === "pending" || item.status > 1,
            isStatus: false,
            user: {
                inner_id: item?.staff?.inner_id,
                name: item?.staff?.name,
                photo: item?.staff?.photo,
                staff: {
                    title: item?.staff?.job_title
                },
                structure: item?.staff?.department
            }
        }
    }

    const getChangeUserData = (item: User) => {
        return {
            date_from: item?.vacation?.near_vacation?.start_date,
            date_to: item?.vacation?.near_vacation?.end_date,
            vacation_info: {
                unused_vacation: item?.vacation?.unused_vacation,
            },
            isProgress: 0,
            isStatus: false,
            user: {
                inner_id: item?.inner_id,
                name: item?.name,
                photo: item?.photo,
                staff: {
                    title: item?.job_title
                },
                structure: item?.department
            }
        }
    }

    useEffect(() => {
        $getSubordinates().then(res => {
            if (!res?.data || res.error) return;

            const updateMedList = [...res.data.medical_leaves].map(item => ({
                ...item,
                isSuccess: item.status === "success",
                isProgress: item.status === "pending",
            }))

            const updateVacationList = [...res.data.vacations].map(item => ({
                ...item,
                isSuccess: item.status === 1,
                isProgress: item.status > 1,
            }))

            const filterList = [...updateMedList, ...updateVacationList].map((item, idx) => getChangeCalendarData(item, idx));

            dispatch(setBookingList(filterList));

            setVacationList(res.data.vacations);
            setAmbulanceData(res.data.medical_leaves);
            setReplacingContent(res.data.medical_leaves);

            setUserAllList(res.data.subordinates.map(item => getChangeUserData(item)));
        })
    }, []);

    return (
        <div className='profile-colleagues'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Мои сотрудники</h1>
                    </div>
                ) }

                <div className='profile-colleagues-header__calendar' onClick={() => navigate("/calendar/vacation")}>
                    <CalendarIcon/>

                    <p className='profile-colleagues-header__calendar__title'>Календарь</p>

                    <span className='notification-circle notification-circle__red'>3</span>
                </div>
            </div>

            <MainLayoutContent contentGap={ 40 } leftComponents={
                <div className='vacation__content__left'>
                    <VacationLeftContent usersList={userAllList}/>
                </div>
            }>
                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Отпуска</h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { vacationList.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    <img src={ item.staff?.photo } alt="" className='profile-colleagues-block__image' />

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__placeholder'>Даты отпуска</p>

                                    <div className='profile-colleagues-block__date'>
                                        с {moment(item.start_date).format("DD.MM.yyyy")} по {moment(item.end_date).format("DD.MM.yyyy")}
                                    </div>
                                </div>

                                <div
                                    onClick={ () => handleOpenDialog(item, 'vacation') }
                                    className='profile-colleagues-block__button'
                                >
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title">Работа в больничный</h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { ambulanceData.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-block' key={ idx }>
                                <div className='profile-colleagues-block__left'>
                                    <img src={ item.staff?.photo } alt="" className='profile-colleagues-block__image' />

                                    <div className='profile-colleagues-block__desc'>
                                        <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                        <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__middle'>
                                    <p className='profile-colleagues-block__workDays'>{ item?.start_date } рабочих дня</p>

                                    <div className='profile-colleagues-block__ambulaneDate'>
                                        с {moment(item.start_date).format("DD.MM.yyyy")} по {moment(item.end_date).format("DD.MM.yyyy")}
                                    </div>
                                </div>

                                <div className='profile-colleagues-block__button'
                                     onClick={ () => handleOpenDialog(item, 'vacation') }
                                >
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>

                <div className='layout-right-block'>
                    <div className="layout-right-block__header">
                        <h1 className="layout-right-block__header__title"> Замещающий сотрудник </h1>
                    </div>

                    <div className='profile-colleagues-content'>
                        { replacingContent.map((
                            item,
                            idx
                        ) => (
                            <div className='profile-colleagues-replacing' key={ idx }>
                                <div className='profile-colleagues-replacing__content'>
                                    <div className='profile-colleagues-block__left'>
                                        <img src={ item.staff?.photo } alt="" className='profile-colleagues-block__image' />

                                        <div className='profile-colleagues-block__desc'>
                                            <h1 className='profile-colleagues-block__name'>{ item.staff?.name }</h1>
                                            <p className='profile-colleagues-block__position'>{ item.staff?.job_title }</p>
                                        </div>
                                    </div>

                                    <ReplacingIcon />

                                    { item.approved_person && (
                                        <div className='profile-colleagues-block__left'>
                                            <img src={ item.approved_person?.photo } alt=""
                                                 className='profile-colleagues-block__image' />

                                            <div className='profile-colleagues-block__desc'>
                                                <h1 className='profile-colleagues-block__name'>{ item.approved_person?.name }</h1>
                                                <p className='profile-colleagues-block__position'>{ item.approved_person?.job_title }</p>
                                            </div>
                                        </div>
                                    ) }
                                </div>

                                <div className='profile-colleagues-block__button'
                                     onClick={ () => handleOpenDialog(item, 'replacing') }>
                                    Согласовать
                                </div>
                            </div>
                        )) }
                    </div>
                </div>


                <DialogContainer
                    isOpen={ dialog === 'replacing' || dialog === 'vacation' }
                    closeModal={ () => setDialog('closed') }
                >

                    { activePerson &&
                        (
                            <>
                                { dialog === 'replacing' ? (
                                    <>
                                        <div className='layout-vacation-modal__header'>
                                            { dialog === 'replacing' ? (
                                                <h1 className='layout-vacation-modal__title'>Согласование
                                                    замещающего</h1>
                                            ) : null
                                            }
                                        </div>

                                        <div className='profile-colleagues-modal__content'>
                                            <div className='profile-colleagues-modal__content__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Замещаемый</p>

                                                <div className='profile-colleagues-block__left'>
                                                    <img src={ activePerson.staff?.photo } alt=""
                                                         className='profile-colleagues-block__image' />

                                                    <div className='profile-colleagues-block__desc'>
                                                        <h1 className='profile-colleagues-block__name'>{ activePerson.staff?.name }</h1>
                                                        <p className='profile-colleagues-block__position'>{ activePerson.staff?.job_title }</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='profile-colleagues-modal__content__block__icon'>
                                                <ReplacingIcon />
                                            </div>

                                            { activePerson.approved_person && (
                                                <div className='profile-colleagues-modal__content__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Замещающий</p>

                                                    <div className='profile-colleagues-block__left'>
                                                        <img src={ activePerson.approved_person?.photo } alt=""
                                                             className='profile-colleagues-block__image' />

                                                        <div className='profile-colleagues-block__desc'>
                                                            <h1 className='profile-colleagues-block__name'>{ activePerson.approved_person?.name }</h1>
                                                            <p className='profile-colleagues-block__position'>{ activePerson.approved_person?.job_title }</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) }
                                        </div>

                                        <div className='profile-colleagues-modal__information'>
                                            <h1>Информация</h1>

                                            <div className='profile-colleagues-modal__information__dates'>
                                                <p className='profile-colleagues-modal__placeholder'>Даты</p>

                                                <h1>с {moment(activePerson.start_date).format("DD.MM.yyyy")} по {moment(activePerson.end_date).format("DD.MM.yyyy")}</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__information__dates'>
                                                <p className='profile-colleagues-modal__placeholder'>Рабочих дней</p>

                                                <h1>0 дня</h1>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='employees-modal__top'>
                                            <img src={ activePerson?.staff?.photo } alt="" />

                                            <h1>
                                                { activePerson?.staff?.name }
                                            </h1>
                                        </div>

                                        <div className='profile-colleagues-modal__vacationContent'>
                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Департамент</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ activePerson?.staff.job_title }</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__block'>
                                                <p className='profile-colleagues-modal__placeholder'>Должность</p>

                                                <h1 className='profile-colleagues-modal__vacationContent__block__title'>{ activePerson?.staff.job_title }</h1>
                                            </div>

                                            <div className='profile-colleagues-modal__vacationContent__information'>
                                                <h1 className='profile-colleagues-modal__vacationContent__information__title'>
                                                    Согласование работы в больничный
                                                </h1>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Даты по больничному листу</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        с {moment(activePerson.start_date).format("DD.MM.yyyy")} по {moment(activePerson.end_date).format("DD.MM.yyyy")}
                                                    </h1>
                                                </div>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Даты по больничному листу</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        {activePerson.start_date} дней
                                                    </h1>
                                                </div>

                                                <div className='profile-colleagues-modal__vacationContent__information__block'>
                                                    <p className='profile-colleagues-modal__placeholder'>Из них рабочих дней</p>

                                                    <h1 className='profile-colleagues-modal__vacationContent__information__block__title'>
                                                        {activePerson.start_date} дня
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) }

                                <div className='profile-colleagues-modal__buttons'>
                                    <button className='profile-colleagues-modal__buttons__accept'>Согласовать</button>
                                    <button className='profile-colleagues-modal__buttons__reject'>Отклонить</button>
                                </div>
                            </>
                        )
                    }

                </DialogContainer>
            </MainLayoutContent>
        </div>
    )
}