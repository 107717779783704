import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $getMapSettingBookingData = (
    id: number,
    params?: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/booking/workplaces/${id}/all`, {
        request: {
            params
        },
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapSettingBookingCreate = (
    id: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`api/v1/booking/workplaces/${id}/mass`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapSettingWorkplaceCreate = (
    buildingId: string,
    floorId: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`backend/v1/booking/building/${buildingId}/floor/${floorId}/workplace`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapSettingWorkplaceEdit = (
    buildingId: string,
    floorId: string,
    workplaceId: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.put(`backend/v1/booking/building/${buildingId}/floor/${floorId}/workplace/${workplaceId || 0}`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapSettingWorkplaceDelete = (
    buildingId: string,
    floorId: string,
    id: string,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete(`backend/v1/booking/building/${buildingId}/floor/${floorId}/workplace/${id || 0}`, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $mapSettingUploadFile = (
    buildingId: string,
    floorId: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`backend/v1/booking/building/${buildingId}/floor/${floorId}/map`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
            formData: true
        }
    });

