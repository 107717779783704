import { IApiHandlerParams } from "../../interface"
import api from '../../../api'

export const $createPoll = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/interviews/createinterview", data, {
    handler
});

export const $editPoll = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/interviews/editinterview", data, {
    handler
});

export const $deletePoll = (
    id: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/interviews/delelteinterview", id, {
    handler
});

export const $downloadPollExcel = (
    id: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/interviews/statexcel", id, {
    handler
});



export const $getPollsList = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get( `api/interviews/listinterviews`, {
        handler,
    })

export const $viewPollsItem = (
    id: number,
    handler?: IApiHandlerParams
): Promise<any> => api
.post( `api/interviews/viewinterview`, {id}, {
    handler,
})

export const $answerPollsItem = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post( `api/interviews/answer`, data, {
    handler,
})

