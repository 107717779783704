import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IRequestUsers, IResponseUsers, ResponseData } from "./interface";

let LocalPersonalPage:Promise<any>;
let LocalsTimeout = 5 * 1000;
let LocalsTs:number;

export const $getUsers = (
    body: IRequestUsers,
    handler?: IApiHandlerParams
): Promise<IResponseUsers> =>
    api.post("api/workspaces/users", body, {
        handler
    });

export const $searchUsers = (
    query: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(query ? `api/v1/user/search?query=${query}` :'api/v1/user/search', {
        handler
    });


export const $getUser = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/user/user_page/${id}`, {
        handler
    });

export const $getPersonalPage = (
    data?: any,
    handler?: IApiHandlerParams
): Promise<any> => {
    let now = new Date().getTime();
    let check = LocalsTs ? LocalsTs + LocalsTimeout : now;
    if(LocalPersonalPage && now < check){
        return LocalPersonalPage;
    }
    LocalsTs = now;
    LocalPersonalPage = api.get("api/v1/user/user_page", {
        request: {
            params: data,
        },
        handler
    });
    return LocalPersonalPage;
}

export const $getBadges = (
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/badges/list`, {
        handler
    });
export const $sendBadge = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post('api/badges/send', data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler,
    })

export const $getNotification = (
    handler?: IApiHandlerParams
): Promise<any> =>
    api.get(`api/v1/user/notifications`, {
        handler
    });

export const $getSubordinates = (
    handler?: IApiHandlerParams
): Promise<ResponseData> =>
    api.get(`api/v1/user/subordinates/list`, {
        mock: require('./mocks/subordinates.json'),
        handler: {
            newApi: true,
            ...handler,
        }
    });

