import React from 'react';
import { checkFileExt } from "../../../utils/file/checkFileExt";

export const FileUploadInput = ({ inputRef, onSuccess, onError, accept = 'images', multiple = false }) => {
  const acceptedExt = {
    allFiles: '*',
    videos: '.avi, .mp4, .mp3, .wav',
    zip: '.zip, .rar',
    images: '.png, .jpg, .jpeg, .gif, .bmp, .svg',
    docs: '.doc, .docx, .pdf, .xls, .xlsx, .ppt, .pptx, .txt, .csv',
    get allDocsImages() {
      return `${this.docs}, ${this.images}, ${this.zip}, ${this.videos}`;
    },
  };

  const arrOfExt = acceptedExt[accept].replace(/\./g, '').split(', ');

  return (
    <input
      type="file"
      multiple={multiple}
      hidden
      ref={inputRef}
      onChange={(e) =>
        checkFileExt(e, {
          onSuccess,
          onError,
          extList: arrOfExt,
        })
      }
      onClick={(e) => {
        e.target.value = null;
      }}
      accept={acceptedExt[accept]}
    />
  );
};
