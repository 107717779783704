import {
    AnalyticsChartBlock,
    AnalyticsDates, AnalyticsExcelModal,
    AnalyticsHorizonNavigation, AnalyticsListBlock,
    getStatisticsChartItems,
    getStatisticsListItems
} from "../../components/analytics";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { IconCalendar, MessengerSendIcon } from "../../img";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import { ThreePeopleBorderIcon } from "../../img/icons/employees/ThreePeopleBorderIcon";
import { DownloadIcon } from "../../img/icons/DownloadIcon";
import { ProfilePersonalIcon } from "../../img/icons/profile/ProfilePersonalIcon";
import { useLocation, useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

export const AnalyticsBadgesPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const excelOpen = searchParams.get("excelOpen")

    const currentDate = dayjs().utcOffset(3).hour(3).minute(0).second(0);
    const date = currentDate.startOf("isoWeek").toDate();
    const end_date = currentDate.endOf("isoWeek").toDate();

    const [startTime, setStartTime] = useState<any>(parseInt((date.getTime() / 1000).toString()));
    const [endTime, setEndTime] = useState<any>(parseInt((end_date.getTime() / 1000).toString()));
    const [filterType, setFilterType] = useState("week");

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [sendPointsList, setSendPointsList] = useState(null);
    const [sendCountList, setSendCountList] = useState(null);
    const [sendersList, setSendersList] = useState(null);
    const [recipientsList, setRecipientsList] = useState(null);
    const [sendBadgesList, setSendBadgesList] = useState(null);
    const [senderBadgesList, setSenderBadgesList] = useState(null);
    const [senderPointsList, setSenderPointsList] = useState(null);

    const [topSenderBadgesList, setTopSenderBadgesList] = useState(null);
    const [topRecipientBadgesList, setTopRecipientBadgesList] = useState(null);
    const [topSenderPointList, setTopSenderPointList] = useState(null);
    const [topRecipientPointList, setTopRecipientPointList] = useState(null);

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [excelData, setExcelData] = useState([
        {
            value: 1,
            label: 'Выгрузка отчета по всем транзакциям',
            route: '/badges/export/transactions'
        },
        {
            value: 2,
            label: 'Выгрузка отчета начислений по пользователям',
            route: '/badges/export/users'
        },
    ])

    const handleDeleteLoadingData = (list: any, setList: any) => {
        const updatedList = {
            data: [],
            total_count: "...",
            text: list?.text,
            title: list?.title
        };

        setList(updatedList);
    };

    useEffect(() => {
        setIsLoading(true);
        handleDeleteLoadingData(sendPointsList, setSendPointsList);
        // handleDeleteLoadingData(sendCountList, setSendCountList)
        handleDeleteLoadingData(sendBadgesList, setSendBadgesList);
        handleDeleteLoadingData(senderBadgesList, setSenderBadgesList);
        handleDeleteLoadingData(sendPointsList, setSenderPointsList);


        getStatisticsChartItems(
            "/badges/send-points",
            "Сумма отправленных баллов",
            "#00A7B5",
            "Сумма отправленных баллов",
            setSendPointsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ProfileBalanceIcon color="#BEA000" />
        );

        getStatisticsChartItems(
            "/badges/send-badges",
            "Количество отправленных бейджей",
            "#00A7B5",
            "Количество отправленных бейджей",
            setSendBadgesList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <MessengerSendIcon />
        );

        getStatisticsChartItems(
            "/badges/senders-badges",
            "Количество людей, которые отправили бейджи",
            "#00A7B5",
            "Количество людей, которые отправили бейджи",
            setSenderBadgesList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ThreePeopleBorderIcon />
        );

        getStatisticsChartItems(
            "/badges/senders-points",
            "Количество людей, которые отправили баллы",
            "#00A7B5",
            "Количество людей, которые отправили баллы",
            setSenderPointsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ProfileBalanceIcon color="#BEA000" />
        );

        getStatisticsListItems(
            "/badges/top-senders-badges",
            "ТОП Отправителей бейджов",
            <MessengerSendIcon />,
            setTopSenderBadgesList,
            startTime,
            endTime,
            filterType
        );

        getStatisticsListItems(
            "/badges/top-recipients-badges",
            "ТОП Получателей бейджов",
            <ProfilePersonalIcon />,
            setTopRecipientBadgesList,
            startTime,
            endTime,
            filterType
        );

        getStatisticsListItems(
            "/badges/top-senders-points",
            "ТОП Отправителей баллов",
            <ProfileBalanceIcon />,
            setTopSenderPointList,
            startTime,
            endTime,
            filterType
        );

        getStatisticsListItems(
            "/badges/top-recipients-points",
            "ТОП Получателей баллов",
            <ProfileBalanceIcon />,
            setTopRecipientPointList,
            startTime,
            endTime,
            filterType
        );

    }, [startTime, endTime]);

    return (
        <>
            <AnalyticsHorizonNavigation />

            <div className="container analytics">
                <div className="analytics-header">
                    <div className="analytics-header__left">
                        <h1 className="analytics-header__left__title">Бейджи и баллы</h1>
                        <p className="analytics-header__left__desc">Статистика коммуникации
                            сотрудников и использования бейджей и балов</p>
                    </div>

                    {/*<div className='analytics-header__right' onClick={() => setIsOpenModal(true)}>*/}
                    {/*    <DownloadIcon color={'#00A7B5'}/>*/}
                    {/*    <p style={{color: '#00A7B5'}}>Скачать .xls</p>*/}
                    {/*</div>*/}
                </div>

                <AnalyticsDates
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    filterType={filterType}
                    setFilterType={setFilterType}
                />

                <div className="analytics-content" style={{}}>
                    {sendPointsList && sendBadgesList && senderBadgesList && senderPointsList && (
                        <>
                            {Object.values([sendPointsList, sendBadgesList, senderBadgesList, senderPointsList])?.map((item: any, idx) => (
                                <AnalyticsChartBlock
                                    {...item}
                                    key={idx}
                                    filter_type={filterType}
                                    isLoading={isLoading}
                                    page="partners"
                                />
                            ))}
                        </>
                    )}

                    {topSenderBadgesList && topRecipientBadgesList && topSenderPointList && topRecipientPointList && (
                        <>
                            {Object.values([topSenderBadgesList, topRecipientBadgesList, topSenderPointList, topRecipientPointList]).map((item, idx) => (
                                <AnalyticsListBlock
                                    key={idx}
                                    data={item}
                                    filter_type={filterType}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>

            <AnalyticsExcelModal
                isOpened={excelOpen === 'true'}
                onClose={() => navigate('/analytics/badges')}
                data={excelData}
                start_date={startTime}
                end_date={endTime}
                filter_type={filterType}
            />
        </>
    );
};