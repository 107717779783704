import React, { FC } from "react";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { FileCategoryData, FileCategoryItemData } from "../../../api/requests/files/interface";
import downloadFile from "../../../utils/file/download-file";
import { successNotify } from "../../../utils";

interface IFileSettingsModal {
    item: FileCategoryItemData;
    onReplace: () => void;
    onDelete: () => void;
    onMove: () => void;
    onClose: () => void;
}

export const FileSettingsModal: FC<IFileSettingsModal> = (
    {
        item,
        onReplace,
        onDelete,
        onMove,
        onClose
    }
) => {

    function handleDownload() {
        downloadFile(item.path, item.name)
    }

    function handleShare() {
        navigator.clipboard.writeText(item.path);

        successNotify("Ссылка успешно скопирована").then();
        onClose();
    }

    return (
        <div className="files__modal">

            <div className="files__modal__item" onClick={handleDownload}>Скачать</div>
            <div className="files__modal__item" onClick={onReplace}>Заменить</div>
            <div className="files__modal__item" onClick={() => {
                onMove();
                onClose();
            }}>Переместить</div>
            <div className="files__modal__item" onClick={handleShare}>Поделиться</div>

            <div
                className="files__modal__delete"
                onClick={() => {
                    onDelete();
                    onClose();
                }}>
                Удалить
            </div>
        </div>

    );
};