import React, { useEffect, useState } from "react";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { CloseIcon } from "../../../img";
import { NotificationCard, NotificationCardProps } from "../../notification/notification-card";
import { useNavigate } from "react-router-dom";
import { $getPersonalPage } from "../../../api/requests/user";


interface CompleteModalProps {
    data: any[];
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}

const HeaderNotificationMobilePopup = (
    {
        data,
        isOpen,
        setIsOpen
    }: CompleteModalProps
) => {
    const navigate = useNavigate();

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isShowCloseBtn={false}
            classNameModal={"p-0"}
            widthProps={428}
        >
            <div className="notifications-mobile-popup">
                <div className={"notifications-mobile-popup__header"}>

                    <div className="notifications-mobile-popup__title">Уведомления</div>

                    <div
                        className="notifications-mobile-popup__close"
                        onClick={() => setIsOpen(false)}>

                        <CloseIcon color={"black"} opacity={"1"} />
                    </div>
                </div>

                <div className="notifications-popup-content">
                    {data && data.slice(0, 5).map((notification: NotificationCardProps, idx: number) => (
                        <NotificationCard data={notification} key={idx} />
                    ))}

                    {data && data.length > 1 && (
                        <div className="notifications-popup__button"
                            onClick={() => navigate("/notifications")}>
                            Открыть все
                        </div>
                    )}
                </div>
            </div>
        </DialogContainer>
    );
};

export default HeaderNotificationMobilePopup;