import {
    AnalyticsChartBlock,
    AnalyticsDates, AnalyticsExcelModal,
    AnalyticsHorizonNavigation, AnalyticsListBlock,
    getStatisticsChartItems, getStatisticsListItems
} from "../../components/analytics";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { AcceptedIcon, IconCalendar, RejectedIcon } from "../../img";
import { ThreePeopleBorderIcon } from "../../img/icons/employees/ThreePeopleBorderIcon";
import { useLocation, useNavigate } from "react-router-dom";

export const AnalyticsSportPage = () => {
    const navigate = useNavigate();
    const currentDate = dayjs().utcOffset(3).hour(3).minute(0).second(0);
    const date = currentDate.startOf("isoWeek").toDate();
    const end_date = currentDate.endOf("isoWeek").toDate();

    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const excelOpen = searchParams.get("excelOpen")

    const [startTime, setStartTime] = useState<any>(parseInt((date.getTime() / 1000).toString()));
    const [endTime, setEndTime] = useState<any>(parseInt((end_date.getTime() / 1000).toString()));
    const [filterType, setFilterType] = useState("week");

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [registeredList, setRegisteredList] = useState([]);
    const [completedList, setCompletedList] = useState([]);
    const [canceledList, setCanceledList] = useState([]);

    const [excelData, setExcelData] = useState([
        {
            value: 1,
            label: 'Выгрузка отчета',
            route: '/trainings/export'
        },
    ])

    const handleDeleteLoadingData = (list: any, setList: any) => {
        const updatedList = {
            data: [],
            total_count: "...",
            text: list?.text,
            title: list?.title
        };

        setList(updatedList);
    };

    useEffect(() => {
        setIsLoading(true);
        handleDeleteLoadingData(registeredList, setRegisteredList);
        handleDeleteLoadingData(completedList, setCompletedList);
        handleDeleteLoadingData(canceledList, setCanceledList);

        getStatisticsChartItems(
            "/trainings/registered",
            "Записалось на тренировки",
            "#00A7B5",
            "Записалось на тренировки",
            setRegisteredList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ThreePeopleBorderIcon />
        );

        getStatisticsChartItems(
            "/trainings/completed",
            "Проведенные тренировки",
            "#00A7B5",
            "Проведенные тренировки",
            setCompletedList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <AcceptedIcon />
        );

        getStatisticsChartItems(
            "/trainings/canceled",
            "Отмененные записи",
            "#00A7B5",
            "Отмененные записи",
            setCanceledList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <RejectedIcon />
        );
    }, [startTime, endTime]);

    return (
        <>
            <AnalyticsHorizonNavigation />

            <div className="container analytics">
                <div className="analytics-header">
                    <div className="analytics-header__left">
                        <h1 className="analytics-header__left__title">Спорт</h1>
                        <p className="analytics-header__left__desc">Статистика тренировок</p>
                    </div>

                </div>

                <AnalyticsDates
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    filterType={filterType}
                    setFilterType={setFilterType}
                />

                <div className="analytics-content" style={{}}>
                    {completedList && registeredList && canceledList && (
                        <>
                            {Object.values([registeredList, completedList, canceledList])?.map((item: any, idx) => (
                                <AnalyticsChartBlock
                                    {...item}
                                    key={idx}
                                    isLoading={isLoading}
                                    filter_type={filterType}
                                    page="partners"
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>

            <AnalyticsExcelModal
                isOpened={excelOpen === 'true'}
                onClose={() => navigate('/analytics/sport')}
                data={excelData}
                start_date={startTime}
                end_date={endTime}
                filter_type={filterType}
            />
        </>
    );
};