import { ArrowExitIcon, ArrowRightIcon, HeartIcon, StarIcon } from "../../img";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { $getEmployees } from "../../api/requests/employees";
import { $getFavoritesNews, $getNewsList } from "../../api/requests/news";
import { PollsCard } from "../../components/polls";
import { CompetitionsCard } from "../../components/competitions";
import { NewsCard } from "../../components/news";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import { $getUserFavoritesPreview } from "../../api/requests/favorites";
import { FavoritesNewsCard } from "../../components/favorites";
import { BlankAva } from "../../components/ui/avatars/BlankAva";
import { BlankImage } from "../../components/ui/blank-image/BlankImage";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const FavoritesPage = () => {
    const profileData = useSelector(getPersonData);

    const navigate = useNavigate();
    const isMobile = isMd();

    const [usersData, setUsersData] = useState([]);
    const [employeesData, setEmployeesData] = useState<any>([]);
    const [newsData, setNewsData] = useState([]);
    const [ideasData, setIdeasData] = useState([]);
    const [birthdaysData, setBirthdaysData] = useState([]);
    const [interviewsData, setInterviewsData] = useState([]);
    const [contestsData, setContestsData] = useState([]);
    const [blogsData, setBlogsData] = useState([]);
    const [mediaGalleryData, setMediaGalleryData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number) => {
        const updatedList: any = employeesData.map((item: any) => {
            if (item.id === id) {
                return {
                    ...item,
                    isLiked: !item.isLiked
                };
            } else {
                return item;
            }
        });

        setEmployeesData(updatedList);
    };


    const getList = (list: any, type: string) => {
        return list.find((item: any) => item.type === type);

    };

    useEffect(() => {
        $getUserFavoritesPreview({ newApi: true }).then((res) => {
            if (!res.data) return;

            setEmployeesData(getList(res.data, "staff").items);
            setUsersData(getList(res.data, "user").items);
            setNewsData(getList(res.data, "news").items);
            setIdeasData(getList(res.data, "idea").items);
            setBirthdaysData(getList(res.data, "birthday").items);
            setInterviewsData(getList(res.data, "interview").items);
            setContestsData(getList(res.data, "contest").items);
            setBlogsData(getList(res.data, "blog").items);
            setMediaGalleryData(getList(res.data, "mediaGallery").items);
        });

    }, [isLoading]);


    return (
        <div className="favorites">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Избранное</h1>
                    </div>
                )}
            </div>

            <div className="favorites-content">
                {usersData && usersData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Пользователи </h1>

                            <div onClick={() => navigate("/favorites/users")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites-employees employees-content">
                            {usersData.map((
                                item: any,
                                idx: number
                            ) => (
                                <div className="employees-block" key={idx}>
                                    <div className="employees-block__top">
                                        <BlankImage image={item.cover}
                                                    classname="employees-block__image" />

                                        <div
                                            onClick={() => {
                                                handleLike(item.id);
                                            }}
                                            className={`employees-block__likeIcon active`}
                                        >
                                            <StarIcon opacity={1} />
                                        </div>
                                    </div>

                                    <div className="employees-block__content" onClick={() => {
                                        // setDialog(true)
                                        // setActivePerson({...item})
                                    }}>
                                        <h1 className="employees-block__title">{item?.title}</h1>

                                        {item.job && (
                                            <>
                                                <p className='employees-block__text'>{ item?.job?.department }</p>

                                                <p className='employees-block__position'>{ item?.job?.title }</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {employeesData && employeesData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Сотрудники </h1>

                            <div onClick={() => navigate("/favorites/employees")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites-employees employees-content">
                            {employeesData.map((
                                item: any,
                                idx: number
                            ) => (
                                <div className="employees-block" key={idx}>
                                    <div className="employees-block__top">
                                        <BlankImage image={item.cover}
                                                    classname="employees-block__image" />

                                        <div
                                            onClick={() => {
                                                handleLike(item.id);
                                            }}
                                            className={`employees-block__likeIcon active`}
                                        >
                                            <StarIcon opacity={1} />
                                        </div>
                                    </div>

                                    <div className="employees-block__content" onClick={() => {
                                        // setDialog(true)
                                        // setActivePerson({...item})
                                    }}>
                                        <h1 className="employees-block__title">{item?.title}</h1>

                                        {item.job && (
                                            <>
                                                <p className='employees-block__text'>{ item?.job?.department }</p>

                                                <p className='employees-block__position'>{ item?.job?.title }</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {newsData && newsData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Новости </h1>

                            <div onClick={() => navigate("/favorites/news")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {newsData.slice(0, 4).map((
                                item: any,
                                idx
                            ) => (
                                <div onClick={() => navigate(`/news/show/${item.id}`)} key={idx}>
                                    <FavoritesNewsCard
                                        data={item}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"news"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {ideasData && ideasData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Идеи </h1>

                            <div onClick={() => navigate("/favorites/ideas")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {ideasData.slice(0, 4).map((
                                item: any,
                                idx
                            ) => (
                                <div onClick={() => navigate(`/ideas/show/${item.id}`)} key={idx}>
                                    <FavoritesNewsCard
                                        data={item}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"idea"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {birthdaysData && birthdaysData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Дни рождения </h1>

                            <div onClick={() => navigate("/favorites/birthdays")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {birthdaysData.slice(0, 4).map((
                                item: any,
                                idx
                            ) => (
                                <div className="employees-block" key={idx}>
                                    <div className="employees-block__top">
                                        <BlankImage image={item.cover}
                                                    classname="employees-block__image" />

                                        <div
                                            onClick={() => {
                                                handleLike(item.id);
                                            }}
                                            className={`employees-block__likeIcon active`}
                                        >
                                            <StarIcon opacity={1} />
                                        </div>
                                    </div>

                                    <div className="employees-block__content" onClick={() => {
                                        // setDialog(true)
                                        // setActivePerson({...item})
                                    }}>
                                        <h1 className="employees-block__title">{item?.title}</h1>

                                        {item.job && (
                                            <>
                                                <p className='employees-block__text'>{ item?.job?.department }</p>

                                                <p className='employees-block__position'>{ item?.job?.title }</p>
                                            </>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {interviewsData && interviewsData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title">Интервью </h1>

                            <div onClick={() => navigate("/favorites/polls")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {interviewsData.slice(0, 4).map((
                                item,
                                idx
                            ) => (
                                <div>
                                    <FavoritesNewsCard
                                        data={item}
                                        key={idx}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"interview"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {contestsData && contestsData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Конкурсы </h1>

                            <div onClick={() => navigate("/favorites/competitions")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {contestsData.slice(0, 4).map((item: any, idx) => (
                                <div onClick={() => navigate(`/contests/show/${item.id}`)}
                                     key={idx}>
                                    <FavoritesNewsCard
                                        data={item}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"contest"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {blogsData && blogsData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Блоги </h1>

                            <div onClick={() => navigate("/favorites/blogs")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {blogsData.slice(0, 4).map((item: any, idx) => (
                                <div key={idx} onClick={() => navigate(`/blogs/show/${item.id}`)}>
                                    <FavoritesNewsCard
                                        data={item}
                                        key={idx}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"blog"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                {mediaGalleryData && mediaGalleryData.length > 0 && (
                    <div className="layout-right-block">
                        <div className="layout-right-block__header">
                            <h1 className="layout-right-block__header__title"> Медиа-галереи </h1>

                            <div onClick={() => navigate("/favorites/media-galleries")}
                                 className="layout-right-block__header__link">
                                <h4>Открыть все</h4>
                                <ArrowRightIcon color={"#000"} />
                            </div>
                        </div>

                        <div className="favorites__news">
                            {mediaGalleryData.slice(0, 4).map((item: any, idx) => (
                                <div onClick={() => navigate(`/media-files/show/${item.id}`)}
                                     key={idx}>
                                    <FavoritesNewsCard
                                        data={item}
                                        isLoading={isLoading}
                                        setIsLoading={setIsLoading}
                                        type={"mediaGallery"}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};