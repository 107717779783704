import { FC } from "react";
import { IBaseIconProps } from "../types";

export const AnalyticsContestsIcon: FC<IBaseIconProps> = (
    {
        size = 28,
        color = '#00A7B5'
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 29 28" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.666 14V23.4C22.666 23.4788 22.6505 23.5568 22.6203 23.6296C22.5902 23.7024 22.546 23.7685 22.4903 23.8243C22.4346 23.88 22.3684 23.9242 22.2956 23.9543C22.2228 23.9845 22.1448 24 22.066 24H7.26602C7.18722 24 7.1092 23.9845 7.03641 23.9543C6.96361 23.9242 6.89747 23.88 6.84175 23.8243C6.78604 23.7685 6.74184 23.7024 6.71169 23.6296C6.68154 23.5568 6.66602 23.4788 6.66602 23.4V14M14.666 24V9M14.666 9H10.166C9.50297 9 8.86709 8.73661 8.39825 8.26777C7.92941 7.79893 7.66602 7.16304 7.66602 6.5C7.66602 5.83696 7.92941 5.20107 8.39825 4.73223C8.86709 4.26339 9.50297 4 10.166 4C13.666 4 14.666 9 14.666 9ZM14.666 9H19.166C19.8291 9 20.4649 8.73661 20.9338 8.26777C21.4026 7.79893 21.666 7.16304 21.666 6.5C21.666 5.83696 21.4026 5.20107 20.9338 4.73223C20.4649 4.26339 19.8291 4 19.166 4C15.666 4 14.666 9 14.666 9ZM24.066 9H5.26602C5.10689 9 4.95427 9.06321 4.84175 9.17574C4.72923 9.28826 4.66602 9.44087 4.66602 9.6V13.4C4.66602 13.5591 4.72923 13.7117 4.84175 13.8243C4.95427 13.9368 5.10689 14 5.26602 14H24.066C24.2251 14 24.3778 13.9368 24.4903 13.8243C24.6028 13.7117 24.666 13.5591 24.666 13.4V9.6C24.666 9.44087 24.6028 9.28826 24.4903 9.17574C24.3778 9.06321 24.2251 9 24.066 9Z"
                stroke={color} strokeWidth="2" stroke-linecap="round" strokeLinejoin="round" />
        </svg>
    );
};