import {AcceptedIcon, ArrowExitIcon, RejectedIcon, ReturnIcon} from "../../../img";
import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {isMd} from "../../../utils";
import {Controller, useForm} from "react-hook-form";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import Button from "../../../components/ui/form/Buttons/Button";
import {DownloadIcon} from "../../../img/icons/DownloadIcon";
import FileClipField from "../../../components/ui/form/FileField/FileClipField";
import {UploadIcon} from "../../../img/icons/files/UploadIcon";
import {$getServiceApplications, $uploadServiceApplicationFile} from "../../../api/requests/services";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import {useSelector} from "react-redux";
import {getPersonData} from "../../../redux";
import {BlankAva} from "../../../components/ui/avatars/BlankAva";
import {
    $createServiceVacation,
    $getServiceVacation,
    $getServiceVacationItem
} from "../../../api/requests/services/vacation";
import TextField from "../../../components/ui/form/Fields/TextField";
import moment from "moment/moment";
import {find} from "lodash";
import {SearchItem} from "../../../components/ui/form/SearchItem";
import {ServicesAcceptanceList, ServicesApplicationsList} from "../../../components/services";
import {ServicesApplicationsHistory} from "../../../components/services/services-education/ServicesApplicationsHistory";
import {
    ServicesFormingApplicationModal,
    ServicesImprovementModal
} from "../../../components/services/services-education/modals";
import {
    ServicesAddingAgreerModal
} from "../../../components/services/services-education/modals/ServicesAddingAgreerModal";
import {IServicesApplications} from "../../../api/requests/services/interface";
import {PlusOneIcon} from "../../../img/icons/PlusOneIcon";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesVacationPage = () => {
    const personalData = useSelector(getPersonData);
    const isAcceptor = personalData?.new_vacations?.can_change_status;

    const navigate = useNavigate();
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [isOpenDialog, setIsOpenDialog] = useState(0);

    const [list, setList] = useState([])
    const [filteredList, setFilteredList] = useState(list)

    const [statusesData, setStatusesData] = useState([
        {
            label: 'Отклонен',
            value: -1
        },
        {
            label: 'Создан',
            value: 0
        },
        {
            label: 'Успешно',
            value: 1
        }
    ])

    const [activeType, setActiveType] = useState(0);
    const [types, setTypes] = useState<any[]>([]);

    const columns = [
        {key: "id", title: "ID заявки", width: 7.5, class: "id"},
        {key: "created_at", title: "Дата заявки", width: 15},
        {key: "date_from", title: "Дата начала", width: 15},
        {key: "date_to", title: "Дата завершения", width: 15},
        {key: "reason", title: "Основание", width: 15},
        {
            key: "user", title: "Отправитель", width: 17.5, render: (value: any) => (
                <div className="services-acceptance__block__status">
                    {value?.photo ? (
                        <img src={value.photo} alt="" className="services-acceptance__block__admin__image"/>
                    ) : (
                        <BlankAva
                            size={35}
                            style={{borderRadius: 8}}
                            colorBg={"#CCD9E0"}
                            colorText={"black"}
                            text={value?.name}
                        />
                    )}

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>
            )
        },
        (activeType == 1 ? {
            key: "file", title: "Заявление", width: 10, render: () => (
                <div className="services-acceptance__block__file">
                    Скачать
                </div>
            )
        } : {width: 0}),
        (activeType == 1 ?
                {
                    key: "actions", title: "", width: 10, render: (_: any, row: any) => (
                        <div className="services-acceptance__block__status">
                            <div className="services-acceptance__block__status__item">
                                <AcceptedIcon/></div>
                            <div className="services-acceptance__block__status__item">
                                <RejectedIcon/></div>
                        </div>
                    )
                }
                :
                {
                    key: "status", title: "Статус", width: 10, render: (value: any, row: any) => {
                        const findStatus = statusesData?.find(item => item?.value == value)

                        return (
                            <div
                                style={{background: (findStatus?.label != 'Рассмотрение' && findStatus?.value == 1) ? '#00A7B5' : ''}}
                                className={`services-applications-create__status ${findStatus?.value == 0 ? 'waiting' : ''}`}
                            >
                                {findStatus?.label || 'Рассмотрение'}
                            </div>
                        )
                    }
                }
        ),

    ];

    const {
        control,
        formState: {isValid},
        setValue,
        watch,
    } = useForm<any>();

    useEffect(() => {
        if (activeType || activeType == 0) {
            $getServiceVacation(+activeType, {newApi: true}).then(res => {
                const updatedList = res?.data?.data?.map((item: any) => {
                    return {
                        ...item,
                        date_from: moment(item?.date_from).format('DD.MM.YYYY'),
                        date_to: moment(item?.date_to).format('DD.MM.YYYY'),
                        created_at: moment(item?.created_at).format('DD.MM.YYYY')
                    }
                })

                setFilteredList(updatedList)
                setList(updatedList)
            })
        }
    }, [activeType]);

    useEffect(() => {
        if (isAcceptor) {
            setTypes([
                {
                    id: 0,
                    key: "applications",
                    title: "Мои заявки"
                },
                // {
                //     id: 1,
                //     key: "acceptance",
                //     title: "Согласование заявок"
                // },
                {
                    id: 2,
                    key: "history",
                    title: "История заявок"
                }
            ])
        } else {
            setTypes([
                {
                    id: 1,
                    key: "applications",
                    title: "Мои заявки"
                },

                {
                    id: 3,
                    key: "history",
                    title: "История заявок"
                }
            ])
        }
    }, [personalData])

    function handleFilterList(date_from: string, date_to: string, created_at: string) {
        const updatedList = list.filter((item: any) => {
                if (date_to|| date_from || created_at) {
                    return (item.date_to === moment(date_to).format('DD.MM.YYYY') ||
                        item.date_from === moment(date_from).format('DD.MM.YYYY') ||
                        item.created_at === moment(created_at).format('DD.MM.YYYY'))
                }
                else {
                    return item
                }
            }
        )

        setFilteredList(updatedList)
    }

    return (
        <div className="services-education">
            <div className="container services-education-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services?page=special-applications")}>
                            <ArrowExitIcon/>
                        </div>

                        <h1>Отпуск</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem onChange={() => ""}/>
                </div>
            </div>

            <div className="services-education-content">
                <div className="container services-education-content__top">
                    {types?.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={idx}
                            className={`services-education-content__top__block ${activeType == item.id ? "active" : ""}`}
                            onClick={() => setActiveType(item.id)}
                        >
                            {item.title}
                        </div>
                    ))}
                </div>

                <div className="services-education-wrapper">
                    <div className="container services-education-wrapper__header">
                        <div className="services-education-wrapper__buttons">
                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="user"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <div className='services-education-wrapper__search'>
                                            <p className='services-education-wrapper__search__label'>Отправитель</p>

                                            <SearchItem
                                                selectItem={''}
                                                setValueProps={() => ''}
                                                onChange={() => {
                                                }}
                                            />
                                        </div>
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="created_at"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={(e: any) => {
                                                setValue('created_at', e)
                                                handleFilterList(watch()?.date_to, watch()?.date_from, e)
                                            }}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_from"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Не выбрано"}
                                            onChange={(e: any) => {
                                                setValue('date_from', e)
                                                handleFilterList(e, watch()?.date_to, watch()?.created_at)
                                            }}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div className="services-education-wrapper__buttons__block">
                                <Controller
                                    name="date_to"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата завершения"
                                            placeholder={"Не выбрано"}
                                            onChange={(e: any) => {
                                                setValue('date_to', e)
                                                handleFilterList(watch().date_from, e, watch()?.created_at)
                                            }}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        {activeType == 0 && (
                            <div className='services-education-wrapper__header__button'
                                 onClick={() => navigate('/services/vacation/create')}>
                                Подать заявку
                            </div>
                        )}
                    </div>

                    <ServicesApplicationsList
                        columns={columns}
                        list={filteredList}
                    />

                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal}
                                                 onClose={() => setIsOpenModal(false)}/>
            )}

            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          onClose={() => setIsOpenDialog(0)}/>
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)}/>
            )}
        </div>
    )
}