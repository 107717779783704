import { useLocation } from "react-router-dom";

export const SliderFlameIcon = (
    {
        color = "currentColor",
        opacity = 0.5
    }
) => {
    const location = useLocation()
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <g opacity={(location.pathname !== '/analytics/sport' ) ? 0.6 : 1}>
                <path
                    d="M12.4266 21.6C8.45657 21.6 5.22656 18.5579 5.22656 14.8187C5.22656 9.60002 12.4267 2.40002 12.4267 2.40002C12.4267 2.40002 19.6266 9.60002 19.6266 14.8187C19.6266 18.558 16.3967 21.6 12.4266 21.6ZM12.4266 21.6C10.4416 21.6 8.82656 20.079 8.82656 18.2094C8.82656 15.6 12.4266 12 12.4266 12C12.4266 12 16.0266 15.6 16.0266 18.2094C16.0266 20.079 14.4116 21.6 12.4266 21.6Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.4266 21.6C8.45657 21.6 5.22656 18.5579 5.22656 14.8187C5.22656 9.60002 12.4267 2.40002 12.4267 2.40002C12.4267 2.40002 19.6266 9.60002 19.6266 14.8187C19.6266 18.558 16.3967 21.6 12.4266 21.6ZM12.4266 21.6C10.4416 21.6 8.82656 20.079 8.82656 18.2094C8.82656 15.6 12.4266 12 12.4266 12C12.4266 12 16.0266 15.6 16.0266 18.2094C16.0266 20.079 14.4116 21.6 12.4266 21.6Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinejoin="round"
                />
            </g>
        </svg>

    )
}