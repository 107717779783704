import { useLocation, useNavigate } from "react-router-dom";
import { isMd } from "../../../utils";
import React, { useEffect, useState } from "react";
import {
    $getAllMediaFiles
} from "../../../api/requests/media-files";
import { ArrowExitIcon } from "../../../img";
import { MediaFilesCard } from "../../../components/media-files/card";

export const MediaFilesDraftsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const [mediaFiles, setMediaFiles] = useState<any[]>([]);

    useEffect(() => {
        init();
    }, []);

    function init() {

        getMediaFiles();
    }

    function getMediaFiles() {
        $getAllMediaFiles({ newApi: true }).then(res => {
            if (!res) return;

            setMediaFiles(res.data.data.filter((item: any) => item.status == 0));
        });
    }

    return (
        <div className="media-files">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Черновик</h1>
                    </div>
                )}
            </div>

            <div className="container media-files-content">
                {/*<div className="news-content__header">*/}
                {/*    <Dropdown*/}
                {/*        value={categorySelect}*/}
                {/*        className="workspace-select"*/}
                {/*        placeholder={"Категория"}*/}
                {/*        options={categoryList}*/}
                {/*        onChange={handleCategory}*/}
                {/*        labelBottom={currentRole === 1 ? (*/}
                {/*            <div className={"dropdown__list__setting"}>*/}
                {/*                <Button*/}
                {/*                    leftIcon={<SettingIcon color={"currentColor"} />}*/}
                {/*                    text={"Настройки"}*/}
                {/*                    className={"btn btn-primary-revert w-100"}*/}
                {/*                    onClick={handleSetting}*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*        ) : null}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className="media-files-content__items">
                    {mediaFiles.map((item, idx) => (
                        <MediaFilesCard data={item} key={idx} />
                    ))}
                </div>

                {mediaFiles.length == 0 && (
                    <div
                        className={"d-flex align-items-center justify-content-center p-5"}
                    >
                        Пусто
                    </div>
                )}
            </div>

        </div>
    );
};