import React, { FC } from "react";
import { IBaseIconProps } from "../types";

const ComputerIcon: FC<IBaseIconProps> = (
    {
        size = 40,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 40 40" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect
                y="0.5"
                width="40"
                height="40"
                rx="20"
                fill="#00A7B5"
                fillOpacity="0.1"
            />

            <path
                d="M20.0004 25.3V27.7M16.4004 28.9H23.6004M12.8004 25.3H27.2004C28.5259 25.3 29.6004 24.2255 29.6004 22.9V14.5C29.6004 13.1745 28.5259 12.1 27.2004 12.1H12.8004C11.4749 12.1 10.4004 13.1745 10.4004 14.5V22.9C10.4004 24.2255 11.4749 25.3 12.8004 25.3Z"
                stroke={color} strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ComputerIcon
