import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    getPersonData,
} from "../../redux";

import { $mainPageNewData, $workSpaceEdit } from "../../api/requests/main";
import { successNotify } from "../../utils";

import TextField from "../../components/ui/form/Fields/TextField";
import Button from "../../components/ui/form/Buttons/Button";
import { $editSportSettings, $getSportSettings } from "../../api/requests/sport";
import { CoinCompetitionIcon } from "../../img";

interface FormData {
    training_points: string;
    penalty_points: string;
}

const WorkspaceSettingSport = () => {
    const dispatch = useDispatch();

    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const [editData, setEditData] = useState<any>(null);

    const {
        handleSubmit,
        control
    } = useForm<FormData>(
        {
            values: {
                training_points: editData?.training_points || 0,
                penalty_points: editData?.penalty_points || 0,
            }
        }
    );

    useEffect(() => {
        if (personalData && mainData) init();
    }, [personalData, mainData]);

    function init() {
        // setEditData({ ...personalData, ...mainData });

        $getSportSettings({ newApi: true }).then(res => {
            if (!res || res.error || res.response) return;

            setEditData(res.data);
        })
    }

    function onSubmit(data: FormData) {
        const requestData: any = {
            training_points: +data?.training_points || 0,
            penalty_points: +data?.penalty_points || 0,
        };

        $editSportSettings(
            requestData,
            { newApi: true }
        ).then((res) => {
            if (!res || res.error || res.response) return;

            successNotify("Успешно сохранена").then();
        });
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form
                    className={"workspace-settings-users__accrual w-100"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__badges"}>
                        <h3>Начисление баллов за спорт</h3>

                        <div className={"d-flex gap-4"}>
                            <Controller
                                name="training_points"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Введите число"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        type={"number"}
                                        prepend={<CoinCompetitionIcon/>}
                                        value={value}
                                        className="mb-3"
                                        title="Баллов за участие"
                                        placeholder={"Введите число"}
                                        name="training_points"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="penalty_points"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Введите число"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        type={"number"}
                                        prepend={<CoinCompetitionIcon/>}
                                        value={value}
                                        className="mb-3"
                                        title="Штраф за отсутствие"
                                        placeholder={"Введите число"}
                                        name="penalty_points"
                                        onChange={onChange}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle"}>
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WorkspaceSettingSport;