import React, { FC, useState } from "react";
import { FileCategoryData } from "../../../api/requests/files/interface";
import { successNotify } from "../../../utils";

interface IFileCategorySettingsModal {
    item: FileCategoryData;
    onRename: () => void;
    onClose: () => void;
    onDelete: () => void;
}

export const FileCategorySettingsModal: FC<IFileCategorySettingsModal> = (
    {
        item,
        onRename,
        onDelete,
        onClose
    }
) => {

    function handleShare() {
        const host = window.location.host;
        navigator.clipboard.writeText(`https://${host}/files/share/${item.id}`);

        successNotify("Ссылка успешно скопирована").then();
        onClose();
    }

    return (
        <div className="files__modal">
            <div className="files__modal__item" onClick={onRename}>Переименовать</div>
            <div className="files__modal__item" onClick={handleShare}>Поделиться</div>

            <div
                className="files__modal__delete"
                onClick={() => {
                    onDelete();
                    onClose();
                }}>
                Удалить
            </div>
        </div>

    );
};