import { FC, useEffect, useState } from "react";
import { getDateWithoutFilterType, sortPartnersChartDates } from "./ChartDataFunctions";
import { statisticsDates } from "./dates";
import ReactApexChart from "react-apexcharts";

interface IChartData {
    month?: string,
    year?: string,
    count: string,
    hour?: string,
    day?: string,
    title?: string,
}

interface IStatisticsLineChart {
    color: string,
    data: IChartData[],
    filter_type: string,
    page?: string,
    title?: string
}

export const AnalyticsLineChart: FC<IStatisticsLineChart> = (
    {
        color,
        data,
        filter_type,
        page = "",
        title
    }) => {
    const [date, setDate] = useState([]);
    const [dataSets, setDataSets] = useState<any>([]);

    const isTablet = window.screen.width < 1020;

    const showDates = () => {
        let list = [];
        const interval = parseInt((data?.length / 4).toString());

        if (data?.length >= 10) {
            for (let i = 0; i < data.length; i += interval) {
                list.push(data[i]);
            }
        } else if (data?.length > 5) {
            for (let i = 0; i < data.length; i += 2) {
                list.push(data[i]);
            }
        } else {
            list = data;
        }

        return list;
    };

    useEffect(() => {
        setDataSets(sortPartnersChartDates(data, filter_type, title));
    }, [data, filter_type]);

    useEffect(() => {
        const sortDate = () => {
            let list = dataSets?.map((item: any) => item.day);
            if (filter_type === "month") {
                list = window.screen.width < 1820 ? statisticsDates.tabletDays() : statisticsDates.desktopDays();
            } else if (filter_type === "week") {
                list = isTablet ? statisticsDates.tabletWeek() : statisticsDates.weekDays;
            } else if (!filter_type || title === "Активные пользователи" && (filter_type === "yesterday" || filter_type === "today")) {
                // list =  showDates()?.map((item: any) => `${pad(new Date(item.date)
                //   ?.getDate())}.${pad(new Date(item.date).getMonth() + 1)}.${new Date(item.date).getUTCFullYear().toLocaleString().slice(-2)}`)
                list = showDates()?.map((item: any) => getDateWithoutFilterType(item));
            } else if (title !== "Активные пользователи" && (filter_type === "yesterday" || filter_type === "today")) {
                list = statisticsDates.desktopHours;
            } else if (filter_type === "year") {
                list = window.screen.width < 1820 ? statisticsDates.tabletMonths : statisticsDates.months;
            }

            return list;
        };

        setDate(sortDate);
    }, [data]);

    const options: any = {
        series: [{
            name: "",
            data: dataSets?.map((item: any) => item.count)
        }],
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false
            }
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: 3,
            curve: "smooth"
        },
        title: {
            text: "",
            align: "left"
        },
        grid: {
            row: {
                colors: ["#000000"],
                opacity: 0.5
            }
        },
        xaxis: {
            categories: dataSets?.map((item: any) => item?.day),
            overwriteCategories: date,
            color: "red",
            labels: {
                rotate: 0
            }
        },
        // fill: {
        //   type: ['solid', 'gradient']
        // },
        colors: color ? [color] : ["#00A7B5"],
        markers: {
            size: 0,
            colors: undefined,
            strokeColors: "#fff",
            strokeWidth: 2,
            strokeOpacity: 0.9,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [],
            shape: "circle",
            radius: 50,
            offsetX: 0,
            offsetY: 0,
            onClick: undefined,
            onDblClick: undefined,
            showNullDataPoints: true,
            hover: {
                size: undefined,
                sizeOffset: 3
            }
        }
    };
    return (
        <div className='analytics-chart-line'>
            <ReactApexChart
                type={"line"}
                width={"100%"}
                height={"100%"}
                series={options.series}
                options={options}>

            </ReactApexChart>
        </div>
    );
};