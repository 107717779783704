import { Controller, useForm } from "react-hook-form";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import Button from "../../ui/form/Buttons/Button";
import FileField from "../../ui/form/FileField/FileField";
import TextField from "../../ui/form/Fields/TextField";
import TextArea from "../../ui/form/Fields/TextArea";
import { CoinCompetitionIcon } from "../../../img";
import AddSelectUsers from "../../ui/users/AddSelectUsers";
import ToggleBlock from "../../ui/form/Toggle/ToggleBlock";
import {
    $createBadge,
    $updateBadge
} from "../../../api/requests/workspace-settings/badges";
import { useEffect } from "react";
import { withDebounce } from "../../../functions";

interface FormData {
    image: File;
    card?: File;
    title: string;
    description: string;
    cause: string;
    points: string;
    limit_per_user: string;
    type_of_limit: string;
    sort: string;
    owner: any[];
    admin_only?: number;
    required_cause?: number;
    required_comment?: number;
    balance_convert_state?: number;
    is_hire?: number;
    hire: string;
}

interface WorkplaceCreateBadgesDialogProps {
    isOpen: boolean;
    onClose: () => void;
    setIsOpen: () => void;
    selectedProps: any;
}

const WorkplaceCreateBadgesDialog = (
    {
        isOpen,
        onClose,
        setIsOpen,
        selectedProps
    }: WorkplaceCreateBadgesDialogProps
) => {
    const isEdit = !!selectedProps?.id;

    const typeLimitsList = [
        {
            label: 'Месячный лимит',
            value: '0',
        },
        {
            label: 'Квартальный лимит',
            value: '1',
        },
    ];

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { isValid, errors },
    } = useForm<FormData>(
        {
            values: {
                image: selectedProps?.image,
                // card: File,
                title: selectedProps?.title,
                description: selectedProps?.description,
                cause: selectedProps?.cause || "",
                points: selectedProps?.points,
                limit_per_user: selectedProps?.limit_per_user,
                type_of_limit: selectedProps?.type_of_limit,
                sort: selectedProps?.sort,
                owner: selectedProps?.owner || [],
                admin_only: +selectedProps?.admin_only || 0,
                required_cause: +selectedProps?.required_cause || 0,
                required_comment: +selectedProps?.required_comment || 0,
                balance_convert_state: +selectedProps?.balance_convert_state || 0,
                is_hire: +selectedProps?.is_hire || 0,
                hire: selectedProps?.hire,
            }
        }
    )

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        withDebounce(() => {
            if (isOpen) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "auto";
            }
        })

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    function onSubmit(data: FormData) {
        const requestData = {
            ...data,
            owner: data?.owner[0]?.name
        };

        if (isEdit) return handleEdit({
            ...requestData,
            id: selectedProps.id
        });

        handleCreate(requestData);
    }

    function handleCreate(requestData: any) {
        $createBadge(requestData).then(res => {
            handleEdit({
                ...requestData,
                id: res.id
            })
        })
    }

    function handleEdit(requestData: any) {
        $updateBadge(requestData).then(res => {
            handleClosePopup();
        })
    }

    function handleClosePopup() {
        onClose();
        reset();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen();
                reset();
            }}
            widthProps={427}
            label={`${isEdit ? "Редактирование" : "Добавление"} бейджа`}
            classNameHeader={"pb-10"}
        >
            <div className={"workspace-settings__badges__create"}>
                <form
                    className={"workplace__booking__form d-flex flex-column gap-5"}
                    onSubmit={handleSubmit(onSubmit)}
                    onKeyDown={(e) => checkKeyDown(e)}
                >
                    <div className={"d-flex flex-column gap-5"}>
                        <Controller
                            name="image"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: "Прикрепите аватарку"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <FileField
                                    className={"form-image"}
                                    upload={value}
                                    label="Изображение"
                                    setUpload={onChange}
                                    name="image"
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="title"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите заголовок"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    className=""
                                    title="Заголовок"
                                    placeholder={"Введите заголовок"}
                                    name="title"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="description"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите описание"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value}
                                    className=""
                                    title="Описание"
                                    placeholder={"Введите описание"}
                                    name="description"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="cause"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value}
                                    title="Причины"
                                    name="cause"
                                    onChange={onChange}
                                />
                            )}
                        />

                        <Controller
                            name="points"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите цену"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={"number"}
                                    prepend={<CoinCompetitionIcon />}
                                    value={value}
                                    title="Цена баллов"
                                    placeholder={"Введите цену"}
                                    name="points"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="limit_per_user"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите лимит"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={"number"}
                                    value={value}
                                    title="Лимит на пользователя"
                                    placeholder={"Введите лимит"}
                                    name="limit_per_user"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name={"type_of_limit"}
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Выберете пункт"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <Dropdown
                                    className={"form-select"}
                                    label="Тип лимита"
                                    placeholder={"Выберете пункт"}
                                    options={typeLimitsList}
                                    value={getValue(typeLimitsList, value)}
                                    onChange={(select) => onChange(select.value)}
                                    name="type_of_limit"
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="sort"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите ключ"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={"number"}
                                    value={value}
                                    title="Ключ сортировки"
                                    placeholder={"Введите ключ"}
                                    name="sort"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <Controller
                            name="owner"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { value, onChange } }) => (
                                <AddSelectUsers
                                    usersList={value}
                                    title="Владелец бейджа"
                                    setUsersList={onChange}
                                    isSingle
                                />
                            )}
                        />

                        <Controller
                            name="admin_only"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    desc="Только для администраторов"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />

                        <Controller
                            name="required_cause"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    desc="Необходимо указывать причину"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />

                        <Controller
                            name="required_comment"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    desc="Необходимо указывать комментарий"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />

                        {/*<Controller*/}
                        {/*    name="balance_convert_state"*/}
                        {/*    control={control}*/}
                        {/*    rules={{*/}
                        {/*        required: {*/}
                        {/*            value: false,*/}
                        {/*            message: ""*/}
                        {/*        }*/}
                        {/*    }}*/}
                        {/*    render={({ field: { onChange, value } }) => (*/}
                        {/*        <ToggleBlock*/}
                        {/*            isChecked={!!value}*/}
                        {/*            desc="Конвертация монет в баллы"*/}
                        {/*            handleChange={(value) => onChange(value ? 1 : 0)}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*/>*/}

                        <Controller
                            name="is_hire"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    desc="Автоматически за стаж"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />

                        <Controller
                            name="hire"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: "Введите стаж"
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    type={"number"}
                                    value={value}
                                    title="Стаж месяца"
                                    placeholder={"Введите стаж"}
                                    name="hire"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />
                    </div>

                    <div className={"workplace__booking__btns"}>
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            </div>
        </DialogContainer>
    );
};

export default WorkplaceCreateBadgesDialog;