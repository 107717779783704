import { isMd } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { getProfileVarsData, setOpenPopup, setPopupData } from "../../../../redux";
import React, { useEffect, useState } from "react";
import DialogContainer from "../../../ui/dialog/DialogContainer";
import Button from "../../../ui/form/Buttons/Button";
import Spinner from "../../../ui/Spinner";
import TableContainer from "../../../ui/form/TableContainer";
import Dropdown from "../../../ui/form/Dropdown/Dropdown";
import ToggleBlock from "../../../ui/form/Toggle/ToggleBlock";
import DatePickerForm from "../../../ui/form/DatePickerForm";
import moment from "moment";
import {
    getCurrentDate,
    getSelected,
    setCurrentDate, setPlacePosition
} from "../../../../redux/reducers/workplace/workplaceSlice";
import { $getTableWorkCalendar } from "../../../../api/requests/workplace";
import { EyeIcon } from "../../../../img";
import ComputerIcon from "../../../../img/icons/form/ComputerIcon";

interface WorkplaceAvailablePlacesModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const WorkplaceAvailablePlacesModal = (
    {
        isOpen,
        onClose
    }: WorkplaceAvailablePlacesModalProps
) => {
    if (!isOpen) return null;

    const dispatch = useDispatch();
    const { floor } = useSelector(getSelected);
    const vars = useSelector(getProfileVarsData);

    const isMobile = isMd();

    const statusTextList = [
        {
            key: 'free',
            text: 'Свободно'
        },
        {
            key: 'busy',
            text: 'Забронировано'
        },
        {
            key: 'own',
            text: 'Забронировано мной'
        }
    ]

    const statusColorList = [
        {
            key: 'Свободно',
            color: null
        },
        {
            key: 'Забронировано',
            color: '#FDD3DA'
        },
        {
            key: 'Забронировано мной',
            color: '#D2F1B3'
        }
    ]

    const currentDate = useSelector(getCurrentDate);

    const [startDate, setStartDate] = useState(moment(currentDate || new Date()).format('yyyy-MM-DD'));
    const [endDate, setEndDate] = useState(moment(new Date()).add(7, 'days').format('yyyy-MM-DD'));
    const [checked, setChecked] = useState(false);
    const [page, setPage] = useState(1);
    const [isDisabledNextPage, setIsDisabledNextPage] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [selectDateTable, setSelectDateTable] = useState(null);

    const [headerTable, setHeaderTable] = useState<any[]>([]);
    const [contentTable, setContentTable] = useState<any[]>([]);
    const [responseArr, setResponseArr] = useState<any[]>([]);

    const getTextByStatus = (status: any) => {
        const findItem = statusTextList.find(i => i.key === status);

        if (findItem) return findItem.text;

        return 'Неизвестно';
    }

    const getTextByDate = (current: any) => {
        if (current?.is_day_off) return 'Выходной';

        if (!current.date) return '';

        return moment(current.date).format('DD.MM')
    }

    const getColorDiv = (key: any) => {
        const findItem = statusColorList.find(i => i.key === key)

        if (findItem) return findItem.color;

        return statusColorList[0].color
    }

    useEffect(() => init({}), []);

    function init({
            isPush = true,
            startDateDefault = startDate,
            endDateDefault = endDate,
            checkedDefault = checked,
            pageDefault = page,
        }) {
        setIsLoadingPage(true);
        const requestObj = {
            date: startDateDefault,
            // end_date: endDate,
            at_least_one_free: checkedDefault,
            page: pageDefault
        }

        $getTableWorkCalendar(
            floor?.id || 0,
            requestObj,
            {
                newApi: true
            },
        ).then((res: any) => {
            setIsLoadingPage(false);
            if (!res || !res?.data.length) return setIsDisabledNextPage(true);

            setIsDisabledNextPage(res.data.length < 10);

            onChangeOriginalResponse(res.data, isPush)

            transformDataForTable(res.data, isPush);
        })
    }

    function onChangeOriginalResponse(dataArray: any[], isPush: boolean) {
        const list: any[] = responseArr;

        const updateList = [...dataArray].map(i => {
            const list = i.days.map((item: any) => ({
                ...item,
                text: moment(item.date).format('LL dd'),
                disabled: item.status !== 'free',
                style: {
                    backgroundColor: getColorDiv(getTextByStatus(item.status)),
                    padding: '9px 10px',
                    marginBottom: '4px',
                    borderBottom: 0
                }
            }))

            return {
                ...i,
                days: list
            }
        })

        if (updateList.length && isPush) {
            list.push(...updateList)
        }

        setResponseArr(isPush ? list : updateList)
    }

    function transformDataForTable(dataArray: any[], isPush: boolean) {
        const header = [{
            key: 'left',
            title: 'Рабочее место'
        }, ...dataArray[0].days.map((day: any) => (
            {
                key: day.date,
                title: getTextByDate(day)
            }
        ))];

        const content = dataArray
        .map(item => ({
            left: (
                <span className={"table-content-left"}>
                    <span className={"table-content-left-device"}>
                        <ComputerIcon/>
                    </span>

                    <p>{item.number_name}</p>

                    <span className={"table-content-left-eye"}
                          onClick={(event) => {
                              // onClickLeftContent && onClickLeftContent(row);
                              event.stopPropagation();
                          }}
                    >
                        <EyeIcon color={"#C2C2C2"}/>
                    </span>
                </span>
            ),
            id: item.id,
            ...item.days.reduce((acc: any, day: any) => {
                acc[day.date] = getTextByStatus(day.status);

                return acc;
            }, {})
        }))
        .sort((a, b) => a.left - b.left);

        const contentUpdate = (contentTable.length && isPush) ? contentTable : content;

        if (contentTable.length && isPush) {
            contentUpdate.push(...content);
        }

        console.log(contentUpdate, "header");

        setHeaderTable(header);
        setContentTable(contentUpdate);
    }

    function onClickChangePage() {
        setPage(page + 1);

        init({
            pageDefault: page + 1
        });
    }

    function onClickTdContent(item: any) {
        dispatch(setCurrentDate(item.date));
        dispatch(setPopupData({
            id: item.id,
            request: {
                number_name: String(item.left),
                place: item.left
            }
        }));
        dispatch(setOpenPopup());

        onClose();
    }

    function onClickLeftTable(item: any) {
        dispatch(setPlacePosition({
            place: item.left,
            id: item.id
        }));

        window.scrollTo({
            top: 150,
            behavior: "smooth"
        });

        onClose();
    }

    function onChangeDatePickerMobile(i: any, item: any) {
        if (i === 0) return;

        setSelectDateTable(item);

        onClickTdContent({
            left: item.number_name,
            id: item.id,
            date: i
        });
    }

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={onClose}
                widthProps={1320}
                label="Доступные места"
                classNameModal={"overflow-visible"}
            >
                <div className={"workplace__avail__places-wrapper"}>
                    <div className={"workplace__avail__places-form"}>
                        <div className={"workplace__avail__places-field"}>
                            <div
                                className={"workplace__avail__places-label workplace__avail__places-dateLabel"}>Дата</div>

                            <div className={"workplace__avail__places-time"}>
                                <DatePickerForm
                                    startDate={new Date(startDate)}
                                    onChange={(value) => {
                                        const date = moment(value).format("yyyy-MM-DD");

                                        dispatch(setCurrentDate(moment(date)));
                                        setStartDate(date);
                                        setPage(1);

                                        init({
                                            pageDefault: 1,
                                            startDateDefault: date,
                                            isPush: false
                                        });
                                    }}
                                    isIcon
                                    isRightIcon
                                />
                                <DatePickerForm
                                    startDate={new Date(startDate)}
                                    onChange={(value) => {
                                        const date = moment(value).format("yyyy-MM-DD");

                                        dispatch(setCurrentDate(moment(date)));
                                        setStartDate(date);
                                        setPage(1);

                                        init({
                                            pageDefault: 1,
                                            startDateDefault: date,
                                            isPush: false
                                        });
                                    }}
                                    isIcon
                                    isRightIcon
                                />
                            </div>
                        </div>

                        <div className={`workplace__avail__places-switch toggle__block`}>
                            <label
                                className={"workplace__avail__places-label"}
                                htmlFor={"switch-check"}
                            ></label>

                            <ToggleBlock
                                desc={"Любой свободный"}
                                className="contests__create__form-toggle"
                                isChecked={checked}
                                handleChange={() => {
                                    setChecked(!checked);
                                    setPage(1);

                                    init({
                                        pageDefault: 1,
                                        checkedDefault: !checked,
                                        isPush: false
                                    });
                                }}
                            />
                        </div>
                    </div>

                    {isMobile ? (
                        <>
                            {(responseArr && !isLoadingPage) ? responseArr.map((item, idx) => (
                                <>
                                    <Dropdown
                                        key={`date-item-${idx}`}
                                        // placeholer={'Выберите дату'}
                                        options={item.days}
                                        onChange={(i) => onChangeDatePickerMobile(i, item)}
                                        defaultValue={selectDateTable}
                                        placeholder={item.number_name}
                                    />
                                </>
                            )) : null}
                        </>
                    ) : (
                        <div className={"workplace__avail__places-table"}>
                            <TableContainer
                                header={headerTable}
                                content={contentTable}
                                onLeftClickTrContent={onClickTdContent}
                                // onClickLeftContent={onClickLeftTable}
                                loading={isLoadingPage}
                                sortable={false}
                                isLastRightText={false}
                            />
                        </div>
                    )}

                    <div className={"workplace__avail__places-buttons"}>
                        <Button
                            text={isLoadingPage ? (
                                    <Spinner isSmall />
                                ) : (
                                    'Показать больше вариантов'
                                )}
                            className={"workplace__avail__places-button"}
                            onClick={onClickChangePage}
                            disabled={isDisabledNextPage || isLoadingPage}
                        />
                    </div>
                </div>
            </DialogContainer>
        </>
    );
};

export default WorkplaceAvailablePlacesModal;