
import {
    $getPartnersTopList,
    $getStatisticsChartItem,
    $getStatisticsListItem,
    $getStatisticsPieChartItem
} from "../../../api/requests/analytics";

export const getStatisticsChartItems = (
    url: string,
    title: string,
    color: string,
    text: string,
    setList: any,
    startTime: number,
    endTime: number,
    filterType: string,
    setIsLoading?: any,
    icon?: any,
) => {
    $getStatisticsChartItem(
        `api/v1/statistics${url}`,
        {
            start_date: startTime,
            end_date: endTime,
            filter_type: filterType
        },
        {newApi: true}
        )
    .then(res => {
        if (!res) return;

        const updatedList = {
            ...res.data,
            title,
            color,
            text,
            icon,
            startDate: startTime,
            endDate: endTime,
        };

        setList(updatedList)
        setIsLoading(false)
    })
}

export const getStatisticsPieChartItems = (
    url: string,
    title: string,
    firstColor: string,
    secondColor: string,
    setList: any,
    startTime: number,
    endTime: number,
    filterType: string,
) => {
    $getStatisticsPieChartItem(
        `api/v1/statistics${url}`,
        {
            start_date: startTime,
            end_date: endTime,
        },  {newApi: true})
    .then(res => {
        if (!res.list) return;

        const updatedList = {
            ...res,
            title,
            firstColor,
            secondColor,
            startDate: startTime,
            endDate: endTime,
        };

        setList(updatedList)
    })
}

export const getStatisticsListItems = (
    url: string,
    title: string,
    icon: any,
    setList: any,
    startTime: number,
    endTime: number,
    filterType: string,
) => {
    $getStatisticsListItem(
        `api/v1/statistics${url}`,
        {
            start_date: startTime,
            end_date:  endTime,
            filter_type: filterType,
        },
        {newApi: true}
    ).then(res => {

        if (!res.data) return;

        const updatedTopList = {
            ...res.data,
            title: title,
            img: icon,
            startDate: startTime,
            endDate: endTime,
        };

        setList(updatedTopList);
    });
}
