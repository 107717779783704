import TextField from "../../components/ui/form/Fields/TextField";
import { CoinCompetitionIcon } from "../../img";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../components/ui/form/Buttons/Button";
import {
    $createAutomation,
    $getAutomation,
    $getAutomationStaffList
} from "../../api/requests/workspace-settings/automation";
import {
    WorkspaceAutomationCreateData
} from "../../api/requests/workspace-settings/automation/interface";
import { successNotify } from "../../utils";

interface FormData {
    points: string;
    schedule_mode: string;
    replacer: number;
    leader: number;
    calculate_decree: number;
    process_status: number;
}

const WorkspaceSettingAccrual = () => {
    const dayTypes = [
        {
            value: "every_week",
            label: "Eженедельно",
        },
        {
            value: "every_month",
            label: "Eжемесячно",
        },
        {
            value: "every_quarter",
            label: "Eжеквартально",
        }
    ];

    const [whoList, setWhoList] = useState<any[]>([]);
    const [editData, setEditData] = useState<any>(null);
    const [search, setSearch] = useState("");

    const {
        handleSubmit,
        control,
        watch
    } = useForm<FormData>(
        {
            values: {
                points: editData?.points,
                schedule_mode: editData?.schedule_mode,
                replacer: editData?.active?.id,
                leader: editData?.leader?.id,
                calculate_decree: editData?.calculate_decree,
                process_status: editData?.process_status
            }
        }
    );

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value === val);
        }
        return null;
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        $getAutomationStaffList(search ? { search } : {}, { newApi: true }).then(res => {
            if (res.error || res.code === "ERR_BAD_RESPONSE") return;

            const activePerson = {
                ...res?.data.active,
                value: res?.data.active.id,
                label: res?.data.active.name
            }

            const updateWhoList = [
                ...res?.data.staff_list
                .filter((item) =>
                    item?.staff_id !== res?.data.active?.staff_id)
                .map((item: any) => ({
                    ...item,
                    value: item.id,
                    label: item.name
                }))
            ];

            activePerson.value && updateWhoList.unshift(activePerson)

            setWhoList(updateWhoList);

            setEditData((prev: any) => {
                return {
                    ...prev,
                    ...res.data,
                    staff_id: res?.data.active.staff_id,
                };
            });
        });
    }, [search]);

    function init() {
        $getAutomation({ newApi: true }).then(res => {
            if (res.error || res.code === "ERR_BAD_RESPONSE") return;

            setEditData((prev: any) => ({
                ...prev,
                ...res.data
            }));
        })
    }

    function onSubmit(data: FormData) {
        const requestData: WorkspaceAutomationCreateData = {
            ...data,
            replacer: +data.replacer || +editData?.active?.id,
            leader: +editData?.leader?.id,
            points: +data.points,
            ws_id: editData.ws_id,
            "coins": 0,
            "is_functional": 0,
            "functional_settings": [],
        };

        $createAutomation(requestData, { newApi: true }).then(res => {
            if (res.error || res.code === "ERR_BAD_RESPONSE") return;

            successNotify("Успешно сохранено");
        })
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form className={"workspace-settings-users__accrual w-100"}
                      onSubmit={handleSubmit(onSubmit)}>
                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Начисление баллов</h3>

                        <div className={"d-flex gap-5 mt-3"}>
                            <Controller
                                name="schedule_mode"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете категорию"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        label={"Периодичность начисления. Каждый 1 день месяца"}
                                        options={dayTypes}
                                        value={getValue(dayTypes, value)}
                                        onChange={(select) => onChange(select.value)}
                                        isSearchable
                                    />
                                )}
                            />

                            <Controller
                                name="points"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Введите баллы"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className=""
                                        type={"number"}
                                        title="Количество баллов за 1го сотрудника"
                                        placeholder={"Введите баллы"}
                                        name="points"
                                        onChange={onChange}
                                        prepend={<CoinCompetitionIcon />}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Линейная структура</h3>
                        <p>Укажите сотрудника, который получает валюту за Генерального Директора</p>

                        <div className={"d-flex gap-5"}>
                            <Controller
                                name="replacer"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Выберете"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        label={"Кто"}
                                        options={whoList}
                                        value={getValue(whoList, value)}
                                        onChange={(select) => onChange(select.value)}
                                        // onInputChange={(value) => setSearch(value)}
                                        isSearchable
                                    />
                                )}
                            />

                            <TextField
                                value={editData?.leader?.name || "Ген. директор отсутствует"}
                                title="За"
                                name="leader"
                                disabled
                                readOnly
                            />
                        </div>
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle"}>
                        <Controller
                            name="calculate_decree"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    className="contests__create__form-toggle"
                                    desc="Учитывать сотрудников в декрете"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle"}>
                        <Controller
                            name="process_status"
                            control={control}
                            rules={{
                                required: {
                                    value: false,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <ToggleBlock
                                    isChecked={!!value}
                                    className="contests__create__form-toggle"
                                    desc="Статус процесса"
                                    handleChange={(value) => onChange(value ? 1 : 0)}
                                />
                            )}
                        />
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle d-flex gap-3 mt-4"}>
                        {/*<Button*/}
                        {/*    text={"Отмена"}*/}
                        {/*    className={"btn btn-light w-100"}*/}
                        {/*    // onClick={() => handleRemoveBlock(item, index)}*/}
                        {/*/>*/}
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WorkspaceSettingAccrual;