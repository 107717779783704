import React, {FC, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../ui/form/DatePickerForm";
import {AcceptedIcon, RejectedIcon} from "../../../img";
import {ServicesImprovementModal} from "./modals";
import {ServicesAddingAgreerModal} from "./modals/ServicesAddingAgreerModal";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface IServicesApplicationsHistory {
    isAdmin?: boolean,
    isHr?: boolean,
    columns?: any,
    list?: any,
}

export const ServicesApplicationsHistory: FC<IServicesApplicationsHistory> = (
    {
        isAdmin,
        isHr,
        columns,
        list
    }) => {
    const [isOpenDialog, setIsOpenDialog] = useState(0);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: {isValid},
        setValue,
    } = useForm<any>();

    const [data, setData] = useState(isHr ? [
            {
                id: 2314,
                date_education: '21.10.2024',
                date_application: '21.07.2024',
                status: 1,
                admin: {
                    image: '/testImages/people/Image-2.png',
                    name: 'Воробьёв Илья Романович'
                },
                users: [
                    {
                        image: '/testImages/people/Image-3.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 2,
                    },
                ]
            },
            {
                id: 2314,
                date_education: '21.10.2024',
                date_application: '21.07.2024',
                status: 0,
                admin: {
                    image: '/testImages/people/Image-2.png',
                    name: 'Воробьёв Илья Романович'
                },
                users: [
                    {
                        image: '/testImages/people/Image-3.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 2
                    },
                ]
            }
        ] :
        [
            {
                id: 2314,
                date_education: '21.10.2024',
                date_application: '21.07.2024',
                status: 1,
                admin: {
                    image: '/testImages/people/Image-2.png',
                    name: 'Воробьёв Илья Романович'
                },
                users: [
                    {
                        image: '/testImages/people/Image-3.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 2,
                    },
                    {
                        image: '/testImages/people/Image-4.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 1
                    },
                    {
                        image: '/testImages/people/Image-5.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 0
                    }
                ]
            },
            {
                id: 2314,
                date_education: '21.10.2024',
                date_application: '21.07.2024',
                status: 0,
                admin: {
                    image: '/testImages/people/Image-2.png',
                    name: 'Воробьёв Илья Романович'
                },
                users: [
                    {
                        image: '/testImages/people/Image-3.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 1
                    },
                    {
                        image: '/testImages/people/Image-4.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 1
                    },
                    {
                        image: '/testImages/people/Image-5.png',
                        name: 'Воробьёв Илья Романович',
                        isAccepted: 0
                    }
                ]
            }
        ])
    return (
        <>
            <div className="container services-acceptance">
                <div className="services-acceptance__header">
                    {columns?.map((item: any) => (
                        <div className={`column `} style={{width: `${item.width}%`}}>{item.title}</div>
                    ))}
                </div>
            </div>

            <div className="container">
                <div className=" services-acceptance__content">
                    {list.map((row: any, index: number) => (
                        <div  key={index} className="services-acceptance__block">
                            {columns.map((col: any) => (
                                <div className={`services-acceptance__block__item ${col.class}`} style={{width: `${col.width}%`}} key={col.key}>{col.render ? col.render(row[col.key], row) : row[col.key]}</div>
                            ))}
                        </div>
                    ))}

                </div>
            </div>

            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          onClose={() => setIsOpenDialog(0)} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)} />
            )}
        </>
    )
}