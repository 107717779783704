export const UserProfileIcon = (
    {
        size = 24,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.90039 20.5124C2.90039 16.7369 6.05468 13.6762 12.5004 13.6762C18.9461 13.6762 22.1004 16.7369 22.1004 20.5124C22.1004 21.1131 21.6622 21.6 21.1216 21.6H3.87921C3.33862 21.6 2.90039 21.1131 2.90039 20.5124Z"
                fill={color} />
            <path
                d="M16.1004 5.99999C16.1004 7.98822 14.4886 9.59999 12.5004 9.59999C10.5122 9.59999 8.90039 7.98822 8.90039 5.99999C8.90039 4.01177 10.5122 2.39999 12.5004 2.39999C14.4886 2.39999 16.1004 4.01177 16.1004 5.99999Z"
                fill={color} />
        </svg>
    );
};
