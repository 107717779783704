import TextField from "../Fields/TextField";
import { CloseIcon } from "../../../../img";
import React, { useEffect, useState } from "react";

interface HashtagsFieldProps {
    value: string[];
    onChange: (value: string[]) => void;
    title?: string;
    name?: string;
    className?: string;
    isHash?: boolean;
}

const HashtagsField = (
    {
        onChange,
        value,
        title = "Хэштеги",
        name,
        className = "",
        isHash = true
    }: HashtagsFieldProps
) => {
    const [ hashtags, setHashtags ] = useState<string[]>(value || []);
    const [ currentHashtag, setCurrentHashtag ] = useState("");

    const pressChangeValue = (event: any) => {
        const value: string = event?.target?.value;

        if (event?.key === "Enter" && value) {
            if (hashtags && hashtags.includes(value)) return setCurrentHashtag("");

            const updatedList = [ ...hashtags, value ]

            onChange(updatedList)
            setHashtags(updatedList)
            setCurrentHashtag("")
        }
    };

    const handleDeleteInput = (
        idx: number
    ) => {
        const updatedList = hashtags && hashtags?.filter((
            _,
            index: number
        ) => index !== idx)

        onChange(updatedList)

        setHashtags(updatedList)
    }

    useEffect(() => {
        setHashtags(value)
    }, [value]);

    return (
        <div className='services-create-form__hashtags'>
            <TextField
                value={currentHashtag}
                className={className}
                title={title}
                name={name}
                onChange={setCurrentHashtag}
                onKeyDown={pressChangeValue}
            />

            <p className='services-create-form__hashtags__warning'>
                Введите ключевое слово и нажмите Enter
            </p>

            <div className='services-create-form__hashtags__values'>
                {hashtags?.map((
                    value,
                    idx
                ) => (
                    <div key={idx}>
                        {value && (
                            <div
                                className='services-create-form__hashtags__values__item'>
                                <p>{isHash ? "#" : ""}{value}</p>

                                <div
                                    onClick={() => handleDeleteInput(idx)}
                                    className='services-create-form__hashtags__values__delete'>
                                    <CloseIcon color='#00A7B5' />
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default HashtagsField;