import React, { FC, ReactNode, useEffect, useState } from "react";
import { PictureIcon } from "../../../../img";

interface FileFieldProps {
    upload: File | string | null;
    setUpload: (file: File) => void;
    label?: string;
    className?: string;
    accept?: string;
    iconFile?: ReactNode;
    id?: string;
    disabled?: boolean;
    showImg?: boolean;
    errors?: any;
    name?: string;
}

const FileField: FC<FileFieldProps> = (
    {
        upload,
        setUpload,
        label = "Фото",
        className = "form-file",
        accept = ".png, .jpg",
        iconFile,
        id = `file-${Math.random()}`,
        disabled = false,
        showImg = true,
        errors,
        name = ""
    }
) => {
    const [placeholder, setPlaceholder] = useState("");
    const [imageURL, setImageURL] = useState<File | string | null>(null);

    const handleFile = (e: any) => {
        const file = e?.target?.files[0];
        
        if (file) {
            const imageURL = URL.createObjectURL(file);
            setImageURL(imageURL);

            setUpload(file);

            setPlaceholder(file?.name);
        }
    };

    useEffect(() => {
        if (upload && (typeof upload === "string")) setImageURL(upload);
    }, [upload]);

    return (
        <div className={className}>
            {label && <label className={"form-label name"} htmlFor={id}>{label}</label>}

            <div className={`${className}-block ${imageURL ? "image" : ""}`}>
                <label className={"label"} htmlFor={id}></label>

                <input type="file" accept={accept} onChange={handleFile} id={id} hidden disabled={disabled} />

                {((imageURL && showImg) && (typeof imageURL === "string"))
                    && (<img src={imageURL} />)
                }

                {iconFile ? iconFile : (
                    <PictureIcon color={imageURL ? "white" : "#00A7B5"}/>
                )}
            </div>

            {errors?.[name] && (
                <div className="d-flex flex-column">
                    <p className="field__error">
                        {errors?.[name]?.message as any}
                    </p>
                </div>
            )}
        </div>
    );
};

export default FileField;
