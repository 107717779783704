import React, { ReactNode, useEffect, useRef, useState } from "react";
import { failureNotify, isMd, successNotify } from "../../../utils";
import Spinner from "../../ui/Spinner";
import downloadExel from "../../../utils/file/download-exel";
import Button from "../../ui/form/Buttons/Button";
import {
    $getDownloadErrorReport,
    $getUploadStatus, $uploadFiles
} from "../../../api/requests/workspace-settings/users-upload";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { DownloadIcon } from "../../../img/icons/DownloadIcon";
import { UploadIcon } from "../../../img/icons/UploadIcon";

interface WorkspaceSettingsUserUploadProps {
    colorScheme: any;
    uploadFileUrl: string;
    downloadFileUrl: string;
    secondDownloadUrl?: string;
    typeDoc: string;
    textBtnName?: string;
    isUploadingFile: boolean;
    setIsUploadingFile: (value: boolean) => void;
    isStars?: boolean;
}

const WorkspaceSettingsUserUpload = (
    {
        colorScheme,
        uploadFileUrl = "backend/v1/workspaces/users/import-badges",
        downloadFileUrl = "/workspaces/download_ws_badges",
        secondDownloadUrl = "",
        typeDoc = "badges",
        textBtnName = "Загрузить документ",
        isUploadingFile,
        setIsUploadingFile,
        isStars = false
    }: WorkspaceSettingsUserUploadProps
) => {
    const isMobile = isMd();

    const personalData = useSelector(getPersonData);

    const inputRef = useRef<any>(null);
    const inputRef2 = useRef<any>(null);

    const [taskId, setTaskId] = useState("");

    const [launchVerificationLoop, setLaunchVerificationLoop] = useState(false);
    const [isSecondClickBtn, setIsSecondClickBtn] = useState(false);

    const [uploadingProgress, setUploadingProgress] = useState(false);
    const [uploadingSecondProgress, setUploadingSecondProgress] = useState(false);

    const [isDocStatus, setDocStatus] = useState<string | ReactNode>(textBtnName);
    const [isComplementStatus, setIsComplementStatus] = useState<string | ReactNode>("Дополнить документ");

    const loadingFileProgress = () => (
        <Spinner
            // style={{ color: colorScheme.primaryColor }}
        />
    );

    useEffect(() => {
        const task = localStorage.getItem("task_id");

        if (task && !secondDownloadUrl.length) {
            setTaskId(task);

            setLaunchVerificationLoop(true);

            $getUploadStatus(
                { task_id: task },
                { formData: true, newApi: true }
            ).then(res => {
                processingCheckStatuses(res);
            });
        }
    }, []);

    useEffect(() => {
        if (launchVerificationLoop) {
            const intervalId = setInterval(
                () => {
                    $getUploadStatus(
                        taskId ? { task_id: taskId } : undefined,
                        { formData: true, newApi: true }
                    ).then(res => {
                        processingCheckStatuses(res);
                    });
                },
                5000
            );

            return () => clearInterval(intervalId);
        }
    }, [launchVerificationLoop]);

    function onDownload() {
        $uploadFiles(
            downloadFileUrl,
            { id: personalData.workspace_id },
            { formData: true, isBlobFIle: true }
        ).then(res => {
            if (!res) return;

            downloadExel(res, `${typeDoc}.xlsx`);
        });
    }

    function onDownloadError() {
        const query: any = taskId ? {
            task_id: taskId,
            type: typeDoc === "balances_points" ? `${typeDoc}_add` : typeDoc
        } : {
            type: typeDoc === "balances_points" ? `${typeDoc}_add` : typeDoc
        };

        $getDownloadErrorReport(query, { newApi: true, isBlobFIle: true }).then(res => {
            setUploadingSecondProgress(false);
            setUploadingProgress(false);
            setIsUploadingFile && setIsUploadingFile(false);

            if (res.response) return;

            downloadExel(res, `${typeDoc}.xlsx`);
            setLaunchVerificationLoop(false);
        });

        setLaunchVerificationLoop(false);
    }

    function onDownloadErrorSecond() {
        const query: any = taskId ? {
            task_id: taskId,
            type: `${typeDoc}_upload`
        } : {
            type: `${typeDoc}_upload`
        };

        $getDownloadErrorReport(query, { newApi: true, isBlobFIle: true }).then(res => {
            setUploadingSecondProgress(false);
            setUploadingProgress(false);
            setIsUploadingFile && setIsUploadingFile(false);

            if (res.response) return;

            downloadExel(res, `${typeDoc}.xlsx`);
            setLaunchVerificationLoop(false);
        });

        setLaunchVerificationLoop(false);
    }

    function processingCheckStatuses(isData: any) {
        if (Array.isArray(isData.data) && !isData.data.length) {
            onChangeStatus("error", isData.error);
            setUploadingProgress(false);
        }

        if (isData.data) {
            const isStatusLoading = isData?.data.status === "SUCCESS";
            const isStatusError = isData?.data.status === "ERROR";

            onChangeSaveTextDoc(isStatusLoading
                ? "Загрузить документ"
                : loadingFileProgress(), !isStatusLoading);

            setUploadingProgress(!["SUCCESS", "ERROR"].includes(isData?.data.status));
            setIsUploadingFile && setIsUploadingFile(!isStatusLoading);

            isStatusError && onChangeStatus("error", isData?.data.error_message);

            if (isStatusLoading) {
                localStorage.removeItem("task_id");
                onChangeStatus("success", "Загрузка завершена успешно");
            }
        }

        if (!!isData.data?.has_error_file) {
            const query: any = isData.data.task_id ? {
                task_id: isData.data.task_id,
                type: typeDoc === "balances_points" ? `${typeDoc}_add` : typeDoc
            } : {
                type: typeDoc === "balances_points" ? `${typeDoc}_add` : typeDoc
            };

            localStorage.removeItem("task_id");

            $getDownloadErrorReport(query, { newApi: true, isBlobFIle: true }).then(res => {
                setUploadingSecondProgress(false);
                setUploadingProgress(false);
                setIsUploadingFile && setIsUploadingFile(false);

                if (res.response) return;

                downloadExel(res, `${typeDoc}.xlsx`);
                setLaunchVerificationLoop(false);
            });
        }
    }

    function onChangeStatus(status: string, message: string) {
        if (status === "error") {
            failureNotify(message);
        } else {
            successNotify(message);
        }

        setLaunchVerificationLoop(false);

        onChangeSaveTextDoc();
    }

    function onChangeSaveTextDoc(text: string | ReactNode = "Загрузить документ", isLoading: boolean = false) {
        if (!isLoading) {
            localStorage.removeItem("task_id");

            setUploadingSecondProgress(false);
            setIsUploadingFile && setIsUploadingFile(false);
        }

        const isComplement = typeDoc === "balances_points";

        const statusText = isComplement
            ? (isSecondClickBtn ? `Дополнить документ` : `Заменить документ`)
            : text;

        isSecondClickBtn ? setIsComplementStatus(statusText) : setDocStatus(statusText);
    }

    function processingLoadFile(setDocStatus: (value: string | ReactNode) => void, isData: any, addText = "") {
        if (!isData) {
            setDocStatus((textBtnName) + addText);
            setIsUploadingFile && setIsUploadingFile(false);

            return;
        }

        if (isData.data && isData.data.task_id) {
            setTaskId(isData.data.task_id);
            localStorage.setItem("task_id", isData.data.task_id);

            successNotify(isData.message);
            setLaunchVerificationLoop(true);
        } else {
            setDocStatus((textBtnName) + addText);
            setIsUploadingFile && setIsUploadingFile(false);
        }
    }

    return (
        <div className={"sm:px-4 pt-1 mt-3"}
             style={
                 isMobile
                     ? {
                         borderRadius: 0,
                         minWidth: "100vw",
                         transform: "translateX(-20px)",
                         background: colorScheme.fourthColor
                     }
                     : { borderRadius: "10px", background: colorScheme.fourthColor }
             }
        >
            <div
                className={`d-flex w-100 column-gap-4 row-gap-5 ${secondDownloadUrl ? "flex-wrap" : ""}`}>
                <div className={"d-flex w-100 gap-4"}>
                    <div className={"w-100"}>
                        <input
                            type="file"
                            id="application_document"
                            ref={inputRef}
                            onChange={(e: any) => {
                                setDocStatus(loadingFileProgress());
                                setUploadingProgress(true);
                                setIsUploadingFile && setIsUploadingFile(true);

                                $uploadFiles(
                                    uploadFileUrl,
                                    { file: e?.target?.files[0] },
                                    { formData: true, newApi: true }
                                ).then(res => {
                                    processingLoadFile(setDocStatus, res);
                                });
                            }}
                            accept=".xlsx, .xls"
                            onClick={(e: any) => {
                                e.target.value = null;
                            }}
                            hidden
                        />

                        <Button
                            leftIcon={<UploadIcon />}
                            text={
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    wordBreak: "break-word"
                                }}>
                                    {isDocStatus}
                                </div>
                            }
                            // whiteIcon={uploadingProgress ? "" : <Clip color="#fff" />}
                            onClick={() => {
                                if (inputRef.current && !uploadingProgress) {
                                    setIsSecondClickBtn(false);

                                    inputRef.current.click();
                                }
                            }}
                            className={`btn btn-primary-revert w-100`}
                            style={{
                                width: "100%",
                                margin: isMobile ? "0" : undefined,
                                position: "relative",
                                cursor: (uploadingProgress || uploadingSecondProgress || isUploadingFile) ? "no-drop" : "pointer",
                                opacity: (uploadingProgress || uploadingSecondProgress || isUploadingFile) ? "0.7" : "1"
                            }}
                            disabled={uploadingProgress || uploadingSecondProgress || isUploadingFile}
                        />
                    </div>

                    {secondDownloadUrl && (
                        <div className={"w-100"}>
                            <input
                                type="file"
                                id="application_document2"
                                ref={inputRef2}
                                onChange={(e: any) => {
                                    setIsSecondClickBtn(true);

                                    setIsComplementStatus(loadingFileProgress());
                                    setUploadingSecondProgress(true);
                                    setIsUploadingFile && setIsUploadingFile(true);

                                    $uploadFiles(
                                        secondDownloadUrl,
                                        { file: e?.target?.files[0] },
                                        { formData: true }
                                    ).then(res => {
                                        processingLoadFile(setIsComplementStatus, res, "(дополнить)");
                                    });
                                }}
                                accept=".xlsx, .xls"
                                onClick={(e: any) => {
                                    e.target.value = null;
                                }}
                                hidden
                            />

                            <Button
                                leftIcon={<UploadIcon />}
                                text={
                                    <div style={{
                                        display: "flex",
                                        alignItems: "center",
                                        wordBreak: "break-word"
                                    }}>
                                        {isComplementStatus}
                                    </div>
                                }
                                // whiteIcon={uploadingSecondProgress ? "" : <Clip color="#fff" />}
                                onClick={() => {
                                    if (inputRef2.current && !uploadingSecondProgress) {
                                        inputRef2.current?.click();
                                    }
                                }}
                                className={"btn btn-primary-revert w-100"}
                                style={{
                                    width: "100%",
                                    margin: isMobile ? "0" : undefined,
                                    position: "relative",
                                    cursor: (uploadingSecondProgress || uploadingProgress || isUploadingFile) ? "no-drop" : "pointer",
                                    opacity: (uploadingSecondProgress || uploadingProgress || isUploadingFile) ? "0.7" : "1"
                                }}
                                disabled={uploadingSecondProgress || uploadingProgress || isUploadingFile}
                            />
                        </div>
                    )}
                </div>

                {!isStars ? (
                    <div className={"d-flex w-100 gap-4"}>
                        <Button
                            leftIcon={<DownloadIcon />}
                            text="Скачать"
                            onClick={onDownload}
                            className={"btn btn-light w-100"}
                            style={{
                                color: "black"
                            }}
                        />

                        <Button
                            leftIcon={<DownloadIcon color={"#FF0000"} />}
                            text="Отчет об ошибках"
                            onClick={onDownloadError}
                            className={"btn btn-red w-100 ml-0"}
                            style={{
                                color: "black"
                            }}
                        />

                        {secondDownloadUrl && (
                            <Button
                                leftIcon={<DownloadIcon color={"#FF0000"} />}
                                text="Отчет об ошибках (дополнить)"
                                onClick={onDownloadErrorSecond}
                                className={"btn btn-red w-100 ml-0"}
                                style={{
                                    color: "black"
                                }}
                            />
                        )}
                    </div>
                ) : ""}
            </div>
        </div>
    );
};

export default WorkspaceSettingsUserUpload;
