import { FC } from "react";
import { IBaseIconProps } from "../types";


export const SaveIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#00A7B5"
    }) => {
    return (
        <svg width={size} height={size} viewBox="0 0 20 20" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.9375 17.875V12.8125C4.9375 12.1912 5.44118 11.6875 6.0625 11.6875H13.9375C14.5588 11.6875 15.0625 12.1912 15.0625 12.8125V18.4375M15.0625 2.125V4.375C15.0625 4.99632 14.5588 5.5 13.9375 5.5L6.0625 5.5C5.44118 5.5 4.9375 4.99632 4.9375 4.375L4.9375 1M18.4351 4.93513L15.0649 1.56487C14.7032 1.20319 14.2127 1 13.7012 1H2.92857C1.86344 1 1 1.86344 1 2.92857V17.0714C1 18.1366 1.86344 19 2.92857 19H17.0714C18.1366 19 19 18.1366 19 17.0714V6.29883C19 5.78734 18.7968 5.2968 18.4351 4.93513Z"
                stroke={color} stroke-width="2" stroke-linecap="round" />
        </svg>
    );
};