export const IdeasIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M9.46736 17.8601L9.83709 17.1035L9.46736 17.8601ZM14.8876 17.8578L14.5173 17.1015L14.8876 17.8578ZM17.5082 12.3087C17.5082 14.4124 16.2903 16.2332 14.5173 17.1015L15.258 18.6141C17.5863 17.4738 19.1924 15.0796 19.1924 12.3087H17.5082ZM12.1752 6.97565C15.1205 6.97565 17.5082 9.36334 17.5082 12.3087H19.1924C19.1924 8.43318 16.0507 5.29144 12.1752 5.29144V6.97565ZM6.84211 12.3087C6.84211 9.36334 9.2298 6.97565 12.1752 6.97565V5.29144C8.29963 5.29144 5.15789 8.43318 5.15789 12.3087H6.84211ZM9.83709 17.1035C8.0619 16.236 6.84211 14.414 6.84211 12.3087H5.15789C5.15789 15.0817 6.76647 17.4775 9.09762 18.6167L9.83709 17.1035ZM10.9617 20.7771V18.841H9.27746V20.7771H10.9617ZM13.2363 20.935H11.1196V22.6192H13.2363V20.935ZM13.3942 18.8383V20.7771H15.0784V18.8383H13.3942ZM9.27746 20.7771C9.27746 21.7945 10.1022 22.6192 11.1196 22.6192V20.935C11.0324 20.935 10.9617 20.8643 10.9617 20.7771H9.27746ZM9.09762 18.6167C9.24014 18.6863 9.27746 18.7915 9.27746 18.841H10.9617C10.9617 18.0425 10.4565 17.4062 9.83709 17.1035L9.09762 18.6167ZM13.2363 22.6192C14.2537 22.6192 15.0784 21.7945 15.0784 20.7771H13.3942C13.3942 20.8643 13.3235 20.935 13.2363 20.935V22.6192ZM14.5173 17.1015C13.8986 17.4045 13.3942 18.0405 13.3942 18.8383H15.0784C15.0784 18.7888 15.1157 18.6838 15.258 18.6141L14.5173 17.1015Z"
                    fill={color}
                />
                <path
                    d="M12.1719 3.17813V1.22266"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5958 3.95796L16.4531 2.20044"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.63074 3.95796L7.77344 2.20044"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
