export const IconFirstPlace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"
             fill="none">
            <path
                d="M6.18579 7.48992L8.32389 7.48039C8.86198 7.48516 9.4715 7.95182 9.5477 8.39944L10.0096 11.7471C10.0239 11.8852 9.9096 12.0661 9.96198 12.1471C10.0953 12.0423 10.1763 11.8756 10.1525 11.6947L9.76674 8.58992C9.67151 7.84706 9.03817 7.28516 8.28579 7.28516H6.19532V7.48516L6.18579 7.48992Z"
                fill="#FFF1B8" />
            <path
                d="M6.56662 7.70504C6.61424 7.70504 8.39043 7.73361 8.39995 7.78123C8.82852 7.78123 9.39043 8.75266 9.45709 9.11456C9.46186 9.16695 9.8809 11.8812 9.93328 11.8765C9.96186 11.986 9.89043 12.1241 9.94281 12.1765C10.0761 12.0717 10.1523 11.905 10.1285 11.7289L9.74281 8.65266C9.64757 7.91456 9.019 7.35742 8.27614 7.35742H6.19995V7.61933L6.57138 7.70504H6.56662Z"
                fill="#FEE89A" />
            <path
                d="M6.97614 7.92578C7.07138 7.92578 8.4571 7.99245 8.46662 8.08769C8.79043 8.08769 9.30472 9.55911 9.35233 9.83054C9.36662 9.9353 9.73329 12.021 9.83805 12.0067C9.88567 12.0877 9.84757 12.1829 9.90472 12.2067C10.038 12.102 10.1142 11.9401 10.0904 11.7639L9.70948 8.7115C9.619 7.97816 8.99043 7.42578 8.25233 7.42578H6.19043V7.80673L6.97614 7.92578Z"
                fill="#FDE07C" />
            <path
                d="M7.35246 8.137C7.50008 8.137 8.48579 8.24176 8.50484 8.38462C8.71912 8.38462 9.18579 10.356 9.21912 10.537C9.23817 10.6941 9.56198 12.1465 9.71912 12.1275C9.78103 12.1751 9.78103 12.2322 9.83817 12.2322C9.96674 12.1322 10.0477 11.9656 10.0239 11.7941L9.6477 8.76557C9.55722 8.037 8.93817 7.49414 8.20484 7.49414H6.18579V8.06557L7.35246 8.14652V8.137Z"
                fill="#FDD75E" />
            <path
                d="M7.79519 8.3575C7.99043 8.3575 8.58567 8.50035 8.60948 8.69083C8.719 8.69083 9.13805 11.1623 9.15234 11.2527C9.18091 11.4623 9.4571 12.2813 9.66662 12.2527C9.74281 12.2718 9.78567 12.2813 9.84281 12.2527C9.97138 12.1527 10.0476 11.9861 10.0238 11.8146L9.64758 8.81464C9.5571 8.09559 8.94281 7.55273 8.21424 7.55273H6.18091V8.31464L7.79043 8.35274L7.79519 8.3575Z"
                fill="#FCCF40" />
            <path
                d="M7.14744 7.48992L5.00935 7.48039C4.47125 7.48516 3.86173 7.95182 3.78078 8.39944L3.31887 11.7471C3.30459 11.8852 3.41887 12.0661 3.36649 12.1471C3.23316 12.0423 3.1522 11.8756 3.17601 11.6947L3.56173 8.58992C3.65697 7.84706 4.2903 7.28516 5.04268 7.28516H7.13316V7.48516L7.14744 7.48992Z"
                fill="#FFF1B8" />
            <path
                d="M6.76667 7.70504C6.71905 7.70504 4.94286 7.73361 4.93334 7.78123C4.50477 7.78123 3.94286 8.75266 3.8762 9.11456C3.87143 9.16695 3.45239 11.8812 3.4 11.8765C3.37143 11.986 3.44286 12.1241 3.39048 12.1765C3.25715 12.0717 3.18096 11.905 3.20477 11.7289L3.59048 8.65266C3.68572 7.91456 4.31429 7.35742 5.05715 7.35742H7.13334V7.61933L6.76191 7.70504H6.76667Z"
                fill="#FEE89A" />
            <path
                d="M6.35714 7.92578C6.2619 7.92578 4.87619 7.99245 4.86666 8.08769C4.54285 8.08769 4.02857 9.55911 3.98095 9.83054C3.96666 9.9353 3.6 12.021 3.49523 12.0067C3.45238 12.0877 3.48571 12.1829 3.42857 12.2067C3.29523 12.102 3.21904 11.9401 3.24285 11.7639L3.6238 8.7115C3.71428 7.97816 4.34285 7.42578 5.08095 7.42578H7.14285V7.80673L6.35714 7.92578Z"
                fill="#FDE07C" />
            <path
                d="M5.981 8.137C5.83338 8.137 4.84766 8.24176 4.82861 8.38462C4.61433 8.38462 4.14766 10.356 4.11433 10.537C4.09528 10.6941 3.77147 12.1465 3.61433 12.1275C3.55242 12.1751 3.55242 12.2322 3.49528 12.2322C3.36671 12.1322 3.28576 11.9656 3.30957 11.7941L3.68576 8.76557C3.77623 8.037 4.39528 7.49414 5.12861 7.49414H7.14766V8.06557L5.981 8.14652V8.137Z"
                fill="#FDD75E" />
            <path
                d="M5.53808 8.3575C5.34284 8.3575 4.75237 8.50035 4.7238 8.69083C4.61427 8.69083 4.19523 11.1623 4.18094 11.2527C4.15237 11.4623 3.87618 12.2813 3.66665 12.2527C3.59046 12.2718 3.54761 12.2813 3.49046 12.2527C3.36189 12.1527 3.2857 11.9861 3.30951 11.8146L3.6857 8.81464C3.77618 8.09559 4.39046 7.55273 5.11427 7.55273H7.14761V8.31464L5.53808 8.35274V8.3575Z"
                fill="#FCCF40" />
            <path
                d="M6.19043 8.57067H8.20472C8.44757 8.57067 8.64757 8.75162 8.68091 8.98971L8.86662 10.4754L9.05233 11.9611C9.08567 12.223 9.319 12.4088 9.58091 12.3754C9.67138 12.3659 9.75233 12.3326 9.81424 12.2802C9.94281 12.1802 10.019 12.0183 9.99519 11.8469L9.80948 10.3611L9.62376 8.87543C9.53329 8.16114 8.92376 7.62305 8.20472 7.62305H6.19043V8.57543V8.57067Z"
                fill="#FBC622" />
            <path
                d="M9.52376 8.8776C9.43329 8.16808 8.82853 7.71094 8.11424 7.71094H6.44281L6.19043 7.74427V7.82522L6.28567 8.57284H7.88091C7.88091 8.57284 8.22852 8.57284 8.23805 8.58713C8.39519 8.59665 8.53329 8.68713 8.60948 8.82046C8.64281 8.87284 8.66662 8.95379 8.67614 9.0157L8.8571 10.4633L8.87138 10.5728L9.03805 11.9062C9.0571 12.0538 9.1571 12.2252 9.27614 12.2824C9.34757 12.3347 9.42376 12.349 9.50948 12.3395C9.59043 12.33 9.67138 12.2967 9.73805 12.249C9.85233 12.1633 9.91424 12.0062 9.89043 11.8252C9.88567 11.7824 9.719 10.4395 9.70948 10.3776C9.69519 10.2633 9.519 8.8776 9.519 8.8776H9.52376Z"
                fill="url(#paint0_radial_14442_17542)" />
            <path
                d="M9.42376 8.87982C9.33329 8.17982 8.73329 7.79411 8.02853 7.79887H6.39995L6.19043 7.86554V7.94649L6.38567 8.5703H7.93805C7.96662 8.5703 8.26186 8.57982 8.28567 8.59887C8.43329 8.62268 8.56186 8.71792 8.62853 8.84649C8.6571 8.88935 8.68091 8.98459 8.68567 9.03697L8.86186 10.4465L8.87614 10.556L9.03805 11.8513C9.05233 11.9751 9.14281 12.1846 9.24757 12.2322C9.30948 12.2989 9.37138 12.3179 9.45233 12.3036C9.52853 12.2941 9.60472 12.2608 9.68091 12.2179C9.77614 12.1465 9.82853 11.9893 9.80948 11.8036C9.79995 11.7179 9.64757 10.5227 9.63329 10.3941C9.59995 10.1703 9.43329 8.87982 9.43329 8.87982H9.42376Z"
                fill="url(#paint1_radial_14442_17542)" />
            <path
                d="M9.32865 8.88477C9.24293 8.18953 8.63817 7.88477 7.94293 7.88477H6.36198L6.19531 7.98477V8.06572L6.49055 8.56572H7.99531C8.04293 8.56572 8.29531 8.58 8.32865 8.60381C8.46674 8.63715 8.58103 8.73715 8.64293 8.87048C8.66674 8.90381 8.69055 9.01334 8.69531 9.05619L8.86674 10.4276L8.88103 10.5324L9.03817 11.7848C9.05245 11.88 9.13341 12.1324 9.21436 12.1705C9.26674 12.2562 9.31912 12.2752 9.39531 12.261C9.46198 12.2514 9.53817 12.2181 9.61912 12.1848C9.70007 12.1276 9.74293 11.9752 9.71912 11.78C9.70484 11.6514 9.5715 10.6038 9.54293 10.4086C9.49531 10.0705 9.33341 8.88477 9.33341 8.88477H9.32865Z"
                fill="url(#paint2_radial_14442_17542)" />
            <path
                d="M9.22852 8.89671C9.14281 8.21099 8.54281 7.98242 7.8571 7.98242C7.82852 7.98242 7.73329 7.98242 7.70472 7.98242H6.319L6.19043 8.11576V8.20147L6.58091 8.5729H8.04281C8.10472 8.5729 8.319 8.59195 8.36662 8.62528C8.49519 8.66814 8.59995 8.7729 8.64757 8.90623C8.66186 8.93004 8.68567 9.05861 8.69043 9.08718L8.8571 10.4205L8.87138 10.5253L9.02376 11.7396C9.03329 11.811 9.10472 12.0967 9.16662 12.1253C9.20948 12.2253 9.25233 12.2443 9.319 12.2348C9.38091 12.2253 9.44757 12.1919 9.54281 12.1634C9.60948 12.1205 9.63805 11.9681 9.61424 11.7681C9.59519 11.5967 9.48091 10.6967 9.44281 10.4348C9.37614 9.98242 9.22852 8.89671 9.22852 8.89671Z"
                fill="url(#paint3_radial_14442_17542)" />
            <path
                d="M9.12865 8.89899C9.04293 8.21804 8.4477 8.06566 7.76674 8.07042C7.73341 8.07042 7.65246 8.07042 7.61912 8.07042H6.27151L6.18579 8.23709V8.3228L6.67627 8.57042H8.09055C8.16674 8.57042 8.34293 8.59423 8.40008 8.63709C8.51912 8.68947 8.61436 8.79899 8.65246 8.93232C8.66198 8.94661 8.68579 9.08947 8.69055 9.10851L8.85246 10.4038L8.86674 10.5038L9.01436 11.6752C9.01912 11.7228 9.08579 12.0466 9.12865 12.0657C9.16198 12.1799 9.19532 12.2038 9.25246 12.1895C9.30484 12.1799 9.37151 12.1466 9.47151 12.1228C9.52389 12.0942 9.53817 11.9466 9.51436 11.7418C9.49055 11.5228 9.39531 10.7752 9.3477 10.4466C9.26674 9.88471 9.12389 8.89899 9.12389 8.89899H9.12865Z"
                fill="url(#paint4_radial_14442_17542)" />
            <path
                d="M9.02852 8.90582C8.94281 8.22963 8.35233 8.1582 7.68091 8.1582C7.64281 8.1582 7.57614 8.1582 7.53805 8.1582H6.23329L6.19043 8.36297V8.44868L6.78091 8.57249H8.15233C8.24281 8.57249 8.38091 8.60106 8.44757 8.65344C8.56186 8.72011 8.64281 8.82963 8.67138 8.96297C8.67614 8.97249 8.69995 9.12487 8.69995 9.13439L8.8571 10.3915L8.87138 10.4915L9.01424 11.6249C9.01424 11.6487 9.07614 12.0058 9.09519 12.0153C9.12376 12.1487 9.13805 12.1677 9.19519 12.1582C9.23805 12.1487 9.30472 12.1153 9.40948 12.0963C9.44281 12.082 9.44757 11.9344 9.42376 11.7249C9.39519 11.463 9.31424 10.8582 9.26186 10.4677C9.16662 9.79154 9.02852 8.90582 9.02852 8.90582Z"
                fill="url(#paint5_radial_14442_17542)" />
            <path
                d="M6.19043 8.57186H8.20472C8.44757 8.57186 8.64757 8.75281 8.68091 8.9909L8.86662 10.4766L9.05233 11.9623C9.08567 12.2242 9.07614 12.0957 9.33805 12.0671C9.38567 12.0623 9.28091 11.2719 9.16662 10.4861C9.05233 9.70043 8.92852 8.91471 8.92852 8.91471C8.83805 8.20043 8.17138 8.25281 7.45233 8.25281H6.19043V8.57662V8.57186Z"
                fill="url(#paint6_radial_14442_17542)" />
            <path
                d="M7.13795 8.57067H5.12367C4.88081 8.57067 4.68081 8.75162 4.64748 8.98971L4.46176 10.4754L4.27605 11.9611C4.24272 12.223 4.00938 12.4088 3.74748 12.3754C3.657 12.3659 3.57605 12.3326 3.51415 12.2802C3.38557 12.1802 3.30938 12.0183 3.33319 11.8469L3.51891 10.3611L3.70462 8.87543C3.7951 8.16114 4.40462 7.62305 5.12367 7.62305H7.13795V8.57543V8.57067Z"
                fill="#FBC622" />
            <path
                d="M3.8047 8.8776C3.89518 8.16808 4.49994 7.71094 5.21423 7.71094H7.13804V7.74427V7.82522L7.0428 8.57284H5.44756C5.44756 8.57284 5.09994 8.57284 5.09042 8.58713C4.93327 8.59665 4.79518 8.68713 4.71899 8.82046C4.68565 8.87284 4.66185 8.95379 4.65232 9.0157L4.47137 10.4633L4.45708 10.5728L4.29042 11.9062C4.27137 12.0538 4.17613 12.2252 4.05232 12.2824C3.98565 12.3347 3.9047 12.349 3.82375 12.3395C3.7428 12.33 3.66185 12.2967 3.59518 12.249C3.48089 12.1633 3.41899 12.0062 3.4428 11.8252C3.44756 11.7824 3.61423 10.4395 3.62375 10.3776C3.63804 10.2633 3.81423 8.8776 3.81423 8.8776H3.8047Z"
                fill="url(#paint7_radial_14442_17542)" />
            <path
                d="M3.90485 8.87982C3.99533 8.17982 4.59533 7.79411 5.30009 7.79887H7.14295V7.86554V7.94649L6.94771 8.5703H5.39533C5.36676 8.5703 5.07152 8.57982 5.04771 8.59887C4.90009 8.62268 4.77152 8.71792 4.70485 8.84649C4.67628 8.88935 4.65247 8.98459 4.64771 9.03697L4.47152 10.4465L4.45723 10.556L4.29533 11.8513C4.28104 11.9751 4.19057 12.1846 4.0858 12.2322C4.0239 12.2989 3.96199 12.3179 3.88104 12.3036C3.80485 12.2941 3.72866 12.2608 3.65247 12.2179C3.55723 12.1465 3.50485 11.9893 3.5239 11.8036C3.53342 11.7179 3.6858 10.5227 3.70009 10.3941C3.73342 10.1703 3.90009 8.87982 3.90009 8.87982H3.90485Z"
                fill="url(#paint8_radial_14442_17542)" />
            <path
                d="M4.00481 8.88477C4.09053 8.18953 4.69529 7.88477 5.39053 7.88477H7.14291V8.07048L6.84767 8.57048H5.34291C5.30005 8.57048 5.04291 8.58477 5.00958 8.60858C4.87148 8.64191 4.75719 8.74191 4.69529 8.87524C4.67148 8.90858 4.64767 9.0181 4.64291 9.06096L4.47148 10.4324L4.45719 10.5371L4.30005 11.7895C4.28577 11.8848 4.20481 12.1371 4.12386 12.1752C4.07148 12.261 4.0191 12.28 3.94291 12.2657C3.87624 12.2562 3.80005 12.2229 3.7191 12.1895C3.63815 12.1324 3.59529 11.98 3.6191 11.7848C3.63338 11.6562 3.76672 10.6086 3.79529 10.4133C3.84291 10.0752 4.00481 8.88953 4.00481 8.88953V8.88477Z"
                fill="url(#paint9_radial_14442_17542)" />
            <path
                d="M4.09994 8.89671C4.18565 8.21099 4.78565 7.98242 5.47137 7.98242C5.49994 7.98242 5.59518 7.98242 5.62375 7.98242H7.13803V8.20147L6.74756 8.5729H5.28565C5.22375 8.5729 5.00946 8.59195 4.96661 8.62528C4.83803 8.66814 4.73327 8.7729 4.68565 8.90623C4.67137 8.93004 4.64756 9.05861 4.6428 9.08718L4.47613 10.4205L4.46184 10.5253L4.30946 11.7396C4.29994 11.811 4.22851 12.0967 4.16661 12.1253C4.12375 12.2253 4.08089 12.2443 4.01422 12.2348C3.95232 12.2253 3.88565 12.1919 3.79041 12.1634C3.72375 12.1205 3.69518 11.9681 3.71899 11.7681C3.73803 11.5967 3.85232 10.6967 3.89041 10.4348C3.95708 9.98242 4.1047 8.89671 4.1047 8.89671H4.09994Z"
                fill="url(#paint10_radial_14442_17542)" />
            <path
                d="M4.2001 8.89899C4.28581 8.21804 4.88105 8.06566 5.56201 8.07042C5.59534 8.07042 5.67629 8.07042 5.70962 8.07042H7.1382V8.3228L6.64772 8.57042H5.22867C5.15248 8.57042 4.97629 8.59423 4.91915 8.63709C4.8001 8.68947 4.70486 8.79899 4.66677 8.93232C4.65724 8.94661 4.63343 9.08947 4.62867 9.10851L4.46677 10.4038L4.45248 10.5038L4.30486 11.6752C4.3001 11.7228 4.23343 12.0466 4.19058 12.0657C4.15248 12.1799 4.12391 12.2038 4.06677 12.1895C4.01439 12.1799 3.94772 12.1466 3.84772 12.1228C3.79534 12.0942 3.78105 11.9466 3.80486 11.7418C3.82867 11.5228 3.92391 10.7752 3.97153 10.4466C4.05248 9.88471 4.19534 8.89899 4.19534 8.89899H4.2001Z"
                fill="url(#paint11_radial_14442_17542)" />
            <path
                d="M4.30001 8.90582C4.38573 8.22963 4.9762 8.1582 5.64763 8.1582C5.68573 8.1582 5.75239 8.1582 5.79049 8.1582H7.13811V8.44392L6.54763 8.56773H5.1762C5.08573 8.56773 4.94763 8.5963 4.88096 8.64868C4.77144 8.71535 4.68573 8.82487 4.65715 8.9582C4.65239 8.96773 4.62858 9.12011 4.62858 9.12963L4.47144 10.3868L4.45715 10.4868L4.3143 11.6201C4.3143 11.6439 4.25239 12.0011 4.23335 12.0106C4.20477 12.1439 4.19049 12.163 4.13335 12.1534C4.09049 12.1439 4.02382 12.1106 3.91906 12.0915C3.88573 12.0773 3.88096 11.9344 3.90477 11.7201C3.93335 11.4582 4.0143 10.8534 4.06668 10.463C4.16192 9.78677 4.30001 8.90106 4.30001 8.90106V8.90582Z"
                fill="url(#paint12_radial_14442_17542)" />
            <path
                d="M7.13822 8.57186H5.12393C4.88107 8.57186 4.68107 8.75281 4.64774 8.9909L4.46202 10.4766L4.27631 11.9623C4.24298 12.2242 4.2525 12.0957 3.9906 12.0671C3.94774 12.0623 4.04774 11.2719 4.16202 10.4861C4.27631 9.70043 4.40012 8.91471 4.40012 8.91471C4.4906 8.20043 5.15726 8.25281 5.87631 8.25281H7.13822V8.57662V8.57186Z"
                fill="url(#paint13_radial_14442_17542)" />
            <path
                d="M6.66656 20.0016C9.5618 20.0016 11.9047 17.6587 11.9047 14.7635C11.9047 11.8682 9.5618 9.52539 6.66656 9.52539C3.77132 9.52539 1.42847 11.8682 1.42847 14.7635C1.42847 17.6587 3.77132 20.0016 6.66656 20.0016Z"
                fill="url(#paint14_linear_14442_17542)" />
            <path
                d="M6.66671 19.0907C9.05718 19.0907 10.9953 17.1526 10.9953 14.7622C10.9953 12.3717 9.05718 10.4336 6.66671 10.4336C4.27623 10.4336 2.33813 12.3717 2.33813 14.7622C2.33813 17.1526 4.27623 19.0907 6.66671 19.0907Z"
                fill="url(#paint15_linear_14442_17542)" />
            <path
                d="M6.66659 18.6354C8.80469 18.6354 10.538 16.9021 10.538 14.764C10.538 12.6259 8.80469 10.8926 6.66659 10.8926C4.5285 10.8926 2.79517 12.6259 2.79517 14.764C2.79517 16.9021 4.5285 18.6354 6.66659 18.6354Z"
                fill="url(#paint16_linear_14442_17542)" />
            <path
                d="M8.30476 6.27143L3.80952 0H0L4.49524 6.29048C4.66667 6.53333 4.7619 6.82381 4.7619 7.11905V8.09524H8.57143V7.10476C8.57143 6.80476 8.47619 6.51429 8.30476 6.27143Z"
                fill="url(#paint17_linear_14442_17542)" />
            <path
                d="M12.0905 0H9.52387L8.84292 0.952381L8.1572 1.90476L6.45244 4.28571L5.77149 5.23809L5.02863 6.27143C4.8572 6.51429 4.76196 6.80476 4.76196 7.10476V8.09524H8.57149V7.11905C8.57149 6.82381 8.66672 6.53333 8.83815 6.29048L9.59053 5.23809L10.2715 4.28571L11.9715 1.90476L13.3334 0H12.0905Z"
                fill="url(#paint18_linear_14442_17542)" />
            <path
                d="M8.0953 7.14258H5.23815C4.97625 7.14258 4.76196 7.35686 4.76196 7.61877V8.57115C4.76196 8.83305 4.97625 9.04734 5.23815 9.04734H8.0953C8.3572 9.04734 8.57149 8.83305 8.57149 8.57115V7.61877C8.57149 7.35686 8.3572 7.14258 8.0953 7.14258Z"
                fill="url(#paint19_linear_14442_17542)" />
            <path
                d="M7.94771 11.6445H6.10009L4.63818 12.6969V14.6779L6.10009 13.7493V17.8541H7.94771V11.6445Z"
                fill="url(#paint20_linear_14442_17542)" />
            <path
                d="M6.66656 20.0016C9.5618 20.0016 11.9047 17.6587 11.9047 14.7635C11.9047 11.8682 9.5618 9.52539 6.66656 9.52539C3.77132 9.52539 1.42847 11.8682 1.42847 14.7635C1.42847 17.6587 3.77132 20.0016 6.66656 20.0016Z"
                fill="url(#paint21_radial_14442_17542)" />
            <path
                d="M6.66671 19.0907C9.05718 19.0907 10.9953 17.1526 10.9953 14.7622C10.9953 12.3717 9.05718 10.4336 6.66671 10.4336C4.27623 10.4336 2.33813 12.3717 2.33813 14.7622C2.33813 17.1526 4.27623 19.0907 6.66671 19.0907Z"
                fill="url(#paint22_radial_14442_17542)" />
            <path
                d="M6.66671 19.0907C9.05718 19.0907 10.9953 17.1526 10.9953 14.7622C10.9953 12.3717 9.05718 10.4336 6.66671 10.4336C4.27623 10.4336 2.33813 12.3717 2.33813 14.7622C2.33813 17.1526 4.27623 19.0907 6.66671 19.0907Z"
                fill="url(#paint23_radial_14442_17542)" />
            <defs>
                <radialGradient id="paint0_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.70395 10.7133) scale(2.00952 2.09048)">
                    <stop stopColor="#F9BF20" />
                    <stop offset="0.86" stopColor="#F9BF20" />
                    <stop offset="1" stopColor="#F9BF20" />
                </radialGradient>
                <radialGradient id="paint1_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.36648 9.61678) scale(1.95714 2.0381)">
                    <stop stopColor="#F8B71E" />
                    <stop offset="0.86" stopColor="#F8B71E" />
                    <stop offset="1" stopColor="#F8B71E" />
                </radialGradient>
                <radialGradient id="paint2_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.01436 10.5269) scale(1.90476 1.98095)">
                    <stop stopColor="#F6B01C" />
                    <stop offset="0.86" stopColor="#F6B01C" />
                    <stop offset="1" stopColor="#F6B01C" />
                </radialGradient>
                <radialGradient id="paint3_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(6.68243 11.447) scale(1.85238 1.92381)">
                    <stop stopColor="#F5A919" />
                    <stop offset="0.86" stopColor="#F5A919" />
                    <stop offset="1" stopColor="#F5A919" />
                </radialGradient>
                <radialGradient id="paint4_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(6.65293 10.5537) scale(1.8 1.87143)">
                    <stop stopColor="#F3A217" />
                    <stop offset="0.86" stopColor="#F3A217" />
                    <stop offset="1" stopColor="#F3A217" />
                </radialGradient>
                <radialGradient id="paint5_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(6.90343 9.88973) scale(1.74762 1.81905)">
                    <stop stopColor="#F29A15" />
                    <stop offset="0.86" stopColor="#F29A15" />
                    <stop offset="1" stopColor="#F29A15" />
                </radialGradient>
                <radialGradient id="paint6_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(8.0371 9.84091) scale(1.69524 1.76667)">
                    <stop stopColor="#F09313" />
                    <stop offset="0.86" stopColor="#F09313" />
                    <stop offset="1" stopColor="#F09313" />
                </radialGradient>
                <radialGradient id="paint7_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(5.71775 10.3996) rotate(180) scale(2.00952 2.09048)">
                    <stop stopColor="#F9BF20" />
                    <stop offset="0.86" stopColor="#F9BF20" />
                    <stop offset="1" stopColor="#F9BF20" />
                </radialGradient>
                <radialGradient id="paint8_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(5.36085 11.2568) rotate(180) scale(1.95714 2.0381)">
                    <stop stopColor="#F8B71E" />
                    <stop offset="0.86" stopColor="#F8B71E" />
                    <stop offset="1" stopColor="#F8B71E" />
                </radialGradient>
                <radialGradient id="paint9_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(6.64291 10.6758) rotate(180) scale(1.90476 1.98095)">
                    <stop stopColor="#F6B01C" />
                    <stop offset="0.86" stopColor="#F6B01C" />
                    <stop offset="1" stopColor="#F6B01C" />
                </radialGradient>
                <radialGradient id="paint10_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.10884 9.57233) rotate(180) scale(1.85238 1.92381)">
                    <stop stopColor="#F5A919" />
                    <stop offset="0.86" stopColor="#F5A919" />
                    <stop offset="1" stopColor="#F5A919" />
                </radialGradient>
                <radialGradient id="paint11_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.15448 10.6986) rotate(180) scale(1.8 1.87143)">
                    <stop stopColor="#F3A217" />
                    <stop offset="0.86" stopColor="#F3A217" />
                    <stop offset="1" stopColor="#F3A217" />
                </radialGradient>
                <radialGradient id="paint12_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(6.80182 11.5694) rotate(-180) scale(1.74762 1.81905)">
                    <stop stopColor="#F29A15" />
                    <stop offset="0.86" stopColor="#F29A15" />
                    <stop offset="1" stopColor="#F29A15" />
                </radialGradient>
                <radialGradient id="paint13_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(5.17155 11.6822) rotate(-180) scale(1.69524 1.76667)">
                    <stop stopColor="#F09313" />
                    <stop offset="0.86" stopColor="#F09313" />
                    <stop offset="1" stopColor="#F09313" />
                </radialGradient>
                <linearGradient id="paint14_linear_14442_17542" x1="1.70466" y1="9.0111"
                                x2="10.3808" y2="19.0682"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE16E" />
                    <stop offset="0.35" stopColor="#FFD226" />
                    <stop offset="0.45" stopColor="#FDCD23" />
                    <stop offset="0.58" stopColor="#F6BD1B" />
                    <stop offset="0.66" stopColor="#F0AF13" />
                    <stop offset="1" stopColor="#F08D13" />
                </linearGradient>
                <linearGradient id="paint15_linear_14442_17542" x1="10.4953" y1="18.5907"
                                x2="2.07147" y2="10.1717"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE16E" />
                    <stop offset="0.35" stopColor="#FFD226" />
                    <stop offset="0.45" stopColor="#FDCD23" />
                    <stop offset="0.58" stopColor="#F6BD1B" />
                    <stop offset="0.66" stopColor="#F0AF13" />
                    <stop offset="1" stopColor="#F08D13" />
                </linearGradient>
                <linearGradient id="paint16_linear_14442_17542" x1="3.36183" y1="10.845"
                                x2="9.92374" y2="18.6259"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE787" />
                    <stop offset="0.52" stopColor="#FFE16E" />
                    <stop offset="1" stopColor="#F0BC13" />
                </linearGradient>
                <linearGradient id="paint17_linear_14442_17542" x1="1.33333" y1="0.261905"
                                x2="5.91429" y2="6.13809"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3FBCF7" />
                    <stop offset="1" stopColor="#0071DA" />
                </linearGradient>
                <linearGradient id="paint18_linear_14442_17542" x1="7.21911" y1="6.55238"
                                x2="11.4477" y2="0.190476"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#3FBCF7" />
                    <stop offset="1" stopColor="#0071DA" />
                </linearGradient>
                <linearGradient id="paint19_linear_14442_17542" x1="6.37625" y1="11.4521"
                                x2="6.77625" y2="6.81401"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.49" stopColor="#3FBCF7" />
                    <stop offset="1" stopColor="#0071DA" />
                </linearGradient>
                <linearGradient id="paint20_linear_14442_17542" x1="-1.07134" y1="4.2731"
                                x2="12.5477" y2="21.9017"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFE16E" />
                    <stop offset="0.35" stopColor="#FFD226" />
                    <stop offset="0.49" stopColor="#F5BA19" />
                    <stop offset="0.58" stopColor="#F0AF13" />
                    <stop offset="1" stopColor="#F08D13" />
                </linearGradient>
                <radialGradient id="paint21_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.21742 15.4329) scale(7.26667)">
                    <stop stopColor="#FFF3C2" stopOpacity="0" />
                    <stop offset="0.72" stopColor="#FFF3C2" stopOpacity="0" />
                    <stop offset="0.83" stopColor="#FFF3C2" />
                    <stop offset="0.96" stopColor="#FFF3C2" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint22_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.84461 14.5629) scale(6.51429)">
                    <stop stopColor="#FFE477" stopOpacity="0" />
                    <stop offset="0.72" stopColor="#FFE477" stopOpacity="0" />
                    <stop offset="0.83" stopColor="#FFEEAB" />
                    <stop offset="0.96" stopColor="#FFE477" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint23_radial_14442_17542" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.84461 14.5629) scale(6.51429)">
                    <stop stopColor="#FFF3C2" stopOpacity="0" />
                    <stop offset="0.72" stopColor="#FFF3C2" stopOpacity="0" />
                    <stop offset="0.83" stopColor="#FFF3C2" />
                    <stop offset="0.96" stopColor="#FFF3C2" stopOpacity="0" />
                </radialGradient>
            </defs>
        </svg>
    );
};
