import { useDispatch, useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { getBookingData, getProfileVarsData } from "../../../redux";
import { PlusIcon2 } from "../../../img";
import {
    getBookingFloorsIsActiveData,
    getIsSelectMeeting,
    onClickChangeDropdownMeeting,
    onClickChangeMeeting,
    onClickOpenBookingActionModal
} from "../../../redux/reducers/booking/bookingRoomsSlice";

interface ServicesBookingContentLeftProps {
    floors: any;
    city: any;
    office: any;
}

const ServicesBookingContentLeft = (
    {
        floors,
        city,
        office
    }: ServicesBookingContentLeftProps
) => {
    const dispatch = useDispatch();
    const selectItemOffice = useSelector(getBookingData);
    const floorsIsActiveData = useSelector(getBookingFloorsIsActiveData);
    const isSelectMeeting = useSelector(getIsSelectMeeting);
    const vars = useSelector(getProfileVarsData);

    const [floorsList, setFloorsList] = useState(floors || floorsIsActiveData || []);

    const colorList = [
        "#D7C3FF",
        "#D2F1B3",
        "#FDD3DA",
        "#96C0FE",
        "#FAE292",
        "#A0EEE9",
        "#C0C8D9",
        "#F6BCFF",
        "#E9C1A7",
        "#CDE4FF"
    ];

    useEffect(() => {
        dispatch(onClickChangeDropdownMeeting(false));

        if (isSelectMeeting) return setFloorsList([...floorsIsActiveData]);

        setFloorsList(floors ? [...floors] : []);
    }, [floors, floorsIsActiveData]);

    function changeListByRooms(arr: any[]) {
        if (!arr) return [];

        const list: any[] = [];

        arr.forEach((i) => {
            if (i.rooms) {
                const roomsList = i.rooms.map((item: any) => {
                    return {
                        ...item,
                        floor: i.number
                    };
                });

                list.push(...roomsList);
            }
        });

        return list.map((i, idx) => {
            const indexColor = idx > 9 ? idx % 10 : idx;

            return {
                ...i,
                color: colorList[indexColor]
            };
        });
    }

    return (
        <div className={"services__booking__left"}>
            <div className={"services__booking__left__header"}>
                <h4>Все переговорные</h4>

                <p>
                    {city}, {selectItemOffice?.name || office}
                </p>
            </div>

            {changeListByRooms(floorsList).map((item, idx) => {
                const floorUpdate = `${+item?.floor}-й этаж`;

                const seatsUpdate =
                    +item?.workplace_count === 1
                        ? "1 место"
                        : item?.workplace_count < 5
                            ? `${item?.workplace_count} места`
                            : `${item?.workplace_count} мест`;

                return (
                    <div
                        key={`room-item-${idx}`}
                        className={"services__booking__left__content"}
                        onClick={() => dispatch(onClickChangeMeeting(item))}
                        style={{
                            backgroundColor: item?.isActiveRooms ? `${vars.colors.secondaryColor}11` : 'transparent'
                        }}
                    >
                        <div className={"services__booking__left__content__color"}
                             style={{ backgroundColor: item?.color }} />

                        <div className={"services__booking__left__content__block"}>
                            <div className={"services__booking__left__content__block__sub"}>
                                <h4>{item?.name}</h4>

                                <div className={"services__booking__left__content__block__text"}>
                                    <p>{floorUpdate}</p>

                                    <p>{seatsUpdate}</p>
                                </div>
                            </div>

                            <div
                                className={"services__booking__left__content__button"}
                                onClick={() => dispatch(onClickOpenBookingActionModal(item))}
                            >
                                <PlusIcon2 />
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default ServicesBookingContentLeft;