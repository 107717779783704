import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $getDiscountList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/partners", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $getDiscountById = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/partner", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $getDiscount = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/getdiscount", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $setDiscountById = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/set", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $setDiscountFavoriteById = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/setfavorite", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $getDiscountCategoriesList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/categories", data,{
    handler: {
        formData: true,
        ...handler
    }
});

export const $getDiscountCitiesList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/partners/cities", data, {
    handler: {
        formData: true,
        ...handler
    }
});