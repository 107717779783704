import {IApiHandlerParams} from "../../interface";
import api from "../../index";

export const $getUserFavoritesTypes = (
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/user/favorites/types`, {
    handler
});

export const $getUserFavoritesPreview = (
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/user/favorites/preview`, {
    handler
});

export const $getUserFavoritesAll= (
    type: string,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/user/favorites/all?type=${type}`, {
    handler
});

export const $toggleUserFavorites= (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api.put(`api/v1/user/favorites/toggle`, data,{
    handler
});