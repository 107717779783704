import { AcceptedIcon, ArrowExitIcon, RejectedIcon, ReturnIcon } from "../../../img";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import { ServicesAcceptanceList } from "../../../components/services";
import {
    ServicesApplicationsHistory
} from "../../../components/services/services-education/ServicesApplicationsHistory";
import {
    ServicesFormingApplicationModal
} from "../../../components/services/services-education/modals";
import React, { useState } from "react";
import { isMd } from "../../../utils";
import { useNavigate } from "react-router-dom";
import { PlusOneIcon } from "../../../img/icons/PlusOneIcon";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesTripPage = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [ activeType, setActiveType ] = useState('acceptance')
    const [ types, setTypes ] = useState([
            {
                id: 2,
                key: 'acceptance',
                title: 'Согласование заявок',
            },
            {
                id: 3,
                key: 'history',
                title: 'История заявок'
            }
        ]
    )

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const columns = [
        { key: "id", title: "ID заявки", width: 7.5, class: 'id'},
        { key: "tripDate", title: "Дата командировки", width: 15 },
        { key: "requestDate", title: "Дата заявки", width: 10, },
        { key: "type", title: "Тип", width: 10 },
        { key: "sender", title: "Отправитель", width: 20, render: (value: any,) => (
                <div className="services-acceptance__block__status" >
                    <img className="services-acceptance__block__admin__image"
                         src={value?.image} alt="" />

                    <h5 className="services-acceptance__block__admin__name">{value?.name}</h5>
                </div>

            )
        },
        { key: "users", title: "Согласант", width: 20, render: (value: any) => (
                <div className="services-acceptance__block__users" >
                    {value.map((user: any, idx: number) => (
                        <div className="services-acceptance__block__user" key={idx}>
                            <div className="services-acceptance__block__user__left">
                                <img src={user.image} alt="" />

                                <h5>{user.name}</h5>
                            </div>

                            {user.isAccepted === 1 && (
                                <AcceptedIcon />
                            )}

                            {user.isAccepted === 0 && (
                                <RejectedIcon />
                            )}
                        </div>
                    ))}
                </div>
            )
        },
        { key: "file", title: "Заявление", width: 7.5, render: () => (
                <div className="services-acceptance__block__file">
                    Скачать
                </div>
            )
        },
        { key: "actions", title: "", width: 10, render: (_: any, row: any) => (
                <div className="services-acceptance__block__status">
                    <div className="services-acceptance__block__status__item">
                        <AcceptedIcon /></div>
                    <div className="services-acceptance__block__status__item">
                        <RejectedIcon /></div>
                </div>
            )
        },
    ];

    const data = [
        {
            id: "2314",
            tripDate: "21.10.2024 - 29.10.2024",
            requestDate: "21.07.2024",
            type: "Зарубеж",
            sender: {
                image: "/testImages/people/Image-2.png",
                name: "Воробьёв Илья Романович"
            },
            users: [
                {
                    image: "/testImages/people/Image-3.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 1
                },
                {
                    image: "/testImages/people/Image-4.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 1
                },
                {
                    image: "/testImages/people/Image-5.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                }
            ],
        },
        {
            id: "2314",
            tripDate: "21.10.2024 - 29.10.2024",
            requestDate: "21.07.2024",
            type: "Зарубеж",
            sender: {
                image: "/testImages/people/Image-2.png",
                name: "Воробьёв Илья Романович"
            },
            users: [
                {
                    image: "/testImages/people/Image-3.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 1
                },
                {
                    image: "/testImages/people/Image-4.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                },
                {
                    image: "/testImages/people/Image-5.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                }
            ],
        },
        {
            id: "2314",
            tripDate: "21.10.2024 - 29.10.2024",
            requestDate: "21.07.2024",
            type: "Зарубеж",
            sender: {
                image: "/testImages/people/Image-2.png",
                name: "Воробьёв Илья Романович"
            },
            users: [
                {
                    image: "/testImages/people/Image-3.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                },
                {
                    image: "/testImages/people/Image-4.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 1
                },
                {
                    image: "/testImages/people/Image-5.png",
                    name: "Воробьёв Илья Романович",
                    isAccepted: 0
                }
            ],
        },
    ];

    const {
        control,
        formState: {isValid},
        setValue,
    } = useForm<any>();

    return (
        <div className='services-education'>
            <div className='container services-education-header'>
                { !isMobile && (
                    <div className='services-education-header__left'>
                        <div className='header__exitIcon' onClick={ () => navigate('/services?page=special-applications') }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Командировка</h1>
                    </div>
                ) }

                <div className='services-education-header__right'>
                    <SearchItem onChange={ () => '' } />
                </div>
            </div>

            <div className='services-education-content'>
                <div className='container services-education-content__top'>
                    { types.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={ idx }
                            className={ `services-education-content__top__block ${ activeType === item.key ? 'active' : '' }` }
                            onClick={ () => setActiveType(item.key) }
                        >
                            { item.title }
                        </div>
                    )) }
                </div>

                <div className='services-education-wrapper'>
                    <div className='container services-education-wrapper__header'>
                        <div className='services-education-wrapper__buttons'>
                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Все"}
                                            label={"Статус"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>

                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата обучение"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>
                        </div>

                    </div>

                    { activeType === 'acceptance' && (
                        <ServicesAcceptanceList isAdmin={false} isHr={false} columns={columns} list={data}/>
                    ) }

                    { activeType === 'history' && (
                        <ServicesApplicationsHistory isAdmin={false} isHr={false}/>
                    ) }
                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}/>
            )}
        </div>
    )
}