import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";
import {
    AutomationDataGet,
    AutomationStaffListResponseData,
    WorkspaceAutomationCreateData
} from "./interface";

export const $getAutomation = (
    handler?: IApiHandlerParams
): Promise<AutomationDataGet> =>
    api.get("api/v1/leaders/settings", {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });

export const $getAutomationStaffList = (
    params?: {
        search?: string;
    },
    handler?: IApiHandlerParams
): Promise<AutomationStaffListResponseData> =>
    api.get("api/v1/leaders/staff-list", {
        // mock: require('./mocks/automation.staff.list.json'),
        request: { params },
        handler
    });

export const $createAutomation = (
    data: WorkspaceAutomationCreateData,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post("api/v1/leaders/store", data, {
        // mock: require('./mocks/complete.form.calculate.json'),
        handler
    });