import { FC } from "react";
import { Controller, useForm } from "react-hook-form";

import TextField from "../../ui/form/Fields/TextField";
import Button from "../../ui/form/Buttons/Button";

import { failureNotify, successNotify } from "../../../utils";
import { SaveIcon } from "../../../img/icons/others/SaveIcon";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import { $saveWelcome } from "../../../api/requests/workspace-settings/welcome";

interface WorkspaceSettingWelcomeFormProps {
    onDelete: (idx: any, isNew: any) => void;
    onSuccess: () => void;
    data?: any;
    index: number;
}

interface FormData {
    id?: string;
    days_after: string;
    points: string;
    comment: string;
    badge_id: string;
    workspace_id: number;
    isNew?: boolean;
}

export const WorkspaceSettingWelcomeForm: FC<WorkspaceSettingWelcomeFormProps> = (
    {
        onSuccess,
        onDelete,
        data,
        index
    }
) => {

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isValid, isDirty }
    } = useForm<FormData>({
        values: {
            id: data?.id || "",
            days_after: data?.days_after || "",
            points: data?.points || "",
            comment: data?.comment || "",
            badge_id: data?.badge_id || "",
            workspace_id: data?.workspace_id || 0,
        }
    });

    function handleDelete(idx: number, isNew: boolean = false) {
        if (!confirm('Удалить?')) {
            return;
        }
        onDelete(idx, isNew);
    }

    const removeUndefinedKeys = (obj: any) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== undefined)
        );
    };

    function onSubmit(data: any) {
        const requestData: any = {
            ...removeUndefinedKeys(data)
        };
        $saveWelcome(requestData).then((res) => {
            if (!res || res.error) return;
            if (res.status) return;
            onSuccess();
            successNotify("Успешно сохранена").then();
        }).catch(() => {
            console.log('apiError');
            failureNotify('Ошибка, попробуй ещё раз.');
        })
    }

    return (
        <form
            className={"workspace-settings-users__accrual w-100"}
            onSubmit={handleSubmit(onSubmit)}
        >
            <div className={"workspace-settings__welcome-form"}>
                <Controller
                    name={`days_after`}
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Обязательное поле"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            value={value}
                            className=""
                            title={!index ? "Дней после присоединения" : ""}
                            placeholder={"Введите кол-во дней"}
                            name={`days_after`}
                            onChange={onChange}
                            errors={errors}
                        />
                    )}
                />

                <Controller
                    name="points"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Обязательное поле"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            value={value}
                            className=""
                            title={!index ? "Кол-во баллов" : ""}
                            placeholder={"Введите кол-во баллов"}
                            name="points"
                            onChange={onChange}
                            errors={errors}
                        />
                    )}
                />

                <Controller
                    name="comment"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Обязательное поле"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            value={value}
                            className=""
                            title={!index ? "Комментарий для логов" : ""}
                            placeholder={"Введите текст"}
                            name="comment"
                            onChange={onChange}
                            errors={errors}
                        />
                    )}
                />

                <Controller
                    name="badge_id"
                    control={control}
                    rules={{
                        required: {
                            value: true,
                            message: "Обязательное поле"
                        }
                    }}
                    render={({ field: { onChange, value } }) => (
                        <TextField
                            value={value}
                            className=""
                            title={!index ? "Id бейджа" : ""}
                            placeholder={"Введите ID"}
                            name="badge_id"
                            onChange={onChange}
                            errors={errors}
                        />
                    )}
                />

                <div className="workspace-settings__welcome-form-btn">
                    <Button
                        disabled={!isValid || !isDirty}
                        className="btn-icon"
                        text={<SaveIcon />}
                        type={"submit"}
                    />

                    <Button
                        className="btn-icon"
                        text={<TrashIcon />}
                        onClick={() => handleDelete(index, data?.isNew)}
                    />
                </div>
            </div>
        </form>
    );
};