import { FC, ReactNode } from "react";

interface RadioBlockForms {
    list: ListRadioData[];
    isChecked: string | number;
    handleChange: (value: string) => void;
    className?: string;
}

export interface ListRadioData {
    key: any;
    desc?: string;
    imgLeft?: ReactNode | null;
    required?: boolean;
    isDisabled?: boolean;
}

const RadioBlock: FC<RadioBlockForms> = (
    {
        list,
        isChecked,
        handleChange,
        className = "",
    }
) => {
    return (
        <div className={`radio ${className}`}>
            {list.map((item, idx) => (
                <div
                    key={`radio-item-${item.key}-${idx}`}
                    className={"radio__list"}
                    onClick={() => handleChange(item.key)}
                >
                    <div className="radio__item">
                        {item.imgLeft}
                        <p
                            style={{ opacity: !item.isDisabled ? "" : ".5" }}
                            className="radio__text"
                        >
                            {item.desc}
                        </p>
                    </div>

                    <div
                        style={{ opacity: !item.isDisabled ? "" : "0.5" }}
                        className={`radio__btn ${(isChecked === item.key) ? "active" : ""}`}
                    >
                    </div>
                </div>
            ))}
        </div>
    );
};

export default RadioBlock;
