import { ArrowExitIcon } from "../../../../img";
import React, { useEffect, useState } from "react";
import { isMd } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { $uploadServiceApplicationFile } from "../../../../api/requests/services";
import DatePickerForm from "../../../../components/ui/form/DatePickerForm";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";
import FileClipField from "../../../../components/ui/form/FileField/FileClipField";
import { UploadIcon } from "../../../../img/icons/files/UploadIcon";
import Button from "../../../../components/ui/form/Buttons/Button";
import Dropdown from "../../../../components/ui/form/Dropdown/Dropdown";
import { $getMedicalLeaveDiapason } from "../../../../api/requests/services/medical-leave";
import CompleteModal from "../../../../components/ui/dialog/CompleteModal";
import TextField from "../../../../components/ui/form/Fields/TextField";
import { $createServiceTrip } from "../../../../api/requests/services/trip";
import moment from "moment";

export const ServicesTripCreate = () => {
    const isMobile = isMd();
    const navigate = useNavigate();

    const [uploadFileList, setUploadFileList] = useState<any>(undefined);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm<any>();

    const btnListRemove = [
        {
            text: "Отлично",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true
                        }).then(res => {
                            setUploadFileList({ file: reader.result });
                            setValue("file", res.data.url);
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }

    function onSubmit(obj: any) {
        const updatedList = {
            ...obj,
            start_date:  moment(obj.start_date || new Date()).format('YYYY-MM-DD'),
            date_to:  moment(obj.end_date || new Date()).format('YYYY-MM-DD'),
            type: 'local'
        }

        $createServiceTrip(updatedList, {newApi: true}).then(res => {

        })
    }

    return (
        <>
            <CompleteModal
                icon={"😊"}
                title={"Успешно"}
                text={'Вы успешно отправили заявку на больничный. Ожидайте уведомление о результате'}
                isOpen={isModalOpen}
                setIsOpen={() => navigate('/services')}
                btnList={btnListRemove}
                onClick={() => navigate('/services')}
            />

            <div className="layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="container layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services/trip/list")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявление на командировку</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <h5 className="services-applications-create__title">Заявление</h5>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <Controller
                                name="type"
                                control={control}

                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Тип командировки"}
                                        label={"Тип сервиса"}
                                        options={[]}
                                        value={{label: 'vse', value: 0}}
                                        onChange={(e) => {
                                            setValue("type", e.value);
                                            setValue("template_type", 0);
                                        }}
                                    />
                                )}
                            />

                            <div className="services-applications-create__dates">
                                <Controller
                                    name={"start_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            startDate={value}
                                            minDate={new Date()}
                                            maxDate={watch().end_date}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={"end_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            startDate={value}
                                            className=""
                                            minDate={watch().start_date}
                                            label="Дата завершения"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                            </div>

                            <Controller
                                name={'reason'}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: "Напишите название"
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className=""
                                        title={'Основание'}
                                        placeholder={"Введите причину "}
                                        name="name"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <div className='services-applications-create__file'>
                                <div className='services-applications-create__file__header'>
                                    <p className='services-applications-create__file__header__label'>Заявление</p>

                                    {/*<div className='services-applications-create__file__header__right'>*/}
                                    {/*    <p  className='services-applications-create__file__header__text'>Скачать заявку</p>*/}

                                    {/*    <DownloadIcon size={18}/>*/}
                                    {/*</div>*/}
                                </div>

                                <Controller
                                    name="document_file"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={".docx"}
                                            label={""}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList}
                                            uploadName={uploadFileList?.file}
                                            text={'Загрузите заполненную заявку'}
                                            icon={<UploadIcon size={44}/>}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            <Button
                                type={"submit"}
                                text={"Отправить"}
                                className={"btn btn-primary w-100"}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}