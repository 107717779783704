import TextField from "../../components/ui/form/Fields/TextField";
import React, { useState } from "react";
import { CloseIcon, HamburgerIcon, MenuIcon, PlusIcon2 } from "../../img";
import { Draggable, Droppable } from "@hello-pangea/dnd";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../components/ui/form/Buttons/Button";
import {
    FieldsData,
    ListData,
    StateData,
    WorkspaceUpdateGroupData
} from "../../api/requests/workspace-settings/field-users/interface";
import {
    $createWorkspaceGroup,
    $deleteWorkspaceGroupById, $updateWorkspaceGroupById
} from "../../api/requests/workspace-settings/field-users";
import { successNotify } from "../../utils";

interface WorkspaceSettingFieldChildProps {
    item: StateData;
    index: number;
    state: StateData[];
    setState: (value: any) => void;
    fieldList: FieldsData[];
    setFieldList: (value: any) => void;
}

const WorkspaceSettingFieldChild = (
    {
        item,
        index,
        state,
        setState,
        fieldList,
        setFieldList
    }: WorkspaceSettingFieldChildProps
) => {

    const [isOpenCollapse, setIsOpenCollapse] = useState<number | null>(null);

    const [titleValue, setTitleValue] = useState(item.title || "");

    function changeCheckboxes(select: ListData, selectItem: StateData) {
        const updateList = [...state].map(item => {
            if (item.position === selectItem.position) return {
                ...item,
                names: item.names.map(i => {
                    if (i.pivot.position === select.pivot.position) return {
                        ...i,
                        pivot: {
                            ...i.pivot,
                            status: !!i.pivot.status ? 0 : 1
                        }
                    }

                    return i
                })
            }

            return item
        });

        setState(updateList);
    }

    function handleUpdateFields(dropdownSelect: any, stateSelect: StateData) {
        const updateList = [...state].map(item => {
            if (item.position === stateSelect.position) {
                item.names.push({
                    id: dropdownSelect.id,
                    name: dropdownSelect.name,
                    pivot: {
                        status: 0
                    }
                })
            }

            return item
        });

        setState(updateList);

        const sliceList = [...fieldList].filter(item => item.id !== dropdownSelect.id);

        setFieldList(sliceList);
    }

    function handleRemoveField(selectRemove: any, selectItem: StateData) {
        const updateList = [...state].map(item => {
            if (item.position === selectItem.position) return {
                ...item,
                names: [...item.names].filter(i => i.pivot.position !== selectRemove.pivot.position)
            }

            return item
        });

        setState(updateList);

        const updateAddList = [...fieldList];

        updateAddList.push(selectRemove);

        setFieldList(updateAddList);
    }

    function onChangeTitle(value: string, idx: number) {
        const updateBLocks = [...state];

        updateBLocks[idx].title = value;

        setState(updateBLocks);
    }

    function handleRemoveBlock(select: StateData, idx: number) {
        if (!select.id) return removeItemList(idx);

        $deleteWorkspaceGroupById(select.id, { newApi :true }).then(() => {
            removeItemList(idx);
            successNotify('Успешно удалено');
        })
    }

    function removeItemList(idx: number) {
        if (!idx && idx !== 0) return;

        const updateBlocks = [...state];

        updateBlocks[idx].names.forEach((i, _idx) => {
            addDropdownItem(updateBlocks[idx].names[_idx]);
        })

        updateBlocks.splice(idx, 1);

        setState(updateBlocks);
    }

    function addDropdownItem(field: any) {
        const updateBLocks = [...fieldList];

        if (field) updateBLocks.unshift(field);

        setFieldList(updateBLocks);
    }

    function handleSaveItem(select: StateData) {
        const namesUpdate = [...select.names].map(item => ({
            id: item?.id || 0,
            status: item?.pivot?.status || 0
        }))

        const requestData = {
            names: namesUpdate,
            position: select.position,
            status: select.status || 0,
            title: titleValue,
        }

        if (select.id) return updateItem(select, requestData);

        $createWorkspaceGroup(requestData, { newApi :true }).then(res => {
            if (res.error || res.response) return;

            successNotify("Успешно создано");

            onChangeTitle(requestData.title, index);
        })
    }

    function updateItem(select: StateData, requestData: WorkspaceUpdateGroupData) {
        if (!select.id) return;

        $updateWorkspaceGroupById(select.id, requestData, { newApi :true }).then(res => {
            if (res.error || res.response) return;

            successNotify("Успешно обновлено");

            onChangeTitle(requestData.title, index);
        })
    }

    return (
        <>
            <div className={"d-flex"}>
                <HamburgerIcon color={"#00000080"} />
            </div>

            <div className={"w-100 justify-content-between"}>
                <div className={"mb-3 pb-1"}>
                    <TextField
                        value={titleValue}
                        title="Введите название"
                        placeholder={"Введите название"}
                        onChange={setTitleValue}
                    />
                </div>

                <div>
                    <Droppable droppableId={item.id?.toString() || ""}>
                        {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}
                                 className={"workspace-settings-users__field-block__modules"}>
                                {item.names.map((_item, _index) => {
                                    if (!_item) return;

                                    return (
                                        <Draggable
                                            key={`${_item.name}-item-${_index}`}
                                            draggableId={`${_item.name}-item-${_index}`}
                                            index={_index}
                                        >
                                            {(_provided) => (
                                                <div
                                                    ref={_provided.innerRef}
                                                    {..._provided.draggableProps}
                                                    {..._provided.dragHandleProps}
                                                    className={"drag-and-drop__block workspace-settings-users__field-block__modules-item"}
                                                >
                                                    <div
                                                        className={"w-100 justify-content-between"}>
                                                        <div>
                                                            <HamburgerIcon color={"#00000080"} />

                                                            <p>{_item.name}</p>
                                                        </div>

                                                        <div className={"d-flex"}>
                                                            <ToggleBlock
                                                                desc={""}
                                                                isChecked={!!_item.pivot.status}
                                                                handleChange={() => changeCheckboxes(_item, item)}
                                                            />

                                                            <Button
                                                                text={<CloseIcon
                                                                    width={14}
                                                                    height={14}
                                                                    color={"#FF0000"}
                                                                />}
                                                                className={"btn-icon"}
                                                                onClick={() => handleRemoveField(_item, item)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}

                                {provided.placeholder}

                                <div className={"position-relative"}>
                                    <Button
                                        text={<PlusIcon2
                                            size={32}
                                            color={"#00A7B5"} />}
                                        className={"btn btn-blue-secondary w-100"}
                                        onClick={() => {
                                            setIsOpenCollapse(isOpenCollapse === item.position ? null : (item.position || null))
                                        }}
                                    />

                                    <div
                                        className={`collapse ${isOpenCollapse === item.position ? "open" : ""}`}>
                                        {fieldList.map((_item, idx) => (
                                            <div
                                                className={"collapse-item"}
                                                key={`add-item-${idx}`}
                                                onClick={() => handleUpdateFields(_item, item)}
                                            >
                                                {_item.name}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </Droppable>
                </div>

                <div className={"d-flex"}>
                    <Button
                        text={"Удалить"}
                        className={"btn btn-red w-100"}
                        onClick={() => handleRemoveBlock(item, index)}
                    />
                    <Button
                        text={"Сохранить"}
                        className={"btn btn-primary w-100"}
                        onClick={() => handleSaveItem(item)}
                    />
                </div>
            </div>
        </>
    )
}

export default WorkspaceSettingFieldChild;