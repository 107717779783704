export const PieChartIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M11.9985 2.3999C17.3004 2.39993 21.5984 6.69802 21.5984 11.9999C21.5984 17.3019 17.3004 21.6 11.9984 21.6C6.6965 21.6 2.39844 17.3019 2.39844 11.9999C2.39844 6.69798 6.69654 2.39987 11.9985 2.3999ZM11.9985 2.3999L11.9984 12M11.9984 12L5.39844 18.6M11.9984 12L5.39844 5.39996"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
