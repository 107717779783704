import {
    ArrowExitIcon,
    SearchIcon,
    SettingIcon
} from "../../img";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Dropdown, { DropdownItem } from "../../components/ui/form/Dropdown/Dropdown";
import { isMd } from "../../utils";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import Button from "../../components/ui/form/Buttons/Button";
import TextField from "../../components/ui/form/Fields/TextField";
import { EventCard } from "../../components/events/card/EventCard";
import {
    $getEventCategories,
    $getEventPaginateList
} from "../../api/requests/events";
import { EventSlider } from "../../components/events/slider/EventSlider";
import ModalCatBlogs from "../../components/categories/ModalCatBlogs";

export const EventsPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || undefined;

    const [eventsData, setEventsData] = useState<any[]>([]);
    const [filterList, setFilterList] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any>(null);
    const [isOpenCatModal, setIsOpenCatModal] = useState(false);

    const [searchValue, setSearchValue] = useState<string>("");

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        setFilterList(!catId ? eventsData : [...eventsData].filter((item: any) => item.category?.id === +catId));
    }, [catId]);

    function init() {
        getCategoriesList();

        getEventsList();
    }

    function getCategoriesList() {
        $getEventCategories(isAdmin, { newApi: true }).then(res => {
            if (!res || !res.data.data.length) return;

            const updateList = [...res.data.data].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            }));

            setCategoryList([...updateList]);

            if (catId) {
                setCategorySelect(updateList.find(i => i.value === +catId));
            }
        });
    }

    function getEventsList() {
        $getEventPaginateList( { newApi: true }).then(res => {
            if (!res.data.data) return;

            setEventsData(res.data.data);
            setFilterList(res.data.data);
        });
    }

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`);
    }

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        const filterList = [...eventsData].filter(item => {
            return (item.title.toLowerCase().includes(value.toLowerCase()));
        });

        setFilterList(filterList);
    }

    return (
        <div className="events">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate("/")}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Мероприятия</h1>
                    </div>
                )}

                <div className="news-search">
                    <div className="search__block-field">
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"black"} />}
                            className={`search__block-textField`}
                        />
                    </div>
                </div>
            </div>

            {filterList.length ? <EventSlider data={filterList?.slice(0, 5)} /> : (
                <div className={"d-flex align-items-center justify-content-center p-5"}>Мероприятий
                    нет</div>
            )}

            <div className="container events-content">
                <div className="events-content__header">
                    <Dropdown
                        value={categorySelect}
                        className="workspace-select"
                        placeholder={"Категория"}
                        options={[{ value: "", label: "Все" }, ...categoryList ]}
                        onChange={handleCategory}
                        labelBottom={currentRole === 1 ? (
                            <div className={"dropdown__list__setting"}>
                                <Button
                                    leftIcon={<SettingIcon color={"currentColor"} />}
                                    text={"Настройки"}
                                    className={"btn btn-primary-revert w-100"}
                                    onClick={handleSetting}
                                />
                            </div>
                        ) : null}
                    />
                </div>

                <div className="events-content__items">
                    {filterList.slice(5, eventsData.length).map((item, idx) => (
                        <div key={idx} onClick={() => navigate(`/events/show/${item?.id}`)}>
                            <EventCard data={item} />
                        </div>
                    ))}
                </div>
            </div>

            <ModalCatBlogs
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={"event"}
            />
        </div>
    );
};