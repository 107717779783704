import { FC, useState } from "react";
import NewsDateIcon from "../../img/icons/news/NewsDateIcon";
import { CommentIcon, EyeIcon, HeartIcon, LikeIcon, StarIcon, ThreePeopleIcon } from "../../img";
import TextOverflow from "../../utils/text-overflow/TextOverflow";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { getYearMonthDay } from "../../utils";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface PollsCard {
    title: string;
    image: string;
    category_title: string;
    date: string;
    views: number;
    likes_count: number;
    comments_count: number;
    isLiked: boolean,
    status?: number,
    complicated?: number,
    end_date?: any,
}

interface IPollsCard {
    isLikeIcon?: boolean;
    data: PollsCard
}

export const PollsCard: FC<IPollsCard> = ({
    isLikeIcon = false,
    data
}) => {
    const isMobile = window.screen.width <= 600
    const {
        title,
        image,
        category_title,
        end_date,
        views,
        likes_count,
        comments_count,
        isLiked,
        status,
        complicated,
        date,
    } = data

    const now: any = Date.now() / 1000;

    const statusData =
        status == 0 || date > now
            ? 'hidden'
            : end_date > now
                ? 'active'
                : 'completed';

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Завершённый",
            value: 0
        },
        {
            label: "Активный",
            value: 1
        },
    ]);

    const statusFind = statusList.find(item => item.value == complicated);

    return (
        <div className='feed-news-card'>
            <div className='feed-news-card__img'>
                <BlankImage image={image} classname='blogs-drafts-card__image'/>

                {status && (
                    <div className={`feed-news-card__active ${statusFind?.label}`} style={{background: data.status == 1 ? '' : '#FF0000'}}>
                        <div className={`${statusFind?.label}`}></div>

                        <p>{statusData == 'hidden'
                            ? 'Скрытый'
                            : statusData == 'active'
                                ? 'Активный'
                                : 'Завершённый'}</p>
                    </div>
                )}
            </div>

            <div className='feed-news-card__infoBox'>

                { isLikeIcon ? (
                        <div className='feed-news-card__infoBox__top'>
                            <h5>{ TextOverflow({title, lines: 3}) }</h5>

                            <div
                                // onClick={ () => {
                                //     handleLike(item.id)
                                // } }
                                className={ `employees-block__likeIcon ${ isLiked ? 'active' : '' }` }
                            >
                                <StarIcon opacity={isLiked ? 1 : 0.2}/>
                            </div>
                        </div>
                    ) :
                    <h5>{ TextOverflow({title, lines: 3}) }</h5>
                }

                <div className='feed-news-card__content'>
                    <div className='feed-news-card__dateBox'>
                        <NewsDateIcon size={ '1.25rem' }
                                      style={ {marginRight: isMobile ? '0.375rem' : '0.5rem', minWidth: '1.25rem'} } />
                        <div>До { getYearMonthDay(+end_date * 1000) }</div>
                    </div>

                    <div className='feed-news-card__stats'>
                        <div className='feed-news-card__stats__block'>
                            <EyeIcon />

                            <h1>{ views }</h1>
                        </div>

                        <div className='feed-news-card__stats__block'>
                            <ThreePeopleIcon />

                            <h1>{ comments_count }</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}