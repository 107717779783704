import { useEffect, useState } from "react";

import { MembersSearch } from "./MembersSearch";
import { isMd } from "../../../../../../utils";
import Button from "../../../../../ui/form/Buttons/Button";
import AddIcon from "../../../../../../img/icons/vacation/AddIcon";
import { CloseIcon } from "../../../../../../img";
import { $getVacationBookingUsers } from "../../../../../../api/requests/vacation";
import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../../../../redux";
import { Popover } from "@availity/mui-popover";
import { Skeleton } from "../../../../../ui/skeleton";
import { Member } from "./Member";

interface MembersAddData {
    booking: any;
    handleAdd: (value: any) => void;
}

export const MembersAdd = (
    {
        booking,
        handleAdd
    }: MembersAddData
) => {
    const isMobile = isMd();
    const vars = useSelector(getProfileVarsData);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const anchorId = open ? "members-popover" : undefined;

    const [members, setMembers] = useState([]);
    const [usersData, setUsersData] = useState([]);
    const [isVacationLoading, setIsVacationLoading] = useState(false);

    const filteredMembers = members
    ?.filter(({ id }) => id !== booking?.member?.id)
    ?.map((member: any) => {
        if (member?.id === booking?.member?.id) {
            return {
                ...member,
                isSelected: true
            };
        }

        return {
            ...member
        };
    });

    const handleOpen = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setIsVacationLoading(true);

        $getVacationBookingUsers("").then(res => {
            setIsVacationLoading(false);

            setUsersData(res?.data?.data);
        });
    }, []);

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor
    };

    return (
        <div className={"calendar__members__add__wrapper"} style={style}>
            <Button
                className={"calendar__members__add__add"}
                onClick={handleOpen}
                leftIcon={<AddIcon />}
                text={"Выбрать сотрудника"}
            />

            <Popover
                id={anchorId}
                anchorEl={anchorEl}
                anchorReference={isMobile ? "anchorPosition" : "anchorEl"}
                anchorPosition={isMobile ? { top: 1000, left: 0 } : undefined}
                marginThreshold={isMobile ? 0 : 16}
                classes={{
                    root: "calendar__members__add__root",
                    paper: "calendar__members__add__paper"
                }}
                anchorOrigin={{
                    vertical: -5,
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                open={open}
                onClose={handleClose}
            >
                {isMobile && (
                    <div className={"calendar__members__add__head"}>
                        {"Выбрать сотрудника"}

                        <div className={"calendar__members__add__close"} onClick={handleClose}>
                            <CloseIcon />
                        </div>
                    </div>
                )}

                <MembersSearch
                    usersData={usersData}
                    setUsersData={setUsersData}
                    isOpen={open}
                    setMembers={setMembers}
                />

                {isVacationLoading ? (
                    <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                ) : (
                    <div className={"calendar__members__add__body"}>
                        {/*{filteredMembers?.map((member) => (*/}
                        {/*    <Member*/}
                        {/*        key={member?.id}*/}
                        {/*        type="add"*/}
                        {/*        photo={member?.photo}*/}
                        {/*        name={`${member?.firstname} ${member?.lastname}`}*/}
                        {/*        position={member?.staff?.title}*/}
                        {/*        isSelected={member?.isSelected}*/}
                        {/*        onClick={() => handleAdd(member)}*/}
                        {/*    />*/}
                        {/*))}*/}

                        {filteredMembers?.length === 0 &&
                          <div className={"calendar__members__add__empty"}>
                            Никого не найдено
                          </div>
                        }
                    </div>
                )}
            </Popover>
        </div>
    );
};
