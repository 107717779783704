import {useNavigate} from "react-router-dom";
import {isMd} from "../../../../utils";
import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {ArrowExitIcon} from "../../../../img";
import DatePickerForm from "../../../../components/ui/form/DatePickerForm";
import {DownloadIcon} from "../../../../img/icons/DownloadIcon";
import FileClipField from "../../../../components/ui/form/FileField/FileClipField";
import {UploadIcon} from "../../../../img/icons/files/UploadIcon";
import Button from "../../../../components/ui/form/Buttons/Button";
import {$createMedicalLeave, $getMedicalLeaveDiapason} from "../../../../api/requests/services/medical-leave";
import {IMedicalLeaveReq} from "../../../../api/requests/services/medical-leave/interface";
import CompleteModal from "../../../../components/ui/dialog/CompleteModal";
import moment from "moment";
import {useSelector} from "react-redux";
import {getPersonData} from "../../../../redux";

export const ServicesMedicalCreatePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [minDate, setMinDate] = useState('')
    const [maxDate, setMaxDate] = useState('')

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm<any>();

    const btnListRemove = [
        {
            text: "Отлично",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    useEffect(() => {
        $getMedicalLeaveDiapason({newApi: true, dontShowErrorMessage: true, showModalOnError: false}).then(res => {
            if (!res.data) return;

            setMinDate(res.data.start_date)
            setMaxDate(res.data.end_date)
        })
    }, [])

    function onSubmit (obj: IMedicalLeaveReq) {
        const updatedList = {
            start_date:  moment(obj.start_date || new Date()).format('YYYY-MM-DD'),
            end_date:  moment(obj.end_date || new Date()).format('YYYY-MM-DD')
        }

        $createMedicalLeave(updatedList, {newApi: true}).then(res => {
            setIsModalOpen(true)
        })
    }

    return (
        <>
            <CompleteModal
                icon={"😊"}
                title={"Успешно"}
                text={'Вы успешно отправили заявку на больничный. Ожидайте уведомление о результате'}
                isOpen={isModalOpen}
                setIsOpen={() => navigate('/services')}
                btnList={btnListRemove}
                onClick={() => navigate('/services')}
            />

            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Работа в больничный</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <h5 className="services-applications-create__title">Заявление</h5>

                    <p  className="services-applications-create__text">Выберите дату нахождения в больничном</p>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__dates">
                                <Controller
                                    name={"start_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={!minDate}
                                            startDate={value}
                                            minDate={new Date(minDate)}
                                            maxDate={watch().end_date}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <Controller
                                    name={"end_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            disabled={!maxDate}
                                            startDate={value}
                                            className=""
                                            minDate={watch().start_date || new Date(minDate)}
                                            maxDate={new Date(maxDate)}
                                            label="Дата завершения"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                            </div>

                            <Button
                                type={"submit"}
                                text={"Отправить"}
                                className={"btn btn-primary w-100"}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}