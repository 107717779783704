import DialogContainer from "../../../ui/dialog/DialogContainer";
import { FC } from "react";
import { CloseIcon } from "../../../../img";
import TextField from "../../../ui/form/Fields/TextField";
import { Controller, useForm } from "react-hook-form";

interface IServicesImprovementModal {
  isOpen: boolean,
  onClose: () => void
}
export const ServicesImprovementModal: FC<IServicesImprovementModal> = ({
  isOpen,
  onClose
}) => {
  const {
    handleSubmit,
    control,
    watch,
    register,
    reset,
    formState: {isValid},
    setValue,
  } = useForm<any>();

  return (
    <DialogContainer isOpen={isOpen} isShowCloseBtn={false} closeModal={onClose}>
      <div className='services-create-modal__header'>
        <h1 className='services-create-modal__header__title'>Отправить на доработку</h1>

        <div className='services-create-modal__header__close' onClick={ onClose }>
          <CloseIcon color='#000' />
        </div>
      </div>

      <div className={'services-show-modal__content'}>
        <Controller
          name="push_text"
          control={ control }
          rules={ {
            required: {
              value: true,
              message: ""
            }
          } }
          render={ ({field: {value, onChange}}) => (
            <TextField
              value={ value }
              className=""
              title="Комментарий"
              placeholder='Укажите причину'
              name="title"
              onChange={ onChange }
            />
          ) }
        />

      </div>

      <div className='services-create-modal__buttons'>
        <div className='services-create-modal__buttons__item services-create-modal__buttons__add'
             onClick={() => ''}
        >
          Направить
        </div>
      </div>
    </DialogContainer>
  )
}