import {IApiHandlerParams} from "../../../interface";
import api from "../../../index";
import {IMedicalLeaveReq} from "./interface";

export const $createMedicalLeave = (
    data: IMedicalLeaveReq,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post("api/v1/services/medical-leaves/store", data, {
        handler
    });

export const $getMedicalLeaveDiapason = (

    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/services/medical-leaves/diapason`, {
        handler
    });