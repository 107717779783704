import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { CompetitionsCard } from "../../components/competitions";
import { ArrowExitIcon, SettingIcon } from "../../img";
import { $getContestById, $getContestCategories, $getContestList } from "../../api/requests/contest";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import { ContestItem } from "../../api/requests/contest/interface";
import ContestsPageView from "./ContestsView/index";
import ContestsPageSuccess from "./ContestsView/ContestsPageSuccess";
import ContestsViewCompleted from "./ContestsView/ContestsViewCompleted";
import { isMd } from "../../utils";
import ModalCatNews from "../../components/categories/ModalCatNews";

import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import Button from "../../components/ui/form/Buttons/Button";

interface DropdownItem {
    label: string;
    value: number | string;
}


function is_array(mixed_var: any) {	// Finds whether a variable is an array
    return (mixed_var instanceof Array);
}

function empty(mixed_var: any) {   // Determine whether a variable is empty
    return (mixed_var === "" || mixed_var === 0 || mixed_var === "0" || mixed_var === null || mixed_var === false || (is_array(mixed_var) && mixed_var.length === 0));
}
let lastUrl = '';
let busyCat = false;
const ContestsPage = () => {

    const navigate = useNavigate();
    const isMobile = isMd();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getCatId = searchParams.get('cat') || null;
    const getViewId = searchParams.get('view') || null;
    const getStatus = searchParams.get('status') || null;

    const profileData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const [contestList, setContestList] = useState<ContestItem[] | null>(null);
    const [isOpenCatModal, setIsOpenCatModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);
    const [categorySelect, setCategorySelect] = useState<any | null>(null);

    const [statusSelect, setStatusSelect] = useState<any>(null);
    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Активные",
            value: "1"
        },
        {
            label: "Завершённые",
            value: "2"
        },
        {
            label: "Принимаю участие",
            value: "3"
        },
        {
            label: "Скрытый",
            value: "0"
        }
    ]);


    const [isOpenModalSelect, setIsOpenModalSelect] = useState(false);
    const [isOpenModalCompletedSelect, setIsOpenModalCompletedSelect] = useState(false);
    const [selectData, setSelectData] = useState<any | null>(null);

    const [isOpenSuccessModal, setIsOpenSuccessModal] = useState(false);

    const handleNavigation = () => {
        navigate(-1);
    };

    function handleSetting() {
        setIsOpenCatModal(true);
    }
    const hasLoaded = useRef(false);
    const hasLoadedView = useRef(false);

    useEffect(() => {
        if (!empty(profileData)) {
            if (!hasLoaded.current) {
                if (!categoryList.length && !busyCat) {
                    busyCat = true;
                    $getContestCategories(profileData?.workspace_id || null).then(res => {
                        busyCat = false;
                        if (!res || !res.length) return;

                        const updateList = [...res].map((item: any) => ({
                            ...item,
                            value: item.id,
                            label: item.title,
                        }))

                        setCategoryList(updateList)

                        hasLoaded.current = true;
                    }).catch(() => {
                        busyCat = false;
                    })
                }
            }
            
            if (getViewId) {
                if (location.pathname + location.search != lastUrl || !hasLoadedView.current) {
                    handleView(parseInt(getViewId))
                    hasLoadedView.current = true;
                }
            } else {
                if (getCatId) {
                    setCategorySelect(categoryList.find(i => i.value == getCatId))
                }
                if (getStatus) {
                    setStatusSelect(statusList.find(i => i.value == getStatus))
                }
                if (location.pathname + location.search != lastUrl || (!isLoading && !contestList?.length)) {
                    loadData(profileData);
                }
            }
            lastUrl = location.pathname + location.search;
        }
    }, [profileData, location, getCatId, getStatus, categoryList,lastUrl]);

    function loadData(profileData: any) {
        const requestData: any = {
            workspace_id: profileData?.workspace_id || null,
            offset: 0,
            limit: 30
        }

        if (getCatId) {
            requestData['cat_id'] = getCatId || '';
        }

        if (getStatus) {
            requestData['status'] = getStatus || '';
        }

        $getContestList(requestData).then(res => {
            if (!res) return;
            setIsLoading(true);
            if (res.error) {

            } else {
                setContestList(res);
            }

        }).catch(e => {
            setIsLoading(true);
            console.log('error', e);
        })
    }


    function handleStatus(select: DropdownItem) {
        setStatusSelect(select);
        navigate(`?${select.value ? `&status=${select.value}` : ''}${(getCatId ? `&cat=${getCatId}` : '')}`)
    }

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);
        navigate(`?${select.value ? `&cat=${select.value}` : ''}${(getStatus ? `&status=${getStatus}` : '')}`)
    }

    function handleView(id: number) {
        $getContestById(id).then(data => {
            if (!data) return;

            setSelectData(data);
            if (data.status === "finish") return setIsOpenModalCompletedSelect(true);

            setIsOpenModalSelect(true);
        })
        navigate(`?view=${id}${getCatId ? `&cat=${getCatId}` : ''}${(getStatus ? `&status=${getStatus}` : '')}`)
    }

    function handleModalClose() {
        setIsOpenModalSelect(false);
        navigate(`?${getCatId ? `&cat=${getCatId}` : ''}${(getStatus ? `&status=${getStatus}` : '')}`)
    }


    function handleSuccessCloseModal() {
        setIsOpenSuccessModal(false);
    }

    function handleSuccessModal() {
        setIsOpenModalSelect(false);
        setIsOpenSuccessModal(true);
    }
    
    return (
        <div className="favorites-news">
            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Конкурсы</h1>
                    </div>
                )}

                <div className="layout-inner__header__selects">
                    <Dropdown
                        value={categorySelect}
                        placeholder={"Категория"}
                        options={[
                            {
                                label: "Все категории",
                                value: "all"
                            },
                            // {
                            //     label: "Без категории",
                            //     value: 0
                            // },
                            ...categoryList
                        ]}
                        onChange={handleCategory}
                        labelBottom={currentRole === 1 ? (
                            <div className={"dropdown__list__setting"}>
                                <Button
                                    leftIcon={<SettingIcon color={"currentColor"} />}
                                    text={"Настроить"}
                                    className={"btn btn-primary-revert w-100"}
                                    onClick={handleSetting}
                                />
                            </div>
                        ) : null}
                    />

                    <Dropdown
                        placeholder={"Статус"}
                        options={[
                            {
                                label: "Все",
                                value: ""
                            },
                            ...statusList
                        ]}
                        value={statusSelect}
                        onChange={handleStatus}
                    />
                </div>
            </div>

            <div className="container favorites-news-content contests-news-content">
                {(isLoading && !contestList?.length) ? 'Нет данных' : ''}
                {isLoading && contestList && contestList.map((
                    item,
                    idx
                ) => (
                    <CompetitionsCard
                        data={item}
                        key={idx}
                        handleOpenItem={(data) => {
                            navigate(`?view=${data.id}${getCatId ? `&cat=${getCatId}` : ''}${(getStatus ? `&status=${getStatus}` : '')}`)
                        }}
                    />
                ))}
            </div>

            <ContestsPageView
                isOpen={isOpenModalSelect}
                onClose={handleModalClose}
                onSuccess={handleSuccessModal}
                data={selectData}
                catsList={categoryList}
            />

            <ContestsViewCompleted
                isOpen={isOpenModalCompletedSelect}
                onClose={() => setIsOpenModalCompletedSelect(false)}
                data={selectData}
            />

            <ContestsPageSuccess
                isOpen={isOpenSuccessModal}
                onClose={() => {
                    setIsOpenSuccessModal(false);
                    navigate("application?view=" + getViewId);
                }}
            />

            <ModalCatNews
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={'contests'}
            />
        </div>
    );
};

export default ContestsPage;