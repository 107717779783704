import {IApiHandlerParams} from "../../../interface";
import api from "../../../index";
import {IServiceVacationReq} from "./interface";

export const $createServiceVacation = (
    data: IServiceVacationReq,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post("api/v1/new_vacations", data, {
        handler
    });

export const $getServiceVacation = (
    type: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/new_vacations?list_type=${type}`, {
        handler
    });


export const $getServiceVacationItem = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/new_vacations/${id}`, {
        handler
    });