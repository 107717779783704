import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import TextField from "../../ui/form/Fields/TextField";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import CountField from "../../ui/form/Fields/CountField";
import TextArea from "../../ui/form/Fields/TextArea";
import {
    $createRoomBooking,
    $updateRoomBooking
} from "../../../api/requests/workspace-settings/booking";

interface WorkspaceAddMeetingProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    building?: any;
    floor?: any;
    meet?: any;
}

interface FormData {
    name: string;
    new_floor_id: number;
    workplace_count: number;
    periphery: string;
}

const WorkspaceAddMeeting = (
    {
        building,
        floor,
        meet,
        isOpen,
        onClose,
        setIsOpen
    }: WorkspaceAddMeetingProps
) => {
    const isEdit = !!meet;

    const [isStatusComplete, setIsStatusComplete] = useState(false);
    const [floorList, setFloorList] = useState([]);

    const {
        handleSubmit,
        control,
        reset,
        formState: { isValid, errors },
        watch
    } = useForm<FormData>({
        values: {
            name: meet?.name,
            new_floor_id: floor?.id,
            workplace_count: meet?.workplace_count,
            periphery: meet?.periphery,
        }
    });

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value === val);
        }
        return null;
    };

    useEffect(() => {
        if (building?.floors) {
            setFloorList(building.floors.map((i: any) => ({
                ...i,
                label: i.number,
                value: i.id
            })))
        }
    }, [building]);

    function handleCloseModal() {
        onClose(false);
        reset();
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        if (!building?.id || !floor?.id) return;

        const requestData = {
            ...request,
            is_active: true
        };

        if (isEdit) return editData(requestData);

        $createRoomBooking(building.id, floor.id, {
            ...requestData,
            new_floor_id: undefined,
        }).then(res => {
            if (res.error || res.response) return;

            setIsStatusComplete(true);
        })
    }

    function editData(requestData: any) {
        $updateRoomBooking(building.id, floor.id, meet.id, {
            ...requestData,
        }).then(res => {
            if (res.error || res.response) return;

            handleCloseModal();
        })
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                reset();
                setIsStatusComplete(false);
            }}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && (isEdit
                ? "Редактирование переговодной"
                : "Новая переговорная")}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>👍🏻</span>

                        <h3>Успешно</h3>

                        <p>
                            Вы успешно создали переговодную.
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <form
                    className={"workspace-settings__meeting-meet__add"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div className="workspace-settings__meeting-meet__add-block">
                        <Controller
                            name="name"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextField
                                    value={value}
                                    title="Название переговорной"
                                    placeholder={"Введите название"}
                                    name="name"
                                    onChange={onChange}
                                    errors={errors}
                                />
                            )}
                        />

                        <div className={"d-flex gap-3"}>
                            <Controller
                                name="new_floor_id"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <Dropdown
                                        className={"form-select"}
                                        placeholder={"Введите этаж"}
                                        label={"Этаж"}
                                        options={floorList}
                                        value={getValue(floorList, value)}
                                        onChange={(select) => onChange(select.value)}
                                        disabled
                                    />
                                )}
                            />

                            <Controller
                                name="workplace_count"
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <CountField
                                        value={value}
                                        label="Количество мест"
                                        onChange={onChange}
                                        isInput
                                    />
                                )}
                            />
                        </div>

                        <Controller
                            name="periphery"
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: ""
                                }
                            }}
                            render={({ field: { onChange, value } }) => (
                                <TextArea
                                    value={value}
                                    placeholder={"Введите перифирию"}
                                    title="Периферия"
                                    name="periphery"
                                    onChange={onChange}
                                    rows={4}
                                />
                            )}
                        />
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            type={"submit"}
                            text={isEdit ? "Сохранить" : "Добавить"}
                            className={"btn btn-primary ml-auto w-100"}
                            disabled={!isValid}
                        />
                    </div>
                </form>
            )}
        </DialogContainer>
    );
};

export default WorkspaceAddMeeting;