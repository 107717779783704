const acceptedFiles = [
  'png',
  'jpg',
  'jpeg',
  'gif',
  'bmp',
  'svg',
  'avi',
  'mp4',
  'mp3',
  'wav',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'pdf',
  'txt',
  'csv',
  'zip',
  'rar',
];

export const checkFileExt = (e, { extList, onSuccess, onError }) => {
  const path = e.target.value.split('.');
  const extension = `${path[path.length - 1]}`;
  const chooseList = extList || acceptedFiles;

  if (chooseList.includes(extension)) {
    onSuccess(e);
  } else {
    onError();
  }
};
