import CompleteModal from "../../ui/dialog/CompleteModal";
import { BlankAva } from "../../ui/avatars/BlankAva";
import moment from "moment/moment";
import {
    CloseIcon,
    LikeIcon,
    MessengerSendIcon,
    PaperClipIcon,
    PaperClipSmallIcon,
    StarIcon
} from "../../../img";
import { TrashIcon } from "../../../img/icons/TrashIcon";
import { IBlogCommentsMessenger } from "../../../api/requests/blogs/interface";
import React, { FC, useRef, useState } from "react";
import { isTillMd } from "../../../utils";
import {
    $deleteBlogComments,
    $handleLikeBlogsComments,
    $postBlogComments
} from "../../../api/requests/blogs";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../../redux";
import { IUsers } from "../../../api/requests/user/interface";
import { $getUsers } from "../../../api/requests/user";

interface CommentMessengerChatBoxProps {
    comments_data: IBlogCommentsMessenger[];
    isLoading: boolean,
    setIsLoading: any,
    blog_id: number,
}

export const BlogsCommentsChatBox: FC<CommentMessengerChatBoxProps> = (
    {
        comments_data,
        setIsLoading,
        isLoading,
        blog_id
    }) => {
    const isMobile = isTillMd();

    const { workspace_id: wsId } = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [text, setText] = useState("");
    const [isChildCommentOpen, setIsChildCommentOpen] = useState(-1);
    const [selectedCommentId, setSelectedCommentId] = useState(-1);

    const [showDropdown, setShowDropdown] = useState(false);
    const [usersList, setUsersList] = useState<IUsers[]>([]);
    const [cursorPosition, setCursorPosition] = useState<number | null>(null);

    const taggedUsers = useRef<IUsers[]>([]);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-red w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const handleOpenChildInput = (index: number) => {
        setIsChildCommentOpen(index);
    };

    const handleDeleteComment = (select: any) => {
        if (select.key === 0) {
            setIsLoading(true);

            if (selectedCommentId >= 0) {
                $deleteBlogComments(selectedCommentId, { newApi: true }).then((res) => {
                    setIsLoading(false);
                    setSelectedCommentId(-1);
                });
            }

            return;
        }

        setSelectedCommentId(-1);
    };

    const handlePostComment = (parent_id: number) => {
        const htmlContent = generateHTML();

        setIsLoading(true);

        if (blog_id) {
            $postBlogComments({
                content: htmlContent,
                blog_id: blog_id,
                parent_id
            }, { newApi: true }).then(res => {
                setIsLoading(false);
                setText("");
                setIsChildCommentOpen(-1);
            });
        }
    };

    const handleLike = (id: number) => {
        setIsLoading(true);

        $handleLikeBlogsComments(id, { newApi: true }).then((res) => {
            setIsLoading(false);
        });
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setText(value);

        const caret = e.target.selectionStart;

        const lastWord = value.slice(0, caret).split(" ").pop();
        if (lastWord?.startsWith("@")) {
            const query = lastWord.slice(1); // Remove "@"
            if (query) {
                fetchSuggestions(query);
            }
            setShowDropdown(true);
            setCursorPosition(caret);
        } else {
            setShowDropdown(false);
        }
    };

    const generateHTML = () => {
        let formattedText = text;

        taggedUsers.current.forEach((user) => {
            const tagPattern = new RegExp(`@${user.name}`, "g");
            const userLink = `<a href="https://itelma-dev.svoi.club/user?workspace_id=${wsId}&amp;user_id=${user.id}"><span class="hashtag">@${user.name}</span></a>`;
            formattedText = formattedText.replace(tagPattern, userLink);
        });

        return `<p>${formattedText}</p>`;
    };

    const fetchSuggestions = async (query: string) => {
        if (wsId) {
            $getUsers({ id: wsId, search: query }, { formData: true }).then(res => {
                if (!res.users) return;

                setUsersList(res.users);
            });
        }
    };

    const handleOptionSelect = (user: IUsers) => {
        if (textareaRef.current && cursorPosition !== null) {
            const value = text;
            const beforeCursor = value.slice(0, cursorPosition).split(" ").slice(0, -1).join(" ");
            const afterCursor = value.slice(cursorPosition);

            const newValue = `${beforeCursor} @${user.name} ${afterCursor}`.trim();
            setText(newValue);

            taggedUsers.current.push(user); // Track the tagged user
            setShowDropdown(false);
            textareaRef.current.focus();
        }
    };

    return (
        <div className="news-chat">
            <CompleteModal
                isOpen={selectedCommentId >= 0}
                setIsOpen={() => setSelectedCommentId(-1)}
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить сообщение?"}
                btnList={btnListRemove}
                onClick={handleDeleteComment}
            />

            {comments_data.map((
                item,
                idx: number
            ) => (
                <div className="news-chat__block" key={idx}>
                    <div className="news-chat__top">
                        <div className="news-chat__info">
                            {item.photo ? (
                                <img src={item.photo} alt="" className="news-chat__info__avatar" />
                            ) : (
                                <BlankAva
                                    colorBg={"#CCD9E0"}
                                    colorText={"black"}
                                    text={"A"}
                                />
                            )}

                            <div className="news-chat__info__desc">
                                <div className="news-chat__info__desc__left">
                                    <h1 className="news-chat__info__name">{item.user}</h1>


                                    {/*{item.is_expert ? (*/}
                                    {/*    <div className='news-chat__info__expert'>эксперт</div>*/}
                                    {/*) : ''}*/}

                                </div>


                                <p className="news-chat__info__date">
                                    {moment(item.created_at ? item.created_at : "").format("DD MMMM, YYYY")}</p>
                            </div>
                        </div>

                        {/*{isAdmin && (*/}
                        {/*  <div className='news-chat__top__buttons'>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <PaperClipSmallIcon/>*/}
                        {/*    </div>*/}
                        {/*    <div className='news-chat__top__buttons__item'>*/}
                        {/*      <TrashIcon/>*/}
                        {/*    </div>*/}
                        {/*  </div>*/}
                        {/*)}*/}

                        <div className="news-chat__top__buttons">

                            {/*{isClip && (*/}
                            {/*    <div className="news-chat__top__buttons__item">*/}
                            {/*        <PaperClipSmallIcon />*/}
                            {/*    </div>*/}
                            {/*)}*/}

                            <div className="news-chat__top__buttons__item"
                                 onClick={() => setSelectedCommentId(item.id)}>
                                <TrashIcon />
                            </div>
                        </div>
                    </div>

                    <div className="news-chat__content">
                        {/*{item.replies ? (*/}
                        {/*    <div className="news-chat-child__attachment">*/}
                        {/*        {item.replies.map((*/}
                        {/*            attachment: any,*/}
                        {/*            idx2*/}
                        {/*        ) => (*/}
                        {/*            <img src={attachment?.attachment} alt="" key={idx2} />*/}
                        {/*        ))}*/}
                        {/*    </div>*/}
                        {/*) : null}*/}

                        <div className="news-chat__content__text"
                             dangerouslySetInnerHTML={{ __html: item?.content }} />
                    </div>

                    <div className="news-chat__buttons">
                        <div
                            onClick={() => handleOpenChildInput(idx)}
                            className="news-chat__buttons__answerButton">Ответить
                        </div>

                        <div className="news-chat__buttons__likeButton"
                             onClick={() => handleLike(item.id)}
                        >
                            <div className="icon" style={{ display: "flex", alignItems: "center" }}>
                                <LikeIcon
                                    color={!item.is_liked ? "rgba(0, 0, 0, 0.5)" : "#00A7B5"} />
                            </div>

                            <p>{item.likes_count}</p>
                        </div>
                    </div>


                    {item.replies ? (
                        <>
                            {item.replies.map((
                                child: any,
                                idx3: number
                            ) => (
                                <div className="news-chat-child__block" key={idx3}>
                                    <div className="news-chat-child__leftRow"></div>

                                    <div className="news-chat-child__right">
                                        <div className="news-chat__top">
                                            <div className="news-chat__info">
                                                {child.photo ? (
                                                    <img
                                                        src={child?.photo}
                                                        className="news-chat__info__avatar"
                                                    />
                                                ) : (
                                                    <BlankAva
                                                        colorBg={"#CCD9E0"}
                                                        colorText={"black"}
                                                        text={"fdsfs"}
                                                    />
                                                )}

                                                <div className="news-chat__info__desc">
                                                    <h1 className="news-chat__info__name">{child.user}</h1>

                                                    <p
                                                        className="news-chat__info__date">{moment(child.created_at ? child.created_at : "").format("DD MMMM, YYYY")}</p>
                                                </div>
                                            </div>

                                            <div className="news-chat__top__buttons">
                                                {/*{isExpert && (*/}
                                                {/*    <div className="news-chat__top__buttons__item"*/}
                                                {/*         onClick={() => handlePickExpert(+item.id, item.is_expert === 0 ? 1 : 0)}*/}
                                                {/*    >*/}
                                                {/*        <StarIcon />*/}
                                                {/*    </div>*/}
                                                {/*)}*/}
                                                {/*{isClip && (*/}
                                                {/*    <div className="news-chat__top__buttons__item">*/}
                                                {/*        <PaperClipSmallIcon />*/}
                                                {/*    </div>*/}
                                                {/*)}*/}

                                                <div className="news-chat__top__buttons__item"
                                                     onClick={() => setSelectedCommentId(child.id)}>
                                                    <TrashIcon />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {child.attachments ? (
                                                <div className="news-chat-child__attachment">
                                                    {child.attachments.map((
                                                        attachment: any,
                                                        idx4: number
                                                    ) => (
                                                        <img
                                                            src={attachment?.attachment}
                                                            alt=""
                                                            key={idx4}
                                                        />
                                                    ))}
                                                </div>
                                            ) : null}

                                            <div
                                                dangerouslySetInnerHTML={{ __html: child?.content }} />
                                        </div>

                                        <div className="news-chat__buttons__likeButton"
                                             onClick={() => handleLike(child.id)}
                                        >
                                            <div className="icon"
                                                 style={{ display: "flex", alignItems: "center" }}>
                                                <LikeIcon
                                                    color={!child.is_liked ? "rgba(0, 0, 0, 0.5)" : "#00A7B5"} />
                                            </div>

                                            <p>{child.likes_count}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : null}

                    {isChildCommentOpen === idx && (
                        <div className="news-chat-child__block">
                            <div className="news-chat-child__leftRow"></div>

                            <div className="news-chat-child__right">
                                <div className="news-messenger__top__textarea">
                                   <textarea
                                       ref={textareaRef}
                                       value={text}
                                       onChange={handleInputChange}
                                       placeholder="Оставить комментарий"
                                       className="news-messenger__top__input"
                                   />

                                    <div className="news-messenger__top__textarea__buttons">
                                        <label
                                            className="news-messenger__top__textarea__buttons__imageButton">
                                            {/*<PaperClipIcon color={isMobile ? "#00A7B5" : "#000"} />*/}

                                            <input
                                                type="file"
                                                accept=".png, .jpg"
                                                // onChange={(event) => handleGetCommentImages(event, item.id, isChildCommentOpen)}
                                                hidden
                                            />
                                        </label>

                                        {isMobile ? (
                                            <MessengerSendIcon />
                                        ) : (
                                            <div
                                                className="news-messenger__top__textarea__buttons__enterButton"
                                                onClick={() => handlePostComment(item.id)}
                                            >
                                                Опубликовать
                                            </div>
                                        )}
                                    </div>

                                    {showDropdown && usersList.length > 0 && (
                                        <div className="news-messenger__top__textarea__users">
                                            {usersList.map((item, idx) => (
                                                <div className="layout-vacation-modal__block__right"
                                                     onClick={() => handleOptionSelect(item)}
                                                     key={idx}
                                                >
                                                    {item?.photo ? (
                                                        <img src={item?.photo} alt="" />
                                                    ) : (
                                                        <BlankAva
                                                            colorBg={"#CCD9E0"}
                                                            colorText={"black"}
                                                            text={item?.name}
                                                        />
                                                    )}

                                                    <div
                                                        className="layout-vacation-modal__block__desc">
                                                        <h1>{item?.name}</h1>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                {/*<div className="news-messenger__top__textarea__attachments">*/}
                                {/*    {attachments?.map((*/}
                                {/*        item: any,*/}
                                {/*        idx: number*/}
                                {/*    ) => (*/}
                                {/*        <div*/}
                                {/*            className="news-messenger__top__textarea__attachments__item"*/}
                                {/*            key={idx}>*/}
                                {/*            <div className="closeIcon"*/}
                                {/*                 onClick={() => handleDeleteCommentImages(idx)}>*/}
                                {/*                <CloseIcon color={"#FF0000"} />*/}
                                {/*            </div>*/}

                                {/*            <p>*/}
                                {/*                {idx !== attachments.length - 1 ? `${item?.name},` : item?.name}*/}
                                {/*            </p>*/}
                                {/*        </div>*/}
                                {/*    ))}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};