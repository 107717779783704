import Slider from 'rc-slider';
import { FC, useEffect, useRef, useState } from "react";
import { isMd } from "../../../utils";

const handleWidth = 60;
const halfHandleWidth = handleWidth / 2;

interface ITrackbar {
    value?: any,
    min?: string,
    max?: string,
    step?: any,
    onChange: any,
    disabled?: boolean
    style?: any,
}

export const Trackbar: FC<ITrackbar> = (
    {
        value,
        min,
        max,
        step,
        onChange, disabled = false,
        style = {}
    }) => {
    const isMobile = isMd();
    const containerRef = useRef<any>();

    const [isInteracting, setIsInteracting] = useState(false);

    useEffect(() => {
        if (!isInteracting) return;

        const handleEl = containerRef.current.querySelector('.rc-slider-handle');

        handleEl.innerHTML = `<div
        style="position: absolute;
            left: 50%;
            top: 50%;
            width: 24px;
            height: 24px;
            background: #00A7B5;
            border-radius: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            transform: translate(-50%, -150%);
            font-size: 12px;
            color: #fff;
            pointer-events: none;
            "
    >
        ${value || min}
    </div>`;
    }, [isInteracting, value]);


    return (
        <>
            <div ref={containerRef} style={{ marginTop: 10, ...style }}>
                <Slider
                    disabled={disabled}
                    onChange={onChange}
                    min={min ? +min : 1}
                    max={max ? +max : 10}
                    onBeforeChange={() => setIsInteracting(true)}
                    onAfterChange={() => setIsInteracting(false)}
                    value={parseInt(value || 3)}
                    dots

                    dotStyle={{
                        backgroundColor: '#D9D9D9',
                        borderRadius: '50%',
                        width: '8px',
                        height: '8px',
                    }}
                    activeDotStyle={{
                        backgroundColor: 'blue',
                        borderColor: 'blue',
                    }}
                    trackStyle={{
                        height: 1,
                        width: '100%',
                        background: 'transparent',
                    }}
                    railStyle={{
                        height: 2,
                        width: `100%`,
                        background: `#D9D9D9`,
                    }}
                    handleStyle={{
                        cursor: 'grab',
                        position: 'relative',
                        marginTop: -12,
                        height: 20,
                        width: 5,
                        background: '#00A7B5',
                        borderRadius: 4,
                        outline: 'none',
                    }}
                />

            </div>

            <div
                style={{
                    color: `#000`,
                    opacity: '0.5',
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontWeight: 500,
                    fontSize: 12,
                    marginTop: 2,
                }}
            >
                <div>{min || '0'}</div>
                <div>{max || '1'}</div>
            </div>
        </>
    )
}