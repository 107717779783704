import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getCurrentRole, getPersonData } from "../../redux";
import DialogContainer from "../ui/dialog/DialogContainer";
import Button from "../ui/form/Buttons/Button";
import { LocationIcon } from "../../img/icons/LocationIcon";
import { getDateByFormat } from "../../utils";
import { BlankImage } from "../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface EventsCalendarModalModalProps {
    isOpen: boolean;
    onClose: (value: boolean) => void;
    setIsOpen: (value: boolean) => void;
    data?: any | null;
}

const EventsCalendarModal = (
    {
        data,
        isOpen,
        onClose,
        setIsOpen
    }: EventsCalendarModalModalProps
) => {
    const navigate = useNavigate();

    const userData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const [isStatusComplete, setIsStatusComplete] = useState(false);

    const validDate = moment(data?.start_date).valueOf() > moment().valueOf()

    const filteredSchedule = (schedule: any[], key: "date" | "time") => {
        return [...schedule].filter((item, index, self) =>
            index === self.findIndex(t => t[key] === item[key])
        )
    };

    const [trainingTimeList, setTrainingTimeList] = useState<any[]>([]);

    useEffect(() => {
        if (data?.schedules) {
            const updateList = [...filteredSchedule(data?.schedules, "time")].map(item => ({
                ...item,
                text: item.time || "",
                disabled: true
            }));

            setTrainingTimeList(updateList);
        }
    }, [data, isOpen]);

    function handleCloseModal() {
        onClose(false);
        setIsStatusComplete(false);
    }

    function onSubmit(request: any) {
        const findItem = data?.schedules.find((item: any) => (item.date === request.date) && (item.time === request.time))

        if (findItem) {
            // $joinEnrollSport(isAdmin, {
            //     user_id: isAdmin ? userData.id : undefined,
            //     schedule_id: findItem.id,
            // }, { newApi: true }).then(res => {
            //     setIsLoading(false);
            //     if (res.error || res.response) return;
            //
            //     setIsStatusComplete(true);
            // })
        }
    }

    function handleOpenConfirmPopup() {
        data?.type === 'with_interview' && navigate(`?poll_id=${data?.interview_id}&event_id=${data?.id}`)

        handleCloseModal();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                setIsOpen(false);
                setIsStatusComplete(false);
            }}
            isShowCloseBtn={!isStatusComplete}
            widthProps={427}
            label={!isStatusComplete && data?.title || "Запись"}
        >
            {isStatusComplete ? (
                <div className={"sport__view__modal"}>
                    <div className={"sport__view__modal__content-complete"}>
                        <span>💪</span>

                        <h3>Поздравляем</h3>

                        <p>
                            Вы успешно записались на мероприятие. Ждем вас!
                        </p>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        <Button
                            text={"Отлично"}
                            className={"btn btn-light w-100"}
                            onClick={handleCloseModal}
                        />
                    </div>
                </div>
            ) : (
                <div className={"sport__view__modal"}>
                    <div className="">
                        <div className="events-show-editor__content">
                            <div className="events-show-info__content">
                                <div className="events-show-info__category">
                                    {data?.category?.title ? data.category.title : "Без категории"}
                                </div>

                                <div className="mt-3"
                                     dangerouslySetInnerHTML={{ __html: data?.description }} />
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Дата проведения
                            </h2>

                            <div className="events-show-extra__desc">
                                {getDateByFormat(data?.start_date, "HH:mm, DD MMMM")}
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Место проведения
                            </h2>

                            <div className="events-show-extra__desc">
                                <LocationIcon />
                                {data?.location}
                            </div>
                        </div>

                        <div className="events-show-extra">
                            <h2 className="events-show-extra__title">
                                Организатор
                            </h2>

                            <div className="events-show-author w-100">
                                <BlankImage image={data?.author?.image} />

                                <div className="events-show-author__content">
                                    <div className="events-show-author__name">
                                        {data?.author?.name}
                                    </div>
                                    <div className="events-show-author__role">
                                        {data?.author?.job_title}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"sport__view__modal__btn"}>
                        {(!data?.is_participating && validDate) && (
                            <Button
                                text={"Записаться"}
                                className={"btn btn-light accordion__button accordion__action ml-0"}
                                onClick={handleOpenConfirmPopup}
                            />
                        )}

                        <Button
                            text={"Перейти в мероприятие"}
                            className={"btn btn-light accordion__button accordion__action ml-0"}
                            onClick={() => {
                                handleCloseModal();

                                navigate(`/events/show/${data?.id}`);
                            }}
                        />
                    </div>
                </div>
            )}
        </DialogContainer>
    );
};

export default EventsCalendarModal;