import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { GetNewsItemResponseData } from "../news/interface";
import { IBlogCommentsMessenger, IBlogReq, IReqBlogComments } from "./interface";
import { SportItemResponseData } from "../sport/interface";

export const $getBlogs = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get( `api/v1/blog/content`, {
        handler,
    })

export const $getDashboardBlogs = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get( `backend/v1/blog/content`, {
    handler,
})

export const $getDashboardBlogsItem = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get( `backend/v1/blog/content/${id}`, {
    handler,
})

export const $getDraftBlogs = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get( `api/v1/blog/drafts`, {
    handler,
})

export const $getBlogsItem = (
    id: number,
    handler?: IApiHandlerParams,
    isIncrement?: boolean | number,
): Promise<any> => api
.get( `api/v1/blog/content/${id}${isIncrement ? '?increment_count=1' : ''}`, {
    handler,
})

export const $createBlogs = (
    data: IBlogReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/blog/content", data, {
    handler
});

export const $editBlogs = (
    id: number,
    data: IBlogReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`api/v1/blog/content/${id}`, data, {
    handler
});

export const $deleteBlogs = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`api/v1/blog/content/${id}`, {
    handler
});

export const $editDashboardBlogs = (
    id: number,
    data: IBlogReq,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`backend/v1/blog/content/${id}`, data, {
    handler
});

export const $deleteDashboardBlogs = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`backend/v1/blog/content/${id}`, {
    handler
});

export const $toggleFavouriteBlog = (
    id: number,
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.get(`api/v1/blog/toggle/favourite/${id}`,{
    handler
});

export const $toggleLikeBlog = (
    id: number,
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.get(`api/v1/blog/toggle/like/${id}`,{
    handler
});


// comment-messenger

export const $getBlogComments = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<IBlogCommentsMessenger> => api
.get(`api/v1/blog/comments?blog_id=${id}`,{
    handler
});

export const $postBlogComments = (
    data: IReqBlogComments,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/blog/comments", data, {
    handler
});

export const $deleteBlogComments = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`api/v1/blog/comments/${id}`, {
    handler
});

export const $handleLikeBlogsComments = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/blog/comments/toggle/like/${id}`, {
    handler
});

export const $toggleNotifyBlogComment = (
    id: number,
    handler?: IApiHandlerParams
): Promise<GetNewsItemResponseData> => api
.get(`api/v1/blog/toggle/notify/${id}`,{
    handler
});




// categories


export const $getBlogsCategories = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get( `api/v1/blog/categories/list`, {
    handler,
})

export const $createBlogCategory = (
    data: any,
    route: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`backend/v1/${route}/categories`, data, {
    handler
});


export const $editBlogCategory = (
    id: number,
    data: any,
    route: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`backend/v1/${route}/categories/${id}`, data, {
    handler
});

export const $deleteBlogCategory = (
    id: number | string,
    route: string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`backend/v1/${route}/categories/${id}`, {
    handler
});

