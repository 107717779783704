import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { routeLinks } from "../../../routes/routeLinks";

export const initialStateBlogsReducer: any = {
    moderationListLength: 0
}

const blogsReducer = createSlice({
    name: 'blogs',
    initialState: initialStateBlogsReducer,
    reducers: {
        changeModerationListLength: (state, action) => {
            state.moderationListLength = action.payload
        },
    },
})

export const {  changeModerationListLength} = blogsReducer.actions

export default blogsReducer.reducer