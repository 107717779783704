import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $getEmployees = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/booking/vacations/users`, {
        mock: require('./mocks/employees.favorites.json'),
        handler,
    })

export const $getNewEmployees = (
    requestData: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/newemployees`, {
        request: {
            params: requestData
        },
        handler: {
            newApi: true,
            ...handler
        }
    })
    
export const $getStruct = (
    handler?: IApiHandlerParams
): Promise<any> => api
    .get(`api/v1/newemployees/struct`, {
        handler: {
            newApi: true,
            ...handler
        }
    })