import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { GetNewsItemResponseData, NewsCreateRequestData } from "../news/interface";

export const $getMediaFiles = (
    handler?: IApiHandlerParams
): Promise<any> => api.get("api/v1/mediaGalleries", {
    handler
});

export const $toggleMediaFileFavorite = (
    id: number,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/mediaGalleries/favourite/toggle/${id}`, {
    handler
});

export const $getAllMediaFiles = (
    handler?: IApiHandlerParams
): Promise<any> => api.get("backend/v1/mediaGalleries/content", {
    handler
});

export const $getMediaFileItem = (
    id: string | undefined,
    incrementShow: boolean,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`api/v1/mediaGalleries/show/${id}${incrementShow ? '?increment_count=1' : ''}`, {
    handler
});

export const $getAdminMediaFileItem = (
    id: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`backend/v1/mediaGalleries/content/${id}`, {
    handler
});

export const $createMediaFile = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("backend/v1/mediaGalleries/content", data, {
    handler
});

export const $editMediaFile = (
    id: string | undefined,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.put(`backend/v1/mediaGalleries/content/${id}`, data, {
    handler
});

export const $deleteMediaFile = (
    id: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api
.delete(`backend/v1/mediaGalleries/content/${id}`, {
    handler
});

export const $getMediaFileCategories = (
    handler?: IApiHandlerParams
): Promise<any> => api.get("api/v1/mediaGalleries/category/list", {
    handler
});

export const $getObjectsOfMediaFile = (
    handler?: IApiHandlerParams
): Promise<any> => api.get("backend/v1/mediaGalleries/files/list", {
    handler
});

export const $addObjectToMediaFile = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api.post("backend/v1/mediaGalleries/files/upload",data, {
    handler
});

export const $deleteObjectOfMediaFile = (
    id: number,
    handler?: IApiHandlerParams
): Promise<any> => api.delete(`backend/v1/mediaGalleries/files/delete/${id}`, {
    handler
});


