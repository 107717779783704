import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const BackIcon: FC<IBaseIconProps>= ({size= 24, color= '#00A7B5'}) => {
    return (
        <svg width={size} height={size} viewBox="0 0 25 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M15.5 17L10.5 12L15.5 7" stroke={color} strokeWidth="2" strokeLinecap="round"
                  strokeLinejoin="round" />
        </svg>
    )
}