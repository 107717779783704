export const IconSecondPlace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20"
             fill="none">
            <path
                d="M6.2334 7.96925L8.38631 7.86855C8.92814 7.87335 9.21583 7.86855 9.29734 8.31927L9.76245 11.6901C9.77683 11.8291 9.98781 12.0113 10.0358 12.0929C10.17 11.9874 10.2515 11.8196 10.2276 11.6373L9.83917 8.51107C9.74327 7.76306 9.10555 7.19727 8.34795 7.19727H6.24299V7.96445L6.2334 7.96925Z"
                fill="#DFF0FC" />
            <path
                d="M6.23811 7.23526V7.99765L6.4347 8.00724L7.46081 7.96409L8.36225 7.92573C8.4054 7.92573 8.45335 7.92573 8.49171 7.92573C8.80818 7.92573 9.02874 7.95929 9.14382 8.12712C9.18697 8.18466 9.22533 8.29014 9.24451 8.39563L9.41713 9.66149L9.6281 11.2102C9.6281 11.2102 9.70962 11.7425 9.724 11.7473C9.73839 11.8096 9.78634 11.8767 9.84387 11.9391C9.911 12.011 9.98293 12.0733 10.0117 12.1069C10.146 12.0014 10.2275 11.8336 10.2035 11.6562L10.0069 10.0978L9.8151 8.53948C9.71921 7.79147 9.08148 7.23047 8.32868 7.23047H6.22852L6.23811 7.23526Z"
                fill="#DEEEFB" />
            <path
                d="M6.23828 7.26172V8.01931L6.41569 8.05288L7.4418 8.01452L8.34324 7.98095C8.3816 7.98095 8.43914 7.98095 8.47271 7.98095C8.78438 7.98095 9.00015 8.0289 9.11043 8.20632C9.14879 8.25906 9.18715 8.37893 9.20153 8.47483L9.37415 9.73589L9.58512 11.2798C9.58512 11.3134 9.67623 11.7881 9.705 11.8025C9.72418 11.86 9.77692 11.9224 9.83446 11.9751C9.90159 12.0374 9.97831 12.0902 10.0119 12.119C10.1461 12.0135 10.2228 11.8456 10.1989 11.6682L10.0071 10.1195L9.81528 8.57073C9.71938 7.82752 9.08645 7.26651 8.33845 7.26651H6.24787L6.23828 7.26172Z"
                fill="#DCEDFA" />
            <path
                d="M6.23347 7.29883V8.05642L6.3869 8.10917L7.41302 8.0756L8.30966 8.04683C8.34323 8.04683 8.41035 8.04683 8.43912 8.04683C8.7412 8.04683 8.95697 8.11396 9.05767 8.29617C9.09602 8.34412 9.12959 8.47837 9.14397 8.56468L9.3118 9.82095L9.51798 11.3601C9.52277 11.4129 9.61387 11.8492 9.65703 11.8684C9.681 11.9211 9.73375 11.9787 9.79608 12.0266C9.86801 12.0841 9.94472 12.1273 9.97829 12.1417C10.1125 12.0362 10.1893 11.8684 10.1653 11.691L9.97349 10.147L9.7817 8.60304C9.6858 7.85983 9.05767 7.30362 8.30966 7.30362H6.22388L6.23347 7.29883Z"
                fill="#DBEBF9" />
            <path
                d="M6.2334 7.32812V8.08093L6.36766 8.15764L7.39377 8.12887L8.29041 8.1049C8.31918 8.1049 8.3959 8.1049 8.41988 8.1049C8.71716 8.1049 8.92814 8.18641 9.01924 8.37341C9.0528 8.41177 9.08637 8.57001 9.10075 8.64193L9.26857 9.8934L9.47476 11.4278C9.48435 11.4949 9.57545 11.8977 9.63778 11.9216C9.66655 11.9696 9.72409 12.0223 9.78642 12.0607C9.85835 12.1086 9.93507 12.1422 9.97822 12.1518C10.1125 12.0463 10.1892 11.8785 10.1652 11.7059L9.97342 10.1667L9.78163 8.62754C9.69053 7.88913 9.0576 7.33292 8.31439 7.33292H6.23819L6.2334 7.32812Z"
                fill="#DAE9F8" />
            <path
                d="M6.2334 7.3544V8.1072L6.34848 8.2031L7.36979 8.17913L8.26644 8.15515C8.29041 8.15515 8.37193 8.15515 8.3959 8.15515C8.6836 8.15515 8.89457 8.25105 8.98088 8.44764C9.00965 8.48121 9.04321 8.65382 9.0528 8.71616L9.22062 9.96283L9.42201 11.4876C9.4316 11.5739 9.53229 11.9383 9.60901 11.9671C9.64258 12.0151 9.70491 12.0582 9.76724 12.0918C9.84396 12.1301 9.92068 12.1541 9.96384 12.1541C10.0981 12.0486 10.1748 11.8856 10.1508 11.7082L9.95904 10.1738L9.76724 8.63944C9.67614 7.90582 9.04801 7.34961 8.3048 7.34961H6.2334V7.3544Z"
                fill="#D9E8F7" />
            <path
                d="M6.2334 7.3837V8.13171L6.3293 8.24678L7.35061 8.22281L8.24726 8.20363C8.24726 8.20363 8.35754 8.20363 8.37672 8.20363C8.65962 8.20363 8.8706 8.31391 8.94252 8.5153C8.9665 8.54407 9.00006 8.73107 9.00965 8.78381L9.17268 10.0305L9.37406 11.5553C9.38845 11.656 9.48914 11.9868 9.58024 12.0204C9.6186 12.0635 9.68094 12.1019 9.74806 12.1259C9.82478 12.1546 9.9063 12.1738 9.94945 12.1642C10.0789 12.0635 10.1604 11.8957 10.1365 11.7183L9.94466 10.1887L9.75286 8.66394C9.66176 7.93032 9.03362 7.37891 8.29521 7.37891H6.2334V7.3837Z"
                fill="#D7E6F6" />
            <path
                d="M6.2334 7.41211V8.16011L6.31012 8.29917L7.33143 8.27999L8.22328 8.2656C8.22328 8.2656 8.33836 8.2656 8.35275 8.2656C8.62606 8.2656 8.83703 8.39027 8.90416 8.60125C8.92334 8.62522 8.95691 8.82661 8.9617 8.86976L9.12473 10.1116L9.32132 11.6268C9.3357 11.7467 9.44599 12.0392 9.55147 12.0776C9.59463 12.1159 9.66176 12.1495 9.72888 12.1687C9.8104 12.1926 9.88712 12.1974 9.93986 12.1782C10.0693 12.0776 10.1508 11.9097 10.1269 11.7371L9.93507 10.2171L9.74327 8.69714C9.65217 7.96832 9.02883 7.4169 8.29041 7.4169H6.2334V7.41211Z"
                fill="#D6E5F5" />
            <path
                d="M6.23331 7.4462V8.18941L6.29085 8.34764L7.30737 8.33326L8.19922 8.32367C8.19922 8.32367 8.3143 8.32367 8.32389 8.32367C8.5924 8.32367 8.79858 8.46272 8.85612 8.67849C8.87051 8.69767 8.90407 8.91824 8.90887 8.94701L9.0671 10.1841L9.26369 11.6945C9.28287 11.8287 9.39315 12.0877 9.51303 12.1308C9.56577 12.1644 9.6329 12.1932 9.70003 12.2027C9.78154 12.2171 9.86305 12.2123 9.9158 12.1884C10.0453 12.0877 10.122 11.9198 10.098 11.7472L9.911 10.232L9.71921 8.71685C9.6281 7.98803 9.00477 7.44141 8.27594 7.44141H6.22852L6.23331 7.4462Z"
                fill="#D5E3F4" />
            <path
                d="M6.2334 7.47461V8.21782L6.27176 8.40003L7.28828 8.39044L8.18013 8.38085H8.3048C8.56372 8.38085 8.7699 8.53428 8.81785 8.75964C8.82744 8.76923 8.86101 9.00898 8.8658 9.03295L9.02403 10.2652L9.21583 11.7708C9.23501 11.9243 9.35009 12.1448 9.48914 12.1928C9.54668 12.2264 9.61381 12.2455 9.68573 12.2503C9.77204 12.2551 9.85355 12.2407 9.9063 12.2072C10.0358 12.1065 10.1125 11.9435 10.0885 11.766L9.9015 10.2557L9.7145 8.74526C9.6234 8.02123 9.00485 7.47461 8.27603 7.47461H6.2334Z"
                fill="#D4E1F3" />
            <path
                d="M6.2334 7.50373V8.24215L6.25258 8.44353H7.2691L8.16095 8.43394H8.28562C8.53495 8.43394 8.74113 8.60176 8.78429 8.83192C8.78429 8.83671 8.82265 9.09084 8.82265 9.10523L8.97608 10.3327L9.16788 11.8335C9.19186 12.0014 9.31173 12.1884 9.46037 12.2411C9.5227 12.2699 9.59463 12.2843 9.66655 12.2795C9.75765 12.2795 9.83437 12.2555 9.89671 12.2075C10.0262 12.1068 10.1029 11.9438 10.0789 11.7712L9.89191 10.2656L9.70491 8.76C9.61381 8.03597 8.99526 7.49414 8.27123 7.49414H6.23819L6.2334 7.50373Z"
                fill="#D2E0F2" />
            <path
                d="M6.2334 8.48934H8.26164C8.50618 8.48934 8.70757 8.67155 8.74113 8.91129L8.92814 10.4073L9.11514 11.9033C9.1487 12.167 9.38365 12.354 9.64737 12.3205C9.73848 12.3109 9.81999 12.2773 9.88232 12.2246C10.0118 12.1239 10.0885 11.9609 10.0645 11.7882L9.87753 10.2922L9.69053 8.79622C9.59942 8.07698 8.98567 7.53516 8.26164 7.53516H6.2334V8.49414V8.48934Z"
                fill="#D1DEF1" />
            <path
                d="M9.62828 8.80011C9.53718 8.08567 8.92343 7.5918 8.20899 7.5918H6.50679L6.23828 7.61577V7.69728L6.30061 8.49324H7.92609C7.92609 8.49324 8.28571 8.49324 8.2905 8.50283C8.45353 8.50762 8.59737 8.59873 8.67409 8.73298C8.70766 8.78573 8.73643 8.86245 8.74602 8.93437L8.92822 10.4064L8.94261 10.5167L9.11043 11.8736C9.12961 12.0271 9.2303 12.1901 9.36456 12.2524C9.43648 12.3004 9.518 12.3148 9.6043 12.3052C9.69061 12.2956 9.77213 12.262 9.83925 12.2141C9.95913 12.123 10.0263 11.96 10.0023 11.7825C10.0023 11.7538 9.82487 10.3489 9.81528 10.3105C9.80569 10.2386 9.62348 8.80491 9.62348 8.80491L9.62828 8.80011Z"
                fill="#CAD6E8" />
            <path
                d="M9.56586 8.80316C9.47475 8.09351 8.8658 7.64759 8.15136 7.65238H6.47794L6.2334 7.69554V7.77705L6.35807 8.49149H7.95477C7.95477 8.49149 8.3 8.49629 8.31439 8.51067C8.47262 8.52505 8.60688 8.61616 8.6788 8.75042C8.71237 8.79836 8.73634 8.88467 8.74593 8.94701L8.92814 10.3951L8.94252 10.5054L9.11034 11.8383C9.12952 11.9774 9.22542 12.1644 9.3405 12.2171C9.40763 12.2747 9.47955 12.2891 9.56586 12.2795C9.64737 12.2699 9.72409 12.2363 9.79601 12.1932C9.9063 12.1116 9.96863 11.9534 9.94466 11.7712C9.93986 11.7137 9.77683 10.4047 9.76245 10.3231C9.74327 10.1793 9.56586 8.80795 9.56586 8.80795V8.80316Z"
                fill="#C3CFDF" />
            <path
                d="M9.49873 8.80311C9.40763 8.09826 8.80347 7.70508 8.09382 7.70508H6.44917L6.2334 7.77221V7.85372L6.4204 8.49144H7.98833C8.0171 8.49144 8.31918 8.50103 8.33836 8.51542C8.4918 8.5346 8.61647 8.6305 8.6836 8.76475C8.71236 8.80791 8.73634 8.9038 8.74593 8.95655L8.92334 10.3806L8.93772 10.4909L9.10075 11.7999C9.11514 11.9246 9.20624 12.1356 9.31173 12.1835C9.37406 12.2506 9.44119 12.265 9.51791 12.2554C9.59463 12.2459 9.67135 12.2123 9.74806 12.1691C9.84876 12.0972 9.9015 11.939 9.87753 11.752C9.86794 11.6705 9.7145 10.4526 9.69532 10.3279C9.66176 10.1121 9.49393 8.80791 9.49393 8.80791L9.49873 8.80311Z"
                fill="#BDC7D7" />
            <path
                d="M9.43631 8.80896C9.3452 8.10891 8.74105 7.76367 8.0362 7.76367H6.42031L6.22852 7.84998V7.93149L6.48265 8.4877H8.02181C8.06017 8.4877 8.33348 8.50209 8.36225 8.52127C8.5061 8.55004 8.62597 8.64593 8.6883 8.78019C8.71228 8.81855 8.73625 8.92404 8.74584 8.97199L8.91846 10.3721L8.93284 10.4776L9.09107 11.7578C9.10546 11.8681 9.19177 12.0983 9.28287 12.1414C9.34041 12.2181 9.39795 12.2373 9.47467 12.2229C9.54659 12.2133 9.62331 12.1798 9.70482 12.1414C9.79592 12.0743 9.83908 11.9209 9.8151 11.7291C9.80551 11.6188 9.66167 10.4968 9.63769 10.3289C9.59454 10.0413 9.43151 8.79937 9.43151 8.79937L9.43631 8.80896Z"
                fill="#B6C0CE" />
            <path
                d="M9.37406 8.8109C9.28775 8.11564 8.6788 7.81836 7.98354 7.81836H6.39643L6.2334 7.92864V8.01016L6.54986 8.48965H8.06026C8.10821 8.48965 8.35754 8.50403 8.3959 8.5328C8.53495 8.56636 8.65003 8.66706 8.70278 8.80132C8.72195 8.83488 8.74593 8.94996 8.75552 8.98832L8.92814 10.3597L8.94252 10.4651L9.10075 11.7214C9.11514 11.8125 9.19185 12.0714 9.27337 12.1098C9.32611 12.1961 9.37406 12.2153 9.45078 12.2057C9.51791 12.1961 9.58983 12.1625 9.67614 12.129C9.75765 12.0714 9.79601 11.918 9.77204 11.7262C9.75765 11.5872 9.62819 10.561 9.59942 10.3501C9.54668 9.99045 9.38845 8.8109 9.38845 8.8109H9.37406Z"
                fill="#AFB8C5" />
            <path
                d="M9.31173 8.81984C9.22542 8.12458 8.62126 7.88004 7.926 7.88483C7.90203 7.88483 7.80133 7.88483 7.77256 7.88483H6.36766L6.2334 8.0143V8.1006L6.6122 8.49858H8.09382C8.15136 8.49858 8.37672 8.51776 8.41988 8.55133C8.55413 8.59448 8.65962 8.69517 8.70757 8.82943C8.72675 8.8582 8.75072 8.97807 8.75552 9.01164L8.92334 10.359L8.93772 10.4645L9.09116 11.692C9.10075 11.7687 9.17747 12.0516 9.2446 12.0804C9.29255 12.1763 9.3357 12.1955 9.40283 12.1859C9.46517 12.1763 9.53709 12.1427 9.62819 12.1091C9.70012 12.0612 9.72889 11.9126 9.70491 11.7112C9.68573 11.5433 9.56586 10.6131 9.53229 10.3638C9.46996 9.93226 9.31652 8.81984 9.31652 8.81984H9.31173Z"
                fill="#A8B1BC" />
            <path
                d="M9.2446 8.82172C9.15829 8.13125 8.55893 7.93945 7.86846 7.93945C7.83969 7.93945 7.74859 7.93945 7.71982 7.93945H6.34368L6.2334 8.09289V8.1792L6.67453 8.49566H8.12739C8.19451 8.49566 8.3959 8.51964 8.44385 8.5532C8.56852 8.60115 8.66921 8.70664 8.71237 8.8409C8.72675 8.86487 8.75072 8.99433 8.75552 9.0231L8.91855 10.3465L8.93293 10.452L9.08157 11.6555C9.09116 11.7178 9.15829 12.0199 9.21104 12.0439C9.2494 12.1542 9.28775 12.1734 9.35488 12.159C9.41242 12.1494 9.47955 12.1158 9.57545 12.087C9.63299 12.0487 9.65696 11.9 9.63299 11.6939C9.61381 11.4973 9.50352 10.663 9.46037 10.3705C9.38845 9.867 9.2398 8.81692 9.2398 8.81692L9.2446 8.82172Z"
                fill="#A1A9B3" />
            <path
                d="M9.18218 8.82757C9.09587 8.14189 8.49651 7.99805 7.81083 7.99805C7.77727 7.99805 7.69576 7.99805 7.66219 7.99805H6.31003L6.22852 8.17066V8.25697L6.73198 8.49672H8.15607C8.23279 8.49672 8.4054 8.52069 8.46774 8.56385C8.58761 8.62138 8.68351 8.72687 8.71707 8.86113C8.72666 8.87551 8.75063 9.01936 8.75063 9.03854L8.91366 10.338L8.92805 10.4387L9.07669 11.6134C9.08148 11.6614 9.14861 11.9874 9.18697 12.0066C9.22054 12.1265 9.24931 12.1456 9.31164 12.1313C9.36438 12.1217 9.43151 12.0881 9.5322 12.0641C9.58015 12.0354 9.59933 11.8867 9.57536 11.6805C9.55138 11.4552 9.45549 10.7168 9.40754 10.3811C9.32123 9.80573 9.18218 8.82277 9.18218 8.82277V8.82757Z"
                fill="#9AA2AA" />
            <path
                d="M9.12002 8.82879C9.03371 8.14791 8.43435 8.05201 7.75827 8.05681C7.71991 8.05681 7.65278 8.05681 7.61442 8.05681H6.29103L6.23828 8.2534V8.33971L6.80408 8.49794H8.1994C8.28571 8.49794 8.43435 8.52671 8.50148 8.57466C8.61655 8.63699 8.70286 8.74727 8.73163 8.88153C8.73643 8.89112 8.7604 9.04456 8.7604 9.05894L8.91863 10.3344L8.93302 10.4351L9.07686 11.5859C9.07686 11.6146 9.1392 11.9694 9.16797 11.979C9.19674 12.1085 9.21592 12.1325 9.27346 12.1181C9.3214 12.1085 9.38853 12.0749 9.49402 12.0558C9.53238 12.0366 9.54197 11.8879 9.518 11.677C9.48923 11.4276 9.40771 10.7755 9.35497 10.4015C9.25907 9.7542 9.12481 8.83358 9.12481 8.83358L9.12002 8.82879Z"
                fill="#949AA2" />
            <path
                d="M9.0576 8.83085C8.97129 8.15477 8.37672 8.10682 7.70543 8.11161C7.66228 8.11161 7.60474 8.11161 7.56159 8.11161H6.26217L6.2334 8.32739V8.41369L6.86153 8.49521H8.22808C8.32398 8.49521 8.44864 8.52877 8.52057 8.58152C8.63085 8.64864 8.70757 8.76372 8.73155 8.89798C8.73155 8.90277 8.75552 9.0658 8.76031 9.0706L8.91855 10.3221L8.93293 10.4228L9.07198 11.5496C9.07198 11.5496 9.12952 11.9379 9.14391 11.9427C9.16788 12.0818 9.18227 12.1058 9.23501 12.0914C9.27816 12.0818 9.3405 12.0482 9.45078 12.0291C9.47955 12.0195 9.47955 11.8756 9.45557 11.655C9.42681 11.3769 9.35488 10.8207 9.29255 10.4036C9.18706 9.68434 9.0576 8.82606 9.0576 8.82606V8.83085Z"
                fill="#8D9399" />
            <path
                d="M6.2334 8.49204H8.26164C8.50618 8.49204 8.70757 8.67425 8.74113 8.91399L8.92814 10.41L9.11514 11.906C9.1487 12.1697 9.13911 12.0403 9.40283 12.0115C9.45078 12.0067 9.34529 11.2108 9.23021 10.4196C9.11514 9.62844 8.99047 8.83728 8.99047 8.83728C8.89937 8.11804 8.22808 8.17078 7.50405 8.17078H6.2334V8.49684V8.49204Z"
                fill="#868B90" />
            <path
                d="M7.17313 7.96925L5.02022 7.86855C4.47839 7.87335 4.1907 7.86855 4.10918 8.31927L3.64408 11.6901C3.62969 11.8291 3.41872 12.0113 3.37077 12.0929C3.23651 11.9874 3.155 11.8196 3.17897 11.6373L3.56736 8.51107C3.66326 7.76306 4.30098 7.19727 5.05857 7.19727H7.16354V7.96445L7.17313 7.96925Z"
                fill="#DFF0FC" />
            <path
                d="M7.16833 7.23526V7.99765L6.97174 8.00724L5.94563 7.96409L5.04418 7.92573C5.00103 7.92573 4.95308 7.92573 4.90993 7.92573C4.59346 7.92573 4.3729 7.95929 4.25782 8.12712C4.20987 8.18466 4.17631 8.29014 4.15713 8.39563L3.98451 9.66149L3.77354 11.2102C3.77354 11.2102 3.69202 11.7425 3.67764 11.7473C3.66325 11.8096 3.6153 11.8767 3.55776 11.9391C3.49064 12.011 3.41871 12.0733 3.38515 12.1069C3.25089 12.0014 3.16938 11.8336 3.19815 11.6562L3.38994 10.0978L3.58653 8.53948C3.68243 7.79147 4.32015 7.23047 5.06816 7.23047H7.16833V7.23526Z"
                fill="#DEEEFB" />
            <path
                d="M7.16832 7.26172V8.01931L6.99091 8.05288L5.9648 8.01452L5.06335 7.98095C5.02499 7.98095 4.96746 7.98095 4.93389 7.98095C4.62222 7.98095 4.40645 8.0289 4.29617 8.20632C4.25781 8.25906 4.21945 8.37893 4.20507 8.47483L4.03245 9.73589L3.82147 11.2798C3.82147 11.3134 3.73037 11.7881 3.7016 11.8025C3.68242 11.86 3.62968 11.9224 3.57214 11.9751C3.50501 12.0374 3.42829 12.0902 3.39473 12.119C3.26047 12.0135 3.18375 11.8456 3.20773 11.6682L3.39952 10.1195L3.59132 8.57073C3.68722 7.82752 4.32014 7.26651 5.06815 7.26651H7.15873L7.16832 7.26172Z"
                fill="#DCEDFA" />
            <path
                d="M7.17315 7.29883V8.05642L7.01492 8.10917L5.98881 8.0756L5.09216 8.04683C5.0586 8.04683 4.99147 8.04683 4.9627 8.04683C4.66062 8.04683 4.44485 8.11396 4.34416 8.29617C4.3058 8.34412 4.27223 8.47837 4.25785 8.56468L4.09003 9.82095L3.88385 11.3601C3.87905 11.4129 3.78795 11.8492 3.7448 11.8684C3.72082 11.9211 3.66808 11.9787 3.60574 12.0266C3.53382 12.0841 3.4571 12.1273 3.42354 12.1417C3.28928 12.0362 3.21256 11.8684 3.23653 11.691L3.42833 10.147L3.62013 8.60304C3.71602 7.85983 4.34416 7.30362 5.09216 7.30362H7.17795L7.17315 7.29883Z"
                fill="#DBEBF9" />
            <path
                d="M7.17315 7.32812V8.08093L7.03889 8.15764L6.01279 8.12887L5.11614 8.1049C5.08737 8.1049 5.01065 8.1049 4.98668 8.1049C4.68939 8.1049 4.47841 8.18641 4.38252 8.37341C4.34895 8.41177 4.31539 8.57001 4.301 8.64193L4.13318 9.8934L3.927 11.4278C3.91741 11.4949 3.82631 11.8977 3.76398 11.9216C3.73521 11.9696 3.67767 12.0223 3.61533 12.0607C3.54341 12.1086 3.46669 12.1422 3.42354 12.1518C3.28928 12.0463 3.21256 11.8785 3.23653 11.7059L3.42833 10.1667L3.62013 8.62754C3.71123 7.88913 4.34416 7.33292 5.08737 7.33292H7.16356L7.17315 7.32812Z"
                fill="#DAE9F8" />
            <path
                d="M7.1731 7.3544V8.1072L7.05803 8.2031L6.03671 8.17913L5.14006 8.15515C5.11609 8.15515 5.03458 8.15515 5.0106 8.15515C4.72291 8.15515 4.51193 8.25105 4.42562 8.44764C4.39685 8.48121 4.36329 8.65382 4.3537 8.71616L4.19067 9.96283L3.98929 11.4876C3.9797 11.5739 3.879 11.9383 3.80229 11.9671C3.76872 12.0151 3.70639 12.0582 3.64405 12.0918C3.56734 12.1301 3.49062 12.1541 3.44746 12.1541C3.31321 12.0486 3.23649 11.8856 3.26046 11.7082L3.45226 10.1738L3.64405 8.63944C3.73516 7.90582 4.36329 7.34961 5.1065 7.34961H7.1779L7.1731 7.3544Z"
                fill="#D9E8F7" />
            <path
                d="M7.1731 7.3837V8.13171L7.0772 8.24678L6.05588 8.22281L5.15924 8.20363C5.15924 8.20363 5.04896 8.20363 5.02978 8.20363C4.74688 8.20363 4.54069 8.31391 4.46398 8.5153C4.44 8.54407 4.40644 8.73107 4.39685 8.78381L4.23382 10.0305L4.03243 11.5553C4.01805 11.656 3.91736 11.9868 3.82625 12.0204C3.78789 12.0635 3.72556 12.1019 3.65843 12.1259C3.58171 12.1546 3.5002 12.1738 3.45705 12.1642C3.32758 12.0635 3.24607 11.8957 3.27004 11.7183L3.46184 10.1887L3.65364 8.66394C3.74474 7.93032 4.37287 7.37891 5.11129 7.37891H7.1731V7.3837Z"
                fill="#D7E6F6" />
            <path
                d="M7.17327 7.41211V8.16011L7.09655 8.29917L6.07524 8.27999L5.18339 8.2656C5.18339 8.2656 5.06831 8.2656 5.05393 8.2656C4.78062 8.2656 4.56964 8.39027 4.50251 8.60125C4.48333 8.62522 4.44977 8.82661 4.44497 8.86976L4.28195 10.1116L4.08535 11.6268C4.07097 11.7467 3.96069 12.0392 3.8552 12.0776C3.81204 12.1159 3.74492 12.1495 3.67779 12.1687C3.59627 12.1926 3.51955 12.1974 3.46681 12.1782C3.33735 12.0776 3.25584 11.9097 3.27981 11.7371L3.47161 10.2171L3.6634 8.69714C3.75451 7.96832 4.37784 7.4169 5.11626 7.4169H7.17327V7.41211Z"
                fill="#D6E5F5" />
            <path
                d="M7.17312 7.4462V8.18941L7.11558 8.34764L6.09906 8.33326L5.2072 8.32367C5.2072 8.32367 5.09213 8.32367 5.07774 8.32367C4.80923 8.32367 4.60305 8.46272 4.54551 8.67849C4.53112 8.69767 4.49756 8.91824 4.49276 8.94701L4.33453 10.1841L4.13794 11.6945C4.11876 11.8287 4.00848 12.0877 3.88861 12.1308C3.83586 12.1644 3.76873 12.1932 3.7016 12.2027C3.62009 12.2171 3.53858 12.2123 3.48583 12.1884C3.35637 12.0877 3.27486 11.9198 3.30363 11.7472L3.49542 10.232L3.68722 8.71685C3.77832 7.98803 4.40166 7.44141 5.13049 7.44141H7.17791L7.17312 7.4462Z"
                fill="#D5E3F4" />
            <path
                d="M7.17319 7.47461V8.21782L7.13483 8.40003L6.11831 8.39044L5.22646 8.38085H5.10179C4.84286 8.38085 4.63668 8.53428 4.58874 8.75964C4.57915 8.76923 4.54558 9.00898 4.54079 9.03295L4.38255 10.2652L4.19076 11.7708C4.17158 11.9243 4.0565 12.1448 3.91745 12.1928C3.85991 12.2264 3.79278 12.2455 3.72086 12.2503C3.63455 12.2551 3.55304 12.2407 3.50029 12.2072C3.37083 12.1065 3.29411 11.9435 3.31809 11.766L3.50509 10.2557L3.69209 8.74526C3.78319 8.02123 4.40173 7.47461 5.13056 7.47461H7.17319Z"
                fill="#D4E1F3" />
            <path
                d="M7.17312 7.50373V8.24215L7.15394 8.44353H6.13742L5.24557 8.43394H5.1209C4.86677 8.43394 4.66539 8.60176 4.62223 8.83192C4.62223 8.83671 4.58387 9.09084 4.58387 9.10523L4.43044 10.3327L4.23864 11.8335C4.21466 12.0014 4.09479 12.1884 3.94615 12.2411C3.88382 12.2699 3.81189 12.2843 3.73997 12.2795C3.65366 12.2795 3.57215 12.2555 3.50981 12.2075C3.38035 12.1068 3.30363 11.9438 3.32761 11.7712L3.51461 10.2656L3.70161 8.76C3.79271 8.03597 4.41126 7.49414 5.13529 7.49414H7.16833L7.17312 7.50373Z"
                fill="#D2E0F2" />
            <path
                d="M7.17314 8.48934H5.1449C4.90036 8.48934 4.69897 8.67155 4.66541 8.91129L4.4784 10.4073L4.2914 11.9033C4.25784 12.167 4.02289 12.354 3.75917 12.3205C3.66807 12.3109 3.58655 12.2773 3.52422 12.2246C3.39476 12.1239 3.31804 11.9609 3.34201 11.7882L3.52901 10.2922L3.71601 8.79622C3.80712 8.07698 4.42087 7.53516 5.1449 7.53516H7.17314V8.49414V8.48934Z"
                fill="#D1DEF1" />
            <path
                d="M3.7784 8.80011C3.8695 8.08567 4.48325 7.5918 5.19769 7.5918H6.89988L7.16839 7.61577V7.69728L7.10606 8.49324H5.48059C5.48059 8.49324 5.12097 8.49324 5.11138 8.50283C4.94835 8.50762 4.8093 8.59873 4.72779 8.73298C4.69422 8.78573 4.66545 8.86245 4.65586 8.93437L4.47366 10.4064L4.45927 10.5167L4.29145 11.8736C4.27227 12.0319 4.17158 12.1901 4.03732 12.2524C3.9654 12.3004 3.88388 12.3148 3.79758 12.3052C3.71127 12.2956 3.62975 12.262 3.56263 12.2141C3.44275 12.123 3.37562 11.96 3.3996 11.7825C3.3996 11.7538 3.57701 10.3489 3.5866 10.3105C3.59619 10.2386 3.7784 8.80491 3.7784 8.80491V8.80011Z"
                fill="#CAD6E8" />
            <path
                d="M3.8406 8.80316C3.9317 8.09351 4.54066 7.64759 5.2551 7.65238H6.92852L7.17306 7.69554V7.77705L7.04839 8.49149H5.45169C5.45169 8.49149 5.10645 8.49629 5.09207 8.51067C4.93384 8.52505 4.79958 8.61616 4.72766 8.75042C4.69409 8.79836 4.67012 8.88467 4.66053 8.94701L4.47832 10.3951L4.46394 10.5054L4.29612 11.8383C4.27694 11.9774 4.18104 12.1644 4.06116 12.2171C3.99404 12.2747 3.92211 12.2891 3.8358 12.2795C3.75429 12.2699 3.67757 12.2363 3.60565 12.1932C3.49536 12.1116 3.43303 11.9534 3.45701 11.7712C3.4618 11.7137 3.62483 10.4047 3.63921 10.3231C3.65839 10.1793 3.8358 8.80795 3.8358 8.80795L3.8406 8.80316Z"
                fill="#C3CFDF" />
            <path
                d="M3.90788 8.80311C3.99898 8.09826 4.60314 7.70508 5.31279 7.70508H6.95744L7.17321 7.77221V7.85372L6.98621 8.49144H5.41827C5.38951 8.49144 5.08743 8.50103 5.06825 8.51542C4.91481 8.5346 4.79014 8.6305 4.72301 8.76475C4.69425 8.80791 4.67027 8.9038 4.66068 8.95655L4.48327 10.3806L4.46888 10.4909L4.30586 11.7999C4.29147 11.9246 4.20037 12.1356 4.09488 12.1835C4.03255 12.2506 3.96542 12.265 3.88391 12.2554C3.80719 12.2459 3.73047 12.2123 3.65375 12.1691C3.55306 12.0972 3.50031 11.939 3.52429 11.752C3.53388 11.6705 3.68731 10.4526 3.70649 10.3279C3.74006 10.1121 3.90788 8.80791 3.90788 8.80791V8.80311Z"
                fill="#BDC7D7" />
            <path
                d="M3.97005 8.80896C4.05636 8.10891 4.66531 7.76367 5.37016 7.76367H6.98604L7.17784 7.84998V7.93149L6.92371 8.4877H5.38455C5.34619 8.4877 5.07288 8.50209 5.04411 8.52127C4.90026 8.55004 4.78039 8.64593 4.71805 8.78019C4.69408 8.81855 4.67011 8.92404 4.66052 8.97199L4.4879 10.3721L4.47351 10.4776L4.31528 11.7578C4.3009 11.8681 4.21459 12.0983 4.12349 12.1414C4.06595 12.2181 4.00841 12.2373 3.93169 12.2229C3.85977 12.2133 3.78305 12.1798 3.70153 12.1414C3.61043 12.0743 3.56728 11.9209 3.59125 11.7291C3.60084 11.6188 3.74469 10.4968 3.76866 10.3289C3.81182 10.0413 3.97484 8.79937 3.97484 8.79937L3.97005 8.80896Z"
                fill="#B6C0CE" />
            <path
                d="M4.03262 8.8109C4.11893 8.11564 4.72309 7.81836 5.42314 7.81836H7.01026L7.17329 7.92864V8.01016L6.85682 8.48965H5.34643C5.29848 8.48965 5.04914 8.50403 5.01078 8.5328C4.87173 8.56636 4.75665 8.66706 4.70391 8.80132C4.68473 8.83488 4.66075 8.94996 4.65116 8.98832L4.47855 10.3597L4.46416 10.4651L4.30593 11.7214C4.29634 11.8125 4.21483 12.0714 4.13332 12.1098C4.08057 12.1961 4.03262 12.2153 3.9607 12.2057C3.89357 12.1961 3.82165 12.1625 3.73534 12.129C3.65862 12.0714 3.61546 11.918 3.63944 11.7262C3.65382 11.5872 3.78329 10.561 3.81206 10.3501C3.8648 9.99045 4.02303 8.8109 4.02303 8.8109H4.03262Z"
                fill="#AFB8C5" />
            <path
                d="M4.09492 8.81984C4.18123 8.12458 4.78539 7.88004 5.48065 7.88483C5.50462 7.88483 5.60531 7.88483 5.63408 7.88483H7.03899L7.17325 8.0143V8.1006L6.79445 8.49858H5.31283C5.25529 8.49858 5.02993 8.51776 4.98677 8.55133C4.85251 8.59448 4.74703 8.69517 4.69908 8.82943C4.6799 8.8582 4.65592 8.97807 4.65113 9.01164L4.48331 10.359L4.46892 10.4645L4.31548 11.692C4.30589 11.7687 4.22918 12.0516 4.16684 12.0804C4.11889 12.1763 4.07574 12.1955 4.00861 12.1859C3.94628 12.1763 3.87435 12.1427 3.78325 12.1091C3.71133 12.0612 3.68256 11.9126 3.70653 11.7112C3.72571 11.5433 3.84079 10.6131 3.87915 10.3638C3.94148 9.93226 4.09492 8.81984 4.09492 8.81984Z"
                fill="#A8B1BC" />
            <path
                d="M4.16186 8.82172C4.24817 8.13125 4.85233 7.93945 5.538 7.93945C5.56677 7.93945 5.65787 7.93945 5.68664 7.93945H7.06278L7.17307 8.09289V8.1792L6.73193 8.49566H5.27908C5.21195 8.49566 5.01056 8.51964 4.96261 8.5532C4.83315 8.60115 4.73725 8.70664 4.6941 8.8409C4.67971 8.86487 4.65574 8.99433 4.65574 9.0231L4.49271 10.3465L4.47833 10.452L4.32969 11.6555C4.3201 11.7178 4.25297 12.0199 4.20022 12.0439C4.16186 12.1542 4.12351 12.1734 4.05638 12.159C3.99884 12.1494 3.92691 12.1158 3.83581 12.087C3.77827 12.0487 3.7543 11.9 3.77827 11.6939C3.79745 11.4973 3.90773 10.663 3.94609 10.3705C4.01802 9.867 4.16666 8.81692 4.16666 8.81692L4.16186 8.82172Z"
                fill="#A1A9B3" />
            <path
                d="M4.22422 8.82757C4.31053 8.14189 4.90989 7.99805 5.59556 7.99805C5.62912 7.99805 5.71064 7.99805 5.7442 7.99805H7.09636L7.17788 8.17066V8.25697L6.67441 8.49672H5.25033C5.17361 8.49672 5.00099 8.52069 4.93866 8.56385C4.81878 8.62138 4.72289 8.72687 4.68932 8.86113C4.67973 8.87551 4.65576 9.01936 4.65096 9.03854L4.48794 10.338L4.47355 10.4387L4.32491 11.6134C4.32011 11.6614 4.25299 11.9874 4.21463 12.0066C4.18106 12.1265 4.15229 12.1456 4.08996 12.1313C4.03722 12.1217 3.97009 12.0881 3.86939 12.0641C3.82144 12.0354 3.80226 11.8867 3.82624 11.6805C3.85021 11.4552 3.94611 10.7168 3.99406 10.3811C4.08037 9.80573 4.21942 8.82277 4.21942 8.82277L4.22422 8.82757Z"
                fill="#9AA2AA" />
            <path
                d="M4.28661 8.82879C4.37291 8.14791 4.96748 8.05201 5.64836 8.05681C5.68672 8.05681 5.75385 8.05681 5.79221 8.05681H7.1156L7.16834 8.2534V8.33971L6.60254 8.49794H5.20723C5.12092 8.49794 4.97228 8.52671 4.90515 8.57466C4.79007 8.63699 4.70376 8.74727 4.67499 8.88153C4.6702 8.89112 4.64622 9.04456 4.64143 9.05894L4.4832 10.3344L4.46881 10.4351L4.32496 11.5859C4.32496 11.6146 4.26263 11.9694 4.23386 11.979C4.20509 12.1085 4.18591 12.1325 4.12358 12.1181C4.07563 12.1085 4.0085 12.0749 3.90301 12.0558C3.86465 12.0366 3.85506 11.8879 3.87904 11.677C3.90301 11.4276 3.98932 10.7755 4.04206 10.4015C4.13796 9.7542 4.27222 8.83358 4.27222 8.83358L4.28661 8.82879Z"
                fill="#949AA2" />
            <path
                d="M4.34899 8.83085C4.4353 8.15477 5.02987 8.10682 5.70116 8.11161C5.74431 8.11161 5.80185 8.11161 5.845 8.11161H7.14442L7.17319 8.32739V8.41369L6.54506 8.49521H5.17851C5.08261 8.49521 4.95795 8.52877 4.88602 8.58152C4.77574 8.64864 4.69902 8.76372 4.67025 8.89798C4.67025 8.90277 4.64628 9.0658 4.64628 9.0706L4.48805 10.3221L4.47366 10.4228L4.33461 11.5496C4.33461 11.5496 4.27707 11.9379 4.26268 11.9427C4.23871 12.0818 4.22433 12.1058 4.17158 12.0914C4.12843 12.0818 4.06609 12.0482 3.95581 12.0291C3.92704 12.0195 3.92704 11.8756 3.95102 11.655C3.97979 11.3769 4.05171 10.8207 4.11404 10.4036C4.21953 9.68434 4.34899 8.82606 4.34899 8.82606V8.83085Z"
                fill="#8D9399" />
            <path
                d="M7.17328 8.49204H5.14504C4.9005 8.49204 4.69911 8.67425 4.66555 8.91399L4.47854 10.41L4.29154 11.906C4.25798 12.1697 4.26757 12.0403 4.00385 12.0115C3.9607 12.0067 4.06139 11.2108 4.17647 10.4196C4.29154 9.62844 4.41621 8.83728 4.41621 8.83728C4.50732 8.11804 5.1786 8.17078 5.90263 8.17078H7.17328V8.49684V8.49204Z"
                fill="#868B90" />
            <path
                d="M6.71287 19.998C9.62817 19.998 11.9873 17.6389 11.9873 14.7236C11.9873 11.8083 9.62817 9.44922 6.71287 9.44922C3.79757 9.44922 1.43848 11.8083 1.43848 14.7236C1.43848 17.6389 3.79757 19.998 6.71287 19.998Z"
                fill="url(#paint0_linear_14442_24292)" />
            <path
                d="M6.71282 19.0843C9.11986 19.0843 11.0714 17.1328 11.0714 14.7258C11.0714 12.3187 9.11986 10.3672 6.71282 10.3672C4.30577 10.3672 2.35425 12.3187 2.35425 14.7258C2.35425 17.1328 4.30577 19.0843 6.71282 19.0843Z"
                fill="url(#paint1_linear_14442_24292)" />
            <path
                d="M6.71271 18.6305C8.86562 18.6305 10.611 16.8852 10.611 14.7322C10.611 12.5793 8.86562 10.834 6.71271 10.834C4.5598 10.834 2.81445 12.5793 2.81445 14.7322C2.81445 16.8852 4.5598 18.6305 6.71271 18.6305Z"
                fill="url(#paint2_linear_14442_24292)" />
            <path
                d="M6.71287 19.998C9.62817 19.998 11.9873 17.6389 11.9873 14.7236C11.9873 11.8083 9.62817 9.44922 6.71287 9.44922C3.79757 9.44922 1.43848 11.8083 1.43848 14.7236C1.43848 17.6389 3.79757 19.998 6.71287 19.998Z"
                fill="url(#paint3_radial_14442_24292)" />
            <path
                d="M6.71282 19.0843C9.11986 19.0843 11.0714 17.1328 11.0714 14.7258C11.0714 12.3187 9.11986 10.3672 6.71282 10.3672C4.30577 10.3672 2.35425 12.3187 2.35425 14.7258C2.35425 17.1328 4.30577 19.0843 6.71282 19.0843Z"
                fill="url(#paint4_radial_14442_24292)" />
            <path
                d="M6.71282 19.0843C9.11986 19.0843 11.0714 17.1328 11.0714 14.7258C11.0714 12.3187 9.11986 10.3672 6.71282 10.3672C4.30577 10.3672 2.35425 12.3187 2.35425 14.7258C2.35425 17.1328 4.30577 19.0843 6.71282 19.0843Z"
                fill="url(#paint5_radial_14442_24292)" />
            <path
                d="M6.83743 16.155L7.3361 15.5796C7.7197 15.2008 7.9978 14.8891 8.17042 14.6589C8.34783 14.424 8.48209 14.1938 8.5636 13.9781C8.64991 13.7575 8.69306 13.5273 8.69306 13.2924C8.69306 12.7362 8.51565 12.3046 8.16083 12.0026C7.81559 11.7053 7.32172 11.5566 6.69359 11.5566C6.30041 11.5566 5.94079 11.6429 5.61953 11.8156C5.29827 11.9882 5.04414 12.2327 4.86193 12.5396C4.67973 12.8465 4.58862 13.1869 4.58862 13.5465V13.6904H6.15176V13.5465C6.15176 13.3308 6.19971 13.1533 6.3052 13.0143C6.4011 12.8848 6.51138 12.8273 6.66002 12.8273C6.80866 12.8273 6.92374 12.8704 7.00046 12.9615C7.08197 13.0574 7.12033 13.2109 7.12033 13.4075C7.12033 13.5801 7.01484 13.9109 6.50659 14.4911L4.69891 16.3899V17.4208H8.82253V16.1502H6.82784L6.83743 16.155Z"
                fill="url(#paint6_linear_14442_24292)" />
            <path
                d="M8.36232 6.31489L3.83592 0H0L4.52639 6.33407C4.69901 6.57861 4.7949 6.8711 4.7949 7.16838V8.15134H8.63083V7.154C8.63083 6.85192 8.53493 6.55943 8.36232 6.31489Z"
                fill="url(#paint7_linear_14442_24292)" />
            <path
                d="M12.1743 0H9.58983L8.90416 0.958981L8.21369 1.91796L6.49711 4.31541L5.81144 5.2744L5.06344 6.31489C4.89082 6.55943 4.79492 6.85192 4.79492 7.154V8.15134H8.63085V7.16838C8.63085 6.8711 8.72674 6.57861 8.89936 6.33407L9.65696 5.2744L10.3426 4.31541L12.0544 1.91796L13.4258 0H12.1743Z"
                fill="url(#paint8_linear_14442_24292)" />
            <path
                d="M8.15136 7.19336H5.27441C5.01069 7.19336 4.79492 7.40913 4.79492 7.67285V8.63183C4.79492 8.89555 5.01069 9.11132 5.27441 9.11132H8.15136C8.41508 9.11132 8.63085 8.89555 8.63085 8.63183V7.67285C8.63085 7.40913 8.41508 7.19336 8.15136 7.19336Z"
                fill="url(#paint9_linear_14442_24292)" />
            <defs>
                <linearGradient id="paint0_linear_14442_24292" x1="0.997345" y1="7.15246"
                                x2="10.7022" y2="20.0124" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6FCFE" />
                    <stop offset="0.35" stop-color="#D1DFF5" />
                    <stop offset="0.43" stop-color="#CDDBF0" />
                    <stop offset="0.54" stop-color="#C3CFE0" />
                    <stop offset="0.65" stop-color="#B2BBC7" />
                    <stop offset="0.66" stop-color="#AFB8C3" />
                    <stop offset="1" stop-color="#717476" />
                </linearGradient>
                <linearGradient id="paint1_linear_14442_24292" x1="1.99463" y1="8.4732" x2="10.4768"
                                y2="19.7173" gradientUnits="userSpaceOnUse">
                    <stop offset="0.1" stop-color="#717476" />
                    <stop offset="0.39" stop-color="#AFB8C3" />
                    <stop offset="0.4" stop-color="#B2BBC7" />
                    <stop offset="0.52" stop-color="#C3CFE0" />
                    <stop offset="0.62" stop-color="#CDDBF0" />
                    <stop offset="0.71" stop-color="#D1DFF5" />
                    <stop offset="1" stop-color="#F6FCFE" />
                </linearGradient>
                <linearGradient id="paint2_linear_14442_24292" x1="4.16182" y1="11.8553"
                                x2="9.93968" y2="18.362" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6FCFE" />
                    <stop offset="0.5" stop-color="#D1DFF5" />
                    <stop offset="0.59" stop-color="#CDDBF0" />
                    <stop offset="0.71" stop-color="#C2CEE0" />
                    <stop offset="0.84" stop-color="#AFB9C7" />
                    <stop offset="0.97" stop-color="#959CA4" />
                    <stop offset="0.99" stop-color="#92989F" />
                </linearGradient>
                <radialGradient id="paint3_radial_14442_24292" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.27416 15.3904) scale(7.31703)">
                    <stop stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.76" stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.84" stop-color="white" />
                    <stop offset="0.94" stop-color="white" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint4_radial_14442_24292" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.94645 14.533) scale(6.55943)">
                    <stop stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.76" stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.84" stop-color="white" />
                    <stop offset="0.94" stop-color="white" stop-opacity="0" />
                </radialGradient>
                <radialGradient id="paint5_radial_14442_24292" cx="0" cy="0" r="1"
                                gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.94645 14.533) scale(6.55943)">
                    <stop stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.76" stop-color="#FFFBE9" stop-opacity="0" />
                    <stop offset="0.84" stop-color="white" />
                    <stop offset="0.94" stop-color="white" stop-opacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_14442_24292" x1="0.0766169" y1="6.38773"
                                x2="10.6062" y2="20.1347" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F6FCFE" />
                    <stop offset="0.52" stop-color="#AFB8C3" />
                    <stop offset="0.63" stop-color="#A6AEB8" />
                    <stop offset="0.84" stop-color="#999FA7" />
                    <stop offset="1" stop-color="#949AA1" />
                </linearGradient>
                <linearGradient id="paint7_linear_14442_24292" x1="1.34257" y1="0.26372"
                                x2="5.95527" y2="6.18063" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint8_linear_14442_24292" x1="7.26909" y1="6.59779" x2="11.527"
                                y2="0.191795" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint9_linear_14442_24292" x1="6.42039" y1="11.5327"
                                x2="6.82317" y2="6.86251" gradientUnits="userSpaceOnUse">
                    <stop offset="0.49" stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
            </defs>
        </svg>
    );
};
