import { VacationUser } from "./VacationUser";

interface VacationUsersAllListProps {
    isAdmin: boolean;
    isOwner: boolean;
    expand: string;
    handleExpand: (value: string) => void;
    usersList: any[];
}

export const VacationUsersAllList = (
    {
        isAdmin,
        isOwner,
        expand,
        handleExpand,
        usersList
    }: VacationUsersAllListProps
) => {
    // if (!isOwner) return null;

    return (
        <div className={"vacation__content__left__calendar-block"}>
            {(usersList.length > 0) && (
                <>
                    <div className={"vacation__content__left__calendar-head"}>
                        <div className={"vacation__content__left__calendar-label"}>
                            Все пользователи
                        </div>
                    </div>

                    <div className={"vacation__content__left__calendar-list"}>
                        {usersList.map((user: any) => (
                            <VacationUser
                                indicator={user?.indicator}
                                key={`${user?.id}-user`}
                                panel={`${user?.id}-user`}
                                expand={expand}
                                handleExpand={handleExpand}
                                data={user}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
