import { IApiHandlerParams } from "../../interface";
import api from "../../index";

export const $createCategories = (
  data: any,
  route: string,
  handler?: IApiHandlerParams
): Promise<any> => api
  .post(`api/${route}/createcategory`, data, {
    handler
  });

export const $editCategories = (
  data: any,
  route: string,
  handler?: IApiHandlerParams
): Promise<any> => api
  .post(`api/${route}/editcategory`, data, {
    handler
  });

export const $deleteCategory = (
  data: { id: string },
  route: string,
  handler?: IApiHandlerParams
): Promise<any> => api
  .post(`api/${route}/deleltecategory`, data, {
    handler
  });


export const $createNewApiCategories = (
    data: any,
    route: string,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`backend/${route}/categories`, data, {
  handler
});
