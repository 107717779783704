import React, {useState} from "react";
import {ArrowExitIcon} from "../../../img";
import {SearchItem} from "../../../components/ui/form/SearchItem";
import {ServicesAcceptanceList, ServicesApplicationsList} from "../../../components/services";
import {ServicesApplicationsHistory} from "../../../components/services/services-education/ServicesApplicationsHistory";
import {isMd} from "../../../utils";
import {useNavigate} from "react-router-dom";
import {IServicesApplications} from "../../../api/requests/services/interface";
import {Controller, useForm} from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import {ServicesFormingApplicationModal} from "../../../components/services/services-education/modals";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const ServicesMedicalPage = () => {
    const isAdmin = false;
    const isHr = true;

    const navigate = useNavigate()
    const isMobile = isMd();

    const [isOpenModal, setIsOpenModal] = useState(false)
    const [ servicesApplicationsData, setServicesApplicationsData ] = useState<IServicesApplications[]>([])

    const [activeType, setActiveType] = useState('acceptance')
    const [types, setTypes] = useState([
        {
            id: 1,
            key: 'applications',
            title: 'Мои заявки'
        },
        {
            id: 2,
            key: 'acceptance',
            title: 'Согласование заявок',
        },
        {
            id: 3,
            key: 'history',
            title: 'История заявок'
        }
    ])

    const [applicationsTableHeader, setApplicationsTableHeader] = useState([
        {
            key: 'id',
            title: 'ID заявки',
            width: '25%'
        },
        {
            key: 'medical_date',
            title: 'Дата больничного',
            width: '25%'
        },
        {
            key: 'application_date',
            title: 'Дата заявки',
            width: '25%'
        },
        {
            key: 'status',
            title: 'Статус',
            width: '25%'
        },
    ])

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([
        {
            label: "Спорт",
            value: 0
        },
        {
            label: "Инновации",
            value: 1
        },
        {
            label: "Творчество",
            value: 2
        }
    ]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: {isValid},
        setValue,
    } = useForm<any>();

    return (
        <div className='services-education'>
            <div className='services-education-header'>
                { !isMobile && (
                    <div className='services-education-header__left'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1) }>
                            <ArrowExitIcon />
                        </div>

                        <h1>Больничный</h1>
                    </div>
                ) }

                <div className='services-education-header__right'>
                    <SearchItem onChange={ () => '' } />
                </div>
            </div>

            <div className='services-education-content'>
                <div className='services-education-content__top'>
                    { types.map((
                        item,
                        idx
                    ) => (
                        <div
                            key={ idx }
                            className={ `services-education-content__top__block ${ activeType === item.key ? 'active' : '' }` }
                            onClick={ () => setActiveType(item.key) }
                        >
                            { item.title }
                        </div>
                    )) }
                </div>

                <div  className='services-education-wrapper'>
                    <div className='services-education-wrapper__header'>
                        <div className='services-education-wrapper__buttons'>
                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="type"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <Dropdown
                                            className={"form-select"}
                                            placeholder={"Все"}
                                            label={"Статус"}
                                            options={[
                                                {
                                                    label: "Все",
                                                    value: "all"
                                                },
                                                ...categoryList
                                            ]}
                                            value={value}
                                            onChange={onChange}
                                        />
                                    )}
                                />
                            </div>

                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата обучение"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>

                            <div className='services-education-wrapper__buttons__block'>
                                <Controller
                                    name="date_start"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({field: {onChange, value}}) => (
                                        <DatePickerForm
                                            value={value}
                                            className=""
                                            label="Дата заявки"
                                            placeholder={"Не выбрано"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='services-education-wrapper__header__button' onClick={() => setIsOpenModal(true)}>
                            Подать заявку
                        </div>
                    </div>

                    { activeType === 'applications' && (
                        <ServicesApplicationsList data={ servicesApplicationsData } headerTable={applicationsTableHeader} />
                    ) }

                    { activeType === 'acceptance' && (
                        <ServicesAcceptanceList isAdmin={isAdmin} isHr={isHr}/>
                    ) }

                    { activeType === 'history' && (
                        <ServicesApplicationsHistory isAdmin={isAdmin} isHr={isHr}/>
                    ) }
                </div>
            </div>

            {isOpenModal && (
                <ServicesFormingApplicationModal isOpen={isOpenModal} onClose={() => setIsOpenModal(false)}/>
            )}
        </div>
    )
}