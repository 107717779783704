import { ArrowRightIcon } from "../../../img";
import { useEffect, useState } from "react";
import { FeedEventsCard } from "./FeedEventsCard";
import { $getEventLastList, $getEventList } from "../../../api/requests/events";
import { useNavigate } from "react-router-dom";

export const FeedEventsContent = () => {
    const navigate = useNavigate();

    const [eventsContent, setEventsContent] = useState([
        // {
        //     id: 1,
        //     title: 'Волейбольный турнир: Полуфинал',
        //     date: '10 августа',
        //     type: 'Спортивные мероприятия',
        //     image: '/testImages/events/events-1.png'
        // },
        // {
        //     id: 1,
        //     title: 'Волейбольный турнир: Полуфинал',
        //     date: '10 августа',
        //     type: 'Спортивные мероприятия',
        //     image: '/testImages/events/events-1.png'
        // },
        // {
        //     id: 1,
        //     title: 'Волейбольный турнир: Полуфинал',
        //     date: '10 августа',
        //     type: 'Спортивные мероприятия',
        //     image: '/testImages/events/events-1.png'
        // },
        // {
        //     id: 1,
        //     title: 'Волейбольный турнир: Полуфинал',
        //     date: '10 августа',
        //     type: 'Спортивные мероприятия',
        //     image: '/testImages/events/events-1.png'
        // }
    ]);

    useEffect(() => {
        init();
    }, []);

    function init() {
        getEventsList();
    }

    function getEventsList() {
        $getEventLastList({ count: 4 }, { newApi: true }).then(res => {
            if (!res.data) return;

            setEventsContent(res.data);
        });
    }

    return (
        <div className="feed-events">
            <div className="feed-controls feed-news__controls">
                {/* <div className={allViewsClass}>{allViews}</div> */}
                <h3>Мероприятия</h3>

                <div className="layout-right-block__header__link"
                     onClick={() => navigate("/events")}>
                    <h4>Все мероприятия</h4>
                    <ArrowRightIcon color={"#000"} />
                </div>
            </div>

            <div className="feed-events__content">
                {eventsContent.map((item, idx) => (
                    <FeedEventsCard data={item} key={idx} />
                ))}
            </div>
        </div>
    );
};