import {
    generateRangeArray,
    generateRangeArrayWeek, getMonthArray,
    statisticsDates
} from "./dates";
import pad from "../../../utils/pad/pad";
import dayjs from "dayjs";

export const sortPartnersChartDates = (data: any, filter_type: any, title?: string,) => {
    const currentDate = new Date();

    const currentMonth = currentDate.getMonth() + 1;

    const currentMonthLength: any = new Date(currentDate.getFullYear(), currentMonth, 0).getDate();
    let list: any ;

    if (filter_type === 'year' && data) {
        list = statisticsDates.months.map((date: any, idx: number) => {
            const monthData =  data?.find((item: any) => {
                const dateMonth = new Date(item.date).getMonth() + 1
                return dateMonth === idx + 1
            });

            return {
                day: date,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (filter_type === 'month') {
        list = getMonthArray().map((date, idx) => {
            const monthData = data?.find((item: any) => {
                const dateDay = new Date(item.date).getDate()

                return dateDay === (idx + 1)
            });

            return {
                day: `${date}`,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (filter_type === 'week') {
        list = generateRangeArrayWeek()?.map((day: any, idx: number) => {
            const monthData = data?.find((item: any) => {
                const dateDay = new Date(item?.date).getDate()

                return dateDay == day?.day
            });

            return {
                day: `${day.date}`,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (title !== 'Активные пользователи' && (filter_type === 'yesterday' || filter_type === 'today') ) {
        list = statisticsDates.hours.map((hour: any, idx: number) => {
            const monthData = data?.find((item: any) => {
                const dateHour = new Date(item.date).getHours()
                const dateMinute = new Date(item.date).getMinutes()

                return `${pad(dateHour)}:${pad(dateMinute)}` === hour
            });

            return {
                day: hour,
                count: monthData ? monthData.count : 0
            }
        })
    }
    else if(!filter_type || title === 'Активные пользователи') {
        list = data?.map((item: any) => {
            return {
                //  day: `${pad(new Date(item.date).getDate())}.${pad(new Date(item.date).getMonth() + 1)}.${new Date(item.date).getUTCFullYear().toLocaleString().slice(-2)}
                // ${hasTime(item.date) ? pad(new Date(item.date).getHours()) : ''}${hasTime(item.date) ? ':' : ''}${hasTime(item.date) ? pad(new Date(item.date).getMinutes() : ''}
                //    `,
                //  count: item.count
                day: getDateWithoutFilterType(item),
                count: item.count
            }
        })
    }

    return list
}


export function getDateWithoutFilterType (item: any){``
    if (hasTime(item.date)) {
        return `${pad(new Date(item.date).getHours())}:${pad(new Date(item.date).getMinutes())}`
    }
    else if (isValidDate(item.date)) {
        return `${pad(new Date(item.date).getDate())}.${pad(new Date(item.date).getMonth() + 1)}.${new Date(item.date).getUTCFullYear().toLocaleString().slice(-2)}`
    }
    else {
        return `${pad(new Date(item.date).getMonth() + 1)}.${new Date(item.date).getUTCFullYear().toLocaleString().slice(-2)}`
    }

}
export function hasTime(dateString: any) {
    const date = dayjs(dateString);

    // If the time is exactly midnight, we assume there's no time specified
    return !(date.hour() === 0 && date.minute() === 0 && date.second() === 0);
}

export function isValidDate(dateString: string) {
    const fullDateRegex = /^\d{4}-\d{2}-\d{2}$/;

    return fullDateRegex.test(dateString);
}


export const sortActivityChartDates = (data: any, filter_type: any) => {
    const currentDate = new Date();

    const currentMonth = currentDate.getMonth() + 1;

    const currentMonthLength = new Date(currentDate.getFullYear(), currentMonth, 0).getDate();
    let list;

    if (filter_type === 'year') {
        list = statisticsDates.months.map((date: any, idx: number) => {
            const monthData = data?.find((item: any) => {
                const dateMonth = item.month
                return dateMonth == idx
            });

            return {
                day: date,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (filter_type === 'month') {
        // @ts-ignore
        list = Array(currentMonthLength).fill().map((_, index) => index + 1).map((date, idx) => {
            const monthData = data?.find((item: any) => {
                const dateDay = item.month

                return dateDay == (idx + 1)
            });

            return {
                day: `${idx + 1} апр`,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (filter_type === 'week') {
        list = generateRangeArray().map((day: any, idx: number) => {
            const monthData = data?.find((item: any) => {
                const dateDay = item.day

                return dateDay == day
            });

            return {
                day: `${day} апр`,
                count: monthData ? monthData.count : 0
            }
        })
    } else if (filter_type === 'yesterday' || filter_type === 'today') {
        list = statisticsDates.hours.map((hour: any, idx: number) => {
            const monthData = data?.find((item: any) => {
                const dateHour = new Date(item.hour).getHours()
                const dateMinute = new Date(item.hour).getMinutes()

                return `${pad(dateHour)}:${pad(dateMinute)}` === hour
            });

            return {
                day: hour,
                count: monthData ? monthData.count : 0
            }
        })
    }
    else if(!filter_type) {
        list = data?.map((item: any) => {
            return {
                day: `${item?.day ? pad(item?.day) + '.': ''}
            ${item?.month && pad(item?.month)}
            .${item?.year?.toLocaleString().slice(-2)}`,
                count: item.count
            }
        })
    }

    return list
}
