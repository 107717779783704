import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import { getCurrentRole, getPersonData } from "../../../redux";
import {
    getSelected,
    setBuildings,
    setSelected
} from "../../../redux/reducers/workplace/workplaceSlice";
import { findTime } from "../../../utils/date/hourdsTime";
import { Controller, useForm } from "react-hook-form";
import { failureNotify, successNotify } from "../../../utils";
import DialogContainer from "../../ui/dialog/DialogContainer";
import DatePickerForm from "../../ui/form/DatePickerForm";
import DatePickerTimeField from "../../ui/form/DatePickerForm/DatePickerTimeField";
import { ArrowDropdownIcon } from "../../../img/icons/arrow/ArrowDropdownIcon";
import Button from "../../ui/form/Buttons/Button";
import TextField from "../../ui/form/Fields/TextField";
import AddSelectUsers from "../../ui/users/AddSelectUsers";
import {
    $bookingCreate,
    $bookingDelete,
    $bookingEdit
} from "../../../api/requests/services/booking";
import Dropdown, { DropdownItem } from "../../ui/form/Dropdown/Dropdown";
import { $getWorkplaceBuildings } from "../../../api/requests/workplace";
import { getBookingActionModalData } from "../../../redux/reducers/booking/bookingRoomsSlice";

interface FormData {
    isEdit: boolean;
    place: string;
    description: string;
    booking_date?: Date;
    start_time: Date | null;
    end_time: Date | null;
    members: any[];
    room_id: number;
    floor: any;
}

interface BookingMeetDialogProps {
    isOpen: boolean;
    onClose: () => void;
    selectedProps: any;
}

const BookingMeetDialog = (
    {
        isOpen,
        onClose,
        selectedProps
    }: BookingMeetDialogProps
) => {
    const { id: user_id } = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const { request } = selectedProps;
    const isMine = (request?.booking?.initiator_id === user_id) || isAdmin;

    const dispatch = useDispatch();

    const { city, building, floor: floorState } = useSelector(getSelected);

    const previousData = useSelector(getBookingActionModalData);

    const isEdit = !!previousData?.booking?.id;

    const bookingDate = moment(request?.booking?.booking_date).format('YYYY-MM-DD');

    const startTime = request?.booking ? moment(`${bookingDate}T${findTime(request?.booking?.start_time)?.time}`).toDate() : null;

    const endTime = request?.booking ? moment(`${bookingDate}T${findTime(request?.booking?.end_time || 0)?.time}`).toDate() : null;

    const timeMain = `${request?.booking ? findTime(request?.booking?.start_time)?.time : ""} - ${request?.booking ? findTime(request?.booking?.end_time)?.time : ""}`

    const getMembersList = () => {
        const list = []

        if (previousData?.booking?.initiator) list.push(previousData?.booking?.initiator)

        if (previousData?.booking?.members?.length) list.push(...previousData?.booking?.members)

        return list;
    }

    const initialBooking = {
        isEdit,
        place: previousData?.number,
        description: previousData?.booking?.description,
        booking_date: request?.booking?.date,
        start_time: startTime,
        end_time: endTime,
        members: getMembersList(),
        room_id: previousData?.id || request?.booking?.idRoom,
        floor: floorState
    };

    const [isEditFields, setIsEditFields] = useState(false);
    const [meetList, setMeetList] = useState<DropdownItem[]>([]);
    const [floorsDropdownData, setFloorsDropdownData] = useState<DropdownItem[]>([]);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { isValid },
        setValue
    } = useForm<FormData>(
        {
            values: {
                ...initialBooking
            }
        }
    );

    const handleCreate = (requestData: any) => {
        $bookingCreate(requestData).then(res => {
            if (res.error || res.response) return;

            handleClosePopup();

            if (res) {
                successNotify(res.message);

                updateBuilding();
            }
        });
    };

    const handleEdit = (requestData: any) => {
        $bookingEdit(previousData?.booking?.id, requestData).then(res => {
            if (res.error || res.response) return;

            handleClosePopup();
            updateBuilding();
        });
    };

    const handleDelete = () => {
        $bookingDelete(previousData?.booking?.id).then(res => {
            if (res.error || res.response) return;

            handleClosePopup();
            updateBuilding();
        });
    };

    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val || val === 0) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const getDateTime = (hours: number, min: number = 0) => {
        const time = new Date()

        time.setHours(hours, min)

        return time
    }

    useEffect(() => {
        if (watch('floor')) {
            const updateList = [...watch('floor').rooms].map(item => ({
                ...item,
                label: item.name,
                value: item.id
            }))

            setMeetList(updateList)
        }
    }, [watch('floor')]);

    useEffect(() => {
        if (building) {
            const updateList = [...building.floors].map(item => ({
                ...item,
                label: `Этаж ${item.number}`,
                value: item.id
            }))

            setFloorsDropdownData(updateList);
        }
    }, [building]);

    function onSubmit(data: FormData) {
        const requestData = {
            members: data?.members.map(item => item.id),
            description: data.description,
            event_date: moment(data?.booking_date).format("YYYY-MM-DD"),
            start_time: moment(data.start_time).diff(moment(data.start_time).startOf('day'), 'seconds'),
            end_time: moment(data.end_time).diff(moment(data.end_time).startOf('day'), 'seconds')
        }

        if (isEdit) return handleEdit(requestData)

        const selectDate = new Date(moment(data?.booking_date).format("yyyy-MM-DD")).getTime();
        const todayDate = new Date(moment().format("yyyy-MM-DD")).getTime();

        if (selectDate < todayDate) return failureNotify("Нельзя забронировать за прошлую дату");

        handleCreate(
            {
                ...requestData,
                city_id: city?.id,
                building_id: building?.id,
                floor_id: watch("floor")?.id,
                room_id: data?.room_id,
                workplace_id: request?.id
            }
        )
    }

    function handleEditFields() {
        setIsEditFields(true)
    }

    function handleClosePopup() {
        setIsEditFields(false);
        onClose();
    }

    function updateBuilding() {
        $getWorkplaceBuildings(city?.value, { newApi: true }).then(res => {
            if (!res || !res?.data) return;

            const buildingUpdate = {
                ...res?.data[0],
                label: res?.data[0].name,
                value: res?.data[0].id
            }

            const updateFloor = buildingUpdate.floors
            .map(item => ({
                ...item,
                label: item?.name,
                value: item?.id
            }))
            .find(item => item?.id === watch("floor")?.id)

            dispatch(setBuildings(res?.data));
            dispatch(setSelected({ city, building: buildingUpdate, floor: updateFloor ? updateFloor : watch("floor") }));
        });
    }

    return (
        <>
            <DialogContainer
                isOpen={isOpen}
                setIsOpen={() => {
                    setIsEditFields(false);
                    onClose()
                }}
                widthProps={427}
                label={!isEdit
                    ? <>Бронирование {previousData?.name}</>
                    : <>Переговорная {initialBooking?.place}</>}
                classNameHeader={"pb-10"}
            >
                <div className={"workplace__booking"}>
                    <div className={"workplace__booking__head"}>
                        <div className={"workplace__booking__address"}>
                            {`${city?.name || ""}, ${building?.name || ""}${floorState?.number ? `, ${floorState?.number}-й этаж` : ''}`}
                        </div>
                    </div>

                    {isEditFields ? (
                        <form
                            className={"workplace__booking__form d-flex flex-column gap-5"}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className={"d-flex flex-column gap-5"}>
                                {!floorState && (
                                    <Controller
                                        name="floor"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Все"}
                                                label={"Этаж"}
                                                options={floorsDropdownData}
                                                value={value}
                                                onChange={onChange}
                                                isSearchable={true}
                                            />
                                        )}
                                    />
                                )}

                                {!previousData?.id && (
                                    <Controller
                                        name={"room_id"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: "Переговорная не выбрана"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <Dropdown
                                                className={"form-select"}
                                                placeholder={"Выберете"}
                                                label={"Переговорная"}
                                                options={meetList}
                                                value={getValue(meetList, value)}
                                                onChange={(select) => onChange(select.value)}
                                            />
                                        )}
                                    />
                                )}

                                <Controller
                                    name={"description"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите тему"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            title="Тема встречи"
                                            placeholder={"Тема"}
                                            name="description"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name={"booking_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            startDate={value}
                                            className=""
                                            label="Дата"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                            minDate={new Date()}
                                            disabled={isEdit}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3"}>
                                    <Controller
                                        name={"start_time"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете время"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerTimeField
                                                startDate={value as Date}
                                                // timeIntervals={1}
                                                minTime={getDateTime(7, 30)}
                                                maxTime={getDateTime(20)}
                                                label="Время начала"
                                                placeholder={"00:00"}
                                                onChange={onChange}
                                                disabled={isEdit}
                                                isIcon={<ArrowDropdownIcon/>}
                                                isRightIcon
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={"end_time"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете время"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerTimeField
                                                startDate={value as Date}
                                                // timeIntervals={1}
                                                minTime={getDateTime(7, 30)}
                                                maxTime={getDateTime(20)}
                                                label="Время завершения"
                                                placeholder={"00:00"}
                                                onChange={onChange}
                                                disabled={isEdit}
                                                isIcon={<ArrowDropdownIcon/>}
                                                isRightIcon
                                            />
                                        )}
                                    />
                                </div>

                                <Controller
                                    name="members"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { value, onChange } }) => (
                                        <AddSelectUsers
                                            usersList={value}
                                            title="Участники"
                                            setUsersList={onChange}
                                        />
                                    )}
                                />
                            </div>

                            {isMine && (
                                <div className={"workplace__booking__btns"}>
                                    <Button
                                        type={"submit"}
                                        text={isEdit ? "Сохранить" : "Забронировать"}
                                        className={"btn btn-primary w-100"}
                                        disabled={!isValid}
                                    />

                                    <Button
                                        text={"Отмена"}
                                        className={"btn btn-light ml-0 w-100"}
                                        onClick={() => setIsEditFields(false)}
                                    />
                                </div>
                            )}
                        </form>
                        ) : (
                        <form
                            className={"workplace__booking__form d-flex flex-column gap-5"}
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            {!isEdit && (
                                <div className={"d-flex flex-column gap-5"}>
                                    {!floorState && (
                                        <Controller
                                            name="floor"
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: ""
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    className={"form-select"}
                                                    placeholder={"Все"}
                                                    label={"Этаж"}
                                                    options={floorsDropdownData}
                                                    value={value}
                                                    onChange={onChange}
                                                    isSearchable={true}
                                                />
                                            )}
                                        />
                                    )}

                                    {!previousData?.id && (
                                        <Controller
                                            name={"room_id"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: false,
                                                    message: "Переговорная не выбрана"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <Dropdown
                                                    className={"form-select"}
                                                    placeholder={"Выберете"}
                                                    label={"Переговорная"}
                                                    options={meetList}
                                                    value={getValue(meetList, value)}
                                                    onChange={(select) => onChange(select.value)}
                                                />
                                            )}
                                        />
                                    )}

                                    <Controller
                                        name={"description"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Введите тему"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <TextField
                                                value={value}
                                                title="Тема встречи"
                                                placeholder={"Тема"}
                                                name="description"
                                                onChange={onChange}
                                            />
                                        )}
                                    />

                                    <Controller
                                        name={"booking_date"}
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Выберете дату"
                                            }
                                        }}
                                        render={({ field: { onChange, value } }) => (
                                            <DatePickerForm
                                                startDate={value}
                                                className=""
                                                label="Дата"
                                                placeholder={"Выберите дату"}
                                                onChange={onChange}
                                                isIcon
                                                isRightIcon
                                                minDate={new Date()}
                                                disabled={isEdit}
                                            />
                                        )}
                                    />

                                    <div className={"d-flex gap-3"}>
                                        <Controller
                                            name={"start_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время начала"
                                                    minTime={getDateTime(7, 30)}
                                                    maxTime={getDateTime(20)}
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    disabled={isEdit}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />

                                        <Controller
                                            name={"end_time"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "Выберете время"
                                                }
                                            }}
                                            render={({ field: { onChange, value } }) => (
                                                <DatePickerTimeField
                                                    startDate={value as Date}
                                                    // timeIntervals={1}
                                                    label="Время завершения"
                                                    minTime={getDateTime(7, 30)}
                                                    maxTime={getDateTime(20)}
                                                    placeholder={"00:00"}
                                                    onChange={onChange}
                                                    disabled={isEdit}
                                                    isIcon={<ArrowDropdownIcon/>}
                                                    isRightIcon
                                                />
                                            )}
                                        />
                                    </div>

                                    <Controller
                                        name="members"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <AddSelectUsers
                                                usersList={value}
                                                title="Участники"
                                                setUsersList={onChange}
                                            />
                                        )}
                                    />
                                </div>
                            )}

                            {isEdit && (
                                <>
                                    <div className={"services-create-form__employee"}>
                                        <p className="title mb-2">Статус митинг</p>
                                    </div>

                                    <div className={"d-flex gap-5"}>
                                        <DatePickerForm
                                            startDate={watch("booking_date")}
                                            className=""
                                            label="Дата"
                                            placeholder={"Выберите дату"}
                                            onChange={() => {
                                            }}
                                            isIcon
                                            isRightIcon
                                            disabled
                                        />

                                        <TextField
                                            value={timeMain}
                                            className=""
                                            title="Время"
                                            placeholder={"Время"}
                                            onChange={() => {
                                            }}
                                            disabled
                                        />
                                    </div>

                                    <Controller
                                        name="members"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: false,
                                                message: ""
                                            }
                                        }}
                                        render={({ field: { value, onChange } }) => (
                                            <AddSelectUsers
                                                usersList={value}
                                                title="Участники"
                                                setUsersList={onChange}
                                                disabled
                                            />
                                        )}
                                    />
                                </>
                            )}

                            {isMine && (
                                <div className={"workplace__booking__btns"}>
                                    {isEdit ? (
                                        <>
                                            <Button
                                                text={"Отменить бронь"}
                                                className={"btn btn-red w-100"}
                                                onClick={handleDelete}
                                            />

                                            <Button
                                                text={"Редактировать"}
                                                className={"btn btn-light ml-0 w-100"}
                                                onClick={handleEditFields}
                                            />
                                        </>
                                    ) : (
                                        <Button
                                            type={"submit"}
                                            text={isEdit ? "Сохранить" : "Забронировать"}
                                            className={"btn btn-primary w-100"}
                                            disabled={!isValid}
                                        />
                                    )}
                                </div>
                            )}
                        </form>
                    )}
                </div>
            </DialogContainer>
        </>
    );
};

export default BookingMeetDialog;