import { FC, ReactNode, useEffect } from "react";
import { CloseIcon } from "../../../img";

interface Dialog {
    children: ReactNode;
    isOpen: boolean;
    setIsOpen?: (is: boolean) => void;
    closeModal?: () => void;
    styles?: any;
    label?: string | ReactNode;
    widthProps?: number;
    isShowCloseBtn?: boolean;
    closeIcon?: ReactNode;
    classNameHeader?: string;
    classNameModal?: string;
    isCloseIconOutside?: boolean;
    isFilter?: boolean;
}

const DialogDragAndDropContainer: FC<Dialog> = (
    {
        children,
        isOpen,
        setIsOpen,
        closeModal,
        styles,
        label = "",
        widthProps = 500,
        isShowCloseBtn = true,
        closeIcon = <CloseIcon color={"black"} />,
        classNameHeader = "",
        classNameModal = "",
        isCloseIconOutside,
        isFilter
    }) => {

    function closeModalLocal() {
        setIsOpen && setIsOpen(false);

        closeModal && closeModal();
    }

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";

        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div
                className={"modal__container"}
                style={{
                    // position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    // backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // zIndex: 10
                }}
            >
                <div style={{
                    backgroundColor: '#fff',
                    padding: '20px',
                    borderRadius: '8px',
                    minWidth: widthProps || '427px',
                }}>
                    {(label || isShowCloseBtn) && (
                        <div className={`modal__block-header ${classNameHeader}`}>
                            {label && <p>{label}</p>}

                            {isShowCloseBtn && (
                                <button
                                    onClick={closeModalLocal}
                                    className={"modal__block-close"}
                                >
                                    {isCloseIconOutside ? (
                                        <CloseIcon color={"white"} opacity={"1"} />
                                    ) : (
                                        closeIcon
                                    )}
                                </button>
                            )}
                        </div>
                    )}

                    {children}
                </div>
            </div>
        </>
    );
};

export default DialogDragAndDropContainer;
