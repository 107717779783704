import { useSelector } from "react-redux";
import { getCurrentRole, getIsOwner, getProfileVarsData } from "../../../redux";
import {
    vacationColors,
    vacationHoverColors
} from "../../../containers/calendar/vacation/constans";
import styles from "./CalendarItem.module.scss";
import React from "react";
import { AcceptedIcon } from "../../../img";
import moment from "moment";
import { BlankImage } from "../../ui/blank-image/BlankImage";

interface CalendarItemProps {
    data: any;
    isEvents?: boolean;
    isDay?: boolean;
    bgColors?: string[]
}

export const CalendarItem = (
    {
        data,
        isEvents,
        isDay,
        bgColors
    }: CalendarItemProps
) => {
    const vars = useSelector(getProfileVarsData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const isBgColor = data?.indicator >= 0 && (bgColors ? bgColors[data?.indicator % bgColors.length] : vacationColors[data?.indicator % vacationColors.length]);
    const isBgActionColor = data?.indicator >= 0 && vacationHoverColors[data?.indicator % vacationHoverColors.length];

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor,
        "--whiteColor": vars.colors.fourthColor,
        "--status": 1, //data?.status === 2 ? 1 : 0.3,
        "--backgroundColor": isBgColor ? isBgColor : vars.colors.secondaryColor,
        "--backgroundActionColor": isBgActionColor ? isBgActionColor : `${vars.colors.secondaryColor}e6`,
        "--color": isBgColor ? "#000" : vars.colors.fourthColor
    };

    if (isEvents) return (
        <div className={`calendar__item__wrapper calendar__item__event ${styles.wrapper}`} style={style}>
            <div className={`calendar__item__name ${styles.name}`}>
                <div className="accordion__image">
                    <BlankImage image={data?.cover} />
                </div>

                <div className={"d-flex flex-column gap-1"}>
                    <div className="accordion__info">
                        <p className="accordion__name">
                            {data?.title}
                        </p>
                        <p className="accordion__position">{data?.category?.title}</p>
                    </div>

                    {!isDay && (
                        <div className="d-flex justify-content-between gap-1">
                            <div className="accordion__status">
                                {moment(data?.start_date).format("HH:mm")}
                            </div>

                            <span className={"d-flex align-items-center"}>
                                {data?.is_recorded && <AcceptedIcon color={"black"} />}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )

    return (
        <div className={`calendar__item__wrapper ${styles.wrapper}`} style={style}>
            {data?.user?.photo && (
                <div
                    className={"accordion__avatar"}
                    style={{
                        backgroundColor: `${vars.colors.secondaryColor}1a`
                    }}
                >
                    {data?.user?.photo && <img src={data?.user?.photo} />}

                    {!data?.user?.photo && data?.user?.name && data?.user?.name.slice(0, 1)}
                </div>
            )}

            <div className={`calendar__item__name ${styles.name}`}>
                {`${data?.user?.name || data?.title}`}
            </div>
        </div>
    );
};
