import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import {
    SportBlockedUserResponseData,
    SportItemResponseData, SportRequestFormData, SportRequestListData,
    SportResponseData, SportResponseUsersData
} from "./interface";

export const $getSportList = (
    isAdmin: boolean,
    data: SportRequestListData,
    handler?: IApiHandlerParams
): Promise<SportResponseData> => api
.get(isAdmin ? "backend/v1/trainings" : `api/v1/trainings`, {
    // mock: require("./mocks/sport.list.json"),
    request: {
        params: data
    },
    handler
});

export const $getSportById = (
    isAdmin: boolean,
    id: string,
    data?: any,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.get(isAdmin ? `backend/v1/trainings/${id}` : `api/v1/trainings/${id}`, {
    // mock: require("./mocks/sport.item.json"),
    request: {
        params: data
    },
    handler
});

export const $createSport = (
    data: SportRequestFormData,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.post("backend/v1/trainings", data, {
    // mock: require("./mocks/sport.item.json"),
    handler
});

export const $editSport = (
    id: string,
    data: SportRequestFormData,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.put(`backend/v1/trainings/${id}`, data, {
    // mock: require("./mocks/sport.item.json"),
    handler
});

export const $deleteSport = (
    id: string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`backend/v1/trainings/${id}`, {
    // mock: require("./mocks/sport.item.json"),
    handler
});

export const $getSportBlockedUsers = (
    data: any,
    handler?: IApiHandlerParams
): Promise<SportBlockedUserResponseData> => api
.get(`backend/v1/trainings/banned_users`, {
    // mock: require("./mocks/sport.blocked.users.json"),
    request: {
        params: data
    },
    handler
});

export const $getSportUnbanUser = (
    id: any,
    handler?: IApiHandlerParams
): Promise<SportBlockedUserResponseData> => api
.get(`backend/v1/trainings/unban/${id}`, {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $getSportTrainerList = (
    handler?: IApiHandlerParams
): Promise<SportResponseUsersData> => api
.get(`backend/v1/trainings/trainers`, {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $joinEnrollSport = (
    isAdmin: boolean,
    data: any,
    handler?: IApiHandlerParams
): Promise<SportResponseUsersData> => api
.post(isAdmin ? "backend/v1/trainings/enroll" : "api/v1/trainings/status/enroll", data, {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $cancelEnrollSport = (
    data: any,
    handler?: IApiHandlerParams
): Promise<SportResponseUsersData> => api
.post("api/v1/trainings/status/cancel", data, {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $changeStatusUserSport = (
    data: any,
    handler?: IApiHandlerParams
): Promise<SportResponseUsersData> => api
.post("backend/v1/trainings/status", data, {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $getUserSportAllList = (
    schedule_id: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`backend/v1/trainings/users_by_schedule_id/${schedule_id}`, {
    // mock: require("./mocks/sport.blocked.users.json"),
    request: {
        params: data
    },
    handler
});

export const $getSportSettings = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get("backend/v1/trainings/settings", {
    // mock: require("./mocks/sport.blocked.users.json"),
    handler
});

export const $editSportSettings = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.patch("backend/v1/trainings/settings", data, {
    // mock: require("./mocks/sport.item.json"),
    handler
});