import { ArrowExitIcon } from "../../../../img";
import React, { useState } from "react";
import { isMd } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { CreateEventFormData } from "../../../events/edit";
import DatePickerForm from "../../../../components/ui/form/DatePickerForm";
import FileClipField from "../../../../components/ui/form/FileField/FileClipField";
import { $uploadServiceApplicationFile } from "../../../../api/requests/services";
import { UploadIcon } from "../../../../img/icons/files/UploadIcon";
import { DownloadIcon } from "../../../../img/icons/DownloadIcon";
import Button from "../../../../components/ui/form/Buttons/Button";

export const ServicesEducationCreatePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const [uploadFileList, setUploadFileList] = useState<any>(undefined);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        setValue,
        formState: { errors, isValid },
        trigger
    } = useForm<any>();

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    function handleUploadList(list: any) {
        list().forEach((item: any) => {
            if (item.file) {
                const reader = new FileReader();

                reader.onloadend = () => {
                    if (reader.result && typeof reader.result === "string") {
                        $uploadServiceApplicationFile({ file: item.file }, {
                            newApi: true,
                            formData: true
                        }).then(res => {
                            setUploadFileList({ file: reader.result });
                            setValue("file", res.data.url);
                        });
                    }
                };

                reader.readAsDataURL(item.file);
            }
        });
    }

    function onSubmit(obj: any) {
        console.log("AAA");
    }

    return (
        <>
            <div className="layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="container layout-inner__header__title">
                        <div className="header__exitIcon"
                             onClick={() => navigate("/services/education/list")}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Заявка на обучение</h1>
                    </div>
                )}
            </div>

            <div className="services-applications-create">
                <div className="services-applications-create__block">
                    <h5 className="services-applications-create__title">Заявление</h5>

                    <div className="services-applications-create__content">
                        <form
                            onSubmit={handleSubmit((obj) => onSubmit(obj))}
                            className="contests__create__form"
                            onKeyDown={(e) => checkKeyDown(e)}
                        >
                            <div className="services-applications-create__withComment">
                                <Controller
                                    name={"start_date"}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Выберете дату"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <DatePickerForm
                                            startDate={value}
                                            className=""
                                            label="Дата начала"
                                            placeholder={"Выберите дату"}
                                            onChange={onChange}
                                            isIcon
                                            isRightIcon
                                        />
                                    )}
                                />

                                <p className="services-applications-create__comment">
                                    Внимание! Если начало обучения согласовано в следующем месяце,
                                    затраты необходимо отразить в бюджете. Если заявка направлена
                                    позднее 15 числа, дирекция по персоналу оставляет за собой право
                                    организовать обучение в следующем периоде.
                                </p>
                            </div>

                            <div className='services-applications-create__file'>
                                <div className='services-applications-create__file__header'>
                                    <p className='services-applications-create__file__header__label'>Заявка</p>

                                    <div className='services-applications-create__file__header__right'>
                                        <p  className='services-applications-create__file__header__text'>Скачать заявку</p>

                                        <DownloadIcon size={18}/>
                                    </div>
                                </div>
                                <Controller
                                    name="file"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileClipField
                                            accept={".docx"}
                                            label={""}
                                            upload={uploadFileList}
                                            setUpload={handleUploadList}
                                            isLockFile={uploadFileList}
                                            uploadName={uploadFileList?.file}
                                            text={'Загрузите заполненную заявку'}
                                            icon={<UploadIcon size={44}/>}
                                            isSingleDoc
                                        />
                                    )}
                                />
                            </div>

                            <Button
                                onClick={() => onSubmit('')}
                                type={"submit"}
                                text={"Отправить"}
                                className={"btn btn-primary w-100"}
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};