import {useNavigate} from "react-router-dom";
import {isMd} from "../../../utils";
import React, {useEffect, useState} from "react";
import {$getUserFavoritesAll} from "../../../api/requests/favorites";
import {ArrowExitIcon} from "../../../img";
import {FavoritesNewsCard} from "../../../components/favorites";

export const FavoritesContests = () => {
    const navigate = useNavigate()
    const isMobile = isMd();

    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        $getUserFavoritesAll('contest', {newApi: true}).then(res => {
            setData(res.data)
        })

    }, [isLoading])

    return (
        <div className='favorites-news'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ () => navigate(-1) }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Конкурсы</h1>
                    </div>
                ) }
            </div>

            <div className='container favorites-news-content'>
                { data.map((
                    item: any,
                    idx
                ) => (
                    <div onClick={() => navigate(`/media-files/show/${item.id}`)}>
                        <FavoritesNewsCard
                            data={item}
                            key={idx}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            type={'contest'}
                        />
                    </div>
                )) }
            </div>
        </div>
    )
}