import React, { useState, MouseEvent } from "react";
import moment from "moment";
import { isTillMd } from "../../../../utils";
import CoverPlaceholderSvg from "../../../../img/icons/header/CoverPlaceholderSvg";
import { getNoun } from "../../../../utils/field/getNoun";

import { Popover } from '@availity/mui-popover';

interface FullCalendarComponentEventItemProps {
    info: {
        view: any;
        event: any;
    };
    item: {
        backgroundColor: string;
        backgroundActionColor: string;
        members?: Array<{ photo?: string; name?: string }>;
        initiator?: { photo?: string; name?: string };
        date: string;
    };
}

export const FullCalendarComponentEventItem = (
    {
        info,
        item
    }: FullCalendarComponentEventItemProps
) => {
    const { view, event } = info;
    const isMobile = isTillMd();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const anchorId = open ? "event-popover" : undefined;

    const endSeconds = new Date(event.end).getTime() / 1000;
    const startSeconds = new Date(event.start).getTime() / 1000;
    const cellSize = (endSeconds - startSeconds) / 60 / 30;
    const count = item?.members ? item?.members?.length + 1 : 1;
    const countUpdate = count <= 3 ? count : count - 3;

    const getDateFormat = (date: Date) =>
        date.toLocaleTimeString("ru-RU", {
            hour: "2-digit",
            minute: "2-digit"
        });

    const startDate = getDateFormat(new Date(event?.start));
    const endDate = getDateFormat(new Date(event?.end));
    const getFullHours = `${startDate.slice(0, 5)} - ${endDate.slice(0, 5)}`;

    const handleOpen = (e: MouseEvent<HTMLElement>) => {
        setAnchorEl(e.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <div
                className="event-item-container"
                aria-owns={anchorId}
                aria-haspopup="true"
                onMouseEnter={
                    cellSize < 3 || view?.type === "timeGridWeek" || isMobile ? handleOpen : undefined
                }
                onMouseLeave={
                    cellSize < 3 || view?.type === "timeGridWeek" || isMobile ? handleClose : undefined
                }
                onTouchEnd={
                    cellSize < 3 || view?.type === "timeGridWeek" || isMobile ? handleClose : undefined
                }
            >
                <div
                    className="event-item-user-block"
                    style={{
                        display: cellSize < 3 || view?.type === 'timeGridWeek' ? 'none' : ''
                    }}
                >
                    <div className="event-item-users">
                        <div className="event-item-user" style={{ left: 0 }}>
                            {item?.initiator?.photo ? (
                                <img src={item?.initiator?.photo} alt="" />
                            ) : (
                                <CoverPlaceholderSvg style={{ transform: "scale(5)" }} />
                            )}
                        </div>

                        {item?.members?.map((i, idx) => {
                            if (idx > 1) return null;
                            return (
                                <div
                                    key={idx}
                                    className="event-item-user"
                                    style={{
                                        left: `${(idx + 1) * (isMobile ? -20 : 14)}px`,
                                        zIndex: 3 - idx
                                    }}
                                >
                                    {i?.photo ? <img src={i?.photo} alt="" /> :
                                        <CoverPlaceholderSvg />}
                                </div>
                            );
                        })}
                    </div>

                    <div className="event-item-users-count">
                        {countUpdate} {getNoun(countUpdate, "участник", "участника", "участников")}
                    </div>
                </div>

                <div>
                    <div
                        className="event-item-title"
                        style={{
                            display: cellSize < 3 || view?.type === "timeGridWeek" ? "none" : ""
                        }}
                    >{event?.title}</div>

                    <div
                        className="event-item-time"
                        style={{
                            display: cellSize < 3 || view?.type === "timeGridWeek" ? "none" : ""
                        }}
                    >{getFullHours}</div>
                </div>
            </div>

            <Popover
                id={anchorId}
                anchorEl={anchorEl}
                marginThreshold={16}
                classes={{
                    root: "popover-root",
                    paper: "popover-paper"
                }}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                open={open}
                onClose={handleClose}
                disableRestoreFocus
            >
                <div className="popover-wrap">
                    <div className="popover-indicator"
                         style={{ backgroundColor: item?.backgroundColor }} />

                    <div className="popover-info">
                        <div className="popover-title">{event?.title}</div>

                        <div className="popover-date">
                            <span>{moment(item?.date).format("DD MMMM")}</span>
                            <span>{getFullHours}</span>
                        </div>
                    </div>

                    <div className="popover-members">
                        <div className="popover-users">
                            <div className="popover-user">
                                {item?.initiator?.photo ? (
                                    <img src={item?.initiator?.photo} alt="" />
                                ) : (
                                    <div>{item?.initiator?.name?.[0]}</div>
                                )}
                            </div>

                            {item?.members?.map((i, idx) => {
                                if (idx > 1) return null;
                                return (
                                    <div key={idx} className="popover-user">
                                        {i?.photo ? <img src={i?.photo} alt="" /> :
                                            <div>{i?.name?.[0]}</div>}
                                    </div>
                                );
                            })}
                        </div>

                        <div className="popover-count">
                            {countUpdate} {getNoun(countUpdate, "участник", "участника", "участников")}
                        </div>
                    </div>
                </div>
            </Popover>
        </>
    );
};
