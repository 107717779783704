import { IApiHandlerParams } from "../../../interface";
import api from "../../../index";

export const $bookingCreate = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.post(`api/v1/booking`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $bookingEdit = (
    id: string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.put(`api/v1/booking/${id}`, data, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

export const $bookingDelete = (
    id: string,
    handler?: IApiHandlerParams
): Promise<any> =>
    api.delete(`api/v1/booking/${id}`, {
        // mock: require('./mocks/vacation.booking.json'),
        handler: {
            ...handler,
            newApi: true,
        }
    });

