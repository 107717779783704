import { useDispatch, useSelector } from "react-redux";
import {
    getPlan,
    getSelected, setPlan
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { useEffect, useState } from "react";
import { ClipIcon } from "../../../img/icons/ClipIcon";
import { failureNotify } from "../../../utils";
import { $mapSettingUploadFile } from "../../../api/requests/workplace/workplace-add";
import { PaperClipIcon } from "../../../img";

export const WorkspaceSettingHeaderPlanField = () => {
    const dispatch = useDispatch();
    const { floor } = useSelector(getSelected);
    const plan = useSelector(getPlan);

    const [imageDimensions, setImageDimensions] = useState<any>({ width: 0, height: 0, file: null });

    useEffect(() => {
        if (imageDimensions.width || imageDimensions.height) {
            if (
                (imageDimensions.width < 1920)
                || (imageDimensions.width > 1920)
                || (imageDimensions.height < 1080)
                || (imageDimensions.height > 1080)
            ) {
                failureNotify("Картинка должна быть размером 1920x1080");

                return;
            }

            $mapSettingUploadFile(
                floor ? floor.building_id : 0,
                floor ? floor.id : 0,
                { file: imageDimensions.file }
            ).then(res => {
                if (res && res.data) {
                    dispatch(setPlan(res.data.filename_url));
                }
            });
        }
    }, [imageDimensions]);

    const handleFile = (event: any) => {
        const file = event?.target?.files[0];

        if (file) {
            const reader = new FileReader();

            reader.onload = (event: any) => {
                const img: any = new Image();
                img.src = event.target.result;

                img.onload = () => {
                    const width = img.width;
                    const height = img.height;

                    setImageDimensions({ width, height, file });
                };
            };

            reader.readAsDataURL(file);
        }
    };

    return (
        <div className={"form-group services-workplace-plan"}>
            <label className={"form-label"} htmlFor={"file"}>Файл</label>

            <label className={"services-workplace-plan-block"} htmlFor={'file'}>
                <input type="file" accept="image/*" onChange={handleFile} id={"file"} />

                <div className={"services-workplace-plan-icon"}>
                    <PaperClipIcon color={"#00A7B5"} />
                </div>

                <div className={"services-workplace-plan-name"}>
                    {plan && (
                        (typeof plan === "string" && plan.includes("http"))
                            ? plan.split("/")[plan.split("/").length - 1]
                            : plan
                    )}

                    {!plan && "Загрузить план"}
                </div>
            </label>
        </div>
    );
};
