import TextField from "../../components/ui/form/Fields/TextField";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Button from "../../components/ui/form/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    getPersonData,
    getProfileVarsData,
} from "../../redux";
import { $mainPageNewData, $workSpaceEdit } from "../../api/requests/main";
import { successNotify } from "../../utils";
import TextArea from "../../components/ui/form/Fields/TextArea";


interface FormData {
    booking_mail_text: string;
    workplace_user_mail_text: string;
    workplace_admin_mail_text: string;
    workplace_admin_mail_text_forever: string;
}

const WorkspaceSettingBooking = () => {
    const dispatch = useDispatch();

    const vars = useSelector(getProfileVarsData);
    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const [editData, setEditData] = useState<any>(null);

    const {
        handleSubmit,
        control
    } = useForm<FormData>(
        {
            values: {
                booking_mail_text: editData?.notification_texts?.booking_mail_text || "",
                workplace_user_mail_text: editData?.notification_texts?.workplace_user_mail_text || "",
                workplace_admin_mail_text: editData?.notification_texts?.workplace_admin_mail_text || "",
                workplace_admin_mail_text_forever: editData?.notification_texts?.workplace_admin_mail_text_forever || "",
            }
        }
    );

    const removeUndefinedKeys = (obj: any) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== undefined)
        );
    };

    useEffect(() => {
        if (personalData && mainData) init();
    }, [personalData, mainData]);

    function init() {
        setEditData({ ...personalData, ...mainData });
    }

    function onSubmit(data: FormData) {
        const requestData: any = {
            ...removeUndefinedKeys(data),
            id: editData.workspace_id
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            $mainPageNewData().then((res) => {
                if (!res || res.error) return;

                dispatch(changeMainPage(res));
            });

            successNotify("Успешно сохранена").then();
        });
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form
                    className={"workspace-settings-users__accrual w-100"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__badges"}>
                        <h3>Пуши и письма</h3>

                        <div className={"workspace-settings__badges-block"}>

                            <Controller
                                name="booking_mail_text"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бронирование переговорок"
                                        placeholder={"Введите текст"}
                                        name="booking_mail_text"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="workplace_user_mail_text"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бронирование рабочего места от пользователя"
                                        placeholder={"Введите текст"}
                                        name="workplace_user_mail_text"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="workplace_admin_mail_text"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бронирование рабочего места от администратора"
                                        placeholder={"Введите текст"}
                                        name="workplace_admin_mail_text"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />

                        </div>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="workplace_admin_mail_text_forever"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бронирование постоянного рабочего места от администратора"
                                        placeholder={"Введите текст"}
                                        name="workplace_admin_mail_text_forever"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle d-flex gap-3 mt-4"}>
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>
        </div>
    );
};

export default WorkspaceSettingBooking;