import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const FolderIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.40142 8.41649L2.40133 17.9408C2.40131 19.0454 3.29675 19.9408 4.40132 19.9408L19.6001 19.9409C20.7046 19.9409 21.6 19.0455 21.6001 17.9409L21.6004 8.01226C21.6004 7.45996 21.1527 7.01223 20.6004 7.01223H12.0841L9.31905 4.05859H3.40077C2.84833 4.05859 2.40056 4.50581 2.40073 5.05824C2.40103 6.00905 2.40143 7.43597 2.40142 8.41649Z"
                stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
