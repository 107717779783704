import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { isMd } from "../../../utils";
import {
    getCurrentRole,
    getProfileVarsData, setPopupData
} from "../../../redux";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";

import styled from "./CalendarBlock.module.scss";
import { CalendarItem } from "../../vacation/CalendarBlock/CalendarItem";
import timeGridPlugin from "@fullcalendar/timegrid";

interface CalendarEventsWeekProps {
    calendarDataList: any[];
    selectionType: "timelineDay" | "dayGridWeek" | "dayGridMonth";
    isLoading: boolean;
}

export const CalendarEventsWeek = (
    {
        calendarDataList,
        selectionType = "dayGridWeek",
        isLoading
    }: CalendarEventsWeekProps
) => {
    const isMobile = isMd();

    const calendarRef = useRef<any>(null);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const slotMinTime = "08:00:00"
    const slotMaxTime = "21:00:00"

    const dispatch = useDispatch();
    const vars = useSelector(getProfileVarsData);

    const [transformData, setTransformData] = useState<any[]>([]);

    const findData = (id: string) => {
        const findItem = transformData?.find((item) => Number(item?.id) === Number(id));

        return findItem && findItem || null;
    };

    const handleOpenPopup = (data: any) => {
        dispatch(setPopupData(data));
    };

    const style: any = {
        "--lightBlue": vars.colors.secondaryColor
    };

    useEffect(() => {
        if (calendarDataList) {
            const listUpdate = [...calendarDataList].map((item, index) => {
                return {
                    ...item,
                    start: item.start_date,
                    end: item.end_date,
                    indicator: index
                };
            });

            setTransformData(listUpdate);
        }
    }, [calendarDataList]);

    // useEffect(() => {
    //     if (calendarRef.current) {
    //         calendarRef.current.getApi().gotoDate(month);
    //         calendarRef.current.getApi().render();
    //     }
    //
    //     return () => {
    //         calendarRef.current?.getApi().destroy();
    //     };
    // }, [month]);

    if (isLoading) return null;

    return (
        <div className={`calendar__wrapper calendar__events ${selectionType === "timelineDay" ? "services__booking__content" : ""} ${styled.wrapper}`} style={style}>
            <FullCalendar
                ref={calendarRef}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[dayGridPlugin, interactionPlugin, resourceTimelinePlugin, timeGridPlugin]}
                initialView={selectionType}
                // views={isWeek ? {} : {
                //     dayGridMonth: {
                //         type: 'month',
                //         duration: { months: 1 },
                //         monthMode: true,
                //         fixedWeekCount: true,
                //     }
                // }}
                locale="ru"
                headerToolbar={false}
                displayEventTime={selectionType === "timelineDay"}
                nowIndicator={selectionType === "timelineDay"}
                slotLabelFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                    omitZeroMinute: false,
                    meridiem: "short"
                }}
                slotLabelInterval="01:00"
                slotDuration="00:30:00"
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                slotLabelContent={({ text, date, view }) => {
                    const hours = date.getHours();

                    const modifiedText = hours % 2 === 0 ? text : '•';

                    return (
                        <span className="customTimeLine">
                            {modifiedText}
                            {/*<span>{slotMaxTime?.split(":")?.slice(0, 2)?.join(":") || "20:00"}</span>*/}
                        </span>
                    )
                }}
                eventTextColor="#000"
                eventBorderColor="transparent"
                firstDay={1}
                monthStartFormat={{
                    month: isMobile ? "2-digit" : "long",
                    day: "numeric"
                }}
                dayHeaderFormat={{
                    weekday: isMobile ? "short" : "long"
                }}
                dayHeaderClassNames={(day) =>
                    "fc-weekend"
                }
                dayMaxEventRows={4}
                moreLinkContent={({ num, shortText }) => (isMobile ? shortText : `+${num} скрыто`)}
                moreLinkHint={(number) => `Показать ещё ${number}`}
                closeHint="Закрыть"
                // select={({ start, startStr, endStr }) => {
                //     console.log(moment(start).isBefore(moment().subtract(1, "days")), 'select');
                //     // if (moment(start).isBefore(moment().subtract(1, "days"))) return;
                //
                //     handleOpenPopup({
                //         for_user: !isAdmin,
                //         start_date: startStr,
                //         end_date: endStr
                //     });
                // }}
                eventClick={(info) => {
                    handleOpenPopup({
                        ...findData(info?.event?.id),
                    });
                }}
                eventContent={(info) =>
                    <CalendarItem
                        data={findData(info?.event?.id)}
                        isEvents={["timelineDay", "dayGridWeek"].includes(selectionType)}
                    />
                }
                events={transformData}
                // selectable
            />
        </div>
    );
};
