import { FC } from "react";
import { EventsTypeIcon } from "../../../img";
import {modDate} from "../../../utils";
import {BlankImage} from "../../ui/blank-image/BlankImage";
import { useNavigate } from "react-router-dom";

interface EventsCard {
    id: number,
    title: string,
    end_date: any,
    cover: string,
    category?: {
        title: string;
        id: number;
    }
}

interface IFeedEventsCard {
    data: EventsCard
}
export const FeedEventsCard: FC<IFeedEventsCard> = ({
    data
}) => {
    const navigate = useNavigate();

    const {
        id,
        cover,
        category,
        title,
        end_date,
    } = data

    return (
        <div  className='feed-events-card' onClick={() => navigate(`/events/show/${id}`)}>
            <BlankImage image={cover} classname='feed-events-card__image'/>

            <div className='feed-events-card__backgroundLinear'></div>

            <div className='feed-events-card__content'>
                <div className='feed-events-card__type'>
                    <EventsTypeIcon/>

                    <h1>{category?.title}</h1>
                </div>

                <div className='feed-events-card__desc'>
                    <h1>{title}</h1>

                    <div className='feed-events-card__desc__date'>
                        {new Date(end_date).getDate()} {modDate((new Date(end_date).getMonth() + 1).toString())}
                    </div>
                </div>
            </div>
        </div>
    )
}