import { FC } from "react";
import { IBaseIconProps } from "../types";

export const WorkspaceOfficeManagerIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#060606"
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.1984 2.3999C11.1984 1.84762 10.7507 1.3999 10.1984 1.3999C9.64615 1.3999 9.19844 1.84762 9.19844 2.3999H11.1984ZM9.19844 21.5999C9.19844 22.1522 9.64615 22.5999 10.1984 22.5999C10.7507 22.5999 11.1984 22.1522 11.1984 21.5999H9.19844ZM5.99844 3.3999H17.9984V1.3999H5.99844V3.3999ZM20.5984 5.9999V17.9999H22.5984V5.9999H20.5984ZM17.9984 20.5999H5.99844V22.5999H17.9984V20.5999ZM3.39844 17.9999V5.9999H1.39844V17.9999H3.39844ZM5.99844 20.5999C4.5625 20.5999 3.39844 19.4358 3.39844 17.9999H1.39844C1.39844 20.5404 3.45793 22.5999 5.99844 22.5999V20.5999ZM20.5984 17.9999C20.5984 19.4358 19.4344 20.5999 17.9984 20.5999V22.5999C20.5389 22.5999 22.5984 20.5404 22.5984 17.9999H20.5984ZM17.9984 3.3999C19.4344 3.3999 20.5984 4.56396 20.5984 5.9999H22.5984C22.5984 3.45939 20.5389 1.3999 17.9984 1.3999V3.3999ZM5.99844 1.3999C3.45793 1.3999 1.39844 3.45939 1.39844 5.9999H3.39844C3.39844 4.56396 4.5625 3.3999 5.99844 3.3999V1.3999ZM9.19844 2.3999V21.5999H11.1984V2.3999H9.19844ZM10.1984 12.9999H20.9984V10.9999H10.1984V12.9999Z"
                fill={color}
            />
        </svg>
    );
};