import { FC, useState } from "react";
import { ArrowRightIcon, SettingIcon } from "../../img";
import { ILinks, isMd } from "../../utils";
import Button from "../ui/form/Buttons/Button";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../redux";
import LinksEditModal from "./links/LinksEditModal";

interface ILinksCard {
    data: ILinks[]
}
export const LinksCard: FC<ILinksCard> = ({
  data
}) => {
    const isMobile = isMd();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [isOpenModal, setIsOpenModal] = useState(false);

    if (isMobile) return (
        <div className='layout-links-mobile'>
            <div className='feed-controls feed-news__controls' >
                <h3>Полезные ссылки</h3>
            </div>

            <div className='layout-links__content'>
                {data.slice(0, 4).map((item, idx) => (
                    <div
                        key={idx}
                        className='layout-links__block cursor-pointer'
                        onClick={() => window.open(item.src)}
                    >
                        <div className='layout-links__block__image'>
                            {item.image ? (
                                <img src={item?.image} alt="" />
                            ) : (
                                <div className='layout-links__block__noneImage'>
                                    {item?.title[0]}
                                </div>
                            )}
                        </div>

                        <h1>{item.title}</h1>
                    </div>
                ))}
            </div>

            <div className='layout-links-mobile__button'>
                Показать все
            </div>
        </div>
    )

    return (
        <div className='layout-links'>
            <div className='layout-links__header'>
                <h1>Полезные ссылки</h1>

                {/*<div className='layout-links__header__more'>*/}
                {/*    Показать все*/}
                {/*</div>*/}

                {isAdmin && (
                    <Button
                        className={'btn btn-icon setting__icon'}
                        onClick={() => setIsOpenModal(true)}
                        text={<SettingIcon color={'black'} width={19} height={19} />}
                    />
                )}
            </div>

            <div className='layout-links__content'>
                {data.map((item, idx) => (
                    <div
                        key={idx}
                        className='layout-links__block cursor-pointer'
                        onClick={() => window.open(item.src)}
                    >
                        <div className='layout-links__block__image'>
                            {item.image ? (
                                <img src={item?.image} alt="" />
                            ) : (
                                <div className='layout-links__block__noneImage'>
                                    {item?.title[0]}
                                </div>
                            )}
                        </div>

                        <h1>{item.title}</h1>
                    </div>
                ))}
            </div>

            <LinksEditModal
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                data={data}
            />
        </div>
    )
}