import { useSelector } from "react-redux";
import { getProfileVarsData } from "../../../../../../redux";
import styled from "./Member.module.scss";
import Button from "../../../../../ui/form/Buttons/Button";
import AddIcon from "../../../../../../img/icons/vacation/AddIcon";
import CheckIcon from "../../../../../../img/icons/vacation/CheckIcon";
import { TrashIcon } from "../../../../../../img/icons/TrashIcon";
import { EmailIcon, PhoneIcon } from "../../../../../../img";
import { phoneNumberFieldMask } from "../../../../../../utils";

interface MemberData {
    photo: string;
    name: string;
    position?: string;
    mail?: string;
    phone?: string;
    isSelected?: boolean;
    type?: string;
    onClick?: () => void;
}

export const Member = (
    {
        photo,
        name,
        position,
        mail,
        phone,
        isSelected,
        type,
        onClick
    }: MemberData
) => {
    const vars = useSelector(getProfileVarsData);

    const style: any = {
        "--backgroundWrapper": type === "edit" ? "#ff00000d" : isSelected ? "#24FF000d" : `${vars.colors.secondaryColor}1a`,
        "--whiteColor": type === "edit" ? "#ff0000" : isSelected ? "#5CD149" : vars.colors.secondaryColor,
        "--lightBlue": type === "edit" ? "#ff00001a" : isSelected ? "#24FF001a" : `${vars.colors.secondaryColor}1a`
    };

    return (
        <div
            className={`calendar__member__wrapper ${styled.wrapper}`} style={style}
            onClick={() => onClick && onClick()}
        >
            <div className={"calendar__member__avatar"}>
                {photo && <img src={photo} />}

                {!photo && name && name.slice(0, 1)}
            </div>

            <div className={"calendar__member__info"}>
                <div className={"calendar__member__name"}>{name}</div>

                {position && <div className={"calendar__member__position"}>{position}</div>}
                {mail && <div className={"calendar__member__position"}>
                    <EmailIcon/> {mail}
                </div>}
                {phone && <div className={"calendar__member__position"}>
                    <PhoneIcon/> {phoneNumberFieldMask.getMaskedValue(phone)}
                </div>}
            </div>

            {type && (
                <Button
                    className={"calendar__member__icon"}
                    text={(
                        <>
                            {type === "add" && !isSelected && <AddIcon />}

                            {type === "add" && isSelected && <CheckIcon />}

                            {type === "edit" && <TrashIcon/>}
                        </>
                    )}
                />
            )}
        </div>
    );
};
