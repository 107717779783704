export const IconFourthPlace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path
                d="M6.22144 8.11246L8.34889 8.01296C8.88431 8.0177 9.1686 8.01296 9.24915 8.45836L9.70876 11.7893C9.72298 11.9267 9.93146 12.1068 9.97884 12.1873C10.1115 12.0831 10.1921 11.9173 10.1684 11.7372L9.78457 8.64788C9.68981 7.90872 9.05962 7.34961 8.31099 7.34961H6.23091V8.10773L6.22144 8.11246Z"
                fill="#DFBFB3" />
            <path
                d="M6.22603 7.38838V8.14175L6.4203 8.15123L7.43427 8.10859L8.32506 8.07068C8.3677 8.07068 8.41508 8.07068 8.45299 8.07068C8.76571 8.07068 8.98367 8.10385 9.09739 8.26969C9.14003 8.32654 9.17794 8.43078 9.19689 8.53502L9.36746 9.78591L9.57595 11.3164C9.57595 11.3164 9.6565 11.8423 9.67071 11.847C9.68493 11.9086 9.73231 11.975 9.78917 12.0366C9.8555 12.1076 9.92657 12.1692 9.955 12.2024C10.0877 12.0982 10.1682 11.9323 10.1445 11.7523L9.95027 10.2124L9.76074 8.67244C9.66597 7.93328 9.03579 7.37891 8.29189 7.37891H6.21655L6.22603 7.38838Z"
                fill="#DCBBAE" />
            <path
                d="M6.22607 7.41406V8.1627L6.40139 8.19587L7.41536 8.15797L8.30615 8.12479C8.34406 8.12479 8.40091 8.12479 8.43408 8.12479C8.74207 8.12479 8.95528 8.17218 9.06426 8.34749C9.10217 8.39961 9.14008 8.51807 9.15429 8.61283L9.32486 9.85898L9.53335 11.3847C9.53335 11.4179 9.62337 11.8869 9.6518 11.9012C9.67075 11.958 9.72288 12.0196 9.77973 12.0717C9.84607 12.1333 9.92188 12.1854 9.95505 12.2139C10.0877 12.1096 10.1635 11.9438 10.1398 11.7685L9.95031 10.2333L9.76078 8.70286C9.66602 7.96843 9.04057 7.41406 8.30141 7.41406H6.23555H6.22607Z"
                fill="#DAB7AA" />
            <path
                d="M6.22139 7.45117V8.19981L6.37301 8.25193L7.38699 8.21876L8.27304 8.19033C8.30621 8.19033 8.37254 8.19033 8.40097 8.19033C8.69948 8.19033 8.9127 8.25193 9.0122 8.43672C9.0501 8.4841 9.08327 8.61677 9.09749 8.70206L9.26332 9.94347L9.46707 11.4644C9.47181 11.5166 9.56183 11.9477 9.60447 11.9667C9.62817 12.0188 9.68029 12.0757 9.74188 12.1231C9.81296 12.1799 9.88877 12.2226 9.92194 12.2368C10.0546 12.1325 10.1304 11.9667 10.1067 11.7914L9.9172 10.2657L9.72767 8.73997C9.6329 8.00554 9.0122 7.45591 8.27304 7.45591H6.21191L6.22139 7.45117Z"
                fill="#D7B3A5" />
            <path
                d="M6.22144 7.47656V8.22047L6.35411 8.29154L7.36808 8.26311L8.25413 8.23942C8.28256 8.23942 8.35837 8.23942 8.38206 8.23942C8.67583 8.23942 8.88431 8.31523 8.97434 8.50475C9.00751 8.54266 9.04067 8.69902 9.05489 8.77009L9.22073 10.0068L9.42447 11.523C9.43394 11.5893 9.52397 11.9873 9.58557 12.011C9.614 12.0584 9.67085 12.1105 9.73245 12.1484C9.80352 12.1958 9.87934 12.229 9.92198 12.2385C10.0547 12.1342 10.1305 11.9684 10.1068 11.7978L9.91724 10.2768L9.72771 8.75588C9.63769 8.02619 9.01224 7.47656 8.27782 7.47656H6.22617H6.22144Z"
                fill="#D4AFA0" />
            <path
                d="M6.22144 7.50669V8.25059L6.33515 8.34535L7.34439 8.32166L8.23044 8.29797C8.25413 8.29797 8.33468 8.29797 8.35837 8.29797C8.64266 8.29797 8.85114 8.39273 8.93643 8.587C8.96486 8.62017 8.99803 8.79074 9.00751 8.85234L9.17334 10.0843L9.37235 11.591C9.38182 11.6763 9.48133 12.0364 9.55714 12.0649C9.59031 12.1122 9.6519 12.1549 9.7135 12.188C9.78931 12.226 9.86512 12.2496 9.90777 12.2496C10.0404 12.1501 10.1162 11.9843 10.0926 11.809L9.90303 10.2928L9.7135 8.77653C9.62347 8.04685 9.00277 7.50195 8.26834 7.50195H6.22144V7.50669Z"
                fill="#D1AA9B" />
            <path
                d="M6.22144 7.53516V8.27432L6.3162 8.38804L7.32544 8.36909L8.21149 8.35013H8.33942C8.61897 8.35013 8.82745 8.45911 8.89853 8.65812C8.92222 8.68655 8.95538 8.87133 8.96486 8.92819L9.12596 10.1601L9.32496 11.6669C9.33918 11.7664 9.43868 12.0933 9.52871 12.1265C9.56661 12.1691 9.62821 12.207 9.69455 12.2307C9.77036 12.2592 9.85091 12.2781 9.89355 12.2686C10.0215 12.1691 10.102 12.0033 10.0783 11.828L9.88881 10.3212L9.69928 8.80974C9.60926 8.08479 8.98855 7.5399 8.25887 7.5399H6.22144V7.53516Z"
                fill="#CFA697" />
            <path
                d="M6.22144 7.56055V8.29971L6.29725 8.43711L7.30649 8.41816L8.18779 8.40395C8.18779 8.40395 8.30151 8.40395 8.31573 8.40395C8.5858 8.40395 8.79429 8.52714 8.86062 8.73562C8.87957 8.75931 8.91274 8.95832 8.91748 9.00096L9.07858 10.2282L9.27285 11.7254C9.28706 11.8439 9.39604 12.1329 9.50028 12.1708C9.54292 12.2087 9.60926 12.2419 9.67559 12.2609C9.75614 12.2845 9.83195 12.2893 9.88407 12.2703C10.012 12.1708 10.0926 12.005 10.0689 11.8344L9.87934 10.3324L9.68981 8.83038C9.59978 8.11018 8.98381 7.56528 8.25413 7.56528H6.22144V7.56055Z"
                fill="#CCA192" />
            <path
                d="M6.22129 7.59737V8.33179L6.27815 8.48816L7.28265 8.47394L8.16396 8.46446C8.16396 8.46446 8.27767 8.46446 8.28715 8.46446C8.55249 8.46446 8.75623 8.60187 8.81309 8.81509C8.82731 8.83405 8.86048 9.052 8.86521 9.08043L9.02157 10.3029L9.21584 11.7954C9.23479 11.9281 9.34377 12.184 9.46223 12.2266C9.51435 12.2598 9.58068 12.2882 9.64702 12.2977C9.72757 12.3119 9.80812 12.3072 9.86024 12.2787C9.98817 12.1792 10.064 12.0181 10.0403 11.8428L9.8555 10.3455L9.66597 8.84826C9.57595 8.12805 8.95998 7.58789 8.23977 7.58789H6.21655L6.22129 7.59737Z"
                fill="#C99D8E" />
            <path
                d="M6.22144 7.62305V8.35747L6.25934 8.53753L7.26384 8.52805L8.14515 8.51857H8.26834C8.52421 8.51857 8.72795 8.6702 8.77533 8.89289C8.78481 8.90237 8.81798 9.13928 8.82272 9.16297L8.97908 10.3807L9.1686 11.8685C9.18756 12.0201 9.30127 12.2381 9.43868 12.2854C9.49554 12.3186 9.56188 12.3376 9.63295 12.3423C9.71824 12.347 9.79879 12.3328 9.85091 12.2997C9.97884 12.2002 10.0547 12.0391 10.031 11.8637L9.84617 10.3712L9.66138 8.87868C9.57135 8.16321 8.96012 7.62305 8.23992 7.62305H6.22144Z"
                fill="#C69989" />
            <path
                d="M6.22144 7.65205V8.38174L6.24039 8.58074H7.24489L8.1262 8.57126H8.24939C8.49578 8.57126 8.69952 8.7371 8.74216 8.96454C8.74216 8.96927 8.78007 9.2204 8.78007 9.23462L8.93169 10.4476L9.12122 11.9307C9.14491 12.0965 9.26337 12.2813 9.41025 12.3334C9.47185 12.3618 9.54292 12.3761 9.614 12.3713C9.70402 12.3713 9.77983 12.3476 9.84143 12.3002C9.96936 12.2007 10.0452 12.0396 10.0215 11.8691L9.83669 10.3813L9.6519 8.89347C9.56188 8.178 8.95065 7.64258 8.23518 7.64258H6.22617L6.22144 7.65205Z"
                fill="#C49585" />
            <path
                d="M6.22144 8.62845H8.2257C8.46735 8.62845 8.66635 8.8085 8.69952 9.04541L8.88431 10.5237L9.0691 12.0021C9.10227 12.2627 9.33444 12.4474 9.59504 12.4143C9.68507 12.4048 9.76562 12.3716 9.82722 12.3195C9.95515 12.22 10.031 12.0589 10.0073 11.8883L9.82248 10.41L9.63769 8.9317C9.54766 8.22097 8.94117 7.68555 8.2257 7.68555H6.22144V8.63319V8.62845Z"
                fill="#C19180" />
            <path
                d="M9.57599 8.93036C9.48596 8.22437 8.87947 7.73633 8.17348 7.73633H6.49141L6.22607 7.75528V7.83583L6.28767 8.62237H7.89393C7.89393 8.62237 8.24929 8.62237 8.25403 8.63185C8.41513 8.63659 8.55727 8.72661 8.63309 8.85928C8.66625 8.9114 8.69468 8.98721 8.70416 9.05829L8.88421 10.5129L8.89843 10.6219L9.06426 11.9628C9.08322 12.1144 9.18272 12.2755 9.31539 12.3371C9.38646 12.3845 9.46701 12.3987 9.5523 12.3892C9.63759 12.3798 9.71814 12.3466 9.78447 12.2992C9.90293 12.2092 9.96926 12.0481 9.94557 11.8728C9.94557 11.8444 9.77026 10.4561 9.76078 10.4182C9.7513 10.3471 9.57125 8.93036 9.57125 8.93036H9.57599Z"
                fill="#BA8B7B" />
            <path
                d="M9.51449 8.93603C9.42447 8.23478 8.82271 7.79413 8.11672 7.79887H6.46309L6.22144 7.84151V7.92206L6.34463 8.63279H7.92245C7.92245 8.63279 8.26361 8.63752 8.27782 8.65174C8.43418 8.66595 8.56685 8.75598 8.63793 8.88865C8.67109 8.93603 8.69478 9.02132 8.70426 9.08292L8.88431 10.5139L8.89853 10.6228L9.06436 11.9401C9.08332 12.0775 9.17808 12.2623 9.2918 12.3191C9.35813 12.376 9.42921 12.3902 9.51449 12.3807C9.59504 12.3712 9.67085 12.3381 9.74193 12.2954C9.85091 12.2149 9.9125 12.0585 9.88881 11.8785C9.88407 11.8216 9.72298 10.5281 9.70876 10.4475C9.68981 10.3054 9.51449 8.95025 9.51449 8.95025V8.93603Z"
                fill="#B48575" />
            <path
                d="M9.44816 8.93661C9.35813 8.24009 8.76112 7.85156 8.05986 7.85156H6.43466L6.22144 7.9179V7.99845L6.40623 8.62863H7.95562C7.98405 8.62863 8.28256 8.6381 8.30151 8.65231C8.45313 8.67127 8.57633 8.76603 8.64266 8.8987C8.67109 8.94135 8.69478 9.03611 8.70426 9.08823L8.87957 10.4955L8.89379 10.6045L9.05489 11.8933C9.0691 12.0165 9.15913 12.2249 9.26337 12.2723C9.32497 12.3386 9.3913 12.3576 9.46711 12.3434C9.54292 12.3339 9.61874 12.3007 9.69455 12.2581C9.79405 12.187 9.84617 12.0307 9.82248 11.8459C9.813 11.7653 9.66138 10.5618 9.64243 10.4386C9.60926 10.2254 9.44342 8.93661 9.44342 8.93661H9.44816Z"
                fill="#AD7E70" />
            <path
                d="M9.38642 8.94308C9.29639 8.2513 8.69938 7.91016 8.00286 7.91016H6.40608L6.21655 7.99544V8.08073L6.46768 8.63037H7.98864C8.02655 8.63037 8.29663 8.64458 8.32506 8.66353C8.4672 8.69196 8.58566 8.78673 8.64726 8.9194C8.67095 8.9573 8.69464 9.06154 8.70411 9.10892L8.87469 10.4925L8.8889 10.5967L9.04527 11.8618C9.05948 11.9708 9.14477 12.1982 9.23479 12.2409C9.29165 12.3167 9.34851 12.3356 9.42432 12.3214C9.4954 12.312 9.57121 12.2788 9.65176 12.2409C9.74178 12.1793 9.78443 12.0229 9.76074 11.8334C9.75126 11.7244 9.60911 10.6157 9.58542 10.4498C9.54278 10.1655 9.38168 8.93835 9.38168 8.93835L9.38642 8.94308Z"
                fill="#A7796A" />
            <path
                d="M9.32496 8.94566C9.23968 8.25388 8.63792 7.96484 7.95088 7.96484H6.38253L6.22144 8.07382V8.15911L6.53416 8.63293H8.0267C8.07408 8.63293 8.32047 8.64715 8.35837 8.67558C8.49578 8.70874 8.6095 8.80824 8.66162 8.94091C8.68057 8.97408 8.70426 9.0878 8.71374 9.1257L8.88431 10.4808L8.89853 10.5851L9.05489 11.8265C9.0691 11.9165 9.14491 12.1724 9.22546 12.2103C9.27758 12.2956 9.32497 12.3145 9.40078 12.305C9.46711 12.2956 9.53818 12.2624 9.62347 12.2292C9.70402 12.1724 9.74193 12.0208 9.71824 11.8312C9.70402 11.6938 9.57609 10.6798 9.54766 10.4714C9.49554 10.116 9.33918 8.95039 9.33918 8.95039L9.32496 8.94566Z"
                fill="#A07265" />
            <path
                d="M9.26337 8.95136C9.17808 8.26432 8.58107 8.02267 7.89403 8.02741C7.87033 8.02741 7.77083 8.02741 7.7424 8.02741H6.35411L6.22144 8.15535V8.24063L6.59575 8.6339H8.05986C8.11672 8.6339 8.33942 8.65286 8.38206 8.68602C8.51473 8.72867 8.61897 8.82817 8.66635 8.96084C8.68531 8.98927 8.709 9.10772 8.71374 9.14089L8.87957 10.4723L8.89379 10.5766L9.04541 11.7896C9.05489 11.8654 9.1307 12.1449 9.19703 12.1733C9.24442 12.2729 9.28706 12.2871 9.35339 12.2776C9.41499 12.2681 9.48607 12.2349 9.57609 12.2018C9.64716 12.1544 9.67559 12.0075 9.6519 11.8085C9.63295 11.6427 9.51449 10.7235 9.48133 10.4771C9.41973 10.0506 9.26811 8.95136 9.26811 8.95136H9.26337Z"
                fill="#9A6C5F" />
            <path
                d="M9.19703 8.95777C9.11175 8.27546 8.51947 8.08594 7.83717 8.08594C7.80874 8.08594 7.71871 8.08594 7.69028 8.08594H6.33041L6.22144 8.23756V8.32285L6.65735 8.63557H8.09303C8.15936 8.63557 8.35837 8.65926 8.40575 8.69243C8.52895 8.73981 8.62845 8.84405 8.67109 8.97672C8.68531 8.99568 8.709 9.12835 8.71374 9.15678L8.87484 10.4645L8.88905 10.5688L9.03594 11.7581C9.04541 11.8196 9.11175 12.1182 9.16387 12.1418C9.20177 12.2508 9.23968 12.2698 9.30601 12.2556C9.36287 12.2461 9.42921 12.2129 9.52397 12.1845C9.58083 12.1466 9.60452 11.9997 9.58083 11.796C9.56188 11.6017 9.4529 10.7772 9.41025 10.4882C9.33918 9.9907 9.1923 8.95303 9.1923 8.95303L9.19703 8.95777Z"
                fill="#936659" />
            <path
                d="M9.13529 8.96034C9.05 8.28278 8.45773 8.14062 7.78016 8.14062C7.747 8.14062 7.66644 8.14062 7.63328 8.14062H6.2971L6.21655 8.3112V8.39649L6.71406 8.6334H8.12131C8.19713 8.6334 8.3677 8.65709 8.4293 8.69974C8.54775 8.7566 8.64252 8.86083 8.67568 8.9935C8.68516 9.00772 8.70885 9.14987 8.70885 9.16882L8.86995 10.4529L8.88417 10.5524L9.03105 11.7132C9.03579 11.7606 9.10212 12.0828 9.14003 12.1018C9.1732 12.2202 9.20163 12.2392 9.26322 12.225C9.31534 12.2155 9.38168 12.1823 9.48118 12.1586C9.52856 12.1302 9.54752 11.9833 9.52383 11.7796C9.50013 11.5616 9.40537 10.8272 9.35799 10.4955C9.2727 9.92693 9.13529 8.9556 9.13529 8.9556V8.96034Z"
                fill="#8D6054" />
            <path
                d="M9.07374 8.95833C8.98845 8.2855 8.39617 8.19074 7.72809 8.19548C7.69018 8.19548 7.62385 8.19548 7.58594 8.19548H6.27819L6.22607 8.38974V8.47503L6.78518 8.63139H8.164C8.24929 8.63139 8.39617 8.65982 8.46251 8.7072C8.57623 8.7688 8.66152 8.87778 8.68995 9.01045C8.69468 9.01993 8.71837 9.17155 8.71837 9.18576L8.87474 10.4461L8.88895 10.5456L9.0311 11.6828C9.0311 11.716 9.09269 12.0619 9.12112 12.0713C9.14955 12.1993 9.1685 12.223 9.22536 12.2087C9.27274 12.1993 9.33908 12.1661 9.44332 12.1471C9.48123 12.1282 9.4907 11.9813 9.46701 11.7728C9.43858 11.5264 9.35803 10.882 9.30591 10.5125C9.21115 9.8728 9.07848 8.96306 9.07848 8.96306L9.07374 8.95833Z"
                fill="#865A4F" />
            <path
                d="M9.01224 8.96492C8.92695 8.29683 8.33942 8.24945 7.67607 8.25419C7.63342 8.25419 7.57656 8.25419 7.53392 8.25419H6.24987L6.22144 8.46741V8.5527L6.84214 8.63325H8.19253C8.2873 8.63325 8.41049 8.66641 8.48156 8.71853C8.59054 8.78961 8.66635 8.89859 8.69005 9.03126C8.69005 9.03599 8.71374 9.19709 8.71847 9.20183L8.87484 10.4385L8.88905 10.538L9.02646 11.6515C9.02646 11.6515 9.08332 12.0353 9.09753 12.04C9.12122 12.1774 9.13544 12.2011 9.18756 12.1869C9.2302 12.1774 9.2918 12.1443 9.40078 12.1253C9.42921 12.1158 9.42921 11.9737 9.40552 11.7557C9.37709 11.4809 9.30601 10.9313 9.24442 10.5191C9.14018 9.80832 9.01224 8.96019 9.01224 8.96019V8.96492Z"
                fill="#80554A" />
            <path
                d="M6.22144 8.6297H8.2257C8.46735 8.6297 8.66635 8.80974 8.69952 9.04665L8.88431 10.525L9.0691 12.0033C9.10227 12.2639 9.09279 12.1407 9.35339 12.1028C9.40078 12.1028 9.29654 11.3115 9.18282 10.5297C9.0691 9.74791 8.94591 8.9661 8.94591 8.9661C8.85588 8.25537 8.19253 8.30749 7.47706 8.30749H6.22144V8.6297Z"
                fill="#794D43" />
            <path
                d="M7.15003 8.11246L5.02257 8.01296C4.48715 8.0177 4.20285 8.01296 4.12231 8.45836L3.6627 11.7893C3.64849 11.9267 3.44 12.1068 3.39262 12.1873C3.25995 12.0831 3.1794 11.9173 3.20309 11.7372L3.58689 8.64788C3.68165 7.90872 4.31183 7.34961 5.06047 7.34961H7.14055V8.10773L7.15003 8.11246Z"
                fill="#DFBFB3" />
            <path
                d="M7.14524 7.38838V8.14175L6.95098 8.15123L5.937 8.10859L5.04622 8.07068C5.00357 8.07068 4.95619 8.07068 4.91355 8.07068C4.60082 8.07068 4.38287 8.10385 4.26915 8.26969C4.22177 8.32654 4.1886 8.43078 4.16965 8.53502L3.99907 9.78591L3.79059 11.3164C3.79059 11.3164 3.71004 11.8423 3.69583 11.847C3.68161 11.9086 3.63423 11.975 3.57737 12.0366C3.51103 12.1076 3.43996 12.1692 3.40679 12.2024C3.27412 12.0982 3.19357 11.9323 3.222 11.7523L3.41153 10.2124L3.6058 8.67244C3.70056 7.93328 4.33074 7.37891 5.06991 7.37891H7.14524V7.38838Z"
                fill="#DCBBAE" />
            <path
                d="M7.14536 7.41406V8.1627L6.97005 8.19587L5.95607 8.15797L5.06529 8.12479C5.02738 8.12479 4.97052 8.12479 4.93736 8.12479C4.62937 8.12479 4.41615 8.17218 4.30717 8.34749C4.26927 8.39961 4.23136 8.51807 4.21715 8.61283L4.04657 9.85898L3.83809 11.3847C3.83809 11.4179 3.74806 11.8869 3.71964 11.9012C3.70068 11.958 3.64856 12.0196 3.5917 12.0717C3.52537 12.1333 3.44956 12.1854 3.41639 12.2139C3.28372 12.1096 3.20791 11.9438 3.2316 11.7685L3.42113 10.2333L3.61066 8.70286C3.70542 7.96843 4.33086 7.41406 5.07003 7.41406H7.13589H7.14536Z"
                fill="#DAB7AA" />
            <path
                d="M7.14999 7.45117V8.19981L6.99363 8.25193L5.97966 8.21876L5.09361 8.19033C5.06044 8.19033 4.99411 8.19033 4.96568 8.19033C4.66717 8.19033 4.45395 8.25193 4.35445 8.43672C4.31654 8.4841 4.28338 8.61677 4.26916 8.70206L4.10332 9.94347L3.89958 11.4644C3.89484 11.5166 3.80482 11.9477 3.76217 11.9667C3.73848 12.0188 3.68636 12.0757 3.62476 12.1231C3.55369 12.1799 3.47788 12.2226 3.44471 12.2368C3.31204 12.1325 3.23623 11.9667 3.25992 11.7914L3.44945 10.2657L3.63898 8.73997C3.73374 8.00554 4.35445 7.45591 5.09361 7.45591H7.15473L7.14999 7.45117Z"
                fill="#D7B3A5" />
            <path
                d="M7.14999 7.47656V8.22047L7.01732 8.29154L6.00335 8.26311L5.1173 8.23942C5.08887 8.23942 5.01306 8.23942 4.98937 8.23942C4.6956 8.23942 4.48712 8.31523 4.39235 8.50475C4.35919 8.54266 4.32602 8.69902 4.3118 8.77009L4.14597 10.0068L3.94222 11.523C3.93275 11.5893 3.84272 11.9873 3.78113 12.011C3.7527 12.0584 3.69584 12.1105 3.63424 12.1484C3.56317 12.1958 3.48735 12.229 3.44471 12.2385C3.31204 12.1342 3.23623 11.9684 3.25992 11.7978L3.44945 10.2768L3.63898 8.75588C3.729 8.02619 4.35445 7.47656 5.08887 7.47656H7.14052H7.14999Z"
                fill="#D4AFA0" />
            <path
                d="M7.14999 7.50669V8.25059L7.03627 8.34535L6.02703 8.32166L5.14098 8.29797C5.11729 8.29797 5.03674 8.29797 5.01305 8.29797C4.72876 8.29797 4.52028 8.39273 4.43499 8.587C4.40656 8.62017 4.37339 8.79074 4.36392 8.85234L4.20282 10.0843L4.00381 11.591C3.99433 11.6763 3.89483 12.0364 3.81902 12.0649C3.78585 12.1122 3.72426 12.1549 3.66266 12.188C3.58685 12.226 3.51104 12.2496 3.46839 12.2496C3.33572 12.1501 3.25991 11.9843 3.2836 11.809L3.47313 10.2928L3.66266 8.77653C3.75269 8.04685 4.37339 7.50195 5.10782 7.50195H7.15472L7.14999 7.50669Z"
                fill="#D1AA9B" />
            <path
                d="M7.15009 7.53516V8.27432L7.05533 8.38804L6.04609 8.36909L5.16005 8.35013H5.03211C4.75256 8.35013 4.54882 8.45911 4.473 8.65812C4.44931 8.68655 4.41615 8.87133 4.40667 8.92819L4.24557 10.1601L4.04656 11.6669C4.03235 11.7664 3.93285 12.0933 3.84282 12.1265C3.80492 12.1691 3.74332 12.207 3.67698 12.2307C3.60117 12.2592 3.52062 12.2781 3.47798 12.2686C3.35005 12.1691 3.2695 12.0033 3.29319 11.828L3.48272 10.3212L3.67225 8.80974C3.76227 8.08479 4.38298 7.5399 5.11266 7.5399H7.15009V7.53516Z"
                fill="#CFA697" />
            <path
                d="M7.15014 7.56055V8.29971L7.07433 8.43711L6.06509 8.41816L5.18378 8.40395C5.18378 8.40395 5.07006 8.40395 5.05585 8.40395C4.78577 8.40395 4.57729 8.52714 4.51095 8.73562C4.492 8.75931 4.45883 8.95832 4.4541 9.00096L4.293 10.2282L4.09873 11.7254C4.08452 11.8439 3.97554 12.1329 3.8713 12.1708C3.82865 12.2087 3.76232 12.2419 3.69598 12.2609C3.61543 12.2845 3.53962 12.2893 3.4875 12.2703C3.35957 12.1708 3.27902 12.005 3.30271 11.8344L3.49224 10.3324L3.68177 8.83038C3.77179 8.11018 4.38776 7.56528 5.11745 7.56528H7.15014V7.56055Z"
                fill="#CCA192" />
            <path
                d="M7.15 7.59737V8.33179L7.09314 8.48816L6.08864 8.47394L5.20733 8.46446C5.20733 8.46446 5.09362 8.46446 5.0794 8.46446C4.81406 8.46446 4.61032 8.60187 4.55346 8.81509C4.53925 8.83405 4.50608 9.052 4.50134 9.08043L4.34498 10.3029L4.15071 11.7954C4.13176 11.9281 4.02278 12.184 3.90433 12.2266C3.85221 12.2598 3.78587 12.2882 3.71954 12.2977C3.63899 12.3119 3.55844 12.3072 3.50632 12.2787C3.37838 12.1792 3.29783 12.0181 3.32626 11.8428L3.51579 10.3455L3.70532 8.84826C3.79535 8.12805 4.41132 7.58789 5.13152 7.58789H7.15474L7.15 7.59737Z"
                fill="#C99D8E" />
            <path
                d="M7.15002 7.62305V8.35747L7.11211 8.53753L6.10761 8.52805L5.2263 8.51857H5.10311C4.84725 8.51857 4.6435 8.6702 4.59612 8.89289C4.58664 8.90237 4.55348 9.13928 4.54874 9.16297L4.39238 10.3807L4.20285 11.8685C4.1839 12.0201 4.07018 12.2381 3.93277 12.2854C3.87591 12.3186 3.80958 12.3376 3.73851 12.3423C3.65322 12.347 3.57267 12.3328 3.52055 12.2997C3.39261 12.2002 3.3168 12.0391 3.3405 11.8637L3.52529 10.3712L3.71008 8.87868C3.8001 8.16321 4.41133 7.62305 5.13154 7.62305H7.15002Z"
                fill="#C69989" />
            <path
                d="M7.15006 7.65205V8.38174L7.13111 8.58074H6.12661L5.2453 8.57126H5.12211C4.87098 8.57126 4.67198 8.7371 4.62933 8.96454C4.62933 8.96927 4.59143 9.2204 4.59143 9.23462L4.43981 10.4476L4.25028 11.9307C4.22659 12.0965 4.10813 12.2813 3.96125 12.3334C3.89965 12.3618 3.82858 12.3761 3.7575 12.3713C3.67221 12.3713 3.59166 12.3476 3.53007 12.3002C3.40214 12.2007 3.32633 12.0396 3.35002 11.8691L3.53481 10.3813L3.7196 8.89347C3.80962 8.178 4.42085 7.64258 5.13632 7.64258H7.14532L7.15006 7.65205Z"
                fill="#C49585" />
            <path
                d="M7.15001 8.62845H5.14574C4.9041 8.62845 4.70509 8.8085 4.67192 9.04541L4.48713 10.5237L4.30234 12.0021C4.26918 12.2627 4.037 12.4474 3.7764 12.4143C3.68638 12.4048 3.60583 12.3716 3.54423 12.3195C3.4163 12.22 3.34049 12.0589 3.36418 11.8883L3.54897 10.41L3.73376 8.9317C3.82378 8.22097 4.43027 7.68555 5.14574 7.68555H7.15001V8.63319V8.62845Z"
                fill="#C19180" />
            <path
                d="M3.79535 8.93036C3.88537 8.22437 4.49186 7.73633 5.19786 7.73633H6.87992L7.14526 7.75528V7.83583L7.08366 8.62237H5.47741C5.47741 8.62237 5.12204 8.62237 5.11257 8.63185C4.95147 8.63659 4.81406 8.72661 4.73351 8.85928C4.70034 8.9114 4.67192 8.98721 4.66244 9.05829L4.48239 10.5129L4.46817 10.6219L4.30233 11.9628C4.28338 12.1144 4.18388 12.2755 4.05121 12.3371C3.98014 12.3845 3.89959 12.3987 3.8143 12.3892C3.72901 12.3798 3.64846 12.3466 3.58213 12.2992C3.46367 12.2092 3.39733 12.0481 3.42103 11.8728C3.42103 11.8444 3.59634 10.4561 3.60582 10.4182C3.61529 10.3471 3.79535 8.93036 3.79535 8.93036Z"
                fill="#BA8B7B" />
            <path
                d="M3.857 8.93603C3.94703 8.23478 4.54878 7.79413 5.25477 7.79887H6.90841L7.15006 7.84151V7.92206L7.02686 8.63279H5.44904C5.44904 8.63279 5.10789 8.63752 5.09367 8.65174C4.93731 8.66595 4.80464 8.75598 4.73357 8.88865C4.7004 8.93603 4.67671 9.02132 4.66723 9.08292L4.48718 10.5139L4.47297 10.6228L4.30713 11.9401C4.28818 12.0775 4.19341 12.2623 4.07496 12.3191C4.00862 12.376 3.93755 12.3902 3.85226 12.3807C3.77171 12.3712 3.6959 12.3381 3.62483 12.2954C3.51585 12.2149 3.45425 12.0585 3.47794 11.8785C3.48268 11.8216 3.64378 10.5281 3.65799 10.4475C3.67695 10.3054 3.85226 8.95025 3.85226 8.95025L3.857 8.93603Z"
                fill="#B48575" />
            <path
                d="M3.9233 8.93661C4.01332 8.24009 4.61034 7.85156 5.31159 7.85156H6.9368L7.15002 7.9179V7.99845L6.96523 8.62863H5.41583C5.3874 8.62863 5.0889 8.6381 5.06994 8.65231C4.91832 8.67127 4.79513 8.76603 4.72879 8.8987C4.70036 8.94135 4.67667 9.03611 4.6672 9.08823L4.49188 10.4955L4.47767 10.6045L4.31657 11.8933C4.30235 12.0165 4.21233 12.2249 4.10809 12.2723C4.04649 12.3386 3.98016 12.3576 3.89961 12.3434C3.82379 12.3339 3.74798 12.3007 3.67217 12.2581C3.57267 12.187 3.52055 12.0307 3.54424 11.8459C3.55372 11.7653 3.70534 10.5618 3.72429 10.4386C3.75746 10.2254 3.9233 8.93661 3.9233 8.93661Z"
                fill="#AD7E70" />
            <path
                d="M3.98478 8.94308C4.07007 8.2513 4.67182 7.91016 5.36834 7.91016H6.96512L7.15464 7.99544V8.08073L6.90352 8.63037H5.38255C5.34465 8.63037 5.07457 8.64458 5.04614 8.66353C4.90399 8.69196 4.78554 8.78673 4.72394 8.9194C4.70025 8.9573 4.67656 9.06154 4.66708 9.10892L4.49651 10.4925L4.48229 10.5967L4.32593 11.8618C4.31172 11.9708 4.22643 12.1982 4.1364 12.2409C4.07955 12.3167 4.02269 12.3356 3.94687 12.3214C3.8758 12.312 3.79999 12.2788 3.71944 12.2409C3.62941 12.1793 3.58677 12.0229 3.61046 11.8334C3.61994 11.7244 3.76208 10.6157 3.78578 10.4498C3.82842 10.1655 3.98952 8.93835 3.98952 8.93835L3.98478 8.94308Z"
                fill="#A7796A" />
            <path
                d="M4.04645 8.94566C4.13174 8.25388 4.72875 7.96484 5.42053 7.96484H6.98888L7.14998 8.07382V8.15911L6.83726 8.63293H5.34472C5.29734 8.63293 5.05095 8.64715 5.01304 8.67558C4.87564 8.70874 4.76192 8.80824 4.7098 8.94091C4.69085 8.97408 4.66716 9.0878 4.65768 9.1257L4.4871 10.4808L4.47289 10.5851L4.31653 11.8265C4.30705 11.9165 4.2265 12.1724 4.14595 12.2103C4.09383 12.2956 4.04645 12.3145 3.97538 12.305C3.90904 12.2956 3.83797 12.2624 3.75268 12.2292C3.67687 12.1724 3.63422 12.0208 3.65792 11.8312C3.67213 11.6938 3.80006 10.6798 3.82849 10.4714C3.88061 10.116 4.03697 8.95039 4.03697 8.95039L4.04645 8.94566Z"
                fill="#A07265" />
            <path
                d="M4.10805 8.95136C4.19334 8.26432 4.79035 8.02267 5.47739 8.02741C5.50109 8.02741 5.60059 8.02741 5.62902 8.02741H7.01731L7.14999 8.15535V8.24063L6.77567 8.6339H5.31156C5.2547 8.6339 5.032 8.65286 4.98936 8.68602C4.85669 8.72867 4.75245 8.82817 4.70507 8.96084C4.68611 8.98927 4.66242 9.10772 4.65768 9.14089L4.49185 10.4723L4.47763 10.5766L4.32601 11.7896C4.31653 11.8654 4.24072 12.1449 4.17913 12.1733C4.13174 12.2729 4.0891 12.2871 4.02276 12.2776C3.96117 12.2681 3.89009 12.2349 3.80007 12.2018C3.72899 12.1544 3.70056 12.0075 3.72426 11.8085C3.74321 11.6427 3.85693 10.7235 3.89483 10.4771C3.95643 10.0506 4.10805 8.95136 4.10805 8.95136Z"
                fill="#9A6C5F" />
            <path
                d="M4.17455 8.95777C4.25984 8.27546 4.85686 8.08594 5.53442 8.08594C5.56285 8.08594 5.65288 8.08594 5.68131 8.08594H7.04117L7.15015 8.23756V8.32285L6.71424 8.63557H5.27856C5.21222 8.63557 5.01322 8.65926 4.96584 8.69243C4.8379 8.73981 4.74314 8.84405 4.7005 8.97672C4.68628 8.99568 4.66259 9.12835 4.66259 9.15678L4.50149 10.4645L4.48728 10.5688L4.34039 11.7581C4.33092 11.8196 4.26458 12.1182 4.21246 12.1418C4.17455 12.2508 4.13665 12.2698 4.07031 12.2556C4.01346 12.2461 3.94238 12.2129 3.85235 12.1845C3.7955 12.1466 3.77181 11.9997 3.7955 11.796C3.81445 11.6017 3.92343 10.7772 3.96133 10.4882C4.03241 9.9907 4.17929 8.95303 4.17929 8.95303L4.17455 8.95777Z"
                fill="#936659" />
            <path
                d="M4.23599 8.96034C4.32128 8.28278 4.91356 8.14062 5.59112 8.14062C5.62429 8.14062 5.70484 8.14062 5.738 8.14062H7.07418L7.15473 8.3112V8.39649L6.65722 8.6334H5.24997C5.17416 8.6334 5.00358 8.65709 4.94198 8.69974C4.82353 8.7566 4.72877 8.86083 4.6956 8.9935C4.68612 9.00772 4.66243 9.14987 4.65769 9.16882L4.49659 10.4529L4.48238 10.5524L4.33549 11.7132C4.33076 11.7606 4.26442 12.0828 4.22651 12.1018C4.19335 12.2202 4.16492 12.2392 4.10332 12.225C4.0512 12.2155 3.98487 12.1823 3.88536 12.1586C3.83798 12.1302 3.81903 11.9833 3.84272 11.7796C3.86641 11.5616 3.96118 10.8272 4.00856 10.4955C4.09385 9.92693 4.23125 8.9556 4.23125 8.9556L4.23599 8.96034Z"
                fill="#8D6054" />
            <path
                d="M4.29775 8.95833C4.38303 8.2855 4.97057 8.19074 5.6434 8.19548C5.6813 8.19548 5.74764 8.19548 5.78554 8.19548H7.09329L7.14541 8.38974V8.47503L6.5863 8.63139H5.20748C5.12219 8.63139 4.97531 8.65982 4.90897 8.7072C4.79526 8.7688 4.70997 8.87778 4.68154 9.01045C4.6768 9.01993 4.65311 9.17155 4.64837 9.18576L4.49201 10.4461L4.4778 10.5456L4.33565 11.6828C4.33565 11.716 4.27405 12.0619 4.24562 12.0713C4.2172 12.1993 4.19824 12.223 4.13665 12.2087C4.08926 12.1993 4.02293 12.1661 3.91869 12.1471C3.88078 12.1282 3.87131 11.9813 3.895 11.7728C3.91869 11.5264 4.00398 10.882 4.0561 10.5125C4.15086 9.8728 4.28353 8.96306 4.28353 8.96306L4.29775 8.95833Z"
                fill="#865A4F" />
            <path
                d="M4.35922 8.96492C4.44451 8.29683 5.03204 8.24945 5.69539 8.25419C5.73804 8.25419 5.7949 8.25419 5.83754 8.25419H7.1216L7.15003 8.46741V8.5527L6.52932 8.63325H5.17893C5.08416 8.63325 4.96097 8.66641 4.8899 8.71853C4.78092 8.78961 4.70511 8.89859 4.67668 9.03126C4.67668 9.03599 4.65299 9.19709 4.65299 9.20183L4.49663 10.4385L4.48241 10.538L4.345 11.6515C4.345 11.6515 4.28814 12.0353 4.27393 12.04C4.25024 12.1774 4.23603 12.2011 4.1839 12.1869C4.14126 12.1774 4.07966 12.1443 3.97068 12.1253C3.94226 12.1158 3.94226 11.9737 3.96595 11.7557C3.99438 11.4809 4.06545 10.9313 4.12705 10.5191C4.23129 9.80832 4.35922 8.96019 4.35922 8.96019V8.96492Z"
                fill="#80554A" />
            <path
                d="M7.15009 8.6297H5.14583C4.90418 8.6297 4.70517 8.80974 4.67201 9.04665L4.48722 10.525L4.30243 12.0033C4.26926 12.2639 4.27874 12.1407 4.01813 12.1028C3.97549 12.1028 4.07499 11.3115 4.18871 10.5297C4.30243 9.74791 4.42562 8.9661 4.42562 8.9661C4.51565 8.25537 5.179 8.30749 5.89447 8.30749H7.15009V8.6297Z"
                fill="#794D43" />
            <path
                d="M6.69519 20.0002C9.57602 20.0002 11.9072 17.6643 11.9072 14.7882C11.9072 11.9121 9.57602 9.57617 6.69519 9.57617C3.81436 9.57617 1.48315 11.9121 1.48315 14.7882C1.48315 17.6643 3.81436 20.0002 6.69519 20.0002Z"
                fill="url(#paint0_linear_14442_46079)" />
            <path
                d="M6.69522 19.0945C9.0738 19.0945 11.0023 17.1661 11.0023 14.7875C11.0023 12.4089 9.0738 10.4805 6.69522 10.4805C4.31664 10.4805 2.38818 12.4089 2.38818 14.7875C2.38818 17.1661 4.31664 19.0945 6.69522 19.0945Z"
                fill="url(#paint1_linear_14442_46079)" />
            <path
                d="M6.69518 18.6438C8.82264 18.6438 10.5474 16.9191 10.5474 14.7916C10.5474 12.6642 8.82264 10.9395 6.69518 10.9395C4.56773 10.9395 2.84302 12.6642 2.84302 14.7916C2.84302 16.9191 4.56773 18.6438 6.69518 18.6438Z"
                fill="url(#paint2_linear_14442_46079)" />
            <path
                d="M6.69519 20.0002C9.57602 20.0002 11.9072 17.6643 11.9072 14.7882C11.9072 11.9121 9.57602 9.57617 6.69519 9.57617C3.81436 9.57617 1.48315 11.9121 1.48315 14.7882C1.48315 17.6643 3.81436 20.0002 6.69519 20.0002Z"
                fill="url(#paint3_radial_14442_46079)" />
            <path
                d="M6.69522 19.0945C9.0738 19.0945 11.0023 17.1661 11.0023 14.7875C11.0023 12.4089 9.0738 10.4805 6.69522 10.4805C4.31664 10.4805 2.38818 12.4089 2.38818 14.7875C2.38818 17.1661 4.31664 19.0945 6.69522 19.0945Z"
                fill="url(#paint4_radial_14442_46079)" />
            <path
                d="M6.69522 19.0945C9.0738 19.0945 11.0023 17.1661 11.0023 14.7875C11.0023 12.4089 9.0738 10.4805 6.69522 10.4805C4.31664 10.4805 2.38818 12.4089 2.38818 14.7875C2.38818 17.1661 4.31664 19.0945 6.69522 19.0945Z"
                fill="url(#paint5_radial_14442_46079)" />
            <path
                d="M9.06433 16.5492H8.24936V17.639H6.74261V16.5492H3.85229V15.502L6.30669 11.9531H7.90821L5.68125 15.2746H6.78999V14.308H8.24936V15.2746H9.06433V16.5492Z"
                fill="url(#paint6_linear_14442_46079)" />
            <path
                d="M8.26344 6.24023L3.79057 0H0L4.47287 6.25918C4.64345 6.50083 4.73821 6.78986 4.73821 7.08363V8.05496H8.52878V7.06941C8.52878 6.77091 8.43402 6.48187 8.26344 6.24023Z"
                fill="url(#paint7_linear_14442_46079)" />
            <path
                d="M12.0304 0H9.47649L8.79893 0.947643L8.11663 1.89529L6.42035 4.26439L5.74278 5.21203L5.00362 6.24023C4.83304 6.48187 4.73828 6.77091 4.73828 7.06941V8.05496H8.52885V7.08363C8.52885 6.78986 8.62361 6.50083 8.79419 6.25918L9.54283 5.21203L10.2204 4.26439L11.9119 1.89529L13.2671 0H12.0304Z"
                fill="url(#paint8_linear_14442_46079)" />
            <path
                d="M8.05503 7.10547H5.2121C4.9515 7.10547 4.73828 7.31869 4.73828 7.57929V8.52693C4.73828 8.78753 4.9515 9.00075 5.2121 9.00075H8.05503C8.31563 9.00075 8.52885 8.78753 8.52885 8.52693V7.57929C8.52885 7.31869 8.31563 7.10547 8.05503 7.10547Z"
                fill="url(#paint9_linear_14442_46079)" />
            <defs>
                <linearGradient id="paint0_linear_14442_46079" x1="1.04724" y1="7.30657" x2="10.5758" y2="19.9386"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E5DDDB" />
                    <stop offset="0.3" stop-color="#D9AB99" />
                    <stop offset="0.37" stop-color="#D4A693" />
                    <stop offset="0.45" stop-color="#C79886" />
                    <stop offset="0.55" stop-color="#B28171" />
                    <stop offset="0.6" stop-color="#A37162" />
                    <stop offset="0.73" stop-color="#926356" />
                    <stop offset="0.98" stop-color="#663C34" />
                    <stop offset="1" stop-color="#613B33" />
                </linearGradient>
                <linearGradient id="paint1_linear_14442_46079" x1="9.91247" y1="19.8148" x2="3.21737" y2="9.35278"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E5DDDB" />
                    <stop offset="0.3" stop-color="#D9AB99" />
                    <stop offset="0.37" stop-color="#D4A693" />
                    <stop offset="0.45" stop-color="#C79886" />
                    <stop offset="0.55" stop-color="#B28171" />
                    <stop offset="0.6" stop-color="#A37162" />
                    <stop offset="0.73" stop-color="#926356" />
                    <stop offset="0.98" stop-color="#663C34" />
                    <stop offset="1" stop-color="#613B33" />
                </linearGradient>
                <linearGradient id="paint2_linear_14442_46079" x1="4.04652" y1="12.1619" x2="11.6087" y2="19.6625"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E5DDDB" />
                    <stop offset="0.3" stop-color="#D9AB99" />
                    <stop offset="0.39" stop-color="#D4A693" />
                    <stop offset="0.51" stop-color="#C79886" />
                    <stop offset="0.64" stop-color="#B28171" />
                    <stop offset="0.71" stop-color="#A37162" />
                    <stop offset="0.8" stop-color="#926356" />
                    <stop offset="0.98" stop-color="#663C34" />
                    <stop offset="1" stop-color="#613B33" />
                </linearGradient>
                <radialGradient id="paint3_radial_14442_46079" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.24984 15.4471) scale(7.23051 7.23051)">
                    <stop stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.73" stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.84" stop-color="#E7E7E7" />
                    <stop offset="0.93" stop-color="#E7D4CE" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint4_radial_14442_46079" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.91427 14.597) scale(6.48187 6.48188)">
                    <stop stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.73" stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.84" stop-color="#E7E7E7" />
                    <stop offset="0.93" stop-color="#E7D4CE" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint5_radial_14442_46079" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse"
                                gradientTransform="translate(7.91427 14.597) scale(6.48187 6.48188)">
                    <stop stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.73" stop-color="#E7D4CE" stopOpacity="0" />
                    <stop offset="0.84" stop-color="#E7E7E7" />
                    <stop offset="0.93" stop-color="#E7D4CE" stopOpacity="0" />
                </radialGradient>
                <linearGradient id="paint6_linear_14442_46079" x1="0.0427721" y1="5.94033" x2="13.0255" y2="24.4146"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#E5DDDB" />
                    <stop offset="0.25" stop-color="#D9AB99" />
                    <stop offset="0.3" stop-color="#D4A693" />
                    <stop offset="0.37" stop-color="#C79886" />
                    <stop offset="0.44" stop-color="#B28171" />
                    <stop offset="0.48" stop-color="#A37162" />
                    <stop offset="0.51" stop-color="#9C6B5D" />
                    <stop offset="0.68" stop-color="#7C5349" />
                    <stop offset="0.82" stop-color="#684640" />
                    <stop offset="0.92" stop-color="#613B33" />
                </linearGradient>
                <linearGradient id="paint7_linear_14442_46079" x1="1.3267" y1="0.260602" x2="5.88486" y2="6.10282"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint8_linear_14442_46079" x1="7.1832" y1="6.51978" x2="11.3955" y2="0.189529"
                                gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
                <linearGradient id="paint9_linear_14442_46079" x1="6.34927" y1="11.3936" x2="6.74255" y2="6.77853"
                                gradientUnits="userSpaceOnUse">
                    <stop offset="0.49" stop-color="#3FBCF7" />
                    <stop offset="1" stop-color="#0071DA" />
                </linearGradient>
            </defs>
        </svg>
    )
}
