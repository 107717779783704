import { IApiHandlerParams } from "../../interface"
import api from '../../../api'
import { ContestApplicationUsersData, ContestData } from "./interface";
import { CreateContestFormData } from "../../../containers/contests/ContestsCreateEdit";

export const $getContestList = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post("api/contests/listcontests", data, {
        // mock: require('./mocks/contest.list.json'),
        handler: { formData: true, ...handler },
    });

export const $getContestById = (
    id: any,
    handler?: IApiHandlerParams
): Promise<CreateContestFormData> => api
    .post(`api/contests/viewcontest`, { id: id }, {
        handler: { formData: true, ...handler },
    })

export const $getContestCategories = (
    id: number,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post(`api/contests/listcategory`, {
        id: id,
        forceToResend: 0
    }, {
        handler: { formData: true, ...handler },
    })

export const $createContest = (
    data: any,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .post("api/contests/createcontest", data, {
        headers: headers || null,
        handler: { formData: true, ...handler },
    });

export const $editContest = (
    contestId: any,
    data: any,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .post("api/contests/editcontest", { id: contestId, ...data }, {
        headers: headers || null,
        handler: { formData: true, ...handler },
    });

export const $pickWinner = (
    data: any,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .post("api/contests/pickwinner", data, {
        headers: headers || null,
        handler: { formData: true, ...handler },
    });

export const $deleteContest = (
    contest_id: any,
    handler?: IApiHandlerParams
): Promise<any> => api
    .post("api/contests/deletecontest", { id: contest_id }, {
        handler: { formData: true, ...handler },
    });

export const $supplyApplication = (
    data: any,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .post("api/contests/createcomment", data, {
        headers: headers || null,
        handler: { formData: true, noTransform: true, ...handler },
    });

export const $excludeApplication = (
    data: any,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .post("api/contests/deletecomment", data, {
        headers: headers || null,
        handler: { formData: true, noTransform: true, ...handler },
    });

export const $downloadExelContests = (
    id: string,
    handler?: IApiHandlerParams,
    headers?: any
): Promise<any> => api
    .get(`backend/v1/contests/export/${id}`, {
        headers: headers || null,
        handler: { newApi: true, isBlobFIle: true, ...handler },
    });

export const $getContestUserApplicationList = (
    id?: any,
    handler?: IApiHandlerParams
): Promise<any> => {
    return $getContestById(id)
}