export const UsersMultiIcon = (
    {
        size = 36,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 36 36" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.5 27V25.5C10.5 21.3579 13.8579 18 18 18C22.1421 18 25.5 21.3579 25.5 25.5V27"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M1.5 27V25.5C1.5 23.0146 3.51473 21 6 21"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M34.5 27V25.5C34.5 23.0146 32.4854 21 30 21"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M18 18C20.4854 18 22.5 15.9854 22.5 13.5C22.5 11.0147 20.4854 9 18 9C15.5146 9 13.5 11.0147 13.5 13.5C13.5 15.9854 15.5146 18 18 18Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M6 21C7.65686 21 9 19.6569 9 18C9 16.3431 7.65686 15 6 15C4.34314 15 3 16.3431 3 18C3 19.6569 4.34314 21 6 21Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M30 21C31.6569 21 33 19.6569 33 18C33 16.3431 31.6569 15 30 15C28.3431 15 27 16.3431 27 18C27 19.6569 28.3431 21 30 21Z"
                stroke={color}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
