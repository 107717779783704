import { useLocation } from "react-router-dom";

export const SliderBadgesIcon = () => {
    const location = useLocation()
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg"
             opacity={location.pathname !== '/analytics/badges' ? 0.6 : 1}
        >
            <path
                d="M8.47271 8.13544L11.0251 2.99522C11.0994 2.84646 11.2137 2.72134 11.3551 2.63389C11.4966 2.54644 11.6596 2.50012 11.8258 2.50012C11.9921 2.50012 12.1551 2.54644 12.2965 2.63389C12.438 2.72134 12.5522 2.84646 12.6265 2.99522L15.179 8.13544L20.8851 8.96464C21.0497 8.98746 21.2045 9.05609 21.332 9.16271C21.4594 9.26932 21.5543 9.40963 21.6059 9.56759C21.6574 9.72556 21.6635 9.89484 21.6234 10.0561C21.5833 10.2174 21.4987 10.3641 21.3793 10.4796L17.251 14.4782L18.2256 20.1273C18.3503 20.8524 17.584 21.4045 16.9287 21.0626L11.8258 18.3943L6.72197 21.0626C6.06765 21.4055 5.30134 20.8524 5.42611 20.1263L6.40071 14.4772L2.27242 10.4786C2.15353 10.363 2.06944 10.2164 2.02972 10.0555C1.98999 9.8945 1.99622 9.72561 2.0477 9.56801C2.09918 9.4104 2.19384 9.2704 2.32092 9.16391C2.44801 9.05742 2.60241 8.98872 2.7666 8.96562L8.47271 8.13544Z"
                fill="currentColor" />
            <path
                d="M8.47271 8.13544L11.0251 2.99522C11.0994 2.84646 11.2137 2.72134 11.3551 2.63389C11.4966 2.54644 11.6596 2.50012 11.8258 2.50012C11.9921 2.50012 12.1551 2.54644 12.2965 2.63389C12.438 2.72134 12.5522 2.84646 12.6265 2.99522L15.179 8.13544L20.8851 8.96464C21.0497 8.98746 21.2045 9.05609 21.332 9.16271C21.4594 9.26932 21.5543 9.40963 21.6059 9.56759C21.6574 9.72556 21.6635 9.89484 21.6234 10.0561C21.5833 10.2174 21.4987 10.3641 21.3793 10.4796L17.251 14.4782L18.2256 20.1273C18.3503 20.8524 17.584 21.4045 16.9287 21.0626L11.8258 18.3943L6.72197 21.0626C6.06765 21.4055 5.30134 20.8524 5.42611 20.1263L6.40071 14.4772L2.27242 10.4786C2.15353 10.363 2.06944 10.2164 2.02972 10.0555C1.98999 9.8945 1.99622 9.72561 2.0477 9.56801C2.09918 9.4104 2.19384 9.2704 2.32092 9.16391C2.44801 9.05742 2.60241 8.98872 2.7666 8.96562L8.47271 8.13544Z"
                fill="currentColor" />
        </svg>
    );
};