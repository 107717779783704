import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import TextField from "../../../components/ui/form/Fields/TextField";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon } from "../../../img";
import { isMd } from "../../../utils";
import FileField from "../../../components/ui/form/FileField/FileField";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import { $createGroup } from "../../../api/requests/groups";
import GroupCreatePreview from "./GroupCreatePreview";
import { setGroupCreated } from "../../../redux/reducers/workplace/workplaceSlice";
import { useDispatch } from "react-redux";

export interface CreateGroupFormData {
    image: File | string | null;
    title: string;
    text: string;
}

const GroupCreatePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const dispatch = useDispatch();

    const [isShow, setIsShow] = useState(true);

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors }
    } = useForm<CreateGroupFormData>(
        {
            values: {
                image: "",
                title: "",
                text: ""
            }
        }
    );

    const handleNavigation = () => {
        navigate(-1);
    };

    function onSubmit(data: CreateGroupFormData) {
        const requestData: any = {
            ...data
        };

        $createGroup(requestData, { formData: true }).then(res => {
            if (res?.code) return;

            reset();
            navigate(`/?group_id=${res?.id}`);
            dispatch(setGroupCreated(true));
        });
    }

    return (
        <div className="contests__create">
            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{"Создать группу"}</h1>
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                                className="contests__create__form"
                            >
                                {/*<Controller*/}
                                {/*    name="image"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: false,*/}
                                {/*            message: "Прикрепите картинку"*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <FileField*/}
                                {/*            className={"form-image"}*/}
                                {/*            upload={value}*/}
                                {/*            label="Изображение"*/}
                                {/*            setUpload={onChange}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Заполните название"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Название группы"
                                            placeholder={"Введите название"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                {/*<Controller*/}
                                {/*    name="text"*/}
                                {/*    control={control}*/}
                                {/*    rules={{*/}
                                {/*        required: {*/}
                                {/*            value: false,*/}
                                {/*            message: "Напишите описание"*/}
                                {/*        }*/}
                                {/*    }}*/}
                                {/*    render={({ field: { onChange, value } }) => (*/}
                                {/*        <TextArea*/}
                                {/*            value={value}*/}
                                {/*            title="Описание"*/}
                                {/*            name="text"*/}
                                {/*            onChange={onChange}*/}
                                {/*            errors={errors}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*/>*/}

                                <div className={"d-flex gap-3"}>
                                    <Button
                                        text={"Отмена"}
                                        className={"btn btn-light w-100"}
                                        onClick={() => navigate(-1)}
                                    />

                                    <Button
                                        type={"submit"}
                                        text={"Создать"}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <GroupCreatePreview
                                        data={watch()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GroupCreatePage;