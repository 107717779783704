import noImageBanner from "../../../img/noimage.png";
import { useSelector } from "react-redux";
import { getRedactorNewsListData } from "../../../redux";
import { EditorShow } from "../../../utils/field/editor-show";
import React, { useEffect, useState } from "react";
import { getDateByFormat } from "../../../utils";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";

interface EventEditPreviewProps {
    data: any;
    author: any;
}

const EventEditPreview = (
    {
        data,
        author
    }: EventEditPreviewProps
) => {
    const [upload, setUpload] = useState("");

    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === "string") {
                    setUpload(reader.result);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (typeof data.cover === "string") {
            setUpload(data.cover);
        } else {
            handleImageUpload(data.cover);
        }
    }, [data.cover]);


    return (
        <div className="contests__create__preview-items">
            <div className="contests__create__preview-img">
                <BlankImage image={upload}/>
                {/*<img src={upload || noImageBanner} />*/}
            </div>

            <div
                className="contests__create__preview-item d-flex flex-column align-items-start gap-0">
                <div className="contests__create__preview-item__header">
                    <div className={"d-flex align-items-center gap-3"}>
                        <h3>{data.title || "Название"}</h3>
                    </div>
                </div>

                <div className="events-show-info__category">
                    {data?.category?.title ? data.category.title : "Без категории"}
                </div>

                <div className="news-show-info__editor events-show-editor">
                    <div style={{ fontWeight: 600 }}
                         dangerouslySetInnerHTML={{ __html: data?.description }} />
                </div>

                <div className="news-show-info__editor events-show-editor">
                    <div dangerouslySetInnerHTML={{ __html: data?.body }} />
                </div>

                <div className="events-show-extra">
                    <h2 className="events-show-extra__title">
                        Дата проведения
                    </h2>
                    <div className="events-show-extra__desc">
                        {getDateByFormat(data?.start_date, "HH:mm, DD MMMM")}
                    </div>
                </div>

                <div className="events-show-extra">
                    <h2 className="events-show-extra__title">
                        Место проведения
                    </h2>
                    <div className="events-show-extra__desc">
                        <LocationIcon />
                        {data?.location}
                    </div>
                </div>

                <div className="events-show-extra">
                    <h2 className="events-show-extra__title">
                        Организатор
                    </h2>

                    <div className="events-show-author">
                        <BlankImage image={author?.image} />

                        <div className="events-show-author__content">
                            <div className="events-show-author__name">
                                {author?.name}
                            </div>
                            <div className="events-show-author__role">
                                {author?.job?.name}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventEditPreview;