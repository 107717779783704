import React, { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import DatePickerForm from "../../ui/form/DatePickerForm";
import { getYearMonthDay } from "../../../utils";

dayjs.extend(isoWeek);
dayjs.extend(utc)

interface IStatisticsManageDates {
    filterType: string,
    setStartTime: any,
    setEndTime: any
    setFilterType: any,
}

export const AnalyticsDates: FC<IStatisticsManageDates> = (
    {
        filterType,
        setStartTime,
        setEndTime,
        setFilterType,
    }) => {

    const currentDate = dayjs().utcOffset(3);
    const yesterday = dayjs().subtract(1, 'day');
    const yesterdayUtc = dayjs().utcOffset(3).subtract(1, 'day')

    const [ selectedDate, setSelectedDate ] = useState<any>([]);
    const [selectedDatePlaceholder, setSelectedDatePlaceholder] = useState<any>([dayjs().startOf('isoWeek').toDate(), dayjs().endOf('isoWeek').toDate()])

    const leftDays = [
        {
            key: 'yesterday',
            title: 'Вчера',
        },
        {
            key: 'today',
            title: 'Сегодня',
        },
        {
            key: 'week',
            title: 'Неделя',
        },
        {
            key: 'month',
            title: 'Месяц',
        },
        {
            key: 'year',
            title: 'Год',
        }
    ]

    const getDay = (
        type: any,
        isYesterday?: boolean
    ) => {
        if (isYesterday) {
            return {
                start_time: parseInt((yesterdayUtc.startOf(type).toDate().getTime() / 1000).toString()),
                end_time: parseInt((yesterdayUtc.endOf(type).toDate().getTime() / 1000).toString()),
                start_date: yesterday.startOf(type).toDate(),
                end_date: yesterday.endOf(type).toDate(),
            }
        }
        return {
            start_time: parseInt((currentDate.startOf(type).toDate().getTime() / 1000).toString()),
            end_time: parseInt((currentDate.endOf(type).toDate().getTime() / 1000).toString()),
            start_date: dayjs().startOf(type).toDate(),
            end_date: dayjs().endOf(type).toDate(),
        }
    }
    const handleDateChange = (dayType: any) => {
        setFilterType(dayType)

        if (dayType === 'today') {
            setStartTime(getDay('day').start_time)
            setEndTime(getDay('day').end_time)

            setSelectedDate([])
            setSelectedDatePlaceholder([ getDay('day',).start_date, getDay('day').end_date ])
        } else if (dayType === 'yesterday') {
            setStartTime(getDay('day', true).start_time)
            setEndTime(getDay('day', true).end_time)

            setSelectedDate([])
            setSelectedDatePlaceholder([ getDay('day', true).start_date, getDay('day', true).end_date ])
        }
        else if (dayType === 'week') {
            setStartTime(getDay('isoWeek').start_time)
            setEndTime(getDay('isoWeek').end_time)

            setSelectedDate([])
            setSelectedDatePlaceholder([ getDay('isoWeek').start_date, getDay('isoWeek').end_date])
        }
        else {
            setStartTime(getDay(dayType).start_time)
            setEndTime(getDay(dayType).end_time)

            setSelectedDate([])
            setSelectedDatePlaceholder([ getDay(dayType).start_date, getDay(dayType).end_date ])
        }
    }

    const handleChangePicker = (value: any, index: number) => {
        const updatedDates = [...selectedDate];
        updatedDates[index] = value;
        setSelectedDate(updatedDates);
    }

    useEffect(() => {
        if (selectedDate[0] && selectedDate[1]) {
            const [startDate, endDate] = selectedDate;

            const utcStartDate: any = startDate ? dayjs(startDate).utcOffset(0).hour(21) : null;
            const utcEndDate: any = endDate ? dayjs(endDate).utcOffset(0).hour(44).minute(59).second(59) : null;

            const dateFirst: any = new Date(utcStartDate)
            const dateSecond: any = new Date(utcEndDate)

            const start_date = selectedDate && parseInt(`${ (dateFirst * 1000) / 1000000 }`);
            const end_date = selectedDate && parseInt(`${ (dateSecond * 1000) / 1000000 }`);

            setStartTime(start_date)
            setEndTime(end_date)
            setFilterType('')
        }

    }, [selectedDate])

    return (
        <div className="analytics-calendar">
            <div className="analytics-calendar-days">
                {leftDays.map((
                    day,
                    idx
                ) => (
                    <div key={idx}
                         onClick={ () => handleDateChange(day.key) }
                         className={`analytics-calendar-days__day ${filterType === day.key ? "active" : ""}`}
                         style={{ boxShadow: filterType === day.key ? `0px -2px 0px 0px #00A7B5 inset` : "" }}
                    >
                        {day.title}
                    </div>
                ))}
            </div>

            <div className={`analytics-calendar-datePicker`}>
                <DatePickerForm
                    value={selectedDate[0]  ? getYearMonthDay(selectedDate[0]) : ''}
                    className=""
                    label={``}
                    maxDate={new Date(selectedDate[1]) || undefined}
                    placeholder={getYearMonthDay(selectedDatePlaceholder[0])}
                    onChange={(value) => handleChangePicker(value, 0)}
                    isIcon
                    isRightIcon
                />

                <DatePickerForm
                    value={selectedDate[1]  ? getYearMonthDay(selectedDate[1]) : ''}
                    className=""
                    label={``}
                    minDate={new Date(selectedDate[0]) || undefined}
                    placeholder={getYearMonthDay(selectedDatePlaceholder[1])}
                    onChange={(value) => handleChangePicker(value, 1)}
                    isIcon
                    isRightIcon
                />

                {/*<CustomProvider locale={customRussianLocale}>*/}
                {/*    <DateRangePicker*/}
                {/*        label={ false }*/}
                {/*        ranges={[]}*/}
                {/*        value={ selectedDate }*/}
                {/*        format={ dateFormat }*/}
                {/*        onOk={() => ''}*/}
                {/*        onChange={ handleDatePickerChange }*/}
                {/*        onOpen={handleOpen}*/}
                {/*        onClose={handleClose}*/}
                {/*        menuClassName={'calendar'}*/}
                {/*        caretAs={IconCalendar}*/}
                {/*        cleanable={false}*/}
                {/*        isoWeek*/}
                {/*    />*/}
                {/*</CustomProvider>*/}

            </div>
        </div>
    );
};