import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { fabric } from "fabric";

import {
    $deleteWorkplaceMapData,
    $getWorkplaceMapData,
    $getWorkplaceMapSearch,
    $pinImportWorkplaceMap,
    $updateWorkplaceMap
} from "../../../api/requests/workplace";
import { successNotify } from "../../../utils";
import { withDebounce } from "../../../functions";

import {
    getShapeData,
    setMeetingOpenPopup,
    setRoomOpenPopup,
    getPlan,
    getSelected,
    getPopupData,
    setFloors,
    setPlan,
    setSelected,
    setPopupData,
    setWorkplaceOpenPopup,
    getWorkplaceOpenPopup,
    setControl,
    getMeetingOpenPopup,
    getRoomOpenPopup,
    getControl,
    setShapeData
} from "../../../redux/reducers/workplace-management/workplaceManagementSlice";
import { getPersonData } from "../../../redux";

import { getWorkplaceShape } from "../../services/services-workplace/GetWorkplaceShape";
import { setDrawnShapeSettings } from "../../services/services-workplace/utils";
import { getMeetingRoomShape } from "../../services/services-workplace/getMeetingRoomShape";
import { getRoomShape } from "../../services/services-workplace/GetRoomShape";
import { PaperClipIcon, PlusIcon2 } from "../../../img";
import Button from "../../ui/form/Buttons/Button";
import FileField from "../../ui/form/FileField/FileField";
import WorkplaceCreateDialog from "./WorkplaceCreateDialog";
import RefreshIcon from "../../../img/icons/RefreshIcon";
import { getSelectedUser, setSelectedUser } from "../../../redux/reducers/workplace/workplaceSlice";
import WorkplaceCreateMeetingDialog from "./WorkplaceCreateMeetingDialog";
import WorkplaceCreateCabinetDialog from "./WorkplaceCreateCabinetDialog";
import Modal from "react-modal";

export const WorkspaceWorkplaceMap = () => {
    const dispatch = useDispatch();

    const { workspace_id: wsId } = useSelector(getPersonData);

    const shapeData = useSelector(getShapeData);
    const { city, building, floor } = useSelector(getSelected);
    const plan = useSelector(getPlan);
    const selectedUser = useSelector(getSelectedUser);
    const popupData = useSelector(getPopupData);

    const openMainPopup = useSelector(getWorkplaceOpenPopup);
    const openWorkPopup = useSelector(getControl);
    const openMeetPopup = useSelector(getMeetingOpenPopup);
    const openRoomPopup = useSelector(getRoomOpenPopup);

    const containerRef = useRef<any>(null);
    const canvasRef = useRef<any>(null);
    const mouseCoords = useRef({
        startX: 0,
        startY: 0,
        scrollLeft: 0,
        scrollTop: 0
    });

    const [canvas, setCanvas] = useState<any>();
    const [shapes, setShapes] = useState<any>([]);
    const [props, setProps] = useState<any>({});

    const [upload, setUpload] = useState<any>();

    const [startMovement, setStartMovement] = useState({ x: 0, y: 0 });
    const [lastMovement, setLastMovement] = useState({ x: 0, y: 0 });
    const [isMouseDown, setIsMouseDown] = useState(false);

    const [size, setSize] = useState({ width: 1300, height: 700 });

    const importPinned = (data: any) => {
        $pinImportWorkplaceMap({ file: data }, { formData: true, newApi: true }).then(res => {
            if (!res) return;

            getData();

            setUpload(null);

            successNotify(res?.message);
        });
    };;

    // Отлавливание клика
    const handleShapeClick = (shape: any) => {
        if (!shape) return;

        console.log(shape.details, 'shape.details');

        dispatch(
            setPopupData({
                ...shape.details,
                shape,
                isEdit: true,
            }),
        );

        if (shape.details.type === "workplace") {
            dispatch(setControl());
        }

        if (shape.details.type === "meeting-room") {
            dispatch(setMeetingOpenPopup());
        }

        if (shape.details.type === 'room') {
            dispatch(setRoomOpenPopup());
        }
    };

    // Отрисовка элемента
    const getDrawnShape = (details: any, onSuccess: (value: any) => void) => {
        if (!canvas) return;

        if (details.type === 'workplace') {
            getWorkplaceShape(containerRef.current, canvas, details, onSuccess);
        }

        if (details.type === 'meeting-room') {
            getMeetingRoomShape(containerRef.current, canvas, details, onSuccess);
        }

        if (details.type === 'room') {
            getRoomShape(containerRef.current, canvas, details, onSuccess);
        }
    };

    const refreshObject = (object: any) => {
        if (object.details.type === "workplace") {
            const workplace = props?.workplaces?.find((w: any) => +w?.number === +object?.details?.request?.place);

            const today = moment(new Date()).format("yyyy-MM-DD");
            const todayBooking = workplace?.bookings?.find((b: any) => b.date_in_string === today);
            const todayInitiator =
                todayBooking?.items?.length > 0
                    ? todayBooking.items[0]?.for_user
                    : null;

            object.details.department = !!workplace?.department;
            object.details.id = workplace?.id;
            object.details.member = workplace?.for_user || todayInitiator;

            object.details.request = {
                ...object.details.request,
                id: workplace?.id,
                place: workplace?.number,
                number_name: workplace?.number_name,
                member: workplace?.for_user || todayInitiator,
                is_permanent: !!workplace?.for_user,
            };

            getWorkplaceShape(containerRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY,
                });

                canvas.remove(object);

                canvas.add(item);
                renderAll();
            });
        }

        if (object.details.type === "meeting-room") {
            getMeetingRoomShape(containerRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);

                canvas.add(item);
                renderAll();
            });
        }

        if (object.details.type === "room") {
            getRoomShape(containerRef.current, canvas, object.details, (item: any) => {
                item.details = object.details;

                item.set({
                    left: object.left,
                    top: object.top,
                    scaleX: object.scaleX,
                    scaleY: object.scaleY
                });

                canvas.remove(object);
                canvas.add(item);
                renderAll();
            });
        }

        setDrawnShapeSettings(object);
    };

    const refreshAllObjects = () => {
        canvas.forEachObject((object: any) => {
            if (object) {
                refreshObject(object);
            }
        });

        let uniqueItems: any[] = [];
        let hasChanges = false;

        canvas.forEachObject((object: any) => {
            if (object && object.details.id && !uniqueItems.find((item) => +item.id === +object.details.id)) {
                uniqueItems = [...uniqueItems, object.details];
            } else if (object.details.id) {
                canvas.remove(object);

                renderAll();

                hasChanges = true;
            }
        });

        if (hasChanges) {
            handleSave();
            return;
        }

        props?.workplaces?.forEach((workplace: any) => {
            const object = canvas.getObjects().find((o: any) => +o.details.request.place === +workplace.number);

            if (!object) {
                $deleteWorkplaceMapData(
                    building?.id,
                    floor.id,
                    workplace.id,
                    { newApi: true }
                ).then()
            }
        });

        setShapes(canvas.getObjects());
    };

    const getData = () => {
        if (floor) {
            withDebounce(() => {
                $getWorkplaceMapData(
                    floor ? floor.building_id : 0,
                    null,
                    { newApi: true }
                ).then(res => {
                    if (res && res?.data?.floors) {
                        const thisFloor = res.data.floors.find((item: any) => item.id === floor?.id);

                        thisFloor && setProps(thisFloor);
                    }
                });
            });
        }
    };

    const initBG = () => {
        if (!canvas || !containerRef.current) return;

        // Устанавливаем размеры Canvas
        canvas.setDimensions({
            width: size.width,
            height: size.height
        });

        let zoom = size.width / 1300;
        if (zoom < 1) {
            zoom = 1;
        }

        canvas.setZoom(zoom);
    };

    const initBGImage = (onSuccess: any) => {
        canvas.selection = false;

        initBG();

        fabric.Image.fromURL(plan, (img: any) => {
            img.scaleToWidth(1300);
            img.scaleToHeight(700);

            img.set({
                top: 0,
                left: 0,
                right: 0,
            });

            canvas.setBackgroundImage(img, canvas.renderAll.bind(canvas));
            if (onSuccess) onSuccess();
        });
    };

    const renderAll = () => {
        canvas.renderAll(); // Обновление всех объектов на canvas
        setShapes(canvas.getObjects()); // Сохранение объектов карты
    };

    const handleResize = () => {
        initBGImage(() => {
            renderAll();
            getData();
        });
    };

    const handleMoveStart = (e: any) => {
        const touches = e.changedTouches;

        handleDown(e);

        for (let i = 0; i < touches.length; i++) {
            setStartMovement({
                x: touches[i].clientX,
                y: touches[i].clientY
            });
        }
    };

    const handleMove = (e: any) => {
        const width = window.innerWidth;

        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            const diffX = lastMovement.x + (startMovement.x - touches[i].clientX);
            const resultDiffX =
                diffX + width > 1300 ? 1300 - width : diffX < 0 ? 0 : diffX;

            const diffY = lastMovement.y + (startMovement.y - touches[i].clientY);
            const resultDiffY = diffY > 200 ? 200 : diffY < 0 ? 0 : diffY;

            canvasRef.current.style.transform = `translate(${-resultDiffX}px, ${-resultDiffY}px)`;
        }
    };

    const handleMoveEnd = (e: any) => {
        const touches = e.changedTouches;

        for (let i = 0; i < touches.length; i++) {
            setLastMovement({
                x: lastMovement.x + startMovement.x - touches[i].clientX,
                y: lastMovement.y + startMovement.y - touches[i].clientY
            });
        }
    };

    const handleDragStart = (e: any) => {
        if (!containerRef.current) return;
        const slider: any = containerRef.current;
        const startX = e.pageX - slider.offsetLeft;
        const startY = e.pageY - slider.offsetTop;
        const { scrollLeft } = slider;
        const { scrollTop } = slider;
        mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
        setIsMouseDown(true);
        document.body.style.cursor = "grabbing";
    };

    const handleDragEnd = () => {
        setIsMouseDown(false);
        if (!containerRef.current) return;
        document.body.style.cursor = "default";
    };

    const handleDrag = (e: any) => {
        if (!isMouseDown || !containerRef.current) return;
        e.preventDefault();

        const slider: any = containerRef.current;

        const x = e.pageX - slider.offsetLeft;
        const y = e.pageY - slider.offsetTop;
        const walkX = (x - mouseCoords.current.startX) * 1.5;
        const walkY = (y - mouseCoords.current.startY) * 1.5;

        slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
        slider.scrollTop = mouseCoords.current.scrollTop - walkY;
    };

    const handleDown = (event: any) => {
        const activeObject = canvas.getActiveObject();
        event.preventDefault();

        console.log(activeObject, event.button, 'activeObject');

        //open modal
        if (activeObject && event.button === 2) {
            handleShapeClick(activeObject);
        }
    };

    const handleZoomIn = () => {
        if (size.width * 1.2 <= 1300 * 3) {
            setSize({ width: size.width * 1.2, height: size.height * 1.2 });
        }
    };

    const handleZoomOut = () => {
        if (size.width / 1.2 >= 1300) {
            setSize({ width: size.width / 1.2, height: size.height / 1.2 });
        }
    };

    const handleZoomReset = () => {
        if (canvas) {
            const shapes = canvas.getObjects();

            shapes.forEach((item: any) => {
                if (item?.details?.type === "workplace") {
                    item.set({
                        opacity: 1,
                        scaleX: 1,
                        scaleY: 1
                    });
                }
            });
            canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
            renderAll();
        }
    };

    useEffect(() => {
        if (canvas && shapes) {
            shapes.forEach((item: any) => handleShapeEdit(item));
        }
    }, [shapes]);

    useEffect(() => {
        if (props.map && props.map.json_map) {
            const data = JSON.parse(props.map.json_map);

            if (data) {
                canvas.loadFromJSON(data, () => {
                    initBGImage(() => {
                        refreshAllObjects();
                    });
                });
            } else {
                initBGImage(() => {
                    refreshAllObjects();
                });
            }
        }
    }, [props, size]);

    useEffect(() => {
        let itemJson: any = localStorage.getItem("booking-back");
        const savedWsId: any = localStorage.getItem("booking-back-ws-id");

        if (!itemJson) return;

        // reload if change workspace
        // if (!savedWsId || (parseInt(savedWsId, 10) !== wsId) && wsId) {
        //     localStorage.setItem("booking-back", "");
        //     localStorage.setItem("booking-back-ws-id", wsId.toString());
        //     itemJson = null;
        // }

        const item = JSON.parse(itemJson);

        if (item) {
            dispatch(setSelected(item));
            dispatch(setFloors(item?.building?.floors));

            if (item?.floor?.map) {
                dispatch(setPlan(item.floor.map.filename_url));
            } else {
                dispatch(setPlan(null));
            }
        }
    }, []);

    useEffect(() => {
        return () => {
            dispatch(setPlan(null));
            dispatch(setShapeData(null));
            dispatch(setSelected({
                city: null,
                building: null,
                floor: null,
                isLoading: false,
            }));
            dispatch(setSelectedUser(null));
            dispatch(setPopupData(null));
        };
    }, [dispatch]);

    useEffect(() => {
        if (!shapeData) return;

        if (shapeData.isCreate) {
            getDrawnShape(shapeData, (item: any) => {
                item.details = shapeData;

                canvas.add(item);
                canvas.setActiveObject(item);

                renderAll();
                handleSave();
            });

            return;
        }

        if (shapeData.isEdit) {
            const item = shapeData.shape;

            refreshObject(item);

            if (item.details.type === 'workplace') {
                canvas.remove(item);

                item.details = {
                    isCreate: shapeData.isCreate,
                    isEdit: shapeData.isEdit,
                    type: shapeData.type,
                    id: shapeData.id,
                    request: shapeData.request,
                    member: shapeData.member,
                };

                canvas.add(item);
            }

            renderAll();
        }

        if (shapeData.isRemove) {
            canvas.remove(shapeData.shape);

            renderAll();
        }

        handleSave();
    }, [shapeData]);

    useEffect(() => {
        if (plan) {
            // @ts-ignore
            setCanvas(new fabric.Canvas("canvas"), {
                fireRightClick: true
            });
        }
    }, [plan]);

    useEffect(() => {
        if (canvas) {
            handleResize();
            window.addEventListener('resize', () => {
                withDebounce(() => handleResize())
            });
        }

        return () => window.removeEventListener('resize', handleResize);
    }, [canvas]);

    useEffect(() => {
        if (selectedUser) {
            $getWorkplaceMapSearch(
                floor?.id || 0,
                null,
                { newApi: true }
            ).then(res => {
                const shapes = canvas.getObjects();

                if (canvas) {
                    if (res?.data) {
                        shapes.forEach((item: any) => {
                            if (item?.details?.type === "workplace") {
                                if (item?.details?.id !== (res?.data?.id || selectedUser?.id)) {
                                    item.set({
                                        opacity: 0.5,
                                    });
                                } else {
                                    item.set({
                                        opacity: 1
                                    });
                                }
                            }
                        });

                        renderAll();
                    } else {
                        shapes.forEach((item: any) => {
                            if (item?.details?.type === "workplace") {
                                item.set({
                                    opacity: 1
                                });
                            }
                        });

                        renderAll();
                    }
                }
            });
        } else {
            dispatch(setSelectedUser(null));

            if (canvas) {
                const shapes = canvas.getObjects();

                shapes.forEach((item: any) => {
                    if (item?.details?.type === 'workplace') {
                        item.set({
                            opacity: 1,
                            scaleX: 1,
                            scaleY: 1,
                        });
                    }
                });

                canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
                canvas.renderAll();
            }
        }
    }, [selectedUser]);

    function handleSave() {
        if (!canvas || !canvas.backgroundImage || !shapes.length) {
            return;
        }

        const cachedImage = canvas.backgroundImage;

        canvas.backgroundImage = null;

        shapes.forEach((item: any) => {
            if (item.details.type === 'workplace') {
                item.fill = 'rgba(255, 255, 255, 0.8)';
            }

            if (item.details.type === 'room') {
                item.forEachObject((object: any) => {
                    if (object.type === 'circle') {
                        object.fill = 'rgba(255, 255, 255, 0.8)';
                    }
                });
            }
        });

        const data = JSON.stringify(canvas.toJSON(['details']));

        $updateWorkplaceMap(
            floor,
            { json_map: data },
            { newApi: true }
        ).then(() => {
            getData();
        });
        canvas.backgroundImage = cachedImage;

        if (upload) {
            importPinned(upload);

            return;
        }
    }

    function handleShapeEdit(shape: any) {
        shape.editable = true;
        shape.hasControls = true;
        shape.hasBorders = true;
        shape.hasRotatingPoint = false;

        shape?.setControlsVisibility && shape.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tl: true,
            tr: true,
            br: true,
            bl: true
        });
    }

    function handleToggleControl() {
        if (!floor) return;

        dispatch(setPopupData({}));
        dispatch(setWorkplaceOpenPopup());
    }

    return (
        <div className={"workspace-map mt-3"}>
            <div className={"workplace__booking__header"}>
                <h4>Редактор рабочих мест</h4>

                <div className={"d-flex"}>
                    <FileField
                        accept=".xlsx, .xls"
                        label={"Закреплённые сотрудники"}
                        upload={upload}
                        setUpload={setUpload}
                        className={"form-file-string"}
                        iconFile={<PaperClipIcon color={"#00A7B5"}/>}
                        showImg={false}
                    />

                    <div
                        className={"workplace__booking__header__add"}
                        onClick={handleToggleControl}
                    >
                        <PlusIcon2 />

                        <p>Место</p>
                    </div>

                    <Button
                        className={`btn btn-primary`}
                        onClick={handleSave}
                        text={"Сохранить"}
                    />
                </div>
            </div>

            <div
                className={"workspace-map-container"}
                onTouchStart={handleMoveStart}
                onTouchMove={handleMove}
                onTouchEnd={handleMoveEnd}
                onMouseDown={handleDragStart}
                onMouseUp={handleDragEnd}
                onMouseMove={handleDrag}
                ref={containerRef}
            >
                {plan && (
                    <div
                        id="map"
                        className={"workspace-settings__map-block"}
                        ref={containerRef}
                        onMouseDown={handleDown}
                    >
                        <canvas className={"workspace-map-canvas"} id="canvas" />
                    </div>
                )}

                {!plan && (
                    <div className={"workspace-map-empty"}>
                        <img
                            className={"emptyImage"}
                            src={"/images/icons/ImgEmptyBooking.svg"}
                            alt="План этажа"
                        />

                        <p className={"emptyText"}>Выберите город, офис и этаж</p>
                    </div>
                )}
            </div>

            <div className={"workplace__booking__zoom__container"}>
                <div className={"workplace__booking__zoom__block"}>
                    <div className={"workplace__booking__zoom"} onClick={handleZoomIn}>
                        +
                    </div>
                    <div className={"workplace__booking__zoom"} onClick={handleZoomOut}>
                        -
                    </div>
                </div>

                <div className={"workplace__booking__zoom__reset"} onClick={handleZoomReset}>
                    <div className={"workplace__booking__zoom workplace__booking__zoom__block"}>
                        <RefreshIcon />
                    </div>
                </div>
            </div>

            <WorkplaceCreateDialog
                isOpen={openWorkPopup}
                onClose={() => dispatch(setControl())}
                selectedProps={{
                    city,
                    building,
                    floor,
                    request: popupData,
                    selectedMember: {
                        photo: popupData?.photo,
                        name: popupData?.name
                    }
                }}
            />

            <WorkplaceCreateMeetingDialog
                isOpen={openMeetPopup}
                onClose={() => dispatch(setMeetingOpenPopup())}
                selectedProps={{
                    city,
                    building,
                    floor,
                    request: popupData,
                    roomsInMap: shapes
                    ?.filter((shape: any) => shape.details.type === 'meeting-room')
                    ?.map((item: any) => item.details),
                }}
            />

            <WorkplaceCreateCabinetDialog
                isOpen={openRoomPopup}
                onClose={() => dispatch(setRoomOpenPopup())}
                selectedProps={{
                    city,
                    building,
                    floor,
                }}
            />

            <Modal
                isOpen={openMainPopup}
                style={{
                    content: {
                        maxWidth: `${300 / 16}rem`,
                        padding: 0,
                        borderRadius: "8px"
                    }
                }}
                ariaHideApp={false}
                className={"modal__block"}
                overlayClassName={"modal__container"}
                onRequestClose={() => dispatch(setWorkplaceOpenPopup())}
            >
                <div>
                    <Button text={"Рабочее место"} className={"btn btn-light w-100"} onClick={() => {
                        dispatch(setWorkplaceOpenPopup())
                        dispatch(setControl())
                    }}/>
                    <Button text={"Переговорная"} className={"btn btn-light w-100 ml-0"} onClick={() => {
                        dispatch(setWorkplaceOpenPopup())
                        dispatch(setMeetingOpenPopup())
                    }}/>
                    <Button text={"Кабинет"} className={"btn btn-light w-100 ml-0"} onClick={() => {
                        dispatch(setWorkplaceOpenPopup())
                        dispatch(setRoomOpenPopup())
                    }}/>
                </div>
            </Modal>
        </div>
    );
};
