import {
    AnalyticsChartBlock,
    AnalyticsDates, AnalyticsExcelModal,
    AnalyticsHorizonNavigation, AnalyticsListBlock, getStatisticsChartItems,
    getStatisticsListItems
} from "../../components/analytics";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IconCalendar } from "../../img";

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { EyeIcon2 } from "../../img/icons/news/EyeIcon2";
import { PercentageIcon } from "../../img/icons/PercentageIcon";
import { DownloadIcon } from "../../img/icons/DownloadIcon";
import ListIcon from "../../img/icons/ListIcon";
import { useLocation, useNavigate } from "react-router-dom";

dayjs.extend(utc);
dayjs.extend(timezone);

export const AnalyticsDiscountsPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const excelOpen = searchParams.get("excelOpen")

    const currentDate = dayjs().utcOffset(3).hour(3).minute(0).second(0);
    const date = currentDate.startOf('isoWeek').toDate()
    const end_date = currentDate.endOf('isoWeek').toDate()

    const [ startTime, setStartTime ] = useState<any>(parseInt((date.getTime() / 1000).toString()))
    const [ endTime, setEndTime ] = useState<any>(parseInt((end_date.getTime() / 1000).toString()))
    const [ filterType, setFilterType ] = useState('week')

    const [viewsList, setViewsList] = useState([])
    const [discountsList, setDiscountsList] = useState([])

    const [topCategoriesList, setTopCategoriesList] = useState(null);
    const [topPartnersList, setTopPartnersList] = useState(null);

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [excelData, setExcelData] = useState([
        {
            value: 5,
            label: 'Просмотры',
            route: '/partners/export-views'
        },
        {
            value: 2,
            label: 'Оформление скидок',
            route: '/partners/export-discounts'
        },
        {
            value: 3,
            label: 'ТОП Партнеров',
            route: '/partners/export-top-partners'
        },
        {
            value: 4,
            label: 'ТОП Категорий',
            route: '/partners/export-top-cats'
        },
    ])

    const handleDeleteLoadingData = (list: any, setList: any) => {
        const updatedList = {
            data: [],
            total_count: '...',
            text: list?.text,
            title: list?.title,
        }

        setList(updatedList)
    }

    useEffect(() => {
        setIsLoading(true)
        handleDeleteLoadingData(viewsList, setViewsList)
        handleDeleteLoadingData(discountsList, setDiscountsList)

        getStatisticsChartItems(
            '/partners/views',
            'Просмотры',
            '#00A7B5',
            'Просмотры',
            setViewsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <EyeIcon2 color='#00A7B5'/>
        )

        getStatisticsChartItems(
            '/partners/discounts',
            'Оформленные скидки',
            '#00A7B5',
            'Оформленные скидки',
            setDiscountsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <PercentageIcon color='#00A7B5' opacity={1}/>
        )

        getStatisticsListItems(
            '/partners/top-list',
            'Топ предложений',
            <PercentageIcon color='#00A7B5' opacity={1}/>,
            setTopPartnersList,
            startTime,
            endTime,
            filterType,
        )

        getStatisticsListItems(
            '/partners/top-categories',
            'ТОП Категорий',
            <ListIcon />,
            setTopCategoriesList,
            startTime,
            endTime,
            filterType,

        )

    }, [ startTime, endTime ])

    return (
        <>
            <AnalyticsHorizonNavigation/>

            <div className='container analytics'>
                <div className='analytics-header'>
                    <div className='analytics-header__left'>
                        <h1 className='analytics-header__left__title'>Витрина Скидок</h1>
                        <p className='analytics-header__left__desc'>Статистика использования витрины скидок</p>
                    </div>

                    {/*<div className='analytics-header__right' onClick={() => setIsOpenModal(true)}>*/}
                    {/*    <DownloadIcon color={'#00A7B5'}/>*/}
                    {/*    <p style={{color: '#00A7B5'}}>Скачать .xls</p>*/}
                    {/*</div>*/}
                </div>

                <AnalyticsDates
                    setStartTime={ setStartTime }
                    setEndTime={ setEndTime }
                    filterType={ filterType }
                    setFilterType={ setFilterType }
                />

                <div className='analytics-content-second' style={{}}>
                    <div className='analytics-content-second__chartContent'>
                        {viewsList && discountsList && (
                            <>
                                {Object.values([viewsList, discountsList])?.map((item: any, idx) => (
                                    <AnalyticsChartBlock
                                        {...item}
                                        key={idx}
                                        isLoading={isLoading}
                                        filter_type={filterType}
                                        page='partners'
                                    />
                                ))}
                            </>
                        )}
                    </div>

                    <div className='analytics-content-second__listContent'>
                        {topPartnersList &&  topCategoriesList  && (
                            <>
                                {Object.values([topPartnersList, topCategoriesList]).map((item, idx) => (
                                    <AnalyticsListBlock
                                        key={idx}
                                        data={item}
                                        filter_type={filterType}
                                    />
                                ))}
                            </>
                        )}
                    </div>
                </div>
            </div>

            <AnalyticsExcelModal
                isOpened={excelOpen === 'true'}
                onClose={() => navigate('/analytics/discounts')}
                data={excelData}
                start_date={startTime}
                end_date={endTime}
                filter_type={filterType}
            />
        </>
    )
}