import React, { FC } from 'react'
import { IBaseIconProps } from "../types"

export const ServicesIcon: FC<IBaseIconProps> = ({
    color = 'black',
    size = 24,
    opacity = 0.3
}) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            opacity={opacity}
        >
            <path
                d="M4 3C3.448 3 3 3.448 3 4V6C3 6.552 3.448 7 4 7H6C6.552 7 7 6.552 7 6V4C7 3.448 6.552 3 6 3H4ZM11 3C10.448 3 10 3.448 10 4V6C10 6.552 10.448 7 11 7H13C13.552 7 14 6.552 14 6V4C14 3.448 13.552 3 13 3H11ZM18 3C17.448 3 17 3.448 17 4V6C17 6.552 17.448 7 18 7H20C20.552 7 21 6.552 21 6V4C21 3.448 20.552 3 20 3H18ZM4 10C3.448 10 3 10.448 3 11V13C3 13.552 3.448 14 4 14H6C6.552 14 7 13.552 7 13V11C7 10.448 6.552 10 6 10H4ZM11 10C10.448 10 10 10.448 10 11V13C10 13.552 10.448 14 11 14H13C13.552 14 14 13.552 14 13V11C14 10.448 13.552 10 13 10H11ZM18 10C17.448 10 17 10.448 17 11V13C17 13.552 17.448 14 18 14H20C20.552 14 21 13.552 21 13V11C21 10.448 20.552 10 20 10H18ZM4 17C3.448 17 3 17.448 3 18V20C3 20.552 3.448 21 4 21H6C6.552 21 7 20.552 7 20V18C7 17.448 6.552 17 6 17H4ZM11 17C10.448 17 10 17.448 10 18V20C10 20.552 10.448 21 11 21H13C13.552 21 14 20.552 14 20V18C14 17.448 13.552 17 13 17H11ZM18 17C17.448 17 17 17.448 17 18V20C17 20.552 17.448 21 18 21H20C20.552 21 21 20.552 21 20V18C21 17.448 20.552 17 20 17H18Z"
                fill={color}
            />
        </svg>
    )
}
