import React, { FC, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogContainer from "../../ui/dialog/DialogContainer";
import { CloseIcon } from "../../../img";
import { UploadIcon } from "../../../img/icons/files/UploadIcon";
import { FileUploadInput } from "../input/FileUploadInput";
import { useSelector } from "react-redux";
import { $addFileItem } from "../../../api/requests/files";
import Preloader from "../../ui/preloader/Preloader";

interface IAddFileModalProps {
    isOpen: boolean;
    onClose: (toUpdate: boolean) => void;
    categoryId: number;
}

export const AddFileModal: FC<IAddFileModalProps> = (
    {
        categoryId,
        isOpen,
        onClose
    }
) => {
    const navigate = useNavigate();

    const [isFile, setFile] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const uploadFileRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    useEffect(() => {
        if (isFile) {
            setIsLoading(true);

            $addFileItem({
                item: isFile,
                parent_id: categoryId
            }, { formData: true, newApi: true }).then(() => {
                setIsLoading(false);
                onClose(true);
            });
            setFile("");
        }
    }, [isFile]);

    return (
        <DialogContainer classNameModal="dialog" isOpen={isOpen} isShowCloseBtn={false}
                         closeModal={() => {
                             if (!isLoading) {
                                 onClose(false);
                             }
                         }}>
            <div className="dialog-mobileLine"></div>

            <div className="dialog-header">
                <h5 className="dialog-header__title">Загрузка файлов</h5>

                {!isLoading && (
                    <div onClick={() => onClose(false)} className="dialog-header__closeIcon">
                        <CloseIcon color={"#000"} />
                    </div>
                )}
            </div>

            <div className="files-add-dialog">
                <div className="files-add-dialog__upload"
                     onClick={() => {
                         if (!isLoading && uploadFileRef.current) {
                             uploadFileRef.current.click();
                         }
                     }}>

                    {isLoading ? (
                        <div className="files-add-dialog__loader">
                            <Preloader />
                        </div>
                    ) : (
                        <>
                            <FileUploadInput
                                inputRef={uploadFileRef}
                                onSuccess={(e: any) => setFile(e.target.files[0])}
                                accept="allDocsImages"
                                onError={(e: any) => {
                                }}
                            />
                            <UploadIcon size={45} />
                            <div className="files-add-dialog__upload__text">Ограничения на загрузку
                                файла не
                                более 100 мб.
                            </div>
                        </>
                    )}
                </div>

                <div className="files-add-dialog__content">
                    Видеофайлы: AVI, MP4, MP3, WAV<br />
                    Архивные: ZIP, RAR<br />
                    Графические: PNG, JPG, JPEG, GIF, BMP, SVG, EPS, AI, PSD<br />
                    Текстовые и табличные: DOC, DOCX, PDF, XLS, XLSX, PPT, PPTX, TXT, CSV<br />
                </div>

            </div>
        </DialogContainer>
    );
};