export const IconFifthPlace = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path d="M6.15527 8.11973L8.28122 8.0203C8.81626 8.02504 9.10035 8.02031 9.18084 8.46538L9.64012 11.794C9.65433 11.9313 9.86266 12.1112 9.91001 12.1917C10.0426 12.0875 10.1231 11.9218 10.0994 11.7419L9.71588 8.65477C9.62118 7.91614 8.99145 7.35742 8.24334 7.35742H6.16474V8.115L6.15527 8.11973Z" fill="#E9DFF8"/>
            <path d="M6.1601 7.39618V8.14902L6.35423 8.15849L7.36749 8.11588L8.25764 8.078C8.30026 8.078 8.3476 8.078 8.38548 8.078C8.69798 8.078 8.91578 8.11115 9.02942 8.27687C9.07203 8.33368 9.10992 8.43785 9.12885 8.54202L9.29931 9.79202L9.50764 11.3214C9.50764 11.3214 9.58813 11.8469 9.60234 11.8517C9.61654 11.9132 9.66389 11.9795 9.72071 12.0411C9.787 12.1121 9.85802 12.1736 9.88643 12.2068C10.019 12.1026 10.0995 11.9369 10.0758 11.757L9.8817 10.2182L9.6923 8.67933C9.5976 7.94069 8.96787 7.38672 8.2245 7.38672H6.15063L6.1601 7.39618Z" fill="#E6DBF6"/>
            <path d="M6.15991 7.42188V8.16998L6.3351 8.20313L7.34836 8.16525L8.23851 8.1321C8.27639 8.1321 8.33321 8.1321 8.36635 8.1321C8.67412 8.1321 8.88718 8.17945 8.99609 8.35464C9.03396 8.40672 9.07184 8.52509 9.08605 8.61979L9.2565 9.86506L9.46484 11.3897C9.46484 11.4228 9.5548 11.8916 9.58321 11.9058C9.60215 11.9626 9.65423 12.0241 9.71105 12.0762C9.77734 12.1378 9.85309 12.1899 9.88624 12.2183C10.0188 12.1141 10.0946 11.9484 10.0709 11.7732L9.8815 10.2391L9.69211 8.70975C9.59741 7.97585 8.97241 7.42188 8.23377 7.42188H6.16938H6.15991Z" fill="#E3D8F4"/>
            <path d="M6.15522 7.45898V8.20709L6.30674 8.25917L7.32 8.22603L8.20541 8.19762C8.23856 8.19762 8.30484 8.19762 8.33325 8.19762C8.63155 8.19762 8.84462 8.25917 8.94405 8.44383C8.98193 8.49118 9.01507 8.62376 9.02927 8.70898L9.195 9.94951L9.39859 11.4694C9.40333 11.5215 9.49329 11.9524 9.5359 11.9713C9.55958 12.0234 9.61166 12.0802 9.67321 12.1275C9.74424 12.1844 9.81999 12.227 9.85314 12.2412C9.98571 12.137 10.0615 11.9713 10.0378 11.7961L9.8484 10.2715L9.65901 8.74686C9.56431 8.01296 8.94405 7.46372 8.20541 7.46372H6.14575L6.15522 7.45898Z" fill="#E0D4F1"/>
            <path d="M6.15527 7.48438V8.22775L6.28785 8.29877L7.30111 8.27036L8.18652 8.24669C8.21493 8.24669 8.29069 8.24669 8.31436 8.24669C8.60793 8.24669 8.81626 8.32244 8.90622 8.51184C8.93936 8.54971 8.97251 8.70596 8.98671 8.77699L9.15243 10.0128L9.35603 11.5279C9.3655 11.5942 9.45546 11.992 9.51702 12.0156C9.54543 12.063 9.60224 12.1151 9.6638 12.1529C9.73482 12.2003 9.81058 12.2334 9.85319 12.2429C9.98577 12.1387 10.0615 11.973 10.0378 11.8026L9.84845 10.2827L9.65906 8.76278C9.5691 8.03362 8.9441 7.48438 8.2102 7.48438H6.16001H6.15527Z" fill="#DDD1EF"/>
            <path d="M6.15527 7.5145V8.25787L6.26891 8.35257L7.27743 8.32889L8.16285 8.30522C8.18652 8.30522 8.26702 8.30522 8.29069 8.30522C8.57478 8.30522 8.78311 8.39991 8.86834 8.59404C8.89675 8.62719 8.92989 8.79764 8.93936 8.85919L9.10508 10.0903L9.30395 11.5959C9.31342 11.6812 9.41285 12.041 9.48861 12.0694C9.52175 12.1168 9.5833 12.1594 9.64486 12.1925C9.72062 12.2304 9.79637 12.2541 9.83899 12.2541C9.97156 12.1547 10.0473 11.9889 10.0236 11.8137L9.83425 10.2986L9.64486 8.78344C9.5549 8.05427 8.93463 7.50977 8.20073 7.50977H6.15527V7.5145Z" fill="#DACDEC"/>
            <path d="M6.15527 7.54297V8.28161L6.24997 8.39524L7.25849 8.37631L8.14391 8.35736H8.27175C8.55111 8.35736 8.75944 8.46627 8.83046 8.66513C8.85414 8.69354 8.88728 8.8782 8.89675 8.93502L9.05774 10.1661L9.2566 11.6718C9.2708 11.7712 9.37023 12.0979 9.4602 12.131C9.49808 12.1737 9.55963 12.2115 9.62592 12.2352C9.70168 12.2636 9.78217 12.2826 9.82478 12.2731C9.95262 12.1737 10.0331 12.0079 10.0094 11.8327L9.82005 10.3271L9.63065 8.81664C9.54069 8.09221 8.92042 7.54771 8.19126 7.54771H6.15527V7.54297Z" fill="#D7CAEB"/>
            <path d="M6.15527 7.56836V8.30699L6.23103 8.4443L7.23955 8.42537L8.12024 8.41116C8.12024 8.41116 8.23387 8.41116 8.24808 8.41116C8.51796 8.41116 8.7263 8.53427 8.79258 8.7426C8.81152 8.76627 8.84467 8.96514 8.8494 9.00775L9.01039 10.2341L9.20452 11.7303C9.21872 11.8487 9.32762 12.1375 9.43179 12.1754C9.4744 12.2132 9.54069 12.2464 9.60698 12.2653C9.68747 12.289 9.76323 12.2937 9.81531 12.2748C9.94315 12.1754 10.0236 12.0096 9.99997 11.8392L9.81058 10.3382L9.62118 8.8373C9.53122 8.1176 8.91569 7.57309 8.18652 7.57309H6.15527V7.56836Z" fill="#D3C6E8"/>
            <path d="M6.15537 7.60517V8.33908L6.21219 8.49533L7.21598 8.48112L8.09666 8.47165C8.09666 8.47165 8.21029 8.47165 8.21976 8.47165C8.48491 8.47165 8.68851 8.60897 8.74533 8.82203C8.75954 8.84097 8.79268 9.05878 8.79741 9.08718L8.95366 10.3088L9.14779 11.8003C9.16673 11.9328 9.27563 12.1885 9.394 12.2311C9.44609 12.2643 9.51238 12.2927 9.57866 12.3021C9.65916 12.3163 9.73965 12.3116 9.79173 12.2832C9.91957 12.1838 9.99533 12.0228 9.97166 11.8476L9.787 10.3514L9.5976 8.85517C9.50764 8.13548 8.89211 7.5957 8.17242 7.5957H6.15063L6.15537 7.60517Z" fill="#D0C2E5"/>
            <path d="M6.15527 7.63086V8.36476L6.19315 8.54469L7.19694 8.53522L8.07762 8.52575H8.20073C8.45641 8.52575 8.66001 8.67726 8.70736 8.8998C8.71683 8.90927 8.74997 9.14601 8.75471 9.16968L8.91095 10.3865L9.10035 11.8733C9.11929 12.0248 9.23292 12.2426 9.37024 12.2899C9.42705 12.3231 9.49334 12.342 9.56436 12.3468C9.64959 12.3515 9.73008 12.3373 9.78217 12.3042C9.91001 12.2047 9.98577 12.0437 9.96209 11.8685L9.77743 10.3771L9.59277 8.8856C9.50281 8.17064 8.89202 7.63086 8.17232 7.63086H6.15527Z" fill="#CDBFE3"/>
            <path d="M6.15527 7.65986V8.38903L6.17421 8.58789H7.178L8.05868 8.57842H8.18179C8.428 8.57842 8.6316 8.74414 8.67421 8.97141C8.67421 8.97614 8.71209 9.2271 8.71209 9.2413L8.86361 10.4534L9.053 11.9354C9.07668 12.1011 9.19505 12.2858 9.34183 12.3379C9.40338 12.3663 9.4744 12.3805 9.54542 12.3758C9.63539 12.3758 9.71114 12.3521 9.7727 12.3047C9.90054 12.2053 9.9763 12.0443 9.95262 11.8739L9.76796 10.3871L9.5833 8.90039C9.49334 8.18543 8.88255 7.65039 8.16758 7.65039H6.16001L6.15527 7.65986Z" fill="#CABCE1"/>
            <path d="M6.15527 8.6356H8.15811C8.39959 8.6356 8.59845 8.81552 8.6316 9.05226L8.81626 10.5295L9.00092 12.0068C9.03406 12.2672 9.26607 12.4519 9.52649 12.4187C9.61645 12.4093 9.69694 12.3761 9.75849 12.324C9.88633 12.2246 9.96209 12.0636 9.93842 11.8932L9.75376 10.4159L9.5691 8.93863C9.47914 8.2284 8.87308 7.69336 8.15811 7.69336H6.15527V8.64033V8.6356Z" fill="#C7B8DF"/>
            <path d="M9.50745 8.93732C9.41749 8.23183 8.81143 7.74414 8.10593 7.74414H6.42506L6.15991 7.76308V7.84357L6.22146 8.62955H7.82658C7.82658 8.62955 8.18169 8.62956 8.18643 8.63903C8.34741 8.64376 8.48946 8.73372 8.56521 8.8663C8.59836 8.91838 8.62677 8.99414 8.63624 9.06516L8.81616 10.5188L8.83037 10.6277L8.99608 11.9676C9.01502 12.1191 9.11446 12.2801 9.24703 12.3417C9.31806 12.389 9.39855 12.4032 9.48378 12.3938C9.569 12.3843 9.64949 12.3512 9.71578 12.3038C9.83415 12.2138 9.90044 12.0529 9.87677 11.8777C9.87677 11.8493 9.70158 10.4619 9.69211 10.4241C9.68264 10.353 9.50271 8.93732 9.50271 8.93732H9.50745Z" fill="#C1B2D9"/>
            <path d="M9.44599 8.94304C9.35603 8.24228 8.7547 7.80194 8.04921 7.80668H6.39675L6.15527 7.84929V7.92978L6.27838 8.64001H7.85508C7.85508 8.64001 8.19599 8.64474 8.2102 8.65895C8.36645 8.67315 8.49902 8.76311 8.57005 8.89569C8.60319 8.94304 8.62686 9.02827 8.63633 9.08982L8.81626 10.5197L8.83046 10.6286L8.99618 11.9449C9.01512 12.0822 9.10982 12.2669 9.22345 12.3237C9.28974 12.3805 9.36077 12.3947 9.44599 12.3853C9.52649 12.3758 9.60224 12.3427 9.67327 12.3C9.78217 12.2196 9.84372 12.0633 9.82005 11.8834C9.81531 11.8266 9.65433 10.5339 9.64012 10.4535C9.62118 10.3114 9.44599 8.95724 9.44599 8.95724V8.94304Z" fill="#BBADD4"/>
            <path d="M9.37971 8.94365C9.28974 8.24763 8.69315 7.85938 7.99239 7.85938H6.36834L6.15527 7.92566V8.00615L6.33993 8.63589H7.88823C7.91664 8.63589 8.21493 8.64535 8.23387 8.65956C8.38539 8.6785 8.50849 8.7732 8.57478 8.90577C8.60319 8.94839 8.62686 9.04308 8.63633 9.09516L8.81152 10.5014L8.82573 10.6103L8.98671 11.8982C9.00092 12.0213 9.09088 12.2296 9.19505 12.277C9.2566 12.3433 9.32289 12.3622 9.39865 12.348C9.4744 12.3385 9.55016 12.3054 9.62592 12.2628C9.72535 12.1918 9.77743 12.0355 9.75376 11.8508C9.74429 11.7704 9.59277 10.5677 9.57383 10.4446C9.54069 10.2315 9.37497 8.94365 9.37497 8.94365H9.37971Z" fill="#B6A8CE"/>
            <path d="M9.31825 8.95016C9.22829 8.25887 8.6317 7.91797 7.93567 7.91797H6.34003L6.15063 8.00319V8.08842L6.40158 8.63767H7.92147C7.95935 8.63767 8.22923 8.65187 8.25764 8.67081C8.39969 8.69922 8.51806 8.79392 8.57961 8.92649C8.60329 8.96437 8.62696 9.06854 8.63643 9.11588L8.80688 10.4985L8.82109 10.6026L8.97734 11.8668C8.99154 11.9757 9.07677 12.203 9.16673 12.2456C9.22355 12.3214 9.28037 12.3403 9.35613 12.3261C9.42715 12.3166 9.50291 12.2835 9.5834 12.2456C9.67336 12.1841 9.71598 12.0278 9.6923 11.8384C9.68283 11.7295 9.54079 10.6216 9.51711 10.4558C9.4745 10.1718 9.31351 8.94543 9.31351 8.94543L9.31825 8.95016Z" fill="#B0A3C9"/>
            <path d="M9.2566 8.95277C9.17137 8.26149 8.57005 7.97266 7.88349 7.97266H6.31626L6.15527 8.08156V8.16679L6.46777 8.64027H7.95925C8.0066 8.64027 8.25281 8.65447 8.29069 8.68288C8.428 8.71603 8.54164 8.81546 8.59372 8.94803C8.61266 8.98118 8.63633 9.09481 8.6458 9.13269L8.81626 10.4869L8.83046 10.591L8.98671 11.8316C9.00092 11.9215 9.07667 12.1772 9.15717 12.2151C9.20925 12.3003 9.2566 12.3192 9.33236 12.3098C9.39865 12.3003 9.46967 12.2672 9.55489 12.234C9.63539 12.1772 9.67327 12.0257 9.64959 11.8363C9.63539 11.699 9.50755 10.6857 9.47914 10.4774C9.42705 10.1223 9.2708 8.95751 9.2708 8.95751L9.2566 8.95277Z" fill="#AA9DC3"/>
            <path d="M9.19505 8.95852C9.10982 8.27197 8.51323 8.03049 7.82668 8.03522C7.803 8.03522 7.70357 8.03522 7.67516 8.03522H6.28785L6.15527 8.16307V8.24829L6.52933 8.64128H7.99239C8.04921 8.64128 8.27175 8.66022 8.31436 8.69337C8.44694 8.73598 8.55111 8.83541 8.59845 8.96799C8.61739 8.9964 8.64107 9.11477 8.6458 9.14791L8.81152 10.4784L8.82573 10.5826L8.97724 11.7947C8.98671 11.8705 9.06247 12.1498 9.12876 12.1782C9.17611 12.2776 9.21872 12.2919 9.28501 12.2824C9.34656 12.2729 9.41758 12.2398 9.50755 12.2066C9.57857 12.1593 9.60698 12.0125 9.5833 11.8136C9.56436 11.6479 9.44599 10.7294 9.41285 10.4831C9.3513 10.057 9.19978 8.95852 9.19978 8.95852H9.19505Z" fill="#A598BE"/>
            <path d="M9.12876 8.96496C9.04353 8.28314 8.45167 8.09375 7.76986 8.09375C7.74145 8.09375 7.65149 8.09375 7.62308 8.09375H6.26417L6.15527 8.24527V8.33049L6.59088 8.64299H8.02554C8.09183 8.64299 8.29069 8.66667 8.33804 8.69981C8.46114 8.74716 8.56058 8.85133 8.60319 8.9839C8.6174 9.00284 8.64107 9.13542 8.6458 9.16383L8.80679 10.4706L8.82099 10.5748L8.96777 11.7633C8.97724 11.8248 9.04353 12.1231 9.09562 12.1468C9.13349 12.2557 9.17137 12.2746 9.23766 12.2604C9.29448 12.2509 9.36077 12.2178 9.45546 12.1894C9.51228 12.1515 9.53595 12.0047 9.51228 11.8011C9.49334 11.607 9.38444 10.7831 9.34183 10.4943C9.2708 9.99716 9.12402 8.96023 9.12402 8.96023L9.12876 8.96496Z" fill="#9F92B8"/>
            <path d="M9.0673 8.96757C8.98207 8.29049 8.39022 8.14844 7.71313 8.14844C7.67999 8.14844 7.5995 8.14844 7.56635 8.14844H6.23113L6.15063 8.31889V8.40412L6.64779 8.64087H8.05404C8.1298 8.64087 8.30025 8.66454 8.36181 8.70715C8.48018 8.76397 8.57488 8.86814 8.60802 9.00071C8.61749 9.01492 8.64116 9.15697 8.64116 9.17591L8.80215 10.459L8.81635 10.5585L8.96313 11.7185C8.96787 11.7659 9.03416 12.0878 9.07204 12.1068C9.10518 12.2251 9.13359 12.2441 9.19514 12.2299C9.24723 12.2204 9.31351 12.1873 9.41294 12.1636C9.46029 12.1352 9.47923 11.9884 9.45556 11.7848C9.43188 11.567 9.33719 10.8331 9.28984 10.5017C9.20461 9.93348 9.0673 8.96283 9.0673 8.96283V8.96757Z" fill="#998DB3"/>
            <path d="M9.00555 8.9656C8.92033 8.29325 8.32847 8.19855 7.66086 8.20329C7.62298 8.20329 7.55669 8.20329 7.51881 8.20329H6.212L6.15991 8.39742V8.48264L6.71862 8.63889H8.09646C8.18169 8.63889 8.32847 8.6673 8.39476 8.71465C8.5084 8.77621 8.59362 8.88511 8.62203 9.01768C8.62677 9.02715 8.65044 9.17867 8.65044 9.19287L8.80669 10.4523L8.8209 10.5518L8.96294 11.6881C8.96294 11.7213 9.0245 12.0669 9.0529 12.0764C9.08131 12.2042 9.10025 12.2279 9.15707 12.2137C9.20442 12.2042 9.27071 12.1711 9.37487 12.1521C9.41275 12.1332 9.42222 11.9864 9.39855 11.7781C9.37014 11.5319 9.28965 10.8879 9.23756 10.5186C9.14287 9.87942 9.01029 8.97033 9.01029 8.97033L9.00555 8.9656Z" fill="#9388AD"/>
            <path d="M8.9441 8.97223C8.85887 8.30462 8.27175 8.25727 7.60887 8.262C7.56626 8.262 7.50944 8.262 7.46683 8.262H6.18368L6.15527 8.47507V8.5603L6.77554 8.64079H8.12497C8.21967 8.64079 8.34277 8.67393 8.4138 8.72601C8.5227 8.79704 8.59846 8.90594 8.62213 9.03852C8.62213 9.04325 8.6458 9.20424 8.65054 9.20897L8.80679 10.4448L8.82099 10.5442L8.9583 11.6569C8.9583 11.6569 9.01512 12.0404 9.02933 12.0451C9.053 12.1825 9.06721 12.2061 9.11929 12.1919C9.1619 12.1825 9.22346 12.1493 9.33236 12.1304C9.36077 12.1209 9.36077 11.9789 9.33709 11.7611C9.30868 11.4864 9.23766 10.9372 9.17611 10.5253C9.07194 9.81503 8.9441 8.9675 8.9441 8.9675V8.97223Z" fill="#8E83A7"/>
            <path d="M6.15527 8.63728H8.15811C8.39959 8.63728 8.59845 8.8172 8.6316 9.05394L8.81626 10.5312L9.00092 12.0085C9.03406 12.2689 9.02459 12.1458 9.28501 12.1079C9.33236 12.1079 9.22819 11.3172 9.11455 10.5359C9.00092 9.7547 8.87781 8.97345 8.87781 8.97345C8.78785 8.26322 8.12497 8.31531 7.41001 8.31531H6.15527V8.63728Z" fill="#887DA2"/>
            <path d="M7.08325 8.11973L4.95731 8.0203C4.42227 8.02504 4.13818 8.02031 4.05768 8.46538L3.59841 11.794C3.5842 11.9313 3.37587 12.1112 3.32852 12.1917C3.19594 12.0875 3.11545 11.9218 3.13913 11.7419L3.52265 8.65477C3.61735 7.91614 4.24708 7.35742 4.99518 7.35742H7.07378V8.115L7.08325 8.11973Z" fill="#E9DFF8"/>
            <path d="M7.07849 7.39618V8.14902L6.88436 8.15849L5.8711 8.11588L4.98095 8.078C4.93834 8.078 4.89099 8.078 4.84838 8.078C4.53588 8.078 4.31807 8.11115 4.20444 8.27687C4.15709 8.33368 4.12394 8.43785 4.105 8.54202L3.93455 9.79202L3.72622 11.3214C3.72622 11.3214 3.64572 11.8469 3.63152 11.8517C3.61732 11.9132 3.56997 11.9795 3.51315 12.0411C3.44686 12.1121 3.37584 12.1736 3.34269 12.2068C3.21012 12.1026 3.12963 11.9369 3.15804 11.757L3.34743 10.2182L3.54156 8.67933C3.63625 7.94069 4.26599 7.38672 5.00462 7.38672H7.07849V7.39618Z" fill="#E6DBF6"/>
            <path d="M7.07862 7.42188V8.16998L6.90343 8.20313L5.89017 8.16525L5.00002 8.1321C4.96214 8.1321 4.90532 8.1321 4.87218 8.1321C4.56441 8.1321 4.35134 8.17945 4.24244 8.35464C4.20456 8.40672 4.16668 8.52509 4.15248 8.61979L3.98203 9.86506L3.77369 11.3897C3.77369 11.4228 3.68373 11.8916 3.65532 11.9058C3.63638 11.9626 3.5843 12.0241 3.52748 12.0762C3.46119 12.1378 3.38544 12.1899 3.35229 12.2183C3.21972 12.1141 3.14396 11.9484 3.16763 11.7732L3.35703 10.2391L3.54642 8.70975C3.64112 7.97585 4.26612 7.42188 5.00475 7.42188H7.06915H7.07862Z" fill="#E3D8F4"/>
            <path d="M7.08326 7.45898V8.20709L6.92701 8.25917L5.91376 8.22603L5.02834 8.19762C4.9952 8.19762 4.92891 8.19762 4.9005 8.19762C4.6022 8.19762 4.38914 8.25917 4.2897 8.44383C4.25182 8.49118 4.21868 8.62376 4.20448 8.70898L4.03876 9.94951L3.83516 11.4694C3.83042 11.5215 3.74046 11.9524 3.69785 11.9713C3.67417 12.0234 3.62209 12.0802 3.56054 12.1275C3.48951 12.1844 3.41376 12.227 3.38061 12.2412C3.24804 12.137 3.17228 11.9713 3.19595 11.7961L3.38535 10.2715L3.57474 8.74686C3.66944 8.01296 4.2897 7.46372 5.02834 7.46372H7.088L7.08326 7.45898Z" fill="#E0D4F1"/>
            <path d="M7.08326 7.48438V8.22775L6.95069 8.29877L5.93743 8.27036L5.05201 8.24669C5.02361 8.24669 4.94785 8.24669 4.92417 8.24669C4.63061 8.24669 4.42228 8.32244 4.32758 8.51184C4.29444 8.54971 4.26129 8.70596 4.24709 8.77699L4.08137 10.0128L3.87777 11.5279C3.8683 11.5942 3.77834 11.992 3.71679 12.0156C3.68838 12.063 3.63156 12.1151 3.57001 12.1529C3.49898 12.2003 3.42323 12.2334 3.38061 12.2429C3.24804 12.1387 3.17228 11.973 3.19595 11.8026L3.38535 10.2827L3.57474 8.76278C3.6647 8.03362 4.2897 7.48438 5.0236 7.48438H7.07379H7.08326Z" fill="#DDD1EF"/>
            <path d="M7.08327 7.5145V8.25787L6.96963 8.35257L5.96111 8.32889L5.0757 8.30522C5.05202 8.30522 4.97153 8.30522 4.94785 8.30522C4.66376 8.30522 4.45543 8.39991 4.3702 8.59404C4.34179 8.62719 4.30865 8.79764 4.29918 8.85919L4.1382 10.0903L3.93933 11.5959C3.92986 11.6812 3.83043 12.041 3.75467 12.0694C3.72153 12.1168 3.65998 12.1594 3.59842 12.1925C3.52267 12.2304 3.44691 12.2541 3.40429 12.2541C3.27172 12.1547 3.19596 11.9889 3.21963 11.8137L3.40903 10.2986L3.59842 8.78344C3.68839 8.05427 4.30865 7.50977 5.04255 7.50977H7.08801L7.08327 7.5145Z" fill="#DACDEC"/>
            <path d="M7.08339 7.54297V8.28161L6.98869 8.39524L5.98017 8.37631L5.09475 8.35736H4.96691C4.68755 8.35736 4.48396 8.46627 4.4082 8.66513C4.38452 8.69354 4.35138 8.8782 4.34191 8.93502L4.18093 10.1661L3.98206 11.6718C3.96786 11.7712 3.86842 12.0979 3.77846 12.131C3.74058 12.1737 3.67903 12.2115 3.61274 12.2352C3.53699 12.2636 3.45649 12.2826 3.41388 12.2731C3.28604 12.1737 3.20555 12.0079 3.22922 11.8327L3.41862 10.3271L3.60801 8.81664C3.69797 8.09221 4.31824 7.54771 5.0474 7.54771H7.08339V7.54297Z" fill="#D7CAEB"/>
            <path d="M7.0832 7.56836V8.30699L7.00744 8.4443L5.99891 8.42537L5.11823 8.41116C5.11823 8.41116 5.0046 8.41116 4.99039 8.41116C4.72051 8.41116 4.51217 8.53427 4.44588 8.7426C4.42694 8.76627 4.3938 8.96514 4.38907 9.00775L4.22808 10.2341L4.03395 11.7303C4.01975 11.8487 3.91085 12.1375 3.80668 12.1754C3.76407 12.2132 3.69778 12.2464 3.63149 12.2653C3.551 12.289 3.47524 12.2937 3.42316 12.2748C3.29532 12.1754 3.21482 12.0096 3.2385 11.8392L3.42789 10.3382L3.61729 8.8373C3.70725 8.1176 4.32278 7.57309 5.05195 7.57309H7.0832V7.56836Z" fill="#D3C6E8"/>
            <path d="M7.08332 7.60517V8.33908L7.0265 8.49533L6.02271 8.48112L5.14203 8.47165C5.14203 8.47165 5.02839 8.47165 5.01419 8.47165C4.74904 8.47165 4.54544 8.60897 4.48862 8.82203C4.47442 8.84097 4.44127 9.05878 4.43654 9.08718L4.28029 10.3088L4.08616 11.8003C4.06722 11.9328 3.95832 12.1885 3.83995 12.2311C3.78786 12.2643 3.72158 12.2927 3.65529 12.3021C3.5748 12.3163 3.4943 12.3116 3.44222 12.2832C3.31438 12.1838 3.23389 12.0228 3.26229 11.8476L3.45169 10.3514L3.64108 8.85517C3.73105 8.13548 4.34658 7.5957 5.06627 7.5957H7.08805L7.08332 7.60517Z" fill="#D0C2E5"/>
            <path d="M7.08335 7.63086V8.36476L7.04547 8.54469L6.04168 8.53522L5.161 8.52575H5.03789C4.78221 8.52575 4.57861 8.67726 4.53126 8.8998C4.52179 8.90927 4.48865 9.14601 4.48391 9.16968L4.32766 10.3865L4.13827 11.8733C4.11933 12.0248 4.00569 12.2426 3.86838 12.2899C3.81157 12.3231 3.74528 12.342 3.67426 12.3468C3.58903 12.3515 3.50853 12.3373 3.45645 12.3042C3.32861 12.2047 3.25285 12.0437 3.27653 11.8685L3.46119 10.3771L3.64585 8.8856C3.73581 8.17064 4.3466 7.63086 5.0663 7.63086H7.08335Z" fill="#CDBFE3"/>
            <path d="M7.0834 7.65986V8.38903L7.06446 8.58789H6.06067L5.17999 8.57842H5.05688C4.80593 8.57842 4.60707 8.74414 4.56446 8.97141C4.56446 8.97614 4.52658 9.2271 4.52658 9.2413L4.37506 10.4534L4.18567 11.9354C4.162 12.1011 4.04362 12.2858 3.89684 12.3379C3.83529 12.3663 3.76427 12.3805 3.69324 12.3758C3.60802 12.3758 3.52753 12.3521 3.46597 12.3047C3.33813 12.2053 3.26238 12.0443 3.28605 11.8739L3.47071 10.3871L3.65537 8.90039C3.74533 8.18543 4.35613 7.65039 5.07109 7.65039H7.07866L7.0834 7.65986Z" fill="#CABCE1"/>
            <path d="M7.08335 8.6356H5.08051C4.83903 8.6356 4.64017 8.81552 4.60703 9.05226L4.42237 10.5295L4.23771 12.0068C4.20457 12.2672 3.97256 12.4519 3.71214 12.4187C3.62218 12.4093 3.54169 12.3761 3.48013 12.324C3.35229 12.2246 3.27654 12.0636 3.30021 11.8932L3.48487 10.4159L3.66953 8.93863C3.75949 8.2284 4.36555 7.69336 5.08051 7.69336H7.08335V8.64033V8.6356Z" fill="#C7B8DF"/>
            <path d="M3.73087 8.93732C3.82083 8.23183 4.42689 7.74414 5.13238 7.74414H6.81325L7.0784 7.76308V7.84357L7.01685 8.62955H5.41174C5.41174 8.62955 5.05662 8.62956 5.04715 8.63903C4.88617 8.64376 4.74886 8.73372 4.66837 8.8663C4.63522 8.91838 4.60681 8.99414 4.59734 9.06516L4.41742 10.5188L4.40321 10.6277L4.2375 11.9676C4.21856 12.1191 4.11912 12.2801 3.98655 12.3417C3.91553 12.389 3.83503 12.4032 3.74981 12.3938C3.66458 12.3843 3.58409 12.3512 3.5178 12.3038C3.39943 12.2138 3.33314 12.0529 3.35681 11.8777C3.35681 11.8493 3.532 10.4619 3.54147 10.4241C3.55094 10.353 3.73087 8.93732 3.73087 8.93732Z" fill="#C1B2D9"/>
            <path d="M3.79252 8.94304C3.88248 8.24228 4.48381 7.80194 5.1893 7.80668H6.84176L7.08324 7.84929V7.92978L6.96013 8.64001H5.38343C5.38343 8.64001 5.04252 8.64474 5.02831 8.65895C4.87206 8.67315 4.73949 8.76311 4.66846 8.89569C4.63532 8.94304 4.61165 9.02827 4.60218 9.08982L4.42225 10.5197L4.40805 10.6286L4.24233 11.9449C4.22339 12.0822 4.12869 12.2669 4.01032 12.3237C3.94403 12.3805 3.87301 12.3947 3.78778 12.3853C3.70729 12.3758 3.63153 12.3427 3.56051 12.3C3.45161 12.2196 3.39006 12.0633 3.41373 11.8834C3.41846 11.8266 3.57945 10.5339 3.59365 10.4535C3.61259 10.3114 3.78778 8.95724 3.78778 8.95724L3.79252 8.94304Z" fill="#BBADD4"/>
            <path d="M3.85881 8.94365C3.94878 8.24763 4.54537 7.85938 5.24613 7.85938H6.87018L7.08325 7.92566V8.00615L6.89859 8.63589H5.35029C5.32188 8.63589 5.02359 8.64535 5.00465 8.65956C4.85313 8.6785 4.73003 8.7732 4.66374 8.90577C4.63533 8.94839 4.61166 9.04308 4.60219 9.09516L4.427 10.5014L4.41279 10.6103L4.25181 11.8982C4.2376 12.0213 4.14764 12.2296 4.04347 12.277C3.98192 12.3433 3.91563 12.3622 3.83514 12.348C3.75938 12.3385 3.68363 12.3054 3.60787 12.2628C3.50844 12.1918 3.45635 12.0355 3.48003 11.8508C3.4895 11.7704 3.64101 10.5677 3.65995 10.4446C3.69309 10.2315 3.85881 8.94365 3.85881 8.94365Z" fill="#B6A8CE"/>
            <path d="M3.9203 8.95016C4.00553 8.25887 4.60685 7.91797 5.30288 7.91797H6.89852L7.08791 8.00319V8.08842L6.83697 8.63767H5.31708C5.2792 8.63767 5.00932 8.65187 4.98091 8.67081C4.83886 8.69922 4.72049 8.79392 4.65894 8.92649C4.63526 8.96437 4.61159 9.06854 4.60212 9.11588L4.43166 10.4985L4.41746 10.6026L4.26121 11.8668C4.24701 11.9757 4.16178 12.203 4.07182 12.2456C4.015 12.3214 3.95818 12.3403 3.88242 12.3261C3.8114 12.3166 3.73564 12.2835 3.65515 12.2456C3.56519 12.1841 3.52257 12.0278 3.54625 11.8384C3.55572 11.7295 3.69776 10.6216 3.72144 10.4558C3.76405 10.1718 3.92504 8.94543 3.92504 8.94543L3.9203 8.95016Z" fill="#B0A3C9"/>
            <path d="M3.98196 8.95277C4.06719 8.26149 4.66378 7.97266 5.35507 7.97266H6.9223L7.08329 8.08156V8.16679L6.77078 8.64027H5.27931C5.23196 8.64027 4.98575 8.65447 4.94787 8.68288C4.81056 8.71603 4.69692 8.81546 4.64484 8.94803C4.6259 8.98118 4.60222 9.09481 4.59276 9.13269L4.4223 10.4869L4.4081 10.591L4.25185 11.8316C4.24238 11.9215 4.16189 12.1772 4.08139 12.2151C4.02931 12.3003 3.98196 12.3192 3.91094 12.3098C3.84465 12.3003 3.77363 12.2672 3.6884 12.234C3.61264 12.1772 3.57003 12.0257 3.5937 11.8363C3.60791 11.699 3.73575 10.6857 3.76416 10.4774C3.81624 10.1223 3.97249 8.95751 3.97249 8.95751L3.98196 8.95277Z" fill="#AA9DC3"/>
            <path d="M4.04356 8.95852C4.12879 8.27197 4.72538 8.03049 5.41193 8.03522C5.43561 8.03522 5.53504 8.03522 5.56345 8.03522H6.95076L7.08334 8.16307V8.24829L6.70928 8.64128H5.24622C5.1894 8.64128 4.96686 8.66022 4.92425 8.69337C4.79167 8.73598 4.6875 8.83541 4.64015 8.96799C4.62121 8.9964 4.59754 9.11477 4.59281 9.14791L4.42709 10.4784L4.41288 10.5826L4.26137 11.7947C4.2519 11.8705 4.17614 12.1498 4.11459 12.1782C4.06724 12.2776 4.02462 12.2919 3.95834 12.2824C3.89678 12.2729 3.82576 12.2398 3.7358 12.2066C3.66478 12.1593 3.63637 12.0125 3.66004 11.8136C3.67898 11.6479 3.79262 10.7294 3.8305 10.4831C3.89205 10.057 4.04356 8.95852 4.04356 8.95852Z" fill="#A598BE"/>
            <path d="M4.10982 8.96496C4.19505 8.28314 4.79164 8.09375 5.46873 8.09375C5.49713 8.09375 5.5871 8.09375 5.61551 8.09375H6.97441L7.08331 8.24527V8.33049L6.6477 8.64299H5.21305C5.14676 8.64299 4.94789 8.66667 4.90055 8.69981C4.7727 8.74716 4.67801 8.85133 4.63539 8.9839C4.62119 9.00284 4.59752 9.13542 4.59752 9.16383L4.43653 10.4706L4.42232 10.5748L4.27555 11.7633C4.26608 11.8248 4.19979 12.1231 4.1477 12.1468C4.10983 12.2557 4.07195 12.2746 4.00566 12.2604C3.94884 12.2509 3.87782 12.2178 3.78785 12.1894C3.73104 12.1515 3.70736 12.0047 3.73104 11.8011C3.74998 11.607 3.85888 10.7831 3.89676 10.4943C3.96778 9.99716 4.11456 8.96023 4.11456 8.96023L4.10982 8.96496Z" fill="#9F92B8"/>
            <path d="M4.1715 8.96757C4.25672 8.29049 4.84858 8.14844 5.52566 8.14844C5.55881 8.14844 5.6393 8.14844 5.67244 8.14844H7.00767L7.08816 8.31889V8.40412L6.591 8.64087H5.18475C5.109 8.64087 4.93854 8.66454 4.87699 8.70715C4.75862 8.76397 4.66392 8.86814 4.63078 9.00071C4.62131 9.01492 4.59763 9.15697 4.5929 9.17591L4.43191 10.459L4.41771 10.5585L4.27093 11.7185C4.26619 11.7659 4.1999 12.0878 4.16203 12.1068C4.12888 12.2251 4.10047 12.2441 4.03892 12.2299C3.98684 12.2204 3.92055 12.1873 3.82112 12.1636C3.77377 12.1352 3.75483 11.9884 3.7785 11.7848C3.80218 11.567 3.89687 10.8331 3.94422 10.5017C4.02945 9.93348 4.16676 8.96283 4.16676 8.96283L4.1715 8.96757Z" fill="#998DB3"/>
            <path d="M4.233 8.9656C4.31822 8.29325 4.90535 8.19855 5.57769 8.20329C5.61557 8.20329 5.68186 8.20329 5.71974 8.20329H7.02656L7.07864 8.39742V8.48264L6.51993 8.63889H5.14209C5.05686 8.63889 4.91008 8.6673 4.84379 8.71465C4.73015 8.77621 4.64493 8.88511 4.61652 9.01768C4.61178 9.02715 4.58811 9.17867 4.58337 9.19287L4.42713 10.4523L4.41292 10.5518L4.27088 11.6881C4.27088 11.7213 4.20932 12.0669 4.18091 12.0764C4.1525 12.2042 4.13357 12.2279 4.07201 12.2137C4.02466 12.2042 3.95837 12.1711 3.85421 12.1521C3.81633 12.1332 3.80686 11.9864 3.83053 11.7781C3.85421 11.5319 3.93944 10.8879 3.99152 10.5186C4.08622 9.87942 4.21879 8.97033 4.21879 8.97033L4.233 8.9656Z" fill="#9388AD"/>
            <path d="M4.29447 8.97223C4.3797 8.30462 4.96682 8.25727 5.6297 8.262C5.67231 8.262 5.72913 8.262 5.77175 8.262H7.05489L7.0833 8.47507V8.5603L6.46303 8.64079H5.1136C5.0189 8.64079 4.8958 8.67393 4.82478 8.72601C4.71588 8.79704 4.64012 8.90594 4.61171 9.03852C4.61171 9.04325 4.58804 9.20424 4.58804 9.20897L4.43178 10.4448L4.41758 10.5442L4.28027 11.6569C4.28027 11.6569 4.22345 12.0404 4.20925 12.0451C4.18557 12.1825 4.17137 12.2061 4.11928 12.1919C4.07667 12.1825 4.01512 12.1493 3.90622 12.1304C3.87781 12.1209 3.87781 11.9789 3.90148 11.7611C3.92989 11.4864 4.00091 10.9372 4.06247 10.5253C4.16663 9.81503 4.29447 8.9675 4.29447 8.9675V8.97223Z" fill="#8E83A7"/>
            <path d="M7.08341 8.63728H5.08057C4.83909 8.63728 4.64023 8.8172 4.60708 9.05394L4.42242 10.5312L4.23776 12.0085C4.20462 12.2689 4.21409 12.1458 3.95367 12.1079C3.91106 12.1079 4.01049 11.3172 4.12413 10.5359C4.23776 9.7547 4.36087 8.97345 4.36087 8.97345C4.45083 8.26322 5.11371 8.31531 5.82867 8.31531H7.08341V8.63728Z" fill="#887DA2"/>
            <path d="M6.62874 20.0006C9.50753 20.0006 11.8371 17.6664 11.8371 14.7923C11.8371 11.9183 9.50753 9.58398 6.62874 9.58398C3.74996 9.58398 1.42041 11.9183 1.42041 14.7923C1.42041 17.6664 3.74996 20.0006 6.62874 20.0006Z" fill="url(#paint0_linear_14442_5591)"/>
            <path d="M6.62868 19.0943C9.00558 19.0943 10.9327 17.1672 10.9327 14.7903C10.9327 12.4134 9.00558 10.4863 6.62868 10.4863C4.25179 10.4863 2.32471 12.4134 2.32471 14.7903C2.32471 17.1672 4.25179 19.0943 6.62868 19.0943Z" fill="url(#paint1_linear_14442_5591)"/>
            <path d="M6.62873 18.6442C8.75467 18.6442 10.4782 16.9207 10.4782 14.7947C10.4782 12.6688 8.75467 10.9453 6.62873 10.9453C4.50278 10.9453 2.7793 12.6688 2.7793 14.7947C2.7793 16.9207 4.50278 18.6442 6.62873 18.6442Z" fill="url(#paint2_linear_14442_5591)"/>
            <path d="M6.62874 20.0006C9.50753 20.0006 11.8371 17.6664 11.8371 14.7923C11.8371 11.9183 9.50753 9.58398 6.62874 9.58398C3.74996 9.58398 1.42041 11.9183 1.42041 14.7923C1.42041 17.6664 3.74996 20.0006 6.62874 20.0006Z" fill="url(#paint3_radial_14442_5591)"/>
            <path d="M6.62868 19.0943C9.00558 19.0943 10.9327 17.1672 10.9327 14.7903C10.9327 12.4134 9.00558 10.4863 6.62868 10.4863C4.25179 10.4863 2.32471 12.4134 2.32471 14.7903C2.32471 17.1672 4.25179 19.0943 6.62868 19.0943Z" fill="url(#paint4_radial_14442_5591)"/>
            <path d="M6.62868 19.0943C9.00558 19.0943 10.9327 17.1672 10.9327 14.7903C10.9327 12.4134 9.00558 10.4863 6.62868 10.4863C4.25179 10.4863 2.32471 12.4134 2.32471 14.7903C2.32471 17.1672 4.25179 19.0943 6.62868 19.0943Z" fill="url(#paint5_radial_14442_5591)"/>
            <path d="M6.6288 13.9796C7.43373 13.9796 8.03032 14.1406 8.41384 14.4579C8.8021 14.7751 8.99623 15.2012 8.99623 15.7315C8.99623 16.0772 8.90153 16.3944 8.71687 16.688C8.53694 16.9721 8.25759 17.2041 7.8788 17.3793C7.50475 17.5497 7.04073 17.6349 6.49149 17.6349C6.0843 17.6349 5.68183 17.5876 5.2841 17.4929C4.89111 17.3982 4.5502 17.2609 4.26611 17.0857L4.8485 15.902C5.08051 16.0488 5.33145 16.1671 5.60607 16.2429C5.88543 16.3234 6.16479 16.3613 6.44414 16.3613C6.7235 16.3613 6.94604 16.3092 7.11176 16.2098C7.28221 16.1056 7.36744 15.9588 7.36744 15.7694C7.36744 15.58 7.28695 15.4427 7.12123 15.348C6.95551 15.2533 6.66668 15.206 6.25001 15.206H4.68751L4.97634 11.9531H8.64111V13.17H6.32104L6.24528 13.9796H6.63354H6.6288Z" fill="url(#paint6_linear_14442_5591)"/>
            <path d="M8.25758 6.23579L3.78788 0H0L4.4697 6.25473C4.64015 6.49621 4.73485 6.78504 4.73485 7.0786V8.04924H8.52273V7.06439C8.52273 6.7661 8.42803 6.47727 8.25758 6.23579Z" fill="url(#paint7_linear_14442_5591)"/>
            <path d="M12.0218 0H9.46971L8.79263 0.94697L8.11081 1.89394L6.41573 4.26136L5.73865 5.20833L5.00002 6.23579C4.82956 6.47727 4.73486 6.7661 4.73486 7.06439V8.04924H8.52274V7.0786C8.52274 6.78504 8.61744 6.49621 8.78789 6.25473L9.536 5.20833L10.2131 4.26136L11.9034 1.89394L13.2576 0H12.0218Z" fill="url(#paint8_linear_14442_5591)"/>
            <path d="M8.04926 7.10352H5.20835C4.94793 7.10352 4.73486 7.31658 4.73486 7.577V8.52397C4.73486 8.78439 4.94793 8.99745 5.20835 8.99745H8.04926C8.30967 8.99745 8.52274 8.78439 8.52274 8.52397V7.577C8.52274 7.31658 8.30967 7.10352 8.04926 7.10352Z" fill="url(#paint9_linear_14442_5591)"/>
            <defs>
                <linearGradient id="paint0_linear_14442_5591" x1="0.984804" y1="7.31599" x2="10.5066" y2="19.9391" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F9F8FD"/>
                    <stop offset="0.3" stop-color="#DDCEF3"/>
                    <stop offset="0.37" stop-color="#D9C9EF"/>
                    <stop offset="0.45" stop-color="#CCBDE4"/>
                    <stop offset="0.55" stop-color="#B8AAD2"/>
                    <stop offset="0.6" stop-color="#AA9CC5"/>
                    <stop offset="0.73" stop-color="#9C90B7"/>
                    <stop offset="0.98" stop-color="#776E91"/>
                    <stop offset="1" stop-color="#71688C"/>
                </linearGradient>
                <linearGradient id="paint1_linear_14442_5591" x1="9.84365" y1="19.814" x2="3.1533" y2="9.35944" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F9F8FD"/>
                    <stop offset="0.3" stop-color="#DDCEF3"/>
                    <stop offset="0.37" stop-color="#D9C9EF"/>
                    <stop offset="0.45" stop-color="#CCBDE4"/>
                    <stop offset="0.55" stop-color="#B8AAD2"/>
                    <stop offset="0.6" stop-color="#AA9CC5"/>
                    <stop offset="0.73" stop-color="#9C90B7"/>
                    <stop offset="0.98" stop-color="#776E91"/>
                    <stop offset="1" stop-color="#71688C"/>
                </linearGradient>
                <linearGradient id="paint2_linear_14442_5591" x1="3.98195" y1="12.1669" x2="11.5388" y2="19.6622" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F9F8FD"/>
                    <stop offset="0.3" stop-color="#DDCEF3"/>
                    <stop offset="0.39" stop-color="#D9C9EF"/>
                    <stop offset="0.51" stop-color="#CCBDE4"/>
                    <stop offset="0.64" stop-color="#B8AAD2"/>
                    <stop offset="0.71" stop-color="#AA9CC5"/>
                    <stop offset="0.8" stop-color="#9C90B7"/>
                    <stop offset="0.98" stop-color="#776E91"/>
                    <stop offset="1" stop-color="#71688C"/>
                </linearGradient>
                <radialGradient id="paint3_radial_14442_5591" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.18301 15.4507) scale(7.22538 7.22538)">
                    <stop stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.73" stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.84" stop-color="white"/>
                    <stop offset="0.93" stop-color="#F6F1FF" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint4_radial_14442_5591" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.84686 14.6) scale(6.47727 6.47727)">
                    <stop stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.73" stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.84" stop-color="white"/>
                    <stop offset="0.93" stop-color="#F6F1FF" stopOpacity="0"/>
                </radialGradient>
                <radialGradient id="paint5_radial_14442_5591" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(7.84686 14.6) scale(6.47727 6.47727)">
                    <stop stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.73" stop-color="#F6F1FF" stopOpacity="0"/>
                    <stop offset="0.84" stop-color="white"/>
                    <stop offset="0.93" stop-color="#F6F1FF" stopOpacity="0"/>
                </radialGradient>
                <linearGradient id="paint6_linear_14442_5591" x1="0.18941" y1="6.41809" x2="13.5322" y2="23.6813" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#F9F8FD"/>
                    <stop offset="0.25" stop-color="#DDCEF3"/>
                    <stop offset="0.3" stop-color="#D9C9EF"/>
                    <stop offset="0.37" stop-color="#CCBDE4"/>
                    <stop offset="0.44" stop-color="#B8AAD2"/>
                    <stop offset="0.48" stop-color="#AA9CC5"/>
                    <stop offset="0.51" stop-color="#A597BF"/>
                    <stop offset="0.68" stop-color="#8A7FA4"/>
                    <stop offset="0.82" stop-color="#797093"/>
                    <stop offset="0.92" stop-color="#71688C"/>
                </linearGradient>
                <linearGradient id="paint7_linear_14442_5591" x1="1.32576" y1="0.260417" x2="5.88068" y2="6.10322" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7"/>
                    <stop offset="1" stop-color="#0071DA"/>
                </linearGradient>
                <linearGradient id="paint8_linear_14442_5591" x1="7.17805" y1="6.51989" x2="11.3873" y2="0.194128" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#3FBCF7"/>
                    <stop offset="1" stop-color="#0071DA"/>
                </linearGradient>
                <linearGradient id="paint9_linear_14442_5591" x1="6.33998" y1="11.3886" x2="6.7377" y2="6.77681" gradientUnits="userSpaceOnUse">
                    <stop offset="0.49" stop-color="#3FBCF7"/>
                    <stop offset="1" stop-color="#0071DA"/>
                </linearGradient>
            </defs>
        </svg>
    )
}
