import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { IEditRequestIdea, IIdeasItem } from "../ideas/interface";

export const $getFileCategories = (
    handler?: IApiHandlerParams
): Promise<any> => api.get("backend/v1/file/categories", {
    handler
});

export const $getFileCategoryById = (
    id: string | undefined,
    handler?: IApiHandlerParams
): Promise<any> => api.get(`backend/v1/file/categories/${id}`, {
    handler
});

export const $addFileItem = (
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.post("backend/v1/file/items", data, {
        handler
    });

export const $editFileItem = (
    id: number | undefined,
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.post(`backend/v1/file/items/${id}`, data, {
        handler
    });

export const $updateFileItem = (
    id: number | undefined,
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.post(`backend/v1/file/items/${id}`, data, {
        handler
    });

export const $deleteFileItem = (
    id: number | undefined,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.delete(`backend/v1/file/items/${id}`, {
        handler
    });

export const $moveFileItem = (
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.patch(`backend/v1/file/move`, data, {
        handler
    });

export const $addFolder = (
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.post("backend/v1/file/categories", data, {
        handler
    });

export const $updateFolder = (
    id: number | undefined,
    data: any,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.put(`backend/v1/file/categories/${id}`, data, {
        handler
    });

export const $deleteFolder = (
    id: number | undefined,
    handler?: IApiHandlerParams
): Promise<IIdeasItem> =>
    api.delete(`backend/v1/file/categories/${id}`, {
        handler
    });