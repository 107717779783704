import Button from "../../../components/ui/form/Buttons/Button";
import { getStructureCompany, setStructureCompany } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";

interface StructureNavProps {
    setDepartment: (value: any) => void;
    setSearchDepartment: (value: any) => void;
}

const StructureNav = (
    {
        setDepartment,
        setSearchDepartment
    }: StructureNavProps
) => {
    const dispatch = useDispatch();

    const { structure } = useSelector(getStructureCompany);

    const updateNav = structure?.length > 4 ? structure?.slice(structure?.length - 4) : structure;

    const handleActive = (person: any, isMain?: boolean) => {
        if (!person?.isActiveStyle && person?.hasChild) {
            const updateArray = structure?.map((_: any, i: number) =>
                structure?.[i]?.map((item: any) => {
                    return { ...item, isActiveStyle: false };
                }),
            );

            const updateIndexArray = structure?.[person.index]?.map((item: any) => {
                if (person.department?.id ? item?.department?.id === person.department?.id : item?.id === person.id) {
                    return { ...item, isActive: true, isActiveStyle: true };
                }

                return { ...item, isActive: false, isActiveStyle: false };
            });

            const copyArray = [...updateArray];

            copyArray[person.index] = updateIndexArray;

            dispatch(
                setStructureCompany({
                    structure: copyArray?.slice(0, person.index + 1),
                }),
            );

            setDepartment(person.department_id || person.department?.department_id);
            setSearchDepartment(person.department_id || person.department?.department_id);
        }

        if (isMain) {
            const updateIndexArray = structure?.[0]?.map((item: any) => {
                return { ...item, isActive: true, isActiveStyle: true };
            });

            const copyArray = [...structure];

            copyArray[0] = updateIndexArray;

            dispatch(
                setStructureCompany({
                    structure: copyArray?.slice(0, 1),
                }),
            );

            setDepartment(copyArray[0]?.department_id || copyArray[0]?.department?.department_id);
            setSearchDepartment(copyArray[0]?.department_id || copyArray[0]?.department?.department_id);
        }
    };

    return (
        <div className={"structure__nav"}>
            {structure?.length > 4 && (
                <Button
                    text={'...'}
                    className={"btn btn-primary"}
                    onClick={() => handleActive(null, true)}
                />
            )}

            {updateNav?.map((col: any, index: number) => (
                <div className={""} key={index}>
                    {col?.map(
                        (person: any, idx: number) => {
                            if (structure?.length - 1 !== index && person?.isActive) {
                                const isArrSeveral = col
                                .map((i: any, _idx: number) => ({
                                    ...i,
                                    idx: _idx
                                }))
                                .filter((i: any) => {
                                    if (!(structure?.length - 1 !== index && person?.isActive)) return false;

                                    return i?.title === person?.title
                                })

                                if (isArrSeveral && isArrSeveral.length > 1) {
                                    if (isArrSeveral[1].idx === idx) return null
                                }
                            }

                            return (
                                structure?.length - 1 !== index &&
                                person?.isActive && (
                                    <Button
                                        text={person.department?.title || person.title}
                                        className={`btn btn-${person?.isActiveStyle ? "primary" : "secondary"}`}
                                        onClick={() => handleActive(person)}
                                    />
                                )
                            )
                        }
                    )}
                </div>
            ))}
        </div>
    )
}

export default StructureNav