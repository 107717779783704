import { FC, useEffect, useState } from "react";
import { statisticsDates } from "./dates";
import { AnalyticsLineChart } from "./AnalyticsLineChart";
import { IconCalendar } from "../../../img";

interface IChartData {
    month?: string,
    year?: string,
    count: string,
    hour?: string,
    day?: string,
}
interface IStatisticsChartBlock {
    color: string,
    startDate: number,
    endDate: number,
    text: string,
    title: string,
    total_count: number,
    filter_type: string,
    isExcel?: boolean,
    excelUrl?: string,
    page?: string,
    icon?: any,
    data: IChartData[],
    isLoading: boolean,
}

export const AnalyticsChartBlock: FC<IStatisticsChartBlock> = (
    {
        color,
        text,
        startDate,
        endDate,
        title,
        total_count,
        filter_type,
        isExcel,
        excelUrl,
        page,
        data,
        isLoading,
        icon,
    }
) => {
    const [editedData, setEditedData] = useState(data)

    useEffect(() => {
        if (data) {
            const updatedList = data?.map((item: any) => {
                const monthDay = new Date(item?.date).getMonth()

                return {
                    ...item,
                    month: statisticsDates.months[monthDay]
                }
            })
            setEditedData(updatedList)
        }
    }, [data])


    return (
        <div className='analytics-chart'>
            <div className='analytics-chart__header'>
                <h6 className='analytics-chart__header__text'>{text}</h6>

                <div className='analytics-chart__stats'>
                    {icon}

                    <h5 className='analytics-chart__stats__number'>{isLoading ? '...' : total_count}</h5>
                </div>
            </div>

            <AnalyticsLineChart
                data={editedData}
                filter_type={filter_type}
                color={color}
                page={page}
                title={title}
            />
        </div>
    )
}