import { ArrowExitIcon, CommentIcon, EyeIcon, LikeIcon, StarIcon } from "../../../img";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import TextField from "../../../components/ui/form/Fields/TextField";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import React, { useEffect, useState } from "react";
import Button from "../../../components/ui/form/Buttons/Button";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import {
    $createBlogs, $deleteBlogs, $deleteDashboardBlogs, $editBlogs, $editDashboardBlogs,
    $getBlogs,
    $getBlogsCategories,
    $getBlogsItem
} from "../../../api/requests/blogs";
import { IBlogsRes } from "../../../api/requests/blogs/interface";
import { ClockIcon } from "@mui/x-date-pickers";
import { getYearMonthDay, isMd } from "../../../utils";
import EditorField from "../../../components/ui/form/Fields/EditorField";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentRole, getRedactorNewsListData, setRedactorNewsListData } from "../../../redux";
import { withDebounce } from "../../../functions";
import { EditorShow } from "../../../utils/field/editor-show";
import CompleteModal from "../../../components/ui/dialog/CompleteModal";
import { $uploadImage } from "../../../api/requests/upload";
import FileField from "../../../components/ui/form/FileField/FileField";
import noImageBanner from "../../../img/noimage.png";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface CreateBlogFormSubmit {
    banner?: File | string | undefined;
    title?: string;
    text?: string;
    json_text?: string,
    category_id?: any;
    is_favourite?: number,
    status?: number,
    category?: any,
}

export const BlogsCreatePage = () => {
    const location = useLocation();
    const pathname = location.pathname;

    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const searchParams = new URLSearchParams(location.search);
    const { id } = useParams();

    const dispatch = useDispatch();

    const isDraft = pathname.includes("draft");
    const isEdit: boolean = pathname.includes("edit");
    const isModeration = pathname.includes("moderation");
    const isCreate = pathname.includes("create");

    const isMobile = isMd();
    const navigate = useNavigate();

    const htmlData = useSelector(getRedactorNewsListData);

    const purpose: string = pathname.includes("edit") ? "edit" : "create";
    const blogs_id = searchParams.get("blogs_id");

    const [deleteModalOpen, setDeleteModalOpen] = useState(0);

    const [blogData, setBlogData] = useState<IBlogsRes>();

    const [editData, setEditData] = useState<any | null>(null);

    const [isShow, setIsShow] = useState(false);

    const [categoryList, setCategoryList] = useState<DropdownItem[]>([]);

    const btnListRemove = [
        {
            text: "Удалить",
            key: 0,
            className: "btn btn-danger w-100"
        },
        {
            text: "Отмена",
            key: 1,
            className: "btn btn-light w-100"
        }
    ];

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: { errors, isValid },
        setValue,
        trigger
    } = useForm<CreateBlogFormSubmit>(
        (isEdit && editData) ? {
            values: {
                banner: editData?.banner,
                title: editData?.title,
                text: editData?.text,
                json_text: (typeof (editData?.json_text) === "string") ? JSON?.parse(editData?.json_text) : editData?.json_text,
                is_favourite: editData?.is_favourite,
                status: editData?.status,
                category_id: editData.category_id
            }
        } : {
            values: {
                banner: "",
                title: "",
                text: "",
                json_text: undefined,
                category_id: undefined
            }
        }
    );


    const handleNavigation = () => {
        navigate(-1);
    };

    const onChangeFormItem = (item: CreateBlogFormSubmit) => {
        return {
            ...item,
            text: item.text,
            json_text: JSON.parse(item?.json_text ? item?.json_text : ""),
            category_id: item?.category?.id
        };
    };


    const getValue = (
        opts: { label: string; value: string | number }[],
        val: number | string | undefined
    ) => {
        if (val) {
            return opts.filter((o) => o.value == val);
        }
        return null;
    };

    const checkKeyDown = (e: any) => {
        if (e.key === "Enter") e.preventDefault();
    };

    const getStatus = (type: string) => {
        let status;

        if (type === "isModeration" || type === "isDraft") {
            status = 1;
        } else if (type === "isDraftUpdate") {
            status = 0;
        } else if (type === "isEdit") {
            status = 2;
        }
        else if (type === 'isCreate') {
            status = 0;
        }

        else if (type === 'isCreateToModeration') {
            status = 1;
        }

        return status;
    };

    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        if (isEdit && id) {
            withDebounce(() => {
                $getBlogsItem(+id, { newApi: true }).then(res => {
                    if (!res.data) return;

                    setEditData(onChangeFormItem(res.data));
                    dispatch(setRedactorNewsListData(res.text));
                });
            });
        }
    }, [categoryList]);


    useEffect(() => {
        $getBlogs({ newApi: true }).then(res => {
            if (!res.data) return;

            const getBlogItem = res?.data?.data?.find((item: any) => item.id == blogs_id);

            setBlogData(getBlogItem);
        });

        $getBlogsCategories({ formData: true, newApi: true }).then(res => {
            if (!res) return;

            const updatedList = res?.data?.map((item: any) => {
                return {
                    value: item.id,
                    label: item.title
                };
            });

            setCategoryList(updatedList);
        });
    }, []);

    useEffect(() => {
        if (blogData) {
        }
    }, [blogData]);

    function init() {
        getCategory();
    }

    function getCategory() {
        $getBlogsCategories({ newApi: true }).then(res => {
            if (!res.data) return;

            setCategoryList(res.data.map((i: any) => ({
                ...i,
                label: i.title,
                value: i.id
            })));
        });
    }

    function onSubmit(data: CreateBlogFormSubmit, type?: string) {
        const requestData = {
            ...data,
            text: htmlData || data.text,
            json_text: data?.json_text ? JSON.stringify(data?.json_text) : undefined,
            category_id: data?.category_id || editData.category.id,
            status: getStatus(type || ""),
            is_favourite: 0
        };

        if (type === "isDraft" || type === "isDraftUpdate") return handleEditData(requestData, type);

        if (type === "isModeration" || type === "isEdit") return handleEditModerationData(requestData, type);

        if (type === "isCreate" || type === 'isCreateToModeration') {
            if (data.banner && data.banner instanceof File) {
                $uploadImage({
                    file_content: data.banner,
                    target_type: "blogs"
                }).then(res => {
                    $createBlogs({
                        ...requestData,
                        banner: res?.file?.url
                    }, { newApi: true }).then(res => {
                        if (!res) return;

                        reset();

                        if (type === "isCreate" || !isAdmin) {
                            navigate("/blogs/drafts");
                        } else {
                            navigate("/blogs/moderation");
                        }
                    });
                });
            }
            // else {
            //     $createBlogs({
            //         ...requestData,
            //         banner: data.banner as string
            //     }, { newApi: true }).then(res => {
            //         if (!res) return;
            //
            //         reset();
            //         navigate("/blogs/drafts");
            //     });
            // }
        }
    }

    function handleEditData(requestData: any, type: string) {
        if (!id) return;

        if (requestData.banner !== editData?.banner) {
            $uploadImage({
                file_content: requestData.banner,
                target_type: "blogs"
            }).then(res => {
                $editBlogs(+id, {
                    ...requestData,
                    banner: res?.file?.url
                }, { newApi: true }).then(res => {
                    reset();

                    if (type === "isDraft" && isAdmin) {
                        navigate(`/blogs/moderation`);

                    } else {
                        navigate(`/blogs/drafts`);
                    }
                });
            });

            return;
        }

        $editBlogs(+id, requestData, { newApi: true }).then(res => {
            reset();

            if (type === "isDraft" && isAdmin) {
                navigate(`/blogs/moderation`);

            } else {
                navigate(`/blogs/drafts`);
            }
        });
    }

    function handleEditModerationData(requestData: any, type: string) {
        if (!id) return;
        if (requestData.banner !== editData?.banner) {
            $uploadImage({
                file_content: requestData.banner,
                target_type: "blogs"
            }).then(res => {
                $editDashboardBlogs(+id, {
                    ...requestData,
                    banner: res?.file?.url
                }, { newApi: true }).then(res => {
                    reset();

                    if (type === "isEdit") {
                        navigate(`/blogs`);
                    } else {
                        if (!isAdmin) {
                            navigate(`/blogs/moderation/show/${id}`);
                        }
                    }
                });
            });

            return;
        }

        $editDashboardBlogs(+id, requestData, { newApi: true }).then(res => {
            reset();

            if (type === "isEdit") {
                navigate(`/blogs`);
            } else {
                if (!isAdmin) {
                    navigate(`/blogs/moderation/show/${id}`);
                }
            }
        });
    }

    function handleDelete(select: any) {
        if (select.key == 1) {
            setDeleteModalOpen(0);
        } else {
            if (isDraft) {
                $deleteBlogs(id ? +id : 0, { newApi: true }).then((res) => {
                    if (!res) return;

                    navigate("/blogs/drafts");
                });
            } else {
                $deleteDashboardBlogs(id ? +id : 0, { newApi: true }).then(res => {
                    if (!res) return;

                    navigate("/blogs");
                });
            }
        }
    }

    const [upload, setUpload] = useState<any>();

    const handleImageUpload = (file: any): void => {
        if (file) {
            const reader = new FileReader();

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === "string") {
                    setUpload(reader.result);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    useEffect(() => {
        if (typeof watch().banner === "string") {
            setUpload(watch()?.banner);
        } else {
            handleImageUpload(watch().banner);
        }
    }, [watch().banner]);

    return (
        <div className="blogs-create">
            <CompleteModal
                icon={"🥲"}
                title={"Вы уверены, что хотите удалить публикацию?"}
                isOpen={deleteModalOpen !== 0}
                setIsOpen={() => setDeleteModalOpen(0)}
                btnList={btnListRemove}
                onClick={handleDelete}
            />

            <div className="container layout-inner__header profile-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1> {purpose === "edit" ? "Редактирование" : "Создать пост"}</h1>

                        {isDraft && (
                            <h5 className="layout-inner__header__draft">{watch().status == 1 ? 'На модерации' : 'Черновик'} </h5>
                        )}
                    </div>
                )}
            </div>

            <div className="blogs-create-content contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit((obj) => onSubmit(obj))}
                                className="contests__create__form"
                                onKeyDown={(e) => checkKeyDown(e)}
                            >
                                <Controller
                                    name="banner"
                                    disabled={isDraft && watch().status == 1}
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Баннер пустой"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileField
                                            disabled={isDraft && watch().status == 1}
                                            className={"form-image"}
                                            upload={value || ""}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            disabled={isDraft && watch().status == 1}
                                            value={value || ""}
                                            className=""
                                            title="Заголовок"
                                            name="title"
                                            onChange={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="json_text"
                                    control={control}
                                    disabled={isDraft && watch().status == 1}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <EditorField
                                            disabled={isDraft && watch().status == 1}
                                            value={value || ""}
                                            title="Описание"
                                            name="json_text"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="category_id"
                                    control={control}
                                    disabled={isDraft && watch().status == 1}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: ""
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <Dropdown
                                            disabled={isDraft && watch().status == 1}
                                            className={"form-select"}
                                            placeholder={"Категория"}
                                            label={"Категория"}
                                            options={[
                                                ...categoryList
                                            ]}
                                            value={getValue(categoryList, value)}
                                            onChange={(select) => onChange(select.value)}
                                        />
                                    )}
                                />

                                <div className={"d-flex gap-3 services-show-content__buttons"}>
                                    {isEdit && (
                                        <Button
                                            text={"Удалить"}
                                            disabled={isDraft && watch().status == 1}
                                            className={"btn btn-red w-100"}
                                            onClick={() => setDeleteModalOpen(1)}
                                        />
                                    )}

                                    {isDraft && (
                                        <>
                                            <Button
                                                text={"В черновик"}
                                                disabled={watch().status == 1}
                                                className={"btn btn-light w-100"}
                                                onClick={() => onSubmit(watch(), "isDraftUpdate")}
                                            />

                                            <Button
                                                type={"submit"}
                                                disabled={watch().status == 1}
                                                text={"Опубликовать"}
                                                className={"btn btn-primary w-100"}
                                                onClick={() => onSubmit(watch(),  "isDraft")}
                                            />
                                        </>
                                    )}


                                    {isModeration && (
                                        <Button
                                            type={"submit"}
                                            text={"Сохранить" }
                                            className={"btn btn-primary w-100"}
                                            onClick={() => onSubmit(watch(), "isModeration" )}
                                        />
                                    )}

                                    {isEdit && !isModeration && (
                                        <Button
                                            type={"submit"}
                                            text={"Сохранить"}
                                            className={"btn btn-primary w-100"}
                                            onClick={() => onSubmit(watch(), "isEdit")}
                                        />
                                    )}
                                    {/*{!isCreate && !isDraft && (*/}
                                    {/*    <>*/}
                                    {/*        {(isModeration ) ? (*/}
                                    {/*            <Button*/}
                                    {/*                type={"submit"}*/}
                                    {/*                text={!isDraft ? "Сохранить" : "Опубликовать"}*/}
                                    {/*                className={"btn btn-primary w-100"}*/}
                                    {/*                onClick={() => onSubmit(watch(), isModeration ? "isModeration" : "isDraft")}*/}
                                    {/*            />*/}
                                    {/*        ) : (*/}
                                    {/*            <Button*/}
                                    {/*                type={"submit"}*/}
                                    {/*                text={"Сохранить"}*/}
                                    {/*                className={"btn btn-primary w-100"}*/}
                                    {/*                onClick={() => onSubmit(watch(), "isEdit")}*/}
                                    {/*            />*/}
                                    {/*        )}*/}
                                    {/*    </>*/}
                                    {/*)}*/}

                                    {isCreate && (
                                        <>
                                            <Button
                                                text={"В черновик"}
                                                className={"btn btn-light w-100"}
                                                onClick={() => onSubmit(watch(), "isCreate")}
                                            />

                                            <Button
                                                type={"submit"}
                                                text={"Опубликовать"}
                                                className={"btn btn-primary w-100"}
                                                onClick={() => onSubmit(watch(), "isCreateToModeration")}
                                            />
                                        </>
                                    )}


                                </div>
                            </form>
                        </div>

                        <div className="col-5">
                            <div className={"blogs-create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="blogs-create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={() => setIsShow(!isShow)}
                                />

                                {isShow && (
                                    <>
                                        {blogData?.author && (
                                            <div className="blogs-card-user">
                                                <img src={blogData?.user?.image} alt=""
                                                     className="blogs-card-user__image" />

                                                <div className="blogs-card-user__content">
                                                    <h1 className="blogs-card-user__content__name">{blogData?.user?.name}</h1>
                                                    <p className="blogs-card-user__content__position">{blogData?.user?.position}</p>
                                                </div>
                                            </div>
                                        )}

                                        <div className="blogs-create__preview-items">
                                            <img src={upload || noImageBanner} alt=""
                                                 className="blogs-create__preview__image" />


                                            <div className="blogs-create__preview-item">
                                                <div className="blogs-create__preview-item__header">
                                                    <div className="blogs-card-block-content__top">
                                                        <h1 className="blogs-card-block-content__title">
                                                            {watch()?.title || "Описание"}
                                                        </h1>

                                                        <div
                                                            // onClick={ () => {
                                                            //     handleLike(item.id)
                                                            // } }
                                                            className={`blogs-card-block-content__likeIcon ${watch()?.is_favourite ? "active" : ""}`}
                                                        >
                                                            <StarIcon
                                                                opacity={watch()?.is_favourite ? 1 : .3} />
                                                        </div>
                                                    </div>

                                                    {/*<div className="blogs-create__preview__category">{watch()?.category?.label}</div>*/}
                                                </div>

                                                <div className="blogs-create__preview__text">
                                                    <EditorShow
                                                        htmlData={htmlData || watch()?.text}
                                                        className={"contests__create__preview-item__content__text"}
                                                    />
                                                </div>

                                                {isEdit && (
                                                    <div
                                                        className="blogs-card-block-content__bottom">
                                                        <div
                                                            className="blogs-card-block-content__bottom__block ">
                                                            <ClockIcon />

                                                            <p>{editData?.created_at && getYearMonthDay(editData?.created_at)}</p>
                                                        </div>

                                                        <div
                                                            className="blogs-card-block-content__bottom__right">
                                                            <div
                                                                className="blogs-card-block-content__bottom__block blogs-card-block-content__bottom__likeContent">
                                                                <LikeIcon color="#000" />

                                                                <p>{editData?.likes_count}</p>

                                                            </div>

                                                            <div
                                                                className="blogs-card-block-content__bottom__block ">
                                                                <CommentIcon color="#000" />

                                                                <p>{editData?.comments_count}</p>
                                                            </div>

                                                            <div
                                                                className="blogs-card-block-content__bottom__block ">
                                                                <EyeIcon color="#000" />

                                                                <p>{editData?.views_count}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
};