import {IApiHandlerParams} from "../../interface";
import api from "../../index";
import {RequestData, ChartRequestData, ChartResponseData, TopElementsResponseData} from './interface';

export const $getPartnersChartItems = (url: string, params?: ChartRequestData, handler?: IApiHandlerParams): Promise<ChartResponseData> => api
.get(url, {
    request: {params},
    handler
})

export const $getPartnersTopList = (url: string, params?: RequestData, handler?: IApiHandlerParams): Promise<TopElementsResponseData> => api
.get(url, {
    request: {params},
    handler
})


export const $getStatisticsChartItem = (url: string, params: ChartRequestData, handler?: IApiHandlerParams): Promise<any> => api
.get(url, {
    request: {params},
    handler
})

export const $getStatisticsPieChartItem = (url: string, params: ChartRequestData, handler?: IApiHandlerParams): Promise<any> => api
.get(url, {
    request: {params},
    handler
})

export const $getStatisticsListItem = (url: string, params?: ChartRequestData, handler?: IApiHandlerParams): Promise<TopElementsResponseData> => api
.get(url, {
    request: {params},
    handler
})

export const $getStatisticsExcel= (url: string, params?: ChartRequestData, handler?: IApiHandlerParams): Promise<TopElementsResponseData> => api
.get(url, {
    request: {params},
    handler
})