import React, { useEffect, useState } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import SportCreateEditPreview from "./SportCreateEditPreview";
import TextField from "../../../components/ui/form/Fields/TextField";
import ImageField from "../../../components/ui/form/ImageField/ImageField";
import DatePickerForm from "../../../components/ui/form/DatePickerForm/index";
import DatePickerTimeField from "../../../components/ui/form/DatePickerForm/DatePickerTimeField";
import ToggleBlock from "../../../components/ui/form/Toggle/ToggleBlock";
import Button from "../../../components/ui/form/Buttons/Button";

import { ArrowExitIcon, CloseIcon, PlusIcon2 } from "../../../img";
import { isMd } from "../../../utils";
import TextArea from "../../../components/ui/form/Fields/TextArea";
import UserSelectListModal, {
    BtnDataItem, UserData
} from "../../../components/ui/dialog/UserSelectListModal";
import DialogContainer from "../../../components/ui/dialog/DialogContainer";
import { LocationIcon } from "../../../img/icons/LocationIcon";
import {
    SportItemData,
    SportRequestFormData,
    SportTrainerData
} from "../../../api/requests/sport/interface";
import {
    $createSport, $deleteSport,
    $editSport,
    $getSportById,
    $getSportTrainerList
} from "../../../api/requests/sport";
import { $uploadImage } from "../../../api/requests/upload";
import moment from "moment";
import FileField from "../../../components/ui/form/FileField/FileField";
import { useSelector } from "react-redux";
import { getCurrentRole } from "../../../redux";
import { BlankImage } from "../../../components/ui/blank-image/BlankImage";


export interface CreateSportFormData {
    image: File | string | null;
    title: string;
    description: string;
    seats: string | number;
    venue: string;
    schedules: ScheduleData[];
    trainer: SportTrainerData;
}

export interface ScheduleData {
    date: Date | string;
    time_start: Date | string;
    time_end: Date | string;
    id?: number;
}

const SportCreateEditPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const pathname = location.pathname;
    const { id } = useParams();

    const isEdit: boolean = pathname.includes("edit");

    const [editData, setEditData] = useState<CreateSportFormData | null>(null);

    const [isShow, setIsShow] = useState(true);

    const [isOpenModal, setIsOpenModal] = useState(false);

    const [btnList, setBtnList] = useState([
        {
            key: 1,
            text: "Выбрать",
            className: "btn-primary w-100"
        }
    ]);

    const [departmentItems, setDepartmentItems] = useState<UserData[]>([]);

    const testBtnCreate = isEdit ? "Сохранить" : "Опубликовать";

    const {
        handleSubmit,
        control,
        watch,
        reset,
        formState: { errors, isValid },
        setValue
    } = useForm<CreateSportFormData>(
        (isEdit && editData) ? {
            values: {
                image: editData.image,
                title: editData.title,
                description: editData.description,
                seats: editData.seats,
                venue: editData.venue,
                schedules: editData.schedules,
                trainer: editData.trainer
            }
        } : {
            values: {
                image: "",
                title: "",
                description: "",
                seats: "",
                venue: "",
                schedules: [
                    {
                        date: "",
                        time_start: "",
                        time_end: ""
                    }
                ],
                trainer: {
                    photo: "",
                    name: "",
                    position: ""
                }
            }
        }
    );

    const handleNavigation = () => {
        navigate(-1);
    };

    const onChangeFormItem = (item: SportItemData): CreateSportFormData => {
        const schedules: ScheduleData[] = [...item.schedules].map((i: any) => ({
            date: i.date_from_at,
            time_start: i.date_from_at,
            time_end: i.date_to_at,
            id: i?.id || undefined,
        }));


        return {
            image: item.image,
            title: item.title,
            description: item.description || "",
            seats: item.seats || "",
            venue: item.venue || "",
            trainer: item.trainer,
            schedules
        };
    };

    const changeDepartmentData = (item: SportTrainerData): UserData => {
        return {
            ...item,
            image: item.photo,
            name: item.name,
            position: item?.staff?.title || "",
            icon: "",
            department: item?.structure?.title || ""
        };
    };

    useEffect(() => {
        init();
    }, []);

    function init() {
        if (isEdit && id) {
            $getSportById(isAdmin, id, null,{ newApi: true }).then(res => {
                if (!res.data) return;

                setEditData(onChangeFormItem(res.data));
            });
        }

        getTrainerList();
    }

    function getTrainerList() {
        $getSportTrainerList({ newApi: true }).then(res => {
            if (!res.data) return;

            setDepartmentItems([...res.data].map(item => changeDepartmentData(item)));
        });
    }

    function onSubmit(data: CreateSportFormData) {
        const requestData = {
            schedules: [...data.schedules].map(item => ({
                id: item?.id || undefined,
                date_from_at: `${moment(item.date).format("yyyy-MM-DD")}T${moment(item.time_start).format("HH:mm")}`,
                date_to_at: `${moment(item.date).format("yyyy-MM-DD")}T${moment(item.time_end).format("HH:mm")}`
            })),
            seats: data.seats as number,
            description: data.description,
            image: data.image,
            title: data.title,
            venue: data.venue,
            trainer_id: data.trainer.id as number,
            published: true
        };

        if (isEdit) return handleEditData(requestData);

        if (data.image && data.image instanceof File) {
            $uploadImage({
                file_content: data.image,
                target_type: "trainings"
            }).then(res => {
                $createSport({
                    ...requestData,
                    image: res?.file?.url
                }, { newApi: true }).then(res => {
                    if (!res.data) return;

                    reset();
                    navigate("/sport");
                });
            });
        } else {
            $createSport({
                ...requestData,
                image: data.image as string
            }, { newApi: true }).then(res => {
                if (!res.data) return;

                reset();
                navigate("/sport");
            });
        }
    }

    function handleEditData(requestData: any) {
        if (!id) return;

        if (requestData.image !== editData?.image) {
            $uploadImage({
                file_content: requestData.image,
                target_type: "trainings"
            }).then(res => {
                $editSport(id, {
                    ...requestData,
                    image: res?.file?.url
                }, { newApi: true }).then(response => {
                    if (!response.data) return;

                    reset();
                    navigate("/sport");
                });
            });

            return;
        }

        $editSport(id, requestData, { newApi: true }).then(res => {
            if (!res.data) return;

            reset();
            navigate("/sport");
        });
    }

    function handleUpdateSchedule() {
        setValue("schedules", [
            ...watch("schedules"),
            {
                date: "",
                time_start: "",
                time_end: ""
            }
        ]);
    }

    function handleRemoveSchedule(index: number) {
        const updateList = [...watch("schedules")].filter((_, idx) => idx !== index);

        setValue("schedules", updateList);
    }

    function handleDelete() {
        if (!id) return;

        $deleteSport(id, { newApi: true }).then(res => {
            if (!res.data) return;

            reset();
            navigate("/sport");
        });
    }

    function handleChangeList(btnItem: BtnDataItem | null, updateList: number[]) {
        const findTrainer = departmentItems.find((_, idx) => idx === updateList[0]);

        if (findTrainer) {
            setValue("trainer.photo", findTrainer.image);
            setValue("trainer.name", findTrainer.name);
            setValue("trainer.position", findTrainer.position);
            setValue("trainer.id", findTrainer.id);
        }

        setIsOpenModal(false);
    }

    return (
        <div className="contests__create">
            <div className="container layout-inner__header profile-header contests__create__header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>

                        <h1>{editData ? editData.title : "Создать тренировку"}</h1>
                    </div>
                )}
            </div>

            <div className="contests__create__block">
                <div className="container">
                    <div className="contests__create__row row">
                        <div className="contests__create__block-left">
                            <form
                                onSubmit={handleSubmit(onSubmit)}
                                className="sport__create__form"
                            >
                                <Controller
                                    name="image"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: false,
                                            message: "Прикрепите картинку"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <FileField
                                            className={"form-image"}
                                            upload={value}
                                            label="Изображение"
                                            setUpload={onChange}
                                        />
                                    )}
                                />

                                <Controller
                                    name="title"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Заполните заголовок"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            className=""
                                            title="Заголовок"
                                            placeholder={"Введите заголовок"}
                                            name="title"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="description"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Напишите описание"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextArea
                                            value={value}
                                            className=""
                                            title="Описание"
                                            placeholder={"Введите описание"}
                                            name="description"
                                            onChange={onChange}
                                            // errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="seats"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите места"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            type={"number"}
                                            value={value.toString()}
                                            className=""
                                            title="Кол-во мест"
                                            placeholder={"Кол-во мест"}
                                            name="seats"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <Controller
                                    name="venue"
                                    control={control}
                                    rules={{
                                        required: {
                                            value: true,
                                            message: "Введите локацию"
                                        }
                                    }}
                                    render={({ field: { onChange, value } }) => (
                                        <TextField
                                            value={value}
                                            prepend={<LocationIcon width={24} height={24} />}
                                            title="Место проведения"
                                            placeholder={"Введите адрес"}
                                            name="venue"
                                            onChange={onChange}
                                            errors={errors}
                                        />
                                    )}
                                />

                                <div className="sport__create__form-date">
                                    <h4>Расписание</h4>

                                    <div className={"sport__create__form-date__items"}>
                                        {watch().schedules && watch().schedules.map((item, idx) => (
                                            <div
                                                key={`sport-create-form-item-date-${idx}`}
                                                className="sport__create__form-date__item"
                                            >
                                                <Controller
                                                    name={`schedules.${idx}.date`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "Выберете дату"
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <DatePickerForm
                                                            startDate={value as Date}
                                                            className=""
                                                            label="Дата тренировки"
                                                            placeholder={"Выберите дату"}
                                                            onChange={onChange}
                                                            isIcon
                                                            isRightIcon
                                                            minDate={new Date()}
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name={`schedules.${idx}.time_start`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "Выберете время"
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <DatePickerTimeField
                                                            startDate={value as Date}
                                                            className=""
                                                            label="Начало"
                                                            placeholder={"Выберите время"}
                                                            onChange={onChange}
                                                            isRightIcon
                                                        />
                                                    )}
                                                />

                                                <Controller
                                                    name={`schedules.${idx}.time_end`}
                                                    control={control}
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "Выберете время"
                                                        }
                                                    }}
                                                    render={({ field: { onChange, value } }) => (
                                                        <DatePickerTimeField
                                                            startDate={value as Date}
                                                            className=""
                                                            label="Конец"
                                                            placeholder={"Выберите время"}
                                                            onChange={onChange}
                                                            isRightIcon
                                                        />
                                                    )}
                                                />

                                                <div
                                                    className={"sport__create__form-date__item__close"}>
                                                    <div onClick={() => handleRemoveSchedule(idx)}>
                                                        <CloseIcon color={"black"} />
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {(watch().schedules && watch().schedules.length < 6) && (
                                        <Button
                                            text={<PlusIcon2 size={32} color={"#00A7B5"} />}
                                            className={"btn btn-blue-secondary w-100"}
                                            onClick={handleUpdateSchedule}
                                        />
                                    )}
                                </div>

                                <div className="sport__create__form-trainer">
                                    <h5>Тренер</h5>

                                    {watch("trainer").name ? (
                                        <div
                                            className="sport__content-card-user cursor-pointer"
                                            onClick={() => setIsOpenModal(true)}
                                        >
                                            <BlankImage
                                                image={watch("trainer").photo}
                                                classname={"sport__content-card-user__image"}
                                            />

                                            <div className="sport__content-card-user__content">
                                                <h1 className="sport__content-card-user__content__name">{watch("trainer").name}</h1>
                                                <p className="sport__content-card-user__content__position">{watch("trainer").position}</p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div
                                            className={"sport__content-card-user-empty"}
                                            onClick={() => setIsOpenModal(true)}
                                        >
                                            <PlusIcon2 size={32} />
                                        </div>
                                    )}
                                </div>

                                <div className={"d-flex gap-3"}>
                                    {isEdit ? (
                                        <Button
                                            text={"Удалить"}
                                            className={"btn btn-red w-100"}
                                            onClick={handleDelete}
                                        />
                                    ) : (
                                        <Button
                                            text={"Отменить"}
                                            className={"btn btn-light w-100"}
                                            onClick={() => navigate(-1)}
                                        />
                                    )}

                                    <Button
                                        type={"submit"}
                                        text={testBtnCreate}
                                        className={"btn btn-primary w-100"}
                                    />
                                </div>
                            </form>
                        </div>

                        <div className="contests__create__block-right">
                            <div className={"contests__create__preview"}>
                                <ToggleBlock
                                    isChecked={isShow}
                                    className="contests__create__form-toggle"
                                    desc="Предпросмотр"
                                    handleChange={setIsShow}
                                />

                                {isShow && (
                                    <SportCreateEditPreview
                                        data={watch()}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DialogContainer
                isOpen={isOpenModal}
                setIsOpen={setIsOpenModal}
                isShowCloseBtn={false}
                classNameModal={"p-0"}
                widthProps={427}
            >
                <UserSelectListModal
                    list={departmentItems}
                    btnList={btnList}
                    title={"Выбор тренера"}
                    onClose={handleChangeList}
                    isClose
                    isSingleSelect
                />
            </DialogContainer>
        </div>
    );
};

export default SportCreateEditPage;