import { ArrowExitIcon } from "../../../img";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getYearMonthDay, isMd } from "../../../utils";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import {
    $getCitiesAvailable,
    $getWorkplaceBuildings,
} from "../../../api/requests/workplace";
import { useDispatch, useSelector } from "react-redux";
import {
    getSelected,
    setBuildings,
    setFloors,
    setSelected
} from "../../../redux/reducers/workplace/workplaceSlice";
import moment from "moment/moment";
import ServicesBookingContentLeft from "./ServicesBookingContentLeft";
import ServicesBookingContentCalendar from "./ServicesBookingContentCalendar";
import {
    getBookingActionIsOpenModal,
    getBookingActionModalData, getBookingFloorsIsActiveData, onClickChangeDropdownMeeting,
    onClickOpenBookingActionModal, setBookingFloorsIsActiveData, setCurrentDayCalendar,
    setInitialViewCalendar
} from "../../../redux/reducers/booking/bookingRoomsSlice";
import BookingMeetDialog from "../../../components/services/services-booking/BookingMeetDialog";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface FormData {
    city?: any;
    date?: string;
    building?: any;
    floor?: any;
    meet?: any;
    view: any;
}

const ServicesBookingPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idBooking = searchParams.get("id");

    const dispatch = useDispatch();
    const { city, building: buildingState, floor: floorState, isLoading } = useSelector(getSelected);
    const bookingFloorsList = useSelector(getBookingFloorsIsActiveData);

    const openPopup = useSelector(getBookingActionIsOpenModal);
    const popupData = useSelector(getBookingActionModalData);

    const [citiesDropdownData, setCitiesDropdownData] = useState<DropdownItem[]>([]);
    const [buildingsDropdownData, setBuildingsDropdownData] = useState<DropdownItem[]>([]);
    const [floorsDropdownData, setFloorsDropdownData] = useState<DropdownItem[]>([]);
    const [meetDropdownData, setMeetDropdownData] = useState<DropdownItem[]>([]);
    const [viewDropdownData, setViewDropdownData] = useState<DropdownItem[]>([
        {
            label: "день",
            value: "timelineDay",
        },
        {
            label: "неделя",
            value: "timeGridWeek",
        }
    ]);

    const [bookingList, setBookingList] = useState<any[]>([]);

    const colorList = [
        '#D7C3FF',
        '#D2F1B3',
        '#FDD3DA',
        '#96C0FE',
        '#FAE292',
        '#A0EEE9',
        '#C0C8D9',
        '#F6BCFF',
        '#E9C1A7',
        '#CDE4FF',
    ];

    const actionColorList = [
        '#AE90EB',
        '#ACE277',
        '#F1A3B0',
        '#679FF0',
        '#EDC94F',
        '#5DDDD5',
        '#93A1BD',
        '#D98FE5',
        '#DE9D73',
        '#95BBE8',
    ];

    const {
        control,
        watch,
        reset,
        setValue
    } = useForm<FormData>(
        {
            values: {
                date: moment().format("DD.MM.YYYY"),
                city,
                building: buildingState,
                floor: floorState,
                meet: "",
                view: viewDropdownData[0]
            }
        }
    );

    const getDropdownItem = (data: any, value: any) => {
        return data?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: value === "number" ? `Этаж ${item[value]}` : item[value]
            };
        });
    };

    useEffect(() => {
        $getCitiesAvailable({ newApi: true }).then((res) => {
            if (!res.data) return;

            setCitiesDropdownData(getDropdownItem(res?.data, "name"));
        });

        dispatch(setCurrentDayCalendar(moment().format("YYYY-MM-DD")));
    }, []);

    useEffect(() => {
        if (city) {
            $getWorkplaceBuildings(city?.value, { newApi: true }).then(res => {
                if (!res) return;

                setBuildingsDropdownData(getDropdownItem(res?.data, "name"));
                dispatch(setBuildings(res?.data));
            });
        }
    }, [city]);

    useEffect(() => {
        if (buildingState) {
            setFloorsDropdownData(getDropdownItem(buildingState.floors, "number"));
        }
    }, [buildingState]);

    useEffect(() => {
        if (bookingFloorsList && floorState) {
            setMeetDropdownData(getDropdownItem(floorState?.rooms, "name"));
        }
    }, [bookingFloorsList]);

    useEffect(() => {
        setBookingList([]);

        if (buildingState?.floors) {
            const booking: any[] = [];
            const rooms: any[] = [];
            const calendarList: any[] = [];

            bookingFloorsList.length ? rooms.push(...bookingFloorsList[0].rooms) : buildingState.floors.forEach((i: any) => i?.rooms?.forEach((_i: any) => rooms?.push(_i)));

            rooms?.forEach((i, idx) => {
                const bookingsList = i?.bookings.map((item: any) => {
                    return {
                        ...item,
                        idRoom: i?.id,
                        color: rooms?.length === 1 ? i?.color : colorList[idx],
                        actionColor: actionColorList[idx],
                    };
                });

                booking.push(bookingsList);
            });

            const bookingUpdateList = [...booking.flat()];

            bookingUpdateList.forEach((i) => {
                if (i?.items.length) {
                    const bookingsList = i?.items.map((item: any) => {
                        return {
                            ...item,
                            ...i,
                            color: i?.color,
                            actionColor: i?.actionColor,
                            date: i?.date_in_string,
                        };
                    });

                    calendarList.push(bookingsList);
                }
            });

            setBookingList([...calendarList.flat()]);
        }
    }, [buildingState, bookingFloorsList]);

    useEffect(() => {
        if (idBooking) {
            const findRoom = floorState?.rooms?.find((item: any) => item.id === +idBooking);

            if (findRoom) {
                dispatch(onClickChangeDropdownMeeting(true));
                dispatch(setBookingFloorsIsActiveData([floorState]));
                dispatch(onClickOpenBookingActionModal(findRoom));
            }
        }
    }, [idBooking]);

    const handleSelect = (type: string, select: any) => {
        if (type === "city") {
            setValue("building", "");
            setValue("floor", "");

            dispatch(setSelected({ city: select, building: null, floor: null }));
        }

        if (type === "building") {
            setValue("floor", "");

            dispatch(setSelected({ building: select, floor: null }));
            dispatch(setFloors(select?.floors));

            setFloorsDropdownData(getDropdownItem(select?.floors, "number"));
        }

        if (type === "floor") {
            dispatch(setSelected({ floor: select }));
            dispatch(onClickChangeDropdownMeeting(true));
            dispatch(setBookingFloorsIsActiveData([select]))
            setValue("meet", { value: 0, label: "Все переговорные" })
        }

        if (type === "meet") {
            const updateFloors = {
                ...floorState,
                rooms: !select.id ? [...floorState.rooms] : [...floorState.rooms].filter((item: any) => item.id === select.id)
            }

            dispatch(onClickChangeDropdownMeeting(true));
            dispatch(setBookingFloorsIsActiveData([updateFloors]))
        }
    };

    function setDate(value: Date) {
        dispatch(setCurrentDayCalendar(moment(value).format("YYYY-MM-DD")));
    }

    return (
        <div className="container services-workplace">
            <div className="services-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Бронирование переговорных</h1>
                    </div>
                )}
            </div>

            <div className="services-workplace-content mb-3">
                <div className="services-workplace-content__header">
                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Город"}
                                options={[
                                    ...citiesDropdownData
                                ]}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("city", e)
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="building"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Офис"}
                                options={buildingsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("building", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="floor"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Этаж"}
                                options={floorsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("floor", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="meet"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все переговорные"}
                                label={"Переговорная"}
                                options={[{ value: 0, label: "Все переговорные" }, ...meetDropdownData]}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("meet", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="view"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"День"}
                                label={"Вид календаря"}
                                options={viewDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    dispatch(setInitialViewCalendar(e.value))
                                }}
                            />
                        )}
                    />

                    <Controller
                        name="date"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете дату"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePickerForm
                                className=""
                                label="Дата"
                                placeholder={"Не выбрано"}
                                onChange={(e) => {
                                    onChange(e)
                                    setDate(e);
                                }}
                                isIcon
                                isRightIcon
                                minDate={new Date()}
                                value={value}
                            />
                        )}
                    />
                </div>

                <div className={"services__booking"}>
                    <ServicesBookingContentLeft
                        city={city?.name}
                        office={buildingState?.name}
                        floors={buildingState?.floors}
                    />

                    {!isLoading && (
                        <ServicesBookingContentCalendar
                            floors={buildingState?.floors}
                            bookingList={bookingList}
                        />
                    )}
                </div>
            </div>

            <BookingMeetDialog
                isOpen={openPopup}
                onClose={() => dispatch(onClickOpenBookingActionModal(null))}
                selectedProps={{
                    city,
                    buildingState,
                    floorState,
                    request: popupData,
                    selectedMember: {
                        photo: popupData?.photo,
                        name: popupData?.name
                    }
                }}
            />
        </div>
    );
};

export default ServicesBookingPage;