import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const UploadIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 44 45" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.8036 28.7862C7.87449 28.7862 5.5 26.9532 5.5 24.2656C5.5 21.578 7.87449 19.3992 10.8036 19.3992C11.0028 19.3992 11.1994 19.4093 11.3929 19.4289V19.3992H11.4658C11.4177 19.045 11.3929 18.684 11.3929 18.3178C11.3929 13.5398 15.6142 9.6665 20.8214 9.6665C24.3476 9.6665 27.4217 11.4427 29.039 14.0731C29.4343 14.0198 29.8386 13.9922 30.25 13.9922C34.8063 13.9922 38.5 17.3813 38.5 21.562C38.5 25.0036 35.997 27.6271 32.5703 28.4919M21.7396 35.3332V22.9138M21.7396 22.9138L16.3281 28.0357M21.7396 22.9138L27.1562 28.0357"
                stroke={color} stroke-width="3.66667" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    );
};
