import { ArrowExitIcon, HeartIcon, StarIcon } from "../../../img";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { $getEmployees } from "../../../api/requests/employees";
import { $getFavoritesNews } from "../../../api/requests/news";
import { isMd } from "../../../utils";
import {$getUserFavoritesAll, $toggleUserFavorites} from "../../../api/requests/favorites";
import {BlankImage} from "../../../components/ui/blank-image/BlankImage";

interface IEmployeesContent {
    id: number,
    name: string,
    text: string,
    position: string,
    image: string,
    isLiked: boolean,
    number: string,
    email: string,
    birth: string,
    skills: any,
    leader: any,
    replacer: any,
}

export const FavoritesEmployeesPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd()

    const [isLoading, setIsLoading] = useState(false)
    const [ employeesData, setEmployeesData ] = useState<any>([]);

    const handleNavigation = () => {
        navigate(-1);
    };

    const handleLike = (id: number, e: any) => {
        e.stopPropagation()
        setIsLoading(true)

        $toggleUserFavorites({type: 'staff', type_id: id, add_to_favorites: false}, {newApi: true}).then(res => {
            setIsLoading(false)
        })
    };


    useEffect(() => {
        $getUserFavoritesAll('staff', {newApi: true}).then(res => {
            setEmployeesData(res.data)
        })
    }, [isLoading])

    return (
        <div className='favorites-employees'>
            <div className='container layout-inner__header profile-header'>
                { !isMobile && (
                    <div className='layout-inner__header__title'>
                        <div className='header__exitIcon' onClick={ handleNavigation }>
                            <ArrowExitIcon />
                        </div>
                        <h1>Сотрудники</h1>
                    </div>
                ) }
            </div>

            <div className='favorites-employees-content'>
                { employeesData.map((
                    item: any,
                    idx: number
                ) => (
                    <div className='employees-block' key={ idx }>
                        <div className='employees-block__top'>
                            <BlankImage image={item.cover}  classname='employees-block__image'/>

                            <div
                                onClick={ (e) => {
                                    handleLike(item.id, e)
                                } }
                                className={ `employees-block__likeIcon active` }
                            >
                                <StarIcon opacity={1 }/>
                            </div>
                        </div>

                        <div className='employees-block__content' onClick={ () => {
                            // setDialog(true)
                            // setActivePerson({...item})
                        } }>
                            <h1 className='employees-block__title'>{ item?.title }</h1>

                            {/*<p className='employees-block__text'>{ item?.text }</p>*/}

                            {/*<p className='employees-block__position'>{ item?.position }</p>*/}
                        </div>
                    </div>
                )) }
            </div>
        </div>
    )
}