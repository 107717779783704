import { CloseIcon, DragAndDropIcon, PenIcon } from "../../img";
import { TrashIcon } from "../../img/icons/TrashIcon";
import DialogContainer from "../ui/dialog/DialogContainer";
import React, { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import TextField from "../ui/form/Fields/TextField";
import Button from "../ui/form/Buttons/Button";
import CheckIcon from "../../img/icons/vacation/CheckIcon";
import { $createCategories, $deleteCategory, $editCategories } from "../../api/requests/categories";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import DialogDragAndDropContainer from "../ui/dialog/DialogDragAndDropContainer";

interface ModalCatNewsProps {
    isOpen: boolean;
    setIsOpen: () => void;
    catList: any[];
    setCatList: (val: any) => void;
    route: string,
}

const ModalCatNews = (
    {
        isOpen,
        setIsOpen,
        catList,
        setCatList,
        route,
    }: ModalCatNewsProps
) => {
    const profileData = useSelector(getPersonData);

    const [editBlock, setEditBlock] = useState<string | null>(null);
    const [catEditValue, setCatEditValue] = useState<string>("");

    const onDragEnd = (result: any) => {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'group') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...catList];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleChangeCatList(targetIndex, deletedItem, workValue);
        }
    };

    function handleChangeCatList(targetIndex: number, deletedItem: any, workValue: any[]) {
        const requestObj = {
            ...deletedItem,
            sort: targetIndex
        }

        $editCategories(requestObj, route || '',{ formData: true }).then(res => {
            if (res.code) return;

            changeCatListByDropdown(workValue);
        })
    }

    function handleEdit(id: string) {
        setEditBlock(id);

        const findEdit = [...catList].find(item => item.id === id)

        findEdit && setCatEditValue(findEdit.title)
    }

    function handleDelete(id: string) {
        $deleteCategory({ id }, route || '', { formData: true }).then(res => {
            if (res.code) return;

            const updateList = [...catList].filter(item => item.id !== id)

            changeCatListByDropdown(updateList)
        })
    }

    function handleAddCat() {
        $createCategories({id: profileData.workspace_id, title: "Новая категория", name: 'Новая поле' },route || '', { formData: true }).then(res => {
            if (res.code) return;

            changeCatListByDropdown([res, ...catList])
        })
    }

    function handleSaveChange(id: string) {
        const requestObj = {
            id,
            title: catEditValue
        }

        $editCategories(requestObj, route || '', { formData: true }).then(res => {
            if (res.code) return;

            const updateList = [...catList].map(item => {
                if (item.id === res.id) return res

                return item
            })

            changeCatListByDropdown(updateList);
            handleCloseChange();
        })
    }

    function handleCloseChange() {
        setEditBlock(null);
        setCatEditValue("");
    }

    function changeCatListByDropdown(list: any[]) {
        const updateList = [...list].map((item) => ({
            ...item,
            label: item.title,
            value: item.id
        }))

        setCatList(updateList)
    }

    return (
        <DialogDragAndDropContainer
            closeModal={setIsOpen}
            isOpen={isOpen}
            label={"Управление категориями"}
            classNameModal={"modal__category"}
            widthProps={427}
        >
            <div className={"modal__category__list"}>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='droppabl' type="group">
                        {(provided) => (
                            <div
                                {...provided.droppableProps}
                                ref={provided.innerRef}
                                className={"modal__category__drag"}
                            >
                                {catList.map((item, idx) => (
                                    <Draggable
                                        draggableId={`items-${idx}-block-${item?.id}`}
                                        key={`items-${idx}-block-${item?.id}`}
                                        index={idx}
                                    >
                                        {(_provided) => (
                                            <div
                                                {..._provided.draggableProps}
                                                {..._provided.dragHandleProps}
                                                ref={_provided.innerRef}
                                                className="drag-and-drop__block"
                                            >
                                                {editBlock === item?.id ? (
                                                    <TextField
                                                        value={catEditValue}
                                                        className="w-100"
                                                        placeholder={"Напишите категорию"}
                                                        onChange={setCatEditValue}
                                                        append={(
                                                            <div>
                                                                    <span
                                                                        className={"btn-check"}
                                                                        onClick={() => handleSaveChange(item?.id)}
                                                                    >
                                                                        <CheckIcon
                                                                            color={"white"} />
                                                                    </span>

                                                                <span
                                                                    onClick={handleCloseChange}
                                                                >
                                                                        <CloseIcon
                                                                            color={"#FF0000"}
                                                                            width={14}
                                                                            height={14}
                                                                        />
                                                                    </span>
                                                            </div>
                                                        )}
                                                    />
                                                ) : (
                                                    <>
                                                        <div>
                                                            <DragAndDropIcon color={"black"} />

                                                            <p>{item?.label}</p>
                                                        </div>

                                                        <div>
                                                            <div
                                                                onClick={() => handleEdit(item?.id)}>
                                                                <PenIcon
                                                                    size={24}
                                                                    color={"#00A7B5"}
                                                                />
                                                            </div>

                                                            <div
                                                                onClick={() => handleDelete(item?.id)}>
                                                                <TrashIcon />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        )}
                                    </Draggable>
                                ))}

                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </div>

            <div className={"dropdown__list__setting"}>
                <Button
                    text={"Добавить"}
                    className={"btn btn-primary w-100"}
                    onClick={handleAddCat}
                />
            </div>
        </DialogDragAndDropContainer>
    )
}

export default ModalCatNews