import { DragDropContext, Draggable, Droppable } from "@hello-pangea/dnd";
import React, { ReactNode, useEffect, useState } from "react";
import {
    BlogIcon,
    ContestIcon, HamburgerIcon,
    InfoIcon,
    InterviewsIcon,
    MenuIcon,
    NewsIcon
} from "../../img";
import ToggleBlock from "../../components/ui/form/Toggle/ToggleBlock";
import { useSelector } from "react-redux";
import { $changeWorkspaceDisableItems } from "../../api/requests/workspace-settings";
import { getPersonData } from "../../redux";
import { $workSpaceEdit } from "../../api/requests/main";

interface StateData {
    key: string;
    name: string;
    url: string;
    value: number;
    icon: ReactNode;
}

const WorkspaceSettingModules = () => {
    const personalData = useSelector(getPersonData);

    const [state, setState] = useState<StateData[]>([
        {
            name: "Главная страница",
            url: "/main",
            icon: <InfoIcon />,
            key: "main",
            value: 0,
        },
        {
            name: "Моя страница",
            url: "/my",
            icon: <InfoIcon />,
            key: "my",
            value: 0,
        },
        {
            name: "Блог",
            url: "/posts",
            icon: <BlogIcon />,
            key: "posts",
            value: 0,
        },
        {
            name: "Календарь",
            url: "/calendar",
            icon: <BlogIcon />,
            key: "calendar",
            value: 0,
        },
        {
            name: "Спорт",
            url: "/sport",
            icon: <BlogIcon />,
            key: "sport",
            value: 0,
        },
        {
            name: "Новости",
            url: "/news",
            icon: <NewsIcon />,
            key: "news",
            value: 0,
        },
        {
            name: "Конкурсы",
            url: "/contests",
            icon: <ContestIcon />,
            key: "contests",
            value: 0,
        },
        {
            name: "Опросы",
            url: "/interviews",
            icon: <InterviewsIcon />,
            key: "interviews",
            value: 0,
        },
    ]);

    useEffect(() => {
        if (personalData) {
            const updateList = [...state].map(item => {
                if (personalData.disabled_items?.length) return {
                    ...item,
                    value: personalData.disabled_items.includes(item.key) ? 1 : 0
                }

                return item
            });

            setState(updateList);
        }
    }, [personalData]);

    function changeCheckboxes(select: any) {
        const updateList = [...state].map(item => {
            if (item.key === select.key) return {
                ...item,
                value: select.value ? 0 : 1
            }

            return item
        });

        const requestData: any = {
            id: personalData.workspace_id,
            [`${select.key}`]: select.value ? 0 : 1
        };

        $changeWorkspaceDisableItems(requestData, { formData: true, disableLoader: true }).then(res => {
            setState(updateList);
        });
    }

    function onDragEnd(result: any) {
        const { destination, source, type } = result;

        if (!destination) {
            return;
        }

        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        if (type === 'modules') {
            const sourceIndex = source.index;
            const targetIndex = destination.index;

            const workValue = [...state];
            const [deletedItem] = workValue.splice(sourceIndex, 1);
            workValue.splice(targetIndex, 0, deletedItem);

            handleEdit(targetIndex, deletedItem, workValue);
        }
    }

    function handleEdit(targetIndex: number, deletedItem: any, workValue: any[]) {
        const requestData: any = {
            id: personalData.workspace_id,
            menu_sort: [...workValue].map(i => i.key),
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            setState(workValue);
        })
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='list' type="modules">
                {(provided) => (
                    <div ref={provided.innerRef} {...provided.droppableProps} className={"workspace-settings-group__modules"}>
                        {state.map((item, index) => (
                            <div key={`${item.key}-item-${index}`} className={"menuBlock"}>
                                <Draggable draggableId={`${item.key}-item-${index}`} index={index}>
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className={"drag-and-drop__block workspace-settings-group__modules-block"}
                                        >
                                            <div className={"w-100 justify-content-between"}>
                                                <div className={""}>
                                                    <HamburgerIcon color={"#00000080"} />

                                                    {item.icon}

                                                    <p>{item.name}</p>
                                                </div>

                                                <ToggleBlock
                                                    desc={""}
                                                    isChecked={!!item.value}
                                                    handleChange={() => changeCheckboxes(item)}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </Draggable>
                            </div>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default WorkspaceSettingModules;