import DialogContainer from "../../ui/dialog/DialogContainer";
import { FC, useEffect } from "react";
import { CloseIcon } from "../../../img";
import { IBadges } from "../../../utils";
import { useNavigate } from "react-router-dom";

interface IBadgesModalCard {
    isOpen: boolean;
    onClose: () => void;
    data: IBadges[];
}

export const BadgesModalCard: FC<IBadgesModalCard> = (
    {
        isOpen,
        onClose,
        data
    }
) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.body.style.overflow = "";
        };
    }, [isOpen]);

    return (
        <DialogContainer
            classNameModal="dialog"
            isOpen={isOpen}
            isShowCloseBtn={false}
            closeModal={onClose}
        >
            <div className="dialog-mobileLine"></div>

            <div className="dialog-header">
                <h5 className="dialog-header__title">Благодарности</h5>

                <div onClick={onClose} className="dialog-header__closeIcon">
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="dialog-content">
                {data.map((item, idx) => (
                    <div
                        className="dialog-block cursor-pointer"
                        key={`badge-dialog-item-${idx}`}
                        onClick={() => {
                            navigate(`/user/${item.sender_id}`)
                            onClose();
                        }}
                    >
                        <div className="dialog-block-left">
                            <img src={item?.sender?.photo} />

                            <div className="dialog-block-left__user">
                                <h5>{item?.sender?.name || "От организации"}</h5>

                                {/*<p>Руководитель отдела</p>*/}
                            </div>
                        </div>

                        <div className="dialog-block-right__image">
                            <img src={item?.workspace_badge?.image} alt="" />
                        </div>
                    </div>
                ))}
            </div>
        </DialogContainer>
    );
};