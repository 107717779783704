import React, { FC } from "react";
import { ArrowRightIcon, SettingIcon, CoinIcon, StarIcon } from "../../img";
import Button from "../../components/ui/form/Buttons/Button";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
    changeProfileVarsData,
    changeToken, getCurrentRole,
    getPersonData,
    resetProfile,
    store
} from "../../redux";
import { BlankAva } from "../ui/avatars/BlankAva";
import { getCurrentGroupData } from "../../redux/reducers/workplace/workplaceSlice";

interface AsideHomeProps {
    isActiveOpen: boolean;
    isActiveLocal: string;
    openMenu: () => void;
    menu: any[];
}

const AsideHome: FC<AsideHomeProps> = (
    {
        isActiveOpen,
        isActiveLocal,
        openMenu,
        menu
    }
) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const currentGroup = useSelector(getCurrentGroupData);
    const personalData = useSelector(getPersonData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const user = {
        workspaces: []
    };

    const workspace_id = 0;
    const workspace_title = "0";
    const workspace_logo = null;

    const isCurrentWorkspace = +workspace_id !== 0;

    const isWorkspace = user.workspaces ? user.workspaces?.length >= 2 : true;

    const avatar = personalData?.photo;

    function handleButtonClick(): void {
        // get 'groups/view'
    }

    function openModalSlider(id: string): void {
        console.log(id);
    }

    return (
        <aside className={`nav-drawer ${isActiveOpen ? "active" : ""}`}>
            <div className="nav-drawer__content">
                <div className="nav-drawer__block">
                    <button
                        type="button"
                        className="nav-drawer__close"
                        onClick={openMenu}
                    >
                        <img src="/images/icons/close-1.svg" alt="close" />
                    </button>

                    <div className="nav-drawer__profile">
                        <Link to={"/profile"} className="nav-drawer__profile-img">
                            {avatar ? (
                                    <img src={avatar} />
                                )
                                : (
                                    <BlankAva
                                        colorBg={"#00A7B5"}
                                        colorText={"#00A7B5"}
                                        text={personalData?.name}
                                    />
                                )
                            }
                        </Link>

                        <div className="nav-drawer__profile-name">
                            <Link to={"/profile"} className="title">{personalData?.name}</Link>

                            <div className="nav-drawer__profile-icons">
                                {/*<div className="header__profile-star">*/}
                                {/*    <div className="header__profile-star-icon">*/}
                                {/*        <StarIcon />*/}
                                {/*    </div>*/}

                                {/*    <p className="text">10</p>*/}
                                {/*</div>*/}

                                <div className="header__profile-star">
                                    <div className="header__profile-star-icon">
                                        <CoinIcon color={"#FF7E00"} />
                                    </div>

                                    <p className="text">{personalData?.points}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"header__spaces"}>
                        <div className={"header__spaces__name"}>
                            <img
                                src={
                                    workspace_logo ||
                                    "/images/organization/temp-logo.png"
                                }
                                alt={"logo"}
                            />

                            <p>
                                {isCurrentWorkspace
                                    ? workspace_title
                                    : "ИТЭЛМА"}
                            </p>
                        </div>

                        <div
                            className={"header__spaces__groups"}
                            onClick={() => navigate("/groups")}
                        >
                            {currentGroup?.title || "Общая группа"}
                            <ArrowRightIcon color={"rgb(0, 122, 255)"} />
                        </div>

                        {isWorkspace && (
                            <Button
                                className={`btn btn-primary`}
                                onClick={handleButtonClick}
                                text={"Сменить пространство"}
                            />
                        )}
                    </div>

                    <ul className="nav-drawer__list">
                        {menu.map((item, idx) => {
                            if (item.isAdmin && !isAdmin) return null;

                            return (
                                <li
                                    key={`list-item-menu-${idx}`}
                                    className={`nav-drawer__item ${item.link === isActiveLocal ? "active" : ""}`}
                                >
                                    <Link className="btn" to={item.link}>
                                        {(item.img && typeof item.img === "string")
                                            ? <img src={item.img} alt={item.text} />
                                            : item.img(item.link === isActiveLocal)
                                        }

                                        <span>{item.text}</span>

                                        {!!item.count && (
                                            <span
                                                className="count"
                                                style={{
                                                    backgroundColor: "#007BFF",
                                                    color: "white"
                                                }}
                                            >
                                            {item.count}
                                        </span>
                                        )}
                                    </Link>
                                </li>
                            )
                        })}
                    </ul>
                </div>

                <div className={"nav-drawer__footer"}>
                    {/*<Button*/}
                    {/*    // color={colorScheme.primaryColor}*/}
                    {/*    className={"btn btn-link buttonErrorBlock"}*/}
                    {/*    onClick={() => {*/}
                    {/*        // if (workspace_id === 644)*/}
                    {/*        //     return window.open(*/}
                    {/*        //         'https://app.svoi.club/application-new?page=add&id=186&workspace_id=644',*/}
                    {/*        //         '_self'*/}
                    {/*        //     )*/}

                    {/*        openModalSlider("FEEDBACK_ID");*/}
                    {/*    }}*/}
                    {/*    text={"Сообщить об ошибке"}*/}
                    {/*/>*/}

                    <Button
                        className="btn btn-red mb-0"
                        type={"submit"}
                        text={"Выйти"}
                        onClick={() => {
                            Cookies.remove("token");
                            localStorage.removeItem("token");
                            dispatch(changeToken(""));
                            dispatch(resetProfile());
                            setTimeout(() => navigate("/auth"), 300);

                            // signOut().then()
                        }}
                    />
                </div>
            </div>
        </aside>
    );
};

export default AsideHome;
