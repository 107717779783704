import WorkplaceAvailablePlacesModal from "./WorkplaceAvailablePlacesModal";
import { useState } from "react";
import ListIcon from "../../../../img/icons/ListIcon";

const WorkplaceAvailablePlaces = () => {
    const [ isOpenModal, setIsOpenModal ] = useState(false);

    return (
        <>
            <div className={"mt-auto"}>
                <div
                    className={"workplace__avail__places__btn"}
                    onClick={() => setIsOpenModal(true)}
                >
                    <ListIcon/>
                </div>

                <WorkplaceAvailablePlacesModal
                    isOpen={isOpenModal}
                    onClose={() => setIsOpenModal(false)}
                />
            </div>
        </>
    );
};

export default WorkplaceAvailablePlaces;