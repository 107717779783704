import { ArrowExitIcon } from "../../../img";
import { SearchItem } from "../../../components/ui/form/SearchItem";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getYearMonthDay, isMd } from "../../../utils";
import { Controller, useForm } from "react-hook-form";
import Dropdown from "../../../components/ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../../components/ui/form/DatePickerForm";
import {
    $getCitiesAvailable,
    $getWorkplaceBuildings,
    $searchWorkplaceBooking
} from "../../../api/requests/workplace";
import { useDispatch, useSelector } from "react-redux";
import {
    getSelected,
    setBuildings,
    setCurrentDate,
    setFloors,
    setPlan,
    setSelected,
    setSelectedUser
} from "../../../redux/reducers/workplace/workplaceSlice";
import { ServicesWorkplaceMap } from "../../../components/services";
import ComputerIcon from "../../../img/icons/form/ComputerIcon";
import moment from "moment/moment";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface FormData {
    city?: any;
    date?: string;
    building?: any;
    floor?: any;
}

const ServicesWorkplacePage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();

    const dispatch = useDispatch();
    const { city, building: buildingState, floor: floorState } = useSelector(getSelected);

    const [citiesDropdownData, setCitiesDropdownData] = useState<DropdownItem[]>([]);
    const [buildingsDropdownData, setBuildingsDropdownData] = useState<DropdownItem[]>([]);
    const [floorsDropdownData, setFloorsDropdownData] = useState<DropdownItem[]>([]);

    const {
        control,
        watch,
        reset,
        setValue
    } = useForm<FormData>(
        {
            values: {
                date: moment().format("DD.MM.YYYY"),
                city,
                building: buildingState,
                floor: floorState,
            }
        }
    );

    const getDropdownItem = (data: any, value: any) => {
        return data?.map((item: any) => {
            return {
                ...item,
                value: item.id,
                label: value === "number" ? `Этаж ${item[value]}` : item[value]
            };
        });
    };

    useEffect(() => {
        $getCitiesAvailable({ newApi: true }).then((res) => {
            if (!res.data) return;

            setCitiesDropdownData(getDropdownItem(res?.data, "name"));
        });

        dispatch(setCurrentDate(moment().format("YYYY-MM-DD")));
    }, []);

    useEffect(() => {
        if (city) {
            $getWorkplaceBuildings(city?.value, { newApi: true }).then(res => {
                if (!res) return;

                setBuildingsDropdownData(getDropdownItem(res?.data, "name"));
                dispatch(setBuildings(res?.data));
            });
        }
    }, [city]);

    useEffect(() => {
        if (buildingState) {
            setFloorsDropdownData(getDropdownItem(buildingState.floors, "number"));
        }
    }, [buildingState]);

    const handleSelect = (type: string, select: any) => {
        if (type === "city") {
            setValue("building", "");
            setValue("floor", "");

            dispatch(setSelected({ city: select, building: null, floor: null }));
        }

        if (type === "building") {
            setValue("floor", "");

            dispatch(setSelected({ building: select, floor: null }));
            dispatch(setFloors(select?.floors));

            setFloorsDropdownData(getDropdownItem(select?.floors, "number"));
        }

        if (type === "floor") {
            dispatch(setSelected({ floor: select }));

            if (select?.map) {
                dispatch(setPlan(select?.map?.filename_url));
            }
        }
    };

    function setDate(value: Date) {
        dispatch(setCurrentDate(moment(value).format("YYYY-MM-DD")));
    }

    return (
        <div className="container services-workplace">
            <div className="services-header">
                {!isMobile && (
                    <div className="services-education-header__left">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Карта офиса</h1>
                    </div>
                )}

                <div className="services-education-header__right">
                    <SearchItem
                        onChange={(value) => dispatch(setSelectedUser(value))}
                        request={(value) => $searchWorkplaceBooking(floorState?.id || 0, {
                            name: value || undefined,
                            page: 1,
                        },{ newApi: true })}
                        disabled={!floorState}
                        updateDateList={(list: any) => {
                            return list.map((item: any) => ({
                                ...item,
                                name: item?.booked_user?.name || item?.number_name || "",
                                image: item?.booked_user?.photo,
                                svg: item?.booked_user ? undefined : <ComputerIcon/>
                            }))
                        }}
                        isShowBtnDelete
                    />
                </div>
            </div>

            <div className="services-workplace-content mb-3">
                <div className="services-workplace-content__header">
                    <Controller
                        name="city"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Город"}
                                options={[
                                    ...citiesDropdownData
                                ]}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("city", e)
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="building"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Офис"}
                                options={buildingsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("building", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="floor"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: ""
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <Dropdown
                                className={"form-select"}
                                placeholder={"Все"}
                                label={"Этаж"}
                                options={floorsDropdownData}
                                value={value}
                                onChange={(e) => {
                                    onChange(e)
                                    handleSelect("floor", e);
                                }}
                                isSearchable={true}
                            />
                        )}
                    />

                    <Controller
                        name="date"
                        control={control}
                        rules={{
                            required: {
                                value: true,
                                message: "Выберете дату"
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <DatePickerForm
                                className=""
                                label="Дата"
                                placeholder={"Не выбрано"}
                                onChange={(e) => {
                                    onChange(e)
                                    setDate(e);
                                }}
                                isIcon
                                isRightIcon
                                minDate={new Date()}
                                value={value}
                            />
                        )}
                    />
                </div>

                <ServicesWorkplaceMap />
            </div>
        </div>
    );
};

export default ServicesWorkplacePage;