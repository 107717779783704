import { FC, useEffect, useState } from "react";
import dayjs from "dayjs";
import pad from "../../../utils/pad/pad";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import { CloseIcon } from "../../../img";
import { $getStatisticsExcel } from "../../../api/requests/analytics";
import downloadExel from "../../../utils/file/download-exel";
import { $uploadFiles } from "../../../api/requests/workspace-settings/users-upload";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { getRequestUrl } from "../../../api/handler";
import { API_URL_NEW } from "../../../api/config";
import axios from "axios";

interface IStatisticsExcelModal {
    isOpened: boolean
    onClose: () => void
    data: any,
    start_date: number,
    end_date: number,
    filter_type: string,
}

export const calcDate = (date?: number) => {
    const dateJs = date ? new Date(date * 1000) : new Date();
    const isFuture = new Date(date || '').getTime() > new Date().getTime() / 1000;

    const curDay = new Date().getDate();
    const curMonth = new Date().getMonth() + 1;
    const curYear = new Date().getFullYear();

    const targetDay = new Date(dateJs).getDate();
    const targetMonth = new Date(dateJs).getMonth() + 1;
    const targetYear = new Date(dateJs).getFullYear();

    const isSameMonth = targetMonth === curMonth && curYear === targetYear;
    const checkIsToday = isSameMonth && curDay === targetDay;
    const checkIsYesterday = isSameMonth && curDay - 1 === targetDay;

    return {
        month: `${dateJs.getMonth() + 1}`.padStart(2, '0'),
        day: `${dateJs.getDate()}`.padStart(2, '0'),
        hour: `${dateJs.getHours()}`.padStart(2, '0'),
        minute: `${dateJs.getMinutes()}`.padStart(2, '0'),
        year: `${dateJs.getFullYear()}`.padStart(2, '0'),
        isToday: checkIsToday,
        isYesterday: checkIsYesterday,
        isFuture,
    };
};


export const useDownlodFile = ({ isData, isLoaded, fileName = 'статистика', fileExt = 'xls', type = '' }: any = {}) => {
    const { year, month, day } = calcDate();

    const handleDwonload = ({ data, name, ext }: any = {}) => {
        console.log({ data, name, ext });
        const tempLink = document.createElement('a');
        const csvURL = window.URL.createObjectURL(data || isData);
        tempLink.href = csvURL;
        tempLink.setAttribute('download', type === 'statistics' ? `${name || fileName}.${ext || fileExt}` : `${name || fileName}_${day}_${month}_${year}.${ext || fileExt}`);
        tempLink.click();
    };

    if (isLoaded) {
        handleDwonload();
    }

    return { handleDwonload };
};


export const AnalyticsExcelModal: FC<IStatisticsExcelModal> = (
    {
        isOpened,
        onClose,
        data,
        start_date,
        end_date,
        filter_type
    }) => {
    const [selectedValue, setSelectedValue] = useState(data[0].value);

    const filterData = data && data?.find((item: any, idx: number) => item.value == selectedValue.value)

    const downloadExcel = async () => {
        try {
            const url = `${API_URL_NEW}api/v1/statistics${filterData?.route}?
            start_date=${start_date}&end_date=${end_date}&filter_type=${filter_type}`;

            const response = await axios.get(url, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = downloadUrl;
            a.download = `${filterData?.label || 'report'}_${getDate()}.xls`;
            document.body.appendChild(a);
            a.click();
            a.remove();

            window.URL.revokeObjectURL(downloadUrl);
        } catch (error) {
            console.error('Error downloading the file:', error);
        }
    };

    useEffect(() => {
        setSelectedValue(data[0])
    }, [])

    function gettingDayBefore (number: number)  {
        const day: any = dayjs().subtract((number), 'day')
        const dayBefore: any = new Date(day)

        return dayBefore
    }

    function getDate () {
        const startDate = new Date(start_date * 1000);
        const endDate = new Date(end_date * 1000);

        if (filter_type === 'today') {
            return `с ${pad(gettingDayBefore(1).getDate())}.${pad(gettingDayBefore(1).getMonth() + 1)}.${gettingDayBefore(1).getFullYear()} до ${pad(endDate.getUTCDate())}.${pad(endDate.getUTCMonth() + 1)}.${endDate.getFullYear()}`
        }
        else if (filter_type === 'yesterday') {
            return `с ${pad(gettingDayBefore(2).getDate())}.${pad(gettingDayBefore(2).getMonth() + 1)}.${gettingDayBefore(2).getFullYear()} до ${pad(endDate.getUTCDate())}.${pad(endDate.getUTCMonth() + 1)}.${endDate.getFullYear()}`
        }
        else {
            return `с ${pad(startDate.getDate())}.${pad(startDate.getMonth() + 1)}.${startDate.getFullYear()} до ${pad(endDate.getUTCDate())}.${pad(endDate.getUTCMonth() + 1)}.${endDate.getFullYear()}`
        }
    }
    return (
        <DialogContainer
            isOpen={ isOpened }
            closeModal={ onClose }
            isShowCloseBtn={false}
            classNameModal={'analytics-modal__overflow'}
        >
            <div className='analytics-modal'>
                <div className='analytics-modal-header'>
                    <h1 className='analytics-modal-header__title'>Выберите категорию</h1>

                    <div className='analytics-model-header__close' onClick={onClose}>
                        <CloseIcon color='#000'/>
                    </div>
                </div>

                <div className='analytics-modal__category'>
                    <p className='analytics-modal__category__label' >Категорию</p>

                    <Dropdown
                        onChange={(e: any, ) => setSelectedValue(e)}
                        value={selectedValue}
                        defaultValue={data[0]}
                        options={data}
                    />
                </div>

                <div className='analytics-modal__button' onClick={downloadExcel} style={{background: '#00A7B5'}}>
                    Скачать отдельно
                </div>
            </div>
        </DialogContainer>
    )
}