import React, { ReactNode } from "react";
import {
    DraftIcon, ExcelDownloadIcon,
    NotificationIcon,
    PenIcon,
    PlusIcon2,
    SearchIcon
} from "../img";
import { store } from "../redux";
import { ProfileBlockAddIcon } from "../img/icons/ProfileBlockAddIcon";
import { FileEditIcon } from "../img/icons/FileEditIcon";
import { ModerationIcon } from "../img/icons/header/ModerationIcon";
import {
    setIsOpenCreateBanner,
    setToAddFile,
    setToAddFolder
} from "../redux/reducers/workplace/workplaceSlice";
import { AddFolderIcon } from "../img/icons/files/AddFolderIcon";
import { DownloadIcon } from "../img/icons/DownloadIcon";

interface IRouteLinks {
    route: string,
    title: string
}
export const routeLinks: Record<string, IRouteLinks> = {
    main: {
        route: '/',
        title: 'Главная',
    },

    user: {
        route: '/user',
        title: 'Профиль',
    },

    workspace_settings: {
        route: '/workspace-settings',
        title: 'Настройки организации'
    },

    blogs: {
       route: '/blogs',
       title: 'Блог',
    },
    blogs_drafts: {
        route: '/blogs/drafts',
        title: 'Черновик',
    },
    blogs_moderation: {
        route: '/blogs/moderation',
        title: 'Модерация блога',
    },
    blogs_show: {
        route: '/blogs/show',
        title: ''
    },
    blogs_create: {
        route: '/blogs/create',
        title: 'Создание'
    },
    blogs_edit: {
        route: '/blogs/edit',
        title: 'Редактирование'
    },

    ideas: {
        route: '/ideas',
        title: 'Идеи'
    },
    ideas_show: {
        route: `/ideas/show`,
        title: ''
    },
    ideas_create: {
        route: '/ideas/show',
        title: 'Создание'
    },


    services: {
        route: '/services',
        title: 'Сервисы',
    },
    services_view: {
        route: '/services/view',
        title: '',
    },
    services_show: {
        route: '/services/show',
        title: '',
    },
    services_create: {
        route: '/services/show',
        title: 'Создание'
    },
    services_education: {
        route: '/services/education',
        title: 'Заявка на обучение'
    },
    services_workplace: {
        route: '/services/workplace',
        title: 'Карта офиса',
    },
    services_medical: {
        route: '/services/medical',
        title: 'Больничный',
    },
    services_booking: {
        route: '/services/booking',
        title: 'Бронирование переговорных',
    },
    services_trip_create: {
        route: '/services/trip/create',
        title: 'Командировка',
    },
    services_education_create: {
        route: '/services/trip/create',
        title: 'Командировка',
    },

    profile: {
        route: '/profile',
        title: 'Моя страница'
    },
    profile_colleagues: {
        route: '/profile/colleagues',
        title: 'Мои сотрудники'
    },
    profile_edit: {
        route: '/profile/edit',
        title: 'Настройки профиля'
    },

    favorites: {
        route: '/favorites',
        title: 'Избранное'
    },
    favorites_employees: {
        route: '/favorites/employees',
        title: 'Сотрудники'
    },
    favorites_news: {
        route: '/favorites/news',
        title: 'Новости'
    },
    favorites_polls: {
        route: '/favorites/polls',
        title: 'Опросы'
    },

    favorites_competitions: {
        route: '/favorites/competitions',
        title: 'Конкурсы'
    },

    competitions: {
        route: '/competitions',
        title: 'Конкурсы'
    },

    competitionsView: {
        route: '/competitions/view',
        title: 'Просмотр'
    },

    competitionsCreate: {
        route: '/competitions/create',
        title: 'Создание'
    },

    competitionsEdit: {
        route: '/competitions/Edit',
        title: 'Редактирование'
    },

    news: {
        route: '/news',
        title: 'Новости'
    },

    news_show: {
        route: `/news/show`,
        title: ''
    },

    polls: {
        route: '/polls',
        title: 'Опросы'
    },
    polls_create: {
        route: '/polls/show',
        title: 'Создание'
    },
    polls_edit: {
        route: '/polls/edit',
        title: ''
    },

    birthdays: {
        route: '/birthdays',
        title: 'Дни рождения'
    },

    employees: {
        route: '/employees',
        title: 'Новые сотрудники',
    },

    structure: {
        route: '/employees/structure',
        title: 'Организационно-штатная структура',
    },

    calendar: {
        route: '/calendar',
        title: 'Календарь',
    },

    vacation: {
        route: '/calendar/vacation',
        title: 'Отпуска'
    },

    medical: {
        route: '/calendar/medical',
        title: 'Больничный'
    },

    business: {
        route: '/calendar/business',
        title: 'Командировки'
    },

    calendarEvents: {
        route: '/calendar/events',
        title: 'Мероприятия'
    },

    groups: {
        route: '/groups',
        title: 'Группы'
    },

    groupsSetting: {
        route: '/groups/setting',
        title: 'Настройка'
    },

    sport: {
        route: '/sport',
        title: 'Спорт'
    },

    sportSetting: {
        route: '/sport/view',
        title: ''
    },

    notifications: {
        route: '/notifications',
        title: 'Уведомления'
    },

    mediaFiles: {
        route: '/media-files',
        title: 'Медиафайлы'
    },
    mediaFiles_drafts: {
        route: '/media-files/drafts',
        title: 'Черновик'
    },
    mediaFiles_show: {
        route: `/media-files/show`,
        title: ''
    },

    files: {
        route: `/files`,
        title: 'Файлы'
    },

    files_show: {
        route: `/files/show`,
        title: ''
    },

    events: {
        route: `/events`,
        title: 'Мероприятия'
    },

    events_show: {
        route: `/events/show`,
        title: ''
    },

    events_drafts: {
        route: `/events/drafts`,
        title: 'Черновик'
    },

    events_create: {
        route: '/events/create',
        title: 'Создание'
    },

    events_edit: {
        route: '/events/edit',
        title: 'Редактирование'
    }
}



export const updateRouteTitle = (newTitle: any) => {
    return routeLinks.news_edit.title = newTitle
};

// const blogsModerationLength = useSelector((state: any) => state.routeTitle);

export enum UserRole {
    ADMIN = 1, // 1 админ
    USER = 4,  // 4 пользователь
    TRAINER = 3,  // 3 тренер
    ZAYAVKA = 5,  // 5 заявка на вступление
    REJECTED = 6,  // 6 отклонен
}

type IconConfigType = (navigate: any, params?: any, id?: string, listLength?: number | string) => {
    [key: string]: {
        leftIcons: { checkIsRole?: boolean, icon: ReactNode; onClick: () => void }[];
        rightIcons: { checkIsRole?: boolean, icon: ReactNode; onClick: () => void }[];
        roles: UserRole[];
    };
};
export const iconConfig: IconConfigType = (navigate, params, id, listLength) => ({
    "/": {
        leftIcons: [],
        rightIcons: [
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon:  <NotificationIcon color={"#000"} />,
                onClick: () => {},
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/analytics/activity": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <ExcelDownloadIcon/>,
                onClick: () => navigate("/analytics/activity?excelOpen=true"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/analytics/badges": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <ExcelDownloadIcon  />,
                onClick: () => navigate("/analytics/badges?excelOpen=true"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/analytics/discounts": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <ExcelDownloadIcon />,
                onClick: () => navigate("/analytics/discounts?excelOpen=true"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/analytics/sport": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <ExcelDownloadIcon />,
                onClick: () => navigate("/analytics/sport?excelOpen=true"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/competitions": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/competitions/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/ideas": {
        leftIcons: [],
        rightIcons: [
            // {
            //     checkIsRole: true,
            //     icon: <PlusIcon2 color="#000" />,
            //     onClick: () => navigate("/ideas/show"),
            // },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/ideas/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/ideas/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/blogs": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <div className='header__listLength'>
                    <ModerationIcon color="#000" />

                    <div className='header__listLength__notification'>
                        {listLength}
                    </div>
                </div>,
                onClick: () => navigate("/blogs/moderation"),
            },
            {
                // checkIsRole: true,
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/blogs/drafts"),
            },
            {
                // checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/blogs/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/blogs/create": {
        leftIcons: [],
        rightIcons: [
            {
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/blogs/drafts"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/blogs/drafts": {
        leftIcons: [],
        rightIcons: [
            {
                // checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/blogs/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
    "/blogs/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/blogs/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/blogs/moderation/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/blogs/moderation/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/blogs/draft/show": {
        leftIcons: [],
        rightIcons: [
            {
                // checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/blogs/draft/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/polls": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/polls/drafts"),
            },
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/polls/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/sport": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <ProfileBlockAddIcon />,
                onClick: () => navigate("/sport/block-users"),
            },
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/sport/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilterEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/sport/view": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/sport/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilterEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },


    "/news": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <FileEditIcon />,
                onClick: () => navigate("/news/drafts"),
            },
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/news/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/news/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/news/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/media-files": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/media-files/drafts"),
            },
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/media-files/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/media-files/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/media-files/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },



    "/events": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <DraftIcon color="#000" />,
                onClick: () => navigate("/events/drafts"),
            },
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => navigate("/events/create"),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/events/show": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PenIcon color="#000" />,
                onClick: () => {
                    navigate(`/events/edit/${id}`)
                },
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/files": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => store.dispatch(setToAddFile(true)),
            },
            {
                checkIsRole: true,
                icon: <AddFolderIcon color="#000" />,
                onClick: () => store.dispatch(setToAddFolder(true)),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },

    "/groups/setting/banners": {
        leftIcons: [],
        rightIcons: [
            {
                checkIsRole: true,
                icon: <PlusIcon2 color="#000" />,
                onClick: () => store.dispatch(setIsOpenCreateBanner(true)),
            },
            {
                type: 'search',
                icon: <SearchIcon color="#000" />,
                onClick: () => {}//store.dispatch(toggleSearchModal()),
            },
            {
                type: 'notification',
                icon: <NotificationIcon color={"#000"}/>,
                onClick: () => console.log("FilteEyeIcon clicked"),
            },
        ],
        roles: [ UserRole.ADMIN ]
    },
});