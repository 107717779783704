import { IApiHandlerParams } from "../../interface";
import api from "../../index";
import { GetNewsItemResponseData, NewsCreateRequestData } from "../news/interface";
import { SportItemResponseData, SportRequestFormData } from "../sport/interface";
import { IRequestServiceApplications, IServiceCategory } from "./interface";

export const $createService = (
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/services", data, {
    handler
});

export const $deleteService = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`api/v1/services/${id}`, {
    handler
});
export const $getServices = (
    id: number | string,
    city_id?: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services?category_id=${id}${city_id ? `&city_id=${city_id}` : ''}`, {
    handler
});

export const $getServiceItem = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/${id}`, {
    handler
});

export const $getServiceApplicationsItem = (
    id: number | string,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/service-applications/${id}`, {
    handler
});

export const $editServiceApplicationsItem = (
    data: any,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.put(`api/v1/service-applications/${data.sample_id}`, data, {
    // mock: require("./mocks/sport.item.json"),
    handler
});

export const $editServiceItem = (
    id: string | number,
    data: any,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.put(`api/v1/services/${id}`, data, {
    // mock: require("./mocks/sport.item.json"),
    handler
});


export const $uploadServiceImage = (
    id: number | string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/services/${id}/upload-image`, data,{
    handler
});

export const $getServiceCategories = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/service-categories`, {
    handler
});

export const $getServiceTypes = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/services/types`, {
    handler
});

export const $getCities = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get("api/v1/partners/cities",{
    handler
});

export const $getServiceApplications = (
    offset: number,
    self: boolean,
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/service-applications?${self ? 'self=true&' : ''}offset=${offset}`, {
    handler
});

export const $getServicesGroups = (
    handler?: IApiHandlerParams
): Promise<any> => api
.get(`api/v1/service-categories`, {
    handler
});

export const $createServiceCategory = (
    data: IServiceCategory,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/service-categories`, data,{
    handler
});

export const $editServiceCategory = (
    id: number | string,
    data: IServiceCategory,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/service-categories/${id}?_method=PUT`, data,{
    handler
});

export const $deleteServiceCategory = (
    id: string | number,
    handler?: IApiHandlerParams
): Promise<SportItemResponseData> => api
.delete(`api/v1/service-categories/${id}`, {
    handler
});

export const $createServiceApplications = (
    data: IRequestServiceApplications,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/service-applications", data, {
    handler
});

export const $uploadServiceApplicationFile = (
    file: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post("api/v1/service-applications/upload-file", file, {
    handler
});


export const $conciliateServiceApplicationUser = (
    id: number | string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/service-applications/${id}/conciliate`, data, {
    handler
})

export const $engageServiceApplicationUser = (
    id: number | string,
    data: any,
    handler?: IApiHandlerParams
): Promise<any> => api
.post(`api/v1/service-applications/${id}/engage`, data, {
    handler
})