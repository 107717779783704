import TextField from "../../components/ui/form/Fields/TextField";
import {
    PenIcon,
    PlusIcon2,
} from "../../img";
import { Controller, useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import Button from "../../components/ui/form/Buttons/Button";
import WorkspaceSettingsUserUpload
    from "../../components/workspace-users/general/WorkspaceSettingsUserUpload";
import { useDispatch, useSelector } from "react-redux";
import {
    changeMainPage,
    getMainPageData,
    getPersonData,
    getProfileVarsData,
} from "../../redux";
import { $mainPageNewData, $workSpaceEdit } from "../../api/requests/main";
import { successNotify } from "../../utils";
import TextArea from "../../components/ui/form/Fields/TextArea";
import { TrashIcon } from "../../img/icons/TrashIcon";
import WorkplaceCreateBadgesDialog
    from "../../components/workspace/settings/WorkplaceCreateBadgesDialog";
import { $deleteBadge, $getBadges } from "../../api/requests/workspace-settings/badges";

interface FormData {
    email_badge_user: string;
    email_badge_admin: string;
    email_badge_hire: string;
    push_badge_user: string;
    push_badge_admin: string;
    push_badge_hire: string;
    status: number;
    main_group_title: string;
    adaptation?: string;
    count?: number;
    no_system_news: number;
    groups_first_then_news: number;
    qr_code_enabled: number;
}

const WorkspaceSettingBadges = () => {
    const dispatch = useDispatch();

    const vars = useSelector(getProfileVarsData);
    const personalData = useSelector(getPersonData);
    const mainData = useSelector(getMainPageData);

    const [editData, setEditData] = useState<any>(null);

    const [isUploadingFile, setIsUploadingFile] = useState(false);
    const [isOpenCreateEditBadges, setIsOpenCreateEditBadges] = useState(false);
    const [settingSelectBadges, setSettingSelectBadges] = useState(null);

    const [badgeList, setBadgeList] = useState<any[]>([]);

    const {
        handleSubmit,
        control
    } = useForm<FormData>(
        {
            values: {
                email_badge_user: editData?.notification_texts?.email_badge_user || "",
                email_badge_admin: editData?.notification_texts?.email_badge_admin || "",
                email_badge_hire: editData?.notification_texts?.email_badge_hire || "",
                push_badge_user: editData?.notification_texts?.push_badge_user || "",
                push_badge_admin: editData?.notification_texts?.push_badge_admin || "",
                push_badge_hire: editData?.notification_texts?.push_badge_hire || "",
                adaptation: editData?.adaptation || undefined,
                status: +editData?.workspace_status,
                main_group_title: editData?.main_group_title,
                count: editData?.count,
                no_system_news: +editData?.no_system_news,
                groups_first_then_news: +editData?.groups_first_then_news,
                qr_code_enabled: editData?.qr_code_enabled
            }
        }
    );

    const removeUndefinedKeys = (obj: any) => {
        return Object.fromEntries(
            Object.entries(obj).filter(([_, value]) => value !== undefined)
        );
    };

    useEffect(() => {
        if (personalData && mainData) init();
    }, [personalData, mainData]);

    function init() {
        setEditData({ ...personalData, ...mainData });

        getListBadges();
    }

    function getListBadges() {
        $getBadges({ id: personalData.workspace_id }).then(res => {
            setBadgeList(res?.badges || [])
        })
    }

    function onSubmit(data: FormData) {
        const requestData: any = {
            ...removeUndefinedKeys(data),
            id: editData.workspace_id
        };

        $workSpaceEdit(
            requestData,
            { formData: true }
        ).then((res) => {
            if (!res || res.error) return;

            $mainPageNewData().then((res) => {
                if (!res || res.error) return;

                dispatch(changeMainPage(res));
            });

            successNotify("Успешно сохранена").then();
        });
    }

    function handleDelete(select: any) {
        $deleteBadge({ id: select.id }).then(res => {
            successNotify("Успешно удалено").then();
            getListBadges();
        })
    }

    return (
        <div className={"workspace-settings-users__field"}>
            <div className={"workspace-settings-users__field-block"}>
                <form
                    className={"workspace-settings-users__accrual w-100"}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <div
                        className={"workspace-settings-users__accrual-block workspace-settings__badges"}>
                        <h3>Пуши и письма</h3>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="push_badge_user"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className="mb-3"
                                        title="Push - Бейдж от пользователя"
                                        placeholder={"Введите текст"}
                                        name="push_badge_user"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="email_badge_user"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бейдж от пользователя"
                                        placeholder={"Введите текст"}
                                        name="email_badge_user"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="push_badge_admin"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className="mb-3"
                                        title="Push - Бейдж от админа"
                                        placeholder={"Введите текст"}
                                        name="push_badge_admin"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="email_badge_admin"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бейдж от админа"
                                        placeholder={"Введите текст"}
                                        name="email_badge_admin"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>

                        <div className={"workspace-settings__badges-block"}>
                            <Controller
                                name="push_badge_hire"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextField
                                        value={value}
                                        className="mb-3"
                                        title="Push - Бейдж за стаж"
                                        placeholder={"Введите текст"}
                                        name="push_badge_hire"
                                        onChange={onChange}
                                    />
                                )}
                            />

                            <Controller
                                name="email_badge_hire"
                                control={control}
                                rules={{
                                    required: {
                                        value: false,
                                        message: ""
                                    }
                                }}
                                render={({ field: { onChange, value } }) => (
                                    <TextArea
                                        value={value}
                                        className="mb-3"
                                        title="Письмо - Бейдж за стаж"
                                        placeholder={"Введите текст"}
                                        name="email_badge_hire"
                                        onChange={onChange}
                                        rows={3}
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3>Загрузка бэйджей</h3>

                        <WorkspaceSettingsUserUpload
                            colorScheme={vars}
                            uploadFileUrl={'backend/v1/workspaces/users/import-badges'}
                            downloadFileUrl={'api/workspaces/download_ws_badges'}
                            typeDoc={"badges"}
                            setIsUploadingFile={setIsUploadingFile}
                            isUploadingFile={isUploadingFile}
                        />
                    </div>

                    <hr />

                    <div className={"workspace-settings-users__accrual-block"}>
                        <h3 className={"mb-1"}>Бейджи</h3>

                        <div className={"workspace-settings__meeting__blocks"}>
                            <div className={"row"}>
                                <div
                                    className={"col-md-4 col-6 workspace-settings__meeting-meet-col mt-3 mb-1"}>
                                    <Button
                                        text={<PlusIcon2 size={32} color={"#00A7B5"} />}
                                        className={"btn btn-primary-revert w-100 h-100"}
                                        onClick={() => setIsOpenCreateEditBadges(true)}
                                    />
                                </div>

                                {badgeList.map((item: any, idx: number) => (
                                    <div
                                        key={`badge-item-${idx}`}
                                        className={"col-md-4 col-6 workspace-settings__meeting-meet-col mt-3 mb-1"}
                                    >
                                        <div
                                            className={"workspace-settings__meeting-meet-block workspace-settings__badges-item"}>
                                            <div
                                                className={"d-flex align-items-start justify-content-between gap-3"}>
                                                <div className={"image"}>
                                                    <img src={item.image} />
                                                </div>

                                                <div className={"d-flex gap-4"}>
                                                    <Button
                                                        className="btn-icon"
                                                        text={<TrashIcon color={"rgba(0,0,0,0.5)"}/>}
                                                        onClick={() => handleDelete(item)}
                                                    />
                                                    <Button
                                                        className="btn-icon"
                                                        text={<PenIcon color={"rgba(0,0,0,0.5)"}/>}
                                                        onClick={() => {
                                                            setSettingSelectBadges(item);
                                                            setIsOpenCreateEditBadges(true)
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className={"workspace-settings__meeting-meet-bottom workspace-settings__badges-bottom"}
                                            >
                                                <h4>{item.title}</h4>

                                                <p>{item.description}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className={"workspace-settings-users__accrual-toggle d-flex gap-3 mt-4"}>
                        <Button
                            type={"submit"}
                            text={"Сохранить"}
                            className={"btn btn-primary w-100"}
                        />
                    </div>
                </form>
            </div>

            <WorkplaceCreateBadgesDialog
                isOpen={isOpenCreateEditBadges}
                onClose={() => {
                    setSettingSelectBadges(null)
                    setIsOpenCreateEditBadges(false);
                    getListBadges();
                }}
                setIsOpen={() => {
                    setSettingSelectBadges(null)
                    setIsOpenCreateEditBadges(false);
                }}
                selectedProps={settingSelectBadges}
            />
        </div>
    );
};

export default WorkspaceSettingBadges;