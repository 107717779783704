import React, { FC } from 'react'
import FileField from "../FileField/FileField";

interface ImageFieldProps {
    upload: File | string | null
    setUpload: (file: any) => void
    label?: any,
    errors?: any,
    name?: any,
    id?: any,
    isFile?: boolean,
}

const ImageField: FC<ImageFieldProps> = ({ upload, setUpload, label, errors, name = "", isFile = false, id = `file-${Math.random()}`}) => {
    const handleImageUpload = (file: File): void => {
        if (file) {
            const reader = new FileReader()

            reader.onloadend = () => {
                if (reader.result && typeof reader.result === 'string') {
                    setUpload(isFile ? file : reader.result)
                }
            }

            reader.readAsDataURL(file)
        }
    }

    return (<>
        <FileField
            className={"form-image"}
            label={label}
            upload={upload}
            id={id}
            setUpload={handleImageUpload}
        />
        {errors?.[name] && (
            <div className="d-flex flex-column">
                <p className="field__error">
                    {errors?.[name]?.message as any}
                </p>
            </div>
        )}
    </>

    )
}

export default ImageField;
