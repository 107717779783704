import { Member, MembersAdd } from "./MembersAction";

interface MembersBlockData {
    booking: any;
    setBooking: (prev: any) => void;
}

export const MembersBlock = (
    {
        booking,
        setBooking
    }: MembersBlockData
) => {
    const { photo, name, mail, phone } = booking?.member || {};

    const handleAdd = (member: any) => {
        if (member?.isSelected) {
            handleRemove();

            return;
        }

        setBooking((prev: any) => {
            return {
                ...prev,
                request: {
                    ...prev?.request,
                    for_user_id: member?.id
                },
                member
            };
        });
    };

    const handleRemove = () => {
        setBooking((prev: any) => {
            return {
                ...prev,
                request: {
                    ...prev?.request,
                    for_user_id: null
                },
                member: null
            };
        });
    };

    return (
        <div className={"calendar__members__block__wrapper"}>
            <div className={"calendar__members__block__head"}>
                <div className={"calendar__members__block__title"}>Замещающий</div>
            </div>

            <div className={"calendar__members__block__body"}>
                {/*{!booking?.member && <MembersAdd booking={booking} handleAdd={handleAdd} />}*/}

                {booking?.member && (
                    <Member
                        photo={photo}
                        name={name}
                        mail={mail}
                        phone={phone ? phone.toString() : ""}
                        // type="edit"
                        // onClick={handleRemove}
                    />
                )}
            </div>
        </div>
    );
};
