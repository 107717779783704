import { useEffect, useState } from "react";
import {
    AnalyticsChartBlock,
    AnalyticsDates, AnalyticsExcelModal,
    AnalyticsHorizonNavigation, AnalyticsListBlock,
    getStatisticsChartItems, getStatisticsListItems
} from "../../components/analytics";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
import {
    AnalyticsContestsIcon,
    AnalyticsNewsIcon,
    AnalyticsPollIcon, AnalyticsPollIdeasIcon,
    IconCalendar,
    LikeIcon,
    ThreePeopleIcon
} from "../../img";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import { ShareIcon } from "../../img/icons/ShareIcon";
import { EyeIcon2 } from "../../img/icons/news/EyeIcon2";
import { MessengerIcon } from "../../img/icons/structure/MessengerIcon";
import { ThreePeopleBorderIcon } from "../../img/icons/employees/ThreePeopleBorderIcon";
import { DownloadIcon } from "../../img/icons/DownloadIcon";
import { useLocation, useNavigate } from "react-router-dom";
import { ex } from "@fullcalendar/core/internal-common";

export const AnalyticsActivityPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search);
    const excelOpen = searchParams.get("excelOpen")

    const currentDate = dayjs().utcOffset(3).hour(3).minute(0).second(0);
    const date = currentDate.startOf('isoWeek').toDate()
    const end_date = currentDate.endOf('isoWeek').toDate()

    const [ startTime, setStartTime ] = useState<any>(parseInt((date.getTime() / 1000).toString()))
    const [ endTime, setEndTime ] = useState<any>(parseInt((end_date.getTime() / 1000).toString()))
    const [ filterType, setFilterType ] = useState('week')

    const [isOpenModal, setIsOpenModal] = useState(false)

    const [viewsList, setViewsList] = useState([])
    const [likesList, setLikesList] = useState([])
    const [commentsList, setCommentsList] = useState([])
    const [sharesList, setSharesList] = useState([])
    const [activeUsersList, setActiveUsersList ] = useState([])
    const [registeredList, setRegisteredList] = useState([])

    const [topNewsList, setTopNewsList] = useState(null)
    const [topIdeasList, setTopIdeasList] = useState([])
    const [topInterviewsList, setTopInterviewsList] = useState([])
    const [topContestsList, setTopContestsList] = useState([])

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const [excelData, setExcelData] = useState([
        {
            value: 1,
            label: 'Активные пользователи',
            route: '/activity/export/active-users'
        },
        {
            value: 2,
            label: 'Зарегистрированные пользователи',
            route: '/activity/export/registered-users'
        },
        {
            value: 3,
            label: 'ТОП  Новости',
            route: '/activity/export/pop-news'
        },
        {
            value: 4,
            label: 'ТОП Опросы',
            route: '/activity/export/pop-polls'
        },
        {
            value: 5,
            label: 'ТОП Конкурсы',
            route: '/activity/export/pop-contests'
        },
        {
            value: 6,
            label: 'ТОП Идей',
            route: '/activity/export/pop-ideas'
        },
    ])

    const handleDeleteLoadingData = (list: any, setList: any) => {
        const updatedList = {
            data: [],
            total_count: '...',
            text: list?.text,
            title: list?.title,
        }

        setList(updatedList)
    }

    useEffect(() => {
        setIsLoading(true)
        handleDeleteLoadingData(viewsList, setViewsList)
        handleDeleteLoadingData(likesList, setLikesList)
        handleDeleteLoadingData(commentsList, setCommentsList)
        handleDeleteLoadingData(sharesList, setSharesList)
        handleDeleteLoadingData(activeUsersList, setActiveUsersList)
        handleDeleteLoadingData(registeredList, setRegisteredList)

        getStatisticsChartItems(
            '/activity/news/views',
            'Просмотры',
            '#00A7B5',
            'Просмотры',
            setViewsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <EyeIcon2 color='#00A7B5'/>
        )

        getStatisticsChartItems(
            '/activity/news/likes',
            'Лайки',
            '#00A7B5',
            'Лайки',
            setLikesList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <LikeIcon color={'#00A7B5'}/>
        )

        getStatisticsChartItems(
            '/activity/news/comments',
            'Комментарии',
            '#00A7B5',
            'Комментарии',
            setCommentsList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <MessengerIcon color='#00A7B5' />
        )

        getStatisticsChartItems(
            '/activity/news/shares',
            'Поделились',
            '#00A7B5',
            'Поделились',
            setActiveUsersList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ShareIcon/>
        )

        getStatisticsChartItems(
            '/activity/active-users',
            'Активные пользователи',
            '#00A7B5',
            'Активные пользователи',
            setRegisteredList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ThreePeopleBorderIcon/>
        )

        getStatisticsChartItems(
            '/activity/registered-users',
            'Зарегистрированные пользователи',
            '#00A7B5',
            'Зарегистрированные пользователи',
            setSharesList,
            startTime,
            endTime,
            filterType,
            setIsLoading,
            <ThreePeopleBorderIcon/>
        )

        getStatisticsListItems(
            '/activity/top_news',
            'ТОП 5 Новости',
            <AnalyticsNewsIcon />,
            setTopNewsList,
            startTime,
            endTime,
            filterType,
        )

        getStatisticsListItems(
            '/activity/top_ideas',
            'ТОП 5 Идей',
            <AnalyticsPollIdeasIcon />,
            setTopIdeasList,
            startTime,
            endTime,
            filterType,
        )

        getStatisticsListItems(
            '/activity/top_interviews',
            'ТОП 5 Опросы',
            <AnalyticsPollIcon />,
            setTopInterviewsList,
            startTime,
            endTime,
            filterType,
        )

        getStatisticsListItems(
            '/activity/top_contests',
            'ТОП 5 Конкурсы',
            <AnalyticsContestsIcon />,
            setTopContestsList,
            startTime,
            endTime,
            filterType,
        )

    }, [startTime, endTime ])

    return (
        <>
            <AnalyticsHorizonNavigation/>

            <div className='container analytics'>
                <div className='analytics-header'>
                    <div className='analytics-header__left'>
                        <h1 className='analytics-header__left__title'>Активность</h1>
                        <p className='analytics-header__left__desc'> Статистика популярности контента на платформе</p>
                    </div>

                    {/*<div className='analytics-header__right' onClick={() => setIsOpenModal(true)}>*/}
                    {/*    <DownloadIcon color={'#00A7B5'}/>*/}
                    {/*    <p style={{color: '#00A7B5'}}>Скачать .xls</p>*/}
                    {/*</div>*/}
                </div>

                <AnalyticsDates
                    setStartTime={ setStartTime }
                    setEndTime={ setEndTime }
                    filterType={ filterType }
                    setFilterType={ setFilterType }
                />

                <div className='analytics-content'>
                    {viewsList && likesList && commentsList && sharesList && activeUsersList && registeredList && (
                        <>
                            {Object.values([viewsList, likesList, sharesList, commentsList, activeUsersList, registeredList])?.map((item: any, idx) => (
                                <AnalyticsChartBlock
                                    {...item}
                                    isLoading={isLoading}
                                    key={idx}
                                    filter_type={filterType}
                                    page='partners'
                                />
                            ))}
                        </>
                    )}

                    {topNewsList && topInterviewsList && topIdeasList && topContestsList && (
                        <>
                            {Object.values([topNewsList, topIdeasList, topContestsList, topInterviewsList]).map((item: any, idx) => (
                                <AnalyticsListBlock
                                    key={idx}
                                    data={item}
                                    filter_type={filterType}
                                />
                            ))}
                        </>
                    )}
                </div>
            </div>

            <AnalyticsExcelModal
                isOpened={excelOpen === 'true'}
                onClose={() => navigate('/analytics/activity')}
                data={excelData}
                start_date={startTime}
                end_date={endTime}
                filter_type={filterType}
            />
        </>
    )
}