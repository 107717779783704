
interface EditorShowProps {
    htmlData: string;
    className?: string;
}

export const EditorShow = (
    {
        htmlData,
        className = ""
    }: EditorShowProps
) => {
    const contentText = (text: string): string => {
        let textUpdate = text;

        textUpdate = changeHashtagsText(textUpdate);

        const tooltipRegex = /data-tooltip/g;
        const tooltipMatch = text.match(tooltipRegex);

        if (tooltipMatch) {
            return changeTooltipText(text, textUpdate);
        }

        const spacesRegex = /&amp;nbsp;/g;
        const spacesMatch = textUpdate.match(spacesRegex);

        if (spacesMatch) {
            textUpdate = textUpdate.replaceAll(spacesRegex, ' ');
        }

        return textUpdate;
    };

    const changeHashtagsText = (text: string) => {
        return text.replace(/(^.|>)([^<]*)(<|.$)/g, function (match, start, capture, end) {
            let hashtagsReplaced: any = (start + capture + end).replace(
                /\#([a-z-A-Z-а-я-А-Я-0-9]+)/g,
                '<span class="hashtag">#$1</span>',
            );

            hashtagsReplaced = hashtagsReplaced.replace(
                /\@([a-z-A-Z-а-я-А-Я-0-9]+)/g,
                '<span class="hashtag">@$1</span>',
            );

            return hashtagsReplaced;
        });
    }

    const changeTooltipText = (defaultText: string, text: string) => {
        const regex = /<span class="cdx-tooltip" data-tooltip="([^"]+)">([^<]+)<\/span>/g;

        return defaultText.replace(regex, (match, tooltipText, spanText) => {
            return `<Tooltip title="${tooltipText}">${spanText}</Tooltip>`;
        });
    }

    return (
        <p className={className}
           dangerouslySetInnerHTML={{ __html: contentText(htmlData || "Введите описание") }}
        />
    )
}