import React, {FC, useState} from "react";
import {IServicesApplications} from "../../../api/requests/services/interface";
import {Controller, useForm} from "react-hook-form";
import Dropdown from "../../ui/form/Dropdown/Dropdown";
import DatePickerForm from "../../ui/form/DatePickerForm";
import {ServicesFormingApplicationModal, ServicesImprovementModal} from "./modals";
import {ServicesAddingAgreerModal} from "./modals/ServicesAddingAgreerModal";
import {useNavigate} from "react-router-dom";

interface DropdownItem {
    label: string;
    value: number | string;
}

interface IServicesApplicationsList {
    data?: IServicesApplications[],
    headerTable?: any,
    columns?: any,
    list?: any,
    isAdmin?: boolean,
    isHr?: boolean,
}

export const ServicesApplicationsList: FC<IServicesApplicationsList> = (
    {
        data,
        headerTable,
        isAdmin,
        columns,
        list,
        isHr
    }) => {
    const navigate = useNavigate();

    const [isOpenDialog, setIsOpenDialog] = useState(0);

    const {
        handleSubmit,
        control,
        watch,
        register,
        reset,
        formState: {isValid},
        setValue,
    } = useForm<any>();


    return (
        <>
            <div className="container services-acceptance">
                <div className="services-acceptance__header">
                    {columns?.map((item: any, idx: number) => (
                        <div key={idx} className={`column `} style={{width: `${item.width}%`}}>{item.title}</div>
                    ))}

                </div>
            </div>

            <div className="container">
                <div className=" services-acceptance__content">
                    {list?.map((row: any, index: number) => (
                        <div  key={index} className="services-acceptance__block" onClick={() => navigate(`/services/vacation/view/${row.id}`)}>
                            {columns.map((col: any, idx: number) => (
                                <div className={`services-acceptance__block__item ${col.class}`} style={{width: `${col.width}%`}} key={idx}>{col.render ? col.render(row[col.key], row) : row[col.key]}</div>
                            ))}
                        </div>
                    ))}
                </div>
            </div>

            {isOpenDialog === 1 && (
                <ServicesImprovementModal isOpen={isOpenDialog === 1}
                                          onClose={() => setIsOpenDialog(0)} />
            )}

            {isOpenDialog === 2 && (
                <ServicesAddingAgreerModal isOpen={isOpenDialog === 2}
                                           onClose={() => setIsOpenDialog(0)} />
            )}
        </>
    )
}