import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { isMd } from "../../../utils";
import {
    ArrowExitIcon,
    SearchIcon, SettingIcon
} from "../../../img";
import TextField from "../../../components/ui/form/Fields/TextField";
import { withDebounce } from "../../../functions";
import { $getSportBlockedUsers, $getSportUnbanUser } from "../../../api/requests/sport";
import { SportTrainerData } from "../../../api/requests/sport/interface";

interface DropdownItem {
    label: string;
    value: number | string;
}

const SportUsersBlockedPage = () => {
    const isMobile = isMd();
    const location = useLocation();
    const navigate = useNavigate();

    const searchParams = new URLSearchParams(location.search).get("search");

    const [searchValue, setSearchValue] = useState<string>("");

    const [userList, setUserList] = useState<SportTrainerData[]>([]);
    const [filterUserList, setFilterUserList] = useState<SportTrainerData[]>([])
    const [activePerson, setActivePerson] = useState<SportTrainerData | null>(null);

    const handleNavigation = () => {
        navigate(-1);
    };

    useEffect(() => {
        withDebounce(() => init());
    }, [searchValue]);

    useEffect(() => {
        searchParams && setSearchValue(searchParams);
    }, [searchParams]);

    function init() {
        $getSportBlockedUsers({
            search: searchValue ? searchValue : undefined
        }, { newApi: true }).then(res => {
            if (!res.data) return;

            setUserList(res.data.data)
            setFilterUserList(res.data.data)
        });
    }

    function onChangeSearch(value: string) {
        setSearchValue(value);

        if (!value.length) return navigate(`/sport/block-users`);

        navigate(`/sport/block-users?search=${value}`);
    }

    function handleOpenEdit(idx: number | null) {
        const updatedList: any = [...filterUserList].map((item, index) => {
            if (index === idx) {
                return {
                    ...item,
                    active: idx
                }
            }

            return item
        })

        const getActivePerson = idx !== null ? [...updatedList].find((item, index) => index === idx) : null

        setUserList(updatedList)
        setActivePerson(getActivePerson)
    }

    function handleUnBlockUser() {
        if (!activePerson) return;

        $getSportUnbanUser(activePerson.id, { newApi: true }).then(res => {
            if (res.error) return;

            const updatedList: any = [...userList]
            .map((item) => {
                return {
                    ...item,
                    active: null
                }
            })
            .filter((_, idx) => activePerson?.active !== idx)

            setFilterUserList(updatedList)
            setActivePerson(null)
        })
    }

    return (
        <div className="container employees">
            <div className="layout-inner__header birthdays-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={handleNavigation}>
                            <ArrowExitIcon />
                        </div>
                        <h1>Заблокированные пользователи</h1>
                    </div>
                )}

                <div className="employees-header__right">
                    <div className={"form-group contests__application__search"}>
                        <TextField
                            value={searchValue}
                            onChange={onChangeSearch}
                            placeholder={"Поиск"}
                            prepend={<SearchIcon color={"#00000080"} />}
                        />
                    </div>
                </div>
            </div>

            <div className="employees-content">
                {filterUserList.map((
                    item,
                    idx
                ) => (
                    <div
                        className={`employees-block ${item.active ? 'active' : ''}`}
                        key={idx}
                        style={{
                            zIndex: typeof activePerson?.active === "number" ? (activePerson.active === idx ? 1 : 0) : 1,
                            opacity: typeof activePerson?.active === "number" ? (activePerson.active === idx ? 1 : 0.5) : 1
                        }}
                    >
                        <div className="employees-block__top">
                            <img className="employees-block__image" src={item?.photo} alt="" />

                            <div
                                onClick={() => handleOpenEdit(activePerson?.active === idx ? null : idx)}
                                className={"employees-block__likeIcon"}
                            >
                                <SettingIcon color={"#00A7B5"} />
                            </div>
                        </div>

                        <div className="employees-block__content">
                            <h4 className="employees-block__title">{item?.name}</h4>

                            <p className="employees-block__small">{item?.structure?.title}</p>

                            <p className="employees-block__small">{item?.staff?.title}</p>

                            {/*<p className="employees-block__text mt-2">{item?.number}</p>*/}
                        </div>

                        {activePerson?.active === idx && (
                            <div className={"employees__btn"} onClick={handleUnBlockUser}>
                                Разблокировать
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SportUsersBlockedPage;