import DialogContainer from "../ui/dialog/DialogContainer";
import React, { FC, useState } from "react";
import { CloseIcon } from "../../img";
import { ProfileBalanceIcon } from "../../img/icons/layout-components";
import { $editDashboardBlogs } from "../../api/requests/blogs";
import { IBlogsRes } from "../../api/requests/blogs/interface";
import { Controller, useForm } from "react-hook-form";
import TextArea from "../ui/form/Fields/TextArea";
import { ICreateRequestIdea } from "../../api/requests/ideas/interface";
import { useNavigate } from "react-router-dom";

interface IIdeaPointModal {
    id: number,
    isOpen: boolean
    onClose: () => void
    setLoading: any,
    blogData: IBlogsRes,
}

export const BlogsRejectModal: FC<IIdeaPointModal> = (
    {
        isOpen,
        onClose,
        id,
        setLoading,
        blogData
    }
) => {
    const navigate = useNavigate();
    const {
        control,
        watch,
        formState: {isValid},
    } = useForm<ICreateRequestIdea>();

    const sendPoint = () => {
        setLoading(true);

        const updatedList = {
            title: blogData?.title,
            text: blogData?.text,
            json_text: JSON.stringify(JSON.parse(blogData?.json_text ? blogData?.json_text : '')),
            banner: blogData?.banner,
            is_favourite: blogData?.is_favourite,
            category_id: blogData?.category?.id,
            status: 3,
            status_message: watch()?.text
        }

        $editDashboardBlogs(id, updatedList, {newApi: true}).then(res => {
            if (!res) return;

            onClose();
            setLoading(false);
            navigate('/blogs/moderation')
        })
    };

    return (
        <DialogContainer isOpen={isOpen} closeModal={onClose} isShowCloseBtn={false}>
            <div className="ideas-modal-points__header">
                <h5 className="ideas-modal-points__title">Отклонение</h5>

                <div className="ideas-modal-points__closeIcon" onClick={onClose}>
                    <CloseIcon color={"#000"} />
                </div>
            </div>

            <div className="ideas-modal-points__content">
                {/*<p className="ideas-modal-points__label">Кол-во баллов</p>*/}

                {/*<div className="ideas-modal-points__input">*/}
                {/*    <ProfileBalanceIcon />*/}

                {/*    <input*/}
                {/*        value={text || 0}*/}
                {/*        type="number"*/}
                {/*        onChange={handleChangeValue}*/}
                {/*    />*/}
                {/*</div>*/}
                <Controller
                    name="text"
                    control={ control }
                    rules={ {
                        required: {
                            value: true,
                            message: "Напишите описание"
                        }
                    } }
                    render={ ({field: {onChange, value}}) => (
                        <TextArea
                            value={ value || ''}
                            className=""
                            title="Комментарий"
                            placeholder={ "Укажите причину" }
                            // name="desc"
                            onChange={ onChange }
                            // errors={errors}
                        />
                    ) }
                />
            </div>

            <div className="ideas-modal-points__button" onClick={sendPoint}>
                Направить
            </div>
        </DialogContainer>
    );
};