export const LayerIcon = ({
    color = '#00A7B5',
    size = 20,
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M14.8359 10.1452L18.3359 11.9392L10.3359 16.0399L2.33594 11.9392L5.89991 10.1124M10.3359 3.95996L18.3359 8.06058L10.3359 12.1612L2.33594 8.06058L10.3359 3.95996Z"
            stroke={color}
            strokeWidth="1.66667"
            strokeLinecap="round"
        />
    </svg>
)
