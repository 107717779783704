import { FC } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { CommentIcon, EyeIcon, LikeIcon, NewsArrowIcon } from "../../../img";
import NewsDateIcon from "../../../img/icons/news/NewsDateIcon";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../../functions";
import { getDateByFormat, getYearMonthDay, isTillMd } from "../../../utils";

interface IEvent {
    cover: string;
    description: string;
    start_date: string;
    end_date: string;
    id: number;
    location: string;
    slug: string;
    title: string;
}

interface IEventSlider {
    data: IEvent[];
}

export const EventSlider: FC<IEventSlider> = (
    {
        data
    }) => {
    const navigate = useNavigate();
    const isMobile = isTillMd();


    return (
        <div className="container news-slider">
            <div className={`news-slider__leftIcon ${isMobile ? "" : "isActive"}`}>
                {!isMobile && (
                    <NewsArrowIcon />
                )}
            </div>

            <div className="news-slider__content">
                <Swiper
                    id={"slider"}
                    slidesPerView={"auto"}
                    speed={1000}
                    spaceBetween={isMobile ? 10 : 20}
                    centeredSlides={true}
                    loop={data.length > 3}
                    autoplay={false}
                    pagination={{
                        clickable: true
                    }}
                    modules={[Navigation, Autoplay]}
                    navigation={{
                        prevEl: ".news-slider__leftIcon",
                        nextEl: ".news-slider__rightIcon"
                    }}
                    className="mySwiper"
                >
                    {data.map((
                        slide,
                        index
                    ) => (
                        <SwiperSlide
                            onClick={() => navigate(`/events/show/${slide.id}`)}
                            key={index}
                            style={{ backgroundImage: `url(${slide.cover})` }}
                            className={`${index === 0 ? "swiper-slider-active" : ""}`}>
                            <div className="news-slider__content__linear"></div>

                            <div className="news-slider__content__desc">
                                <h1 className="news-slider__content__desc__title">{slide.title}</h1>

                                {/*<div className="news-slider__content__desc__category">*/}
                                {/*    {slide.category_title || "Без категория"}*/}
                                {/*</div>*/}

                                {!isMobile && (
                                    <div className="news-slider__content__desc__bottom">
                                        <div className="news-slider__content__desc__dateBox">
                                            <NewsDateIcon size={"1.25rem"} fill={"#fff"} />
                                            <div>{getYearMonthDay(slide.start_date)}</div>
                                        </div>

                                        <div className="news-slider__content__desc__stats">

                                        </div>
                                    </div>
                                )}
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>

            <div className={`news-slider__rightIcon ${isMobile ? "" : "isActive"}`}>
                {!isMobile && (
                    <NewsArrowIcon />
                )}
            </div>
        </div>
    );
};