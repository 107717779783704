import { FC, ReactNode } from "react";

interface ToggleBlockForms {
    desc: any;
    isChecked: boolean;
    handleChange: (is: boolean, event?: any) => void;
    imgLeft?: ReactNode | null;
    required?: boolean;
    isDisabled?: boolean;
    className?: string;
    name?: string;
    errors?: any;
}

const ToggleBlock: FC<ToggleBlockForms> = (
    {
        desc,
        isChecked,
        handleChange,
        imgLeft,
        required,
        className = "",
        name = "",
        errors,
        isDisabled
    }
) => {
    return (<>
        <div className={`toggle ${className}`}>
            <div className="toggle__item">
                {imgLeft}
                <p
                    style={{ opacity: !isDisabled ? "" : ".5" }}
                    className="toggle__text"
                >
                    {desc}
                </p>
            </div>

            <div
                style={{ opacity: !isDisabled ? "" : "0.5" }}
                onClick={(event) => handleChange(!isChecked, event)}
                className={`toggle__btn ${isChecked ? "active" : ""}`}
            >
                <span></span>
            </div>
        </div>
        
        {errors?.[name] && (
            <div className="d-flex flex-column">
                <p className="field__error">
                    {errors?.[name]?.message as any}
                </p>
            </div>
        )}
        </>
    );
};

export default ToggleBlock;
