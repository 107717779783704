import {
    ArrowRightIcon,
    CloseIcon,
    IconFifthPlace,
    IconFirstPlace,
    IconFourthPlace,
    IconSecondPlace,
    IconThirdPlace
} from "../../../img";
import { FC, useRef, useState } from "react";
import DialogContainer from "../../ui/dialog/DialogContainer";

export interface TopElementDataModel {
    category_id?: number;
    title: string;
    count: number;
}


interface TopElementsResponseData {
    total: number;
    lastPage: number;
    currentPage: number;
    list: TopElementDataModel[];
}

interface IData extends TopElementsResponseData {
    title: string,
    img: any,
    isExcel: boolean,
    excelUrl?: string,
    startDate: number,
    endDate: number,
}

interface IStatisticsTopListBlock {
    data: IData,
    filter_type: string
}

export const AnalyticsListBlock: FC<IStatisticsTopListBlock> = (
    {
        data,
        filter_type
    }) => {
    const popupRef: any = useRef(null);
    const [dialog, setDialog] = useState(false);

    const sortedList = data?.list?.sort((a, b) => b.count - a.count);

    const openDialog = () => {
        setDialog(!dialog);
    };

    const handleClickOutside = (event: any) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            setDialog(false);
        }
    };

    document.addEventListener("mousedown", handleClickOutside);


    function addTextToObjects(arr: any) {
        const images = [<IconFirstPlace />, <IconSecondPlace />, <IconThirdPlace />,
            <IconFourthPlace />, <IconFifthPlace />];

        for (let i = 0; i < Math.min(arr?.length, 5); i++) {
            arr[i].image = images[i];
        }

        return arr;
    }

    return (
        <div className='analytics-list'>
            <div className='analytics-list__header'>
                <div className='analytics-list__header__left'>
                    {data?.img}
                    <h1 className='analytics-list__header__title'>{data?.title}</h1>
                </div>

                {sortedList?.length > 5 && (
                    <button
                        onClick={openDialog}
                        className='analytics-list-content__items__button'
                    >
                        <p>  Показать все</p>

                        <ArrowRightIcon color='#00A7B5'/>
                    </button
                    >
                )}
            </div>

            <div className='analytics-list-content'>
                {addTextToObjects(sortedList)?.length === 0 ? (
                        <div className='analytics-list-content__empty'>
                            Hет данных
                        </div>
                    )
                    :
                    (
                        <>
                            {addTextToObjects(sortedList)?.slice(0, 5).map((i: any) => (
                                <div className='analytics-list-content__items'>
                                    <div className='analytics-list-content__items__left'>
                                        {i.image}
                                        <p className='analytics-list-content__items__title'> {i.title}</p>
                                    </div>

                                    <div className='analytics-list-content__items__right'>
                                        <div className='analytics-list-content__items__stats'>
                                            <p> {i.count}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                            {/*<div className='statistics-list-content__items__seperator'></div>*/}

                        </>
                    )}
            </div>

            <DialogContainer
                isOpen={dialog}
                isShowCloseBtn={false}
                closeModal={() => setDialog(false)}
            >
                <div ref={popupRef} className='analytics-list-modal__dialog'>
                    <div className='analytics-list-modal__dialog__header'>
                        <h2 className='analytics-list__header__left'>
                            {data.img}

                            <h5 className='analytics-list__header__title'>{data.title}</h5>
                        </h2>

                        <div  onClick={() => {setDialog(false)}}>
                            <CloseIcon color={'#000'}/>
                        </div>
                    </div>

                    <div className='analytics-list-content'>
                        {addTextToObjects(sortedList)?.map((i: any) => (
                            <div className='analytics-list-content__items'>
                                <div className='analytics-list-content__items__left'>
                                    <div style={{width: 20}}>{i.image}</div>
                                    <p className='analytics-list-content__items__desc'> {i.title}</p>
                                </div>

                                <div className='analytics-list-content__items__right'>
                                    <div className='analytics-list-content__items__stats'>
                                        <p> {i.count}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </DialogContainer>
        </div>
    );
};