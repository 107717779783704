import { FC, useEffect, useState } from "react";
import TextField from "./Fields/TextField";
import Button from "./Buttons/Button";
import { withDebounce } from "../../../functions";
import { CloseIcon, SearchIcon } from "../../../img";
import { Skeleton } from "../skeleton";


interface Search {
    selectItem?: any;
    onChange: (item: any) => void;
    setValueProps?: (value: string) => void;
    isShowBtnDelete?: boolean;
    request?: (value: string) => Promise<any>;
    updateDateList?: (value: string) => any[];
    listProps?: any[];
    disabled?: boolean;
    placeholder?: string,
}

export interface UserData {
    id: number;
    name: string;
    image: string;
    status?: number;
    user_id?: number;
}

export const SearchItem: FC<Search> = (
    {
        selectItem,
        onChange,
        isShowBtnDelete = false,
        request,
        listProps,
        setValueProps,
        disabled,
        updateDateList,
        placeholder =  'Поиск',
    }
) => {
    const [userValue, setUserValue] = useState<string>('');
    const [userImg, setUserImg] = useState<string>('/img/icon/sidebar/users-blue.svg');

    const [listUsers, setListUsers] = useState<any[]>([]);
    const [selectItemLocal, setSelectItemLocal] = useState<any | null>(selectItem || null);

    const [ isFocused, setIsFocused ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (event: MouseEvent) => {
            const classEvent = (event.target as any).className;

            setIsFocused(['form-control'].includes(classEvent))
        };

        document.addEventListener('click', handleDocumentClick);

        return () => document.removeEventListener('click', handleDocumentClick);
    }, []);

    useEffect(() => {
        if (!selectItemLocal || !selectItem) return;

        if (userImg !== (selectItemLocal.image || selectItem.image)) {
            setUserValue(selectItemLocal.name || selectItem.name)
            setUserImg(selectItemLocal.image || selectItem.image)
        }
    }, [selectItem])

    useEffect(() => {
        listProps && setListUsers(listProps)
    }, [listProps]);

    function onChangeValueName(value: string) {
        if (!request) return;

        request(value).then((res: any) => {
            if (res && !res.data) return;

            setListUsers(updateDateList ? updateDateList(res.data.data) : res.data.data)
        })
    }

    function handleChange (value: string) {
        const filteredValue = value.replace(/[^a-zA-Z-а-я-А-Я-0-9\s()+]/g, "");
        setUserValue(filteredValue);
        setValueProps && setValueProps(filteredValue);
        onChange(placeholder === 'ФИО' ? value : '');
        setSelectItemLocal(null);
        withDebounce(() => onChangeValueName(filteredValue));
    }

    function onChangeSelectItem(item: any) {
        onChange(item);

        setUserValue(item.name);
        setUserImg(item.image);
        setSelectItemLocal(item);

        setListUsers([]);
    }

    function clearItem() {
        setSelectItemLocal(null);
        setUserValue('');
        setUserImg('/img/icon/sidebar/users-blue.svg');
    }

    function deleteItem() {
        clearItem();
        onChange(null);
    }

    return (
        <div className={'search__block'}>
            <div className="search__block-field">
                <TextField
                    value={userValue}
                    onChange={handleChange}
                    placeholder={placeholder}
                    prepend={<SearchIcon color={"#00000080"}/>}
                    className={`search__block-textField ${listUsers && 'active'}`}
                    disabled={disabled}
                    append={isShowBtnDelete ? (
                        <span className={"cursor-pointer"} onClick={deleteItem}>
                            <CloseIcon
                                color={'#FF0000'}
                                opacity={'1'}
                                width={10}
                                height={10}
                            />
                        </span>
                    ) : ""}
                />
            </div>

            {((listUsers || isLoading) && (userValue && userValue.length) && isFocused) ? (
                <div className="search__block-dialog">
                    <div className="search__block-items">
                        {isLoading && (
                            <>
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </>
                        )}


                        {listUsers.map((item) => (
                            <div
                                className="search__block-item"
                                key={'search__block-item' + item.id}
                                onClick={() => onChangeSelectItem(item)}
                            >
                                <div className="search__block-img">
                                    {item.svg ? item.svg : (
                                        <img src={item.image || "/img/avatars.png"} />
                                    )}
                                </div>

                                <p>{ item.name }</p>
                            </div>
                        ))}
                    </div>
                </div>
            ) : null}
        </div>
    )
}
