import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DialogContainer from "../../ui/dialog/DialogContainer";
import Button from "../../ui/form/Buttons/Button";

interface EventSuccessModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const EventSuccessModal = (
    {
        isOpen,
        onClose,
    }: EventSuccessModalProps
) => {
    const navigate = useNavigate();


    function handleCloseModal() {
        onClose();
    }

    return (
        <DialogContainer
            isOpen={isOpen}
            setIsOpen={() => {
                onClose()
            }}
            isShowCloseBtn={false}
            widthProps={427}
            label={""}
        >
            <div className={"sport__view__modal"}>
                <div className={"sport__view__modal__content-complete"}>
                    <span>😊</span>

                    <h3>Поздравляем</h3>

                    <p style={{ maxWidth: "90%" }}>
                        Вы успешно записались на мероприятие. Ждем вас!
                    </p>
                </div>

                <div className={"sport__view__modal__btn"}>
                    <Button
                        text={"Отлично"}
                        className={"btn btn-light w-100"}
                        onClick={handleCloseModal}
                    />
                </div>
            </div>
        </DialogContainer>
    );
}
;

export default EventSuccessModal;