import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EventsCalendarUsersCalendarList } from "./EventsCalendarUsersCalendarList";
import {
    setOpenPopup,
    setPopupData
} from "../../../redux";
import { Skeleton } from "../../../components/ui/skeleton";
import { SearchItem } from "../../../components/ui/form/SearchItem";

interface EventsCalendarLeftContentProps {
    list: any[];
    isLoading: boolean;
}

export const EventsCalendarLeftContent = (
    {
        list,
        isLoading
    }: EventsCalendarLeftContentProps
) => {
    const dispatch = useDispatch();

    const [expand, setExpand] = useState<number | boolean>(false);
    const [search, setSearch] = useState("");
    const [filterData, setFilterData] = useState<any[] | null>(null);

    const handleExpand = (panel: number | boolean) => {
        setExpand(panel);
    };

    const handleClosePopup = () => {
        dispatch(setOpenPopup());

        setTimeout(() => dispatch(setPopupData(null)), 500);
    };

    useEffect(() => {
        const listUpdate = search ? [...list].filter(item => item.description.toLowerCase().includes(search.toLowerCase())) : list

        setFilterData(listUpdate)
    }, [search, list]);

    return (
        <div className={"vacation__content__left__calendar"}>
            <div className={"vacation__content__left__calendar-header"}>
                <h2 className={"vacation__content__left__calendar-title"}>Мероприятия</h2>

                <SearchItem
                    setValueProps={setSearch}
                    onChange={() => {}}
                    // request={(value) => {}}
                />
            </div>

            {isLoading && (
                <div className={"vacation__content__left__calendar-block"}>
                    {[1, 2, 3, 4, 5].map((_, index) => (
                        <Skeleton key={index} />
                    ))}
                </div>
            )}

            {!isLoading && (
                <EventsCalendarUsersCalendarList
                    expand={expand}
                    handleExpand={handleExpand}
                    list={filterData}
                />
            )}
        </div>
    );
};
