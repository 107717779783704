export const MediaIcon = (
    {
        size = 24,
        color = "black",
        opacity = 0.3
    }
) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g opacity={opacity}>
                <path
                    d="M4.79844 21.6001H19.1984C20.5239 21.6001 21.5984 20.4691 21.5984 19.0738V4.92646C21.5984 3.53122 20.5239 2.40015 19.1984 2.40015H4.79844C3.47295 2.40015 2.39844 3.53121 2.39844 4.92646V19.0738C2.39844 20.4691 3.47295 21.6001 4.79844 21.6001Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.99844 15.6H17.9984L13.9984 8.60003L10.9984 13.1L8.99844 11.1L5.99844 15.6Z"
                    stroke={color}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
