import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPersonData } from "../../../redux";
import { BadgesModalCard } from "./BadgesModalCard";

interface BadgesCardProps {
    title?: string;
    data?: any[];
}

export const BadgesCard = (
    {
        title = "Благодарности",
        data
    }: BadgesCardProps
) => {
    const [badgesData, setBadgesList] = useState<any[]>([]);

    const personalData = useSelector(getPersonData);
    const [isOpenModal, setIsOpenModal] = useState(false)

    const groupData = (): any => {
        return [{}, ...badgesData].reduce((acc: any, curr: any) => {
            const key = curr.badge_id;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(curr);
            return acc;
        });
    };

    const filterData = badgesData?.filter((v: any, i: number, a: any) => a.findIndex((t: any) => t.badge_id === v.badge_id) === i);

    const checkBadgesSize = (badgeId: number) => groupData()[badgeId]?.length;

    useEffect(() => {
        const badgeList = data ? data : personalData?.badges?.personal || []

        if (badgeList) {
            const filterData = [...badgeList];

            setBadgesList(filterData)
        }
    }, [personalData, data]);

    function openBadgesListModal(item: any) {
        setIsOpenModal(true);
    }

    const handleOpenModal = () => {
        setIsOpenModal(true);
    }

    return (
        <div className='layout-badges'>
            <div className="layout-congratulations__header">
                <h1>{title}</h1>

                {!!filterData?.length && <p onClick={handleOpenModal}>Показать все</p>}
            </div>

            {filterData?.length ? (
                <div className='layout-badges__list'>
                    {filterData.map((item: any, idx: number) => {
                        const isBadgesSize = checkBadgesSize(item.badge_id);

                        return (
                            <div
                                key={idx}
                                className='layout-badges__item'
                                onClick={(e) => openBadgesListModal(item)}
                            >
                                <img src={item.workspace_badge.image} />

                                {isBadgesSize > 1 &&
                                  <div className='layout-badges__itemCount'>{isBadgesSize}</div>}
                            </div>
                        );
                    })}
                </div>
            ) : <div className={"empty"}>Список пуст</div>}

            <BadgesModalCard
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              data={badgesData}
            />
        </div>
    )
}
