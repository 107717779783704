import { ArrowExitIcon, SettingIcon } from "../../img";
import { isMd } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { getPersonData } from "../../redux";
import React, { useEffect, useState } from "react";
import { $getIdeasCategories, $getIdeasList } from "../../api/requests/ideas";
import { IIdeas } from "../../api/requests/ideas/interface";
import { IdeasCard } from "../../components/ideas";
import Dropdown from "../../components/ui/form/Dropdown/Dropdown";
import Button from "../../components/ui/form/Buttons/Button";
import ModalCatNews from "../../components/categories/ModalCatNews";

interface DropdownItem {
    label: string;
    value: number | string;
}

export const IdeasPage = () => {
    const navigate = useNavigate();
    const isMobile = isMd();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || undefined;

    const profileData = useSelector(getPersonData);

    const [ideasData, setIdeasData] = useState<IIdeas[]>([]);
    const [visibleIdeasData, setVisibleIdeasData] = useState<IIdeas[]>([]);
    const [isOpenCatModal, setIsOpenCatModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [statusSelect, setStatusSelect] = useState<DropdownItem | null>(
        {
            label: "Все ",
            value: "all"
        }
    );
    const [categorySelect, setCategorySelect] = useState<any | null>(
        {
            label: "Все категории",
            value: "all"
        }
    );

    const [statusList, setStatusList] = useState<DropdownItem[]>([
        {
            label: "Все",
            value: "all"
        },
        {
            label: "На проверке",
            value: 0
        },
        {
            label: "Обсуждение",
            value: 1
        },
        {
            label: "Одобрены",
            value: 2
        },
        {
            label: "Отклонены",
            value: 3
        },
        {
            label: "Мои",
            value: 4
        },
        {
            label: "Поддерживаю",
            value: 5
        }
    ]);

    const [categoryList, setCategoryList] = useState<any[]>([
        // {
        //   label: "Все категории",
        //   value: 'all'
        // },
        // {
        //   label: "Без категории",
        //   value: 0
        // },
    ]);

    function handleSetting() {
        setIsOpenCatModal(true);
    }

    useEffect(() => {
        const list: any = ideasData.filter((item) =>
            categorySelect?.value === "all" ? item : item.category_id == categorySelect?.value);

        setVisibleIdeasData(list);
    }, [categorySelect, ideasData]);


    useEffect(() => {
        setIsLoading(false);

        $getIdeasList({
            workspace_id: profileData?.workspace_id,
            offset: 0,
            limit: 30
        }, { formData: true }).then(res => {
            if (!res) return;

            setVisibleIdeasData(res);
            setIdeasData(res);
            setIsLoading(false);
        });

        $getIdeasCategories({
            id: profileData?.workspace_id,
            forceToResend: 0
        }, { formData: true }).then(res => {
            // if (!res) return;
            //
            // const updatedList = res?.map((item) => {
            //   return {
            //     value: item.id,
            //     label: item.title
            //   }
            // })
            //
            // setCategoryList([ ...updatedList, ...categoryList ])
            if (!res || !res.length) return;

            const updateList: any = [...res].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            }));

            setCategoryList([...updateList]);

            if (catId) {
                setCategorySelect({
                    label: "Все категории",
                    value: "all"
                });
            }
        });
    }, []);

    function handleChangeStatusDropdown(select: DropdownItem) {
        setIsLoading(false);

        setStatusSelect(select);

        $getIdeasList(select.value === "all" ?
            {
                workspace_id: profileData?.workspace_id,
                offset: 0,
                limit: 30
            } :
            {
                workspace_id: profileData?.workspace_id,
                offset: 0,
                limit: 30,
                status: select.value
            }, { formData: true }).then(res => {
            if (!res) return;

            setVisibleIdeasData(res);
            setIdeasData(res);
            setIsLoading(false);
        });
    }

    function handleChangeCategoryDropdown(select: DropdownItem) {
        setCategorySelect(select);
    }

    function handleCategory(select: DropdownItem) {
        setCategorySelect(select);

        navigate(`?catId=${select.value}`);
    }

    return (
        <div className="container ideas">
            <ModalCatNews
                isOpen={isOpenCatModal}
                setIsOpen={() => setIsOpenCatModal(false)}
                catList={categoryList}
                setCatList={setCategoryList}
                route={"ideas"}
            />

            <div className="layout-inner__header ideas-header">
                {!isMobile && (
                    <div className="layout-inner__header__title">
                        <div className="header__exitIcon" onClick={() => navigate(-1)}>
                            <ArrowExitIcon />
                        </div>

                        <h1>Идеи</h1>

                        <div className="ideas-header__createButton"
                             onClick={() => navigate("/ideas/create")}>
                            Предложить идею
                        </div>
                    </div>
                )}

                <div className="ideas-header__selects">
                    <Dropdown
                        // className="workspace-select"
                        placeholder={"Статус"}
                        options={[
                            ...statusList
                        ]}
                        value={statusSelect}
                        onChange={handleChangeStatusDropdown}
                    />

                    <Dropdown
                        // className="workspace-select"
                        placeholder={"Категория"}
                        options={[
                            {
                                label: "Все категории",
                                value: "all"
                            },
                            {
                                label: "Без категории",
                                value: 0
                            },
                            ...categoryList
                        ]}
                        value={categorySelect}
                        onChange={handleCategory}
                        labelBottom={(
                            <div className={"dropdown__list__setting"}>
                                <Button
                                    leftIcon={<SettingIcon color={"currentColor"} />}
                                    text={"Настройки"}
                                    className={"btn btn-primary-revert w-100"}
                                    onClick={handleSetting}
                                />
                            </div>
                        )}
                    />
                </div>


                {isMobile && (
                    <div className="ideas-createButton" onClick={() => navigate("/ideas/create")}>
                        Предложить идею
                    </div>
                )}
            </div>

            {visibleIdeasData.length > 0 ? (
                <div className="ideas-content">
                    {visibleIdeasData.map((
                        idea,
                        index
                    ) => (
                        <IdeasCard key={index} data={idea} />
                    ))}
                </div>
            ) : (
                <div className="ideas-content__empty">
                    В выбранной категории ещё нет элементов
                </div>
            )}
        </div>
    );
};