import EventsCalendarHeader from "./EventsCalendarHeader";
import { EventsCalendarLeftContent } from "./EventsCalendarLeftContent";
import React, { useEffect, useState } from "react";
import { getCurrentRole, getPersonData, getPopupData, setPopupData } from "../../../redux";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CalendarEventsWeek } from "../../../components/events/calendar/CalendarEventsWeek";
import {
    $getCalendarEventList,
    $getEventCategories
} from "../../../api/requests/events";
import moment from "moment";
import EventsCalendarModal from "../../../components/calendar/EventsCalendarModal";
import { PollsQuestionsModal } from "../../../components/polls";
import CalendarEventsDay from "../../../components/events/calendar/CalendarEventsDay";

interface Period {
    startDate: string;
    endDate: string;
}

const EventsCalendarPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const catId = searchParams.get("catId") || undefined;
    const pollId = searchParams.get("poll_id") || undefined;

    const dispatch = useDispatch();
    const { id } = useSelector(getPersonData);
    const dataModal = useSelector(getPopupData);
    const currentRole = useSelector(getCurrentRole);

    const isAdmin = currentRole === 1;

    const [period, setPeriod] = useState<Period | null>(null);
    const [selectionType, setSelectionType] = useState<"timelineDay" | "dayGridWeek" | "dayGridMonth">("dayGridWeek");
    const [eventsData, setEventsData] = useState<any[]>([]);

    const [categoryList, setCategoryList] = useState<any[]>([]);
    const [categorySelect, setCategorySelect] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);

    const formatDate = "YYYY-MM-DD HH:mm:ss";

    const getDayRange = (): Period => {
        const startOfDay = moment().startOf("day");
        const endOfDay = moment().endOf("day");
        return {
            startDate: startOfDay.format(formatDate),
            endDate: endOfDay.format(formatDate)
        };
    };

    const getWeekRange = (): Period => {
        const startOfWeek = moment().startOf("week");
        const endOfWeek = moment().endOf("week");
        return {
            startDate: startOfWeek.format(formatDate),
            endDate: endOfWeek.format(formatDate)
        };
    };

    const getMonthRange = (): Period => {
        const startOfMonth = moment().startOf("month");
        const endOfMonth = moment().endOf("month");
        return {
            startDate: startOfMonth.format(formatDate),
            endDate: endOfMonth.format(formatDate)
        };
    };

    useEffect(() => {
        getCategoriesList();
    }, []);

    useEffect(() => {
        init();
    }, [catId, period]);

    useEffect(() => {
        switch (selectionType) {
            case "timelineDay":
                setPeriod(getDayRange());
                break;
            case "dayGridWeek":
                setPeriod(getWeekRange());
                break;
            case "dayGridMonth":
                setPeriod(getMonthRange());
                break;
            default:
                break;
        }
    }, [selectionType]);

    function init() {
        getEventsList();
    }

    function getCategoriesList() {
        setIsLoading(true);

        $getEventCategories(isAdmin, { newApi: true }).then(res => {
            setIsLoading(false);
            if (!res || !res.data.data.length) return;

            const updateList = [...res.data.data].map(i => ({
                ...i,
                label: i.title,
                value: i.id
            }));

            setCategoryList([{ value: 0, label: "Все" }, ...updateList]);

            if (catId) {
                setCategorySelect(updateList.find(i => i.value === +catId));
            }
        });
    }

    function getEventsList() {
        if (!period) return;

        setIsLoading(true);

        const requestData = {
            category_id: catId || 0,
            start_date: period.startDate,
            end_date: period.endDate
        };

        $getCalendarEventList(requestData).then(res => {
            setIsLoading(false);

            if (!res.data) return;

            const updateList = res.data.flatMap(entry => entry.items);

            setEventsData(updateList);
        });
    }

    return (
        <div className={"vacation__wrapper"}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="vacation">
                            <EventsCalendarHeader
                                setCalendarBtn={setSelectionType}
                                categoryList={categoryList}
                                categorySelect={categorySelect}
                                setCategorySelect={setCategorySelect}
                            />

                            <div className={"vacation__content"}>
                                <div className={"vacation__content__left"}>
                                    <EventsCalendarLeftContent
                                        list={eventsData}
                                        isLoading={isLoading}
                                    />
                                </div>

                                <div className="vacation__content__right">
                                    {selectionType === "timelineDay" ? (
                                        <CalendarEventsDay
                                            dateWork={{
                                                start: "08:00:00",
                                                end: "20:00:00"
                                            }}
                                            list={eventsData}
                                            // list={[
                                            //     {
                                            //         "id": 7,
                                            //         "category": {
                                            //             "id": 1,
                                            //             "title": "test_1"
                                            //         },
                                            //         "author": {
                                            //             "id": 1,
                                            //             "name": "Абраров Акмаль ",
                                            //             "image": "https://api-itelma-dev.svoi.club/storage/https://api-old-itelma-dev.svoi.club/images/account/SuK2tp1Pc4.png",
                                            //             "job_title": "admin test"
                                            //         },
                                            //         "interview_id": 14,
                                            //         "start_date": "2024-12-09 08:00:00",
                                            //         "end_date": "2024-12-09 10:00:00",
                                            //         "location": "location",
                                            //         "title": "test events",
                                            //         "description": "desc events",
                                            //         "type": "with_interview",
                                            //         "cover": "https://api-itelma-dev.svoi.club/storage/images/events/PPXYTut0NiiQCJSa.png",
                                            //         "cover_for_banner": null,
                                            //         "is_participating": false
                                            //     },
                                            //     {
                                            //         "id": 8,
                                            //         "category": {
                                            //             "id": 2,
                                            //             "title": "string"
                                            //         },
                                            //         "author": {
                                            //             "id": 1,
                                            //             "name": "Абраров Акмаль ",
                                            //             "image": "https://api-itelma-dev.svoi.club/storage/https://api-old-itelma-dev.svoi.club/images/account/SuK2tp1Pc4.png",
                                            //             "job_title": "admin test"
                                            //         },
                                            //         "interview_id": 0,
                                            //         "start_date": "2024-12-09 11:00:00",
                                            //         "end_date": "2024-12-09 18:00:00",
                                            //         "location": "local",
                                            //         "title": "test events2",
                                            //         "description": "desc",
                                            //         "type": "without_interview",
                                            //         "cover": "https://api-itelma-dev.svoi.club/storage/images/events/711c7HSt8UqTCLCF.png",
                                            //         "cover_for_banner": null,
                                            //         "is_participating": false
                                            //     }
                                            // ]}
                                            onClickPropsAdd={(item, isEdit) => {
                                                isEdit && dispatch(setPopupData(item))
                                            }}
                                            selectDate={new Date()}
                                        />
                                    ) : (
                                        <CalendarEventsWeek
                                            calendarDataList={eventsData}
                                            selectionType={selectionType}
                                            isLoading={isLoading}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <EventsCalendarModal
                isOpen={!!dataModal}
                data={dataModal}
                onClose={() => dispatch(setPopupData(null))}
                setIsOpen={() => dispatch(setPopupData(null))}
            />

            <PollsQuestionsModal
                isEvent={true}
                isOpenModal={!!pollId}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                onClose={() => {
                    navigate("/calendar/events");
                    getEventsList();
                }}
            />
        </div>
    );
};

export default EventsCalendarPage;