import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import SignInLayout from "../layouts/SignInLayout";
import Auth from "../containers/auth";
import Activate from "../containers/auth/activate";
import ProtectedRoute from "../components/ui/ProtectedRoute";
import NotFound from "../components/ui/NotFound";
import Register from "../containers/auth/register";
import {
    MainPage,
    ProfilePage,
    WorkspacePage,
    CalendarPage,
    ReferencePage,
    ServicesPage,
    Contacts,
    VacationPage,
    BirthdaysPage,
    EmployeesPage,
    ProfileColleagues,
    FavoritesPage,
    FavoritesEmployeesPage,
    FavoritesNewsPage,
    FavoritesPolls,
    FavoritesCompetitions,
    NewsPage,
    NewsShowPage,
    PollsPage,
    BlogsPage,
    BlogsDraftsPage,
    BlogsShowPage,
    PollsCreatePage,
    BlogsCreatePage,
    ServicesCreatePage,
    ServicesEducationPage,
    UserProfilePage,
    WorkspaceSettingsPage,
    IdeasPage,
    IdeasCreateEdit,
    ServicesShowPage,
    ServicesViewPage,
    BlogsModerationPage,
    AnalyticsActivityPage,
    AnalyticsBadgesPage,
    AnalyticsDiscountsPage,
    AnalyticsSportPage,
    FavoritesBlogs,
    FavoritesBirthdays,
    FavoritesIdeas,
    FavoritesMediaGalleries,
    FavoritesContests,
    FavoritesUsers,
    PollsDraftPage,
    ServicesMedicalPage,
    ServicesEducationCreatePage,
    ServicesMedicalCreatePage,
    ServicesTripPage, ServicesTripCreate, ServicesVacationPage, ServicesVacationCreate
} from "../containers";
import MainLayout from "../layouts/MainLayout";
import { useEffect, useState } from "react";
import InnerLayout from "../layouts/InnerLayout";
import ContestsPage from "../containers/contests/ContestsPage";
import ContestsCreateEditPage from "../containers/contests/ContestsCreateEdit";
import ContestsPageApplications from "../containers/contests/ContestsPageApplications";
import StructurePage from "../containers/services/structure/StructurePage";
import TrainingPage from "../containers/sport/TrainingPage";
import TrainingSessionViewPage from "../containers/sport/view/TrainingSessionViewPage";
import SportCreateEditPage from "../containers/sport/SportCreateEdit";
import TrainerLayout from "../layouts/TrainerLayout";
import TrainerViewPage from "../containers/sport/view/TrainerViewPage";
import SportUsersBlockedPage from "../containers/sport/SportUsersBlocked/SportUsersBlockedPage";
import NewsCreateEditPage from "../containers/news/NewsCreateEdit";
import { IdeasShowPage } from "../containers/ideas/IdeasShow";
import { NewsDraftPage } from "../containers/news/NewsDraftPage";
import GroupsPage from "../containers/groups/GroupsPage";
import GroupCreatePage from "../containers/groups/GroupCreate";
import WorkspaceSettingGroupPage from "../containers/workspace-settings/WorkspaceSettingGroupPage";
import WorkspaceSettingGroupBanners
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupBanners";
import WorkspaceSettingGroupUsers
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupUsers";
import WorkspaceSettingGroupModules
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupModules";
import WorkspaceSettingGroupEdit
    from "../containers/workspace-settings/groups/WorkspaceSettingGroupEdit";
import AuthLayout from "../layouts/AuthLayout";
import { NotificationsPage } from "../containers/notifications";
import WorkspaceSettingModules from "../containers/workspace-settings/WorkspaceSettingModules";
import WorkspaceSettingBanners from "../containers/workspace-settings/WorkspaceSettingBanners";
import { WorkspaceUsersBlock } from "../components/workspace-users";
import WorkspaceSettingFieldUser from "../containers/workspace-settings/WorkspaceSettingFieldUser";
import { ProfileEditPage } from "../containers/profile/edit";
import WorkspaceSettingAccrual from "../containers/workspace-settings/WorkspaceSettingAccrual";
import WorkspaceSettingAll from "../containers/workspace-settings/WorkspaceSettingAll";
import ServicesWorkplacePage from "../containers/services/services-workplace/ServicesWorkplacePage";
import WorkspaceSettingWorkplacePage
    from "../containers/workspace-settings/WorkspaceSettingWorkplacePage";
import { MediaFilesPage } from "../containers/media-files";
import { MediaFilesShowPage } from "../containers/media-files/show";
import MediaFilesEditPage from "../containers/media-files/create/MediaFilesEditPage";
import { MediaFilesDraftsPage } from "../containers/media-files/drafts/MediaFilesDraftsPage";
import WorkspaceSettingOfficeManager
    from "../containers/workspace-settings/WorkspaceSettingOfficeManager";
import { FilesPage } from "../containers/files";
import { EventsPage } from "../containers/events";
import { EventsShowPage } from "../containers/events/show";
import EventEditPage from "../containers/events/edit/EventEditPage";
import { EventsDraftPage } from "../containers/events/drafts";
import WorkspaceSettingBadges from "../containers/workspace-settings/WorkspaceSettingBadges";
import ServicesBookingPage from "../containers/services/services-booking/ServicesBookingPage";
import WorkspaceSettingSport from "../containers/workspace-settings/WorkspaceSettingSport";
import EventsCalendarPage from "../containers/calendar/events";
import WorkspaceSettingWelcome from "../containers/workspace-settings/WorkspaceSettingWelcome";
import WorkspaceSettingBooking from "../containers/workspace-settings/WorkspaceSettingBooking";
import { DiscountPage } from "../containers/discount";
import { DiscountShowPage } from "../containers/discount/show";

const RoutesComponent = () => {
    const pathname = window.location.pathname
    const [ isScrolled, setIsScrolled ] = useState(false)

    useEffect(() => {
        
        const handleScroll = () => {
            const slider = document.getElementById("sliderRouters");

            if (!slider) return;

            const sliderRect = slider.getBoundingClientRect();

            setIsScrolled(sliderRect.top <= 80);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [pathname]);

    useEffect(() => {
        if (document.body) {
            const body = document.body;
            body.scrollTo(1000, 1000);
        }
    }, [pathname]);

    return (
        <BrowserRouter future={{
            v7_relativeSplatPath: true,
            v7_startTransition: true,
        }}>
            <Routes>
                <Route
                    element={<SignInLayout />}
                >
                    <Route path="/auth" element={<Auth />} />

                    <Route path="/verification" element={<Activate />} />
                    <Route path="/register" element={<Register />} />
                </Route>

                <Route
                    element={<AuthLayout/>}
                >
                    <Route path="/" element={<ProtectedRoute />}>
                        <Route element={<MainLayout isScrolled={isScrolled}/>}>
                            <Route index element={<MainPage isScrolled={isScrolled} />} />

                            <Route path='workspace' element={<WorkspacePage/>}/>

                            <Route path='reference' element={<ReferencePage/>}/>

                            <Route path='contacts' element={<Contacts/>}/>

                            <Route path='services' element={<ServicesPage/>}/>

                            <Route path='sport' element={<TrainingPage/>}/>
                        </Route>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout />}>
                    <Route path='calendar/:key' element={<VacationPage/>}/>

                    <Route path='calendar/events' element={<EventsCalendarPage/>}/>

                    <Route path='employees'>
                        <Route path='' element={ <EmployeesPage /> }/>
                        <Route path='structure' element={<StructurePage/>}/>
                    </Route>

                    <Route path="profile" >
                        <Route path='' element={<ProfilePage/>}/>
                        <Route path='colleagues' element={<ProfileColleagues/>}/>
                        <Route path='edit' element={<ProfileEditPage/>}/>
                    </Route>

                    <Route path='birthdays' element={<BirthdaysPage/>}></Route>

                    <Route path='favorites' >
                        <Route path='' element={<FavoritesPage/>}/>
                        <Route path='employees' element={<FavoritesEmployeesPage/>}/>
                        <Route path='users' element={<FavoritesUsers/>}/>
                        <Route path='news' element={<FavoritesNewsPage/>}/>
                        <Route path='polls' element={<FavoritesPolls/>}/>
                        <Route path='competitions' element={<FavoritesCompetitions/>}/>
                        <Route path='blogs' element={<FavoritesBlogs/>}/>
                        <Route path='birthdays' element={<FavoritesBirthdays/>}/>
                        <Route path='ideas' element={<FavoritesIdeas/>}/>
                        <Route path='media-galleries' element={<FavoritesMediaGalleries/>}/>
                        <Route path='contests' element={<FavoritesContests/>}/>
                    </Route>

                    <Route path='news'>
                        <Route path='' element={<NewsPage/>}/>
                        <Route path="show/:id" element={ <NewsShowPage/> }/>
                        <Route path='edit/:id' element={<NewsCreateEditPage/>}/>
                        <Route path='create' element={<NewsCreateEditPage/>}/>
                        <Route path='drafts' element={<NewsDraftPage/>}/>
                    </Route>

                    <Route path='ideas'>
                        <Route path='' element={<IdeasPage/>}/>
                        <Route path='show/:id' element={<IdeasShowPage/>}/>
                        <Route path='create' element={<IdeasCreateEdit/>}/>
                        <Route path='edit/:id' element={<IdeasCreateEdit/>}/>
                    </Route>

                    <Route path='polls' >
                        <Route path='' element={<PollsPage/>}/>
                        <Route path='drafts' element={<PollsDraftPage/>}/>
                        <Route path='edit' element={<PollsCreatePage/>}/>
                        <Route path='create' element={<PollsCreatePage/>}/>
                    </Route>

                    <Route path='competitions'>
                        <Route path='' element={<ContestsPage/>}/>
                        <Route path='application' element={<ContestsPageApplications/>}/>
                        <Route path='create' element={<ContestsCreateEditPage/>}/>
                        <Route path='edit' element={<ContestsCreateEditPage/>}/>
                    </Route>

                    <Route path='blogs'>
                        <Route path='drafts' element={<BlogsDraftsPage/>}/>
                        <Route path='moderation' element={<BlogsModerationPage/>}/>
                        <Route path='show/:id' element={<BlogsShowPage/>}/>
                        <Route path='moderation/show/:id' element={<BlogsShowPage/>}/>
                        <Route path='draft/show/:id' element={<BlogsShowPage/>}/>
                        <Route path='create' element={<BlogsCreatePage/>}/>
                        <Route path='edit/:id' element={<BlogsCreatePage/>}/>
                        <Route path='moderation/edit/:id' element={<BlogsCreatePage/>}/>
                        <Route path='draft/edit/:id' element={<BlogsCreatePage/>}/>
                    </Route>

                    <Route path='services'>
                        <Route path='create' element={<ServicesCreatePage/>}/>
                        <Route path='edit/:id' element={<ServicesCreatePage/>}/>
                        <Route path='show/:id' element={<ServicesShowPage/>}/>
                        <Route path='view/:id' element={<ServicesViewPage/>}/>
                        <Route path='education/list' element={<ServicesEducationPage/>}/>
                        <Route path='education/create' element={<ServicesEducationCreatePage/>}/>
                        <Route path='medical' element={<ServicesMedicalPage/>}/>
                        <Route path='medical/create' element={<ServicesMedicalCreatePage/>}/>
                        <Route path='trip/list' element={<ServicesTripPage/>}/>
                        <Route path='trip/create' element={<ServicesTripCreate/>}/>
                        <Route path='vacation' element={<ServicesVacationPage/>}/>
                        <Route path='vacation/create' element={<ServicesVacationCreate/>}/>
                        <Route path='vacation/view/:id' element={<ServicesVacationCreate/>}/>
                        <Route path='workplace' element={<ServicesWorkplacePage/>}/>
                        <Route path='booking' element={<ServicesBookingPage/>}/>
                    </Route>

                    <Route path='sport'>
                        <Route path='view/:id' element={<TrainingSessionViewPage/>} />
                        <Route path='edit/:id' element={<SportCreateEditPage/>}/>
                        <Route path='create' element={<SportCreateEditPage/>}/>
                        <Route path='block-users' element={<SportUsersBlockedPage/>}/>
                    </Route>

                    <Route path='workspace-settings' element={<WorkspaceSettingsPage/>}>
                        <Route path='navigation' element={<WorkspaceSettingModules/>}/>
                        <Route path='banners' element={<WorkspaceSettingBanners/>}/>
                        <Route path='users' element={<WorkspaceUsersBlock/>}/>
                        <Route path='user_fields' element={<WorkspaceSettingFieldUser/>}/>
                        <Route path='accrual' element={<WorkspaceSettingAccrual/>}/>
                        <Route path='office-manager' element={<WorkspaceSettingOfficeManager/>}>
                            <Route path=':key' element={<WorkspaceSettingOfficeManager/>}/>
                        </Route>
                        <Route path='all-settings'>
                            <Route path='setting' element={<WorkspaceSettingAll/>}/>
                            <Route path='badges' element={<WorkspaceSettingBadges/>}/>
                            <Route path='booking' element={<WorkspaceSettingBooking/>}/>
                            <Route path='workplace' element={<WorkspaceSettingWorkplacePage/>}/>
                            <Route path='welcome' element={<WorkspaceSettingWelcome/>}/>
                            <Route path='sport' element={<WorkspaceSettingSport/>}/>
                        </Route>
                    </Route>

                    <Route path='groups'>
                        <Route path='' element={<GroupsPage/>}/>
                        <Route path='create' element={<GroupCreatePage/>}/>
                        <Route path='setting' element={<WorkspaceSettingGroupPage/>}>
                            <Route path='banners' element={<WorkspaceSettingGroupBanners/>}/>
                            <Route path='modules' element={<WorkspaceSettingGroupModules/>}/>
                            <Route path='settings' element={<WorkspaceSettingGroupEdit/>}/>
                            <Route path='users' element={<WorkspaceSettingGroupUsers/>}/>
                        </Route>
                    </Route>

                    <Route path='files'>
                        <Route path='' element={<FilesPage/>}/>
                        <Route path='show/:id' element={<FilesPage/>}/>
                    </Route>

                    <Route path='notifications'>
                        <Route path='' element={<NotificationsPage/>}/>
                    </Route>

                    <Route path='media-files'>
                        <Route path='' element={<MediaFilesPage/>}/>
                        <Route path="show/:id" element={ <MediaFilesShowPage/> }/>
                        <Route path='edit/:id' element={<MediaFilesEditPage/>}/>
                        <Route path='create' element={<MediaFilesEditPage/>}/>
                        <Route path='drafts' element={<MediaFilesDraftsPage/>}/>
                    </Route>

                    <Route path='events'>
                        <Route path='' element={<EventsPage />}/>
                        <Route path="show/:id" element={ <EventsShowPage/> }/>
                        <Route path='edit/:id' element={<EventEditPage/>}/>
                        <Route path='create' element={<EventEditPage/>}/>
                        <Route path='drafts' element={<EventsDraftPage/>}/>
                    </Route>
                </Route>

                <Route path={"/"} element={<InnerLayout isShowBreadcrumb={false}/>}>
                    <Route path='user/:id'>
                        <Route path='' element={<UserProfilePage/>} />
                    </Route>

                    <Route path='calendar'>
                        <Route path='' element={<CalendarPage/>}/>
                    </Route>

                    <Route path='analytics'>
                        <Route path='activity' element={<AnalyticsActivityPage/>} />
                        <Route path='badges' element={<AnalyticsBadgesPage/>} />
                        <Route path='discounts' element={<AnalyticsDiscountsPage/>} />
                        <Route path='sport' element={<AnalyticsSportPage/>} />
                    </Route>

                    <Route path='sport'>
                        <Route path='' element={<TrainingPage/>} />
                    </Route>

                    <Route path='blogs'>
                        <Route path='' element={<BlogsPage/>} />
                    </Route>

                    <Route path='discount'>
                        <Route path='' element={<DiscountPage/>} />
                        <Route path='show/:id' element={<DiscountShowPage/>} />
                    </Route>
                </Route>

                <Route path={"/"} element={<TrainerLayout/>}>
                    <Route path='trainer'>
                        <Route path='' element={<TrainingPage isTrainerRole={true}/>} />
                        <Route path='view/:id' element={<TrainerViewPage/>} />
                    </Route>
                </Route>

                <Route
                    path={"*"}
                    element={<NotFound />}
                />
            </Routes>
        </BrowserRouter>
    );
};
export default RoutesComponent;
