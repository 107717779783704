import VacationHeader from "./VacationHeader";
import { VacationLeftContent } from "./VacationLeftContent";
import { CalendarBlock } from "../../../components/vacation/CalendarBlock";
import { useEffect, useState } from "react";
import {
    CalendarVacationWeek
} from "../../../components/vacation/CalendarBlock/CalendarVacationWeek";
import { useDispatch, useSelector } from "react-redux";
import { getMonth, setBookingList } from "../../../redux";
import { $getVacationBooking } from "../../../api/requests/vacation";

export const VacationPage = () => {
    const dispatch = useDispatch();
    const month = useSelector(getMonth);

    const [calendarBtn, setCalendarBtn] = useState("month");
    const [page, setPage] = useState(1);

    useEffect(() => {
        month && getRequestBooking();

        return () => {
            dispatch(setBookingList([]));
        }
    }, [month]);

    function getRequestBooking() {
        const requestData = {
            page: page,
            date: month,
        }

        $getVacationBooking(
            requestData,
        ).then(res => {
            if (!res?.data) return;

            const updateSuccessList = [...res.data.success].map(item => ({
                ...item,
                isSuccess: true,
            }))

            const updateProgressList = [...res.data.in_progress].map(item => ({
                ...item,
                isProgress: true,
            }))

            const filterList = [...updateSuccessList, ...updateProgressList];

            dispatch(setBookingList(filterList));
        });
    }

    return (
        <div className={"vacation__wrapper"}>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="vacation">
                            <VacationHeader setCalendarBtn={setCalendarBtn}/>

                            <div className={"vacation__content"}>
                                <div className={"vacation__content__left"}>
                                    <VacationLeftContent />
                                </div>

                                <div className="vacation__content__right">
                                    {(calendarBtn === "week") ? (
                                        <CalendarVacationWeek/>
                                    ) : (
                                        <CalendarBlock/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};