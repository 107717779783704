import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment";
import { useEffect, useRef } from "react";
import { FullCalendarComponentEventItem } from "./FullCalendarComponentEventItem";

const dayHeaderContent = (arg: { date: Date }) => {
    return moment(arg.date).format("dddd, DD MMMM");
};

interface MeetRoom {
    id: string;
    resourceId: string;
    title: string;
    start: string;
    end: string;
    backgroundColor?: string;
    borderColor?: string;
    textColor?: string;
}

interface FullCalendarComponentProps {
    initialView?: string;
    slotMinTime?: string;
    slotMaxTime?: string;
    selectedDate: any;
    meetRooms: MeetRoom[];
    onClickCalendar: (item: MeetRoom) => void;
    onClickCalendarNew: (info: any) => void;
}

const FullCalendarComponent = (
    {
        initialView = "timeGridWeek",
        slotMinTime,
        slotMaxTime,
        selectedDate,
        meetRooms = [],
        onClickCalendar,
        onClickCalendarNew
    }: FullCalendarComponentProps
) => {
    const calendarRef = useRef<FullCalendar | null>(null);

    useEffect(() => {
        if (calendarRef.current && selectedDate) {
            (calendarRef.current.getApi()).gotoDate(selectedDate);
        }
    }, [selectedDate]);

    const searchData: any = (id: string) => meetRooms.find((i) => i.id === id);

    return (
        <FullCalendar
            ref={calendarRef}
            plugins={[timeGridPlugin, resourceTimelinePlugin, interactionPlugin, dayGridPlugin]}
            initialView={initialView}
            schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
            locale="ru"
            dayHeaderContent={dayHeaderContent}
            headerToolbar={false}
            displayEventTime
            selectable
            nowIndicator
            allDaySlot={false}
            slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
                omitZeroMinute: false,
                meridiem: "short"
            }}
            height="700px"
            hiddenDays={[0]}
            slotEventOverlap={false}
            eventDisplay="block"
            slotLabelInterval="01:00"
            slotDuration="00:30:00"
            snapDuration="00:30:00"
            slotMinTime={slotMinTime || "08:00:00"}
            slotMaxTime={slotMaxTime || "20:00:00"}
            slotLabelContent={({ text }) => (
                <span className="customTimeLine">
          {text} <span>{slotMaxTime?.split(":")?.slice(0, 2)?.join(":") || "20:00"}</span>
        </span>
            )}
            firstDay={1}
            eventContent={(info) => (
                <FullCalendarComponentEventItem
                    info={info}
                    item={searchData(info.event.id)}
                />
            )}
            eventTextColor="#000"
            eventBorderColor="transparent"
            dateClick={(info: any) => {
                const currentDate = new Date();
                if (currentDate > info.date) {
                    return;
                }

                onClickCalendarNew(info);
            }}
            eventClick={(info: any) => {
                const item = searchData(info.event.id);
                if (item) onClickCalendar(item);
            }}
            events={meetRooms}
        />
    );
};

export default FullCalendarComponent;
