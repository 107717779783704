import React, { FC } from "react";
import { IBaseIconProps } from "../types";

export const AddFolderIcon: FC<IBaseIconProps> = (
    {
        size = 24,
        color = "#00A7B5"
    }
) => {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12.1019 19.5145H4.30288C2.97739 19.5145 1.90287 18.44 1.90288 17.1145L1.90297 7.99022C1.90298 7.0763 1.90263 5.77457 1.90234 4.83202C1.90214 4.1691 2.43947 3.63232 3.10239 3.63232H8.8206L11.5856 6.58596H19.9019C20.5647 6.58596 21.1019 7.12322 21.1019 7.78596V10.9735M18.7019 20.3674L18.7019 16.9733M18.7019 16.9733L18.7019 13.5791M18.7019 16.9733L15.3078 16.9733M18.7019 16.9733L22.096 16.9733"
                stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};
